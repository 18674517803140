import React, { useEffect, useRef, useState, useContext } from "react";
import "../../assets/style/breadcrumbs.css";
import { Dropdown, Space } from "antd";
import { ContractTabContext } from "./ContractTabContext";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { getNodeIcon, isEmptyObject } from "../../utils/common";
import { debounce } from "lodash";
import ContractRouter from "./ContractRouter";

const Breadcrumbs = ({
  tabBreadcrumbs,
  setTabBreadcrumbs,
  location,
  expandedKeys,
  setExpandedKeys,
}) => {
  const { tabs, setTabs, activeKey, setActiveKey } =
    useContext(ContractTabContext);
  const containerRef = useRef(null);
  const [visibleBreadcrumbs, setVisibleBreadcrumbs] = useState([]);
  const [overflowedBreadcrumbs, setOverflowedBreadcrumbs] = useState([]);
  const breadcrumbWidths = useRef(new Map());

  const calculateBreadcrumbWidths = () => {
    if (!containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth - 70;
    let totalWidth = 0;
    const newVisibleBreadcrumbs = [];
    const newDropdownBreadcrumbs = [];

    tabBreadcrumbs.forEach((breadcrumb, index) => {
      let breadcrumbWidth;

      // Use cached width if available
      if (breadcrumbWidths.current.has(breadcrumb.title)) {
        breadcrumbWidth = breadcrumbWidths.current.get(breadcrumb.title);
      } else {
        // Create a hidden span element of the breadcrumb to calculate width
        const fakeSpan = document.createElement("span");
        fakeSpan.className = "breadcrumb-title";
        fakeSpan.style.visibility = "hidden";
        fakeSpan.style.position = "absolute";
        fakeSpan.innerHTML = `${
          getNodeIcon(
            breadcrumb.title === "Suppliers"
              ? "SupplierTree"
              : breadcrumb.apiArgs.type,
            index === tabBreadcrumbs.length - 1 ? "active" : "inactive"
          ).outerHTML
        } ${breadcrumb.title}`;

        document.body.appendChild(fakeSpan);

        // Get width and clean up
        breadcrumbWidth = fakeSpan.getBoundingClientRect().width - 10;
        document.body.removeChild(fakeSpan);

        // Cache the calculated width
        breadcrumbWidths.current.set(breadcrumb.title, breadcrumbWidth);
      }

      totalWidth += breadcrumbWidth;

      if (totalWidth <= containerWidth) {
        newVisibleBreadcrumbs.push(breadcrumb);
      } else {
        newDropdownBreadcrumbs.push(breadcrumb);
      }
    });

    setVisibleBreadcrumbs(newVisibleBreadcrumbs);
    setOverflowedBreadcrumbs(newDropdownBreadcrumbs);
  };

  useEffect(() => {
    calculateBreadcrumbWidths();
    const debouncedCalculateBreadcrumbWidths = debounce(
      calculateBreadcrumbWidths,
      50
    );

    window.addEventListener("resize", debouncedCalculateBreadcrumbWidths);

    return () => {
      window.removeEventListener("resize", debouncedCalculateBreadcrumbWidths);
    };
  }, [tabBreadcrumbs]);

  const navigateBackToTree = (breadcrumb) => {
    const existingTabIndex = tabs.findIndex((t) => t.id === activeKey);
    if (existingTabIndex !== -1) {
      const slicedBreadcrumbs = tabBreadcrumbs.slice(
        0,
        tabBreadcrumbs.indexOf(breadcrumb) + 1
      );

      const newTabId = `newTab_${Date.now()}`;
      const updatedTab = {
        ...tabs[existingTabIndex],
        id: newTabId,
        title: "Contracts",
        type: "Supplier",
        children: <ContractRouter breadcrumbs={slicedBreadcrumbs} />,
        breadcrumbs: slicedBreadcrumbs,
      };

      setTabs((prevTabs) => [
        ...prevTabs.slice(0, existingTabIndex),
        updatedTab,
        ...prevTabs.slice(existingTabIndex + 1),
      ]);

      setActiveKey(newTabId);
    }
  };

  const renderDropdown = (breadcrumbs) => {
    return breadcrumbs.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.length - 1;
      const dropdownItem = {
        label: (
          <span
            className={
              isLast ? "breadcrumb-title" : "breadcrumb-title-inactive"
            }
            onClick={() => {
              if (isLast) {
                return;
              } else if (
                tabBreadcrumbs[tabBreadcrumbs.length - 1].inspectItem
              ) {
                // Executes when breadcrumbs are clicked from contract view screen
                navigateBackToTree(breadcrumb);
              } else if (
                location &&
                location === "createContract" &&
                expandedKeys &&
                setExpandedKeys
              ) {
                setExpandedKeys((prevKeys) => {
                  const index = prevKeys.findIndex(
                    (key) => key === breadcrumb.apiArgs?.contract_id
                  );

                  if (
                    breadcrumb.apiArgs.supplier_id === "undefined" &&
                    breadcrumb.apiArgs.contract_id === "undefined"
                  ) {
                    return [];
                  }
                  // If the key is found, keep only the keys up to and including the found index
                  return index !== -1 ? prevKeys.slice(0, index + 1) : prevKeys;
                });

                setTabBreadcrumbs((prev) =>
                  prev.slice(0, tabBreadcrumbs.indexOf(breadcrumb) + 1)
                );
              } else {
                setTabBreadcrumbs((prev) =>
                  prev.slice(0, tabBreadcrumbs.indexOf(breadcrumb) + 1)
                );
              }
            }}
          >
            {getNodeIcon(
              breadcrumb.title === "Suppliers"
                ? "SupplierTree"
                : breadcrumb.apiArgs.type,
              isLast ? "active" : "inactive"
            )}
            {breadcrumb.title}
          </span>
        ),
        key: `${breadcrumb.title}_${index}`,
      };

      return dropdownItem;
    });
  };

  return (
    <div ref={containerRef} style={{ display: "flex", alignItems: "center" }}>
      {visibleBreadcrumbs.map((breadcrumb, index) => {
        const isLast = index === visibleBreadcrumbs.length - 1;
        return (
          <span key={index} style={{ display: "flex", alignItems: "center" }}>
            <span
              className={
                isLast && overflowedBreadcrumbs.length === 0
                  ? "breadcrumb-title"
                  : "breadcrumb-title-inactive"
              }
              onClick={() => {
                if (
                  isLast &&
                  overflowedBreadcrumbs.length === 0 &&
                  !tabBreadcrumbs[tabBreadcrumbs.length - 1].inspectItem
                ) {
                  return;
                } else if (
                  tabBreadcrumbs[tabBreadcrumbs.length - 1].inspectItem
                ) {
                  // Executes when breadcrumbs are clicked from contract view screen
                  navigateBackToTree(breadcrumb);
                } else if (
                  location &&
                  location === "createContract" &&
                  expandedKeys &&
                  setExpandedKeys
                ) {
                  setExpandedKeys((prevKeys) => {
                    const index = prevKeys.findIndex(
                      (key) => key === breadcrumb.apiArgs?.contract_id
                    );

                    if (
                      breadcrumb.apiArgs.supplier_id === "undefined" &&
                      breadcrumb.apiArgs.contract_id === "undefined"
                    ) {
                      return [];
                    }

                    // If the key is found, keep only the keys up to and including the found index
                    return index !== -1
                      ? prevKeys.slice(0, index + 1)
                      : prevKeys;
                  });

                  setTabBreadcrumbs((prev) => prev.slice(0, index + 1));
                } else {
                  setTabBreadcrumbs((prev) => prev.slice(0, index + 1));
                }
              }}
            >
              {getNodeIcon(
                breadcrumb.title === "Suppliers"
                  ? "SupplierTree"
                  : breadcrumb.apiArgs.type,
                isLast && overflowedBreadcrumbs.length === 0
                  ? "active"
                  : "inactive"
              )}
              {breadcrumb.title}
            </span>
            {!isLast && (
              <RightOutlined
                style={{
                  margin: "0 8px",
                  fontSize: 12,
                  color: "var(--color-solid-darkergrey)",
                }}
              />
            )}
          </span>
        );
      })}
      {overflowedBreadcrumbs.length > 0 && (
        <Dropdown
          menu={{
            items: renderDropdown(overflowedBreadcrumbs),
          }}
          trigger={["hover"]}
        >
          <a onClick={(e) => e.preventDefault()} style={{ cursor: "pointer" }}>
            <Space style={{ fontSize: 12, fontWeight: 600 }}>
              <RightOutlined
                style={{
                  margin: "0 5px 0 8px",
                  fontSize: 12,
                  color: "var(--color-solid-darkergrey)",
                }}
              />
              More
              <DownOutlined style={{ fontSize: 13 }} />
            </Space>
          </a>
        </Dropdown>
      )}
    </div>
  );
};

export default Breadcrumbs;
