import React, { useState, useEffect } from "react";
import { Image, Layout, Card } from "antd"; //antd components
import { useParams } from "react-router-dom";
import "../assets/style/contract.css";
import { getContractBreadcrumbsApi } from "../api/contractsApi";
import link_off from "../assets/images/icons/link_off.svg";
import { HeaderChangerAtom } from "../store/store";
import { useRecoilState } from "recoil";
import CustomLoader from "../components/CustomLoader";
import ContractController from "../components/Contract/ContractController";
import { convertBreadcrumbs } from "../utils/common";

function ApprovalPage() {
  // State variable to hold the select contract data
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  // Variable to hold the contract id and expiry time
  const { contractId, expiry_time } = useParams();
  // State variable to hold expired mes visibility status
  const [expiredMsg, setExpiredMsg] = useState(false);
  // State variable to hold button loader visibility status
  const [loader, setLoader] = useState(true);
  // Variable to hold todat date object
  let myDate = new Date();
  // Convert the Date object (myDate) to Unix timestamp by dividing its time in milliseconds by 1000
  const unixTimestamp = Math.floor(myDate.getTime() / 1000);
  //Get top header details
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);

  //Setting top header to show "Contract Pipeline" menu
  useEffect(() => {
    setHeaderChanger({
      header: "contract",
      headerItem: "2",
      headerItemName: headerChanger.headerItemName,
    });
  }, []);

  //Function called when change the contractid then get the selected contract information
  useEffect(() => {
    if (expiry_time <= unixTimestamp) {
      setExpiredMsg(true);
      setLoader(false);
    }
    getContractBreadcrumbsApi(contractId).then((res) => {
      console.log("res", res);
      const processedBreadcrumbs = convertBreadcrumbs(res);
      setBreadcrumbs(processedBreadcrumbs);
      setLoader(false);
    });
  }, [contractId]);

  return (
    <div className="contract">
      <Layout className="header-layout">
        {!expiredMsg && !loader && breadcrumbs.length !== 0 && (
          <div className="approval-link-page">
            <ContractController
              mode={{ view: true, edit: false, create: false }}
              breadcrumbs={breadcrumbs}
              location={"createContractForm"}
            />
          </div>
        )}

        {loader && <CustomLoader />}

        {expiredMsg && !loader && (
          <div className="auth-bg auth-page-bg">
            <Card className="auth-card p-30">
              <div>
                <Image src={link_off} preview={false} />
              </div>
              <div className="regenerate-msg mt-10">Link Expired</div>
              <div className="mt-20">
                The link is valid for only 24 hours. Please contact admin for
                further details.
              </div>
            </Card>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default ApprovalPage;
