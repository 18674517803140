import { axiosInstance } from "../utils/common";

export const getFileApi = (updateBody) => {
  return axiosInstance
    .post(
      `v1/common/get/files`,
      updateBody
      // {
      // responseType: "blob",
      // }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const downloadFileApi = (updateBody) => {
  return axiosInstance
    .post(`v1/common/download/file`, updateBody, {
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const deleteS3FileApi = (updateBody) => {
  return axiosInstance
    .post(`v1/common/delete/file`, updateBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateNotesCommentsApi = (moduleId, updatedCommentsBody, flag) => {
  return axiosInstance
    .post(`v1/common/comments/${moduleId}/${flag}`, updatedCommentsBody, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
