import { axiosInstance } from "../utils/common";

// get contract metadata by ID
export const getActionByContractApi = (contractId, page, limit) => {
  return axiosInstance
    .get(
      `v1/actionManagement/contract-action/get-by-contract/${contractId}?limit=${limit}&page=${page}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleActionDataApi = (actionId) => {
  return axiosInstance
    .get(`v1/actionManagement/single/${actionId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// get supplier metadata by ID
export const getActionBySupplierApi = (supplierId, page, limit) => {
  return axiosInstance
    .get(
      `v1/actionManagement/contract-action/get-by-supplier/${supplierId}?limit=${limit}&page=${page}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllActionApi = () => {
  return axiosInstance
    .get("v1/actionManagement")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllActionPaginationApi = (page, limit, sortColumn) => {
  return axiosInstance
    .post(`v1/actionManagement/all/pagination?limit=${limit}&page=${page}`, {
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createRootActionAPi = (actionDetails) => {
  return axiosInstance
    .post("v1/actionManagement", actionDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editRootActionAPi = (actionId, actionDetails) => {
  return axiosInstance
    .patch(`v1/actionManagement/${actionId}`, actionDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const gettRootActionByUser = (userId) => {
  return axiosInstance
    .get(`v1/actionManagement/get/by-user/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
