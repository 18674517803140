import React, { useState, useEffect } from "react";
import TopHeader from "../../Header";
import { HeaderChangerAtom } from "../../../store/store";
import { useRecoilState } from "recoil";
import {
  Layout,
  Row,
  Col,
  Image,
  Card,
  Form,
  Input,
  Space,
  Spin,
  Modal,
  Select,
  Upload,
  message,
} from "antd"; //antd components
import { useNavigate, useLocation } from "react-router-dom";
import {
  CancelButton,
  PreviewButton,
  SubmitButton,
  SaveButton,
  UploadButton,
  BrowseButton,
  NextButton,
  OnlineButton,
} from "../../GlobalButton";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import { validateFileSize } from "../../../components/FileUploadUtils";
import close from "../../../assets/images/icons/close.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import edit_temp from "../../../assets/images/icons/edit_temp.svg";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import info from "../../../assets/images/icons/info.svg";
import { updateTemplateApi } from "../../../api/contractAuthorityApi";
import { userDataAtom } from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import UploadPreview from "./UploadPreview";
import { getAllMsaApi } from "../../../api/msaApi";
import { getAllowedSupplierList } from "../../../api/suppliersApi";
import CustomLoader from "../../CustomLoader";
import { createActivityLogApi } from "../../../api/logApi";

const EditTemplate = () => {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const menuKey = "5";
  const navigate = useNavigate();
  const userData = useRecoilState(userDataAtom); //global state
  const popupModalInstance = PopupModal();
  const [tableLoader, setTableLoader] = useState(false);
  let { state } = useLocation();
  const State = {
    button: 1,
  };
  console.log("state", state);
  let header = headerChanger.header;
  const [headerEditIndex, setHeaderEditIndex] = useState(null);
  const [paragraphEditIndex, setParagraphEditIndex] = useState(null);
  const [loader, setLoader] = useState(true);
  const [templateDesciption, setTemplateDescription] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [buttonState, setButtonState] = useState(0);
  const { TextArea } = Input;
  const [browseFile, setBrowseFile] = useState(false);
  const [editTemplateFile, setEditTemplateFile] = useState(true);
  const [uploadPreview, setUploadPreview] = useState(false);
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [showBrowseFile, setShowBrowseFile] = useState(true);
  const [TemplateFile, setTemplateFile] = useState();
  // const [templateMetaData, setTemplateMetaData] = useState([]);
  const [initialClauseContent, setInitialClauseContent] = useState(
    state.clause_content ? state.clause_content : []
  );

  useEffect(() => {
    initialClauseContent.length > 0 ? setLoader(false) : setLoader(true);
  }, [initialClauseContent]);

  const [templateMetaData, setTemplateMetaData] = useState(
    state.clause_content ? state.clause_content : []
  );
  const [msaData, setMsaData] = useState([]);
  const [form] = Form.useForm();
  const [supplierData, setSupplierData] = useState([]);
  const [entityField, setEntityField] = useState("upload");
  const [msaName, setMsaName] = useState(state.msa_name && state.msa_name);
  const [supplierName, setSupplierName] = useState(
    state.supplier_name && state.supplier_name
  );
  const [templateField, setTemplateField] = useState({
    msa: state.msa && state.msa,
    contract_template_name:
      state.contract_template_name && state.contract_template_name,
    supplier_id: state.supplier_id && state.supplier_id,
    // supplier_name: state.supplier_name && state.supplier_name,
    template_description:
      state.template_description && state.template_description,
  });
  console.log("templateField", templateField);
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  const [clauseContent, setClauseContent] = useState(
    state.clause_content ? state.clause_content : []
  );
  const editHeader = (index) => {
    setHeaderEditIndex(index);
    setParagraphEditIndex(null);
  };

  const editParagraph = (index) => {
    setParagraphEditIndex(index);
    setHeaderEditIndex(null);
  };
  // const deleteTemplateMeta = (index) => {
  //   const tempData = clauseContent.filter((_, i) => i !== index); // Remove the item at the given index
  //   console.log("tampData **   ", tempData);
  //   setClauseContent(tempData);
  // };
  useEffect(() => {
    console.log("initialClauseContent", initialClauseContent);
    console.log("clauseContent", clauseContent);
  }, [initialClauseContent, clauseContent]);

  const deleteTemplateMeta = (index) => {
    const tempData = clauseContent
      .filter((_, i) => i !== index) // Remove the item at the given index
      .map((element, newIndex) => ({
        ...element,
        h: `${newIndex + 1}. ${element.h.split(". ")[1]}`,
      }));

    console.log("tempData **   ", tempData);
    setClauseContent(tempData);
  };

  const handleEdit = (editedIndex, key, event) => {
    const updatedValue = event.target ? event.target.value : event; // Determine if it's an Input or ReactQuill change
    let updatedArray;

    // Update clauseContent if it has data
    if (clauseContent.length > 0) {
      updatedArray = clauseContent.map((data, index) => {
        if (index === editedIndex) {
          return {
            ...data,
            [key]: updatedValue, // Update the appropriate field with the new value
          };
        }
        return data;
      });
      setClauseContent(updatedArray);
    }

    // Update initialClauseContent if it has data
    if (initialClauseContent.length > 0) {
      updatedArray = initialClauseContent.map((data, index) => {
        if (index === editedIndex) {
          return {
            ...data,
            [key]: updatedValue, // Update the appropriate field with the new value
          };
        }
        return data;
      });
      setInitialClauseContent(updatedArray);
    }
  };

  const handleNext = () => {
    setShowBrowseFile(false);
    setBrowseFile(true);
  };

  //OnFinish
  const onFinish = (values) => {
    Object.keys(templateField).forEach((key) => {
      // If the value is empty or undefined, replace it with "null"
      if (!templateField[key]) {
        templateField[key] = "null";
      }
    });
    values = {
      ...templateField,
      clause_content:
        initialClauseContent.length > 0 ? initialClauseContent : clauseContent,
      updated_on: new Date(),
      updated_by: userData[0].id,
    };
    // values["msa_name"] = msaName;
    // values["supplier_name"] = supplierName;
    console.log("valuess", values);
    if (State.button === 1) {
      values["status"] = "Draft";
      updateTemplateApi(state.id ? state.id : state._id, values)
        .then((res) => {
          console.log("resss", res);
          let ssLog = {
            ss_id: state.id ? state.id : state._id,
            ss_name: values.contract_template_name,
            code: "template",
            author: userData[0].id,
            action: "edit",
            notes: "saved contract template",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          popupModalInstance.successModal(
            "",
            ModalMessage.TEM_Edit_Info,
            "/contract_authority/template"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (State.button === 2) {
      values["status"] = "Active";
      popupModalInstance
        .infoModal(ModalMessage.TEM_Edit_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            console.log("Submiting values", values);
            await updateTemplateApi(state.id ? state.id : state._id, values)
              .then((res) => {
                let ssLog = {
                  ss_id: state.id ? state.id : state._id,
                  ss_name: values.contract_template_name,
                  code: "template",
                  author: userData[0].id,
                  action: "edit",
                  notes: "submited contract template",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                popupModalInstance.successModal(
                  "",
                  ModalMessage.TEM_Edit_Info,
                  "/contract_authority/template"
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (userChoice === "cancel") {
            setTableLoader(false);
          }
        });
    } else if (State.button === 3) {
      values["clause_content"] =
        initialClauseContent.length > 0 ? initialClauseContent : clauseContent;
      values["viewTemp"] = false;
      values["_id"] = state.id ? state.id : state._id;
      values["status"] = state.status;
      console.log("values", values);

      navigate("/contract_authority/template/edit-preview", {
        // state: {
        //   // pass your state here
        //   // _id : state.
        //   contract_template_name: templateField.contract_template_name,
        //   clause_content: clauseContent,
        //   viewTemp: false, // set to true if you want to view the template
        // },
        state: {
          ...values,
        },
      });
    }
  };

  const onChangeSupplier = (key, value) => {
    console.log("Value: ", value);
    form.setFieldsValue({ msa: null });
    if (typeof value == "undefined") {
      form.setFieldsValue({ msa: null });
      setMsaName(null);
      setTemplateField({
        ...templateField,
        supplier_id: null,
        msa: null,
      });
      setMsaData([]); // Clear MSA data
    } else {
      setTemplateField({
        ...templateField,
        supplier_id: value.key,
      });
      setSupplierName(value.value);
      getAllMsaApi().then((res) => {
        let filteredMsa = res.filter((data) => data.supplier_id === value.key);
        setMsaData(filteredMsa);
      });
    }
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  useEffect(() => {
    getAllowedSupplierList(userData[0].id).then((res) => {
      setSupplierData(res);

      if (state.supplier_id) {
        getAllMsaApi().then((res) => {
          let filteredMsa = res.filter(
            (data) => data.supplier_id === state.supplier_id
          );
          console.log("filteredMsa", filteredMsa);
          setMsaData(filteredMsa);
        });
      }
    });
  }, []);

  const onChangeMsa = (key, value) => {
    console.log("value", value);
    console.log("key", key);
    if (typeof value === "undefined") {
      // Clear the MSA field when value is undefined
      form.setFieldsValue({ msa: null });
      setMsaName(null);
      setTemplateField({
        ...templateField,
        msa: null,
      });
    } else {
      setTemplateField({
        ...templateField,
        msa: key,
      });
      setMsaName(value.label);
    }
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
    setTemplateField({
      ...templateField,
      contract_template_name: e.target.value,
    });
  };
  const handleTemplateDescChange = (e) => {
    setTemplateDescription(e.target.value);
    setTemplateField({
      ...templateField,
      template_description: e.target.value,
    });
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const props = {
    name: "file",
    listType: "picture",
    accept: ["pdf"],
    maxCount: 1,
    // onRemove() {
    //   setTableData([]);
    // },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length !== 0) {
          setTemplateFile(info.file);
        } else {
          setTemplateFile();
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setTemplateFile(info.file);
        } else {
          setTemplateFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      if (!validateFileSize(file)) {
        return Upload.LIST_IGNORE;
      }
      setConfirmationModel(false);
      const acceptedFormats = ["pdf"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        popupModalInstance.errorModal(ModalMessage.Workflow_Excel_Upload_Error);
      } else {
        popupModalInstance
          .infoModal(ModalMessage.CAC_Temp_Info)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              setBrowseFile(true);
              setShowBrowseFile(false);
              // setDraftTemplate(false);

              const formData = new FormData();
              formData.append("file", file);
              console.log("FormDatavalue", formData);
              try {
                const response = fetch(
                  "https://g2d1ry31gh.execute-api.ap-south-1.amazonaws.com/v1/extract",
                  {
                    method: "post",
                    headers: {
                      "Content-Type": "application/pdf",
                    },
                    body: formData,
                  }
                )
                  .then(async (response) => {
                    console.log("response", response);

                    let data = await response.json();
                    let setData = JSON.parse(data.body);

                    // Log data before filtering
                    console.log("Data before filtering", setData);

                    // Filter out headers that are empty or fewer than 5 characters
                    setData = setData.filter(
                      (item) => item.h && item.h.trim().length >= 5
                    );

                    // Log data after filtering
                    console.log("Data after filtering", setData);

                    // Update the state with filtered data
                    setTemplateMetaData(setData);
                    setInitialClauseContent(setData);
                    setLoader(false);
                  })
                  .catch((error) => {
                    console.error("Something went wrong!", error);
                    message.error(
                      "An error occurred while processing the response"
                    );
                  });
              } catch (error) {
                console.error("Error uploading file:", error);
                message.error("An error occurred while uploading the file");
              }
            }
          });
        // setUploadRule(false);
        // setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    console.log("editTemplateFile", editTemplateFile);
    console.log("browseFile", browseFile);
  }, [editTemplateFile, browseFile]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {editTemplateFile && (
        <Form onFinish={onFinish} onKeyDown={handleKeyDown} form={form}>
          {state && state.status === "Draft" ? (
            <div>
              {showBrowseFile ? (
                <div className="system-setup1">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[8, 16]}
                    className="m-10"
                  >
                    <Col
                      className="vertical-align"
                      xs={24}
                      sm={16}
                      md={18}
                      lg={18}
                      xl={22}
                    >
                      <Card className="setup-header-card">
                        <Row type="flex" gutter={[8, 16]}>
                          <Col
                            className="vertical-align"
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            xl={2}
                          >
                            <Row type="flex" gutter={[8, 16]}>
                              <Col span={12}>
                                <Image
                                  src={arrow_left}
                                  style={{ width: 25 }}
                                  preview={false}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(`/contract_authority/template`)
                                  }
                                />
                              </Col>
                              <Col span={12}>
                                <Image
                                  src={arrow_right_disable}
                                  style={{ width: 20 }}
                                  preview={false}
                                  className="cursor-not-allowed"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            className="vertical-align"
                            xs={17}
                            sm={17}
                            md={19}
                            lg={19}
                            xl={22}
                          >
                            <span>Contract Authority</span> &nbsp; &nbsp;
                            <Image src={arrow_back} preview={false} /> &nbsp;
                            &nbsp;
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(`/contract_authority/template`)
                              }
                            >
                              Template
                            </span>
                            &nbsp; &nbsp;
                            <Image src={arrow_back} preview={false} /> &nbsp;
                            &nbsp;
                            <span>Edit Template</span>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    {state.clause_content && state.clause_content.length < 1 ? (
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <SaveButton
                          type="primary"
                          htmlType="submit"
                          onClick={() => (State.button = 1)}
                          disabled={state.status === "Active" ? true : false}
                        />
                      </Col>
                    ) : (
                      <Col
                        className="vertical-align"
                        xs={8}
                        sm={4}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <NextButton
                          type="primary"
                          name="next-btn"
                          onClick={handleNext}
                          // onClick={() => setBrowseFile(true)}
                        />
                      </Col>
                    )}
                    <div className="price-profile-content">
                      <Row
                        align="middle"
                        type="flex"
                        gutter={[8, 16]}
                        className="m-0"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row>
                            <Col span={24}>
                              <div className="create-supplier-div">
                                <div className="m-20">
                                  <Row align="middle" type="flex">
                                    <Col span={24}>
                                      <Row>
                                        <Col span={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="template_name"
                                            label="TEMPLATE NAME"
                                            initialValue={
                                              templateField.contract_template_name
                                            }
                                            colon={false}
                                            rules={[
                                              {
                                                message:
                                                  "Please enter template name",
                                                pattern: /^(?!\s*$).+/,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter your title"
                                              onChange={
                                                handleTemplateNameChange
                                              }
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="template_description"
                                            label="ENTER TEMPLATE DESCRIPTION"
                                            initialValue={
                                              templateField.template_description !==
                                              "null"
                                                ? templateField.template_description
                                                : ""
                                            }
                                            colon={false}
                                            rules={[
                                              {
                                                message:
                                                  "Please enter template description",
                                                pattern: /^(?!\s*$).+/,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter your Description"
                                              onChange={
                                                handleTemplateDescChange
                                              }
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="supplier name"
                                        label="SUPPLIER NAME"
                                        initialValue={supplierName}
                                        colon={false}
                                      >
                                        <Select
                                          allowClear
                                          onChange={(key, value) =>
                                            onChangeSupplier(key, value)
                                          }
                                          showSearch={true}
                                          placeholder="Choose Supplier"
                                          options={
                                            supplierData &&
                                            supplierData.map((data) => ({
                                              key: data._id,
                                              label: data.supplier_name,
                                              value: data.supplier_name,
                                            }))
                                          }
                                        ></Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="msa"
                                        label="FRAMEWORK AGREEMENT / MSA"
                                        initialValue={msaName}
                                        colon={false}
                                      >
                                        <Select
                                          allowClear
                                          onChange={(key, value) =>
                                            onChangeMsa(key, value)
                                          }
                                          showSearch={true}
                                          placeholder="Choose MSA"
                                          options={
                                            msaData &&
                                            msaData.map((data) => ({
                                              key: data.id,
                                              label: data.contract_name,
                                              value: data.id,
                                            }))
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                                {entityField === "upload" && (
                                  <>
                                    <Row
                                      align="middle"
                                      gutter={[40, 16]}
                                      justify="center"
                                      className="mt-25"
                                    >
                                      <Col
                                        span={8}
                                        align="right"
                                        className="vertical-align"
                                      >
                                        <div className="choose-input-div">
                                          <Form.Item>
                                            <Row type="flex" gutter={[64, 56]}>
                                              <Col
                                                span={24}
                                                align="middle"
                                                className="vertical-align"
                                              >
                                                <span className="upload-note">
                                                  Click below to enter Template
                                                  Details through Uploading
                                                </span>
                                              </Col>
                                              <Col
                                                span={24}
                                                align="middle"
                                                className="vertical-align"
                                              >
                                                <Col
                                                  span={10}
                                                  style={{ marginRight: 5 }}
                                                >
                                                  <UploadButton
                                                    className="template-upload"
                                                    type="primary"
                                                    onClick={() =>
                                                      setConfirmationModel(true)
                                                    }
                                                    disabled={
                                                      !(
                                                        templateField.contract_template_name &&
                                                        templateField.supplier_id &&
                                                        templateField.supplier_id !==
                                                          "null" &&
                                                        state.clause_content &&
                                                        state.clause_content
                                                          .length < 1
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </Col>
                                              <Col span={24} align="middle">
                                                <Upload
                                                  customRequest={dummyRequest}
                                                ></Upload>
                                              </Col>
                                              <Col
                                                span={24}
                                                className="vertical-align"
                                                align="middle"
                                              ></Col>
                                            </Row>
                                          </Form.Item>
                                        </div>
                                      </Col>
                                      {confirmationModel && (
                                        <Modal
                                          centered
                                          open={confirmationModel}
                                          onOk={() =>
                                            setConfirmationModel(false)
                                          }
                                          onCancel={() => {
                                            setConfirmationModel(false);
                                          }}
                                          width={450}
                                          footer={false}
                                        >
                                          <Space align="">
                                            <div>
                                              <h2>Select Document File</h2>
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                Select the File to Show in
                                                Template
                                              </span>
                                            </div>
                                          </Space>

                                          <Row
                                            align="middle"
                                            type="flex"
                                            gutter={[40, 4]}
                                          >
                                            <Col span={24}>
                                              <Row gutter={[16, 8]}>
                                                <Col span={24}>
                                                  <Form.Item
                                                    labelCol={{ span: 24 }}
                                                  >
                                                    <TextArea rows={3} />
                                                  </Form.Item>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>

                                          <Row className="mt-15">
                                            <Col
                                              span={24}
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "end",
                                              }}
                                            >
                                              <Col span={8}>
                                                <div className="main-test">
                                                  <Upload
                                                    {...props}
                                                    customRequest={dummyRequest}
                                                    className="contract_authority-upload-wrapper"
                                                  >
                                                    <Col
                                                      span={24}
                                                      align="middle"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        alignItems: "end",
                                                      }}
                                                    >
                                                      <Col
                                                        span={24}
                                                        style={{
                                                          marginRight: 5,
                                                        }}
                                                      >
                                                        <BrowseButton />
                                                      </Col>
                                                    </Col>
                                                  </Upload>
                                                </div>
                                              </Col>
                                              <Col span={8}>
                                                <CancelButton
                                                  onClick={() =>
                                                    setConfirmationModel(false)
                                                  }
                                                />
                                              </Col>
                                            </Col>
                                          </Row>
                                        </Modal>
                                      )}

                                      <Col
                                        span={8}
                                        align="right"
                                        className="vertical-align"
                                      >
                                        <div className="choose-input-div">
                                          <Form.Item>
                                            <Row type="flex" gutter={[64, 56]}>
                                              <Col
                                                span={24}
                                                align="middle"
                                                className="vertical-align"
                                              >
                                                <span className="upload-note">
                                                  Click below to enter Template
                                                  Details through Online mode
                                                </span>
                                              </Col>
                                              <Col
                                                span={24}
                                                align="middle"
                                                className="vertical-align"
                                              >
                                                <Col
                                                  span={10}
                                                  style={{ marginRight: 5 }}
                                                >
                                                  <OnlineButton
                                                    className="template-upload"
                                                    type="primary"
                                                    disabled={
                                                      !(
                                                        templateField.contract_template_name &&
                                                        templateField.supplier_id &&
                                                        templateField.supplier_id !==
                                                          "null" &&
                                                        state.clause_content &&
                                                        state.clause_content
                                                          .length < 1
                                                      )
                                                    }
                                                    onClick={() =>
                                                      navigate(
                                                        `/contract_authority/online/template`,
                                                        {
                                                          state: {
                                                            ...templateField,
                                                            status:
                                                              state.status,
                                                            _id: state._id,
                                                            clause_content:
                                                              state.clause_content,
                                                            supplier_name:
                                                              supplierName,
                                                            msa_name: msaName,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </Col>
                                              <Col span={24} align="middle">
                                                <Upload
                                                  customRequest={dummyRequest}
                                                ></Upload>
                                              </Col>
                                              <Col
                                                span={24}
                                                className="vertical-align"
                                                align="middle"
                                              ></Col>
                                            </Row>
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </div>
              ) : (
                <div>
                  {browseFile && (
                    <Layout className="header-layout">
                      <div className="system-setup1">
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[8, 16]}
                          className="m-10"
                        >
                          <Col
                            className="vertical-align"
                            xs={24}
                            sm={16}
                            md={18}
                            lg={18}
                            xl={18}
                          >
                            <Card className="setup-header-card">
                              <Row type="flex" gutter={[8, 16]}>
                                <Col
                                  className="vertical-align"
                                  xs={6}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  xl={2}
                                >
                                  <Row type="flex" gutter={[8, 16]}>
                                    <Col span={12}>
                                      <Image
                                        src={arrow_left}
                                        style={{ width: 25 }}
                                        preview={false}
                                        className="cursor-pointer"
                                        onClick={() => {
                                          // navigate(
                                          //   `/contract_authority/edit/template`
                                          // );
                                          setShowBrowseFile(true);
                                          setBrowseFile(false);
                                        }}
                                      />
                                    </Col>
                                    <Col span={12}>
                                      <Image
                                        src={arrow_right_disable}
                                        style={{ width: 20 }}
                                        preview={false}
                                        className="cursor-not-allowed"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  className="vertical-align"
                                  xs={17}
                                  sm={17}
                                  md={19}
                                  lg={19}
                                  xl={22}
                                >
                                  <span>Contract Authority</span> &nbsp; &nbsp;
                                  <Image
                                    src={arrow_back}
                                    preview={false}
                                  />{" "}
                                  &nbsp; &nbsp;
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      navigate(
                                        `/contract_authority/edit/template`
                                      );
                                      setShowBrowseFile(true);
                                      setBrowseFile(false);
                                    }}
                                  >
                                    Templates
                                  </span>
                                  &nbsp; &nbsp;
                                  <Image
                                    src={arrow_back}
                                    preview={false}
                                  />{" "}
                                  &nbsp; &nbsp;
                                  <span>Edit Templates</span>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col
                            className="vertical-align"
                            xs={8}
                            sm={4}
                            md={3}
                            lg={3}
                            xl={2}
                          >
                            <CancelButton
                              onClick={() => {
                                navigate(`/contract_authority/template`);
                                // setShowBrowseFile(true);
                                // setBrowseFile(false);
                              }}
                            />
                          </Col>
                          <Col
                            className="vertical-align"
                            xs={8}
                            sm={4}
                            md={3}
                            lg={3}
                            xl={2}
                          >
                            <SaveButton
                              type="primary"
                              htmlType="submit"
                              onClick={() => (State.button = 1)}
                            />
                          </Col>
                          <Col
                            className="vertical-align"
                            xs={8}
                            sm={4}
                            md={3}
                            lg={3}
                            xl={2}
                          >
                            <PreviewButton
                              disabled={loader}
                              type="primary"
                              htmlType="submit"
                              onClick={() => (State.button = 3)}
                            />
                          </Col>
                          {/* <Col
                          className="vertical-align"
                          xs={8}
                          sm={4}
                          md={3}
                          lg={3}
                          xl={2}
                        >
                          <SubmitButton
                            type="primary"
                            htmlType="submit"
                            onClick={() => (State.button = 2)}
                          />
                        </Col> */}
                        </Row>
                        {!loader && (
                          <div className="price-profile-content m-10">
                            <Row
                              align="middle"
                              type="flex"
                              gutter={[8, 16]}
                              className="m-0"
                            >
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row>
                                  <Col span={24}>
                                    <div className="create-supplier-div">
                                      <Row align="middle" gutter={[40, 16]}>
                                        <Col
                                          span={9}
                                          className="vertical-align"
                                        >
                                          <div className="org-temp-div mt-10">
                                            <Space align="">
                                              <div
                                                style={{
                                                  marginTop: "10px",
                                                  paddingLeft: "20px",
                                                }}
                                              >
                                                <Row>
                                                  <Col>
                                                    <h3
                                                      style={{
                                                        color: "#2684ff",
                                                      }}
                                                    >
                                                      Extracted Clause Headers
                                                    </h3>
                                                  </Col>
                                                </Row>
                                                <Image
                                                  src={info}
                                                  preview={false}
                                                  style={{
                                                    padding: "0px 5px 0px 0px",
                                                  }}
                                                ></Image>
                                                <span
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Select to view the Contents,
                                                  Re-arrange the headers, as
                                                  required
                                                </span>
                                              </div>
                                            </Space>
                                            <hr className="mt-10 view-supplier-hr" />
                                            {initialClauseContent.map(
                                              (item, index) => (
                                                <Form.Item key={index}>
                                                  <div key={index}>
                                                    {/* Wrap each Input in a separate div */}
                                                    <Row
                                                      type="flex"
                                                      align="middle"
                                                      className="template-header"
                                                    >
                                                      <Col
                                                        span={21}
                                                        className="vertical-align"
                                                      >
                                                        <Input
                                                          className="templateSetup-2"
                                                          disabled={
                                                            index !==
                                                            headerEditIndex
                                                          } // Disable unless in edit mode
                                                          value={item.h}
                                                          onChange={(e) =>
                                                            handleEdit(
                                                              index,
                                                              "h",
                                                              e
                                                            )
                                                          } // Handle header edit
                                                        />
                                                      </Col>
                                                      <Col
                                                        span={3}
                                                        className="vertical-align"
                                                      >
                                                        <Space>
                                                          {/* Edit Header Button */}
                                                          <Image
                                                            className="cursor-pointer"
                                                            src={edit_temp}
                                                            preview={false}
                                                            onClick={() =>
                                                              editHeader(index)
                                                            } // Trigger header editing
                                                          />
                                                          {/* Delete Button */}
                                                          <Image
                                                            className="cursor-pointer"
                                                            src={close}
                                                            preview={false}
                                                            onClick={() =>
                                                              deleteTemplateMeta(
                                                                index
                                                              )
                                                            } // Handle delete
                                                          />
                                                        </Space>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Form.Item>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                        <Col
                                          span={15}
                                          className="vertical-align"
                                        >
                                          <div className="org-temp-div mt-10">
                                            <Space align="">
                                              <div
                                                style={{
                                                  marginTop: "10px",
                                                  paddingLeft: "20px",
                                                }}
                                              >
                                                <h3
                                                  style={{ color: "#2684ff" }}
                                                >
                                                  Clause Content
                                                </h3>
                                                <Image
                                                  src={info}
                                                  preview={false}
                                                  style={{
                                                    padding: "0px 5px 0px 0px",
                                                  }}
                                                ></Image>
                                                <span
                                                  style={{ fontSize: "11px" }}
                                                >
                                                  Select the required content
                                                </span>
                                              </div>
                                            </Space>
                                            <hr className="mt-10 view-supplier-hr" />
                                            {initialClauseContent.map(
                                              (data, index) => {
                                                return (
                                                  <div key={index}>
                                                    <Row
                                                      type="flex"
                                                      align="middle"
                                                      className="mt-15"
                                                    >
                                                      <Col
                                                        span={23}
                                                        className="vertical-align"
                                                      >
                                                        <Input
                                                          style={{
                                                            border: "none",
                                                            fontWeight: 600,
                                                          }}
                                                          readOnly
                                                          value={data.h} // Clause header
                                                          onChange={(e) =>
                                                            handleEdit(
                                                              index,
                                                              "h",
                                                              e
                                                            )
                                                          } // Existing header edit logic
                                                        />
                                                      </Col>
                                                      <Col
                                                        span={1}
                                                        className="vertical-align float-right"
                                                      >
                                                        <Image
                                                          src={edit_temp}
                                                          preview={false}
                                                          className="cursor-pointer"
                                                          onClick={() =>
                                                            editParagraph(index)
                                                          }
                                                        />
                                                      </Col>
                                                    </Row>

                                                    {/* Conditionally render ReactQuill when the paragraph is being edited */}
                                                    {index ===
                                                    paragraphEditIndex ? (
                                                      <ReactQuill
                                                        id="min-quill"
                                                        theme="snow"
                                                        value={data.p || ""}
                                                        onChange={(content) =>
                                                          handleEdit(
                                                            index,
                                                            "p",
                                                            {
                                                              target: {
                                                                value: content,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        modules={{
                                                          toolbar:
                                                            toolbarOptions,
                                                        }}
                                                      />
                                                    ) : (
                                                      <Input
                                                        className="template-setup2"
                                                        disabled={
                                                          index !==
                                                          paragraphEditIndex
                                                        } // Disable unless in edit mode
                                                        value={data.p} // Paragraph content as plain text
                                                        onChange={(e) =>
                                                          handleEdit(
                                                            index,
                                                            "p",
                                                            e
                                                          )
                                                        } // Update paragraph content
                                                      />
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {loader && <CustomLoader />}
                      </div>
                    </Layout>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="system-setup1">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={18}
                  lg={18}
                  xl={18}
                >
                  <Card className="setup-header-card">
                    <Row type="flex" gutter={[8, 16]}>
                      <Col
                        className="vertical-align"
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={2}
                      >
                        <Row type="flex" gutter={[8, 16]}>
                          <Col span={12}>
                            <Image
                              src={arrow_left}
                              style={{ width: 25 }}
                              preview={false}
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(`/contract_authority/template`)
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <Image
                              src={arrow_right_disable}
                              style={{ width: 20 }}
                              preview={false}
                              className="cursor-not-allowed"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={17}
                        sm={17}
                        md={19}
                        lg={19}
                        xl={22}
                      >
                        <span>Contract Authority</span> &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`/contract_authority/template`)
                          }
                        >
                          Template
                        </span>
                        &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span>
                          {state.contract_template_name
                            ? state.contract_template_name
                            : "Edit Template"}
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <CancelButton
                    onClick={() => navigate(`/contract_authority/template`)}
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SaveButton
                    type="primary"
                    htmlType="submit"
                    onClick={() => (State.button = 1)}
                    disabled={state.status === "Active" ? true : false}
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <PreviewButton
                    disabled={loader}
                    type="primary"
                    htmlType="submit"
                    onClick={() => (State.button = 3)}
                  />
                </Col>
                {/* <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SubmitButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => (State.button = 2)}
                />
              </Col> */}
              </Row>
              <div className="price-profile-content m-10">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={24}>
                        <div className="create-supplier-div">
                          <Row align="middle" gutter={[40, 16]}>
                            <Col span={9} className="vertical-align">
                              <div className="org-temp-div mt-10">
                                <Space align="">
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Row>
                                      <Col>
                                        <h3
                                          style={{
                                            color: "#2684ff",
                                          }}
                                        >
                                          Extracted Clause Headers
                                        </h3>
                                      </Col>
                                    </Row>
                                    <Image
                                      src={info}
                                      preview={false}
                                      style={{
                                        padding: "0px 5px 0px 0px",
                                      }}
                                    ></Image>
                                    <span
                                      style={{
                                        fontSize: "11px",
                                      }}
                                    >
                                      Select to view the Contents, Re-arrange
                                      the headers, as required
                                    </span>
                                  </div>
                                </Space>
                                <hr className="mt-10 view-supplier-hr" />

                                {clauseContent.map((item, index) => (
                                  <Form.Item key={index}>
                                    <div key={index}>
                                      {/* Wrap each Input in a separate div */}
                                      <Row
                                        type="flex"
                                        align="middle"
                                        className="template-header"
                                      >
                                        <Col
                                          span={21}
                                          className="vertical-align"
                                        >
                                          <Input
                                            className="templateSetup-2"
                                            disabled={index !== headerEditIndex}
                                            value={item.h || ""}
                                            onChange={(e) =>
                                              handleEdit(index, "h", e)
                                            } // Update the header content
                                            onBlur={() =>
                                              setHeaderEditIndex(null)
                                            } // Close header edit on blur
                                            autoFocus
                                          />
                                        </Col>
                                        <Col
                                          span={3}
                                          className="vertical-align"
                                        >
                                          <Space>
                                            <Image
                                              className="cursor-pointer"
                                              src={edit_temp}
                                              preview={false}
                                              onClick={() => editHeader(index)}
                                            />
                                            <Image
                                              className="cursor-pointer"
                                              src={close}
                                              preview={false}
                                              onClick={() =>
                                                deleteTemplateMeta(index)
                                              }
                                            />
                                          </Space>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Form.Item>
                                ))}
                              </div>
                            </Col>
                            <Col span={15} className="vertical-align">
                              <div className="org-temp-div mt-10">
                                <Space align="">
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <h3
                                      style={{
                                        color: "#2684ff",
                                      }}
                                    >
                                      Clause Content
                                    </h3>
                                    <Image
                                      src={info}
                                      preview={false}
                                      style={{
                                        padding: "0px 5px 0px 0px",
                                      }}
                                    ></Image>
                                    <span
                                      style={{
                                        fontSize: "11px",
                                      }}
                                    >
                                      Select the required content
                                    </span>
                                  </div>
                                </Space>
                                <hr className="mt-10 view-supplier-hr" />
                                <>
                                  {clauseContent.map((item, index) => (
                                    <div key={index}>
                                      <Row
                                        type="flex"
                                        align="middle"
                                        className="mt-15"
                                      >
                                        {/* Header Section */}
                                        <Col
                                          span={23}
                                          className="vertical-align"
                                        >
                                          {index === headerEditIndex ? (
                                            // Header is in edit mode
                                            <Input
                                              style={{
                                                border: "none",
                                                fontWeight: 600,
                                              }}
                                              value={item.h}
                                              onChange={(e) =>
                                                handleEdit(index, "h", e)
                                              }
                                            />
                                          ) : (
                                            // Display header normally
                                            <h3>{item.h || ""}</h3>
                                          )}
                                        </Col>
                                        <Col
                                          span={1}
                                          className="vertical-align float-right"
                                        >
                                          <Image
                                            src={edit_temp}
                                            preview={false}
                                            className="cursor-pointer"
                                            onClick={() => editParagraph(index)} // Trigger paragraph edit mode
                                          />
                                        </Col>
                                      </Row>

                                      {/* Paragraph Section */}
                                      <Row
                                        type="flex"
                                        align="middle"
                                        className="mt-15"
                                      >
                                        <Col span={24}>
                                          {index === paragraphEditIndex ? (
                                            // Paragraph is in edit mode
                                            <ReactQuill
                                              ref={(el) => el && el.focus()} // Focus on editor when it becomes editable
                                              className="templateSetup-1"
                                              theme="snow"
                                              value={item.p || ""}
                                              onChange={(content) =>
                                                handleEdit(index, "p", content)
                                              } // Update paragraph content
                                              modules={{
                                                toolbar: toolbarOptions,
                                              }}
                                            />
                                          ) : (
                                            // Display paragraph content
                                            <div
                                              className="templateSetup-1 read-only-content"
                                              dangerouslySetInnerHTML={{
                                                __html: item.p || "",
                                              }} // Display content as HTML
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Form>
      )}
      {uploadPreview && (
        <UploadPreview
          editState={{
            ...templateField,
            clause_content: templateMetaData,
            created_on: new Date(),
            updated_on: new Date(),
            created_by: userData[0].id,
            updated_by: userData[0].id,
          }}
          // showBrowseFile={setShowBrowseFile}
          editTemplateFile={setEditTemplateFile}
          uploadPreview={setUploadPreview}
          // browseFile={setBrowseFile}
        />
      )}
    </Layout>
  );
};

export default EditTemplate;
