import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../../Header";
import save_filter from "../../../assets/images/icons/save_filter.svg";
import {
  Image,
  Row,
  Col,
  Form,
  Input,
  Select,
  Layout,
  Table,
  Badge,
  Tabs,
  Upload,
  message,
} from "antd";
import axios from "axios";
import {
  LoadingOutlined,
  DownloadOutlined,
  UserOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  HeaderChangerAtom,
  userDataAtom,
  RoleAccessAtom,
} from "../../../store/store";
import { useRecoilState } from "recoil";
import "../../../assets/style/contractfile.css";
import attach from "../../../assets/images/icons/attach.svg";
import send from "../../../assets/images/icons/send.svg";
import { CancelButton, OkButton } from "../../GlobalButton";
import moment from "moment";
import {
  downloadFileApi,
  updateActionInstanceApi,
} from "../../../api/actionInstanceApi";
import { createActivityLogApi } from "../../../api/logApi";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { airflowUrl } from "../../../utils/request";
import { airflowCredential, dataTable } from "../../../utils/common";
import NotAuthorized from "../../NotAuthorized";
import DataTable from "../../Tables/DataTable";

const ViewActionInstance = () => {
  const popupModalInstance = PopupModal(); //global popup modal
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const history = useNavigate(); //react-router-dom navigate method
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  let { state } = useLocation(); //state data which is sent from parent component
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  const [tableNewData, setTableNewData] = useState([]);
  const [tableOldData, setTableOldData] = useState([]);
  const [actionFile, setActionFile] = useState();
  const [comments, setComments] = useState([]);
  const [oldComments, setOldComments] = useState([]);
  const [commentEntered, setCommentEntered] = useState("");
  const [saveTableLoader, setSaveTableLoader] = useState(false); //save loader on api integration
  console.log("state", state);
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [tabKey, setTabKey] = useState("1"); //store active tab key

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  let header = headerChanger.header;
  const menuKey = "6";
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const columns = [
    {
      title: "Document Name",
      dataIndex: "document_name",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Action Instance Status",
      dataIndex: "action_instance_status",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Doc Uploaded On",
      dataIndex: "doc_uploaded_on",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Doc Uploaded By",
      dataIndex: "doc_uploader_name",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
    },
  ];

  const onDownloadFile = async (fileKey, filename) => {
    console.log("filename", filename);
    try {
      const response = await downloadFileApi({ fileKey: fileKey });
      console.log("response file", response);
      // Create a temporary anchor element and trigger the download
      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      ); // Specify content type
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const onDownloadNewFile = async (fileInfo) => {
    console.log("fileInfo", fileInfo);
    // Extract necessary information
    const { name, originFileObj } = fileInfo;

    // Create a blob from the file object
    const blob = new Blob([originFileObj], { type: originFileObj.type });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = name;

    // Programmatically trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  useEffect(() => {
    const fileData =
      state &&
      state.action_data &&
      state.action_data.length > 0 &&
      state.action_data.map((item) => {
        let tableRow = {
          document_name: (
            <span
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
              }}
              onClick={() => {}}
            >
              {item.file_name}
            </span>
          ),
          action_instance_status: state && state.instance_status,
          doc_uploaded_on: moment(item.uploaded_on).format("DD MMM YYYY"),
          doc_uploader_name: item.author_name,
          doc_uploaded_by: item.uploaded_by,
          action: (
            <>
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                <DownloadOutlined
                  title="Download"
                  style={{
                    fontSize: "20px",
                    color: "var(--color-solid-darkgrey)",
                  }}
                  className="cursor-pointer"
                  onClick={() => {
                    onDownloadFile(item.file_name_on_s3, item.file_name);
                  }}
                />
              </div>
            </>
          ),
        };
        return tableRow;
      });
    setTableOldData(fileData && fileData.length > 0 ? fileData : []);
    const instanceComment =
      state &&
      state.action_data &&
      state.action_data.length > 0 &&
      state.action_data
        .flatMap((obj) => {
          console.log("obj", obj);
          return (
            obj &&
            obj.comments &&
            obj.comments.length > 0 &&
            obj.comments.map((data) => {
              return {
                comment: data,
                author: obj.author_name,
                created_on: moment(obj.uploaded_on).format("DD/MM/YY - hh:mm"),
              };
            })
          );
        })
        .filter(Boolean);
    console.log("instanceComment", instanceComment);
    setOldComments(
      instanceComment && instanceComment.length > 0 ? instanceComment : []
    );
  }, []);

  const props = {
    name: "file",
    listType: "picture",
    showUploadList: false,
    // accept: ["xlsx", "xls"],
    // maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, "***************************", info.fileList);
        if (info.fileList.length !== 0) {
          setActionFile(info.fileList);
          setComments([...comments, `file-${info.file.name}`]);
          let data = [
            {
              document_name: (
                <span
                  style={{
                    color: "var(--color-solid-darkblue)",
                    fontWeight: "100",
                  }}
                  onClick={() => {}}
                >
                  {info.file.name.split(".")[0]}
                </span>
              ),
              action_instance_status: state && state.instance_status,
              doc_uploaded_on: moment(new Date()).format("DD MMM YYYY"),
              doc_uploader_name: userData[0].first_name,
              doc_uploaded_by: userData[0].id,
              action: (
                <>
                  <div
                    style={{
                      justifyContent: "space-evenly",
                      display: "flex",
                    }}
                  >
                    <DownloadOutlined
                      title="Download"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        onDownloadNewFile(info.file);
                      }}
                    />
                  </div>
                </>
              ),
            },
          ];
          setTableNewData([...tableNewData, ...data]);
        } else {
          setActionFile();
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setActionFile(info.fileList);
          setComments([...comments, `file-${info.file.name}`]);
        } else {
          setActionFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeComment = (e) => {
    setCommentEntered(e.target.value);
  };

  const onAddComment = () => {
    if (commentEntered !== "") {
      setComments([...comments, `text-${commentEntered}`]);
      setCommentEntered("");
    }
  };

  useEffect(() => {
    console.log("comments", comments);
  }, [comments]);

  const markActionOption =
    state &&
    state.instance_status === "Submitted" &&
    state &&
    state.action_owner === userData[0].id &&
    state &&
    state.action_approver === userData[0].id
      ? ["Met", "Not Met", "Cancel Instance"]
      : state &&
        state.instance_status === "Submitted" &&
        state &&
        state.action_approver === userData[0].id
      ? ["Met", "Not Met", "Cancel Instance"]
      : state &&
        state.instance_status === "Submitted" &&
        state &&
        state.action_owner === userData[0].id &&
        state &&
        state.action_approver !== userData[0].id
      ? ["Request Cancellation"]
      : state &&
        state.instance_status === "Open" &&
        state &&
        state.action_owner === userData[0].id
      ? ["Submitted", "Request Cancellation"]
      : state &&
        state.instance_status === "Open" &&
        state &&
        state.action_approver === userData[0].id
      ? ["Cancel Instance"]
      : [];

  const onFinish = async (values) => {
    let data = {
      root_action_id: state && state.root_action[0]._id,
      instance_status:
        values.mark_action === "Cancel Instance" ||
        values.mark_action === "Request Cancellation"
          ? "Cancelled"
          : values.mark_action,
      updated_on: new Date(),
      updated_by: userData[0].id,
      due_date: state && state.due_date,
      comments: comments,
      author_name: userData[0].first_name,
    };
    console.log("data", data);

    // Prepare form data
    const formData = new FormData();
    for (let i = 0; i < actionFile.length; i++) {
      formData.append("file", actionFile[i] && actionFile[i].originFileObj);
    }
    formData.append("formData", JSON.stringify(data)); // Attach other form
    console.log("formdata", formData);

    updateActionInstanceApi(
      state && state._id,
      state && state.iteration_no,
      formData
    ).then((res) => {
      let ssLog = {
        ss_id: res.id ? res.id : res._id,
        ss_name: state && state.root_action[0].action_name,
        code: "action_instance",
        author: userData[0].id,
        action: "view",
        notes: "Updated Action Instance Status",
        updated_on: new Date(),
      };
      // api integration for store a record on activity log
      createActivityLogApi(ssLog);
      setSaveTableLoader(false);
      // global popup modal
      popupModalInstance.successModal(
        values.action_name,
        ModalMessage.AI_Update,
        `/action-management`
      );
    });
  };

  const handlePressEnter = () => {
    // Add logic to execute when Enter key is pressed
    console.log("Enter key pressed");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  // function for handle on tab change
  const onChangeTabs = (key) => {
    setTabKey(key);
  };

  const tabItems = [
    {
      key: "1",
      label: "Root Action",
    },
    {
      key: "2",
      label: "Entity Log",
    },
  ];

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <Form onFinish={onFinish} onKeyDown={handleKeyDown}>
          <div
            id="action-management-id"
            className="contract-pipeline"
            style={{ padding: "0 25px" }}
          >
            <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
              <Col
                className="p-0 vertical-align"
                xs={24}
                sm={24}
                md={16}
                lg={16}
                xl={
                  ((state && state.action_approver === userData[0].id) ||
                    (state && state.action_owner === userData[0].id)) &&
                  state &&
                  state.instance_status !== "Met" &&
                  state &&
                  state.instance_status !== "Delinquent"
                    ? 20
                    : 22
                }
                // style={{ background: "white", height: "24px" }}
              >
                <div className="filter-white-space-head mt-10">
                  {/* <Col
                    className="p-0 vertical-align"
                    xs={24}
                    sm={24}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{ background: "white" }}
                  >
                    <Image
                      className="cursor-pointer saved-filter-image"
                      src={save_filter}
                      preview={false}
                      // style={{ width: "50%" }}
                    />
                  </Col> */}
                  {/* <Col
                    className="p-0 vertical-align"
                    xs={24}
                    sm={24}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{ background: "white" }}
                  >
                    <div className="cursor-pointer">
                      <Badge>
                        <FilterOutlined
                          className="cursor-pointer filter-image"
                          // style={{ width: "18px", height: "19px", marginTop: "5px" }}
                        />
                      </Badge>
                    </div>
                  </Col>
                  <Space
                    className="filter-white-space"
                    style={{ width: "90%" }}
                    size={[0, 8]}
                  >
                    <p className="placeholder-text">Add Filters</p>
                  </Space> */}
                </div>
              </Col>
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <div className="">
                  <CancelButton
                    onClick={() => {
                      setHeaderChanger({
                        header: "actionManagement",
                        headerItem: "2",
                        headerItemName: "actionManagement",
                      });
                      history(`/action-management`, {
                        state: {
                          tabKey: 2,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
              {((state && state.action_approver === userData[0].id) ||
                (state && state.action_owner === userData[0].id)) &&
                state &&
                state.instance_status !== "Met" &&
                state &&
                state.instance_status !== "Delinquent" && (
                  <Col xl={2} md={4} xs={24} className="vertical-align">
                    <div className="">
                      <OkButton
                        loading={saveTableLoader}
                        disabled={!actionFile}
                        type="primary"
                        htmlType="submit"
                      />
                    </div>
                  </Col>
                )}
            </Row>
            {/* Tab for view root action and action instance */}
            <Tabs
              className="action-instance-tab mt-10"
              defaultActiveKey="1"
              items={tabItems}
              onChange={onChangeTabs}
            />
            {/* Root action tab content */}
            {tabKey === "1" && (
              <div
                className="action-div-head"
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  height: "calc(100vh - 205px)",
                }}
              >
                <span style={{ color: "black", fontSize: "17px" }}>
                  <h4 className="mt-10">Action Instance</h4>
                </span>

                <div
                  className="action-body-head"
                  style={{ textAlign: "left", color: "black" }}
                >
                  <div className="action-new-div">
                    <div className="create-entity-div">
                      <Row
                        align="middle"
                        type="flex"
                        style={{ padding: "13px 30px 0px" }}
                      >
                        <Col span={19} className="vertical-align">
                          <h4 className="entity-text">
                            Action Instance Details
                          </h4>
                        </Col>
                      </Row>
                      <hr
                        className="mt-10 view-supplier-hr"
                        style={{ color: "grey" }}
                      />

                      <Row
                        align="middle"
                        type="flex"
                        gutter={[4, 16]}
                        className="m-0"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Col span={24}>
                            <div className="create-supplier-div">
                              <div className="m-10">
                                <Row align="middle" type="flex">
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_name"
                                      label="ACTION NAME"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.root_action &&
                                        state.root_action.length !== 0 &&
                                        state.root_action[0].action_name
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="iteration_no"
                                      label="ACTION INSTANCE ITERATION NO"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.iteration_no &&
                                        state.iteration_no
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_description"
                                      label="DESCRIPTION"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.root_action &&
                                        state.root_action.length !== 0 &&
                                        state.root_action[0].action_description
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row align="middle" type="flex">
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_category"
                                      label="CATEGORY"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.root_action &&
                                        state.root_action.length !== 0 &&
                                        state.root_action[0].action_category
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_type"
                                      label="TYPE"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.root_action &&
                                        state.root_action.length !== 0 &&
                                        state.root_action[0].action_type
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_priority"
                                      label="PRIORITY"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.root_action &&
                                        state.root_action.length !== 0 &&
                                        state.root_action[0].action_priority
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row align="middle" type="flex">
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="trigger_date"
                                      label="TRIGGER DATE"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.trigger_date &&
                                        moment(state.trigger_date).format(
                                          "DD MMM YYYY"
                                        )
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="due_date"
                                      label="DUE DATE"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.due_date &&
                                        moment(state.due_date).format(
                                          "DD MMM YYYY"
                                        )
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row align="middle" type="flex">
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_owner"
                                      label="ACTION OWNER"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.action_owner_name &&
                                        state.action_owner_name
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_approver"
                                      label="ACTION APPROVER"
                                      colon={false}
                                      initialValue={
                                        state &&
                                        state.action_approver_name &&
                                        state.action_approver_name
                                      }
                                    >
                                      <Input readOnly />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                {((state && state.action_approver === userData[0].id) ||
                  (state && state.action_owner === userData[0].id)) &&
                  state &&
                  state.instance_status !== "Met" &&
                  state &&
                  state.instance_status !== "Delinquent" && (
                    <div
                      className="action-body-head"
                      style={{ textAlign: "left", color: "black" }}
                    >
                      <div className="action-new-div">
                        <div className="create-entity-div">
                          <Row
                            align="middle"
                            type="flex"
                            style={{ padding: "13px 30px 0px" }}
                          >
                            <Col span={19} className="vertical-align">
                              <h4 className="entity-text">Fulfillment</h4>
                            </Col>
                          </Row>
                          <hr
                            className="mt-10 view-supplier-hr"
                            style={{ color: "grey" }}
                          />
                          <Row
                            align="middle"
                            type="flex"
                            gutter={[4, 16]}
                            className="m-0"
                          >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Col span={24}>
                                <div className="create-supplier-div">
                                  <div className="m-10">
                                    <Row align="middle" type="flex">
                                      <Col span={6}>
                                        <Form.Item
                                          {...formItemLayout}
                                          className="org-supplier-label"
                                          name="mark_action"
                                          label="MARK ACTION"
                                          colon={false}
                                          rules={[
                                            {
                                              required: true,
                                              message: `Mark Action is required`,
                                            },
                                          ]}
                                        >
                                          <Select
                                            allowClear
                                            placeholder="Select Action"
                                            options={
                                              markActionOption &&
                                              markActionOption.map((data) => ({
                                                key: data,
                                                label: data,
                                                value: data,
                                              }))
                                            }
                                          ></Select>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}

                <div
                  className="action-body-head "
                  style={{ textAlign: "left", color: "black" }}
                >
                  <div className="action-new-div mb-10">
                    <div className="create-entity-div">
                      <Row
                        align="middle"
                        type="flex"
                        style={{ padding: "13px 30px 0px" }}
                      >
                        <Col span={19} className="vertical-align">
                          <h4 className="entity-text">Upload & Docs</h4>
                        </Col>
                      </Row>
                      <hr
                        className="mt-10 view-supplier-hr"
                        style={{ color: "grey" }}
                      />
                      <Row align="middle" type="flex">
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />
                        <Col span={20} className="vertical-align" offset={2}>
                          <div className="action-notes-div ">
                            <div className="text-center notes-header">
                              <h4>Notes & Comments</h4>
                            </div>
                            <div className="comments-div">
                              <div className="comments-align-right">
                                {comments &&
                                  comments.length !== 0 &&
                                  comments.map((comment) => {
                                    return (
                                      <div className="comments-new mt-10">
                                        <div className="author-data">
                                          <span>
                                            <UserOutlined
                                              style={{ fontSize: "11px" }}
                                            />
                                          </span>
                                          &nbsp; you
                                        </div>
                                        {comment.split("-")[0] === "text" ? (
                                          <div> {comment.split("-")[1]}</div>
                                        ) : (
                                          <div>
                                            <span>
                                              <FileOutlined />
                                            </span>
                                            &nbsp;&nbsp;
                                            <span>{comment.split("-")[1]}</span>
                                          </div>
                                        )}
                                        {/* <div
                                      style={{
                                        fontSize: "11px",
                                        textAlign: "end",
                                      }}
                                    >
                                      {moment().format("DD/MM/YY - hh:mm")}
                                    </div> */}
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="comments-align-left">
                                {oldComments &&
                                  oldComments.length !== 0 &&
                                  oldComments.map((data) => {
                                    return (
                                      <div className="comments-old mt-10">
                                        <div className="author-data">
                                          <span>
                                            <UserOutlined
                                              style={{ fontSize: "11px" }}
                                            />
                                          </span>
                                          &nbsp;
                                          {data.author}
                                        </div>
                                        {data.comment.split("-")[0] ===
                                        "text" ? (
                                          <div>
                                            {" "}
                                            {data.comment.split("-")[1]}
                                          </div>
                                        ) : (
                                          <div>
                                            <span>
                                              <FileOutlined />
                                            </span>
                                            &nbsp;&nbsp;
                                            <span>
                                              {data.comment.split("-")[1]}
                                            </span>
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            fontSize: "11px",
                                            textAlign: "end",
                                          }}
                                        >
                                          {data.created_on}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>

                            {((state &&
                              state.action_approver === userData[0].id) ||
                              (state &&
                                state.action_owner === userData[0].id)) &&
                              state &&
                              state.instance_status !== "Met" &&
                              state &&
                              state.instance_status !== "Delinquent" && (
                                <div className="action-org-view-div">
                                  <Input
                                    // ref={inputRef}
                                    value={commentEntered}
                                    onPressEnter={handlePressEnter}
                                    onChange={onChangeComment}
                                    // style={{ background: "transparent" }}
                                    className="instance-notes-input"
                                    placeholder="Comments"
                                    suffix={
                                      <>
                                        <Upload
                                          {...props}
                                          customRequest={dummyRequest}
                                        >
                                          <Image
                                            title="Upload"
                                            className="cursor-pointer"
                                            src={attach}
                                            preview={false}
                                          />
                                        </Upload>

                                        <Image
                                          title="Add Comment"
                                          className="cursor-pointer"
                                          style={{
                                            paddingLeft: 10,
                                            pointerEvents:
                                              commentEntered === ""
                                                ? "none"
                                                : "",
                                          }}
                                          src={send}
                                          preview={false}
                                          onClick={onAddComment}
                                        />
                                      </>
                                    }
                                  />
                                  {/* <div className="instance-notes-input">
                            <p>Comments</p>
                            <div className="instance-notes-icons">
                              
                            </div>
                          </div> */}
                                </div>
                              )}
                          </div>
                          <DataTable
                            type="action-instance"
                            dataTableData={[...tableNewData, ...tableOldData]}
                            colData={columns}
                            rowClassName={() => "contract-tree-table-row"}
                            showSorterTooltip={false}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Entity Log table */}
            {tabKey === "2" && (
              <div className="action-div-head-view-instance">
                <Row className="mt-10">
                  <DataTable type="entityLog" logData={state} />
                </Row>
              </div>
            )}
          </div>
        </Form>
      ) : (
        <NotAuthorized redirect={`/action-management`} />
      )}
    </Layout>
  );
};
export default ViewActionInstance;
