import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Image, Tabs, Divider } from "antd"; //antd components
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Layout } from "antd"; //antd components
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../../Header";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import {
  HeaderChangerAtom,
  SupplierTreeDataAtom,
  RoleAccessAtom,
} from "../../../store/store";
import "../../../assets/style/setup.css";
import FlowDiagram from "../../FlowDiagram";
import moment from "moment";
import { CancelButton } from "../../GlobalButton";
import { getContractByWF } from "../../../api/contractsApi";
import ContractFile from "../../Contract/ContractFile";
import NotAuthorized from "../../NotAuthorized";
import DataTable from "../../Tables/DataTable";
import { navigateBasedOnContractStatus } from "../../../utils/common";
import { ContractTabContext } from "../../Contract/ContractTabContext";

function ViewWorkFlow() {
  const { handleAddTab } = useContext(ContractTabContext);

  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //global state variable hold the corresponding work flow details using location
  let { state } = useLocation();
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  //global state variable hold the header
  let header = headerChanger.header;
  //global state variable hold the navigating different routes
  const history = useNavigate();
  const [associationData, setAssociationData] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // global state variable hold the key while switch header name
  const menuKey = "5";
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  useEffect(() => {
    document.title = "Workflow | Judo";
  }, []);

  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    setHeaderChanger({
      header: "systemSetup",
      headerItem: "2",
      headerItemName: "systemSetup",
    });
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    getContractByWF(state._id ? state._id : state.id).then((res) => {
      console.log("contract res", res);
      let association = res.map((data) => {
        let tableRow = {
          associated_item: "Contract",
          name: (
            <span
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contracts.view_contract.view
                ) {
                  navigateBasedOnContractStatus(
                    data.contract_data,
                    history,
                    setHeaderChanger,
                    handleAddTab
                  );
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contracts.view_contract.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contracts.view_contract.view
                    ? "pointer"
                    : "default",
              }}
            >
              {data.contract_data.contract_name}
            </span>
          ),
          association_date: moment(data.contract_data.createdAt).format(
            "DD-MMM-YY"
          ),
        };
        return tableRow;
      });
      setAssociationData(association);
    });
  }, [state]);

  const associationCol = [
    {
      title: "Associated Item",
      dataIndex: "associated_item",
      key: "associated_item",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Association Date",
      dataIndex: "association_date",
      key: "association_date",
    },
  ];

  const Workflowcover = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="create-supplier-div">
              <div className="create-entity-div mt-15">
                <FlowDiagram
                  flowData={
                    state.workflow_metadata ? state.workflow_metadata : {}
                  }
                  from="workflow-view"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state} />;
  };

  const items = [
    {
      key: "1",
      label: `Workflow`,
      children: Workflowcover(),
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "3",
      label: `Entity Log`,
      children: EntityLog(),
    },
  ];

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <>
          {!selectedContract && (
            <div className="system-setup1">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={20}
                  md={21}
                  lg={21}
                  xl={22}
                >
                  <Card id="header-main-card" className="setup-header-card2">
                    <Row align="middle" type="flex" className="m-0">
                      <Col span={1}>
                        <Image
                          src={arrow_left}
                          style={{
                            width: 35,
                            height: 35,
                            backgroundColor: "#fff",
                            padding: 5,
                            borderRadius: 10,
                          }}
                          preview={false}
                          className="cursor-pointer"
                          onClick={() => history(`/system-setup/?back=con-wf`)}
                        />
                      </Col>
                      <Col span={13}>
                        <span className="role-name-text">
                          {state.workflow_name}
                        </span>
                      </Col>
                      <Col span={5} align="right">
                        <span className="text-overflow">
                          <span className="last-update-text">Created on: </span>
                          <span
                            title={moment(state.createdAt).format("DD/MM/YY")}
                          >
                            {moment(state.createdAt).format("DD/MM/YY")}
                          </span>
                        </span>
                      </Col>
                      <Col span={5} align="middle">
                        <span className="text-overflow">
                          <span className="last-update-text">Created by: </span>
                          <span title={state.created_user_name}>
                            {state.created_user_name}
                          </span>
                        </span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <CancelButton
                    onClick={() => history(`/system-setup/?back=con-wf`)}
                  />
                </Col>
              </Row>
              <div className="price-profile-content m-10">
                <Row type="flex" gutter={[14, 0]} className="m-0">
                  <Col span={24}>
                    <Tabs items={items} />
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {selectedContract && (
            <div className="pipeline-click">
              <Row
                align="top"
                type="flex"
                gutter={[8, 0]}
                className="m-10"
                style={{ display: "none" }}
              >
                <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
                  <div className="pipeline-breadcrumb mt-10">
                    <span>
                      <Image
                        src={arrow_left}
                        style={{ width: 25 }}
                        preview={false}
                        onClick={() => {
                          setHeaderChanger({
                            header: "systemSetup",
                            headerItem: "1",
                            headerItemName: "systemSetup",
                          });
                          setSelectedContract();
                        }}
                        className="cursor-pointer"
                      />
                      <span className="breadcrumb-path">
                        {finalPath &&
                          finalPath.map((item, i) => {
                            return (
                              <>
                                <span
                                  key={item.id}
                                  className="path-text-pipeline"
                                >
                                  {item.name === "home"
                                    ? "Suppliers"
                                    : item.name}
                                </span>
                                <span
                                  style={{
                                    display:
                                      i === finalPath.length - 1
                                        ? "none"
                                        : "inline-block",
                                  }}
                                  className="path-icon"
                                >
                                  <RightOutlined />
                                </span>
                              </>
                            );
                          })}
                      </span>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row type="flex" gutter={[8, 0]}>
                <Col span={24}>
                  <ContractFile
                    pipelinePath={finalPath}
                    backButton={backButton}
                    selected={selectedContract}
                    changeHomeTab={changeHomeTab}
                  />
                </Col>
              </Row>
            </div>
          )}
        </>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-wf`} />
      )}
    </Layout>
  );
}

export default ViewWorkFlow;
