import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Image,
  Space,
  Button,
  Radio,
  Form,
  Modal,
  Input,
} from "antd"; //antd components
import { CancelButton } from "../../GlobalButton";
import TopHeader from "../../Header";
import { HeaderChangerAtom, userDataAtom } from "../../../store/store";
import { useRecoilState } from "recoil";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import Union from "../../../assets/images/icons/Union.svg";
import "../../../assets/style/template.css";
import close from "../../../assets/images/icons/close.svg";
import info from "../../../assets/images/icons/info.svg";
import open_new from "../../../assets/images/icons/open_new.svg";
import { Checkbox } from "antd";
import { SaveButton, PreviewButton } from "../../GlobalButton";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {
  clauseLibraryOnlyApi,
  saveTemplateApi,
  updateTemplateApi,
} from "../../../api/contractAuthorityApi";
import workspace_premium from "../../../assets/images/icons/workspace_premium.svg";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { createActivityLogApi } from "../../../api/logApi";

function OnlineTemplate() {
  const { TextArea } = Input;
  const popupModalInstance = PopupModal();
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [clauseHeader, setClauseHeader] = useState([]);
  const userData = useRecoilState(userDataAtom); //global state
  const [selectedBatch, setSelectedBatch] = useState(null); // State to store selected batch for each header
  let { state } = useLocation();
  if (state === null || state === undefined) {
    console.log("checking ");

    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  let header = headerChanger.header;
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [checkedList, setCheckedList] = useState(
    state && state.checkedList && state.checkedList ? state.checkedList : []
  );
  const [selectedContent, setSelectedContent] = useState(
    state && state.selectedContent && state.selectedContent
      ? state.selectedContent
      : []
  );
  const [selectedHeader, setSelectedHeader] = useState(
    state && state.selectedHeader && state.selectedHeader
      ? state.selectedHeader
      : []
  );
  const [selectedBatches, setSelectedBatches] = useState(
    state && state.selectedBatches && state.selectedBatches
      ? state.selectedBatches
      : {}
  );
  const [batchData, setBatchData] = useState();
  const menuKey = "5";
  const navigate = useNavigate();
  const [finalClause, setFinalClause] = useState(
    state && state.finalClause && state.finalClause ? state.finalClause : []
  );
  let stateButton = 1;
  console.log("finalClause", finalClause);
  const onChange = (item) => {
    const isChecked = checkedList.some(
      (checkedItem) => JSON.stringify(checkedItem) === JSON.stringify(item)
    );

    let itemForFinalClause = {
      id: item._id,
      header_name: item.header_name,
      batch_details: {},
    };

    if (isChecked) {
      // If the item is already checked, remove it from the checkedList
      handleRemoveItem(item);
    } else {
      // If the item is not checked, add it to the checkedList
      setCheckedList((prevList) => [...prevList, item]);
      setFinalClause((prevList) => [
        ...(Array.isArray(prevList) && prevList.length > 0 ? prevList : []),
        itemForFinalClause,
      ]);
    }
    if (selectedHeader.includes(item._id)) {
      // If the clicked header is already selected, close it
      const data = selectedHeader.filter((data) => data !== item._id);
      setSelectedHeader(data); // Reset selectedContent
    }
  };

  useEffect(() => {
    clauseLibraryOnlyApi().then((res) => {
      console.log("ress", res);
      setClauseHeader(res);
    });
  }, []);
  useEffect(() => {
    console.log("checkedList", selectedHeader);
  }, [selectedHeader]);

  // const handleRemoveItem = (list) => {
  //   const filteredIndex = checkedList.findIndex(
  //     (item) => JSON.stringify(item) === JSON.stringify(list)
  //   );
  //   const newList = checkedList.filter(
  //     (item, index) => index !== filteredIndex
  //   );
  //   const newListForFC = checkedList.filter(
  //     (item, index) => index !== filteredIndex
  //   );
  //   setCheckedList(newList);
  //   setFinalClause(
  //     newListForFC &&
  //       newListForFC.length > 0 &&
  //       newListForFC.map((item) => {
  //         return {
  //           id: item.id,
  //           header_name: item.header_name,
  //           batch_details: {},
  //         };
  //       })
  //   );

  //   // If the removed header is the selected header, reset selectedContent and selectedBatch to null
  //   if (
  //     selectedContent &&
  //     JSON.stringify(selectedContent) === JSON.stringify(list)
  //   ) {
  //     setSelectedContent(null);
  //     setSelectedBatch(null); // Reset the selected batch when the header is removed
  //   }

  //   // If the removed header is in selectedBatches, remove it from selectedBatches
  //   setSelectedBatches((prevSelectedBatches) => {
  //     const updatedSelectedBatches = { ...prevSelectedBatches };
  //     delete updatedSelectedBatches[list.id];
  //     return updatedSelectedBatches;
  //   });
  // };

  const handleRemoveItem = (list) => {
    // Remove the header from the checkedList
    const newList = checkedList.filter((item) => item._id !== list._id);
    setCheckedList(newList);

    // Update finalClause to remove the unselected header
    const updatedFinalClause = finalClause.filter(
      (clause) => clause.id !== list._id
    );
    setFinalClause(updatedFinalClause);

    // If the removed header is the selected header, reset related states
    if (selectedContent && selectedContent._id === list._id) {
      setSelectedContent(null);
      setSelectedBatch(null);
    }

    // Remove the unselected header from selectedBatches
    setSelectedBatches((prevSelectedBatches) => {
      const updatedBatches = { ...prevSelectedBatches };
      delete updatedBatches[list._id];
      return updatedBatches;
    });
  };

  const handleBatchChange = (headerId, batch) => {
    setSelectedBatches((prevState) => {
      const isSelected = prevState[headerId] === batch.batch_name;

      // Update finalClause accordingly
      setFinalClause((prevList) => {
        return prevList.map((item) => {
          if (item.id === headerId) {
            return {
              ...item,
              batch_details: isSelected ? {} : batch,
            };
          }
          return item;
        });
      });
      return {
        ...prevState,
        [headerId]: isSelected ? null : batch.batch_name,
      };
    });
  };

  // const onClickClauseHeader = (header) => {
  //   if (selectedHeader.includes(header._id)) {
  //     // If the clicked header is already selected, close it
  //     const data = selectedHeader.filter((data) => data !== header._id);
  //     console.log("data", data);
  //     setSelectedHeader(data);
  //     setSelectedContent(null); // Reset selectedContent
  //   } else {
  //     // If the clicked header is different, select it
  //     selectedHeader.push(header._id);
  //     setSelectedContent(header);
  //   }
  // };
  const onClickClauseHeader = (header) => {
    if (selectedHeader.includes(header._id)) {
      // If the clicked header is already selected, close it
      const data = selectedHeader.filter((data) => data !== header._id);
      setSelectedHeader(data);
      setSelectedContent(null); // Reset selectedContent
      setSelectedBatches((prevBatches) => {
        const updatedBatches = { ...prevBatches };
        delete updatedBatches[header._id];
        return updatedBatches;
      });
    } else {
      // If the clicked header is different, select it
      setSelectedHeader([...selectedHeader, header._id]);
      setSelectedContent(header);
    }
  };

  function transformBatchContent(data) {
    if (data.batch_content && Array.isArray(data.batch_content.ops)) {
      let opsArray = data.batch_content.ops;

      // Assuming there is only one object in the array
      if (opsArray.length > 0 && opsArray[0].insert) {
        data.batch_content = opsArray[0].insert;
      } else {
        data.batch_content = ""; // or handle as needed if no valid insert found
      }
    }

    return data;
  }
  const onOpenBatchDetail = (batch) => {
    transformBatchContent(batch);
    setBatchData(batch);
    setConfirmationModel(true);
  };

  //onFinish
  const onFinish = (values) => {
    console.log("values", values);
    let newArrayFC = finalClause.map((item) => {
      if (
        item.id === selectedContent._id &&
        item.batch_details &&
        item.batch_details.batch_name === values.batch_name
      ) {
        return {
          ...item,
          batch_details: {
            batch_name: values.batch_name,
            batch_desc: values.batch_desc,
            batch_content: { ops: [{ insert: values.batch_content }] },
          },
        };
      }
      return item;
    });
    setFinalClause(newArrayFC);

    setCheckedList((prevData) =>
      prevData.map((item) => {
        if (item.id === selectedContent.id) {
          return {
            ...item,
            batch_details: item.batch_details.map((data) => {
              if (data.batch_name === values.batch_name) {
                return {
                  ...data,
                  batch_name: values.batch_name,
                  batch_desc: values.batch_desc,
                  batch_content: values.batch_content,
                };
              }
              return data;
            }),
          };
        }
        return item;
      })
    );

    let newSC = {
      ...selectedContent,
      batch_details: selectedContent.batch_details.map((data) => {
        if (data.batch_name === values.batch_name) {
          return {
            ...data,
            batch_name: values.batch_name,
            batch_desc: values.batch_desc,
            batch_content: values.batch_content,
          };
        }
        return data;
      }),
    };
    setSelectedContent(newSC);
    setConfirmationModel(false);
  };

  const onSaveTemplate = async () => {
    let clauesContent = finalClause
      ? finalClause.map((clause) => {
          const ops = clause.batch_details?.batch_content?.ops;
          const concatenatedContent = ops
            ? ops
                .map((op) => {
                  if (typeof op.insert === "object" && op.insert.image) {
                    // If the content is an image, return the image HTML tag
                    return `<img src="${op.insert.image}" alt="image" />`;
                  } else {
                    // For text content, return the text
                    return op.insert;
                  }
                })
                .join("")
            : ""; // Handle case where ops is missing
          return {
            h: clause.header_name,
            p: concatenatedContent,
          };
        })
      : [];

    let values = {
      ...state,
      clause_content: clauesContent,
      created_on: new Date(),
      updated_on: new Date(),
      created_by: userData[0].id,
      updated_by: userData[0].id,
    };

    console.log("values", values);

    if (values["msa"] === null) {
      values["msa"] = "null";
    }
    if (values["template_description"] === null) {
      values["template_description"] = "null";
      console.log("values", values);
    }

    if (stateButton === 1) {
      console.log("values", values);

      values.msa_name || (values.msa_name === null && delete values.msa_name);
      values.supplier_name && delete values.supplier_name;
      setSaveTableLoader(true);

      if (state.status) {
        delete values._id;
        updateTemplateApi(state.id ? state.id : state._id, values)
          .then((res) => {
            console.log("resss", res);
            let ssLog = {
              ss_id: res.id ? res.id : res._id,
              ss_name: values.contract_template_name,
              code: "template",
              author: userData[0].id,
              action: "edit",
              notes: "saved contract template",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            popupModalInstance.successModal(
              "",
              ModalMessage.TEM_Edit_Info,
              "/contract_authority/template"
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        values["status"] = "Draft";
        console.log("values", values);

        await saveTemplateApi(values).then((res) => {
          let ssLog = {
            ss_id: res.data.id ? res.data.id : res.data._id,
            ss_name: values.contract_template_name,
            code: "template",
            author: userData[0].id,
            action: "view",
            notes: "Created contract template",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          console.log("res-draft", res);
          popupModalInstance.successModal(
            state.contract_template_name,
            ModalMessage.CA_TE_Save
          );
        });
      }
    } else if (stateButton === 2) {
      const stateValues = {
        ...values,
        checkedList: checkedList,
        selectedContent: selectedContent,
        selectedHeader: selectedHeader,
        selectedBatches: selectedBatches,
        finalClause: finalClause,
      };
      setHeaderChanger({
        header: "contractAuthority",
        headerItem: "2",
        headerItemName: "contractAuthority",
        stateSetter: stateValues,
      });
      navigate(`/contract_authority/template/online-preview`, {
        state: stateValues,
      });
    }
  };

  console.log("finalClause", finalClause);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  console.log("selectedBatches", selectedBatches);

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <Form onFinish={onSaveTemplate} onKeyDown={handleKeyDown}>
        <div className="system-setup1">
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={20}
            >
              <Card className="setup-header-card">
                <Row type="flex" gutter={[8, 16]}>
                  <Col
                    className="vertical-align"
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={2}
                  >
                    <Row type="flex" gutter={[8, 16]}>
                      <Col span={12}>
                        <Image
                          src={arrow_left}
                          style={{ width: 25 }}
                          preview={false}
                          className="cursor-pointer"
                          onClick={() =>
                            state && state.status
                              ? navigate(`/contract_authority/edit/template`, {
                                  state: state,
                                })
                              : navigate(
                                  `/contract_authority/create/template`,
                                  {
                                    state: state,
                                  }
                                )
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Image
                          src={arrow_right_disable}
                          style={{ width: 20 }}
                          preview={false}
                          className="cursor-not-allowed"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={17}
                    sm={17}
                    md={19}
                    lg={19}
                    xl={22}
                  >
                    <span>Contract Authority</span> &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate(`/contract_authority/template`)}
                    >
                      Templates
                    </span>
                    &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span>Create Templates</span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SaveButton
                loader={saveTableLoader}
                type="primary"
                htmlType="submit"
                onClick={() => (stateButton = 1)}
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <PreviewButton
                type="primary"
                htmlType="submit"
                onClick={() => (stateButton = 2)}
              />
            </Col>
          </Row>
          <div className="price-profile-content m-10">
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <Col span={24}>
                    <div className="create-supplier-div">
                      <Row align="middle" gutter={[40, 16]}>
                        <Col span={7} className="vertical-align">
                          <div className="org-temp-div mt-10">
                            {/* <Space align=""> */}
                            <div
                              style={{ marginTop: "10px", paddingLeft: "20px" }}
                            >
                              <Row>
                                <Col span={22}>
                                  <h3 style={{ color: "#2684ff" }}>
                                    Available Clause Header
                                  </h3>
                                </Col>
                                <Col span={2} align="right">
                                  <Image src={Union} preview={false}></Image>
                                </Col>
                              </Row>
                              <Image
                                src={info}
                                preview={false}
                                style={{ padding: "0px 5px 0px 0px" }}
                              ></Image>
                              <span
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                Select the required Clause Header
                              </span>
                            </div>
                            {/* </Space> */}
                            <hr className="mt-10 view-supplier-hr" />
                            <div className="checkbox-styles">
                              <Row type="flex">
                                <Col span={24} className="check-header">
                                  {clauseHeader &&
                                    clauseHeader.length !== 0 &&
                                    clauseHeader.map((item, index) => {
                                      const isChecked = checkedList.some(
                                        (checkedItem) =>
                                          // JSON.stringify(checkedItem) ===
                                          // JSON.stringify(item)
                                          (checkedItem._id
                                            ? checkedItem._id
                                            : checkedItem.id) ===
                                          (item._id ? item._id : item.id)
                                      );

                                      return (
                                        <Checkbox
                                          key={index}
                                          checked={isChecked}
                                          onChange={() => onChange(item)}
                                        >
                                          {item.header_name}
                                        </Checkbox>
                                      );
                                    })}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col span={7} className="vertical-align">
                          <div className="org-temp-div mt-10">
                            <Space align="">
                              <div
                                className="margin-align"
                                style={{
                                  marginTop: "10px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <h3 style={{ color: "#2684ff" }}>
                                  Selected Clause Headers
                                </h3>
                                <Image
                                  src={info}
                                  preview={false}
                                  style={{ padding: "0px 5px 0px 0px" }}
                                ></Image>
                                <span
                                  style={{
                                    fontSize: "11px",
                                  }}
                                >
                                  Select to view the Contents. Re-arrange the
                                  headers
                                </span>
                              </div>
                            </Space>
                            <hr className="mt-10 view-supplier-hr" />

                            <Row type="flex">
                              <Col span={24}>
                                {checkedList &&
                                  checkedList.length !== 0 &&
                                  checkedList.map((item, index) => (
                                    <div key={index} className="cursor-pointer">
                                      <Row
                                        align="middle"
                                        className="header-div mt-10"
                                        style={{
                                          border: selectedHeader.includes(
                                            item._id
                                          )
                                            ? "2px solid var(--color-solid-darkblue)"
                                            : "none",
                                        }}
                                      >
                                        <Col
                                          span={22}
                                          className="vertical-align"
                                          onClick={() =>
                                            onClickClauseHeader(item)
                                          }
                                        >
                                          <p
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                            }}
                                          >{`${index + 1}. ${
                                            item.header_name
                                          }`}</p>
                                        </Col>
                                        <Col
                                          span={2}
                                          className="vertical-align"
                                        >
                                          {/* Separate div for the remove icon */}
                                          <div
                                            onClick={() =>
                                              handleRemoveItem(item)
                                            }
                                          >
                                            <Image
                                              className="cursor-pointer"
                                              src={close}
                                              preview={false}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col span={10} className="vertical-align">
                          <div className="org-temp-div mt-10">
                            <Space align="">
                              <div
                                style={{
                                  marginTop: "10px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <h3 style={{ color: "#2684ff" }}>
                                  Clause Content
                                </h3>
                                <Image
                                  src={info}
                                  preview={false}
                                  style={{ padding: "0px 5px 0px 0px" }}
                                />
                                <span style={{ fontSize: "11px" }}>
                                  Select the required content
                                </span>
                              </div>
                            </Space>
                            <hr className="mt-10 view-supplier-hr" />
                            {selectedContent ? (
                              <>
                                <Row type="flex" className="mb-5">
                                  <Col span={24}>
                                    <Row align="middle" className="">
                                      <Col span={22} className="vertical-align">
                                        <p
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {selectedContent.header_name}
                                        </p>
                                      </Col>
                                      {/* <Col span={2} className="vertical-align">
                                        <Button>Add</Button>
                                      </Col> */}
                                    </Row>
                                  </Col>
                                </Row>
                                <div>
                                  {selectedContent.batch_details &&
                                    selectedContent.batch_details.length > 0 &&
                                    selectedContent.batch_details.map(
                                      (data) => (
                                        <Row
                                          align="middle"
                                          className="content-radio-wrapper"
                                          key={data.batch_name}
                                        >
                                          <Col
                                            span={23}
                                            className="vertical-align"
                                          >
                                            <Checkbox
                                              value={data.batch_name}
                                              checked={
                                                selectedBatches[
                                                  selectedContent._id
                                                ] === data.batch_name
                                              } // Check if this batch is selected
                                              onChange={() =>
                                                handleBatchChange(
                                                  selectedContent._id,
                                                  data
                                                )
                                              } // Handle change for this batch
                                            >
                                              {data.batch_name}
                                            </Checkbox>
                                          </Col>
                                          <Col
                                            span={1}
                                            className="vertical-align"
                                          >
                                            <Image
                                              onClick={() =>
                                                onOpenBatchDetail(data)
                                              }
                                              className="cursor-pointer"
                                              src={open_new}
                                              preview={false}
                                              width={20}
                                            />
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                </div>
                              </>
                            ) : (
                              <p>No header selected</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          {confirmationModel && (
            <Modal
              centered
              open={confirmationModel}
              onOk={() => setConfirmationModel(false)}
              onCancel={() => {
                setConfirmationModel(false);
              }}
              width={450}
              footer={false}
            >
              <Form
                initialValues={batchData}
                onFinish={onFinish}
                onKeyDown={handleKeyDown}
              >
                <div style={{ display: "flex" }}>
                  <img
                    src={workspace_premium}
                    style={{ width: 25, height: 25 }}
                  />
                  <p
                    style={{
                      paddingTop: 1,
                      paddingLeft: 6,
                      fontWeight: 600,
                    }}
                  >
                    View Clause Content
                  </p>
                </div>
                <Row
                  align="middle"
                  type="flex"
                  gutter={[40, 4]}
                  style={{ marginTop: "10px" }}
                >
                  <Col span={24}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="header_name"
                          label="Header Name"
                          labelCol={{ span: 24 }}
                          initialValue={selectedContent.header_name}
                        >
                          <Input
                            readOnly
                            placeholder=""
                            className=""
                            style={{
                              borderColor: "#0092ff",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  type="flex"
                  gutter={[40, 4]}
                  style={{ marginTop: "10px" }}
                >
                  <Col span={24}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="batch_name"
                          label="Clause Header"
                          labelCol={{ span: 24 }}
                          // initialValue={batchData && batchData.batch_name}
                        >
                          <Input
                            readOnly
                            placeholder=""
                            className=""
                            style={{
                              borderColor: "#0092ff",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* </Col> */}

                <Row align="middle" type="flex" gutter={[40, 4]}>
                  <Col span={24}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="batch_desc"
                          label="Clause Header Description"
                          labelCol={{ span: 24 }}
                          // initialValue={batchData && batchData.batch_desc}
                        >
                          <Input
                            readOnly
                            placeholder=""
                            className=""
                            style={{
                              borderColor: "#0092ff",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row align="middle" type="flex" gutter={[40, 4]}>
                  <Col span={24}>
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.Item
                          name="batch_content"
                          label="Clause Content"
                          labelCol={{ span: 24 }}
                          // initialValue={batch.batch_content}
                        >
                          <TextArea
                            rows={3}
                            style={{
                              borderColor: "#0092ff",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Checkbox onChange={onChange}>
                  Make as default Clause Content
                </Checkbox>
                <Row gutter={[16, 8]} className="mt-15">
                  <Col span={16} offset={4}>
                    <Row type="flex" align={"middle"}>
                      <Col span={11} offset={1}>
                        <CancelButton
                          onClick={() => setConfirmationModel(false)}
                        />
                      </Col>
                      <Col span={11} offset={1}>
                        <SaveButton htmlType="submit" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Modal>
          )}
        </div>
      </Form>
    </Layout>
  );
}

export default OnlineTemplate;
