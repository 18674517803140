// External imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; //router
import { Button, Form, Input, Row, Col, Image } from "antd"; //antd components
import "../assets/style/login.css";
import emailIcon from "../assets/images/icons/mark_email_unread.svg";
import { getPasswordLinkApi } from "../api/usersApi";
import judoIcon from "../assets/images/icons/judo_logo.svg";

// functional component for dashboard page
function ForgotPassword() {
  // State variable to hold send meg visibility status
  const [linkSendMsg, setLinkSendMsg] = useState(false);
  // State variable to hold email data
  const [email, setEmail] = useState("");
  // State variable to hold navigate
  let history = useNavigate();

  /* Function to handle the form submission error
   * @Param errorInfo - a error message
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /* Function to handle send password link
   * @Param value - a value have user data
   */
  const onFinishFp = (value) => {
    getPasswordLinkApi(value.email, "forgot").then((res) => {
      if (res.status) {
        setLinkSendMsg(true);
      }
    });
  };

  return (
    <div>
      <div className="login-page login-bg gradient-bg">
        {!linkSendMsg && (
          <Form
            name="basic"
            onFinish={onFinishFp}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="login-form p-30"
          >
            <div style={{ textAlign: "center" }}>
              <img src={judoIcon} alt="logo" style={{ width: "50px" }} /> &nbsp;
            </div>{" "}
            &nbsp;
            <div className="text-center verification-msg mt-10">
              Forgot Password!!! Don't Worry
            </div>
            <div className="mt-20 text-center">
              Enter your registered email address to get a password reset link.
            </div>
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                className="vertical-align"
              >
                <Form.Item
                  label=""
                  className="mt-20"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    className="login-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                className="vertical-align"
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    // loading={tableLoader}
                    disabled={email ? false : true}
                    className="signin-btn"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        {linkSendMsg && (
          <div className="login-form text-center p-30">
            <div>
              <Image src={emailIcon} preview={false} />
            </div>
            <div className="auth-head-msg mt-10">Email has been sent</div>
            <div className="mt-30">
              A password reset link has been sent to your account. Kindly set
              your password before attempting to log in again.
            </div>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                history(`/login`);
              }}
              className="signin-btn mt-20"
              style={{ width: "25%" }}
            >
              Go Back
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default ForgotPassword;
