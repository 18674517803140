import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import "../../assets/style/suppliertree.css";
import {
  Layout,
  Tree,
  Splitter,
  Spin,
  Row,
  Col,
  Card,
  Image,
  Table,
  Pagination,
  Empty,
  Modal,
  Checkbox,
  Button,
  Input,
} from "antd";
import {
  SyncOutlined,
  LoadingOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { ContractTabContext } from "../Contract/ContractTabContext";
import Breadcrumbs from "../Contract/Breadcrumbs";
import ContractRouter from "../Contract/ContractRouter";
import { getContractTreeApi } from "../../api/contractsApi";
import { getNodeIcon } from "../../utils/common";
import add from "../../assets/images/icons/Add_round.svg";
import { useRecoilState } from "recoil";
import { ContractTreeUpdateAtom, userDataAtom } from "../../store/store";
import dayjs from "dayjs";
import { createActivityLogApi } from "../../api/logApi";

const TreeComponent = ({ initialBreadcrumbs }) => {
  const { tabs, setTabs, activeKey, defaultBreadcrumbs, handleAddTab } =
    useContext(ContractTabContext);

  const userData = useRecoilState(userDataAtom);

  const [expandLoader, setExpandLoader] = useState(false);
  const [supplierTree, setSupplierTree] = useState(null);
  const [nodeMap, setNodeMap] = useState();
  const [contractTableData, setContractTableData] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [panelSizes, setPanelSizes] = useState(() => {
    return [
      (window.innerWidth - 40) * 0.17,
      (window.innerWidth - 40) * (1 - 0.17),
    ];
  });
  const [contractTreeUpdate, setContractTreeUpdate] = useRecoilState(
    ContractTreeUpdateAtom
  );
  const [selectedColLoc, setSelectedColLoc] = useState(
    contractTreeUpdate.selectedCol
  );
  const [colEditor, openColEditor] = useState(false);
  const [listedCol, _] = useState(
    contractTreeUpdate.selectedCol.concat(contractTreeUpdate.unSelectedCol)
  );
  const [selectedCol, setSelectedCol] = useState(
    contractTreeUpdate.selectedCol
  );
  const [selectedBackup, setSelectedBackup] = useState(
    contractTreeUpdate.selectedCol
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [paginateTreeData, setPaginateTreeData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [paginateTableData, setPaginateTableData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [supplierTreeLoader, setSupplierTreeLoader] = useState(true);
  const [contractTreeTableLoader, setContractTreeTableLoader] = useState(true);
  const [breadcrumbsLoader, setBreadcrumbsLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [tabBreadcrumbs, setTabBreadcrumbs] = useState(initialBreadcrumbs);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const newSize = [
        (window.innerWidth - 10) * 0.17,
        (window.innerWidth - 10) * (1 - 0.17),
      ];
      setPanelSizes(newSize);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const modifyPanelTransition = (action) => {
    const panels = document.querySelectorAll(
      ".contract-tree-splitter .ant-splitter-panel"
    );

    panels.forEach((panel) => {
      if (action === "start") panel.style.transition = "none";
      else if (action === "end")
        panel.style.transition =
          "flex-basis 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)";
    });
  };

  const isColumnSorted = (column) => sortedColumn === column;

  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  const fetchAndSetSupplierTree = async () => {
    if (!supplierTree) {
      setSupplierTreeLoader(true);
      try {
        const contractTreeRes = await getContractTreeApi(
          userData[0].id,
          paginateTreeData.pageNo,
          paginateTreeData.limit,
          undefined,
          undefined,
          "Supplier"
        );
        console.log("contractTreeRes: ", contractTreeRes);

        if (!contractTreeRes.docs.length) {
          throw new Error(
            "The requested contract is unavailable or access is restricted."
          );
        }

        setPaginateTreeData((prev) => ({
          ...prev,
          total: contractTreeRes.totalDocs,
        }));
        setSupplierTree(contractTreeRes.docs);
        buildNodeMap(contractTreeRes.docs);
      } catch (error) {
        console.error("There was an issue fetching supplier tree: ", error);
        setSelectedItem([{ type: "error" }]);
      } finally {
        setSupplierTreeLoader(false);
      }
    }
  };

  const fetchAndSetContractTree = async (
    supplier_id,
    contract_id,
    type,
    extraFlag
  ) => {
    setBreadcrumbsLoader(true);
    setContractTreeTableLoader(true);
    try {
      const contractTreeRes = await getContractTreeApi(
        userData[0].id,
        1,
        20,
        supplier_id === "undefined" ? undefined : supplier_id,
        contract_id,
        type
      );
      if (!contractTreeRes.docs.length) {
        throw new Error(
          "The requested contract is unavailable or access is restricted."
        );
      }
      setPaginateTableData({
        pageNo: 1,
        limit: 20,
        total: contractTreeRes.totalDocs,
      });
      setSelectedItem(
        extraFlag === "resetSelectedItem" ? undefined : contractTreeRes.docs
      );

      setContractTableData(contractTreeRes.docs);
    } catch (error) {
      console.error("There was an issue fetching the contract tree: ", error);
      setSelectedItem([{ type: "error" }]);
    } finally {
      setBreadcrumbsLoader(false);
      setContractTreeTableLoader(false);
    }
  };

  useEffect(() => {
    if (tabBreadcrumbs.length > 1) {
      const { supplier_id, contract_id, type } =
        tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs;

      fetchAndSetSupplierTree();
      fetchAndSetContractTree(supplier_id, contract_id, type, "");
    } else if (
      tabBreadcrumbs.length === 1 &&
      tabBreadcrumbs[0].title === "Suppliers"
    ) {
      fetchAndSetSupplierTree();
      fetchAndSetContractTree(
        undefined,
        undefined,
        "Supplier",
        "resetSelectedItem"
      );
    }

    const existingTabIndex = tabs.findIndex((t) => t.id === activeKey);
    if (existingTabIndex !== -1) {
      const updatedTab = {
        ...tabs[existingTabIndex],
        breadcrumbs: tabBreadcrumbs,
      };
      setTabs((prevTabs) => [
        ...prevTabs.slice(0, existingTabIndex),
        updatedTab,
        ...prevTabs.slice(existingTabIndex + 1),
      ]);
    }
  }, [tabBreadcrumbs]);

  const buildNodeMap = (tree) => {
    const generatedNodeMap = new Map();

    const traverseTree = (nodes) => {
      for (const node of nodes) {
        generatedNodeMap.set(node._id, node);
        if (
          node.children &&
          node.children.length &&
          typeof node.children !== "boolean"
        ) {
          traverseTree(node.children);
        }
      }
    };

    traverseTree(tree);
    setNodeMap((prevNodeMap) => {
      const updatedNodeMap = new Map(prevNodeMap);
      generatedNodeMap.forEach((value, key) => {
        updatedNodeMap.set(key, value);
      });
      return updatedNodeMap;
    });
    return generatedNodeMap;
  };

  const addChildrenToTree = (tree, contractId, newChildren, depth) => {
    // Create a map of all nodes in the tree for quick access
    const generatedNodeMap = buildNodeMap(tree);

    // Check if the node exists and update its children
    const nodeToUpdate = generatedNodeMap.get(contractId);
    if (nodeToUpdate) {
      const updatedChildren = newChildren.map((child) => ({
        ...child,
        depth, // Set the depth based on the parent's depth
      }));

      nodeToUpdate.children = updatedChildren;
    }

    return [...tree];
  };

  const findParentNode = (childNodeId, nodeMap) => {
    for (let node of nodeMap.values()) {
      if (node.children && Array.isArray(node.children)) {
        const hasChild = node.children.some(
          (child) => child._id === childNodeId
        );
        if (hasChild) {
          return node; // Return the parent node
        }
      }
    }
    return null;
  };

  const onTableSelect = (record) => {
    console.log("record: ", record);

    const newBreadcrumb = {
      title: record.title,
      apiArgs: {
        supplier_id: record.supplier_name ? record._id : undefined,
        contract_id: record._id,
        type: record.type,
      },
    };

    const lastBreadcrumb = tabBreadcrumbs[tabBreadcrumbs.length - 1] || {};
    const breadcrumbsAlreadyExists =
      lastBreadcrumb.apiArgs?.supplier_id ===
        newBreadcrumb.apiArgs.supplier_id &&
      lastBreadcrumb.apiArgs?.contract_id ===
        newBreadcrumb.apiArgs.contract_id &&
      lastBreadcrumb.apiArgs?.type === newBreadcrumb.apiArgs.type;

    // Important Debugging Logs

    // console.log(
    //   "check if record has children as [true]: ",
    //   record.children &&
    //     record.children.length === 1 &&
    //     typeof record.children[0] !== "boolean"
    // );

    // console.log(
    //   "check if record has no children: ",
    //   record.children.length === 0 && record.type !== "Supplier"
    // );

    // console.log(
    //   "check if breadcrumbs for record already exists: ",
    //   breadcrumbsAlreadyExists
    // );

    // Check if the child node(Contract) has no children and if so, opens the child node in a new tab
    if (
      (record.children &&
        record.children.length === 1 &&
        typeof record.children[0] !== "boolean") ||
      (record.children.length === 0 && record.type !== "Supplier") ||
      breadcrumbsAlreadyExists
    ) {
      const newTabBreadcrumbs = breadcrumbsAlreadyExists
        ? [
            ...tabBreadcrumbs.slice(0, -1),
            {
              ...tabBreadcrumbs[tabBreadcrumbs.length - 1],
              inspectItem: true,
            },
          ]
        : [
            ...tabBreadcrumbs,
            {
              title: record.title,
              apiArgs: {
                supplier_id: record.supplier_name ? record._id : undefined,
                contract_id: record._id,
                type: record.type,
              },
              inspectItem: true,
            },
          ];

      // This may seem unconventional, but the use of setTimeout to add a new tab allows the
      // useEffect with tabBreadcrumbs dependency (updates tabs state) to complete before adding a new tab (also updates tabs state).
      // This slight delay helps to ensure that the  latest tabs state is used when calling handleAddTab.
      // By using a delay of 0, handleAddTab's execution is pushed to the end of the event loop,
      // so that the current tab's breadcrumbs updates correctly and a new tab is added with the new breadcrumbs.

      // Clears any existing timeout before setting a new one.
      // We do this to prevent the setTimeout from hogging the application and causing a memory leak.
      // So we use a useEffect cleanup function to clear the timeout.
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      let contractLog = {
        contract_id: record._id,
        contract_name: record.title,
        code: "Contract-Viewed",
        author: userData[0].id,
        action: "Viewed",
        notes: "Contract viewed",
        updated_on: new Date(),
      };
      createActivityLogApi(contractLog);

      timeoutRef.current = setTimeout(() => {
        handleAddTab({
          id: record._id,
          title: record.title,
          breadcrumbs: newTabBreadcrumbs,
          children: <ContractRouter breadcrumbs={newTabBreadcrumbs} />,
          type: record.type,
        });
      }, 0);
    } else {
      setTabBreadcrumbs((prev) => [...prev, newBreadcrumb]);
    }
  };

  const onTreeSelect = (_, info) => {
    console.log("selected", info);

    // Check if the selected node's apiArgs is the same as the last breadcrumb's apiArgs
    // If so, there is no need to update tabBreadcrumbs
    if (
      JSON.stringify({
        supplier_id: info.node.supplier_name ? info.node._id : undefined,
        contract_id: info.node._id,
        type: info.node.type,
      }) !== JSON.stringify(tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs)
    ) {
      let newBreadcrumbs = [];
      newBreadcrumbs.push(defaultBreadcrumbs[0]);
      // Use nodeMap to build the path from the selected node up to the root
      let currentNode = nodeMap.get(info.node._id);
      const breadcrumbTrail = [];

      while (currentNode) {
        const breadcrumb = {
          title: currentNode.title,
          apiArgs: {
            supplier_id: currentNode.supplier_name
              ? currentNode._id
              : undefined,
            contract_id: currentNode._id,
            type: currentNode.type,
          },
        };
        breadcrumbTrail.unshift(breadcrumb);
        // Search for the parent node in the nodeMap
        const parentNode = findParentNode(currentNode._id, nodeMap);

        if (!parentNode) {
          break;
        }
        currentNode = parentNode;
      }

      newBreadcrumbs.push(...breadcrumbTrail);

      // Check if the child node(Contract) has no children and if so, opens the child node in a new tab
      if (info.node.children.length === 0 && info.node.type !== "Supplier") {
        const slicedBreadcrumbs = newBreadcrumbs.slice(0, -1);

        const newTabBreadcrumbs = [
          ...slicedBreadcrumbs,
          {
            title: info.node.title,
            apiArgs: {
              supplier_id: info.node.supplier_name ? info.node._id : undefined,
              contract_id: info.node._id,
              type: info.node.type,
            },
            inspectItem: true,
          },
        ];

        setTabBreadcrumbs(slicedBreadcrumbs);

        // This may seem unconventional, but the use of setTimeout to add a new tab allows the
        // useEffect with tabBreadcrumbs dependency (updates tabs state) to complete before adding a new tab (also updates tabs state).
        // This slight delay helps to ensure that the  latest tabs state is used when calling handleAddTab.
        // By using a delay of 0, handleAddTab's execution is pushed to the end of the event loop,
        // so that the current tab's breadcrumbs updates correctly and a new tab is added with the new breadcrumbs.

        // Clears any existing timeout before setting a new one.
        // We do this to prevent the setTimeout from hogging the application and causing a memory leak.
        // So we use a useEffect cleanup function to clear the timeout.
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        let contractLog = {
          contract_id: info.node._id,
          contract_name: info.node.title,
          code: "Contract-Viewed",
          author: userData[0].id,
          action: "Viewed",
          notes: "Contract viewed",
          updated_on: new Date(),
        };
        createActivityLogApi(contractLog);

        timeoutRef.current = setTimeout(() => {
          handleAddTab({
            id: info.node._id,
            title: info.node.title,
            breadcrumbs: newTabBreadcrumbs,
            children: <ContractRouter breadcrumbs={newTabBreadcrumbs} />,
            type: info.node.type,
          });
        }, 0);
      } else {
        setTabBreadcrumbs(newBreadcrumbs);
      }
    }
  };

  const onTreeExpand = async (_, info) => {
    console.log("expanded", info);

    if (info.expanded) {
      // Check if the current node has children loaded
      if (
        info.node.children &&
        Array.isArray(info.node.children) &&
        info.node.children.length > 0 &&
        typeof info.node.children[0] !== "boolean"
      ) {
        // If children are already loaded, no need to make an API call
        console.log("Children already loaded, skipping API call");
        setExpandedKeys((prev) => [...prev, info.node._id]);
        return;
      }

      setExpandLoader(true);
      setSelectedNode(info.node._id);

      // Make an API call only if children are not loaded or if children are true
      const newTree = await getContractTreeApi(
        userData[0].id,
        undefined,
        undefined,
        info.node.supplier_name ? info.node._id : undefined,
        info.node._id,
        info.node.type
      ).catch((error) =>
        console.error("There was an error fetching tree children: ", error)
      );

      console.log("newTree: ", newTree);

      if (newTree) {
        setSupplierTree((prevTree) => {
          const parentDepth = info.node.depth || 0;
          const updatedTree = addChildrenToTree(
            prevTree,
            info.node._id,
            newTree[0].children,
            parentDepth + 1
          );
          return updatedTree;
        });

        buildNodeMap(newTree[0].children);

        setExpandedKeys((prev) => [...prev, info.node._id]);
        setExpandLoader(false);
        setSelectedNode(null);
      } else {
        setSelectedItem([{ type: "error" }]);
        setExpandLoader(false);
        setSelectedNode(null);
      }
    } else {
      // If the node is collapsing, just remove it from the expandedKeys
      setExpandedKeys((prev) => prev.filter((key) => key !== info.node._id));
    }
  };

  const treeTitleRender = (node) => {
    const availableWidth = panelSizes[0] - 75 - (node.depth || 0) * 24;

    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          padding: "3px 5px",
          color: "var(--color-solid-darkergrey)",
        }}
      >
        {getNodeIcon(node.type)}
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 12,
            fontWeight: 600,
            width: availableWidth,
          }}
        >
          {node.title}
        </span>
      </span>
    );
  };

  const switcherIcon = (node) => {
    // Return loading icon if this node is currently loading
    if (selectedNode === node._id && expandLoader) {
      return (
        <SyncOutlined
          spin
          style={{ color: "var(--color-solid-darkerblue)", fontSize: 12 }}
        />
      );
    }
    return <DownOutlined />;
  };

  const filterSearch = (value) => {
    const searched = listedCol.filter((col) =>
      col.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const unselected = searched.filter((element) => !value.includes(element));
    const newSelected = [...new Set([...selectedCol, ...value])];
    const updatedSelectedCol = newSelected.filter(
      (element) => !unselected.includes(element)
    );
    setSelectedCol(updatedSelectedCol);
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const contractTreeColumns = useMemo(() => {
    return [
      {
        title: renderColumnTitle("Contract Name", "title"),
        dataIndex: "title",
        align: "left",
        show: selectedColLoc.includes("Name") ? true : false,
        sorter: (a, b) =>
          a.name.props.children[1].props.children.localeCompare(
            b.name.props.children[1].props.children
          ),
        sortOrder: isColumnSorted("title") ? sortOrder : false,
        ellipsis: true,
        render: (text, record) => {
          return (
            <span
              className="contract-table-cell"
              onClick={() => onTableSelect(record)}
            >
              {getNodeIcon(record.type)}
              {text}
            </span>
          );
        },
      },
      {
        title: renderColumnTitle("Contract Type", "type"),
        dataIndex: "type",
        align: "left",
        show: selectedColLoc.includes("Contract Type") ? true : false,
        sorter: (a, b) => a.contract_type.localeCompare(b.contract_type),
        sortOrder: isColumnSorted("type") ? sortOrder : false,
        ellipsis: true,
        width: "15%",
      },
      {
        title: renderColumnTitle("Status", "status"),
        dataIndex: "status",
        align: "left",
        show: selectedColLoc.includes("Status") ? true : false,
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: isColumnSorted("status") ? sortOrder : false,
        ellipsis: true,
        width: "15%",
        render: (text) => (
          <span>
            {text &&
              text
                .replace(/-/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
          </span>
        ),
      },
      {
        title: renderColumnTitle("Created Date", "created_on"),
        dataIndex: "created_on",
        align: "left",
        show: selectedColLoc.includes("Created Date") ? true : false,
        sorter: (a, b) => a.date.localeCompare(b.date),
        sortOrder: isColumnSorted("created_on") ? sortOrder : false,
        ellipsis: true,
        width: "15%",
        render: (text) => dayjs(text).format("DD/MM/YYYY"),
      },
      {
        title: (
          <Image
            src={add}
            className="cursor-pointer"
            preview={false}
            onClick={() => openColEditor(true)}
          />
        ),
        dataIndex: "add",
        align: "center",
        width: 50,
      },
    ].filter((col) => col.show !== false);
  }, [selectedColLoc, tabs, initialBreadcrumbs, tabBreadcrumbs, sortOrder]);

  const onTreePageChange = (page, limit) => {
    setSupplierTreeLoader(true);
    getContractTreeApi(
      userData[0].id,
      page,
      limit,
      undefined,
      undefined,
      "Supplier"
    )
      .then((contractTreeRes) => {
        setExpandedKeys([]);
        setPaginateTreeData((prev) => ({
          ...prev,
          pageNo: page,
          limit: limit,
          total: contractTreeRes.totalDocs,
        }));
        setSupplierTree(contractTreeRes.docs);
        buildNodeMap(contractTreeRes.docs);
      })
      .catch((error) =>
        console.error("There was an issue fetching supplier tree: ", error)
      )
      .finally(() => setSupplierTreeLoader(false));
  };

  const onSupplierTablePageChange = (page, limit) => {
    setContractTreeTableLoader(true);
    getContractTreeApi(
      userData[0].id,
      page,
      limit,
      undefined,
      undefined,
      "Supplier"
    )
      .then((contractTreeRes) => {
        setPaginateTableData((prev) => ({
          ...prev,
          pageNo: page,
          limit: limit,
          total: contractTreeRes.totalDocs,
        }));
        setContractTableData(contractTreeRes.docs);
      })
      .catch((error) =>
        console.error("There was an issue fetching supplier tree: ", error)
      )
      .finally(() => setContractTreeTableLoader(false));
  };

  const onChildrenTablePageChange = (page, limit, childrenType) => {
    setBreadcrumbsLoader(true);
    getContractTreeApi(
      userData[0].id,
      page,
      limit,
      childrenType === "Supplier" ? selectedItem[0]._id : undefined,
      selectedItem[0]._id,
      selectedItem[0].type
    )
      .then((contractTreeRes) => {
        setPaginateTableData((prev) => ({
          ...prev,
          pageNo: page,
          limit: limit,
          total: contractTreeRes.totalDocs,
        }));
        setSelectedItem(contractTreeRes.docs);
      })
      .catch((error) =>
        console.error("There was an issue fetching supplier tree: ", error)
      )
      .finally(() => setBreadcrumbsLoader(false));
  };

  return (
    <Layout className="tree-component-layout">
      <Row gutter={[0, 8]} align="middle">
        <Col span={24}>
          <Card
            id="breadcrumb-card-id"
            rootClassName="breadcrumb-card"
            bordered={false}
          >
            <Breadcrumbs
              tabBreadcrumbs={tabBreadcrumbs}
              setTabBreadcrumbs={setTabBreadcrumbs}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Splitter
            className="contract-tree-splitter"
            style={{ height: "calc(100vh - 154px)" }}
            onResize={setPanelSizes}
            onResizeStart={() => modifyPanelTransition("start")}
            onResizeEnd={() => modifyPanelTransition("end")}
          >
            <Splitter.Panel
              collapsible
              // defaultSize={panelSizes[0]}
              defaultSize="17%"
              min="17%"
              max="50%"
            >
              <Spin
                spinning={supplierTreeLoader}
                indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
              >
                {supplierTree && supplierTree.length === 0 ? (
                  <Empty
                    rootClassName={"contract-tree-empty-stretched"}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Active Suppliers</span>}
                  />
                ) : (
                  <Tree
                    treeData={supplierTree}
                    className="supplier-contract-tree"
                    rootClassName={
                      paginateTreeData.total <= 20
                        ? "supplier-contract-tree-wrapper-stretched"
                        : "supplier-contract-tree-wrapper"
                    }
                    showIcon
                    showLine
                    switcherIcon={switcherIcon}
                    onSelect={onTreeSelect}
                    onExpand={onTreeExpand}
                    expandedKeys={expandedKeys}
                    titleRender={treeTitleRender}
                  />
                )}
              </Spin>
              <Pagination
                hideOnSinglePage
                showLessItems
                showSizeChanger={false}
                className="mt-10"
                align="end"
                defaultPageSize={paginateTreeData.limit}
                current={paginateTreeData.pageNo}
                total={paginateTreeData.total}
                onChange={onTreePageChange}
              />
            </Splitter.Panel>
            {!selectedItem && (
              <Splitter.Panel collapsible={false} style={{ marginLeft: 6 }}>
                <Spin
                  spinning={contractTreeTableLoader}
                  indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                >
                  {contractTableData.length === 0 ? (
                    <Empty
                      rootClassName={"contract-tree-empty-stretched"}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <>
                          <span style={{ fontWeight: 600 }}>
                            {`Contract Tree`}
                          </span>
                          <span> is empty</span>
                        </>
                      }
                    />
                  ) : (
                    <Table
                      dataSource={contractTableData}
                      columns={contractTreeColumns}
                      onChange={handleTableChange}
                      showSorterTooltip={false}
                      bordered
                      size="large "
                      expandable={{ showExpandColumn: false }}
                      scroll={{
                        x: "calc(500px + 50%)",
                        y:
                          paginateTableData.total <= 20
                            ? "calc(100vh - 199px)"
                            : "calc(100vh - 243px)",
                      }}
                      pagination={false}
                      className="system-setup-table"
                      locale={{
                        emptyText: (
                          <Empty
                            style={{ height: "calc(100vh - 243px)" }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          />
                        ),
                      }}
                    />
                  )}
                  <Pagination
                    hideOnSinglePage
                    className="mt-10"
                    align="end"
                    defaultPageSize={paginateTableData.limit}
                    current={paginateTableData.pageNo}
                    total={paginateTableData.total}
                    onChange={onSupplierTablePageChange}
                  />
                </Spin>
              </Splitter.Panel>
            )}
            {selectedItem && selectedItem[0].type === "error" && (
              <Splitter.Panel style={{ marginLeft: 6 }}>
                <Row>
                  <Col span={24}>
                    <Spin
                      spinning={breadcrumbsLoader}
                      indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                    >
                      <Empty
                        rootClassName={
                          paginateTreeData.total > 20
                            ? "contract-tree-empty"
                            : "contract-tree-empty-stretched"
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          <>
                            <span style={{ fontSize: 13, fontWeight: 600 }}>
                              The requested Supplier or Contract is unavailable
                              or access is restricted.
                            </span>
                          </>
                        }
                      />
                    </Spin>
                  </Col>
                </Row>
              </Splitter.Panel>
            )}
            {selectedItem && selectedItem[0].type === "Supplier" && (
              <Splitter.Panel style={{ marginLeft: 6 }}>
                <Spin
                  spinning={breadcrumbsLoader}
                  indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                >
                  {selectedItem[0].children.length !== 0 &&
                  Object.keys(selectedItem[0].children[0]).length <= 1 &&
                  selectedItem[0].children[0].children.length === 0 ? (
                    <Empty
                      rootClassName={
                        paginateTreeData.total > 20
                          ? "contract-tree-empty"
                          : "contract-tree-empty-stretched"
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <>
                          <span style={{ fontWeight: 600 }}>
                            {`${selectedItem[0].title}`}
                          </span>
                          <span> has no contracts</span>
                        </>
                      }
                    />
                  ) : (
                    <>
                      <Table
                        dataSource={selectedItem[0].children}
                        columns={contractTreeColumns}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        bordered
                        size="large "
                        expandable={{ showExpandColumn: false }}
                        scroll={{
                          x: "calc(500px + 50%)",
                          y: "calc(100vh - 243px)",
                        }}
                        pagination={false}
                        className="system-setup-table"
                        locale={{
                          emptyText: (
                            <Empty
                              style={{ height: "calc(100vh - 243px)" }}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                      />
                      <Pagination
                        hideOnSinglePage
                        className="mt-10"
                        align="end"
                        defaultPageSize={paginateTableData.limit}
                        current={paginateTableData.pageNo}
                        total={paginateTableData.total}
                        onChange={(page, limit) =>
                          onChildrenTablePageChange(page, limit, "Supplier")
                        }
                      />
                    </>
                  )}
                </Spin>
              </Splitter.Panel>
            )}
            {selectedItem &&
              selectedItem[0].type !== "Supplier" &&
              selectedItem[0].type !== "error" && (
                <Splitter.Panel style={{ marginLeft: 6 }}>
                  <Spin
                    spinning={breadcrumbsLoader}
                    indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                  >
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <Card
                          bordered={false}
                          rootClassName="contract-type-card"
                        >
                          Parent Contract
                        </Card>
                      </Col>
                      <Col span={24}>
                        <Table
                          dataSource={selectedItem}
                          columns={contractTreeColumns}
                          onChange={handleTableChange}
                          showSorterTooltip={false}
                          bordered
                          size="large "
                          expandable={{ showExpandColumn: false }}
                          scroll={{
                            x: "calc(500px + 50%)",
                            y: "calc(100vh - 243px)",
                          }}
                          pagination={false}
                          className="system-setup-table"
                          locale={{
                            emptyText: (
                              <Empty
                                style={{ height: "calc(100vh - 243px)" }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              />
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[0, 8]} className="mt-30">
                      <Col span={24}>
                        <Card
                          bordered={false}
                          rootClassName="contract-type-card"
                        >
                          Child Contract
                        </Card>
                      </Col>
                      <Col span={24}>
                        <Table
                          dataSource={selectedItem[0].children}
                          columns={contractTreeColumns}
                          onChange={handleTableChange}
                          showSorterTooltip={false}
                          bordered
                          size="large "
                          expandable={{ showExpandColumn: false }}
                          scroll={{
                            x: "calc(500px + 50%)",
                            y: "calc(100vh - 442px)",
                          }}
                          pagination={false}
                          className="system-setup-table ml-5"
                          locale={{
                            emptyText: (
                              <Empty
                                style={{ height: "calc(100vh - 243px)" }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              />
                            ),
                          }}
                        />
                        <Pagination
                          hideOnSinglePage
                          className="mt-10"
                          align="end"
                          defaultPageSize={paginateTableData.limit}
                          current={paginateTableData.pageNo}
                          total={paginateTableData.total}
                          onChange={(page, limit) =>
                            onChildrenTablePageChange(page, limit, "Contract")
                          }
                        />
                      </Col>
                    </Row>
                  </Spin>
                </Splitter.Panel>
              )}
          </Splitter>
        </Col>
      </Row>
      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => {
            openColEditor(false);
          }}
          onCancel={() => {
            setSearchQuery("");
            openColEditor(false);
            setSelectedCol(selectedBackup);
            setSelectedColLoc(selectedBackup);
          }}
          closable={true}
          footer={null}
        >
          <div>
            <h3>Add Column</h3>
            <span className="col-editor-head">
              Select or Deselect the tag to show in Contract Tree
            </span>
          </div>
          <Row type="flex">
            <Col span={24} className="mt-20">
              <Input
                placeholder="Search column tags"
                allowClear
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
          </Row>
          <div className="mt-20">
            <span className="col-editor-head">
              Different options in choosing the tag for Contract Tree
            </span>
          </div>
          <div className="col-list p-10">
            <Row gutter={[8, 16]} type="flex">
              <Col span={24}>
                {listedCol &&
                listedCol.length !== 0 &&
                listedCol.filter((col) =>
                  col.toLowerCase().includes(searchQuery.toLowerCase())
                ).length !== 0 ? (
                  <Checkbox.Group
                    style={{
                      display: "block",
                      overflowY: "scroll",
                      height: 250,
                    }}
                    options={listedCol
                      .filter((col) =>
                        col.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((col) => ({
                        label: col,
                        value: col,
                        disabled:
                          selectedCol.length === 1 && selectedCol.includes(col),
                      }))}
                    defaultValue={selectedCol}
                    onChange={(value) => {
                      if (searchQuery != "") {
                        filterSearch(value);
                        return;
                      }
                      setSelectedCol(value);
                    }}
                  />
                ) : (
                  <p>No column name matches search query</p>
                )}
              </Col>
            </Row>
          </div>
          <Row type="flex">
            <Col span={24} align="middle" className="mt-20">
              <Button
                className="save-btn"
                onClick={() => {
                  setSearchQuery("");
                  setSelectedBackup(selectedCol);
                  setSelectedColLoc(selectedCol);
                  setContractTreeUpdate({
                    selectedCol: selectedCol,
                    unSelectedCol: listedCol.filter(
                      (element) => !selectedCol.includes(element)
                    ),
                  });
                  openColEditor(false);
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Layout>
  );
};

export default TreeComponent;
