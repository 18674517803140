import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CustomLoader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    {/* <LoadingOutlined spin style={{ fontSize: 40 }} /> */}
    <Spin />
  </div>
);

export default CustomLoader;
