import React, { useState, useRef, useEffect } from "react";
import { Image, Layout, Tabs, Divider } from "antd";
import { useSearchParams } from "react-router-dom";
import "../assets/style/settings.css";
import person_active from "../assets/images/icons/person_4.png";
import person_inactive from "../assets/images/icons/person_inactive.png";
import groups_active from "../assets/images/icons/groups_active.png";
import groups_inactive from "../assets/images/icons/groups.png";
import adminPanel_active from "../assets/images/icons/admin_panel_active.png";
import adminPanel_inactive from "../assets/images/icons/admin_panel_settings.png";
import RoleManagement from "./RoleManagement";
import UserManagement from "./UserManagement";
import GroupManagement from "./GroupManagement";
import ProfileManagement from "./ProfileManagement";
import WorkflowManagement from "./WorkflowManagement";
import TopHeader from "../components/Header";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../store/store";
import config from "../ConfigFile";

const Settings = () => {
  // Varible to hold search param value
  const [searchParams] = useSearchParams();
  // Varaible to hold back button value
  let backToTab = searchParams.get("back");
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Function to set the headerchnager state variable
  useEffect(
    () =>
      setHeaderChanger({
        header: "userManagement",
        headerItem: "1",
        headerItemName: "userManagement",
      }),
    []
  );
  // Variable to hold local header data
  let header = headerChanger.header;
  // State variable to hold tab key value
  const [tabkey, setTabKey] = useState(
    sessionStorage.getItem("userTabKey")
      ? sessionStorage.getItem("userTabKey")
      : backToTab === null
      ? "1"
      : backToTab === "set-roles"
      ? "1"
      : backToTab === "set-users"
      ? "2"
      : backToTab === "set-groups"
      ? "3"
      : "1"
  );
  /* Fucntion called when change the tab
   * @Param key - a particular tab key value
   */
  const onTabChange = (key) => {
    setTabKey(key);
    sessionStorage.setItem("userTabKey", key);
  };
  // Varible to hold the initial tab value
  const initialItems = [
    {
      label: "Workflow",
      children: <WorkflowManagement />,
      key: "1",
      closable: false,
    },
  ];
  // State variable to hold the avtive tab key
  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  // State variable to hold set initial active tab
  const [wfItems, setWfItems] = useState(initialItems);
  // Variable to hold use ref value 0
  const newTabIndex = useRef(0);
  // Funtion to handle set the active state variable
  const add = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...wfItems];
    newPanes.push({
      label: "Workflow",
      children: <WorkflowManagement />,
      key: newActiveKey,
    });
    setWfItems(newPanes);
    setActiveKey(newActiveKey);
  };
  /* Function to remove the tab based on the close icon click event
   * @Param  targetKey - The key of the panel to be removed
   */
  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    wfItems.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = wfItems.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setWfItems(newPanes);
    setActiveKey(newActiveKey);
  };
  /*  Event handler for Tab change
     @param  tab - The selected Tab's Key
  */
  const TabsIcon = (tab) => {
    return (
      <>
        <div className="tabs-title-main">
          {tab.state.selected === "1" ? (
            tab.state.tabkey === "1" ? (
              <div className="tabs-title">
                <img src={person_active} /> <p>{config.tabs.roles}</p>
              </div>
            ) : (
              <div className="tabs-title">
                <img src={person_inactive} /> <p>{config.tabs.roles}</p>
              </div>
            )
          ) : tab.state.selected === "2" ? (
            tab.state.tabkey === "2" ? (
              <div className="tabs-title">
                <img src={adminPanel_active} /> <p>{config.tabs.users}</p>
              </div>
            ) : (
              <div className="tabs-title">
                <img src={adminPanel_inactive} /> <p>{config.tabs.users}</p>
              </div>
            )
          ) : tab.state.selected === "3" ? (
            tab.state.tabkey === "3" ? (
              <div className="tabs-title">
                <img src={groups_active} /> <p>{config.tabs.groups}</p>
              </div>
            ) : (
              <div className="tabs-title">
                <img src={groups_inactive} /> <p>{config.tabs.groups}</p>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </>
    );
  };
  // Variable to hold all tab names
  const items = [
    {
      key: "1",
      label: <TabsIcon state={{ tabkey, selected: "1" }} />,
      children: <RoleManagement tabKey={tabkey} />,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "2",
      label: <TabsIcon state={{ tabkey, selected: "2" }} />,
      children: <UserManagement tabKey={tabkey} />,
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "3",
      label: <TabsIcon state={{ tabkey, selected: "3" }} />,
      children: <GroupManagement tabKey={tabkey} />,
    },
  ];

  const { TabPane } = Tabs;

  return (
    <>
      <Layout className="header-layout">
        <TopHeader
          headerChange={header}
          feedRow={false}
          searchRow={false}
          sticky=""
        />

        <div className="user-manage-div">
          <Tabs
            defaultActiveKey={tabkey}
            // items={items}
            type="card"
            onChange={onTabChange}
          >
            {items.map((item) => (
              <TabPane key={item.key} tab={item.label} disabled={item.disabled}>
                {tabkey === item.key && item.children}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Layout>
    </>
  );
};

export default Settings;
