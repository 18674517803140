// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useRecoilState } from "recoil";
// import { DocuSignContract } from "../store/store";

// const SignedDocumentViewer = ({
//   envelopeId,
//   accessToken,
//   sendSignedStatus,
// }) => {
//   console.log("sdvA", accessToken);
//   console.log("sdcE", envelopeId);
//   console.log("sendSignedStatus", sendSignedStatus);

//   const [documentUrl, setDocumentUrl] = useState("");
//   const contractId = useRecoilState(DocuSignContract);
//   console.log("StorecontractId", contractId);

//   useEffect(() => {
//     console.log("sdv");
//     const fetchSignedDocument = async () => {
//       if (!contractId) {
//         console.error("No contractId available");
//         return;
//       }
//       try {
//         const response = await axios.get(
//           `http://localhost:4000/api/v1/docusign/download-document`,
//           {
//             params: { envelopeId, accessToken, contractId: contractId[0] },
//           }
//         );
//         setDocumentUrl(response.data.url);
//       } catch (error) {
//         console.error("Error fetching signed document", error);
//       }
//     };
//     if (
//       accessToken &&
//       envelopeId &&
//       sendSignedStatus === true &&
//       contractId[0]
//     ) {
//       fetchSignedDocument();
//     }
//   }, []);

//   return (
//     <div>
//       {documentUrl ? (
//         <div>
//           <a href={documentUrl} target="_blank" rel="noopener noreferrer">
//             View Signed Document
//           </a>
//         </div>
//       ) : (
//         ""
//       )}
//     </div>
//   );
// };

// export default SignedDocumentViewer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { useRecoilState } from "recoil";
import { Modal, Button } from "antd";
import { DocuSignContract } from "../store/store";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SignedDocumentViewer = ({
  envelopeId,
  accessToken,
  sendSignedStatus,
}) => {
  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const contractId = useRecoilState(DocuSignContract);

  useEffect(() => {
    const fetchSignedDocument = async () => {
      try {
        const response = await axios.get(
          `http://localhost:4000/api/v1/docusign/download-document`,
          {
            params: { envelopeId, accessToken, contractId: contractId[0] },
            responseType: "arraybuffer",
          }
        );
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        setPdfData(URL.createObjectURL(pdfBlob));
      } catch (error) {
        console.error("Error fetching signed document", error);
      }
    };

    if (
      accessToken &&
      envelopeId &&
      sendSignedStatus === true &&
      contractId[0]
    ) {
      fetchSignedDocument();
    }
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      {pdfData ? (
        <Button type="primary" onClick={showModal} disabled={!pdfData}>
          View Signed Document
        </Button>
      ) : (
        ""
      )}

      <Modal
        title="Signed Document"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        {pdfData ? (
          <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          <p>Loading document...</p>
        )}
      </Modal>
    </div>
  );
};

export default SignedDocumentViewer;
