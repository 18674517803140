import { axiosInstance } from "../utils/common";

export const loginApi = (userCred) => {
  return axiosInstance
    .post("v1/auth/login", userCred)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const refreshTokenApi = (refreshToken) => {
  return axiosInstance
    .post("v1/auth/refresh-tokens", { refreshToken })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createUserApi = (userBody) => {
  return axiosInstance
    .post("v1/users", userBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getUsersApi = () => {
  return axiosInstance
    .get("v1/users")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getUserByIdApi = (userId) => {
  return axiosInstance
    .get(`v1/users/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getUsersPaginationApi = (page, limit, filterObj, sortColumn) => {
  return axiosInstance
    .post(`v1/users/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// edit user
export const editUserApi = (userId, userDetail) => {
  return axiosInstance
    .patch(`v1/users/${userId}`, userDetail)
    .then((res) => res.data)
    .catch((err) => console.log(err));
  // .catch((e) => console.log("e", e));
};

// delete user
export const deleteUserApi = (userId) => {
  console.log("userId", userId);
  return axiosInstance
    .delete(`v1/users/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
  // .catch((e) => console.log("e", e));
};

export const verifyEmail = (userId) => {
  return axiosInstance
    .patch(`v1/users/verify-email/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const setPasswordApi = (userId, userDetail) => {
  return axiosInstance
    .patch(`v1/users/set-password/${userId}`, userDetail)
    .then((res) => res.data)
    .catch((err) => console.log(err));
  // .catch((e) => console.log("e", e));
};

// reset-password
export const resetPasswordApi = (userId, userDetail) => {
  return axiosInstance
    .patch(`v1/users/reset-password/${userId}`, userDetail)
    .then((res) => res.data)
    .catch((err) => console.log(err));
  // .catch((e) => console.log("e", e));
};

export const getPasswordLinkApi = (email, type) => {
  return axiosInstance
    .get(`v1/users/set-password-link/${email}/${type}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
  // .catch((e) => console.log("e", e));
};

export const verifyPasswordApi = (userId) => {
  return axiosInstance
    .get(`v1/users/verify-password/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const RegenerateLinkApi = (userId) => {
  return axiosInstance
    .get(`v1/users/regenerate-verification-link/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const RegeneratePasswordLink = (userId, type) => {
  return axiosInstance
    .get(`v1/users/regenerate-password-link/${userId}/${type}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getFiltersApi = (userId) => {
  return axiosInstance
    .get(`v1/filters/get/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createFiltersApi = (filterBody) => {
  return axiosInstance
    .post("v1/filters/save", filterBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const searchUserApi = (searchQuery) => {
  return axiosInstance
    .get(`v1/users/search/${searchQuery}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const searchUserByEmailApi = (emailList) => {
  return axiosInstance
    .post(`v1/users/search/emaillist`, emailList)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getUsersByRole = (roleId) => {
  return axiosInstance
    .get(`v1/users/get/user/by-role/${roleId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllUserFiltersApi = () => {
  return axiosInstance
    .get(`v1/users/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
