import { axiosInstance } from "../utils/common";

export const createActivityLogApi = (logBody) => {
  return axiosInstance
    .post("v1/activityLog", logBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// get contract logs by contract ID
export const getLogApi = (contractId) => {
  return axiosInstance
    .get(`v1/activityLog/${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getUserLogApi = (userId) => {
  return axiosInstance
    .get(`v1/userActivityLog/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
