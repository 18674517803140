import React, { useState, useEffect, useForm } from "react";
import { Button, Modal, Image, Row, Col } from "antd";
import { Form, Layout } from "antd"; //antd components
import { useNavigate, Link, Navigate } from "react-router-dom";
import TopHeader from "../../Header";
import { useRecoilState } from "recoil";
import "../../../assets/style/setup.css";
import { HeaderChangerAtom } from "../../../store/store";
import flowsheet from "../../../assets/images/icons/flowsheet.svg";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";

const CreateWorkFlow = () => {
  // state variable hold the model true pr false
  const [isModalOpen, setIsModalOpen] = useState(true);
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //global state variable hold the header
  let header = headerChanger.header;
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  //global state variable hold the navigating different routes
  const history = useNavigate();
  // global state variable hold the key while switch header name
  const menuKey = "5";
  // global variable hold the  create a form instance
  const [form] = Form.useForm();
  // function handle the cancel in model pop up
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <div>
        <Modal
          className="system-setup"
          footer={null}
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Row className="modal-image mb-10">
            <Col span={24} align="middle">
              <Image src={flowsheet} alt="Flowsheet" preview={false} />
            </Col>
          </Row>
          <h2 className="modal-title">Select Workflow</h2>
          <div className="modal-content">
            <p>Capture A Way For Your Workflow process</p>
            <div className="modal-popup-footer mt-25">
              <Row type="flex">
                <Col span={12} align="middle">
                  <button
                    type="button"
                    className="btn btn-primary workflow-modal-btn"
                    onClick={() =>
                      history("/contract-setup/Excel/excel-Work-Flow")
                    }
                  >
                    Excel Workflow
                  </button>
                </Col>
                <Col span={12} align="middle">
                  <button
                    type="button"
                    disabled
                    className="btn btn-primary workflow-modal-btn"
                    onClick={() =>
                      history("/contract-setup/Online/online-Work-Flow")
                    }
                  >
                    Online Workflow
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </div>{" "}
    </Layout>
  );
};

export default CreateWorkFlow;
