import React, { useState, useRef } from "react";
import {
  Image,
  Card,
  Row,
  Col,
  Button,
  Form,
  Radio,
  Select,
  Input,
  Tree,
  Layout,
} from "antd"; //antd components
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../components/Header";
import arrow_left from "../assets/images/icons/arrow_left.svg";
import arrow_right from "../assets/images/icons/arrow_right.svg";
import arrow_back from "../assets/images/icons/arrow_back_ios.svg";
import search from "../assets/images/icons/search.svg";
import refresh from "../assets/images/icons/refresh.svg";
import divider from "../assets/images/icons/divider.svg";
import "../assets/style/setup.css";

function ProfilePriceDetails() {
  //global state variable hold the corresponding cost center details using location
  const { state } = useLocation();
  // global state variable hold the key while switch header name
  const menuKey = "5";
  //global state variable hold the navigating different routes
  const history = useNavigate();
  const [radioSelect, setRadioSelect] = useState(state && state.contract);
  const [selectedContractCurrency, setSelectedContractCurrency] = useState(
    state &&
      state.length !== 0 &&
      state.contract_currency.length !== 0 &&
      state.contract_currency.map((data) => data.currency)
  );
  const [selectedDeliveryCurrency, setSelectedDeliveryCurrency] = useState(
    state &&
      state.length !== 0 &&
      state.delivery_currency.length !== 0 &&
      state.delivery_currency.map((data) => data.currency)
  );
  const [showSearch, setShowSearch] = useState("");
  const [deliverySearchVal, setDeliverySearchVal] = useState();
  const [contractSearchVal, setContractSearchVal] = useState();
  const [selectedDeliverySearchVal, setSelectedDeliverySearchVal] = useState();
  const [selectedContractSearchVal, setSelectedContractSearchVal] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);

  // button disable
  let disableVal = "disable-btn";
  const [disable, setDisable] = useState(disableVal);
  // const enableButton = () => {
  //     setDisable('');
  // }

  const searchInput = useRef(); //setting reference hook for search funtionality
  const [availableDeliveryCurrency, setAvailableDeliveryCurrency] = useState([
    "INR",
    "RUL",
  ]);
  const [availableContractCurrency, setAvailableContractCurrency] = useState([
    "USD",
    "EUR",
    "GDP",
  ]);

  const clauseLibrary = [
    {
      title: "1. Information-1",
      key: "1",
      children: [
        {
          title: "Content-1-1",
          key: "1-1",
          children: [
            {
              title: "Sub-Content-1-1",
              key: "1-1-1",
            },
            {
              title: "Sub-Content-1-2",
              key: "1-1-2",
            },
            {
              title: "Sub-Content-1-3",
              key: "1-1-3",
            },
          ],
        },
        {
          title: "Content-1-2",
          key: "1-2",
          children: [
            {
              title: "Sub-Content-2-1",
              key: "1-2-1",
            },
            {
              title: "Sub-Content-2-2",
              key: "1-2-2",
            },
            {
              title: "Sub-Content-2-3",
              key: "1-2-3",
            },
          ],
        },
        {
          title: "Content-1-3",
          key: "1-3",
        },
      ],
    },
    {
      title: "2. Information-2",
      key: "2",
      children: [
        {
          title: "Content-2-1",
          key: "2-1",
        },
        {
          title: "Content-2-2",
          key: "2-2",
        },
        {
          title: "Content-2-3",
          key: "2-3",
        },
      ],
    },
    {
      title: "3. Information-3",
      key: "3",
    },
  ];

  const onSelect = (selectedKeys, info) => {
    setDisable("");
  };

  const onCheck = (checkedKeys, info) => {
    setDisable("");
    console.log("onCheck", checkedKeys);
    console.log("onCheckInfo1", info.checkedNodesPositions);
    setSelectedKeys(checkedKeys);
  };

  //on submit form
  const onFinish = (values) => {
    console.log("values", values);
  };

  //error on form submit
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeRadio = (e) => {
    setDisable("");
    setRadioSelect(e.target.value);
  };

  const handleChangeTable = (value) => {
    setDisable("");
    console.log(`selected ${value}`);
  };

  // const [isOpen, setIsOpen] = useState(false);

  // const toggleOpen = () => {
  //     setIsOpen(!isOpen);
  // };

  const selectContractCurrency = (currency) => {
    setDisable("");
    setSelectedContractCurrency((prevActiveCurrency) => {
      if (prevActiveCurrency.includes(currency)) {
        return prevActiveCurrency.filter(
          (activeFilter) => activeFilter !== currency
        );
      } else {
        return [...prevActiveCurrency, currency];
      }
    });
  };

  const selectDeliveryCurrency = (currency) => {
    setDisable("");
    setSelectedDeliveryCurrency((prevActiveCurrency) => {
      if (prevActiveCurrency.includes(currency)) {
        return prevActiveCurrency.filter(
          (activeFilter) => activeFilter !== currency
        );
      } else {
        return [...prevActiveCurrency, currency];
      }
    });
  };

  return (
    <div className="gradient-bg price-profile">
      <Layout className="header-layout">
        <Form
          name="priceProfile"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // fields={[
          //     {
          //         name: ["contract_pricing"],
          //         value: state.contract,
          //     },
          //     {
          //         name: ["fx_table"],
          //         value: state.fx_table,
          //     },
          //     {
          //         name: ["clause_library"],
          //         value: state.clause_library,
          //     },
          // ]}
        >
          <div className="sticky">
            <TopHeader
              feedRow={false}
              searchRow={false}
              menuKey={menuKey}
              sticky=""
            />
            <div className="m-10 content-padding">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={18}
                  lg={18}
                  xl={18}
                >
                  <Card className="setup-header-card">
                    <Row align="middle" type="flex" gutter={[8, 16]}>
                      <Col
                        className="vertical-align"
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <Row align="middle" type="flex" gutter={[8, 16]}>
                          <Col span={12}>
                            <Image
                              src={arrow_left}
                              preview={false}
                              className="cursor-pointer"
                              onClick={() =>
                                history(`/system-setup/?back=con-pp`)
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <Image
                              src={arrow_right}
                              preview={false}
                              className="cursor-pointer"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={17}
                        sm={17}
                        md={19}
                        lg={19}
                        xl={19}
                      >
                        <span>Contract Setup</span> &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span>Price Profile</span>&nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span>{state.price_profile_name}</span>
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        xl={1}
                      >
                        <Image
                          src={refresh}
                          onClick={() => window.location.reload()}
                          preview={false}
                          className="cursor-pointer"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    // loading={tableLoader}
                    onClick={() => {
                      history(`/system-setup/?back=con-pp`);
                    }}
                    className={`cancel-btn ${disable}`}
                    style={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    // loading={tableLoader}
                    className={`primaryBtn save-btn ${disable}`}
                    style={{ width: "100%" }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div className="content-padding price-profile-content">
            <div>
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {/* <Form.Item
                                        name="contract_pricing"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select contract pricing!",
                                        },
                                    ]}
                                    > */}
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={24} className="vertical-align">
                        <h4 className="div-head">Contract Pricing</h4>
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "0px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      <Col span={24} className="vertical-align">
                        <Radio.Group
                          className="radio-group text-center"
                          onChange={onChangeRadio}
                          value={radioSelect}
                        >
                          <Row gutter={[24, 0]} align="middle" type="flex">
                            <Col
                              className="vertical-align"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                            >
                              <Radio value="Fixed">Fixed</Radio>
                            </Col>
                            <Col
                              className="vertical-align"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                            >
                              <Radio value="Variable">Variable</Radio>
                            </Col>
                            <Col
                              className="vertical-align"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                            >
                              <Radio value="Agreed">Agreed</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </div>
                  {/* </Form.Item> */}
                </Col>

                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {/* <Form.Item
                                        name="fx_table"
                                    > */}
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={24} className="vertical-align">
                        <h4 className="div-head">Choose Fx table</h4>
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      <Col span={20} offset={2} className="vertical-align">
                        <Select
                          defaultValue={state.fx_table}
                          style={{ width: "100%" }}
                          onChange={handleChangeTable}
                          options={[{ value: "fx_table", label: "Fx Table" }]}
                        />
                      </Col>
                    </Row>
                  </div>
                  {/* </Form.Item> */}
                </Col>
              </Row>

              <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {/* <Form.Item
                                        name="clause_library"
                                    > */}
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={22} className="vertical-align">
                        <h4 className="div-head">Clause Library</h4>
                      </Col>
                      <Col span={2} className="vertical-align">
                        <Image
                          src={search}
                          preview={false}
                          className="cursor-pointer"
                        />
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      <Col span={24} className="vertical-align">
                        <Tree
                          checkable
                          switcherIcon={
                            <DownOutlined style={{ fontSize: "12px" }} />
                          }
                          onCheck={onCheck}
                          onSelect={onSelect}
                          treeData={clauseLibrary}
                        />
                      </Col>
                    </Row>
                  </div>
                  {/* </Form.Item> */}
                </Col>
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={22} className="vertical-align">
                        <h4 className="div-head">Selected Clauses</h4>
                      </Col>
                      <Col span={2} className="vertical-align">
                        <Image
                          src={search}
                          preview={false}
                          className="cursor-pointer"
                        />
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      <Col span={24} className="vertical-align selected-clause">
                        {selectedKeys && selectedKeys.length !== 0 && (
                          <Tree
                            checkable
                            switcherIcon={
                              <DownOutlined style={{ fontSize: "12px" }} />
                            }
                            selectedKeys={selectedKeys}
                            checkedKeys={selectedKeys}
                            defaultSelectedKeys={selectedKeys}
                            defaultCheckedKeys={selectedKeys}
                            // treeData={clauseLibrary}
                            treeData={clauseLibrary}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {/* <Form.Item
                                        name="contract_currency"
                                    > */}
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={22} className="vertical-align">
                        {showSearch === "contractCurrency" ? (
                          <Input
                            // prefix={<SearchOutlined />}
                            className="has-search div-head"
                            ref={searchInput}
                            style={{ width: "90%" }}
                            // onKeyDown={handleSearch}
                            // onChange={onSearchInputChange}
                            onChange={(e) => {
                              setContractSearchVal(e.target.value);
                            }}
                            placeholder="Search Contract currency"
                          />
                        ) : (
                          <h4 className="div-head">
                            Available Contract currency
                          </h4>
                        )}
                      </Col>
                      <Col span={2} className="vertical-align">
                        {showSearch === "contractCurrency" ? (
                          <Image
                            src={arrow_right}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("");
                              setContractSearchVal();
                            }}
                          />
                        ) : (
                          <Image
                            src={search}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("contractCurrency");
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      {availableContractCurrency &&
                        availableContractCurrency.length !== 0 &&
                        contractSearchVal &&
                        availableContractCurrency
                          .filter((data) =>
                            data
                              .toLowerCase()
                              .includes(contractSearchVal.toLowerCase())
                          )
                          .map((currency) => (
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className="vertical-align"
                            >
                              <Button
                                type="primary"
                                className={
                                  selectedContractCurrency.includes(currency)
                                    ? "selected-btn"
                                    : "select-btn"
                                }
                                style={{ width: "100%" }}
                                onClick={() => {
                                  setDisable("");
                                  selectContractCurrency(currency);
                                }}
                              >
                                {currency}
                              </Button>
                            </Col>
                          ))}
                      {contractSearchVal &&
                        availableContractCurrency.filter((data) =>
                          data
                            .toLowerCase()
                            .includes(contractSearchVal.toLowerCase())
                        ).length === 0 && (
                          <Col span={24} className="no-data">
                            <p>No Currency Available</p>
                          </Col>
                        )}
                      {availableContractCurrency &&
                        availableContractCurrency.length !== 0 &&
                        !contractSearchVal &&
                        availableContractCurrency.map((currency) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="vertical-align"
                          >
                            <Button
                              type="primary"
                              className={
                                selectedContractCurrency.includes(currency)
                                  ? "selected-btn"
                                  : "select-btn"
                              }
                              style={{ width: "100%" }}
                              onClick={() => selectContractCurrency(currency)}
                            >
                              {currency}
                            </Button>
                          </Col>
                        ))}
                      {availableContractCurrency &&
                        availableContractCurrency.length === 0 &&
                        !contractSearchVal && (
                          <Col span={24} className="no-data">
                            <p>No Currency Available</p>
                          </Col>
                        )}
                    </Row>
                  </div>
                  {/* </Form.Item> */}
                </Col>
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={22} className="vertical-align">
                        {showSearch === "selectedContractCurrency" ? (
                          <Input
                            // prefix={<SearchOutlined />}
                            className="has-search div-head"
                            ref={searchInput}
                            style={{ width: "90%" }}
                            // onKeyDown={handleSearch}
                            // onChange={onSearchInputChange}
                            onChange={(e) => {
                              setSelectedContractSearchVal(e.target.value);
                            }}
                            placeholder="Search Selected Contract currency"
                          />
                        ) : (
                          <h4 className="div-head">
                            Selected Contract currency
                          </h4>
                        )}
                      </Col>
                      <Col span={2} className="vertical-align">
                        {showSearch === "selectedContractCurrency" ? (
                          <Image
                            src={arrow_right}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("");
                              setSelectedContractSearchVal();
                            }}
                          />
                        ) : (
                          <Image
                            src={search}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("selectedContractCurrency");
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      {selectedContractCurrency &&
                        selectedContractCurrency.length !== 0 &&
                        selectedContractSearchVal &&
                        selectedContractCurrency
                          .filter((data) =>
                            data
                              .toLowerCase()
                              .includes(selectedContractSearchVal.toLowerCase())
                          )
                          .map((currency) => (
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className="vertical-align"
                            >
                              <Button
                                type="primary"
                                className="selected-btn"
                                style={{ width: "100%", cursor: "default" }}
                              >
                                {currency}
                              </Button>
                            </Col>
                          ))}
                      {selectedContractSearchVal &&
                        selectedContractCurrency.filter((data) =>
                          data
                            .toLowerCase()
                            .includes(selectedContractSearchVal.toLowerCase())
                        ).length === 0 && (
                          <Col span={24} className="no-data">
                            <p>No Selected Contract Currency</p>
                          </Col>
                        )}
                      {selectedContractCurrency &&
                        selectedContractCurrency.length !== 0 &&
                        !selectedContractSearchVal &&
                        selectedContractCurrency.map((currency) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="vertical-align"
                          >
                            <Button
                              type="primary"
                              className="selected-btn"
                              style={{ width: "100%", cursor: "default" }}
                            >
                              {currency}
                            </Button>
                          </Col>
                        ))}
                      {selectedContractCurrency &&
                        selectedContractCurrency.length === 0 &&
                        !selectedContractSearchVal && (
                          <Col span={24} className="no-data">
                            <p>No Selected Contract Currency</p>
                          </Col>
                        )}
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {/* <Form.Item
                                        name="delivery_currency"
                                    > */}
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={22} className="vertical-align">
                        {showSearch === "deliveryCurrency" ? (
                          <Input
                            // prefix={<SearchOutlined />}
                            className="has-search div-head"
                            ref={searchInput}
                            style={{ width: "90%" }}
                            // onKeyDown={handleSearch}
                            // onChange={onSearchInputChange}
                            onChange={(e) => {
                              setDeliverySearchVal(e.target.value);
                            }}
                            placeholder="Search Delivery currency"
                          />
                        ) : (
                          <h4 className="div-head">
                            Available Delivery currency
                          </h4>
                        )}
                      </Col>
                      <Col span={2} className="vertical-align">
                        {showSearch === "deliveryCurrency" ? (
                          <Image
                            src={arrow_right}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("");
                              setDeliverySearchVal();
                            }}
                          />
                        ) : (
                          <Image
                            src={search}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("deliveryCurrency");
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      {availableDeliveryCurrency &&
                        availableDeliveryCurrency.length !== 0 &&
                        deliverySearchVal &&
                        availableDeliveryCurrency
                          .filter((data) =>
                            data
                              .toLowerCase()
                              .includes(deliverySearchVal.toLowerCase())
                          )
                          .map((currency) => (
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className="vertical-align"
                            >
                              <Button
                                type="primary"
                                className={
                                  selectedDeliveryCurrency.includes(currency)
                                    ? "selected-btn"
                                    : "select-btn"
                                }
                                style={{ width: "100%" }}
                                onClick={() => {
                                  setDisable("");
                                  selectDeliveryCurrency(currency);
                                }}
                              >
                                {currency}
                              </Button>
                            </Col>
                          ))}
                      {deliverySearchVal &&
                        availableDeliveryCurrency.filter((data) =>
                          data
                            .toLowerCase()
                            .includes(deliverySearchVal.toLowerCase())
                        ).length === 0 && (
                          <Col span={24} className="no-data">
                            <p>No Currency Available</p>
                          </Col>
                        )}
                      {availableDeliveryCurrency &&
                        availableDeliveryCurrency.length !== 0 &&
                        !deliverySearchVal &&
                        availableDeliveryCurrency.map((currency) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="vertical-align"
                          >
                            <Button
                              type="primary"
                              className={
                                selectedDeliveryCurrency.includes(currency)
                                  ? "selected-btn"
                                  : "select-btn"
                              }
                              style={{ width: "100%" }}
                              onClick={() => selectDeliveryCurrency(currency)}
                            >
                              {currency}
                            </Button>
                          </Col>
                        ))}
                      {availableDeliveryCurrency &&
                        availableDeliveryCurrency.length === 0 &&
                        !deliverySearchVal && (
                          <Col span={24} className="no-data">
                            <p>No Currency Available</p>
                          </Col>
                        )}
                    </Row>
                  </div>
                  {/* </Form.Item> */}
                </Col>
                <Col
                  className="self-stretch-col"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className="glass-div-form">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "15px 20px 5px" }}
                    >
                      <Col span={22} className="vertical-align">
                        {showSearch === "selectedDeliveryCurrency" ? (
                          <Input
                            // prefix={<SearchOutlined />}
                            className="has-search div-head"
                            ref={searchInput}
                            style={{ width: "90%" }}
                            // onKeyDown={handleSearch}
                            // onChange={onSearchInputChange}
                            onChange={(e) => {
                              setSelectedDeliverySearchVal(e.target.value);
                            }}
                            placeholder="Search Selected Delivery currency"
                          />
                        ) : (
                          <h4 className="div-head">
                            Selected Delivery currency
                          </h4>
                        )}
                      </Col>
                      <Col span={2} className="vertical-align">
                        {showSearch === "selectedDeliveryCurrency" ? (
                          <Image
                            src={arrow_right}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("");
                              setSelectedDeliverySearchVal();
                            }}
                          />
                        ) : (
                          <Image
                            src={search}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowSearch("selectedDeliveryCurrency");
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                    <Image src={divider} preview={false} />
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "10px 20px 20px" }}
                      gutter={[8, 16]}
                    >
                      {selectedDeliveryCurrency &&
                        selectedDeliveryCurrency.length !== 0 &&
                        selectedDeliverySearchVal &&
                        selectedDeliveryCurrency
                          .filter((data) =>
                            data
                              .toLowerCase()
                              .includes(selectedDeliverySearchVal.toLowerCase())
                          )
                          .map((currency) => (
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              className="vertical-align"
                            >
                              <Button
                                type="primary"
                                className="selected-btn"
                                style={{ width: "100%", cursor: "default" }}
                              >
                                {currency}
                              </Button>
                            </Col>
                          ))}
                      {selectedDeliverySearchVal &&
                        selectedDeliveryCurrency.filter((data) =>
                          data
                            .toLowerCase()
                            .includes(selectedDeliverySearchVal.toLowerCase())
                        ).length === 0 && (
                          <Col span={24} className="no-data">
                            <p>No Selected Contract Currency</p>
                          </Col>
                        )}
                      {selectedDeliveryCurrency &&
                        selectedDeliveryCurrency.length !== 0 &&
                        !selectedDeliverySearchVal &&
                        selectedDeliveryCurrency.map((currency) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            className="vertical-align"
                          >
                            <Button
                              type="primary"
                              className="selected-btn"
                              style={{ width: "100%", cursor: "default" }}
                            >
                              {currency}
                            </Button>
                          </Col>
                        ))}
                      {selectedDeliveryCurrency &&
                        selectedDeliveryCurrency.length === 0 &&
                        !selectedDeliverySearchVal && (
                          <Col span={24} className="no-data">
                            <p>No Selected Delivery Currency</p>
                          </Col>
                        )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Layout>
    </div>
  );
}

export default ProfilePriceDetails;
