import { Table, Row, Col, Select, Spin, Switch, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { monthsShort } from "../../utils/data";
import { isEmptyObject } from "../../utils/common";

const NewAggregateValue = ({ aggregateTableData }) => {
  const [loading, setLoading] = useState(true);
  const [yearFilter, setYearFilter] = useState("all");
  const [aggregateDataSource, setAggregateDataSource] = useState([]);
  const [monthlyView, setMonthlyView] = useState(false);
  const [viewMode, setViewMode] = useState("cost");
  const [totalAnnualMode, setTotalAnnualMode] = useState("total");

  const commonStyles = {
    cellText: {
      fontSize: 12,
      fontWeight: 600,
      color: "var(--color-solid-darkergrey)",
      textAlign: "right",
      display: "block",
    },
    contractTypeBadge: {
      borderRadius: 20,
      padding: "3px 8px",
      marginRight: 8,
      background: "var(--color-solid-darkblue)",
      color: "white",
      fontSize: 9,
    },
    contractNameText: {
      fontSize: 12,
      fontWeight: 600,
      color: "var(--color-solid-darkergrey)",
    },
    yearLabelText: {
      fontSize: 13,
      fontWeight: 600,
      color: "var(--color-solid-darkergrey)",
    },
  };

  const processTree = (node, year) => {
    const processValues = (valueObject) => {
      if (!valueObject || valueObject[year] === undefined) return "0";

      if (viewMode === "cost") {
        return parseFloat(valueObject[year].toFixed(2)).toLocaleString("en-us");
      } else if (viewMode === "days") {
        return valueObject[year]; // Return raw "Days" values directly
      }
    };

    if (node.contract_value) {
      node.contract_value =
        viewMode === "cost"
          ? processValues(node.contract_value)
          : node.contract_days
          ? node.contract_days[year] || "0"
          : "0";
    }

    if (node.aggregate_value) {
      node.aggregate_value =
        viewMode === "cost"
          ? processValues(node.aggregate_value)
          : node.aggregate_days
          ? node.aggregate_days[year] || "0"
          : "0";
    }

    if (node.engagement_value) {
      node.engagement_value =
        viewMode === "cost"
          ? processValues(node.engagement_value)
          : node.engagement_days
          ? node.engagement_days[year] || "0"
          : "0";
    }

    if (node.children && node.children.length > 0) {
      node.children = node.children
        .map((child) => processTree(child, year))
        .filter((child) => !!child);
    } else {
      delete node.children;
    }

    return node;
  };

  const extractUniqueYears = (node, years = new Set()) => {
    const addYears = (values) => {
      if (values) {
        Object.keys(values).forEach((key) => {
          if (key !== "all") years.add(key);
        });
      }
    };

    addYears(node.contract_value);
    addYears(node.aggregate_value);
    addYears(node.engagement_value);

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => extractUniqueYears(child, years));
    }

    return years;
  };

  const uniqueYears = Array.from(
    extractUniqueYears(JSON.parse(JSON.stringify(aggregateTableData)))
  ).sort((a, b) => a - b);

  const selectOptions = [
    {
      label: "All",
      value: "all",
    },
    ...uniqueYears.map((year) => ({ label: year, value: year })),
  ];

  const generateMonthlyBreakdown = (node, year) => {
    const monthlyData = {};
    let total = 0;

    if (node.commercial_details) {
      node.commercial_details.forEach((detail) => {
        const price = parseFloat(detail.Price);
        const days = detail.Days;

        const processMonth = (month, dayValue) => {
          if (!monthlyData[month]) monthlyData[month] = 0;

          if (viewMode === "cost") {
            const ratePeriod = detail["Rate Period"];
            if (ratePeriod === "Day Rate" || ratePeriod === "Monthly Rate") {
              monthlyData[month] += price * dayValue;
            } else if (ratePeriod === "Yearly Rate") {
              monthlyData[month] += (price * dayValue) / 12;
            }
          } else if (viewMode === "days") {
            monthlyData[month] += dayValue;
          }
        };

        if (year === "all") {
          Object.keys(days).forEach((availableYear) => {
            monthsShort.forEach((month) => {
              const dayValue = days[availableYear]?.[month] || 0;
              processMonth(month, dayValue);
            });
          });
        } else if (days[year]) {
          monthsShort.forEach((month) => {
            const dayValue = days[year][month] || 0;
            processMonth(month, dayValue);
          });
        }
      });
    }

    monthsShort.forEach((month) => {
      total += monthlyData[month] || 0;
      monthlyData[month] = parseFloat((monthlyData[month] || 0).toFixed(2));
    });

    node.monthly_breakdown = {
      ...monthlyData,
      total: parseFloat(total.toFixed(2)),
    };

    if (node.children && node.children.length > 0) {
      node.children = node.children
        .map((child) => generateMonthlyBreakdown(child, year))
        .filter((child) => !!child);
    } else {
      delete node.children;
    }

    return node;
  };

  const onChangeYear = (value) => {
    const currentYear = new Date().getFullYear().toString();
    if (value == currentYear) {
      setTotalAnnualMode("annual");
    } else {
      setTotalAnnualMode("total");
    }
    setYearFilter(value);
  };

  const switchDataView = () => {
    setLoading(true);

    if (monthlyView) {
      // Return to default view
      setAggregateDataSource(
        processTree(JSON.parse(JSON.stringify(aggregateTableData)), yearFilter)
      );
    } else {
      // Switch to monthly breakdown view
      setAggregateDataSource(
        generateMonthlyBreakdown(
          JSON.parse(JSON.stringify(aggregateTableData)),
          yearFilter
        )
      );
    }

    setLoading(false);
    setMonthlyView(!monthlyView);
  };

  const switchTotalAnnual = (checked) => {
    const currentYear = new Date().getFullYear().toString();
    if (checked) {
      setYearFilter(currentYear);
      setTotalAnnualMode("annual");
    } else {
      setTotalAnnualMode("total");
      setYearFilter("all");
    }
  };

  const renderContractName = (text, record) => (
    <span style={commonStyles.contractNameText}>
      {record.contract_type && (
        <span style={commonStyles.contractTypeBadge}>
          {record.contract_type}
        </span>
      )}
      {text}
    </span>
  );

  const renderCellText = (text) => (
    <span style={commonStyles.cellText}>{text}</span>
  );

  const renderNumberText = (text) => (
    <span style={commonStyles.cellText}>{text.toLocaleString("en-us")}</span>
  );

  const aggregateColumns = [
    {
      key: "contract_name",
      dataIndex: "contract_name",
      title: "Contract",
      ellipsis: true,
      render: renderContractName,
    },
    {
      key: "contract_value_all",
      dataIndex: "contract_value",
      title: viewMode === "cost" ? "Contract Value" : "Contract Days",
      ellipsis: true,
      width: "20%",
      render: renderCellText,
    },
    {
      key: "aggregate_value_all",
      dataIndex: "aggregate_value",
      title: viewMode === "cost" ? "Aggregate Value" : "Aggregate Days",
      ellipsis: true,
      width: "20%",
      render: renderCellText,
    },
    {
      key: "engagement_value_all",
      dataIndex: "engagement_value",
      title: viewMode === "cost" ? "Engagement Value" : "Engagement Days",
      ellipsis: true,
      width: "20%",
      render: renderCellText,
    },
  ];

  const aggregateMonthlyColumns = [
    {
      key: "contract_name",
      dataIndex: "contract_name",
      title: "Contract",
      width: "25%",
      fixed: "left",
      ellipsis: true,
      render: renderContractName,
    },
    ...monthsShort.map((month) => ({
      key: month,
      dataIndex: ["monthly_breakdown", month],
      title: month.charAt(0).toUpperCase() + month.slice(1),
      ellipsis: true,
      render: renderNumberText,
    })),
    {
      key: "total",
      dataIndex: ["monthly_breakdown", "total"],
      title: "Total",
      ellipsis: true,
      render: renderNumberText,
    },
  ];

  useEffect(() => {
    console.log("rawAggregate: ", aggregateTableData);

    setLoading(true);

    if (monthlyView) {
      setAggregateDataSource(
        generateMonthlyBreakdown(
          JSON.parse(JSON.stringify(aggregateTableData)),
          yearFilter
        )
      );
    } else {
      setAggregateDataSource(
        processTree(JSON.parse(JSON.stringify(aggregateTableData)), yearFilter)
      );
    }

    setLoading(false);
  }, [yearFilter, aggregateTableData, monthlyView, viewMode]);

  return (
    <>
      {!isEmptyObject(aggregateTableData) ? (
        <Row justify="center">
          <Row gutter={[12, 0]} align="middle" className="mb-10">
            <Col>
              <Select
                disabled={uniqueYears.length === 0}
                options={selectOptions}
                defaultValue="all"
                value={yearFilter}
                onChange={onChangeYear}
                labelRender={(label) => {
                  return (
                    <span style={{ ...commonStyles.yearLabelText }}>
                      <span
                        style={{
                          background: "var(--color-solid-darkblue)",
                          padding: "1px 5px 1px 6px",
                          marginRight: 8,
                          fontSize: 12,
                          color: "var(--color-solid-white)",
                          borderRadius: 4,
                        }}
                      >
                        FY
                      </span>
                      {label.label}
                    </span>
                  );
                }}
                style={{ width: 115 }}
              />
            </Col>
            <Col>
              <Switch
                unCheckedChildren="Cost"
                checkedChildren="Days"
                onChange={(checked) => setViewMode(checked ? "days" : "cost")}
              />
            </Col>
            <Col>
              <Switch
                unCheckedChildren="Aggregate"
                checkedChildren="Monthly"
                onChange={switchDataView}
              />
            </Col>
            <Col>
              <Switch
                unCheckedChildren="Total Value"
                checkedChildren="Annual Value"
                checked={totalAnnualMode === "annual"}
                disabled={uniqueYears.length === 0}
                onChange={(checked) => switchTotalAnnual(checked)}
              />
            </Col>
          </Row>

          <Col span={24}>
            <Spin
              spinning={loading}
              indicator={
                <LoadingOutlined style={{ fontSize: 35, marginTop: 20 }} spin />
              }
            >
              {!loading && (
                <Table
                  bordered
                  dataSource={[aggregateDataSource]}
                  columns={
                    monthlyView ? aggregateMonthlyColumns : aggregateColumns
                  }
                  expandable={{ defaultExpandAllRows: true, indentSize: 23 }}
                  rowKey="_id"
                  style={!monthlyView ? { padding: "0 20%" } : {}}
                  tableLayout="auto"
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 320px)",
                  }}
                  className="data-table mt-10"
                  pagination={false}
                />
              )}
            </Spin>
          </Col>
        </Row>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 100,
          }}
          description={<span>Aggregate Table not available</span>}
        />
      )}
    </>
  );
};

export default NewAggregateValue;
