// External imports
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; //router
import { Image, Button, Spin, Card } from "antd"; //antd components
import { verifyEmail, RegenerateLinkApi } from "../api/usersApi";
import "../assets/style/auth.css";
import check_circle from "../assets/images/icons/check_circle.svg";
import verified from "../assets/images/icons/verified.svg";
import link_off from "../assets/images/icons/link_off.svg";
import CustomLoader from "../components/CustomLoader";

// functional componnet for user verification success page for normal signup user
const VerificationSuccess = (props) => {
  //global state variable hold the navigating different routes
  let history = useNavigate();
  //global state variable hold the getting user id from url param
  const { id, expiry_time } = useParams();
  // global varaiable hold the new data
  let myDate = new Date();
  // global varaiable hold the get data and cureent time
  const unixTimestamp = Math.floor(myDate.getTime() / 1000);
  // state varaiable hold the verify user authorization to access this page
  const [authorized, setAuthorized] = useState();
  // state  varaiable hold the expired msg purpose
  const [expiredMsg, setExpiredMsg] = useState(false);
  // state  varaiable hold the sent link purpose
  const [sentLink, setSentLink] = useState(false);
  // state varaible hold the save loader fectch data from api
  const [loader, setLoader] = useState(true);

  //use effecct the api integration to verify user email
  useEffect(() => {
    if (unixTimestamp <= expiry_time) {
      verifyEmail(id)
        .then((res) => {
          console.log("res", res);
          if (res.status === true) {
            setAuthorized(true);
            setLoader(false);
          } else {
            setAuthorized(false);
            setLoader(false);
          }
        })
        .catch(() => {
          setAuthorized(false);
          setLoader(false);
        });
      setExpiredMsg(false);
    } else {
      setExpiredMsg(true);
    }
  }, []);
  // function handle the regenerating link
  const regenerateLink = () => {
    RegenerateLinkApi(id).then((res) => {
      if (res.status) {
        setLoader(false);
        setSentLink(true);
        setAuthorized(false);
        setExpiredMsg(false);
      }
    });
  };

  return (
    <div>
      {authorized && !loader && (
        <div className="auth-bg auth-page-bg">
          <Card className="auth-card p-30">
            <div>
              <Image src={check_circle} preview={false} />
            </div>
            <div className="verification-msg mt-10">Verification Success</div>
            <div className="mt-20">
              Thank you for your support, We have successfully verified your
              email adderess, Yo can now proceed to your home page
            </div>
            <div className="mt-20">
              <Button
                type="primary"
                onClick={() => history(`/set-password/${id}/set/null`)}
                className="set-password-btn"
                shape="round"
              >
                Create Your Password
              </Button>
            </div>
          </Card>
        </div>
      )}
      {!authorized && !loader && (
        <div className="auth-bg auth-page-bg">
          <Card className="auth-card p-30">
            <div>
              <Image src={verified} preview={false} />
            </div>
            <div className="auth-head-msg mt-10">Already Verified</div>
            <div className="mt-20">The link had been Already verified.</div>
            <div className="mt-20">
              <Button
                type="primary"
                onClick={() => history(`/login`)}
                className="signin-btn"
                shape="round"
              >
                Go to Login
              </Button>
            </div>
          </Card>
        </div>
      )}
      {expiredMsg && !loader && (
        <div className="auth-bg auth-page-bg">
          <Card className="auth-card p-30">
            <div>
              <Image src={link_off} preview={false} />
            </div>
            <div className="regenerate-msg mt-10">Link Expired</div>
            <div className="mt-20">
              The link is valid for only 24 hours. Please regenerate the link.
            </div>
            <div className="mt-20">
              <Button
                type="primary"
                onClick={regenerateLink}
                className="regenerate-btn"
                shape="round"
              >
                Regenerate Link
              </Button>
            </div>
          </Card>
        </div>
      )}
      {sentLink && !loader && (
        <div className="auth-bg auth-page-bg">
          <Card className="auth-card p-30">
            <div>
              <Image src={check_circle} preview={false} />
            </div>
            <div className="verification-msg mt-10">Link has been sent</div>
            <div className="mt-20">
              A verification link has been sent to your email account. Kindly
              verify your email before attempting to log in again.
            </div>
            <div className="mt-20">
              <Button
                type="primary"
                onClick={() => history(`/login`)}
                className="set-password-btn"
                shape="round"
              >
                Login
              </Button>
            </div>
          </Card>
        </div>
      )}
      {loader && <CustomLoader />}
    </div>
  );
};

export default VerificationSuccess;
