import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import {
  Table,
  Form,
  Input,
  Select,
  DatePicker,
  Popconfirm,
  Image,
  Button,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  UndoOutlined,
} from "@ant-design/icons";
import duplicate from "../../assets/images/icons/duplicate.svg";
import { dataTable, dataTableForm } from "../../utils/common";
import {
  AddButton,
  ViewButton,
  UploadButton,
  DownloadTemplateButton,
  DownloadAllCommercialDataButton,
  EditButton,
} from "../GlobalButton";
import moment from "moment";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import DaysModal from "./DaysModal";
import CostModal from "./CostModal";
import TotalDaysModal from "../../components/Contract/TotalDaysModal";
import TotalCostModal from "../../components/Contract/TotalCostModal";
import { CostAndDaysTotalTableCol } from "../../utils/tableColumns";
import { monthsShort } from "../../utils/data";
import _ from "lodash";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  isParentRow,
  haveDaysChanged,
  handleSaveCommercialRow,
  handleDeleteCommercialRow,
  handleNegateCommercialRow,
  handleRestoreCommercialRow,
  handleCloneCommercialRow,
  negatedResource,
  state,
  rateCardData,
  commercialDataSource,
  contractStartDateRef,
  contractEndDateRef,
  yearsList,
  recordKey,
  commercialItemDates,
  commercialItemRates,
  daysTableRef,
  costsTableRef,
  setDaysFormData,
  setOpenDaysModal,
  setCostsFormData,
  setOpenCostsModal,
  getTotalCommercialDaysCosts,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  /* This if-statement exists solely to fix cloning of rows.
   * When cloning a row, commercialDataSource updates correctly but the table doesn't display its value because we use EditableRow & EditableCell.
   * record - The row's data in commercialDataSource which will have the cloned row's values.
   * formValues - This will hold the actual values of the row that is displayed to the user.
   * So, after comparing record and formValues, if the values in record are not in formValues, we update it to display the cloned values.
   * It does work like magic
   */
  if (record) {
    const ignoredKeys = ["key", "Resource Id", "Item Id", "Days", "Cost"];
    const formValues = form.getFieldsValue();

    const areValuesDifferent = Object.keys(record)
      .filter((key) => !ignoredKeys.includes(key))
      .some((key) => formValues[key] !== record[key]);

    if (areValuesDifferent) {
      form.setFieldsValue(record);
    }
  }

  // Function which saves the row values to commercialDataSource
  const saveRow = async (value) => {
    try {
      const values = await form.validateFields();
      const updatedRecord = { ...record, ...values };

      // Clear all keys from dataSource when any Select component field is cleared
      // This if-statement will not apply for Start Date/End Date because there is no need to clear the fields if those two fields are cleared
      if (value === undefined) {
        Object.keys(rateCardData.assembledData[0])
          .filter((key) => key !== "Item Id")
          .forEach((key) => {
            updatedRecord[key] = undefined;
          });

        // Resetting Days and Cost when any option is cleared
        updatedRecord.Days = undefined;
        updatedRecord.Cost = undefined;
        delete daysTableRef.current[updatedRecord.key];
        delete costsTableRef.current[updatedRecord.key];
        form.resetFields([
          ...Object.keys(rateCardData.assembledData[0]),
          "Days",
          "Cost",
        ]);
      }

      handleSaveCommercialRow(updatedRecord);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  // Function to get the dropdown select options for a particular field
  const getOptions = (field, selectedValues = {}) => {
    const ignoreKeys = ["Start Date", "End Date", "Days", "Cost", "Action"];

    const filterOptions = (items, field) => {
      return [
        ...new Set(
          items
            .filter((item) =>
              Object.keys(selectedValues)
                .filter((key) => !ignoreKeys.includes(key))
                .every(
                  (key) =>
                    !selectedValues[key] || item[key] === selectedValues[key]
                )
            )
            .map((item) => item[field])
        ),
      ].map((option) => ({
        label: option,
        value: option,
      }));
    };

    const allOptions = filterOptions(rateCardData.assembledData, field);
    return allOptions;
  };

  // Function to set values automatically if only one option is available for any field
  const setSingleOptionValues = (selectedValues) => {
    const newValues = {};

    Object.keys(rateCardData.assembledData[0]).forEach((field) => {
      const options = getOptions(field, selectedValues);
      if (options.length === 1) {
        newValues[field] = options[0].value;
      }
    });

    form.setFieldsValue(newValues);

    return newValues;
  };

  // Function that calculates the months & years range between selected startDate and endDate
  const handleCommercialDates = () => {
    const startDate = form.getFieldValue("Start Date");
    const endDate = form.getFieldValue("End Date");

    // Constructing commercialDaysRange object which will have the years and months over which the commercial item is applicable
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);

      const commercialDaysRange = {};

      let currentYear = start.year();
      let currentMonth = start.month(); // 0 (January) to 11 (December)

      while (
        currentYear < end.year() ||
        (currentYear === end.year() && currentMonth <= end.month())
      ) {
        const monthAbbreviation = dayjs()
          .month(currentMonth)
          .format("MMM")
          .toLowerCase();

        if (!commercialDaysRange[currentYear]) {
          commercialDaysRange[currentYear] = [];
        }

        commercialDaysRange[currentYear].push(monthAbbreviation);

        if (currentMonth === 11) {
          // December, move to next year
          currentMonth = 0;
          currentYear++;
        } else {
          currentMonth++;
        }
      }

      yearsList.current = {
        ...yearsList.current,
        [record.key]: commercialDaysRange,
      };

      // Clearing Days and cost data when either Start Date or End Date is updated.
      // If not done, it will lead to issues with Days Addition data
      form.resetFields(["Days", "Cost"]);
      delete daysTableRef.current[record.key];
      delete costsTableRef.current[record.key];

      console.log("yearsList:", yearsList.current);
    } else {
      // Runs when either Start Date or End Date field is cleared
      form.resetFields(["Days", "Cost"]);
      delete daysTableRef.current[record.key];
      delete costsTableRef.current[record.key];
    }
  };

  const isNegated =
    Object.values(negatedResource).includes(record["Resource Id"]) ||
    Object.keys(negatedResource).includes(record["Resource Id"]);
  const isParent = isParentRow(record);

  const commonButtonProps = {
    style: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: 30,
      borderRadius: 0,
      width: "100%",
      marginTop: -2,
    },
    disabled:
      !form.getFieldValue("Start Date") ||
      !form.getFieldValue("End Date") ||
      !form.getFieldValue("Rate Basis"),
    onClick: () => {
      recordKey.current = record.key;
      commercialItemRates.current = {
        itemPrice: form.getFieldValue("Price"),
        rateBasis: form.getFieldValue("Rate Basis"),
        ratePeriod: form.getFieldValue("Rate Period"),
      };
      if (record.Days) {
        setDaysFormData(record.Days);
      }
      commercialItemDates.current = {
        startDate: form.getFieldValue("Start Date"),
        endDate: form.getFieldValue("End Date"),
      };
      setOpenDaysModal(true);
    },
  };

  const renderDaysButton = () => {
    if (isParent) {
      return isNegated ? (
        <ViewButton {...commonButtonProps} />
      ) : (
        <EditButton {...commonButtonProps} />
      );
    }
    return isNegated ? (
      <ViewButton {...commonButtonProps} />
    ) : (
      <AddButton {...commonButtonProps} />
    );
  };

  let childNode = children;

  // All the form fields are defined and stored in childNode
  if (editable) {
    childNode = (
      <Form.Item name={dataIndex} style={{ margin: 0 }}>
        {["Price", "Currency", "Rate Basis", "Rate Period"].includes(
          dataIndex
        ) ? (
          <div style={{ fontSize: 12, fontWeight: 100, padding: 5 }}>
            {dataIndex === "Price" && record[dataIndex] !== undefined
              ? Number(record[dataIndex]).toLocaleString("en-US")
              : record[dataIndex]}
            {record[dataIndex] === undefined && (
              <p style={{ color: "#bfbfbf", cursor: "default" }}>{dataIndex}</p>
            )}
          </div>
        ) : ![
            "Price",
            "Currency",
            "Rate Basis",
            "Rate Period",
            "Start Date",
            "End Date",
            "Days",
            "Cost",
            "Action",
          ].includes(dataIndex) ? (
          <Select
            disabled={
              state.mode === "view" ||
              isParent ||
              Object.values(negatedResource).includes(record["Resource Id"])
            }
            allowClear
            showSearch
            placeholder={`${dataIndex}`}
            options={getOptions(dataIndex, form.getFieldsValue())}
            onChange={(value) => {
              const updatedValues = {
                ...form.getFieldsValue(),
                [dataIndex]: value,
              };

              const newSingleValues = setSingleOptionValues(updatedValues);

              Object.keys(newSingleValues).forEach((key) => {
                if (key !== dataIndex) {
                  form.setFieldsValue({
                    [key]: form.getFieldValue(key),
                  });
                }
              });
              saveRow(value);
            }}
            value={record[dataIndex]}
          />
        ) : ["Start Date", "End Date"].includes(dataIndex) ? (
          state.mode === "view" ||
          isParent ||
          Object.values(negatedResource).includes(record["Resource Id"]) ? (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 100,
                fontSize: 12,
                padding: "5px 8px",
              }}
            >
              {dataIndex === "Start Date" && record["Start Date"]
                ? dayjs(record["Start Date"]).format("DD MMM YYYY")
                : dataIndex === "End Date" && record["End Date"]
                ? dayjs(record["End Date"]).format("DD MMM YYYY")
                : ""}
            </span>
          ) : (
            <DatePicker
              format={"DD MMM YYYY"}
              disabledDate={(current) => {
                if (dataIndex === "Start Date") {
                  return (
                    current &&
                    (current.isBefore(
                      dayjs(contractStartDateRef.current, "day")
                    ) ||
                      current.isAfter(dayjs(contractEndDateRef.current, "day")))
                  );
                }
                if (dataIndex === "End Date") {
                  const startDate = form.getFieldValue("Start Date");
                  return (
                    current &&
                    (current.isBefore(
                      dayjs(contractStartDateRef.current, "day")
                    ) ||
                      current.isBefore(dayjs(startDate, "day")) ||
                      current.isAfter(dayjs(contractEndDateRef.current, "day")))
                  );
                }
                return false;
              }}
              placeholder={`${dataIndex}`}
              style={{ width: "100%" }}
              onChange={(selectedDate) => {
                if (dataIndex === "Start Date") {
                  form.setFieldValue("End Date", null);
                }
                handleCommercialDates();
                saveRow(selectedDate); // Although it is unnecessary to send the selectedDate value, not sending a value will clear the values of the other fields
              }}
              showToday={false}
              size="small"
            />
          )
        ) : dataIndex === "Days" ? (
          state.mode === "view" ? (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                textDecoration: "underline",
                color: "var(--color-solid-darkerblue)",
                fontWeight: 100,
                fontSize: 13,
                padding: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                recordKey.current = record.key;
                commercialItemRates.current = {
                  itemPrice: form.getFieldValue("Price"),
                  rateBasis: form.getFieldValue("Rate Basis"),
                  ratePeriod: form.getFieldValue("Rate Period"),
                };
                if (record.Days) {
                  setDaysFormData(record.Days);
                }
                commercialItemDates.current = {
                  startDate: form.getFieldValue("Start Date"),
                  endDate: form.getFieldValue("End Date"),
                };
                setOpenDaysModal(true);
              }}
            >
              {getTotalCommercialDaysCosts(
                record.Days,
                "days",
                "single_row"
              ).toLocaleString("US")}
            </span>
          ) : (
            renderDaysButton()
          )
        ) : dataIndex === "Cost" ? (
          state.mode === "view" ? (
            <span
              style={{
                alignItems: "center",
                textDecoration: "underline",
                color: "var(--color-solid-darkerblue)",
                fontWeight: 100,
                fontSize: 13,
                padding: 5,
                cursor: "pointer",
                display: "block",
                textAlign: "right",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={getTotalCommercialDaysCosts(
                record.Cost,
                "cost",
                "single_row"
              ).toLocaleString("en-us")}
              onClick={() => {
                recordKey.current = record.key;
                if (record.Cost) {
                  setCostsFormData(costsTableRef.current[record.key]);
                }
                setOpenCostsModal(true);
              }}
            >
              {getTotalCommercialDaysCosts(
                record.Cost,
                "cost",
                "single_row"
              ).toLocaleString("en-us")}
            </span>
          ) : (
            <ViewButton
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                height: 30,
                borderRadius: 0,
                width: "100%",
                marginTop: -2,
              }}
              disabled={
                !form.getFieldValue("Start Date") ||
                !form.getFieldValue("End Date") ||
                !form.getFieldValue("Rate Basis") ||
                !record.Days
              }
              onClick={() => {
                recordKey.current = record.key;
                if (record.Cost) {
                  setCostsFormData(costsTableRef.current[record.key]);
                }
                setOpenCostsModal(true);
              }}
            />
          )
        ) : dataIndex === "Action" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            {(commercialDataSource.length > 1 || isParent) &&
              (isParent ? (
                !Object.keys(negatedResource).includes(
                  record["Resource Id"]
                ) ? (
                  isParent && haveDaysChanged(record) ? (
                    <Popconfirm
                      placement="left"
                      title="Confirm undoing changes to this resource?"
                      okButtonProps={{ type: "primary", shape: "round" }}
                      cancelButtonProps={{ type: "default", shape: "round" }}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleRestoreCommercialRow(record.key)}
                    >
                      <a>
                        <UndoOutlined style={{ fontSize: 16, marginTop: 2 }} />
                      </a>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      placement="left"
                      title="Confirm negation of this resource?"
                      okButtonProps={{ type: "primary", shape: "round" }}
                      cancelButtonProps={{ type: "default", shape: "round" }}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleNegateCommercialRow(record.key)}
                    >
                      <a>
                        <MinusCircleFilled
                          style={{
                            color: "#de350b",
                            fontSize: 16,
                            marginTop: 2,
                          }}
                        />
                      </a>
                    </Popconfirm>
                  )
                ) : (
                  <div style={{ width: 16, height: 16 }}></div>
                )
              ) : (
                <Popconfirm
                  placement="left"
                  title={
                    Object.values(negatedResource).includes(
                      record["Resource Id"]
                    )
                      ? "Confirm restoration of this resource?"
                      : "Confirm deletion of this resource?"
                  }
                  okButtonProps={{ type: "primary", shape: "round" }}
                  cancelButtonProps={{ type: "default", shape: "round" }}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleDeleteCommercialRow(record.key)}
                >
                  <a>
                    {Object.values(negatedResource).includes(
                      record["Resource Id"]
                    ) ? (
                      <PlusCircleFilled
                        style={{
                          fontSize: 16,
                          marginTop: 2,
                        }}
                      />
                    ) : (
                      <DeleteOutlined
                        style={{
                          fontSize: 16,
                          marginTop: 2,
                        }}
                      />
                    )}
                  </a>
                </Popconfirm>
              ))}
            {!Object.values(negatedResource).includes(record["Resource Id"]) ? (
              <a>
                <Image
                  title="Clone"
                  src={duplicate}
                  style={{ width: "20px", marginBottom: 2 }}
                  preview={false}
                  className="cursor-pointer"
                  onClick={() => handleCloneCommercialRow(record.key)}
                />
              </a>
            ) : (
              <div style={{ width: 16, height: 16 }}></div>
            )}
          </div>
        ) : (
          <Input value={record[dataIndex]} />
        )}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const CommercialTable = ({
  state,
  rateCardData,
  rateCardTemplate,
  parentCommercialRef,
  parentYearsListRef,
  parentDaysRef,
  parentCostsRef,
  negatedResourceRef,
  currencyToggleRef,
  fxCurrencyList,
  contractStartDateRef,
  contractEndDateRef,
  onUpdateCommercialData,
}) => {
  // useEffect(() => {
  //   console.log("state: ", state);
  //   console.log("fxCurrencyList: ", fxCurrencyList);
  //   console.log("parentCostsRef.current: ", parentCostsRef.current);
  // }, []);

  const commercialTableRef = useRef(null);
  const daysTableRef = useRef(parentDaysRef.current);
  const costsTableRef = useRef(parentCostsRef.current);
  const [currencyMode, setCurrencyMode] = useState(currencyToggleRef.current);
  const [commercialDataSource, setCommercialDataSource] = useState(
    parentCommercialRef.current
  );
  const [rowCount, setRowCount] = useState(parentCommercialRef.current.length);
  const yearsList = useRef(parentYearsListRef.current);
  const recordKey = useRef();
  const commercialItemDates = useRef();
  const commercialItemRates = useRef();
  const [openDaysModal, setOpenDaysModal] = useState(false);
  const [openCostsModal, setOpenCostsModal] = useState(false);
  const [totalDaysModal, openTotalDaysModal] = useState(false);
  const [totalCostModal, openTotalCostModal] = useState(false);
  const [daysFormData, setDaysFormData] = useState({});
  const [costsFormData, setCostsFormData] = useState({});
  const daysModalRef = useRef(totalDaysModal);
  const costModalRef = useRef(totalCostModal);
  const firstRoleYears = yearsList.current[0]
    ? Object.keys(yearsList.current[0])
    : [];
  const [selectedYear, setSelectedYear] = useState(firstRoleYears[0] || "");
  const [daysCostsDataSource, setDaysCostsDataSource] = useState([]);

  const [tableLoader, setTableLoader] = useState(false);
  const [rootData, setRootData] = useState({
    rootId: "",
    rootName: "",
    startDate: "",
    endDate: "",
  });
  const [negatedResource, setNegatedResource] = useState(
    negatedResourceRef.current
  );

  const isParentRow = (record) =>
    state.parentData &&
    state.parentData.some(
      (parentRow) => parentRow["Resource Id"] === record["Resource Id"]
    );

  const haveDaysChanged = (record) => {
    const parentRow =
      state.parentData &&
      state.parentData.find(
        (parent) => parent["Resource Id"] === record["Resource Id"]
      );

    if (!record?.Days || !parentRow?.Days) {
      return true;
    }

    const recordDays = record.Days;
    const parentRowDays = parentRow.Days;

    for (const year in recordDays) {
      for (const month in recordDays[year]) {
        if (recordDays[year][month] !== parentRowDays[year][month]) {
          return true;
        }
      }
    }

    return false;
  };

  const getComColumWidth = (column_name) => {
    switch (column_name) {
      case "Role":
        return 160;
      case "Grade":
        return 60;
      case "Location":
        return 150;
      case "Price":
        return 115;
      case "Rate Period":
        return 130;
      case "Rate Basis":
        return 120;
      case "Currency":
        return 80;
      case "Start Date":
        return 115;
      case "End Date":
        return 115;
      case "Days":
        return 100;
      case "Cost":
        return 100;
      case "Action":
        return 65;
      default:
        return 115;
    }
  };

  const tableColumnMap = useMemo(() => {
    const rateCardKeys = rateCardData?.disassembledData?.keys ?? [];
    const columnTitles = [
      ...(rateCardKeys ? [...rateCardKeys] : []),
      "Start Date",
      "End Date",
      "Days",
      "Cost",
      "Action",
    ];

    const filteredColumnTitles = columnTitles.filter((column_name) => {
      if (state.mode === "view") {
        return column_name !== "Item Id" && column_name !== "Action";
      } else {
        return column_name !== "Item Id";
      }
    });

    return filteredColumnTitles.map((column_name) => {
      return {
        title:
          column_name === "Action" ? (
            <span className="hide-add">Action</span>
          ) : (
            column_name
          ),
        dataIndex: column_name,
        editable: true,
        align: "left",
        fixed: ["Start Date", "End Date", "Days", "Cost", "Action"].includes(
          column_name
        )
          ? "right"
          : false,
        width: getComColumWidth(column_name),
        // width: column_name === "Action" ? 70 : 120,
      };
    });
  }, [rateCardData]);

  const commercialTableColumns = tableColumnMap.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        isParentRow,
        haveDaysChanged,
        handleSaveCommercialRow,
        handleDeleteCommercialRow,
        handleNegateCommercialRow,
        handleRestoreCommercialRow,
        handleCloneCommercialRow,
        negatedResource,
        state,
        rateCardData,
        commercialDataSource,
        contractStartDateRef,
        contractEndDateRef,
        yearsList,
        recordKey,
        commercialItemDates,
        commercialItemRates,
        daysTableRef,
        costsTableRef,
        setDaysFormData,
        setOpenDaysModal,
        setCostsFormData,
        setOpenCostsModal,
        getTotalCommercialDaysCosts,
      }),
    };
  });

  const commercialTableComponents = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSaveDaysCosts = (rowDays, rowCosts) => {
    const key = recordKey.current;
    const newData = [...commercialDataSource];
    const index = newData.findIndex((item) => key === item.key);

    if (index > -1) {
      if (Object.keys(rowDays).length !== 0) {
        newData[index].Days = rowDays;
        daysTableRef.current = { ...daysTableRef.current, [key]: rowDays };
      } else {
        newData[index].Days = undefined;
        delete daysTableRef.current[key];
      }
      if (Object.keys(rowCosts).length !== 0) {
        newData[index].Cost = rowCosts;
        costsTableRef.current = { ...costsTableRef.current, [key]: rowCosts };

        console.log("currencyMode saveCosts: ", currencyMode);

        if (currencyMode === "reporting") {
          handleCurrencyToggle("row", key, currencyMode);
        }
      } else {
        newData[index].Cost = undefined;
        delete costsTableRef.current[key];
      }
      setCommercialDataSource(newData);
    }
  };

  const handleSaveCommercialRow = (row) => {
    const newData = [...commercialDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setCommercialDataSource(newData);
  };

  const handleCloneCommercialRow = (rowKey) => {
    const rowToClone = commercialDataSource.find((item) => item.key === rowKey);

    if (rowToClone) {
      const clonedRow = {
        ...rowToClone,
        "Resource Id": `RE_${rowCount + 1}`,
        key: rowCount,
      };
      const newData = [...commercialDataSource, clonedRow];

      // Add entries to yearsList, daysTableRef and costsTableRef for the newly cloned row
      if (yearsList.current[rowToClone.key])
        yearsList.current = {
          ...yearsList.current,
          [rowCount]: yearsList.current[rowToClone.key],
        };

      if (daysTableRef.current[rowToClone.key])
        daysTableRef.current = {
          ...daysTableRef.current,
          [rowCount]: daysTableRef.current[rowToClone.key],
        };

      if (costsTableRef.current[rowToClone.key])
        costsTableRef.current = {
          ...costsTableRef.current,
          [rowCount]: costsTableRef.current[rowToClone.key],
        };

      setCommercialDataSource(newData);
      setRowCount((prevCount) => prevCount + 1);

      // Scroll to the newly added row
      setTimeout(() => {
        if (commercialTableRef.current) {
          const tableBody = document.querySelector(".ant-table-body");
          tableBody.scrollTo({
            top: tableBody.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 0);
    }
  };

  const handleNegateCommercialRow = (rowKey) => {
    const originalRow = commercialDataSource.find(
      (item) => item.key === rowKey
    );
    if (!originalRow) return;

    const rowToBeNegated = _.cloneDeep(originalRow);

    const negateObject = (object) => {
      if (object) {
        for (const year in object) {
          if (object.hasOwnProperty(year)) {
            for (const month in object[year]) {
              if (object[year].hasOwnProperty(month)) {
                object[year][month] = 0 - (object[year][month] || 0);
              }
            }
          }
        }
      }
    };

    negateObject(rowToBeNegated?.Days);
    negateObject(rowToBeNegated?.Cost);
    setRowCount((prevCount) => prevCount + 1);

    const newRow = {
      ...rowToBeNegated,
      "Resource Id": `RE_${rowCount + 1}`,
      key: rowCount,
    };

    yearsList.current = {
      ...yearsList.current,
      [rowCount]: yearsList.current[rowToBeNegated.key],
    };

    daysTableRef.current = {
      ...daysTableRef.current,
      [rowCount]: rowToBeNegated.Days,
    };

    costsTableRef.current = {
      ...costsTableRef.current,
      [rowCount]: rowToBeNegated.Cost,
    };

    const newData = [...commercialDataSource, newRow];
    setCommercialDataSource(newData);
    setNegatedResource((prev) => ({
      ...prev,
      [rowToBeNegated["Resource Id"]]: newRow["Resource Id"],
    }));

    setRowCount((prevCount) => prevCount + 1);

    // Scroll to the newly added row
    setTimeout(() => {
      if (commercialTableRef.current) {
        const tableBody = document.querySelector(".ant-table-body");
        tableBody.scrollTo({
          top: tableBody.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  const handleRestoreCommercialRow = (rowKey) => {
    const parentRow =
      state.parentData &&
      state.parentData.find(
        (parent) =>
          parent["Resource Id"] ===
          commercialDataSource.find((row) => row.key === rowKey)?.[
            "Resource Id"
          ]
      );

    if (parentRow) {
      const newData = [...commercialDataSource];
      const index = newData.findIndex((item) => item.key === rowKey);

      if (index !== -1) {
        const costObj = {};
        for (const year in parentRow.Days) {
          if (!costObj[year]) {
            costObj[year] = {};
          }
          for (const month in parentRow.Days[year]) {
            const daysValue = parentRow.Days[year][month] || 0;
            const price = parentRow["Price"] || 0;
            let cost = 0;
            if (parentRow["Rate Period"] === "Yearly Rate") {
              cost = (daysValue / 12) * price;
            } else {
              cost = daysValue * price;
            }
            costObj[year][month] = Number(cost.toFixed(2));
          }
        }

        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          Days: { ...parentRow.Days },
          Cost: { ...costObj },
        });

        daysTableRef.current = {
          ...daysTableRef.current,
          [rowKey]: parentRow.Days,
        };
        costsTableRef.current = { ...costsTableRef.current, [rowKey]: costObj };

        setCommercialDataSource(newData);
      }
    }
  };

  const handleDeleteCommercialRow = (rowKey) => {
    const rowToBeDeleted = commercialDataSource.find(
      (item) => item.key === rowKey
    );

    const originalParentKey = Object.keys(negatedResource).find(
      (parentKey) =>
        negatedResource[parentKey] === rowToBeDeleted["Resource Id"]
    );

    if (originalParentKey) {
      setNegatedResource((prev) => {
        const updatedResource = { ...prev };
        delete updatedResource[originalParentKey];
        return updatedResource;
      });
    }

    const newData = commercialDataSource.filter((item) => item.key !== rowKey);

    setCommercialDataSource(newData);
    setRowCount((prevCount) => prevCount + 1);

    // Removing the deleted rowKeys from the dependent objects
    delete yearsList.current[rowKey];
    delete daysTableRef.current[rowKey];
    delete costsTableRef.current[rowKey];
  };

  const handleAddCommercialRow = () => {
    const newRow = {
      ...rateCardTemplate,
      "Resource Id": `RE_${rowCount + 1}`,
      key: rowCount,
    };

    daysTableRef.current = {
      ...daysTableRef.current,
      [rowCount]: {},
    };
    costsTableRef.current = {
      ...costsTableRef.current,
      [rowCount]: {},
    };
    setCommercialDataSource([...commercialDataSource, newRow]);
    setRowCount((prevCount) => prevCount + 1);

    // Scroll to the newly added row
    setTimeout(() => {
      if (commercialTableRef.current) {
        const tableBody = document.querySelector(".ant-table-body");
        tableBody.scrollTo({
          top: tableBody.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  const getYearsBetweenDates = (startDate, endDate) => {
    const startYear = startDate ? dayjs(startDate).year() : dayjs().year();
    const endYear = endDate ? dayjs(endDate).year() : dayjs().year();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }

    return years;
  };

  const exportToExcel = (flag) => {
    let tableDataSource = JSON.parse(JSON.stringify(commercialDataSource));
    console.log("data..%%%%", tableDataSource);
    let yearsBetweenDates;
    const commercialStartDate = dayjs(contractStartDateRef.current);
    const commercialEndDate = dayjs(contractEndDateRef.current);

    if (state.contractType === "CR" || state.contractType === "CCN") {
      // Extract start dates and end dates from parent and child arrays
      const allStartDates = [
        commercialStartDate.isValid() ? commercialStartDate : null,
        rootData.startDate ? dayjs(rootData.startDate) : null,
      ];

      const allEndDates = [
        commercialEndDate.isValid() ? commercialEndDate : null,
        rootData.endDate ? dayjs(rootData.endDate) : null,
      ];

      // Filter out null values
      const filteredStartDates = allStartDates.filter((date) => date !== null);
      const filteredEndDates = allEndDates.filter((date) => date !== null);

      // Find farthest start date
      const farthestStartDate = dayjs(
        Math.min(...filteredStartDates.map((date) => date.valueOf()))
      );

      // Find closest end date
      const closestEndDate = dayjs(
        Math.max(...filteredEndDates.map((date) => date.valueOf()))
      );

      yearsBetweenDates = getYearsBetweenDates(
        farthestStartDate,
        closestEndDate
      );
    } else {
      yearsBetweenDates = getYearsBetweenDates(
        commercialStartDate,
        commercialEndDate
      );
    }

    // This will output an array of years between the given start and end dates
    const colHeader = commercialTableColumns
      .filter((item) =>
        flag === "template"
          ? item.title !== "Cost" &&
            item.title !== "Currency" &&
            item.title !== "Price" &&
            item.title !== "Rate Basis" &&
            item.title !== "Rate Period" &&
            typeof item.title === "string"
          : item.title !== "Cost" && typeof item.title === "string"
      )
      .map((item) => item.title);

    const headerLine1 = [...colHeader]; // Copy colHeader array
    for (let i = 1; i < yearsBetweenDates.length; i++) {
      headerLine1.push(" "); // Add empty strings for the remaining years
    }

    const headerLine2 = [];
    const daysIndex = colHeader.indexOf("Days");
    colHeader.forEach((item, index) => {
      if (index < daysIndex) {
        headerLine2.push(""); // Fill with empty strings until "Days"
      } else if (index === daysIndex) {
        yearsBetweenDates.forEach((year) => {
          headerLine2.push(year, "", "", "", "", "", "", "", "", "", "", "");
        });
      }
    });

    const headerLine3 = [];
    colHeader.forEach((item, index) => {
      if (index < daysIndex) {
        headerLine3.push(""); // Fill with empty strings until "Days"
      } else if (index === daysIndex) {
        yearsBetweenDates.forEach((year) => {
          headerLine3.push(...monthsShort);
        });
      }
    });

    const header = [headerLine1, headerLine2, headerLine3];
    console.log("header: ", header);

    const colData = tableDataSource.map((item) => {
      if (flag === "template") {
        const modifiedItem = { ...item };
        delete modifiedItem["Price"];
        delete modifiedItem["Currency"];
        delete modifiedItem["Rate Basis"];
        delete modifiedItem["Rate Period"];
        modifiedItem["Start Date"] =
          item["Start Date"] && dayjs(item["Start Date"]).format("DD MMM YYYY");
        modifiedItem["End Date"] =
          item["End Date"] && dayjs(item["End Date"]).format("DD MMM YYYY");
        const row = colHeader.map((key) => {
          if (key === "Days") {
            const yearData = [];
            yearsBetweenDates.forEach((year) => {
              monthsShort.forEach((month) => {
                const value =
                  (item[key] && item[key][year] && item[key][year][month]) || 0;
                yearData.push(value);
              });
            });
            return yearData;
          } else {
            return modifiedItem[key];
          }
        });
        return row.flat(); // Flatten the array structure
      } else {
        item["Start Date"] =
          item["Start Date"] && dayjs(item["Start Date"]).format("DD MMM YYYY");
        item["End Date"] =
          item["End Date"] && dayjs(item["End Date"]).format("DD MMM YYYY");
        const row = colHeader.map((key) => {
          if (key === "Days") {
            const yearData = [];
            yearsBetweenDates.forEach((year) => {
              monthsShort.forEach((month) => {
                const value =
                  (item[key] && item[key][year] && item[key][year][month]) || 0;
                yearData.push(value);
              });
            });
            return yearData;
          } else {
            return item[key];
          }
        });
        return row.flat(); // Flatten the array structure
      }
    });

    const ws = XLSX.utils.aoa_to_sheet([...header, ...colData]);

    ws["!merges"] = [
      {
        s: { r: 0, c: daysIndex },
        e: { r: 0, c: headerLine3.length - 1 },
      },
    ];

    colHeader.map((data, index) => {
      if (data !== "Days")
        ws["!merges"].push({ s: { r: 0, c: index }, e: { r: 2, c: index } });
    });

    yearsBetweenDates.forEach((year) => {
      const startCol = header[1].indexOf(year);
      const mergeConfig = {
        s: { r: 1, c: startCol },
        e: { r: 1, c: startCol + 11 },
      };
      ws["!merges"].push(mergeConfig);
    });

    // Align content left for all cells
    for (let r = 0; r < header.length; r++) {
      for (let c = 0; c < header[r].length; c++) {
        const cellAddress = XLSX.utils.encode_cell({ r, c });
        ws[cellAddress].s = { alignment: { horizontal: "left" } };
      }
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Commercial Data");

    XLSX.writeFile(
      wb,
      flag === "template"
        ? "exported_commercial_template.xlsx"
        : "exported_commercial_details.xlsx"
    );
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const excelUploadProps = {
    name: "file",
    listType: "picture",
    accept: ["xlsx", "xls"],
    maxCount: 1,
    showUploadList: false,

    onChange(info) {
      if (info.file.status === "done") {
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
      setTableLoader(true);
    },
    beforeUpload(file) {
      console.log("file: ", file);

      const acceptedFormats = ["xls", "xlsx"];
      let uploadedFormat = file.name.split(".");
      const formatCheck = acceptedFormats.includes(
        uploadedFormat[uploadedFormat.length - 1]
      );
      if (!formatCheck) {
        Modal.error({
          className: "popup-model-success",
          centered: true,
          icon: false,
          content: <div>You can only upload the excel files.</div>,
        });
      } else {
        const commercialStartDate = dayjs(contractStartDateRef.current).format(
          "DD MMM YYYY"
        );
        const commercialEndDate = dayjs(contractEndDateRef.current).format(
          "DD MMM YYYY"
        );

        console.log("commercialEndDate: ", commercialEndDate);
        console.log("commercialStartDate: ", commercialStartDate);

        if (
          rateCardData.disassembledData.keys.length === 0 ||
          commercialEndDate === "" ||
          commercialStartDate === ""
        ) {
          Modal.error({
            className: "popup-model-success",
            centered: true,
            icon: false,
            content: (
              <div>
                Please select the Pricing Profile, Start Date, and End Date
                before uploading the Commercial File.
              </div>
            ),
            onOk() {
              setTableLoader(false);
            },
          });
        } else {
          try {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              let exceptionData = [];

              let jsonData = XLSX.utils
                .sheet_to_json(worksheet, { header: 1 })
                .filter((e) => e.length);
              console.log("jsonDataaa", jsonData);

              if (jsonData && jsonData.length > 0) {
                let excelRes = {};
                let headerArr = jsonData[0];
                let yearsArr = jsonData[1].filter((e) => e);
                let monthsArr = jsonData[2].filter((e) => e);

                // Additional required items
                const headerItems = [
                  ...rateCardData.disassembledData.keys.filter(
                    (item) => item !== "Item Id"
                  ),
                  "Start Date",
                  "End Date",
                  "Days",
                ];

                let _days = {};
                let daysIndex = jsonData[0].indexOf("Days");
                for (let j = 0; j < yearsArr.length; j++) {
                  _days[yearsArr[j]] = {};
                }
                for (let i = 3; i < jsonData.length; i++) {
                  let arr = jsonData[i];
                  for (let k = 0; k <= headerItems.length; k++) {
                    if (k === daysIndex) {
                      excelRes = {
                        ...excelRes,
                        [i - 3]: {
                          ...excelRes[i - 3],
                          [headerArr[k]]: _days,
                        },
                      };
                    } else {
                      excelRes = {
                        ...excelRes,
                        [i - 3]: {
                          ...excelRes[i - 3],
                          [headerArr[k]]: arr[k],
                        },
                      };
                    }
                  }
                }

                const chunkArray = (arr, n) => {
                  let chunkLength = Math.max(arr.length / n, 1);
                  let chunks = [];
                  for (let i = 0; i < n; i++) {
                    if (chunkLength * (i + 1) <= arr.length)
                      chunks.push(
                        arr.slice(chunkLength * i, chunkLength * (i + 1))
                      );
                  }
                  return chunks;
                };
                let yearMonth = [];
                for (let k = 3; k < jsonData.length; k++) {
                  let arr = jsonData[k].slice(daysIndex, jsonData[k].length);
                  let newArr = chunkArray(arr, yearsArr.length);
                  let yearObj = {};
                  for (let l = 0; l < yearsArr.length; l++) {
                    let monthObj = {};
                    // console.log("newArr", newArr[l]);
                    if (newArr && newArr[l]) {
                      for (let m = 0; m < newArr[l].length; m++) {
                        // excelRes[k-3]["Days"][yearsArr[l]][monthsArr[m]] = newArr[l][m]
                        monthObj[monthsArr[m]] =
                          newArr[l][m] !== undefined &&
                          newArr[l][m] !== null &&
                          newArr[l][m] !== ""
                            ? newArr[l][m]
                            : 0;
                      }
                    }
                    yearObj[yearsArr[l]] = monthObj;
                    // excelRes[k-3]["Days"][yearsArr[l]] = monthObj
                  }
                  yearMonth.push(yearObj);
                }
                for (let y = 3; y < jsonData.length; y++) {
                  excelRes[y - 3]["Days"] = yearMonth[y - 3];
                }

                // Function to filter keys for each object
                const filterObjectKeys = (obj, allowedKeys) => {
                  const newObj = {};
                  for (const key in obj) {
                    if (allowedKeys.includes(key)) {
                      newObj[key] = obj[key];
                    }
                  }
                  return newObj;
                };

                excelRes = Object.keys(excelRes || {}).reduce((acc, key) => {
                  const filteredObject = filterObjectKeys(
                    excelRes[key],
                    headerItems
                  );
                  acc[key] = filteredObject;
                  return acc;
                }, {});

                let errMsg = [];
                console.log("excelRes*****", excelRes);

                // Check if all items in headerArr are valid
                const isValid = headerArr
                  .filter((item) => item.trim() !== "")
                  .every((item) => headerItems.includes(item));
                if (!isValid) {
                  errMsg.push(
                    `Invalid Header. It should include:
                  ( ${headerItems.join(", ")} )`
                  );
                } else {
                }

                // Validate Years
                const yearsBetweenDates = getYearsBetweenDates(
                  commercialStartDate,
                  commercialEndDate
                );

                // Ensure startYear and endYear are correctly derived even if the dates are the same
                const startYear =
                  commercialStartDate === ""
                    ? new Date().getFullYear()
                    : new Date(commercialStartDate).getFullYear();
                const endYear =
                  commercialEndDate === ""
                    ? new Date().getFullYear()
                    : new Date(commercialEndDate).getFullYear();

                // Include the endYear explicitly in the range if start and end dates are the same
                const yearsRange =
                  yearsBetweenDates.length > 0
                    ? yearsBetweenDates
                    : [startYear];

                const isValidYears = yearsArr.every((year) =>
                  yearsRange.includes(year)
                );

                if (!isValidYears) {
                  errMsg.push(
                    `Invalid Years. All years under Days column must be between ${startYear} and ${endYear}.`
                  );
                } else {
                }

                //Validate Values & Combinations
                let newExcelRes = {};
                for (let key in excelRes) {
                  if (excelRes.hasOwnProperty(key)) {
                    let item = excelRes[key];
                    let newItem = { ...item }; // Create a copy of the item

                    newExcelRes[key] = newItem;
                  }
                }
                newExcelRes = Object.values(newExcelRes || {});

                const rtArrObj = rateCardData.disassembledData.values.map(
                  (item) => {
                    let obj = {};
                    rateCardData.disassembledData.keys.forEach((key, index) => {
                      if (
                        key !== "Item Id" &&
                        key !== "Price" &&
                        key !== "Currency" &&
                        key !== "Rate Basis" &&
                        key !== "Rate Period"
                      ) {
                        obj[key] = item[index];
                      }
                    });
                    return obj;
                  }
                );

                // Function to compare two objects
                const compareObjects = (obj1, obj2) => {
                  for (let key in obj1) {
                    if (obj1.hasOwnProperty(key)) {
                      obj1[key] = String(obj1[key]);
                    }
                  }

                  for (let key in obj2) {
                    if (obj2.hasOwnProperty(key)) {
                      obj2[key] = String(obj2[key]);
                    }
                  }

                  const keys1 = Object.keys(obj1 || {});
                  const keys2 = Object.keys(obj2 || {});
                  if (keys1.length !== keys2.length) {
                    return false;
                  }
                  for (const key of keys1) {
                    if (obj1[key] !== obj2[key]) {
                      return false;
                    }
                  }
                  return true;
                };

                // Function to find a matching object in the array
                const findMatchingObject = (obj, arr) => {
                  return arr.find((item) => compareObjects(obj, item));
                };

                let notVerifiedValues = [];
                // Compare newExcelRes with rtArrObj
                newExcelRes.forEach((obj) => {
                  let newItem = { ...obj }; // Create a copy of the item
                  // Remove unnecessary fields
                  delete newItem["Start Date"];
                  delete newItem["End Date"];
                  delete newItem["Days"];
                  delete newItem["Price"];
                  delete newItem["Currency"];
                  delete newItem["Rate Basis"];
                  delete newItem["Rate Period"];
                  delete newItem["Resource Id"];

                  if (!findMatchingObject(newItem, rtArrObj)) {
                    exceptionData.push(obj);
                    notVerifiedValues.push(
                      "Values and Values combinations given in excel not matched with selected rate table items."
                    );
                  }
                });

                if (notVerifiedValues.length === 0) {
                } else {
                  errMsg.push(
                    "Values and Values combinations given in excel not matched with selected rate table items."
                  );
                }

                // Validate start date and end date with contract start date and end date

                // Function to compare dates
                let dateVerification = [];
                // Function to parse dates from Excel and other formats
                let parseDate = (input) => {
                  if (!input) return null; // Return null for empty or undefined inputs

                  if (typeof input === "number") {
                    // Convert Excel serial number to JavaScript Date
                    return new Date((input - 25569) * 86400 * 1000);
                  }

                  if (typeof input === "string") {
                    // Try to parse the string directly
                    let parsedDate = new Date(input);
                    if (!isNaN(parsedDate)) {
                      return parsedDate;
                    }

                    // Handle custom formats (dd/mm/yyyy or mm/dd/yyyy)
                    const parts = input.split(/[-\/\s]/);
                    if (parts.length === 3) {
                      const [first, second, third] = parts.map(Number);
                      if (third > 31) {
                        // Assume yyyy is the third part
                        if (first > 12) {
                          // dd/mm/yyyy
                          return new Date(`${third}-${second}-${first}`);
                        } else {
                          // mm/dd/yyyy
                          return new Date(`${third}-${first}-${second}`);
                        }
                      }
                    }
                  }

                  return null;
                };

                // Function to check if dates are within contract range
                const isDateWithinContract = (
                  startDate,
                  endDate,
                  contractStartDate,
                  contractEndDate
                ) => {
                  // Parse and normalize dates to remove irrelevant time comparison
                  const normalizeDate = (date) => {
                    const parsedDate = parseDate(date);
                    if (!parsedDate || isNaN(parsedDate)) {
                      throw new Error(`Invalid date: ${date}`);
                    }
                    return new Date(
                      parsedDate.getFullYear(),
                      parsedDate.getMonth(),
                      parsedDate.getDate()
                    );
                  };

                  try {
                    startDate = normalizeDate(startDate);
                    endDate = normalizeDate(endDate);
                    contractStartDate = normalizeDate(contractStartDate);
                    contractEndDate = normalizeDate(contractEndDate);

                    return (
                      startDate >= contractStartDate &&
                      endDate <= contractEndDate
                    );
                  } catch (error) {
                    console.error(error.message);
                    return false;
                  }
                };

                // Iterate through excelRes objects and check date range
                for (const key in excelRes) {
                  if (excelRes.hasOwnProperty(key)) {
                    const obj = excelRes[key];
                    const start_date = obj["Start Date"];
                    const end_date = obj["End Date"];

                    if (
                      !isDateWithinContract(
                        start_date,
                        end_date,
                        commercialStartDate,
                        commercialEndDate
                      )
                    ) {
                      exceptionData.push(obj);
                      dateVerification.push(
                        `Object ${key} is outside the contract date range.`
                      );
                    }
                  }
                }

                // Handle error messages
                if (dateVerification.length > 0) {
                  errMsg.push(
                    "Some rows start date and end date are outside the contract date range."
                  );
                }

                // Validate Each Rows End Date against it's start date
                let endDateVerification = [];
                for (const key in excelRes) {
                  const resource = excelRes[key];
                  const start_date = new Date(
                    typeof resource["Start Date"] === "number"
                      ? (resource["Start Date"] - 25569) * 86400 * 1000
                      : resource["Start Date"]
                  );
                  const end_date = new Date(
                    typeof resource["End Date"] === "number"
                      ? (resource["End Date"] - 25569) * 86400 * 1000
                      : resource["End Date"]
                  );

                  if (end_date < start_date) {
                    exceptionData.push(resource);
                    endDateVerification.push(
                      `Invalid date range for Resource ID ${resource["Resource Id"]}: End Date is before the Start Date.`
                    );
                  }
                }

                if (endDateVerification.length === 0) {
                  console.log(
                    "Validation successful. All End Dates are less than or equal to Start Dates."
                  );
                } else {
                  errMsg.push(
                    "Some rows End Dates are not less than or equal to Start Dates."
                  );
                }

                // Days count validation
                let errMsgInDaysVerify = [];
                let dayBasedErr = [];
                let fteBasedErr = [];
                for (const key in excelRes) {
                  if (excelRes.hasOwnProperty(key)) {
                    const entry = excelRes[key];
                    // excelResArr.forEach((excelObj) => {
                    // Find matching object in rtDataArrOfObj based on Role, Grade, and Location
                    const matchingObj = rateCardData.assembledData.find(
                      (rtObj) => {
                        return (
                          entry.Role === rtObj.Role &&
                          entry.Grade === rtObj.Grade &&
                          entry.Location === rtObj.Location
                        );
                      }
                    );

                    // If a matching object is found, add its Item Id to excelResArr object
                    if (matchingObj) {
                      entry["Rate Basis"] = matchingObj["Rate Basis"];
                      entry["Rate Period"] = matchingObj["Rate Period"];
                    }
                    // });

                    const start_date = new Date(
                      typeof entry["Start Date"] === "number"
                        ? (entry["Start Date"] - 25569) * 86400 * 1000
                        : entry["Start Date"]
                    );
                    const monthNameStart = start_date
                      .toLocaleString("default", { month: "short" })
                      .toLowerCase()
                      .substring(0, 3);
                    const yearStart = start_date.getFullYear();
                    const daysCountStart =
                      new Date(
                        yearStart,
                        start_date.getMonth() + 1,
                        0
                      ).getDate() -
                      start_date.getDate() +
                      1;

                    const end_date = new Date(
                      typeof entry["End Date"] === "number"
                        ? (entry["End Date"] - 25569) * 86400 * 1000
                        : entry["End Date"]
                    );
                    const monthNameEnd = end_date
                      .toLocaleString("default", { month: "short" })
                      .toLowerCase()
                      .substring(0, 3);
                    const yearEnd = end_date.getFullYear();
                    const daysCountEnd = end_date.getDate();

                    const daysData = entry["Days"];

                    for (const year in daysData) {
                      if (daysData.hasOwnProperty(year)) {
                        for (const month in daysData[year]) {
                          if (daysData[year].hasOwnProperty(month)) {
                            const daysCount =
                              daysData[year][month] === undefined
                                ? 0
                                : daysData[year][month];

                            const monthMap = monthsShort.reduce(
                              (acc, month, index) => {
                                acc[month] = index + 1;
                                return acc;
                              },
                              {}
                            );
                            const monthNumber = monthMap[month.toLowerCase()];
                            const daysInMonth = new Date(
                              year,
                              monthNumber,
                              0
                            ).getDate();

                            if (entry["Rate Period"] === "Day Rate") {
                              if (
                                ((state.contractType === "CCN" ||
                                  state.contractType === "CR") &&
                                  !Number.isInteger(daysCount)) ||
                                (state.contractType !== "CCN" &&
                                  state.contractType !== "CR" &&
                                  (daysCount < 0 ||
                                    !Number.isInteger(daysCount)))
                              ) {
                                exceptionData.push(entry);
                                dayBasedErr.push(
                                  "For Days Based Rate, given day value must be a whole number."
                                );
                              }
                              if (
                                Number(year) === yearStart &&
                                month === monthNameStart
                              ) {
                                if (
                                  Number(daysCount) > Number(daysCountStart)
                                ) {
                                  exceptionData.push(entry);
                                  errMsgInDaysVerify.push(
                                    `For Days Based Rate, given day value must be within possible days as per Contract Start date and Contract End Date.`
                                  );
                                }
                              } else if (
                                Number(year) === yearEnd &&
                                month === monthNameEnd
                              ) {
                                if (Number(daysCount) > Number(daysCountEnd)) {
                                  exceptionData.push(entry);
                                  errMsgInDaysVerify.push(
                                    `For Days Based Rate, given day value must be within possible days as per Contract Start date and Contract End Date.`
                                  );
                                }
                              } else {
                                if (Number(daysCount) > Number(daysInMonth)) {
                                  exceptionData.push(entry);
                                  errMsgInDaysVerify.push(
                                    `For Days Based Rate, given day value must be within possible days as per Contract Start date and Contract End Date.`
                                  );
                                }
                              }
                            } else {
                              console.log("dayscount", Number(daysCount));
                              let regExp =
                                state.contractType === "CCN" ||
                                state.contractType === "CR"
                                  ? /^-?\d*\.?\d*$/
                                  : /^\d+(\.\d+)?$/;

                              if (
                                !regExp.test(
                                  (Number(daysCount) || 0).toString()
                                )
                              ) {
                                exceptionData.push(entry);
                                fteBasedErr.push(
                                  "For FTE Rate Basis, entered value must be a whole number or two digit decimal number and must be 99 or less."
                                );
                              }
                              if (Number(daysCount) > 99) {
                                exceptionData.push(entry);
                                fteBasedErr.push(
                                  "For FTE Rate Basis, entered value must be a whole number or two digit decimal number and must be 99 or less."
                                );
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                if (errMsgInDaysVerify.length === 0) {
                  console.log("All days for days based rate are verified");
                } else {
                  errMsg.push(
                    "For Days Based Rate, given day value must be within possible days as per Contract Start date and Contract End Date."
                  );
                }

                if (dayBasedErr.length !== 0) {
                  errMsg.push(dayBasedErr[0]);
                }

                if (fteBasedErr.length !== 0) {
                  errMsg.push(fteBasedErr[0]);
                }

                // Exception file download
                console.log("*****exceptionData*****", exceptionData);
                exceptionData &&
                  exceptionData.length > 0 &&
                  exceptionData.map((exc) => {
                    delete exc["Rate Basis"];
                    delete exc["Rate Period"];
                  });
                const downloadExceptionFile = () => {
                  console.log("***Exception file downlaod****");
                  const header = [jsonData[0], jsonData[1], jsonData[2]];

                  const colData =
                    exceptionData &&
                    exceptionData.length > 0 &&
                    exceptionData.map((item) => {
                      const modifiedItem = { ...item };
                      delete modifiedItem["Price"];
                      delete modifiedItem["Currency"];
                      delete modifiedItem["Rate Basis"];
                      delete modifiedItem["Rate Period"];
                      modifiedItem["Start Date"] =
                        item["Start Date"] &&
                        moment(parseDate(item["Start Date"])).format(
                          "DD MMM YYYY"
                        );
                      modifiedItem["End Date"] =
                        item["End Date"] &&
                        moment(parseDate(item["End Date"])).format(
                          "DD MMM YYYY"
                        );
                      const row = jsonData[0].map((key) => {
                        if (key === "Days") {
                          const yearData = [];
                          yearsBetweenDates.forEach((year) => {
                            monthsShort.forEach((month) => {
                              const value =
                                (item[key] &&
                                  item[key][year] &&
                                  item[key][year][month]) ||
                                0;
                              yearData.push(value);
                            });
                          });
                          return yearData;
                        } else {
                          return modifiedItem[key];
                        }
                      });
                      return row.flat(); // Flatten the array structure
                    });

                  const ws = XLSX.utils.aoa_to_sheet([...header, ...colData]);

                  ws["!merges"] = [
                    {
                      s: { r: 0, c: daysIndex },
                      e: { r: 0, c: jsonData[2].length - 1 },
                    },
                  ];

                  jsonData[0].map((data, index) => {
                    if (data !== "Days")
                      ws["!merges"].push({
                        s: { r: 0, c: index },
                        e: { r: 2, c: index },
                      });
                  });

                  yearsBetweenDates.forEach((year) => {
                    const startCol = header[1].indexOf(year);
                    const mergeConfig = {
                      s: { r: 1, c: startCol },
                      e: { r: 1, c: startCol + 11 },
                    };
                    ws["!merges"].push(mergeConfig);
                  });

                  // Align content left for all cells
                  for (let r = 0; r < header.length; r++) {
                    for (let c = 0; c < header[r].length; c++) {
                      const cellAddress = XLSX.utils.encode_cell({ r, c });
                      ws[cellAddress].s = { alignment: { horizontal: "left" } };
                    }
                  }

                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, ws, "Commercial Data");

                  XLSX.writeFile(wb, "exception_file.xlsx");
                };

                if (errMsg.length === 0) {
                  // If Uploaded file have error, then show error modal
                  console.log("Excel uploaded***");

                  let excelResArr = Object.keys(excelRes || {}).map((data) => {
                    excelRes[data]["key"] = Number(data);
                    excelRes[data]["Resource Id"] = `RE_${Number(data) + 1}`;

                    // Convert Excel dates (if in numeric format) to dayjs objects
                    excelRes[data]["Start Date"] = dayjs(
                      typeof excelRes[data]["Start Date"] === "number"
                        ? (excelRes[data]["Start Date"] - 25569) * 86400 * 1000
                        : excelRes[data]["Start Date"]
                    );

                    excelRes[data]["End Date"] = dayjs(
                      typeof excelRes[data]["End Date"] === "number"
                        ? (excelRes[data]["End Date"] - 25569) * 86400 * 1000
                        : excelRes[data]["End Date"]
                    );

                    return excelRes[data];
                  });

                  excelResArr.forEach((excelObj) => {
                    // Find matching object in rtDataArrOfObj based on Role, Grade, and Location
                    const matchingObj = rateCardData.assembledData.find(
                      (rtObj) => {
                        return (
                          excelObj.Role === rtObj.Role &&
                          excelObj.Grade === rtObj.Grade &&
                          excelObj.Location === rtObj.Location
                        );
                      }
                    );

                    // If a matching object is found, add its Item Id to excelResArr object
                    if (matchingObj) {
                      excelObj["Item Id"] = matchingObj["Item Id"];
                      excelObj["Price"] = matchingObj["Price"];
                      excelObj["Currency"] = matchingObj["Currency"];
                      excelObj["Rate Basis"] = matchingObj["Rate Basis"];
                      excelObj["Rate Period"] = matchingObj["Rate Period"];
                    }
                  });

                  // setCommercialTableData(excelRes);

                  let initialYearsList = {};
                  excelResArr &&
                    excelResArr.length !== 0 &&
                    excelResArr.map((data) => {
                      const startDate = new Date(data["Start Date"]);
                      const endDate = new Date(data["End Date"]);
                      const startYear = startDate.getFullYear();
                      const endYear = endDate.getFullYear();
                      let output = {};
                      let currentYear = startYear;
                      let currentMonth = startDate.getMonth(); // 0 (January) to 11 (December)
                      while (
                        currentYear < endYear ||
                        (currentYear === endYear &&
                          currentMonth <= endDate.getMonth())
                      ) {
                        const monthAbbreviation = dayjs()
                          .month(currentMonth)
                          .format("MMM")
                          .toLowerCase();
                        if (!output[currentYear]) {
                          output[currentYear] = [];
                        }
                        output[currentYear].push(monthAbbreviation);
                        if (currentMonth === 11) {
                          // December, move to next year
                          currentMonth = 0;
                          currentYear++;
                        } else {
                          currentMonth++;
                        }
                      }
                      initialYearsList = {
                        ...initialYearsList,
                        [data["key"]]: output,
                      };
                    });

                  console.log("initialYearsList: ", initialYearsList);

                  yearsList.current = initialYearsList;

                  console.log("excelResArr: ", excelResArr);

                  setRowCount(
                    excelResArr &&
                      excelResArr.length !== 0 &&
                      excelResArr.length
                  );

                  daysTableRef.current = {
                    ...excelResArr.map((data, index) => (index = data["Days"])),
                  };

                  let costObj = {};
                  for (const item of excelResArr) {
                    const key = item["key"];
                    const price = item["Price"];
                    const days = item["Days"];

                    if (!costObj[key]) {
                      costObj[key] = {};
                    }
                    for (const year in days) {
                      if (!costObj[key][year]) {
                        costObj[key][year] = {};
                      }
                      for (const month in days[year]) {
                        const daysValue =
                          days[year][month] !== undefined
                            ? days[year][month]
                            : 0;
                        if (!costObj[key][year][month]) {
                          costObj[key][year][month] = Number(
                            item["Rate Period"] !== "Yearly Rate"
                              ? daysValue * price
                              : Number.isInteger((daysValue / 12) * price)
                              ? (daysValue / 12) * price
                              : ((daysValue / 12) * price).toFixed(2)
                          );
                        } else {
                          costObj[key][year][month] += Number(
                            item["Rate Period"] !== "Yearly Rate"
                              ? daysValue * price
                              : Number.isInteger((daysValue / 12) * price)
                              ? (daysValue / 12) * price
                              : ((daysValue / 12) * price).toFixed(2)
                          );
                        }
                      }
                    }
                  }

                  costsTableRef.current = { ...costObj };

                  console.log("daysTableRef: ", daysTableRef.current);
                  console.log("costsTableRef: ", costsTableRef.current);

                  let finalExcelRessArr = excelResArr.map((item) => {
                    const cost = costObj[item.key];

                    return {
                      ...item,
                      Cost: cost,
                    };
                  });

                  setCommercialDataSource(finalExcelRessArr);
                  setTableLoader(false);
                } else {
                  Modal.error({
                    className: "popup-model-success",
                    centered: true,
                    icon: false,
                    content: (
                      <div>
                        <div style={{ fontWeight: 600 }} className="mb-10">
                          Upload failed for following reasons:
                        </div>
                        {errMsg.map((err, index) => {
                          return (
                            <div>
                              {index + 1}.&nbsp; {err}
                            </div>
                          );
                        })}
                        <a onClick={downloadExceptionFile} className="mt-10">
                          Click here to download exception file
                        </a>
                      </div>
                    ),
                  });
                  setTableLoader(false);
                }
              } else {
                Modal.error({
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: <div>Please upload excel with valid data.</div>,
                });
                setTableLoader(false);
              }
            };
            fileReader.readAsArrayBuffer(file);
          } catch (error) {
            console.error("Error:", error);
          }
        }
      }

      return formatCheck || Upload.LIST_IGNORE;
    },
  };

  const getTotalCommercialDaysCosts = (
    data,
    calculationType,
    calculationFor
  ) => {
    const sumValues = (yearData) => {
      return Object.values(yearData).reduce((yearAcc, months) => {
        return (
          yearAcc +
          Object.values(months).reduce((monthAcc, value) => {
            return monthAcc + (isNaN(value) ? 0 : value);
          }, 0)
        );
      }, 0);
    };

    if (calculationFor === "single_row") {
      return data ? sumValues(data) : 0;
    }

    if (calculationFor === "table") {
      return Object.values(data).reduce((tableAcc, row) => {
        const rowData = calculationType === "days" ? row : row;
        return tableAcc + (rowData ? sumValues(rowData) : 0);
      }, 0);
    }

    return 0;
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    let newDataSource = [];

    if (daysModalRef.current) {
      newDataSource = Object.entries(daysTableRef.current).map(
        ([key, item]) => {
          const daysObj = item[year] || {};
          const total = Object.values(daysObj)
            .filter((val) => !isNaN(val))
            .reduce((sum, val) => sum + val, 0);

          return {
            role: commercialDataSource.find((row) => row.key == key)["Role"],
            jan: daysObj.jan ? daysObj.jan.toString() : "0",
            feb: daysObj.feb ? daysObj.feb.toString() : "0",
            mar: daysObj.mar ? daysObj.mar.toString() : "0",
            apr: daysObj.apr ? daysObj.apr.toString() : "0",
            may: daysObj.may ? daysObj.may.toString() : "0",
            jun: daysObj.jun ? daysObj.jun.toString() : "0",
            jul: daysObj.jul ? daysObj.jul.toString() : "0",
            aug: daysObj.aug ? daysObj.aug.toString() : "0",
            sep: daysObj.sep ? daysObj.sep.toString() : "0",
            oct: daysObj.oct ? daysObj.oct.toString() : "0",
            nov: daysObj.nov ? daysObj.nov.toString() : "0",
            dec: daysObj.dec ? daysObj.dec.toString() : "0",
            total: total.toString(),
          };
        }
      );
    }

    if (costModalRef.current) {
      newDataSource = Object.entries(costsTableRef.current).map(
        ([key, item]) => {
          const costs = item[year] || {};
          const total = Object.values(costs)
            .filter((val) => !isNaN(val))
            .reduce((sum, val) => sum + val, 0);

          return {
            role: commercialDataSource.find((row) => row.key == key)["Role"],
            jan: costs.jan ? costs.jan.toLocaleString("en-us") : "0",
            feb: costs.feb ? costs.feb.toLocaleString("en-us") : "0",
            mar: costs.mar ? costs.mar.toLocaleString("en-us") : "0",
            apr: costs.apr ? costs.apr.toLocaleString("en-us") : "0",
            may: costs.may ? costs.may.toLocaleString("en-us") : "0",
            jun: costs.jun ? costs.jun.toLocaleString("en-us") : "0",
            jul: costs.jul ? costs.jul.toLocaleString("en-us") : "0",
            aug: costs.aug ? costs.aug.toLocaleString("en-us") : "0",
            sep: costs.sep ? costs.sep.toLocaleString("en-us") : "0",
            oct: costs.oct ? costs.oct.toLocaleString("en-us") : "0",
            nov: costs.nov ? costs.nov.toLocaleString("en-us") : "0",
            dec: costs.dec ? costs.dec.toLocaleString("en-us") : "0",
            total: total.toLocaleString("en-us"),
          };
        }
      );
    }

    setDaysCostsDataSource(newDataSource);
  };

  const calculateDaysCostsSummary = (dataSource) => {
    const summary = {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      total: 0,
    };

    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    months.forEach((month) => {
      dataSource.forEach((item) => {
        summary[month] += Number(
          item[month] ? item[month].replace(/,/g, "") : "0"
        );
      });
    });

    for (let i = 0; i < 12; i++) {
      if (!isNaN(summary[months[i]])) {
        summary.total += Number(summary[months[i]]);
        summary[months[i]] = summary[months[i]].toLocaleString("en-US");
      }
    }

    summary.total = summary.total.toLocaleString("en-us");

    return summary;
  };

  const getUniqueYears = (yearsList) => {
    const uniqueYears = new Set();
    Object.values(yearsList).forEach((yearObj) => {
      Object.keys(yearObj).forEach((year) => {
        uniqueYears.add(year);
      });
    });
    return Array.from(uniqueYears);
  };

  const uniqueYears = getUniqueYears(yearsList.current);

  useEffect(() => {
    daysModalRef.current = totalDaysModal;
    costModalRef.current = totalCostModal;
  }, [totalDaysModal, totalCostModal]);

  useEffect(() => {
    if (selectedYear) {
      handleYearChange(selectedYear);
    }
  }, [selectedYear, totalDaysModal, totalCostModal]);

  // Runs everytime commercialDataSource is updated and sends updated dataSource, days and costs back to parent component
  useEffect(() => {
    console.log("commercialDataSource: ", commercialDataSource);
    onUpdateCommercialData(
      commercialDataSource,
      yearsList.current,
      daysTableRef.current,
      costsTableRef.current,
      currencyToggleRef.current,
      negatedResource
    );
  }, [commercialDataSource]);

  const findClosestFxRate = (itemCurrency, endDate) => {
    const currencyRates = fxCurrencyList.filter(
      (fx) => fx["From Currency"] === itemCurrency
    );
    if (!currencyRates.length) return 1;

    const itemYear = dayjs(endDate).year();
    const exactMatch = currencyRates.find(
      (fx) => dayjs(fx["End Date"], "MMM-YYYY").year() === itemYear
    );

    if (exactMatch) {
      return exactMatch.Rate;
    }

    const fallbackRate = currencyRates.find(
      (fx) =>
        fx["End Date"] === "Dec-2999" && fx["From Currency"] === itemCurrency
    );

    return fallbackRate?.Rate || 1;
  };

  const handleCurrencyToggle = (mode, rowKey = null, currentMode) => {
    console.log("currentMode: ", currentMode);

    const isReportingMode = currentMode === "reporting";

    const processRow = (key) => {
      const commercialItemIndex = commercialDataSource.findIndex(
        (item) => item.key == key
      );
      if (commercialItemIndex === -1) return;

      const commercialItem = commercialDataSource[commercialItemIndex];

      const endDate = commercialItem["End Date"];
      const fxRate = findClosestFxRate(commercialItem.Currency, endDate);

      const yearData = costsTableRef.current[key];
      if (!yearData) return;

      const updatedYearData = JSON.parse(JSON.stringify(yearData));

      Object.keys(updatedYearData).forEach((year) => {
        const monthData = updatedYearData[year];
        Object.keys(monthData).forEach((month) => {
          monthData[month] = isReportingMode
            ? monthData[month] * fxRate
            : monthData[month] / fxRate;
        });
      });

      costsTableRef.current[key] = updatedYearData;

      const updatedCommercialItem = {
        ...commercialItem,
        Cost: updatedYearData,
      };

      setCommercialDataSource((prev) => {
        const newData = [...prev];
        newData[commercialItemIndex] = updatedCommercialItem;
        return newData;
      });
    };

    if (mode === "table") {
      Object.keys(costsTableRef.current).forEach(processRow);
    } else if (mode === "row" && rowKey) {
      processRow(rowKey);
    }
    console.log("costsTableRef.current: ", costsTableRef.current);
  };

  const handleToggleChange = (checked) => {
    const newCurrencyMode = checked ? "reporting" : "contract";
    console.log("newCurrencyMode: ", newCurrencyMode);

    setCurrencyMode(newCurrencyMode);
    handleCurrencyToggle("table", null, newCurrencyMode);
  };

  useEffect(() => {
    currencyToggleRef.current = currencyMode;
  }, [currencyMode]);

  return (
    <div>
      <Row gutter={[6, 0]} type="flex" align="middle" justify="space-between">
        <Col span={3}>
          <Switch
            style={{
              minWidth: 150,
              fontWeight: 600,
            }}
            disabled={
              !(
                commercialDataSource[0] &&
                commercialDataSource[0].Currency &&
                commercialDataSource[0]["End Date"] &&
                commercialDataSource[0].Cost &&
                fxCurrencyList.length !== 0
              )
            }
            checked={currencyMode === "reporting"}
            unCheckedChildren="Contract Currency"
            checkedChildren="Reporting Currency"
            onChange={handleToggleChange}
          />
        </Col>
        <Col span={21}>
          {state.mode !== "view" && (
            <Row gutter={[6, 0]} justify="end">
              <Col span={3}>
                <Upload
                  {...excelUploadProps}
                  customRequest={dummyRequest}
                  className="custom-upload-wrapper"
                >
                  <UploadButton />
                </Upload>
              </Col>
              <Col span={5}>
                <DownloadTemplateButton
                  onClick={() => exportToExcel("template")}
                />
              </Col>
              <Col span={5}>
                <DownloadAllCommercialDataButton
                  onClick={() => exportToExcel("detail")}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Table
        loading={tableLoader}
        ref={commercialTableRef}
        pagination={false}
        dataSource={commercialDataSource}
        columns={commercialTableColumns}
        scroll={{
          // x: "max-content",
          y: "calc(100vh - 345px)",
        }}
        id={dataTableForm}
        components={commercialTableComponents}
        rowClassName={() => "editable-row"}
        className={`${dataTable} mt-10`}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell
              colSpan={
                state.mode === "view"
                  ? commercialTableColumns.length - 2
                  : commercialTableColumns.length - 3
              }
              index={0}
            >
              <span
                style={{
                  color: "var(--color-solid-darkergrey)",
                }}
              >
                Total
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell
              index={
                state.mode === "view"
                  ? commercialTableColumns.length - 2
                  : commercialTableColumns.length - 3
              }
            >
              <span
                style={{
                  color: "var(--color-solid-darkerblue)",
                  textDecoration: "underline",
                  cursor: "pointer",
                  display: "block",
                  textAlign: "right",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                title={getTotalCommercialDaysCosts(
                  daysTableRef.current,
                  "days",
                  "table"
                ).toLocaleString("us")}
                onClick={() => {
                  const firstRoleYears = yearsList.current[0]
                    ? Object.keys(yearsList.current[0])
                    : [];
                  openTotalDaysModal(true);
                  handleYearChange(firstRoleYears[0] || "");
                }}
              >
                {Object.keys(daysTableRef.current).length !== 0 &&
                  getTotalCommercialDaysCosts(
                    daysTableRef.current,
                    "days",
                    "table"
                  ).toLocaleString("us")}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell
              index={
                state.mode === "view"
                  ? commercialTableColumns.length - 1
                  : commercialTableColumns.length - 2
              }
            >
              <span
                style={{
                  color: "var(--color-solid-darkerblue)",
                  textDecoration: "underline",
                  cursor: "pointer",
                  display: "block",
                  textAlign: "right",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                title={getTotalCommercialDaysCosts(
                  costsTableRef.current,
                  "cost",
                  "table"
                ).toLocaleString("en-us")}
                onClick={() => {
                  const firstRoleYears = yearsList.current[0]
                    ? Object.keys(yearsList.current[0])
                    : [];
                  openTotalCostModal(true);
                  handleYearChange(firstRoleYears[0] || "");
                }}
              >
                {Object.keys(costsTableRef.current).length !== 0 &&
                  getTotalCommercialDaysCosts(
                    costsTableRef.current,
                    "cost",
                    "table"
                  ).toLocaleString("en-us")}
              </span>
            </Table.Summary.Cell>
            {state.mode !== "view" && (
              <Table.Summary.Cell
                index={commercialTableColumns.length - 1}
              ></Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        )}
      />
      {state.mode !== "view" && (
        <div className="text-center mt-10" style={{ width: "100%" }}>
          <Button
            onClick={handleAddCommercialRow}
            shape="round"
            className="add-btn mb-10"
            size="medium"
            style={{
              width: 90,
            }}
          >
            <PlusOutlined />
          </Button>
        </div>
      )}
      {openDaysModal && (
        <Modal
          className="con-modal"
          centered
          open={openDaysModal}
          onOk={() => {
            setDaysFormData({});
            setOpenDaysModal(false);
          }}
          onCancel={() => {
            setDaysFormData({});
            setOpenDaysModal(false);
          }}
          closable={false}
          footer={null}
          width={350}
        >
          <DaysModal
            modalState={
              state.mode === "view" ||
              Object.values(negatedResource).includes(
                commercialDataSource.find(
                  (item) => item.key === recordKey.current
                )["Resource Id"]
              ) ||
              Object.keys(negatedResource).includes(
                commercialDataSource.find(
                  (item) => item.key === recordKey.current
                )["Resource Id"]
              )
                ? "view"
                : state.mode === "create"
                ? { mode: "create", parentType: state.parentType }
                : state.mode === "edit" && {
                    mode: "edit",
                    parentType: state.parentType,
                  }
            }
            formData={daysFormData}
            yearsList={yearsList.current}
            recordKey={recordKey.current}
            commercialItemRates={commercialItemRates.current}
            commercialItemDates={commercialItemDates.current}
            onClose={() => {
              setDaysFormData({});
              setOpenDaysModal(false);
            }}
            onSaveDays={handleSaveDaysCosts}
          />
        </Modal>
      )}
      {openCostsModal && (
        <Modal
          className="con-modal"
          centered
          open={openCostsModal}
          onOk={() => {
            setCostsFormData({});
            setOpenCostsModal(false);
          }}
          onCancel={() => {
            setCostsFormData({});
            setOpenCostsModal(false);
          }}
          closable={false}
          footer={null}
          width={350}
        >
          <CostModal
            formData={costsFormData}
            yearsList={yearsList.current}
            recordKey={recordKey.current}
            onClose={() => {
              setCostsFormData({});
              setOpenCostsModal(false);
            }}
          />
        </Modal>
      )}
      {totalDaysModal && (
        <Modal
          className="con-modal"
          centered
          open={totalDaysModal}
          onOk={() => openTotalDaysModal(false)}
          onCancel={() => {
            openTotalDaysModal(false);
          }}
          closable={false}
          footer={null}
          width={1250}
        >
          <TotalDaysModal
            handleYearChange={handleYearChange}
            selectedYear={selectedYear}
            uniqueYears={uniqueYears}
            daysCostsDataSource={daysCostsDataSource}
            calculateDaysCostsSummary={calculateDaysCostsSummary}
            CostAndDaysTotalTableCol={CostAndDaysTotalTableCol}
            openTotalDaysModal={openTotalDaysModal}
          />
        </Modal>
      )}
      {totalCostModal && (
        <Modal
          className="con-modal"
          centered
          open={totalCostModal}
          onOk={() => openTotalCostModal(false)}
          onCancel={() => {
            openTotalCostModal(false);
          }}
          closable={false}
          footer={null}
          width={1250}
        >
          <TotalCostModal
            handleYearChange={handleYearChange}
            selectedYear={selectedYear}
            uniqueYears={uniqueYears}
            daysCostsDataSource={daysCostsDataSource}
            calculateDaysCostsSummary={calculateDaysCostsSummary}
            CostAndDaysTotalTableCol={CostAndDaysTotalTableCol}
            openTotalCostModal={openTotalCostModal}
          />
        </Modal>
      )}
    </div>
  );
};

export default React.memo(CommercialTable);
