import React from "react";
import SupplierTree from "./SupplierTree";
import ContractController from "../Contract/ContractController";

const ContractRouter = ({ breadcrumbs }) => {
  return (
    <div>
      {!breadcrumbs[breadcrumbs.length - 1].inspectItem ? (
        <SupplierTree initialBreadcrumbs={breadcrumbs} />
      ) : (
        <ContractController
          mode={{ view: true, edit: false, create: false }}
          breadcrumbs={breadcrumbs}
          location={"contractTree"}
        />
      )}
    </div>
  );
};

export default React.memo(ContractRouter);
