import React, { useState } from "react";
import { Layout } from "antd"; //antd components
import { useSearchParams } from "react-router-dom";
import TopHeader from "../components/Header";
import "../assets/style/contract.css";
import ContractPipeline from "../components/Contract/ContractPipeline";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../store/store";
import ContractList from "../components/Contract/ContractList";
import CreateContract from "../components/Contract/CreateContract";
import ContractTabManager from "../components/Contract/ContractTabManager";
import { ContractTabProvider } from "../components/Contract/ContractTabContext";

function Contract() {
  // Variable to hold search param value
  const [searchParams] = useSearchParams();
  // Variable to hold view contract data
  let backToTab = searchParams.get("view");
  // Variable to hold manu key
  const menuKey = "2";
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to hold header item value
  const tabkey = headerChanger.headerItem;
  // State variable to hold the screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold pipeline table data
  const [pipelineTable, setPipelineTable] = useState(true);
  /* Function to handle change the screen size
   * @Param val - a screen size
   */
  const changeScreenSize = (val) => {
    setScreenSize(val);
  };

  return (
    <div className="contract">
      <Layout className="header-layout">
        <div style={{ display: screenSize === "min" ? "block" : "none" }}>
          <TopHeader
            headerChange={header}
            feedRow={false}
            searchRow={false}
            menuKey={menuKey}
            sticky=""
          />
        </div>

        {backToTab !== "con" && (
          <div>
            {/* <ContractTabProvider> */}
            <div className="contract-tab-content">
              {tabkey === "1" && <ContractTabManager />}
            </div>
            <div className="contract-tab-content">
              {tabkey === "2" && (
                <ContractPipeline
                  changeScreenSize={changeScreenSize}
                  pipelineTable={pipelineTable}
                />
              )}
            </div>
            <div className="contract-tab-content">
              {tabkey === "3" && <CreateContract />}
            </div>
            <div className="contract-tab-content">
              {tabkey === "4" && <ContractList />}
            </div>
            {/* </ContractTabProvider> */}
          </div>
        )}
      </Layout>
    </div>
  );
}

export default Contract;
