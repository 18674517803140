import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Image } from "antd";
import duplicate from "../assets/images/icons/duplicate.svg";
import delete_disable from "../assets/images/icons/delete_disable.png";
import edit from "../assets/images/icons/edit_pen.svg";
import deleteIcon from "../assets/images/icons/delete.svg";
import { createActivityLogApi } from "../api/logApi";
import PopupModal from "../PopupModal";
const ActionButton = ({
  AccessCheck,
  data,
  dataStatus,
  itemName,
  setHeaderChanger,
  headerValue,
  menuItem,
  subMenuItem,
  deleteInfo,
  handleDelete,
  handleView,
}) => {
  const navigate = useNavigate();
  const popupModalInstance = PopupModal();
  const setHeader = () => {
    setHeaderChanger({
      header: headerValue.header,
      headerItem: headerValue.headerItem,
      headerItemName: headerValue.headerItemName,
      stateSetter: data,
    });
  };

  const handleDeleteClick = () => {
    popupModalInstance.confirModal(deleteInfo).then(async (userchoice) => {
      if (userchoice.action === "delete") {
        handleDelete(
          data.id ? data.id : data._id,
          itemName,
          userchoice.inputValue
        );
      }
    });
  };

  return (
    <>
      <div
        style={{
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        {AccessCheck.view && (
          <EyeOutlined
            title="View"
            style={{
              fontSize: "20px",
              color: "var(--color-solid-darkgrey)",
            }}
            className="cursor-pointer"
            onClick={() => {
              setHeader();
              handleView(data);
              navigate(`/${menuItem}/view/${subMenuItem}`, {
                state: { data, flag: "view" },
              });
            }}
          />
        )}
        {AccessCheck.create && (
          <Image
            title="Edit"
            src={edit}
            preview={false}
            style={{ width: "20px" }}
            className="cursor-pointer"
            onClick={() => {
              setHeader();
              navigate(`/${menuItem}/edit/${subMenuItem}`, {
                state: { data, flag: "edit" },
              });
            }}
          />
        )}

        {AccessCheck.create && (
          <Image
            title="Clone"
            src={duplicate}
            style={{ width: "20px" }}
            preview={false}
            className="cursor-pointer"
            onClick={() => {
              setHeader();
              navigate(`/${menuItem}/clone/${subMenuItem}`, {
                state: { data, flag: "clone" },
              });
            }}
          />
        )}

        {AccessCheck.create && (
          <>
            {dataStatus === "In Draft" ? (
              <>
                <Image
                  title="Delete"
                  src={deleteIcon}
                  preview={false}
                  style={{ width: "20px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    handleDeleteClick();
                  }}
                />
              </>
            ) : (
              <>
                <Image
                  title="Delete"
                  src={delete_disable}
                  style={{ width: "20px" }}
                  preview={false}
                  className="cursor-not-allowed"
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ActionButton;
