import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Table, Input } from "antd";
import { OkButton } from "../GlobalButton";

const CostModal = ({ formData, yearsList, recordKey, onClose }) => {
  const [costForm] = Form.useForm();
  const recordYearList = yearsList[recordKey];
  const [chargingYear, setChargingYear] = useState(
    Object.keys(recordYearList || {})[0]
  );
  const [chargingYearTotal, setChargingYearTotal] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    const yearKey =
      Object.keys(recordYearList || {}).length !== 0
        ? Object.keys(recordYearList || {})[0]
        : null;

    if (yearKey) {
      const values = formData[chargingYear] || {};
      const formattedValues = Object.keys(values).reduce((acc, key) => {
        const value = values[key];
        acc[key] = value ? parseInt(value).toLocaleString("en-US") : "";
        return acc;
      }, {});

      let sumOfCostOfFY = formData
        ? Object.values(formData[chargingYear]).reduce((acc, year) => {
            return acc + year;
          }, 0)
        : 0;
      setChargingYearTotal(sumOfCostOfFY);
      let sumOfCost = formData
        ? Object.values(formData).reduce((acc, year) => {
            return (
              acc +
              Object.values(year).reduce((yearSum, month) => yearSum + month, 0)
            );
          }, 0)
        : 0;
      setTotalCost(sumOfCost);

      costForm.resetFields();
      costForm.setFieldsValue(formattedValues);
    }
  }, []);

  return (
    <div>
      <Form form={costForm} name="cost_form">
        <Row align="middle" type="flex" style={{ padding: "15px 20px 5px" }}>
          <Col span={24} className="vertical-align">
            <div className="div-head text-center">
              <span>
                Cost&nbsp; &nbsp;
                <Select
                  onChange={async (key, value) => {
                    setChargingYear(key);
                    let sumOfCostOfFY = formData
                      ? Object.values(formData[key]).reduce((acc, year) => {
                          return acc + year;
                        }, 0)
                      : 0;
                    setChargingYearTotal(sumOfCostOfFY);
                    let sumOfCost = formData
                      ? Object.values(formData).reduce((acc, year) => {
                          return (
                            acc +
                            Object.values(year).reduce(
                              (yearSum, month) => yearSum + month,
                              0
                            )
                          );
                        }, 0)
                      : 0;
                    setTotalCost(sumOfCost);
                    const values = formData[key] || {};
                    const formattedValues = Object.keys(values).reduce(
                      (acc, key) => {
                        const value = values[key];
                        acc[key] = value
                          ? parseInt(value).toLocaleString("en-US")
                          : "";
                        return acc;
                      },
                      {}
                    );

                    costForm.resetFields();
                    costForm.setFieldsValue(formattedValues);
                  }}
                  defaultValue={chargingYear}
                  showSearch={true}
                  placeholder="Year"
                  options={
                    recordYearList &&
                    Object.keys(recordYearList || {}).map((data) => ({
                      key: data,
                      label: data,
                      value: data,
                    }))
                  }
                />
              </span>
            </div>
          </Col>
        </Row>
        <hr className="mt-10" />
        <Table
          dataSource={[
            {
              key: "1",
              month: "January",
              cost: costForm.getFieldValue("jan") || 0,
            },
            {
              key: "2",
              month: "February",
              cost: costForm.getFieldValue("feb") || 0,
            },
            {
              key: "3",
              month: "March",
              cost: costForm.getFieldValue("mar") || 0,
            },
            {
              key: "4",
              month: "April",
              cost: costForm.getFieldValue("apr") || 0,
            },
            {
              key: "5",
              month: "May",
              cost: costForm.getFieldValue("may") || 0,
            },
            {
              key: "6",
              month: "June",
              cost: costForm.getFieldValue("jun") || 0,
            },
            {
              key: "7",
              month: "July",
              cost: costForm.getFieldValue("jul") || 0,
            },
            {
              key: "8",
              month: "August",
              cost: costForm.getFieldValue("aug") || 0,
            },
            {
              key: "9",
              month: "September",
              cost: costForm.getFieldValue("sep") || 0,
            },
            {
              key: "10",
              month: "October",
              cost: costForm.getFieldValue("oct") || 0,
            },
            {
              key: "11",
              month: "November",
              cost: costForm.getFieldValue("nov") || 0,
            },
            {
              key: "12",
              month: "December",
              cost: costForm.getFieldValue("dec") || 0,
            },
          ]}
          columns={[
            {
              title: "Month",
              dataIndex: "month",
              key: "month",
              width: "50%",
            },
            {
              title: "Cost",
              dataIndex: "cost",
              key: "cost",
              width: "50%",
              render: (text, record) => {
                return (
                  <Input
                    value={record.cost}
                    readOnly
                    bordered={false}
                    style={{ textAlign: "right" }}
                    className="commercial-days-costs-input"
                  />
                );
              },
            },
          ]}
          pagination={false}
          id="commercial-days-costs"
          className="data-table mt-10"
        />
        <Row align="middle" type="flex" gutter={[16, 24]} className="mt-10">
          <Col
            span={24}
            className="vertical-align"
            style={{
              color: "var(--color-solid-darkblue)",
              fontWeight: "600",
              fontSize: 13,
            }}
          >
            <div>
              Total FY {chargingYear.toLocaleString("en-us")} :{" "}
              {chargingYearTotal.toLocaleString("en-us")}
            </div>
            <div>Total Cost : {totalCost.toLocaleString("en-us")}</div>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="center"
          type="flex"
          style={{ padding: "10px 0px" }}
        >
          <Col className="vertical-align" span={8}>
            <OkButton onClick={onClose} type="primary" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CostModal;
