import React, { useEffect, useState } from "react";
import { Row, Col, Button, Switch, Table, Select, Layout } from "antd"; //antd components
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import "../../assets/style/aggregate.css";
import DataTable from "../Tables/DataTable";

function AggregateValue({ contractData, sendAggregate }) {
  const { Content } = Layout;
  // State variable to hold all year list
  const [yearsList, setYearsList] = useState([]);
  // State variable to hold selected year list
  const [selectedYear, setSelectedYear] = useState("");
  // State vatiable to hold switch table visibility
  const [switchTable, setSwitchTable] = useState(false);
  // State variable to hold main table data
  const [mainTableData, setMainTableData] = useState([]);
  // State variable to hold sub table data
  const [subTableData, setSubTableData] = useState([]);
  // State variable to hold yearly sum of cost value
  const [yearlySumCost, setYearlySumCost] = useState({});
  // State variable to hold sum of total cost
  const [totalSumCost, setTotalSumCost] = useState({});
  // State variable to hold yearly sum of days
  const [yearlySumDays, setYearlySumDays] = useState({});
  // State variable to hold sum of total days
  const [totalSumDays, setTotalSumDays] = useState({});
  // State variable to hold contract data view and edit visibility
  const [checked, setChecked] = useState(false);
  // State variable to hold sub cost of year
  const [subcostByYear, setSubcostByYear] = useState();
  // State variable to hold sub days of year
  const [subdaysByYear, setSubdaysByYear] = useState();
  console.log("contractData", contractData);
  // Function which sends Aggregate Table data back to Reports components while Creating, viewing and editing reports
  useEffect(() => {
    if (contractData.report) {
      sendAggregate({
        expanded: switchTable,
        tableData: switchTable ? subTableData : mainTableData,
      });
    }
  }, [mainTableData, subTableData, switchTable]);
  // Function to handle sum of cost per year and days per year for aggregate
  useEffect(() => {
    if (
      (contractData && contractData.mode === "create") ||
      (contractData && contractData.mode === "edit-updated")
    ) {
      if (
        contractData.contractType === "CR" ||
        contractData.contractType === "CCN"
      ) {
        let ratecardData = [];
        if (
          contractData.contracts.commercial_data[0].ratetable_data &&
          contractData.contracts.commercial_data[0].ratetable_data.length !== 0
        ) {
          for (
            let i = 0;
            i <
            contractData.contracts.commercial_data[0].ratetable_data[0]
              .rt_items_detail.length;
            i++
          ) {
            let detail =
              contractData.contracts.commercial_data[0].ratetable_data[0]
                .rt_items_detail[i];
            let dataObj = {};
            for (
              let j = 0;
              j <
              contractData.contracts.commercial_data[0].ratetable_data[0]
                .rt_items_desc.length;
              j++
            ) {
              dataObj[
                contractData.contracts.commercial_data[0].ratetable_data[0].rt_items_desc[
                  j
                ]
              ] = detail[j];
            }
            ratecardData.push(dataObj);
          }
        }

        let sowDataParent = [];
        let sowDataChild = [];
        if (
          Object.keys(contractData.contracts || {}).length !== 0 &&
          contractData.contracts.commercial_data.length !== 0
        ) {
          for (
            let i = 0;
            i <
            contractData.contracts.commercial_data[0].commercial_details.length;
            i++
          ) {
            let detail =
              contractData.contracts.commercial_data[0].commercial_details[i];
            let dataObj = {
              contract_name: contractData.contracts.contract_name,
              contract_id: contractData.contracts._id,
              contract_type: contractData.contracts.contract_type,
              parent_cid: contractData.contracts.parent_cid,
            };
            for (
              let j = 0;
              j <
              contractData.contracts.commercial_data[0].commercial_desc.length;
              j++
            ) {
              dataObj[
                contractData.contracts.commercial_data[0].commercial_desc[j]
              ] = detail[j];
            }
            sowDataParent.push(dataObj);
          }
        }
        if (
          Object.keys(contractData.contracts || {}).length !== 0 &&
          contractData.contracts.children !== 0
        ) {
          contractData.contracts.children.map((data) => {
            if (data.commercial_data && data.commercial_data.length !== 0) {
              for (
                let i = 0;
                i < data.commercial_data.commercial_details.length;
                i++
              ) {
                let detail = data.commercial_data.commercial_details[i];
                let dataObj = {
                  contract_name: data.contract_name,
                  contract_id: data._id,
                  contract_type: data.contract_type,
                  parent_cid: data.parent_cid,
                };
                for (
                  let j = 0;
                  j < data.commercial_data.commercial_desc.length;
                  j++
                ) {
                  dataObj[data.commercial_data.commercial_desc[j]] = detail[j];
                }
                sowDataChild.push(dataObj);
              }
            }
          });
        }

        let finalSowDataParent = [];
        let finalSowDataChild = [];
        sowDataParent &&
          sowDataParent.length !== 0 &&
          sowDataParent.map((t1, index) => {
            Object.assign(t1, { key: index });
            finalSowDataParent.push({
              ...t1,
              ...(ratecardData &&
                ratecardData.length !== 0 &&
                ratecardData.find((t2) => {
                  return t2["Item Id"] === t1["RtItem Id"];
                })),
            });
          });
        sowDataChild &&
          sowDataChild.length !== 0 &&
          sowDataChild.map((t1, index) => {
            Object.assign(t1, { key: index });
            finalSowDataChild.push({
              ...t1,
              ...(ratecardData &&
                ratecardData.length !== 0 &&
                ratecardData.find((t2) => {
                  return t2["Item Id"] === t1["RtItem Id"];
                })),
            });
          });
        finalSowDataChild = [
          ...finalSowDataChild,
          ...(contractData.newContract ? contractData.newContract : []),
        ];
        console.log("finalSowDataParent", finalSowDataParent);
        console.log("finalSowDataChild", finalSowDataChild);

        // Extract start dates and end dates from parent and child arrays
        const allStartDates = [
          ...finalSowDataParent.map((item) => new Date(item["Start Date"])),
          ...finalSowDataChild.map((item) => new Date(item["Start Date"])),
        ];

        const allEndDates = [
          ...finalSowDataParent.map((item) => new Date(item["End Date"])),
          ...finalSowDataChild.map((item) => new Date(item["End Date"])),
        ];

        // Find the farthest start date
        const farthestStartDate = new Date(Math.min(...allStartDates));

        // Find the closest end date
        const closestEndDate = new Date(Math.max(...allEndDates));

        // Parse the input dates
        let startDate =
          farthestStartDate !== ""
            ? new Date(farthestStartDate)
            : farthestStartDate === "" && closestEndDate !== ""
            ? new Date(closestEndDate)
            : new Date();
        let endDate =
          closestEndDate !== "" ? new Date(closestEndDate) : new Date();

        // Get the start year and current year
        const startYear = startDate.getFullYear();
        const currentYear = endDate.getFullYear();

        // Create an array to store the years
        const years = [];

        // Add years to the array from startYear to currentYear
        for (let year = startYear; year <= currentYear; year++) {
          years.push(year);
        }
        setYearsList(years);

        // Filter out in-active items
        const filteredItemsParent = finalSowDataParent.filter(
          (item) => item.Status !== "In-Active"
        );
        const filteredItemsChild = finalSowDataChild.filter(
          (item) => item.Status !== "In-Active"
        );

        console.log("filteredItemsParent", filteredItemsParent);
        console.log("filteredItemsChild", filteredItemsChild);

        // Calculate the cost for an array
        function calculateCost(array) {
          const cost = {};
          array.forEach((item) => {
            for (const year in item.Days) {
              if (!cost[year]) {
                cost[year] = {};
              }
              for (const month in item.Days[year]) {
                cost[year][month] =
                  Number(cost[year][month] || 0) +
                    // Number(item.Days[year][month]) * Number(item.Price);
                    item["Rate Period"] !==
                  "Yearly Rate"
                    ? Number(item.Days[year][month]) * Number(item.Price)
                    : Number.isInteger(
                        (Number(item.Days[year][month]) / 12) *
                          Number(item.Price)
                      )
                    ? (Number(item.Days[year][month]) / 12) * Number(item.Price)
                    : (
                        (Number(item.Days[year][month]) / 12) *
                        Number(item.Price)
                      ).toFixed(2);
              }
            }
          });
          return cost;
        }
        const parentCost = calculateCost(filteredItemsParent);
        let parent = {
          contract_name: filteredItemsParent[0].contract_name,
          contract_id: filteredItemsParent[0].contract_id,
          contract_type: filteredItemsParent[0].contract_type,
          parent_cid: filteredItemsParent[0].parent_cid,
          cost: parentCost,
        };

        function calculateChildCost(child) {
          const childMap = {};
          child.forEach((item) => {
            const contractId = item.contract_id;
            if (!childMap[contractId]) {
              childMap[contractId] = {
                contract_name: item.contract_name,
                contract_id: contractId,
                contract_type: item.contract_type,
                parent_cid: item.parent_cid,
                cost: {},
              };
            }

            for (const year in item.Days) {
              if (!childMap[contractId].cost[year]) {
                childMap[contractId].cost[year] = {};
              }
              for (const month in item.Days[year]) {
                const cost =
                  item["Rate Period"] !== "Yearly Rate"
                    ? Number(item.Days[year][month]) * Number(item.Price)
                    : Number.isInteger(
                        (Number(item.Days[year][month]) / 12) *
                          Number(item.Price)
                      )
                    ? (Number(item.Days[year][month]) / 12) * Number(item.Price)
                    : (
                        (Number(item.Days[year][month]) / 12) *
                        Number(item.Price)
                      ).toFixed(2);
                const costValue = Number(cost);
                // const costValue =
                //   Number(item.Days[year][month]) * Number(item.Price);
                if (!childMap[contractId].cost[year][month]) {
                  childMap[contractId].cost[year][month] = costValue;
                } else {
                  childMap[contractId].cost[year][month] += Number(costValue);
                }
              }
            }
          });
          return Object.values(childMap);
        }

        const childCost = calculateChildCost(filteredItemsChild);
        const mergedCost = [parent, ...childCost];

        console.log("parentCost", parent);
        console.log("childCost", childCost);

        function buildContractTree(contracts, parentId) {
          const tree = [];

          contracts.forEach((contract) => {
            if (contract.parent_cid === parentId) {
              const children = buildContractTree(
                contracts,
                contract.contract_id
              );
              if (children.length) {
                contract.children = children;
              }
              tree.push(contract);
            }
          });

          return tree;
        }
        const treeResult = buildContractTree(mergedCost, parent.parent_cid); // Start with the top-level parent ID

        function calculateTotalSums(node) {
          if (!node) return null;

          const mainData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            original_value: 0,
            cr_value: 0,
            net_value: 0,
          };

          const monthData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            total: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          };

          if (node.cost) {
            for (const year in node.cost) {
              for (const month in node.cost[year]) {
                monthData.total += node.cost[year][month];
                monthData[month] += node.cost[year][month];
              }
            }
          }

          if (node.children) {
            mainData.cr_value = 0;
            monthData.cr_value = 0;
            mainData.children = [];
            monthData.children = [];

            for (const child of node.children) {
              const childTotalSums = calculateTotalSums(child);
              mainData.cr_value += Number(childTotalSums.mainData.net_value);
              monthData.cr_value += Number(childTotalSums.monthData.total);

              mainData.children.push(childTotalSums.mainData);
              monthData.children.push(childTotalSums.monthData);
            }
          }

          mainData.original_value = monthData.total;
          mainData.net_value =
            Number(mainData.original_value) + Number(mainData.cr_value);

          return { mainData, monthData };
        }

        function calculateYearlySums(node, year) {
          if (!node) return null;

          const yearlyData = {
            mainData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              original_value: 0,
              cr_value: 0,
              net_value: 0,
            },
            monthData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              total: 0,
              jan: 0,
              feb: 0,
              mar: 0,
              apr: 0,
              may: 0,
              jun: 0,
              jul: 0,
              aug: 0,
              sep: 0,
              oct: 0,
              nov: 0,
              dec: 0,
            },
          };

          if (node.cost && node.cost[year]) {
            for (const month in node.cost[year]) {
              yearlyData.monthData.total += Number(node.cost[year][month]);
              yearlyData.monthData[month] += Number(node.cost[year][month]);
            }
          }

          if (node.children) {
            yearlyData.mainData.cr_value = 0;
            yearlyData.monthData.cr_value = 0;
            yearlyData.mainData.children = [];
            yearlyData.monthData.children = [];

            for (const child of node.children) {
              const childYearlySums = calculateYearlySums(child, year);
              yearlyData.mainData.cr_value += Number(
                childYearlySums.mainData.net_value
              );
              yearlyData.monthData.cr_value += Number(
                childYearlySums.monthData.total
              );

              yearlyData.mainData.children.push(childYearlySums.mainData);
              yearlyData.monthData.children.push(childYearlySums.monthData);
            }
          }

          yearlyData.mainData.original_value = yearlyData.monthData.total;
          yearlyData.mainData.net_value =
            yearlyData.mainData.original_value + yearlyData.mainData.cr_value;

          return yearlyData;
        }

        // Calculate the total sums and yearly sums for the entire tree
        const totalSum = calculateTotalSums(treeResult[0]);
        const yearlySum = {};

        for (let i = 0; i <= years.length; i++) {
          if (years[i] !== undefined) {
            yearlySum[years[i]] = calculateYearlySums(treeResult[0], years[i]);
          }
        }

        function replaceZerosWithDash(obj) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === "object") {
                replaceZerosWithDash(obj[key]);
              } else if (obj[key] === 0) {
                obj[key] = "-";
              }
            }
          }
        }
        replaceZerosWithDash(totalSum);
        replaceZerosWithDash(yearlySum);

        // Calculate the days for an array
        function calculateDays(array) {
          const days = {};
          array.forEach((item) => {
            for (const year in item.Days) {
              if (!days[year]) {
                days[year] = {};
              }
              for (const month in item.Days[year]) {
                days[year][month] = parseFloat(
                  Number(
                    (days[year][month] || 0) + item.Days[year][month]
                  ).toFixed(2)
                );
              }
            }
          });
          return days;
        }
        const parentDays = calculateDays(filteredItemsParent);
        let parDays = {
          contract_name: filteredItemsParent[0].contract_name,
          contract_type: filteredItemsParent[0].contract_type,
          contract_id: filteredItemsParent[0].contract_id,
          parent_cid: filteredItemsParent[0].parent_cid,
          days: parentDays,
        };

        function calculateChildDays(child) {
          const childMapDays = {};
          child.forEach((item) => {
            const contractId = item.contract_id;
            if (!childMapDays[contractId]) {
              childMapDays[contractId] = {
                contract_name: item.contract_name,
                contract_type: item.contract_type,
                contract_id: contractId,
                parent_cid: item.parent_cid,
                days: {},
              };
            }

            for (const year in item.Days) {
              if (!childMapDays[contractId].days[year]) {
                childMapDays[contractId].days[year] = {};
              }
              for (const month in item.Days[year]) {
                const daysValue = item.Days[year][month];
                if (!childMapDays[contractId].days[year][month]) {
                  childMapDays[contractId].days[year][month] = parseFloat(
                    Number(daysValue).toFixed(2)
                  );
                } else {
                  childMapDays[contractId].days[year][month] += parseFloat(
                    Number(daysValue).toFixed(2)
                  );
                }
              }
            }
          });
          return Object.values(childMapDays);
        }

        const childDays = calculateChildDays(filteredItemsChild);

        const mergedDays = [parDays, ...childDays];

        console.log("parentDays", parDays);
        console.log("childDays", childDays);

        function buildContractTreeDays(contracts, parentId) {
          const treeDays = [];

          contracts.forEach((contract) => {
            if (contract.parent_cid === parentId) {
              const children = buildContractTreeDays(
                contracts,
                contract.contract_id
              );
              if (children.length) {
                contract.children = children;
              }
              treeDays.push(contract);
            }
          });

          return treeDays;
        }
        const treeResultDays = buildContractTreeDays(
          mergedDays,
          parent.parent_cid
        ); // Start with the top-level parent ID

        function calculateTotalSumsDays(node) {
          if (!node) return null;

          const mainData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            original_value: 0,
            cr_value: 0,
            net_value: 0,
          };

          const monthData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            total: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          };

          if (node.days) {
            for (const year in node.days) {
              for (const month in node.days[year]) {
                monthData.total += parseFloat(
                  Number(node.days[year][month]).toFixed(2)
                );
                monthData[month] += parseFloat(
                  Number(node.days[year][month]).toFixed(2)
                );
              }
            }
          }

          if (node.children) {
            mainData.cr_value = 0;
            monthData.cr_value = 0;
            mainData.children = [];
            monthData.children = [];

            for (const child of node.children) {
              const childTotalSumsDays = calculateTotalSumsDays(child);
              mainData.cr_value += parseFloat(
                Number(childTotalSumsDays.mainData.net_value).toFixed(2)
              );
              monthData.cr_value += parseFloat(
                Number(childTotalSumsDays.monthData.total).toFixed(2)
              );

              mainData.children.push(childTotalSumsDays.mainData);
              monthData.children.push(childTotalSumsDays.monthData);
            }
          }

          mainData.original_value = monthData.total;
          mainData.net_value = parseFloat(
            Number(mainData.original_value + mainData.cr_value).toFixed(2)
          );

          return { mainData, monthData };
        }

        function calculateYearlySumsDays(node, year) {
          if (!node) return null;

          const yearlyData = {
            mainData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              original_value: 0,
              cr_value: 0,
              net_value: 0,
            },
            monthData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              total: 0,
              jan: 0,
              feb: 0,
              mar: 0,
              apr: 0,
              may: 0,
              jun: 0,
              jul: 0,
              aug: 0,
              sep: 0,
              oct: 0,
              nov: 0,
              dec: 0,
            },
          };

          if (node.days && node.days[year]) {
            for (const month in node.days[year]) {
              yearlyData.monthData.total += parseFloat(
                Number(node.days[year][month]).toFixed(2)
              );
              yearlyData.monthData[month] += parseFloat(
                Number(node.days[year][month]).toFixed(2)
              );
            }
          }

          if (node.children) {
            yearlyData.mainData.cr_value = 0;
            yearlyData.monthData.cr_value = 0;
            yearlyData.mainData.children = [];
            yearlyData.monthData.children = [];

            for (const child of node.children) {
              const childYearlySums = calculateYearlySumsDays(child, year);
              yearlyData.mainData.cr_value += parseFloat(
                Number(childYearlySums.mainData.net_value).toFixed(2)
              );
              yearlyData.monthData.cr_value += parseFloat(
                Number(childYearlySums.monthData.total).toFixed(2)
              );

              yearlyData.mainData.children.push(childYearlySums.mainData);
              yearlyData.monthData.children.push(childYearlySums.monthData);
            }
          }

          yearlyData.mainData.original_value = parseFloat(
            Number(yearlyData.monthData.total).toFixed(2)
          );
          yearlyData.mainData.net_value = parseFloat(
            Number(
              yearlyData.mainData.original_value + yearlyData.mainData.cr_value
            ).toFixed(2)
          );

          return yearlyData;
        }

        // Calculate the total sums and yearly sums for the entire tree
        const totalSumDays = calculateTotalSumsDays(treeResultDays[0]);
        const yearlySumDays = {};

        for (let i = 0; i <= years.length; i++) {
          if (years[i] !== undefined) {
            yearlySumDays[years[i]] = calculateYearlySumsDays(
              treeResultDays[0],
              years[i]
            );
          }
        }
        replaceZerosWithDash(totalSumDays);
        replaceZerosWithDash(yearlySumDays);
        setYearlySumDays(yearlySumDays);
        setTotalSumDays(totalSumDays);

        // Output the results
        setYearlySumCost(yearlySum);
        setTotalSumCost(totalSum);

        console.log("totalSumDays", totalSumDays);
        console.log("yearlySumDays", yearlySumDays);
        console.log("yearlySumCost", yearlySum);
        console.log("totalSumCost", totalSum);
      } else {
        // Parse the input dates
        let startDate =
          contractData && contractData.startDate !== ""
            ? new Date(contractData.startDate)
            : contractData &&
              contractData.startDate === "" &&
              contractData.endDate !== ""
            ? new Date(contractData.endDate)
            : new Date();
        let endDate =
          contractData && contractData.endDate !== ""
            ? new Date(contractData.endDate)
            : new Date();

        // Get the start year and current year
        const startYear = startDate.getFullYear();
        const currentYear = endDate.getFullYear();

        // Create an array to store the years
        const years = [];

        // Add years to the array from startYear to currentYear
        for (let year = startYear; year <= currentYear; year++) {
          years.push(year);
        }
        setYearsList(years);
        // setSelectedYear(years[0]);
        const totalSum = {
          total: 0,
        };
        const yearlySum = {};
        const totalSumDays = {
          total: 0,
        };
        const yearlySumDays = {};

        // Iterate through the input object for cost
        for (const key in contractData.cost) {
          const yearData = contractData.cost[key];

          for (const year in yearData) {
            if (!yearlySum[year]) {
              yearlySum[year] = {
                total: 0,
              };
            }

            for (const month in yearData[year]) {
              const value = yearData[year][month];
              yearlySum[year][month] = value + (yearlySum[year][month] || 0);
              totalSum[month] = value + (totalSum[month] || 0);
              yearlySum[year].total += value;
              totalSum.total += value;
            }
          }
        }
        // Iterate through the input object for days
        for (const key in contractData.days) {
          const yearData = contractData.days[key];

          for (const year in yearData) {
            if (!yearlySumDays[year]) {
              yearlySumDays[year] = {
                total: 0,
              };
            }

            for (const month in yearData[year]) {
              const value = Number(yearData[year][month]);
              yearlySumDays[year][month] =
                value + Number(yearlySumDays[year][month] || 0);
              totalSumDays[month] = value + Number(totalSumDays[month] || 0);
              yearlySumDays[year].total += value;
              totalSumDays.total += value;
            }
          }
        }
        setYearlySumCost(yearlySum);
        setTotalSumCost(totalSum);
        setYearlySumDays(yearlySumDays);
        setTotalSumDays(totalSumDays);
        console.log("totalSumDays", totalSumDays);
        console.log("yearlySumDays", yearlySumDays);
        console.log("yearlySumCost", yearlySum);
        console.log("totalSumCost", totalSum);
      }
    } else {
      let finalSowDataParent = [];
      let finalSowDataChild = [];
      if (
        Object.keys(contractData.contracts || {}).length !== 0 &&
        contractData.contracts.commercial_data.length !== 0
      ) {
        let ratecardData = [];
        let sowDataParent = [];
        if (
          contractData.contracts.commercial_data[0].ratetable_data &&
          contractData.contracts.commercial_data[0].ratetable_data.length !== 0
        ) {
          for (
            let i = 0;
            i <
            contractData.contracts.commercial_data[0].ratetable_data[0]
              .rt_items_detail.length;
            i++
          ) {
            let detail =
              contractData.contracts.commercial_data[0].ratetable_data[0]
                .rt_items_detail[i];
            let dataObj = {};
            for (
              let j = 0;
              j <
              contractData.contracts.commercial_data[0].ratetable_data[0]
                .rt_items_desc.length;
              j++
            ) {
              dataObj[
                contractData.contracts.commercial_data[0].ratetable_data[0].rt_items_desc[
                  j
                ]
              ] = detail[j];
            }
            ratecardData.push(dataObj);
          }
        }

        for (
          let i = 0;
          i <
          contractData.contracts.commercial_data[0].commercial_details.length;
          i++
        ) {
          let detail =
            contractData.contracts.commercial_data[0].commercial_details[i];
          let dataObj = {
            contract_name: contractData.contracts.contract_name,
            contract_type: contractData.contracts.contract_type,
            contract_id: contractData.contracts._id,
            parent_cid: contractData.contracts.parent_cid,
          };
          for (
            let j = 0;
            j <
            contractData.contracts.commercial_data[0].commercial_desc.length;
            j++
          ) {
            dataObj[
              contractData.contracts.commercial_data[0].commercial_desc[j]
            ] = detail[j];
          }
          sowDataParent.push(dataObj);
        }

        sowDataParent &&
          sowDataParent.length !== 0 &&
          sowDataParent.map((t1, index) => {
            Object.assign(t1, { key: index });
            finalSowDataParent.push({
              ...t1,
              ...(ratecardData &&
                ratecardData.length !== 0 &&
                ratecardData.find((t2) => {
                  return t2["Item Id"] === t1["RtItem Id"];
                })),
            });
          });
      }

      if (
        Object.keys(contractData.contracts || {}).length !== 0 &&
        contractData.contracts.children &&
        contractData.contracts.children !== 0
      ) {
        contractData.contracts.children.map((data) => {
          let sowDataChild = [];
          let ratecardData = [];
          if (data.commercial_data && data.commercial_data.length !== 0) {
            for (
              let i = 0;
              i < data.commercial_data[0].commercial_details.length;
              i++
            ) {
              let detail = data.commercial_data[0].commercial_details[i];
              let dataObj = {
                contract_name: data.contract_name,
                contract_type: data.contract_type,
                contract_id: data._id,
                parent_cid: data.parent_cid,
              };
              for (
                let j = 0;
                j < data.commercial_data[0].commercial_desc.length;
                j++
              ) {
                dataObj[data.commercial_data[0].commercial_desc[j]] = detail[j];
              }
              sowDataChild.push(dataObj);
            }
          }

          if (
            data.commercial_data &&
            data.commercial_data.length !== 0 &&
            data.commercial_data[0].ratetable_data &&
            data.commercial_data[0].ratetable_data.length !== 0
          ) {
            for (
              let i = 0;
              i <
              data.commercial_data[0].ratetable_data[0].rt_items_detail.length;
              i++
            ) {
              let detail =
                data.commercial_data[0].ratetable_data[0].rt_items_detail[i];
              let dataObj = {};
              for (
                let j = 0;
                j <
                data.commercial_data[0].ratetable_data[0].rt_items_desc.length;
                j++
              ) {
                dataObj[
                  data.commercial_data[0].ratetable_data[0].rt_items_desc[j]
                ] = detail[j];
              }
              ratecardData.push(dataObj);
            }
          }
          sowDataChild &&
            sowDataChild.length !== 0 &&
            sowDataChild.map((t1, index) => {
              Object.assign(t1, { key: index });
              finalSowDataChild.push({
                ...t1,
                ...(ratecardData &&
                  ratecardData.length !== 0 &&
                  ratecardData.find((t2) => {
                    return t2["Item Id"] === t1["RtItem Id"];
                  })),
              });
            });
        });
      }

      // Extract start dates and end dates from parent and child arrays
      const allStartDates = [
        ...finalSowDataParent.map((item) => new Date(item["Start Date"])),
        ...finalSowDataChild.map((item) => new Date(item["Start Date"])),
      ];

      const allEndDates = [
        ...finalSowDataParent.map((item) => new Date(item["End Date"])),
        ...finalSowDataChild.map((item) => new Date(item["End Date"])),
      ];

      // Find the farthest start date
      const farthestStartDate = new Date(Math.min(...allStartDates));

      // Find the closest end date
      const closestEndDate = new Date(Math.max(...allEndDates));

      // Parse the input dates
      let startDate =
        farthestStartDate !== ""
          ? new Date(farthestStartDate)
          : farthestStartDate === "" && closestEndDate !== ""
          ? new Date(closestEndDate)
          : new Date();
      let endDate =
        closestEndDate !== "" ? new Date(closestEndDate) : new Date();

      // Get the start year and current year
      const startYear = startDate.getFullYear();
      const currentYear = endDate.getFullYear();

      // Create an array to store the years
      const years = [];

      // Add years to the array from startYear to currentYear
      for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
      }
      setYearsList(years);
      console.log("finalSowDataParent", finalSowDataParent);
      console.log("finalSowDataChild", finalSowDataChild);

      // Filter out in-active items
      const filteredItemsParent = finalSowDataParent.filter(
        (item) => item.Status !== "In-Active"
      );
      console.log("filteredItemsParent", filteredItemsParent);

      const filteredItemsChild = finalSowDataChild.filter(
        (item) => item.Status !== "In-Active"
      );
      console.log("filteredItemsChild", filteredItemsChild);

      if (
        (filteredItemsParent && filteredItemsParent.length > 0) ||
        (filteredItemsChild && filteredItemsChild.length > 0)
      ) {
        // Calculate the cost for an array
        function calculateCost(array) {
          const cost = {};
          array.forEach((item) => {
            for (const year in item.Days) {
              if (!cost[year]) {
                cost[year] = {};
              }
              for (const month in item.Days[year]) {
                cost[year][month] =
                  Number(cost[year][month] || 0) +
                  Number(item.Days[year][month]) * Number(item.Price);
              }
            }
          });
          return cost;
        }
        const parentCost = calculateCost(filteredItemsParent);
        let parent = {
          contract_name:
            filteredItemsParent &&
            filteredItemsParent.length > 0 &&
            filteredItemsParent[0].contract_name,
          contract_type:
            filteredItemsParent &&
            filteredItemsParent.length > 0 &&
            filteredItemsParent[0].contract_type,
          contract_id:
            filteredItemsParent &&
            filteredItemsParent.length > 0 &&
            filteredItemsParent[0].contract_id,
          parent_cid:
            filteredItemsParent &&
            filteredItemsParent.length > 0 &&
            filteredItemsParent[0].parent_cid,
          cost: parentCost,
        };

        function calculateChildCost(child) {
          const childMap = {};
          child.forEach((item) => {
            const contractId = item.contract_id;
            if (!childMap[contractId]) {
              childMap[contractId] = {
                contract_name: item.contract_name,
                contract_type: item.contract_type,
                contract_id: contractId,
                parent_cid: item.parent_cid,
                cost: {},
              };
            }

            for (const year in item.Days) {
              if (!childMap[contractId].cost[year]) {
                childMap[contractId].cost[year] = {};
              }
              for (const month in item.Days[year]) {
                const costValue =
                  Number(item.Days[year][month]) * Number(item.Price);
                if (!childMap[contractId].cost[year][month]) {
                  childMap[contractId].cost[year][month] = costValue;
                } else {
                  childMap[contractId].cost[year][month] += costValue;
                }
              }
            }
          });
          return Object.values(childMap);
        }

        const childCost = calculateChildCost(filteredItemsChild);
        let subContractCost = [];

        for (let i = childCost.length - 1; i >= 0; i--) {
          const child = childCost[i];
          if (
            child.contract_type !== "CR" &&
            child.contract_type !== "CCN" &&
            child.contract_type !== "SOW" &&
            child.contract_type !== "Supplement" &&
            child.contract_type !== "Others" &&
            parent.contract_id === child.parent_cid
          ) {
            subContractCost.push(child);
            childCost.splice(i, 1);
          }

          if (
            child.contract_type === "CR" &&
            child.contract_type === "CCN" &&
            child.contract_type === "SOW" &&
            child.contract_type === "Supplement" &&
            child.contract_type === "Others" &&
            parent.contract_id !== child.parent_cid
          ) {
            subContractCost.push(child);
            childCost.splice(i, 1);
          }
        }

        let subContractTotal = 0;
        // Loop through each contract object
        subContractCost.forEach((contract) => {
          // Access the 'cost' object within each contract object
          const costObject = contract.cost;
          // Loop through the years in the cost object
          Object.keys(costObject).forEach((year) => {
            // Loop through the months in each year
            Object.keys(costObject[year]).forEach((month) => {
              // Add the cost value to the total cost
              subContractTotal += costObject[year][month];
            });
          });
        });

        function sumCostByYear(contracts) {
          let sumByYear = {};
          // Loop through each contract object
          contracts.forEach((contract) => {
            // Access the 'cost' object within each contract object
            const costObject = contract.cost;
            // Loop through the years in the cost object
            Object.keys(costObject).forEach((year) => {
              // Check if the year key exists in the sumByYear object, if not initialize it with 0
              if (!sumByYear[year]) {
                sumByYear[year] = 0;
              }

              // Loop through the months in each year and add the cost to the respective year
              Object.keys(costObject[year]).forEach((month) => {
                sumByYear[year] += costObject[year][month];
              });
            });
          });
          return sumByYear;
        }
        // Get the sum of costs by year
        const totalCostByYear = sumCostByYear(subContractCost);
        setSubcostByYear(totalCostByYear);

        const mergedCost = [parent, ...childCost];

        function buildContractTree(contracts, parentId) {
          const tree = [];

          contracts.forEach((contract) => {
            if (contract.parent_cid === parentId) {
              const children = buildContractTree(
                contracts,
                contract.contract_id
              );
              if (children.length) {
                contract.children = children;
              }
              tree.push(contract);
            }
          });

          return tree;
        }
        const treeResult = buildContractTree(mergedCost, parent.parent_cid); // Start with the top-level parent ID

        function calculateTotalSums(node) {
          if (!node) return null;

          const mainData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            original_value: 0,
            cr_value: 0,
            net_value: 0,
          };

          const monthData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            total: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          };

          if (node.cost) {
            for (const year in node.cost) {
              for (const month in node.cost[year]) {
                monthData.total += node.cost[year][month];
                monthData[month] += node.cost[year][month];
              }
            }
          }

          if (node.children) {
            mainData.cr_value = 0;
            monthData.cr_value = 0;
            mainData.children = [];
            monthData.children = [];

            for (const child of node.children) {
              const childTotalSums = calculateTotalSums(child);
              mainData.cr_value += childTotalSums.mainData.net_value;
              monthData.cr_value += childTotalSums.monthData.total;

              mainData.children.push(childTotalSums.mainData);
              monthData.children.push(childTotalSums.monthData);
            }
          }

          mainData.original_value = monthData.total;
          mainData.net_value =
            mainData.original_value + mainData.cr_value + subContractTotal;

          return { mainData, monthData };
        }

        function calculateYearlySums(node, year) {
          if (!node) return null;

          const yearlyData = {
            mainData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              original_value: 0,
              cr_value: 0,
              net_value: 0,
            },
            monthData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              total: 0,
              jan: 0,
              feb: 0,
              mar: 0,
              apr: 0,
              may: 0,
              jun: 0,
              jul: 0,
              aug: 0,
              sep: 0,
              oct: 0,
              nov: 0,
              dec: 0,
            },
          };

          if (node.cost && node.cost[year]) {
            for (const month in node.cost[year]) {
              yearlyData.monthData.total += node.cost[year][month];
              yearlyData.monthData[month] += node.cost[year][month];
            }
          }

          if (node.children) {
            yearlyData.mainData.cr_value = 0;
            yearlyData.monthData.cr_value = 0;
            yearlyData.mainData.children = [];
            yearlyData.monthData.children = [];

            for (const child of node.children) {
              const childYearlySums = calculateYearlySums(child, year);
              yearlyData.mainData.cr_value +=
                childYearlySums.mainData.net_value;
              yearlyData.monthData.cr_value += childYearlySums.monthData.total;

              yearlyData.mainData.children.push(childYearlySums.mainData);
              yearlyData.monthData.children.push(childYearlySums.monthData);
            }
          }

          yearlyData.mainData.original_value = yearlyData.monthData.total;
          yearlyData.mainData.net_value =
            yearlyData.mainData.original_value + yearlyData.mainData.cr_value;

          return yearlyData;
        }

        // Calculate the total sums and yearly sums for the entire tree
        const totalSum = calculateTotalSums(treeResult[0]);
        const yearlySum = {};

        for (let i = 0; i <= years.length; i++) {
          if (years[i] !== undefined) {
            yearlySum[years[i]] = calculateYearlySums(treeResult[0], years[i]);
          }
        }

        function replaceZerosWithDash(obj) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === "object") {
                replaceZerosWithDash(obj[key]);
              } else if (obj[key] === 0) {
                obj[key] = "-";
              }
            }
          }
        }
        replaceZerosWithDash(totalSum);
        replaceZerosWithDash(yearlySum);

        // Calculate the days for an array
        function calculateDays(array) {
          const days = {};
          array.forEach((item) => {
            for (const year in item.Days) {
              if (!days[year]) {
                days[year] = {};
              }
              for (const month in item.Days[year]) {
                days[year][month] = parseFloat(
                  Number(
                    Number(days[year][month] || 0) +
                      Number(item.Days[year][month])
                  ).toFixed(2)
                );
              }
            }
          });
          return days;
        }
        const parentDays = calculateDays(filteredItemsParent);
        let parDays = {
          contract_name: filteredItemsParent[0].contract_name,
          contract_type: filteredItemsParent[0].contract_type,
          contract_id: filteredItemsParent[0].contract_id,
          parent_cid: filteredItemsParent[0].parent_cid,
          days: parentDays,
        };

        function calculateChildDays(child) {
          const childMapDays = {};
          child.forEach((item) => {
            const contractId = item.contract_id;
            if (!childMapDays[contractId]) {
              childMapDays[contractId] = {
                contract_name: item.contract_name,
                contract_type: item.contract_type,
                contract_id: contractId,
                parent_cid: item.parent_cid,
                days: {},
              };
            }

            for (const year in item.Days) {
              if (!childMapDays[contractId].days[year]) {
                childMapDays[contractId].days[year] = {};
              }
              for (const month in item.Days[year]) {
                const daysValue = item.Days[year][month];
                if (!childMapDays[contractId].days[year][month]) {
                  childMapDays[contractId].days[year][month] = parseFloat(
                    Number(daysValue).toFixed(2)
                  );
                } else {
                  childMapDays[contractId].days[year][month] += parseFloat(
                    Number(daysValue).toFixed(2)
                  );
                }
              }
            }
          });
          return Object.values(childMapDays);
        }

        const childDays = calculateChildDays(filteredItemsChild);
        let subContractDays = [];

        for (let i = childDays.length - 1; i >= 0; i--) {
          const child = childDays[i];

          if (
            ((child.contract_type !== "CR" ||
              child.contract_type !== "CCN" ||
              child.contract_type !== "SOW" ||
              child.contract_type !== "Supplement" ||
              child.contract_type !== "Others") &&
              parDays.contract_id === child.parent_cid) ||
            ((child.contract_type === "CR" ||
              child.contract_type === "CCN" ||
              child.contract_type === "SOW" ||
              child.contract_type === "Supplement" ||
              child.contract_type === "Others") &&
              parDays.contract_id !== child.parent_cid)
          ) {
            subContractDays.push(child);
            // childDays.splice(i, 1);
          }
        }

        let subContractTotalDays = 0;
        // Loop through each contract object
        subContractDays.forEach((contract) => {
          // Access the 'days' object within each contract object
          const costObject = contract.days;
          // Loop through the years in the days object
          Object.keys(costObject).forEach((year) => {
            // Loop through the months in each year
            Object.keys(costObject[year]).forEach((month) => {
              // Add the days value to the total days
              subContractTotalDays += parseFloat(
                Number(costObject[year][month]).toFixed(2)
              );
            });
          });
        });

        function sumDaysByYear(contracts) {
          let sumByYear = {};
          // Loop through each contract object
          contracts.forEach((contract) => {
            // Access the 'days' object within each contract object
            const costObject = contract.days;
            // Loop through the years in the days object
            Object.keys(costObject).forEach((year) => {
              // Check if the year key exists in the sumByYear object, if not initialize it with 0
              if (!sumByYear[year]) {
                sumByYear[year] = 0;
              }

              // Loop through the months in each year and add the days to the respective year
              Object.keys(costObject[year]).forEach((month) => {
                sumByYear[year] += Number(
                  parseFloat(Number(costObject[year][month]).toFixed(2))
                );
              });
            });
          });
          return sumByYear;
        }
        // Get the sum of costs by year
        const totalDaysByYear = sumDaysByYear(subContractDays);
        setSubdaysByYear(totalDaysByYear);

        const mergedDays = [parDays, ...childDays];

        function buildContractTreeDays(contracts, parentId) {
          const treeDays = [];

          contracts.forEach((contract) => {
            if (contract.parent_cid === parentId) {
              const children = buildContractTreeDays(
                contracts,
                contract.contract_id
              );
              if (children.length) {
                contract.children = children;
              }
              treeDays.push(contract);
            }
          });

          return treeDays;
        }
        const treeResultDays = buildContractTreeDays(
          mergedDays,
          parent.parent_cid
        ); // Start with the top-level parent ID

        function calculateTotalSumsDays(node) {
          if (!node) return null;

          const mainData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            original_value: 0,
            cr_value: 0,
            net_value: 0,
          };

          const monthData = {
            contract: `${node.contract_name} (${node.contract_type})`,
            total: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          };

          if (node.days) {
            for (const year in node.days) {
              for (const month in node.days[year]) {
                monthData.total += parseFloat(
                  Number(node.days[year][month]).toFixed(2)
                );
                monthData[month] += parseFloat(
                  Number(node.days[year][month]).toFixed(2)
                );
              }
            }
          }

          if (node.children) {
            mainData.cr_value = 0;
            monthData.cr_value = 0;
            mainData.children = [];
            monthData.children = [];

            for (const child of node.children) {
              const childTotalSumsDays = calculateTotalSumsDays(child);
              mainData.cr_value += parseFloat(
                Number(childTotalSumsDays.mainData.net_value).toFixed(2)
              );
              monthData.cr_value += parseFloat(
                Number(childTotalSumsDays.monthData.total).toFixed(2)
              );

              mainData.children.push(childTotalSumsDays.mainData);
              monthData.children.push(childTotalSumsDays.monthData);
            }
          }

          mainData.original_value = monthData.total;
          mainData.net_value = parseFloat(
            Number(
              Number(mainData.original_value) +
                Number(mainData.cr_value) +
                Number(subContractTotalDays)
            ).toFixed(2)
          );

          return { mainData, monthData };
        }

        function calculateYearlySumsDays(node, year) {
          if (!node) return null;

          const yearlyData = {
            mainData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              original_value: 0,
              cr_value: 0,
              net_value: 0,
            },
            monthData: {
              contract: `${node.contract_name} (${node.contract_type})`,
              total: 0,
              jan: 0,
              feb: 0,
              mar: 0,
              apr: 0,
              may: 0,
              jun: 0,
              jul: 0,
              aug: 0,
              sep: 0,
              oct: 0,
              nov: 0,
              dec: 0,
            },
          };

          if (node.days && node.days[year]) {
            for (const month in node.days[year]) {
              yearlyData.monthData.total += parseFloat(
                Number(node.days[year][month]).toFixed(2)
              );
              yearlyData.monthData[month] += parseFloat(
                Number(node.days[year][month]).toFixed(2)
              );
            }
          }

          if (node.children) {
            yearlyData.mainData.cr_value = 0;
            yearlyData.monthData.cr_value = 0;
            yearlyData.mainData.children = [];
            yearlyData.monthData.children = [];

            for (const child of node.children) {
              const childYearlySums = calculateYearlySumsDays(child, year);
              yearlyData.mainData.cr_value += parseFloat(
                Number(childYearlySums.mainData.net_value).toFixed(2)
              );
              yearlyData.monthData.cr_value += parseFloat(
                Number(childYearlySums.monthData.total).toFixed(2)
              );

              yearlyData.mainData.children.push(childYearlySums.mainData);
              yearlyData.monthData.children.push(childYearlySums.monthData);
            }
          }

          yearlyData.mainData.original_value = parseFloat(
            Number(yearlyData.monthData.total).toFixed(2)
          );
          yearlyData.mainData.net_value = parseFloat(
            Number(
              Number(yearlyData.mainData.original_value) +
                Number(yearlyData.mainData.cr_value)
            ).toFixed(2)
          );

          return yearlyData;
        }

        // Calculate the total sums and yearly sums for the entire tree
        const totalSumDays = calculateTotalSumsDays(treeResultDays[0]);
        const yearlySumDays = {};

        for (let i = 0; i <= years.length; i++) {
          if (years[i] !== undefined) {
            yearlySumDays[years[i]] = calculateYearlySumsDays(
              treeResultDays[0],
              years[i]
            );
          }
        }
        replaceZerosWithDash(totalSumDays);
        replaceZerosWithDash(yearlySumDays);
        setYearlySumDays(yearlySumDays);
        setTotalSumDays(totalSumDays);
        setYearlySumCost(yearlySum);
        setTotalSumCost(totalSum);
      }
    }
  }, []);
  // Funtion to handle after calculate the cost per day, days per year , then render the table
  useEffect(() => {
    let mainData = {};
    let monthData = {};
    if (!checked) {
      if (
        contractData.mode === "view" ||
        contractData.mode === "edit-initial"
      ) {
        if (selectedYear === "") {
          mainData = totalSumCost.mainData;
          monthData = totalSumCost.monthData;
        } else {
          monthData =
            yearlySumCost[selectedYear] &&
            yearlySumCost[selectedYear].monthData;
          mainData = {
            contract:
              yearlySumCost[selectedYear] &&
              yearlySumCost[selectedYear].mainData.contract,
            original_value:
              yearlySumCost[selectedYear] &&
              yearlySumCost[selectedYear].mainData.original_value,
            cr_value:
              yearlySumCost[selectedYear] &&
              yearlySumCost[selectedYear].mainData.cr_value,
            net_value:
              (yearlySumCost[selectedYear] &&
              yearlySumCost[selectedYear].mainData.net_value !== "-"
                ? yearlySumCost[selectedYear] &&
                  yearlySumCost[selectedYear].mainData.net_value
                : 0) +
              (subcostByYear && subcostByYear[selectedYear]
                ? subcostByYear[selectedYear]
                : 0),
          };
        }
      } else {
        if (
          contractData &&
          (contractData.contractType === "CR" ||
            contractData.contractType === "CCN")
        ) {
          if (selectedYear === "") {
            mainData = totalSumCost.mainData;
            monthData = totalSumCost.monthData;
          } else {
            mainData =
              yearlySumCost[selectedYear] &&
              yearlySumCost[selectedYear].mainData;
            monthData =
              yearlySumCost[selectedYear] &&
              yearlySumCost[selectedYear].monthData;
          }
        } else {
          if (selectedYear === "") {
            mainData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              original_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(totalSumCost || {}).length !== 0
                    ? parseFloat(totalSumCost.total.toFixed(2))
                    : "-"
                  : Object.keys(totalSumCost || {}).length !== 0
                  ? parseFloat(totalSumCost.parentSum.toFixed(2))
                  : "-",
              cr_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? "-"
                  : Object.keys(totalSumCost || {}).length !== 0
                  ? parseFloat(totalSumCost.childSum.toFixed(2))
                  : "-",
              net_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(totalSumCost || {}).length !== 0
                    ? parseFloat(totalSumCost.total.toFixed(2))
                    : "-"
                  : Object.keys(totalSumCost || {}).length !== 0
                  ? parseFloat(
                      (totalSumCost.parentSum + totalSumCost.childSum).toFixed(
                        2
                      )
                    )
                  : "-",
            };
            monthData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              total:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(totalSumCost || {}).length !== 0
                    ? parseFloat(totalSumCost.total.toFixed(2))
                    : "-"
                  : Object.keys(totalSumCost || {}).length !== 0
                  ? parseFloat(
                      (totalSumCost.parentSum + totalSumCost.childSum).toFixed(
                        2
                      )
                    )
                  : "-",
              jan:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["jan"]
                  ? parseFloat(totalSumCost["jan"].toFixed(2))
                  : "-",
              feb:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["feb"]
                  ? parseFloat(totalSumCost["feb"].toFixed(2))
                  : "-",
              mar:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["mar"]
                  ? parseFloat(totalSumCost["mar"].toFixed(2))
                  : "-",
              apr:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["apr"]
                  ? parseFloat(totalSumCost["apr"].toFixed(2))
                  : "-",
              may:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["may"]
                  ? parseFloat(totalSumCost["may"].toFixed(2))
                  : "-",
              jun:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["jun"]
                  ? parseFloat(totalSumCost["jun"].toFixed(2))
                  : "-",
              jul:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["jul"]
                  ? parseFloat(totalSumCost["jul"].toFixed(2))
                  : "-",
              aug:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["aug"]
                  ? parseFloat(totalSumCost["aug"].toFixed(2))
                  : "-",
              sep:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["sep"]
                  ? parseFloat(totalSumCost["sep"].toFixed(2))
                  : "-",
              oct:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["oct"]
                  ? parseFloat(totalSumCost["oct"].toFixed(2))
                  : "-",
              nov:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["nov"]
                  ? parseFloat(totalSumCost["nov"].toFixed(2))
                  : "-",
              dec:
                Object.keys(totalSumCost || {}).length !== 0 &&
                totalSumCost["dec"]
                  ? parseFloat(totalSumCost["dec"].toFixed(2))
                  : "-",
            };
          } else {
            mainData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              original_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                    ? parseFloat(yearlySumCost[selectedYear].total.toFixed(2))
                    : "-"
                  : Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                  ? parseFloat(yearlySumCost[selectedYear].parentSum.toFixed(2))
                  : "-",
              cr_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? "-"
                  : Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                  ? parseFloat(yearlySumCost[selectedYear].childSum.toFixed(2))
                  : "-",
              net_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                    ? parseFloat(yearlySumCost[selectedYear].total.toFixed(2))
                    : "-"
                  : Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                  ? parseFloat(
                      (
                        yearlySumCost[selectedYear].parentSum +
                        yearlySumCost[selectedYear].childSum
                      ).toFixed(2)
                    )
                  : "-",
            };
            monthData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              total:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                    ? parseFloat(yearlySumCost[selectedYear].total.toFixed(2))
                    : "-"
                  : Object.keys(yearlySumCost || {}).length !== 0 &&
                    yearlySumCost[selectedYear]
                  ? parseFloat(
                      (
                        yearlySumCost[selectedYear].parentSum +
                        yearlySumCost[selectedYear].childSum
                      ).toFixed(2)
                    )
                  : "-",
              jan:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["jan"]
                  ? parseFloat(yearlySumCost[selectedYear]["jan"].toFixed(2))
                  : "-",
              feb:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["feb"]
                  ? parseFloat(yearlySumCost[selectedYear]["feb"].toFixed(2))
                  : "-",
              mar:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["mar"]
                  ? parseFloat(yearlySumCost[selectedYear]["mar"].toFixed(2))
                  : "-",
              apr:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["apr"]
                  ? parseFloat(yearlySumCost[selectedYear]["apr"].toFixed(2))
                  : "-",
              may:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["may"]
                  ? parseFloat(yearlySumCost[selectedYear]["may"].toFixed(2))
                  : "-",
              jun:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["jun"]
                  ? parseFloat(yearlySumCost[selectedYear]["jun"].toFixed(2))
                  : "-",
              jul:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["jul"]
                  ? parseFloat(yearlySumCost[selectedYear]["jul"].toFixed(2))
                  : "-",
              aug:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["aug"]
                  ? parseFloat(yearlySumCost[selectedYear]["aug"].toFixed(2))
                  : "-",
              sep:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["sep"]
                  ? parseFloat(yearlySumCost[selectedYear]["sep"].toFixed(2))
                  : "-",
              oct:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["oct"]
                  ? parseFloat(yearlySumCost[selectedYear]["oct"].toFixed(2))
                  : "-",
              nov:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["nov"]
                  ? parseFloat(yearlySumCost[selectedYear]["nov"].toFixed(2))
                  : "-",
              dec:
                Object.keys(yearlySumCost || {}).length !== 0 &&
                yearlySumCost[selectedYear] &&
                yearlySumCost[selectedYear]["dec"]
                  ? parseFloat(yearlySumCost[selectedYear]["dec"].toFixed(2))
                  : "-",
            };
          }
        }
      }
    } else {
      if (
        contractData.mode === "view" ||
        contractData.mode === "edit-initial"
      ) {
        if (selectedYear === "") {
          mainData = totalSumDays.mainData;
          monthData = totalSumDays.monthData;
        } else {
          mainData = {
            contract:
              yearlySumDays[selectedYear] &&
              yearlySumDays[selectedYear].mainData.contract,
            original_value:
              yearlySumDays[selectedYear] &&
              yearlySumDays[selectedYear].mainData.original_value,
            cr_value:
              yearlySumDays[selectedYear] &&
              yearlySumDays[selectedYear].mainData.cr_value,
            net_value:
              (yearlySumDays[selectedYear] &&
              yearlySumDays[selectedYear].mainData.net_value !== "-"
                ? yearlySumDays[selectedYear] &&
                  yearlySumDays[selectedYear].mainData.net_value
                : 0) +
              (subdaysByYear && subdaysByYear[selectedYear]
                ? subdaysByYear[selectedYear]
                : 0),
          };
          monthData =
            yearlySumDays[selectedYear] &&
            yearlySumDays[selectedYear].monthData;
        }
      } else {
        if (
          contractData &&
          (contractData.contractType === "CR" ||
            contractData.contractType === "CCN")
        ) {
          if (selectedYear === "") {
            mainData = totalSumDays.mainData;
            monthData = totalSumDays.monthData;
          } else {
            mainData =
              yearlySumDays[selectedYear] &&
              yearlySumDays[selectedYear].mainData;
            monthData =
              yearlySumDays[selectedYear] &&
              yearlySumDays[selectedYear].monthData;
          }
        } else {
          if (selectedYear === "") {
            mainData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              original_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(totalSumDays || {}).length !== 0
                    ? parseFloat(totalSumDays.total.toFixed(2))
                    : "-"
                  : Object.keys(totalSumDays || {}).length !== 0
                  ? parseFloat(totalSumDays.parentSum.toFixed(2))
                  : "-",
              cr_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? "-"
                  : Object.keys(totalSumDays || {}).length !== 0
                  ? parseFloat(totalSumDays.childSum.toFixed(2))
                  : "-",
              net_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(totalSumDays || {}).length !== 0
                    ? parseFloat(totalSumDays.total.toFixed(2))
                    : "-"
                  : Object.keys(totalSumDays || {}).length !== 0
                  ? parseFloat(
                      (totalSumDays.parentSum + totalSumDays.childSum).toFixed(
                        2
                      )
                    )
                  : "-",
            };
            monthData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              total:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(totalSumDays || {}).length !== 0
                    ? parseFloat(totalSumDays.total.toFixed(2))
                    : "-"
                  : Object.keys(totalSumDays || {}).length !== 0
                  ? parseFloat(
                      (totalSumDays.parentSum + totalSumDays.childSum).toFixed(
                        2
                      )
                    )
                  : "-",
              jan:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["jan"]
                  ? parseFloat(totalSumDays["jan"].toFixed(2))
                  : "-",
              feb:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["feb"]
                  ? parseFloat(totalSumDays["feb"].toFixed(2))
                  : "-",
              mar:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["mar"]
                  ? parseFloat(totalSumDays["mar"].toFixed(2))
                  : "-",
              apr:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["apr"]
                  ? parseFloat(totalSumDays["apr"].toFixed(2))
                  : "-",
              may:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["may"]
                  ? parseFloat(totalSumDays["may"].toFixed(2))
                  : "-",
              jun:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["jun"]
                  ? parseFloat(totalSumDays["jun"].toFixed(2))
                  : "-",
              jul:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["jul"]
                  ? parseFloat(totalSumDays["jul"].toFixed(2))
                  : "-",
              aug:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["aug"]
                  ? parseFloat(totalSumDays["aug"].toFixed(2))
                  : "-",
              sep:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["sep"]
                  ? parseFloat(totalSumDays["sep"].toFixed(2))
                  : "-",
              oct:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["oct"]
                  ? parseFloat(totalSumDays["oct"].toFixed(2))
                  : "-",
              nov:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["nov"]
                  ? parseFloat(totalSumDays["nov"].toFixed(2))
                  : "-",
              dec:
                Object.keys(totalSumDays || {}).length !== 0 &&
                totalSumDays["dec"]
                  ? parseFloat(totalSumDays["dec"].toFixed(2))
                  : "-",
            };
          } else {
            mainData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              original_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                    ? parseFloat(yearlySumDays[selectedYear].total.toFixed(2))
                    : "-"
                  : Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                  ? parseFloat(yearlySumDays[selectedYear].parentSum.toFixed(2))
                  : "-",
              cr_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? "-"
                  : Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                  ? parseFloat(yearlySumDays[selectedYear].childSum.toFixed(2))
                  : "-",
              net_value:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                    ? parseFloat(yearlySumDays[selectedYear].total.toFixed(2))
                    : "-"
                  : Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                  ? parseFloat(
                      (
                        yearlySumDays[selectedYear].parentSum +
                        yearlySumDays[selectedYear].childSum
                      ).toFixed(2)
                    )
                  : "-",
            };
            monthData = {
              contract:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? `${contractData && contractData.contractName} (${
                      contractData &&
                      contractData.contractType !== "Framework Agreement"
                        ? contractData.contractType
                        : "MSA"
                    })`
                  : `${contractData && contractData.contracts.contract_name} (${
                      contractData && contractData.contracts.contract_type
                    })`,
              total:
                contractData.mode === "create" ||
                contractData.mode === "edit-updated"
                  ? Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                    ? parseFloat(yearlySumDays[selectedYear].total.toFixed(2))
                    : "-"
                  : Object.keys(yearlySumDays || {}).length !== 0 &&
                    yearlySumDays[selectedYear]
                  ? parseFloat(
                      (
                        yearlySumDays[selectedYear].parentSum +
                        yearlySumDays[selectedYear].childSum
                      ).toFixed(2)
                    )
                  : "-",
              jan:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["jan"]
                  ? parseFloat(yearlySumDays[selectedYear]["jan"].toFixed(2))
                  : "-",
              feb:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["feb"]
                  ? parseFloat(yearlySumDays[selectedYear]["feb"].toFixed(2))
                  : "-",
              mar:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["mar"]
                  ? parseFloat(yearlySumDays[selectedYear]["mar"].toFixed(2))
                  : "-",
              apr:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["apr"]
                  ? parseFloat(yearlySumDays[selectedYear]["apr"].toFixed(2))
                  : "-",
              may:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["may"]
                  ? parseFloat(yearlySumDays[selectedYear]["may"].toFixed(2))
                  : "-",
              jun:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["jun"]
                  ? parseFloat(yearlySumDays[selectedYear]["jun"].toFixed(2))
                  : "-",
              jul:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["jul"]
                  ? parseFloat(yearlySumDays[selectedYear]["jul"].toFixed(2))
                  : "-",
              aug:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["aug"]
                  ? parseFloat(yearlySumDays[selectedYear]["aug"].toFixed(2))
                  : "-",
              sep:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["sep"]
                  ? parseFloat(yearlySumDays[selectedYear]["sep"].toFixed(2))
                  : "-",
              oct:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["oct"]
                  ? parseFloat(yearlySumDays[selectedYear]["oct"].toFixed(2))
                  : "-",
              nov:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["nov"]
                  ? parseFloat(yearlySumDays[selectedYear]["nov"].toFixed(2))
                  : "-",
              dec:
                Object.keys(yearlySumDays || {}).length !== 0 &&
                yearlySumDays[selectedYear] &&
                yearlySumDays[selectedYear]["dec"]
                  ? parseFloat(yearlySumDays[selectedYear]["dec"].toFixed(2))
                  : "-",
            };
          }
        }
      }
    }

    setMainTableData([mainData]);
    setSubTableData([monthData]);
  }, [
    totalSumCost,
    yearlySumCost,
    selectedYear,
    checked,
    yearlySumDays,
    totalSumDays,
  ]);
  // Variable to  hold main table column name and key
  const mainTableCol = [
    {
      title: "Contract",
      dataIndex: "contract",
      key: "contract",
      ellipsis: true,
    },
    {
      title: "Original Value",
      dataIndex: "original_value",
      key: "original_value",
      ellipsis: true,
    },
    {
      title: "CR Value",
      dataIndex: "cr_value",
      key: "cr_value",
      ellipsis: true,
    },
    {
      title: (
        <Row type="flex" align="middle">
          <Col className="vertical-align" span={12}>
            Net Value
          </Col>
          <Col className="vertical-align " span={12}>
            <Button
              className="table-switch-btn float-right"
              onClick={() => setSwitchTable(!switchTable)}
              icon={
                switchTable ? (
                  <MinusOutlined style={{ color: "#fff" }} />
                ) : (
                  <PlusOutlined style={{ color: "#fff" }} />
                )
              }
            ></Button>
          </Col>
        </Row>
      ),
      dataIndex: "net_value",
      key: "net_value",
    },
  ];
  // Variable to  hold sub table column name and key
  const subTableCol = [
    {
      title: "Contract",
      dataIndex: "contract",
      key: "contract",
      ellipsis: true,
    },
    {
      title: "January",
      dataIndex: "jan",
      key: "jan",
      ellipsis: true,
    },
    {
      title: "February",
      dataIndex: "feb",
      key: "feb",
      ellipsis: true,
    },
    {
      title: "March",
      dataIndex: "mar",
      key: "mar",
      ellipsis: true,
    },
    {
      title: "April",
      dataIndex: "apr",
      key: "apr",
      ellipsis: true,
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
      ellipsis: true,
    },
    {
      title: "June",
      dataIndex: "jun",
      key: "jun",
      ellipsis: true,
    },
    {
      title: "July",
      dataIndex: "jul",
      key: "jul",
      ellipsis: true,
    },
    {
      title: "August",
      dataIndex: "aug",
      key: "aug",
      ellipsis: true,
    },
    {
      title: "September",
      dataIndex: "sep",
      key: "sep",
      ellipsis: true,
    },
    {
      title: "October",
      dataIndex: "oct",
      key: "oct",
      ellipsis: true,
    },
    {
      title: "November",
      dataIndex: "nov",
      key: "nov",
      ellipsis: true,
    },
    {
      title: "December",
      dataIndex: "dec",
      key: "dec",
      ellipsis: true,
    },
    {
      title: (
        <Row type="flex" align="middle">
          <Col className="vertical-align" span={12} align="left">
            Total
          </Col>
          <Col className="vertical-align " span={12}>
            <Button
              className="table-switch-btn float-right"
              onClick={() => setSwitchTable(!switchTable)}
              icon={
                switchTable ? (
                  <MinusOutlined style={{ color: "#fff" }} />
                ) : (
                  <PlusOutlined style={{ color: "#fff" }} />
                )
              }
            ></Button>
          </Col>
        </Row>
      ),
      dataIndex: "total",
      key: "total",
      width: "500",
      ellipsis: true,
    },
  ];

  return (
    <>
      {contractData && !contractData.download ? (
        <Content>
          <Row
            justify="end"
            type="flex"
            align="middle"
            className="m-10"
            gutter={16}
          >
            <Col span={4} className="vertical-align">
              <Row align="middle" type="flex" gutter={[24, 0]} className="m-10">
                <Col className="p-0 vertical-align" span={22}>
                  <span
                    className={`${checked === false ? "fxsetup-status" : ""}`}
                  >
                    Cost
                  </span>
                  &nbsp;&nbsp;
                  <Switch
                    onChange={(value) => setChecked(value)}
                    className="fx-setup-switch"
                  ></Switch>
                  &nbsp;&nbsp;
                  <span
                    className={`${checked === true ? "fxsetup-status" : ""}`}
                  >
                    Days
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={6} className="vertical-align">
              <Row type="flex" align="middle" className="" gutter={16}>
                <Col span={12} className="vertical-align">
                  <div className="fy-cur-div">FY</div>
                </Col>
                <Col span={10} className="vertical-align year-select-col">
                  <Select
                    onChange={(key, value) => {
                      setSelectedYear(key === undefined ? "" : key);
                    }}
                    allowClear
                    defaultValue={yearsList[0]}
                    className="year-select"
                    showSearch={true}
                    placeholder="All"
                    options={
                      yearsList &&
                      yearsList.length !== 0 &&
                      yearsList.map((data) => ({
                        key: data,
                        label: data,
                        value: data,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6} className="vertical-align">
              <Row type="flex" align="middle" className="" gutter={16}>
                <Col span={12} className="vertical-align">
                  <div className="fy-cur-div">Currency</div>
                </Col>
                <Col span={12} className="vertical-align">
                  <div className="currency-div">EUR</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={24} className="mt-10">
              {!switchTable ? (
                <DataTable
                  type="aggregate"
                  dataTableData={mainTableData}
                  colData={mainTableCol}
                />
              ) : (
                <Table
                  scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 265px)" }}
                  columns={subTableCol}
                  dataSource={subTableData}
                  pagination={false}
                  // id="performance-table"
                  // className="system-setup-table aggregate-table mb-20"
                  className="data-table"
                />
              )}
            </Col>
          </Row>
        </Content>
      ) : null}
    </>
  );
}

export default AggregateValue;
