import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import "../../assets/style/contractcontroller.css";
import {
  Row,
  Col,
  Card,
  Layout,
  Tabs,
  Form,
  Input,
  Divider,
  Menu,
  Empty,
  Skeleton,
  Table,
  Image,
  Switch,
  Button,
  Upload,
  Modal,
  message,
  Select,
  DatePicker,
} from "antd";
import {
  LoadingOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import folder_active from "../../assets/images/icons/folder_active.svg";
import folder_inactive from "../../assets/images/icons/folder_inactive.svg";
import adminPanel_active from "../../assets/images/icons/admin_panel_active.png";
import adminPanel_inactive from "../../assets/images/icons/admin_panel_settings.png";
import approval_inactive from "../../assets/images/icons/approval_flow_icon.svg";
import approval_active from "../../assets/images/icons/approval_flow_icon_active.svg";
import contract_doc_inactive from "../../assets/images/icons/contract_doc_icon.svg";
import contract_doc_active from "../../assets/images/icons/contract_doc_icon_active.svg";
import chat from "../../assets/images/icons/chat.svg";
import chat_active from "../../assets/images/icons/chat_active.svg";
import log_active from "../../assets/images/icons/sow_active.svg";
import log_inactive from "../../assets/images/icons/sow_inactive.svg";
import dotPointer from "../../assets/images/icons/dot_pointer.svg";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import UploadButtonIcon from "../../assets/images/icons/upload_icon_blue.svg";
import CompareIcon from "../../assets/images/icons/compare_arrows_blue.svg";
import VersionIcon from "../../assets/images/icons/version_icon.svg";
import approve from "../../assets/images/icons/approve.svg";
import reject from "../../assets/images/icons/reject.svg";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Breadcrumbs from "../Contract/Breadcrumbs";
import CommercialTable from "../Contract/CommercialTable";
import DataTable from "../Tables/DataTable";
import FlowDiagram from "../FlowDiagram";
import NotesAndComments from "../NotesAndComments";
import { validateFileSize } from "../FileUploadUtils";
import {
  editContractAPi,
  authorChangeApi,
  createContractAPi,
  getApproverListFromEmailList,
  getAggregateTableApi,
} from "../../api/contractsApi";
import { getContractById } from "../../api/contractsApi";
import { getSupplierEntityList } from "../../api/suppliersApi";
import { getPricingProfileList } from "../../api/pricingProfileApi";
import { getInvoicingProfileList } from "../../api/invoicingProfileApi";
import { getFxTableList } from "../../api/fxTableApi";
import { deleteS3FileApi, getFileApi } from "../../api/commonApi";
import { onApproveApi, onRejectApi, onReturnApi } from "../../api/workflowApi";
import {
  userDataAtom,
  DocuSignUri,
  HeaderChangerAtom,
} from "../../store/store";
import TopHeader from "../../components/Header";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import { airflowUrl } from "../../utils/request";
import {
  airflowCredential,
  determineContractClass,
  contractConfig,
} from "../../utils/common";
import { isEmptyObject } from "../../utils/common";
import {
  OkButton,
  CancelButton,
  UploadButton,
  CompareButton,
  AuthorChangeButton,
  SaveButton,
  SubmitButton,
  ApproveButton,
  RejectButton,
  ReturnButton,
  LatestContractButton,
} from "../GlobalButton";
import ReactDiffViewer from "react-diff-viewer-continued";
import CompareDocs from "../CompareDocs";
import ConfigFile from "../../ConfigFile";
import { diffLines } from "diff";
import { useRecoilState } from "recoil";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import NewAggregateValue from "./NewAggregateValue";
import { getUsersApi } from "../../api/usersApi";

const ContractController = ({ mode, breadcrumbs, location }) => {
  let { state } = useLocation();
  const navigate = useNavigate();

  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  const menuKey = "2";

  const SECRET_KEY = "mysecretkey";
  const { TextArea } = Input;

  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  mode = mode || state.mode;
  breadcrumbs = breadcrumbs || state.breadcrumbs;
  location = location ? String(location) : state.location;

  const [contractForm] = Form.useForm();
  const innerTabRef = useRef(null);
  const [buttonState, setButtonState] = useState(0);
  const userData = useRecoilState(userDataAtom);
  const popupModalInstance = PopupModal();
  const [contract, setContract] = useState();
  const [contractTabKey, setContractTabKey] = useState("1");
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [commercialMenuState, setCommercialMenuState] = useState("contract");
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  const [rateTableData, setRateTableData] = useState({});
  const rateCardTemplate = useMemo(() => {
    if (isEmptyObject(rateTableData) || !rateTableData.ratecard_items)
      return {
        key: 0,
        "Resource Id": "RE_1",
        "Start Date": undefined,
        "End Date": undefined,
        Days: undefined,
        Cost: undefined,
      };

    return (
      !isEmptyObject(rateTableData) &&
      rateTableData.ratecard_items &&
      rateTableData.ratecard_items.reduce(
        (acc, curr) => {
          Object.keys(curr)
            .filter((column) => column !== "Item Id")
            .forEach((key) => {
              acc[key] = undefined;
            });

          return acc;
        },
        {
          key: 0,
          "Resource Id": "RE_1",
          "Start Date": undefined,
          "End Date": undefined,
          Days: undefined,
          Cost: undefined,
        }
      )
    );
  }, [rateTableData]);
  const commercialType = mode.create
    ? state.data.commercial_options
    : mode.edit || mode.view
    ? contract && contract.commercial_type
    : null;

  const commercialRef = useRef([{ ...rateCardTemplate }]);
  const yearsListRef = useRef({});
  const daysRef = useRef({});
  const costsRef = useRef({});
  const currencyToggleRef = useRef("contract");
  const negatedResourceRef = useRef({});
  const contractStartDateRef = useRef({});
  const contractEndDateRef = useRef({});
  const viewerRef = useRef(null);
  const [viewedFile, setViewedFile] = useState(false);
  const [uploadForm] = Form.useForm();
  const [contractDocData, setContractDocData] = useState([]);
  const contractDocDataRef = useRef(contractDocData);
  const [contractDocFileList, setContractDocFileList] = useState([]);
  const [disableUpload, setDisableUpload] = useState(true);
  const [newDocFileUpload, setNewDocFileUpload] = useState([]);
  const [selectedRows, setSelectedRows] = useState(0);
  const [compareModal, setCompareModal] = useState(false);
  const [uploadContractDoc, setUploadContractDoc] = useState(false);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [splitView, setSplitView] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [highlightLines, setHighlightLines] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [changedLines, setChangedLines] = useState([]);
  const [contractFile, setContractFile] = useState([]);
  const [comments, setComments] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [authorSwitch, setAuthorSwitch] = useState(false);
  const [authorSwitchLoad, setAuthorSwitchLoad] = useState(false);
  const [supplierEntityList, setSupplierEntityList] = useState([]);
  const [pricingProfileList, setPricingProfileList] = useState([]);
  const [invoicingProfileList, setInvoicingProfileList] = useState([]);
  const [fxTableList, setFxTableList] = useState([]);
  const [fxCurrencyList, setFxCurrencyList] = useState([]);
  const [contractPricing, setContractPricing] = useState([]);
  const [contractCurrency, setContractCurrency] = useState([]);
  const [formLoader, setFormLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [contractApprovalLoader, setContractApprovalLoader] = useState(false);
  const [createdId, setCreatedId] = useState("");
  const [getUser, setGetUser] = useState();
  const [checkedSd, setCheckedSd] = useState(false);
  const [checkedEd, setCheckedEd] = useState(false);
  const [rootData, setRootData] = useState({
    rootId: "",
    rootName: "",
    startDate: "",
    endDate: "",
  });
  const [isApprover, setIsApprover] = useState(false);
  const [approverID, setApproverID] = useState();
  const [rejectModel, setRejectModel] = useState(false);
  const [returnModel, setReturnModel] = useState(false);
  const [approveModel, setApproveModel] = useState(false);
  const [dagID, setDagID] = useState();
  const [aggregateTableData, setAggregateTableData] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [authorForm] = Form.useForm();
  const [docButtonView, setDocButtonView] = useState(false);

  const initializeContractForm = async () => {
    setFormLoader(true);
    try {
      if (mode.view || mode.edit) {
        const contract_id =
          breadcrumbs &&
          breadcrumbs.length >= 1 &&
          breadcrumbs[breadcrumbs.length - 1].apiArgs.contract_id;

        const contractClass = determineContractClass(
          breadcrumbs &&
            breadcrumbs.length >= 2 &&
            breadcrumbs[breadcrumbs.length - 1].apiArgs.type,
          breadcrumbs && breadcrumbs.length >= 2 && breadcrumbs[2].apiArgs.type,
          contractConfig
        );

        console.log("contractClass: ", contractClass);

        const contractInfo = await getContractById(
          location === "pipeline" ? state.contract_id : contract_id,
          contractClass
        );

        if (contractInfo.error) {
          throw new Error(
            "The requested Contract is unavailable or access is restricted."
          );
        }

        if (
          mode.create
            ? state.data.contract_class === "amendment"
            : mode.edit || mode.view
        ) {
          const aggregateTableRes = await getAggregateTableApi(
            breadcrumbs &&
              breadcrumbs.length >= 2 &&
              breadcrumbs[2].apiArgs.contract_id
          );

          setAggregateTableData(aggregateTableRes || []);
        }

        console.log("contractInfo:", contractInfo);
        setContract(contractInfo);
        setRateTableData(contractInfo.ratetable_data);
        if (contractInfo.fx_table_data) {
          setFxCurrencyList(contractInfo.fx_table_data.fx_profile);
        }

        if (mode.edit) {
          const [profiles, invoicing, fxTables] = await Promise.all([
            getPricingProfileList(contractInfo.supplier_data._id),
            getInvoicingProfileList(contractInfo.supplier_data._id),
            getFxTableList(contractInfo.supplier_data._id),
          ]);

          setSupplierEntityList(contractInfo.supplier_data.supplier_entities);
          setPricingProfileList(profiles);
          setInvoicingProfileList(invoicing);
          setFxTableList(fxTables);

          setContractPricing(
            contractInfo.pricing_profile_data?.contract_pricing || []
          );
          setContractCurrency(
            contractInfo.pricing_profile_data?.contract_cc || []
          );

          getUsersApi().then((res) => {
            setAllUsers(res);
          });
        }

        contractStartDateRef.current = contractInfo.start_date
          ? dayjs(contractInfo.start_date)
          : undefined;
        contractEndDateRef.current = contractInfo.end_date
          ? dayjs(contractInfo.end_date)
          : undefined;

        updateCommercialReferences(
          contractInfo,
          commercialRef,
          daysRef,
          yearsListRef,
          costsRef,
          rateCardTemplate
        );

        // console.log(daysRef.current, costsRef.current);

        setDagID(contractInfo.dag?._id + "_qa");

        contractForm.setFieldsValue({
          contract: {
            ...contractInfo.contract_metadata.contract,
            "Contract Start Date": contractInfo.contract_metadata.contract[
              "Contract Start Date"
            ]
              ? dayjs(
                  contractInfo.contract_metadata.contract["Contract Start Date"]
                )
              : undefined,
            "Contract End Date": contractInfo.contract_metadata.contract[
              "Contract End Date"
            ]
              ? dayjs(
                  contractInfo.contract_metadata.contract["Contract End Date"]
                )
              : undefined,
            "Contract Value":
              commercialType === "table"
                ? getTotalCommercialDaysCosts(
                    costsRef.current,
                    "cost",
                    "table"
                  ).toLocaleString("en-us")
                : formatNumberWithCommas(
                    contractInfo.contract_metadata.contract["Contract Value"]
                  ),
          },
          scope: contractInfo.contract_metadata.scope,
          people: contractInfo.contract_metadata.people,
          financial: {
            ...contractInfo.contract_metadata.financial,
            "Pricing Profile": mode.edit
              ? contractInfo.pricing_profile_data?._id
              : contractInfo.pricing_profile_data?.price_profile_name,
            "Invoicing Profile": mode.edit
              ? contractInfo.invoicing_profile_data?._id
              : contractInfo.invoicing_profile_data?.invoice_profile_name,
            "Fx Profile": mode.edit
              ? contractInfo.fx_table_data?._id
              : contractInfo.fx_table_data?.fx_table_name,
          },
        });

        setContractDocData(() =>
          contractInfo.contract_doc_data
            .map((contractDocData, index) => ({
              key: index,
              version: (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      color: "var(--color-solid-darkerblue)",
                      fontWeight: 600,
                    }}
                  >
                    {contractDocData.version}
                  </span>
                  <Image
                    src={VersionIcon}
                    preview={false}
                    height={20}
                    width={20}
                    style={{ marginTop: -6, marginLeft: 8 }}
                  />
                </div>
              ),
              file_content: contractDocData.file_name_on_s3,
              file_name: contractDocData.file_name,
              last_updated_contract: (
                <SwapRightOutlined className="contract-arrow" />
              ),
              updated_on: (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "var(--color-solid-darkergrey)",
                    }}
                  >
                    Updated On&nbsp;:&nbsp;
                  </span>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "var(--color-solid-darkestblue)",
                    }}
                  >
                    {dayjs(contractDocData.updated_on).format("DD/MM/YYYY")}
                  </span>
                </div>
              ),
              updated_by: (
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "var(--color-solid-darkergrey)",
                    }}
                  >
                    Updated By&nbsp;:&nbsp;
                  </span>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "var(--color-solid-darkestblue)",
                    }}
                  >
                    {contractDocData.updated_by}
                  </span>
                </div>
              ),
              updated_by_id: contractDocData.updated_by_id,
              action: (
                <span
                  style={{
                    fontWeight: 600,
                    color: "var(--color-solid-darkerblue)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    viewFile(contractDocData, "contract_doc", "old");
                    setViewedFile(true);
                  }}
                >
                  View
                </span>
              ),
            }))
            .reverse()
        );

        if (mode.view) {
          const hasEmailId = (item) => {
            return item.emailId.includes(userData[0].email);
          };

          const hasNonEmailIds = (emailArray) => {
            return emailArray.some(
              (email) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())
            );
          };

          Object.values(
            contractInfo.contract_workflow_data.contract_workflow_metadata || {}
          ).some((level) => {
            if (level.status === "Current Approver") {
              level.approverList.every(async (item, key) => {
                console.log("approveItem: ", item);

                if (item.status === "Current Approver") {
                  console.log("is current approver");

                  if (hasEmailId(item)) {
                    setIsApprover(true);
                    level.approvalType !== "OR"
                      ? setApproverID(level.approverId[key])
                      : setApproverID(level.approverId[0]);
                  } else if (hasNonEmailIds(item.emailId)) {
                    getApproverListFromEmailList(item.emailId).then(
                      (approverList) => {
                        approverList.map((user) => {
                          if (user.email === userData[0].email) {
                            setIsApprover(true);
                            level.approvalType !== "OR"
                              ? setApproverID(level.approverId[key])
                              : setApproverID(level.approverId[0]);
                          }
                        });
                      }
                    );
                  }
                }
              });
            }
          });
        }
      } else if (mode.create) {
        contractForm.setFieldValue(["contract", "Title"], state.data.title);
        contractForm.setFieldValue(
          ["contract", "Contract Value"],
          commercialType === "table" ? 0 : undefined
        );

        // Contract Extraction Initialization
        if (state.contractExtractData) {
          contractStartDateRef.current = state.contractExtractData.START_DATE
            ? dayjs(state.contractExtractData.START_DATE, "D MMMM YYYY")
            : undefined;
          contractEndDateRef.current = state.contractExtractData.END_DATE
            ? dayjs(state.contractExtractData.END_DATE, "D MMMM YYYY")
            : undefined;

          contractForm.setFieldsValue({
            contract: {
              "Contract Start Date": contractStartDateRef.current,
              "Contract End Date": contractEndDateRef.current,
            },
            financial: {
              "Pricing Profile": state.data.pricing_profile._id,
            },
          });

          commercialRef.current = state.commercialExtractionData.map(
            (item) => ({
              ...item,
              ["Start Date"]: item["Start Date"]
                ? dayjs(item["Start Date"], "DD/MM/YYYY")
                : undefined,
              ["End Date"]: item["End Date"]
                ? dayjs(item["End Date"], "DD/MM/YYYY")
                : undefined,
            })
          );

          daysRef.current = {
            ...daysRef.current,
            ...state.commercialExtractionData.map(
              (data, index) => (index = data["Days"])
            ),
          };

          costsRef.current = {
            ...daysRef.current,
            ...state.commercialExtractionData.map(
              (data, index) => (index = data["Cost"])
            ),
          };

          yearsListRef.current = initializeYearList(commercialRef.current);

          console.log(commercialRef.current);
          console.log(daysRef.current);
          console.log(costsRef.current);
          console.log(yearsListRef.current);

          setRateTableData(state.data.pricing_profile.ratetable_data);
          setPricingProfileList([state.data.pricing_profile]);
          setContractPricing(state.data.pricing_profile.contract_pricing);
          setContractCurrency(state.data.pricing_profile.contract_cc);

          setRootData({
            ...rootData,
            startDate: contractStartDateRef.current,
            endDate: contractEndDateRef.current,
          });
          return;
        }

        // Contract Amendment Form Initialization
        if (
          state.data.parentData &&
          state.data.parentData.ratetable_data &&
          state.data.parentData.fx_table_data
        ) {
          setRateTableData(state.data.parentData.ratetable_data);
          updateCommercialReferences(
            state.data.parentData,
            commercialRef,
            daysRef,
            yearsListRef,
            costsRef,
            rateCardTemplate
          );
          setContractPricing(
            state.data.parentData.pricing_profile_data.contract_pricing
          );
          setContractCurrency(
            state.data.parentData.pricing_profile_data.contract_cc
          );
          setFxCurrencyList(state.data.parentData.fx_table_data.fx_profile);

          contractForm.setFieldsValue({
            contract: {
              "Contract Start Date": dayjs(state.data.parentData.start_date),
              "Contract End Date": dayjs(state.data.parentData.end_date),
              "Contract Value": getTotalCommercialDaysCosts(
                costsRef.current,
                "cost",
                "table"
              ).toLocaleString("en-us"),
            },
            financial: {
              "Pricing Profile":
                state.data.parentData.pricing_profile_data?._id,
              "Fx Profile": state.data.parentData.fx_table_data?._id,
            },
          });

          contractStartDateRef.current = state.data.parentData.start_date
            ? dayjs(state.data.parentData.start_date)
            : undefined;
          contractEndDateRef.current = state.data.parentData.end_date
            ? dayjs(state.data.parentData.end_date)
            : undefined;
        }

        // Common create form initializations
        if (
          mode.create
            ? state.data.contract_class === "amendment" ||
              state.data.contract_class === "secondary"
            : (contract && contract.contract_class === "amendment") ||
              (contract && contract.contract_class === "secondary")
        ) {
          const aggregateTableRes = await getAggregateTableApi(
            breadcrumbs &&
              breadcrumbs.length >= 2 &&
              breadcrumbs[2].apiArgs.contract_id
          );

          setAggregateTableData(aggregateTableRes || {});
        } else {
          setAggregateTableData({});
        }

        const [entities, profiles, invoicing, fxTables] = await Promise.all([
          getSupplierEntityList(breadcrumbs[1].apiArgs.supplier_id),
          getPricingProfileList(breadcrumbs[1].apiArgs.supplier_id),
          getInvoicingProfileList(breadcrumbs[1].apiArgs.supplier_id),
          getFxTableList(breadcrumbs[1].apiArgs.supplier_id),
        ]);

        setSupplierEntityList(entities);
        setPricingProfileList(profiles);
        setInvoicingProfileList(invoicing);
        setFxTableList(fxTables);
      }
    } catch (error) {
      console.error("initializationError: ", error.message);
      setErrorMsg(error.message);
      if (
        error.message !==
        "The requested Contract is unavailable or access is restricted."
      ) {
        popupModalInstance
          .errorCodeModal(ModalMessage.CON_FORM.ERR_Init)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              console.log("Alright");
            }
          });
      }
    } finally {
      setFormLoader(false);
    }
  };

  const handleViewLatestContract = () => {
    viewFile(contractDocData[0], "contract_doc", "old");
    setDocButtonView(true);
  };

  const getInfoPopupContainer = () => {
    return innerTabRef.current;
  };

  const getDatePickerContainer = (trigger) => {
    const rect = trigger.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    const spaceAbove = rect.top;
    const spaceBelow = viewportHeight - rect.bottom;

    return spaceAbove > spaceBelow ? document.body : trigger.parentNode;
  };

  const decryptAndRetrieveUserData = () => {
    const encryptedUserData = localStorage.getItem("loggedInUser");

    if (encryptedUserData) {
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(encryptedUserData, SECRET_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // Use the decrypted data (for example, set it in state)
      setGetUser(decryptedData);
    }
  };

  const initializeYearList = (commercialData) => {
    let initialYearsList = {};

    commercialData &&
      commercialData.length !== 0 &&
      commercialData.map((data) => {
        const startDate = new Date(data["Start Date"]);
        const endDate = new Date(data["End Date"]);
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        let output = {};
        let currentYear = startYear;
        let currentMonth = startDate.getMonth(); // 0 (January) to 11 (December)
        while (
          currentYear < endYear ||
          (currentYear === endYear && currentMonth <= endDate.getMonth())
        ) {
          const monthAbbreviation = dayjs()
            .month(currentMonth)
            .format("MMM")
            .toLowerCase();
          if (!output[currentYear]) {
            output[currentYear] = [];
          }
          output[currentYear].push(monthAbbreviation);
          if (currentMonth === 11) {
            // December, move to next year
            currentMonth = 0;
            currentYear++;
          } else {
            currentMonth++;
          }
        }
        initialYearsList = {
          ...initialYearsList,
          [data["key"]]: output,
        };
      });

    return initialYearsList;
  };

  const updateCommercialReferences = (
    contractInfo,
    commercialRef,
    daysRef,
    yearsListRef,
    costsRef,
    rateCardTemplate
  ) => {
    // Update commercialRef with keys and transformed Start/End Dates
    commercialRef.current =
      contractInfo.commercial_data.length !== 0
        ? contractInfo.commercial_data.map((item, index) => {
            if (item.NegationMap) {
              negatedResourceRef.current = {
                ...negatedResourceRef.current,
                ...item.NegationMap,
              };
            }
            return {
              ...item,
              key: index,
              ["Start Date"]: item["Start Date"]
                ? dayjs(item["Start Date"])
                : undefined,
              ["End Date"]: item["End Date"]
                ? dayjs(item["End Date"])
                : undefined,
            };
          })
        : [{ ...rateCardTemplate }];

    // Initialize years list reference
    yearsListRef.current = initializeYearList(commercialRef.current);

    // Update daysRef with days data from commercialRef
    daysRef.current = {
      ...daysRef.current,
      ...commercialRef.current.map((data, index) => (index = data["Days"])),
    };

    // Calculate costs
    const costObj = {};
    for (const item of commercialRef.current) {
      const key = item["key"];
      const price = item["Price"];
      const days = item["Days"];

      if (!costObj[key]) {
        costObj[key] = {};
      }

      for (const year in days) {
        if (!costObj[key][year]) {
          costObj[key][year] = {};
        }

        for (const month in days[year]) {
          const daysValue =
            days[year][month] !== undefined ? days[year][month] : 0;

          if (!costObj[key][year][month]) {
            costObj[key][year][month] = Number(
              item["Rate Period"] !== "Yearly Rate"
                ? daysValue * price
                : Number.isInteger((daysValue / 12) * price)
                ? (daysValue / 12) * price
                : ((daysValue / 12) * price).toFixed(2)
            );
          } else {
            costObj[key][year][month] += Number(
              item["Rate Period"] !== "Yearly Rate"
                ? daysValue * price
                : Number.isInteger((daysValue / 12) * price)
                ? (daysValue / 12) * price
                : ((daysValue / 12) * price).toFixed(2)
            );
          }
        }
      }
    }

    // Update costsRef
    costsRef.current = { ...costObj };

    // Add calculated costs to each commercial data row
    const updatedCommercialData = commercialRef.current.map((item) => {
      const costData = costObj[item.key];
      return {
        ...item,
        Cost: costData,
      };
    });

    // Update commercialRef with the updated data
    commercialRef.current = updatedCommercialData;
  };

  useEffect(() => {
    decryptAndRetrieveUserData();
    initializeContractForm();

    console.log("state: ", state);
    console.log("mode: ", mode);
    console.log("breadcrumbs: ", breadcrumbs);
    console.log("location: ", location);
  }, [mode]);

  const contractDocColumns = [
    {
      title: "Version",
      dataIndex: "version",
      ellipsis: true,
      width: 200,
    },
    {
      title: "Last Updated Contract",
      dataIndex: "last_updated_contract",
      ellipsis: true,
      width: 170,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      ellipsis: true,
      width: 350,
    },
    {
      title: "Updated On",
      dataIndex: "updated_on",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      ellipsis: true,
      width: 100,
    },
  ];

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const viewFile = async (fileData, view_tab, upload_period) => {
    console.log("fileData", fileData);

    const handleOldUploadPeriod = async () => {
      try {
        const res = await getFileApi({
          fileKey: fileData.file_url
            ? fileData.file_url
            : fileData.file_content,
          flag: "contract",
          file_storage: fileData.file_storage,
        });
        console.log("resss", res);
        setUrl([
          {
            uri: res.url,
            fileType: res.fileType,
            fileName: fileData.file_name,
          },
        ]);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    console.log("docusignuri", DocuSignUri);

    const handleNewUploadPeriod = () => {
      const getDataByViewTab = () => {
        const dataRef =
          view_tab === "contract_doc" && contractDocDataRef.current;

        return dataRef.find((data) =>
          view_tab === "contract_doc"
            ? fileData.file_name === data.file_name
            : fileData.file_name === data.document_name.props.children
        );
      };

      const clickedFile = getDataByViewTab();
      if (clickedFile && clickedFile.file_content) {
        const fileUrl = URL.createObjectURL(clickedFile.file_content);
        const fileName =
          view_tab === "contract_doc"
            ? clickedFile.file_name
            : clickedFile.document_name.props.children;
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);

        setUrl([
          {
            uri: fileUrl,
            fileType,
            fileName,
            newUpload: true,
          },
        ]);
      } else {
        console.log("No file content found for the clicked file.");
      }
    };

    if (upload_period === "old") {
      await handleOldUploadPeriod();
    } else if (upload_period === "new") {
      handleNewUploadPeriod();
    }
  };

  const onUploadFinish = (values) => {
    console.log("uploadValues: ", values);
    const newKey = contractDocData.length;
    const newVersionNumber =
      contractDocData.length === 0 ? 1 : contractDocData.length + 1;

    let newUpload = {
      key: newKey,
      version: (
        <div style={{ display: "flex" }}>
          <span
            style={{ color: "var(--color-solid-darkerblue)", fontWeight: 600 }}
          >{`Version ${newVersionNumber}`}</span>
          <Image
            src={VersionIcon}
            preview={false}
            height={20}
            width={20}
            style={{ marginTop: -6, marginLeft: 8 }}
          />
        </div>
      ),
      file_content:
        values.uploaded_doc.fileList.length !== 0
          ? values.uploaded_doc.fileList[0].originFileObj
          : "",
      file_name: values.uploaded_doc.file.name,
      last_updated_contract: <SwapRightOutlined className="contract-arrow" />,
      updated_on: (
        <div style={{ display: "flex" }}>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkergrey)" }}
          >
            Updated On&nbsp;:&nbsp;
          </span>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkestblue)" }}
          >{`${dayjs(new Date()).format("DD/MM/YYYY")}`}</span>
        </div>
      ),
      updated_by: (
        <div style={{ display: "flex" }}>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkergrey)" }}
          >
            Updated By&nbsp;:&nbsp;
          </span>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkestblue)" }}
          >{`${userData[0].first_name + " " + userData[0].last_name}`}</span>
        </div>
      ),
      updated_by_id: userData[0].id ? userData[0].id : userData[0]._id,
      action: (
        <span
          style={{
            fontWeight: 600,
            color: "var(--color-solid-darkerblue)",
            cursor: "pointer",
          }}
          onClick={() => {
            viewFile(
              { file_name: values.uploaded_doc.file.name },
              "contract_doc",
              "new"
            );
            // setViewedFile(true);
          }}
        >
          View
        </span>
      ),
    };

    setContractDocData((prev) => [newUpload, ...prev]);
    setContractDocFileList((docFileList) => [...docFileList, newDocFileUpload]);
    setUploadContractDoc(false);
    setDisableUpload(true);
    setIsDocumentUploaded(true); // Enable submit button
    uploadForm.resetFields();
  };

  const onUploadFinishFailed = (err) => {
    console.log("uploadErr: ", err);
  };

  const contractDocProps = {
    name: "file",
    listType: "picture",
    showUploadList: true,
    accept: ".docx,.doc,.pdf",
    onChange(info) {
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setNewDocFileUpload(info.fileList[0]);
          setDisableUpload(false);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      if (!validateFileSize(file)) {
        return Upload.LIST_IGNORE;
      }
      const acceptedFormats = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/pdf",
      ];
      const formatCheck = acceptedFormats.includes(file.type);

      if (!formatCheck) {
        message.error({
          content: "You can only upload DOCX or DOC or pdf files.",
          top: 150,
        });
        return Upload.LIST_IGNORE;
      }

      return formatCheck;
    },
    onRemove() {
      setNewDocFileUpload();
      setDisableUpload(true);
    },
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        selectedRows.length >= 2 &&
        !selectedRows.map((row) => row.key).includes(record.key),
      name: record.name,
    }),
  };

  useEffect(() => {
    // Compute the changed lines
    const diff = diffLines(text1, text2);
    const changes = [];
    let leftLineNumber = 0;
    let rightLineNumber = 0;

    diff.forEach((part) => {
      const lines = part.value.split("\n");
      lines.pop(); // Remove the last empty element due to split on '\n'

      lines.forEach((line, index) => {
        if (part.added || part.removed) {
          changes.push({
            left: part.removed ? leftLineNumber + 1 + index : null,
            right: part.added ? rightLineNumber + 1 + index : null,
          });
        }
      });

      if (!part.added) {
        leftLineNumber += lines.length;
      }
      if (!part.removed) {
        rightLineNumber += lines.length;
      }
    });

    if (changes.length > 0) {
      const firstChange = changes[0];
      const highlights = [];
      if (firstChange.left !== null) highlights.push(`L-${firstChange.left}`);
      if (firstChange.right !== null) highlights.push(`R-${firstChange.right}`);
      setHighlightLines(highlights);
      // setTimeout(() => scrollToHighlight(firstChange), 0);
    }

    setChangedLines(changes);
    if (text1.length !== 0 && text2.length !== 0) {
      const table = viewerRef.current.querySelector("table");
      if (table) {
        const rows = table.querySelectorAll("tr");
        console.log("rows:", rows);
      }
    }
  }, [text1, text2]);

  const handleNext = () => {
    if (currentLineIndex < changedLines.length - 1) {
      const nextIndex = currentLineIndex + 1;
      setCurrentLineIndex(nextIndex);
      const line = changedLines[nextIndex];
      const highlights = [];
      if (line.left !== null) highlights.push(`L-${line.left}`);
      if (line.right !== null) highlights.push(`R-${line.right}`);
      setHighlightLines(highlights);
    }
  };

  const handlePrevious = () => {
    if (currentLineIndex > 0) {
      const prevIndex = currentLineIndex - 1;
      setCurrentLineIndex(prevIndex);
      const line = changedLines[prevIndex];
      const highlights = [];
      if (line.left !== null) highlights.push(`L-${line.left}`);
      if (line.right !== null) highlights.push(`R-${line.right}`);
      setHighlightLines(highlights);
    }
  };

  const scrollToLine = (highlight) => {
    if (viewerRef.current) {
      const table = viewerRef.current.querySelector("table");
      if (table) {
        const rows = Array.from(table.querySelectorAll("tr"));
        rows.shift(); // Remove the first row if it's a header row

        const [side, lineNumber] = highlight[0].split("-");
        const targetLineNumber = parseInt(lineNumber) - 1;

        for (const row of rows) {
          const cells = row.cells;
          let cell;

          if (side === "L") {
            cell = cells[0];
          } else if (side === "R") {
            cell = cells[3];
          }

          if (cell) {
            const pre = cell.childNodes[0];
            if (pre && pre.childNodes[0]) {
              const textContent = pre.childNodes[0].textContent.trim();
              if (parseInt(textContent, 10) === targetLineNumber) {
                row.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                break;
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (highlightLines.length !== 0) scrollToLine(highlightLines);
  }, [highlightLines]);

  const updateCommercialData = (
    commercialTableData,
    yearsListData,
    daysData,
    costsData,
    updatedCurrencyToggle,
    updatedNegatedResource
  ) => {
    commercialRef.current = commercialTableData;
    yearsListRef.current = yearsListData;
    daysRef.current = daysData;
    costsRef.current = costsData;
    currencyToggleRef.current = updatedCurrencyToggle;
    negatedResourceRef.current = updatedNegatedResource;
    // console.log("commercialRef.current: ", commercialRef.current);
    // console.log("daysRef.current: ", daysRef.current);
    // console.log("costsRef.current: ", costsRef.current);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onReject = async (values) => {
    setContractApprovalLoader(true);
    let data = {
      contract_id: contract._id ? contract._id : contract.id,
      contract_name: contract.contract_name,
      author: userData && userData[0] && userData[0].id,
      author_name: userData && userData[0] && userData[0].first_name,
      comments: comments,
      email: userData && userData[0] && userData[0].email,
      notes: values.description,
      updated_on: new Date(),
    };

    const days = new FormData();
    days.append("file", contractFile && contractFile.originFileObj);
    days.append("days", JSON.stringify(data));

    await onRejectApi(days).then(() => {
      setContractApprovalLoader(false);
      popupModalInstance
        .successModal(ModalMessage.Contract_Reject, "")
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setRejectModel(false);
            window.location.reload();
          }
        });
    });
  };

  const onReturn = (values) => {
    setContractApprovalLoader(true);

    let data = {
      contract_id: contract._id ? contract._id : contract.id,
      contract_name: contract.contract_name,
      author: userData && userData[0] && userData[0].id,
      author_name: userData && userData[0] && userData[0].first_name,
      comments: comments,
      email: userData && userData[0] && userData[0].email,
      notes: values.description,
      updated_on: new Date(),
    };

    const days = new FormData();
    days.append("file", contractFile && contractFile.originFileObj);
    days.append("days", JSON.stringify(data));

    onReturnApi(days).then(() => {
      setContractApprovalLoader(false);
      popupModalInstance
        .successModal(ModalMessage.Contract_Return, "")
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setReturnModel(false);
            window.location.reload();
          }
        });
    });
  };

  const onApprove = async (values) => {
    setContractApprovalLoader(true);

    let _dagRunId;
    let data = {
      contract_id: contract._id ? contract._id : contract.id,
      contract_name: contract.contract_name,
      author: userData && userData[0] && userData[0].id,
      author_name: userData && userData[0] && userData[0].first_name,
      comments: comments,
      email: userData && userData[0] && userData[0].email,
      notes: values.description,
      updated_on: new Date(),
    };

    const days = new FormData();
    for (let i = 0; i < contractFile.length; i++) {
      days.append("file", contractFile[i].originFileObj);
    }
    days.append("days", JSON.stringify(data));

    await onApproveApi(days).then((res) => {
      setContractApprovalLoader(false);
      popupModalInstance
        .successModal(ModalMessage.Contract_Approve, "")
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setApproveModel(false);
            window.location.reload();
          }
        });
    });

    // get DagRunId
    await axios
      .get(`${airflowUrl}/dags/${dagID}/dagRuns`, {
        auth: {
          username: airflowCredential.username,
          password: airflowCredential.password,
        },
        withCredentials: true,
      })
      .then((res) => {
        console.log("dagRes", res);
        let dagRes = res.data.dag_runs;
        let dagrunID = dagRes[dagRes.length - 1];
        _dagRunId = dagrunID.dag_run_id;
      })
      .catch((err) => {
        console.log("err", err);
      });

    // update Dag Taskwait status
    console.log("approverID", approverID);
    let airflow_task_level = approverID.substring(11);
    let _url = `${airflowUrl}/dags/${dagID}/updateTaskInstancesState`;
    let bodyData = {
      dry_run: false,
      task_id: `task_${airflow_task_level}_wait`,
      dag_run_id: _dagRunId,
      include_upstream: false,
      include_downstream: false,
      include_future: false,
      include_past: false,
      new_state: "success",
    };

    await axios
      .post(_url, bodyData, {
        auth: {
          username: airflowCredential.username,
          password: airflowCredential.password,
        },
        withCredentials: true,
      })
      .then((res) => console.log("taskres", res))
      .catch((err) => console.log("taskerr", err));
  };

  const onFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSwitchAuthor = () => {
    setAuthorSwitch(true);
  };

  const onFailedAuthorChange = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeAuthor = (values) => {
    setAuthorSwitchLoad(true);
    console.log("author change values", values);
    values["contract_id"] = contract && contract._id;

    popupModalInstance
      .infoModal(ModalMessage.Author_Change_Info)
      .then(async (userChoice) => {
        if (userChoice === "ok") {
          await authorChangeApi(values).then(() => {
            setAuthorSwitchLoad(false);
            setAuthorSwitch(false);
            popupModalInstance.authorChangeModal(
              contract && contract.contract_name,
              ModalMessage.Author_Change_Success,
              "/contract"
            );
          });
        }
        if (userChoice === "cancel") {
          setAuthorSwitchLoad(false);
        }
      });
  };

  const onChangeContractDates = () => {
    const parentCommercials =
      mode.create && state.data.contract_class === "amendment"
        ? state.data.parentData.commercial_data
        : mode.edit && contract && contract.contract_class === "amendment"
        ? contract.parent_commercial_data
        : null;

    commercialRef.current = commercialRef.current.map((data) => {
      if (parentCommercials) {
        const parentRow = parentCommercials.find(
          (row) => row["Resource Id"] === data["Resource Id"]
        );

        if (
          parentRow ||
          Object.values(negatedResourceRef.current).includes(
            data["Resource Id"]
          )
        ) {
          return data;
        } else {
          data["Start Date"] = undefined;
          data["End Date"] = undefined;
          data["Days"] = {};
          data["Cost"] = {};
          delete yearsListRef.current[data.key];
          delete daysRef.current[data.key];
          delete costsRef.current[data.key];
        }
      } else {
        data["Start Date"] = undefined;
        data["End Date"] = undefined;
        data["Days"] = {};
        data["Cost"] = {};
        yearsListRef.current = {};
        daysRef.current = {};
        costsRef.current = {};
      }
      return data;
    });

    if (commercialType === "table") {
      if (parentCommercials) {
        contractForm.setFieldValue(
          ["contract", "Contract Value"],
          getTotalCommercialDaysCosts(
            costsRef.current,
            "cost",
            "table"
          ).toLocaleString("en-us")
        );
      } else {
        contractForm.setFieldValue(["contract", "Contract Value"], 0);
      }
    }

    setRootData({
      ...rootData,
      startDate: contractStartDateRef.current,
      endDate: contractEndDateRef.current,
    });
  };

  const onChangePricingProfile = (pricingProfileKey) => {
    if (pricingProfileKey) {
      const selectedPricingProfile = pricingProfileList.find(
        (profile) => profile._id === pricingProfileKey
      );
      setRateTableData(selectedPricingProfile.ratetable_data);
      setContractPricing(selectedPricingProfile.contract_pricing);
      setContractCurrency(selectedPricingProfile.contract_cc);
    } else {
      setRateTableData({});
      setContractPricing([]);
      setContractCurrency([]);
    }

    commercialRef.current = [{ ...rateCardTemplate }];
    yearsListRef.current = {};
    daysRef.current = {};
    costsRef.current = {};
  };

  const onChangeFxProfile = (fxProfileKey) => {
    if (fxProfileKey) {
      const selectedFxProfile = fxTableList.find(
        (fxTable) => fxTable.id === fxProfileKey
      );
      setFxCurrencyList(selectedFxProfile.fx_profile);
    }
  };

  const getTotalCommercialDaysCosts = (
    data,
    calculationType,
    calculationFor
  ) => {
    const sumValues = (yearData) => {
      return Object.values(yearData).reduce((yearAcc, months) => {
        return (
          yearAcc +
          Object.values(months).reduce((monthAcc, value) => {
            return monthAcc + (isNaN(value) ? 0 : value);
          }, 0)
        );
      }, 0);
    };

    if (calculationFor === "single_row") {
      return data ? sumValues(data) : 0;
    }

    if (calculationFor === "table") {
      return Object.values(data).reduce((tableAcc, row) => {
        const rowData = calculationType === "days" ? row : row;
        return tableAcc + (rowData ? sumValues(rowData) : 0);
      }, 0);
    }

    return 0;
  };

  const formatNumberWithCommas = (value) => {
    if (!value) return "";
    const [integer, decimal] = value.split(".");
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimal !== undefined
      ? `${formattedInteger}.${decimal}`
      : formattedInteger;
  };

  const contractInfoTab = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 20,
      },
    };

    return (
      <>
        {docButtonView && url.length > 0 && !url[0].newUpload ? (
          <div className="org-view-div">
            <div
              className="cursor-pointer m-10"
              onClick={() => {
                setDocButtonView(false);
                setUrl([
                  {
                    fileType: "docx",
                    fileName: "",
                  },
                ]);
              }}
            >
              <Image
                src={arrow_left}
                style={{ marginLeft: 10, width: 25 }}
                preview={false}
              />
              <span
                style={{
                  fontWeight: 600,
                  fontSize: 13,
                  paddingLeft: 20,
                  color: "var(--color-solid-darkergrey)",
                }}
              >
                &nbsp;{url[0]?.fileName || "No file name"}
              </span>
            </div>
            <div style={{ padding: "0px 20px" }}>
              <DocViewer
                className="doc-viewer"
                pluginRenderers={DocViewerRenderers}
                documents={url}
                style={{ height: "calc(100vh - 230px)" }}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                  pdfZoom: {
                    defaultZoom: 0.7,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="org-view-div">
            <Row align="middle">
              <Col span={24}>
                {(mode.view || mode.edit) && (
                  <Row
                    justify="end"
                    className="mt-10"
                    style={{ marginRight: 20 }}
                  >
                    <Col
                      className="vertical-align"
                      xs={10}
                      sm={7}
                      md={5}
                      lg={4}
                      xl={3}
                    >
                      <LatestContractButton
                        disabled={contractDocData.length === 0}
                        onClick={handleViewLatestContract}
                      />
                    </Col>
                  </Row>
                )}

                {/* About the Contract */}
                <Row>
                  <Col span={24}>
                    <div className="contract-main-div m-15">
                      <div
                        className="edit-invoice-entity-div"
                        style={mode.create ? { marginTop: 30 } : {}}
                      >
                        <h4 className="access-title">About the Contract</h4>
                        <div ref={innerTabRef} className="m-20">
                          <Row>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Title"]}
                                label="Title"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: "Please enter Title!",
                                  },
                                ]}
                              >
                                <Input
                                  readOnly
                                  placeholder="Enter title"
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Client Entity"]}
                                label="Client Entity"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Client Entity`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Client Entity"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Supplier Entity Name"]}
                                label="Supplier Entity Name"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Supplier Entity Name`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={mode.view}
                                  placeholder={
                                    !mode.view && "Select Supplier Entity Name"
                                  }
                                  className="basic-input"
                                  allowClear={true}
                                  showSearch
                                  options={
                                    mode.edit
                                      ? supplierEntityList.map((entity) => ({
                                          key: entity.supplier_entity,
                                          value: entity.supplier_entity,
                                          label: entity.supplier_entity,
                                        }))
                                      : supplierEntityList
                                  }
                                  getPopupContainer={getInfoPopupContainer}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Business Area Department"]}
                                label="Business Area / Department"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Business Area Department`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view &&
                                    "Enter Business Area / Department"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Contract Start Date"]}
                                label="Contract Start Date"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please select Start Date`,
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabled={mode.view}
                                  onChange={(startDateValue) => {
                                    contractForm.setFieldValue(
                                      ["contract", "Contract End Date"],
                                      null
                                    );
                                    contractStartDateRef.current =
                                      startDateValue;
                                    contractEndDateRef.current = undefined;
                                  }}
                                  placeholder={
                                    !mode.view && "Select Contract Start Date"
                                  }
                                  getPopupContainer={getDatePickerContainer}
                                  showNow={false}
                                  format="DD MMM YYYY"
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Contract End Date"]}
                                label="Contract End Date"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please select End Date`,
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabled={mode.view}
                                  disabledDate={(current) => {
                                    const startDate =
                                      contractForm.getFieldValue([
                                        "contract",
                                        "Contract Start Date",
                                      ]);
                                    return startDate
                                      ? current &&
                                          current.isBefore(startDate, "day")
                                      : false;
                                  }}
                                  onChange={(endDateValue) => {
                                    contractEndDateRef.current = endDateValue;
                                    onChangeContractDates();
                                  }}
                                  placeholder={
                                    !mode.view && "Select Contract End Date"
                                  }
                                  getPopupContainer={getDatePickerContainer}
                                  showNow={false}
                                  format="DD MMM YYYY"
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Priority"]}
                                label="Priority"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please select Priority`,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={mode.view}
                                  placeholder={!mode.view && "Select Priority"}
                                  className="basic-input"
                                  allowClear={true}
                                  getPopupContainer={getInfoPopupContainer}
                                >
                                  <Select.Option value="P1">P1</Select.Option>
                                  <Select.Option value="P2">P2</Select.Option>
                                  <Select.Option value="P3">P3</Select.Option>
                                  <Select.Option value="P4">P4</Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["contract", "Contract Value"]}
                                label="Contract Value"
                                colon={false}
                                rules={[
                                  {
                                    required:
                                      buttonState === 2 &&
                                      commercialType === "value"
                                        ? true
                                        : false,
                                    message: `Please enter Contract Value`,
                                  },
                                ]}
                              >
                                <Input
                                  disabled={commercialType === "table"}
                                  placeholder="Contract Value"
                                  className="basic-input"
                                  onChange={(e) => {
                                    const rawValue = e.target.value
                                      .replace(/[^0-9.]/g, "")
                                      .replace(/(\..*?)\./g, "$1");
                                    contractForm.setFieldValue(
                                      ["contract", "Contract Value"],
                                      formatNumberWithCommas(rawValue)
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* About the Scope */}
                <Row className="mt-10">
                  <Col span={24}>
                    <div className="contract-main-div m-15">
                      <div className="edit-invoice-entity-div">
                        <h4 className="access-title">About the Scope</h4>
                        <div className="m-20">
                          <Row>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["scope", "Scope"]}
                                label="Scope"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Scope`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={!mode.view && "Enter Scope"}
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["scope", "Scope In Detail"]}
                                label="Scope In Detail"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Scope In Detail`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Scope in detail"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["scope", "Assumptions"]}
                                label="Assumptions"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Assumption`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Assumptions"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["scope", "Dependencies"]}
                                label="Dependencies"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Dependencies`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Dependencies"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["scope", "Other Details"]}
                                label="Other Details"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Other Details`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Other Details"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* About the People */}
                <Row className="mt-10">
                  <Col span={24}>
                    <div className="contract-main-div m-15">
                      <div className="edit-invoice-entity-div">
                        <h4 className="access-title">About the People</h4>
                        <div className="m-20">
                          <Row>
                            <Col span={24}>
                              {!mode.view &&
                                contract &&
                                contract.contract_status ===
                                  "Contract-in-Draft" && (
                                  <Row justify="end" className="mb-10">
                                    <Col xs={24} sm={6} md={4} lg={4} xl={3}>
                                      <AuthorChangeButton
                                        onClick={onSwitchAuthor}
                                      />
                                    </Col>
                                  </Row>
                                )}
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["people", "Department Head"]}
                                label="Department Head"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Department Head`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Department Head"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["people", "Department Lead"]}
                                label="Department Lead"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Department Lead`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Department Lead"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["people", "Department Manager"]}
                                label="Department Manager"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Department Manager`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Department Manager"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["people", "Contract Manager"]}
                                label="Contract Manager"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Contract Manager`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Contract Manager"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["people", "Supplier Partner Lead"]}
                                label="Supplier Partner Lead"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Supplier Partner Lead`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view && "Enter Supplier Partner Lead"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name={["people", "Supplier Partner Manager"]}
                                label="Supplier Partner Manager"
                                colon={false}
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Supplier Partner Manager`,
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  readOnly={mode.view}
                                  placeholder={
                                    !mode.view &&
                                    "Enter Supplier Partner Manager"
                                  }
                                  className="basic-input"
                                />
                              </Form.Item>
                            </Col>

                            {ConfigFile.isDocuSignEnabled && (
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name={["people", "Docusign Signers"]}
                                  label="Docusign Signers"
                                  colon={false}
                                  rules={[
                                    // {
                                    //   required: buttonState === 2,
                                    //   message: "Please enter Docusign Signers",
                                    // },
                                    {
                                      pattern:
                                        /^(\s*\S+@\S+\.\S+\s*)(,\s*\S+@\S+\.\S+\s*)*$/,
                                      message:
                                        "Please enter valid email address",
                                    },
                                  ]}
                                >
                                  <Input
                                    readOnly={mode.view}
                                    placeholder={
                                      !mode.view && "Enter Docusign Signers"
                                    }
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* About the Financials */}
                <Row className="mt-10">
                  <Col span={24}>
                    <div className="contract-main-div m-15">
                      <div className="edit-invoice-entity-div">
                        <h4 className="access-title">About the Financials</h4>
                        <div ref={innerTabRef} className="m-20">
                          <Row>
                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                colon={false}
                                name={["financial", "Pricing Profile"]}
                                label="Pricing Profile"
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please select Pricing Profile`,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={
                                    mode.view ||
                                    (mode.create &&
                                      state.data?.contract_class ===
                                        "amendment") ||
                                    (mode.edit &&
                                      contract?.contract_class === "amendment")
                                  }
                                  onChange={(key) => {
                                    contractForm.setFieldValue(
                                      ["financial", "Contract Pricing"],
                                      null
                                    );
                                    contractForm.setFieldValue(
                                      ["financial", "Contract Currency"],
                                      null
                                    );
                                    onChangePricingProfile(key);
                                  }}
                                  className="basic-input"
                                  placeholder="Select Pricing Profile"
                                  allowClear
                                  showSearch
                                  options={pricingProfileList.map(
                                    (profile) => ({
                                      key: profile._id,
                                      label: profile.price_profile_name,
                                      value: profile._id,
                                    })
                                  )}
                                  getPopupContainer={getInfoPopupContainer}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                colon={false}
                                name={["financial", "Invoicing Profile"]}
                                label="Invoicing Profile"
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please select Invoicing Profile`,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={mode.view}
                                  placeholder="Select Invoicing Profile"
                                  className="basic-input"
                                  allowClear={true}
                                  options={
                                    invoicingProfileList &&
                                    invoicingProfileList.map((data) => ({
                                      key: data.id,
                                      label: data.invoice_profile_name,
                                      value: data.id,
                                    }))
                                  }
                                  getPopupContainer={getInfoPopupContainer}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                colon={false}
                                name={["financial", "Fx Profile"]}
                                label="FX Profile"
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please select Fx Profile`,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={mode.view}
                                  allowClear
                                  className="basic-input"
                                  showSearch={true}
                                  placeholder="Select FX Profile"
                                  options={
                                    fxTableList &&
                                    fxTableList.map((data) => ({
                                      key: data.id,
                                      label: data.fx_table_name,
                                      value: data.id,
                                    }))
                                  }
                                  onChange={onChangeFxProfile}
                                  getPopupContainer={getInfoPopupContainer}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                colon={false}
                                name={["financial", "Contract Pricing"]}
                                label="Contract Pricing"
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Contract Pricing`,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={mode.view}
                                  allowClear
                                  className="basic-input"
                                  showSearch={true}
                                  placeholder="Select Contract Pricing"
                                  options={
                                    contractPricing &&
                                    contractPricing
                                      .filter((filData) => filData !== "All")
                                      .map((data) => ({
                                        key: data,
                                        label: data,
                                        value: data,
                                      }))
                                  }
                                  getPopupContainer={getInfoPopupContainer}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12} xl={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                colon={false}
                                name={["financial", "Contract Currency"]}
                                label="Contract Currency"
                                rules={[
                                  {
                                    required: buttonState === 2 ? true : false,
                                    message: `Please enter Contract Currency`,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={mode.view}
                                  allowClear
                                  className="basic-input"
                                  showSearch={true}
                                  placeholder="Select Contract Currency"
                                  options={
                                    contractCurrency &&
                                    contractCurrency.map((data) => ({
                                      key: data,
                                      label: data,
                                      value: data,
                                    }))
                                  }
                                  getPopupContainer={getInfoPopupContainer}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  };

  const commercialTab = () => {
    const ContractMenuContent = () => {
      // console.log("commercialRef: ", commercialRef.current);
      // console.log("yearsListRef: ", yearsListRef.current);
      // console.log("contractStartDateRef: ", contractStartDateRef.current);
      // console.log("contractEndDateRef: ", contractEndDateRef.current);
      // console.log("rateCardTemplate: ", rateCardTemplate);

      const contractClass = determineContractClass(
        breadcrumbs &&
          breadcrumbs.length >= 2 &&
          breadcrumbs[breadcrumbs.length - 1].apiArgs.type,
        breadcrumbs && breadcrumbs.length >= 2 && breadcrumbs[2].apiArgs.type,
        contractConfig
      );

      let commercialTableDataSource = JSON.parse(
        JSON.stringify(commercialRef.current)
      );

      const isUntouchedCommercialTable =
        commercialTableDataSource.length === 1 &&
        commercialTableDataSource[0].key === 0 &&
        commercialTableDataSource[0]["Resource Id"] === "RE_1" &&
        Object.keys(commercialTableDataSource[0]).length === 3;

      const commercialState = {
        mode: mode.view ? "view" : mode.create ? "create" : "edit",
        parentType: contractClass,
        parentData:
          contractClass === "amendment"
            ? mode.edit || mode.view
              ? contract && contract.parent_commercial_data
              : state.data?.parentData.commercial_data
            : null,
      };

      const commercialRateCardState = {
        assembledData: rateTableData.ratecard_items,
        disassembledData: {
          keys: rateTableData.rt_items_desc,
          values: rateTableData.rt_items_detail,
        },
      };

      if (
        !isEmptyObject(contractStartDateRef.current || {}) &&
        !isEmptyObject(contractEndDateRef.current || {}) &&
        !isEmptyObject(rateTableData || {}) &&
        rateTableData.ratecard_items &&
        !isUntouchedCommercialTable
      ) {
        return (
          <div style={{ margin: "15px 5px 20px 20px" }}>
            <CommercialTable
              state={commercialState}
              rateCardData={commercialRateCardState}
              rateCardTemplate={rateCardTemplate}
              parentCommercialRef={commercialRef}
              parentYearsListRef={yearsListRef}
              parentDaysRef={daysRef}
              parentCostsRef={costsRef}
              negatedResourceRef={negatedResourceRef}
              currencyToggleRef={currencyToggleRef}
              fxCurrencyList={fxCurrencyList}
              contractStartDateRef={contractStartDateRef}
              contractEndDateRef={contractEndDateRef}
              onUpdateCommercialData={updateCommercialData}
            />
          </div>
        );
      } else {
        return (
          <Row>
            {(mode.create || mode.edit) && commercialType === "table" ? (
              <Col span={24} align="center">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 100,
                  }}
                  description={
                    <span>
                      Please select Contract Start Date, Contract End Date and
                      Pricing Profile from Contract Info tab
                    </span>
                  }
                />
              </Col>
            ) : (
              <Col span={24}>
                <div className="contract-info-view">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                      height: 100,
                    }}
                    description={
                      <span>
                        No Commercial Data for{" "}
                        <span style={{ fontWeight: 600 }}>
                          {contract ? contract.contract_name : state.data.title}
                        </span>
                      </span>
                    }
                  />
                </div>
              </Col>
            )}
          </Row>
        );
      }
    };

    const AggregateMenuContent = () => {
      return (
        <div>
          <Row>
            <Col span={24}>
              <div className="contract-info-view">
                {/* <AggregateValue contractData={contractData} /> */}
                <NewAggregateValue aggregateTableData={aggregateTableData} />
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    const menuitems = [
      getItem(
        "Contract Value",
        "1",
        commercialMenuState === "contract" ? <img src={dotPointer} /> : ""
      ),
      getItem(
        "Aggregate Value",
        "2",
        commercialMenuState === "aggregate" ? <img src={dotPointer} /> : ""
      ),
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-10">
                <Row type="flex">
                  <Col md={4} lg={4} xl={3}>
                    <Menu
                      id="contract-info-menu"
                      onClick={(key) => {
                        if (key.key === "1") {
                          setCommercialMenuState("contract");
                        } else if (key.key === "2") {
                          setCommercialMenuState("aggregate");
                        }
                      }}
                      defaultSelectedKeys={["1"]}
                      mode="vertical"
                      items={menuitems}
                    />
                  </Col>
                  <Col md={20} lg={20} xl={21}>
                    {commercialMenuState === "contract" ? (
                      <ContractMenuContent />
                    ) : commercialMenuState === "aggregate" ? (
                      <AggregateMenuContent />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const approvalFlowChart = () => {
    return (
      <div className="org-view-div">
        <Row align="middle" type="flex" gutter={[24, 0]} className="m-0">
          <Col className="p-0 vertical-align" span={24}>
            <FlowDiagram
              flowData={
                mode.create
                  ? state.data.workflow.workflow_metadata
                  : contract &&
                    contract.contract_workflow_data.contract_workflow_metadata
              }
              selectedFile={
                mode.create
                  ? undefined
                  : {
                      contract_status: contract && contract.contract_status,
                      created_by: contract && contract.created_by,
                      _id: contract && contract._id,
                      docusign_envelope_id:
                        contract && contract.docusign_envelope_id,
                    }
              }
              from={
                mode.create ? "create-contract-view" : "contract-pipeline-view"
              }
              contract_id={contract && contract._id}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const contractDoc = () => {
    const formItemStyle = {
      border: "1px solid var(--color-solid-blue)",
      borderRadius: 8,
      height: 32,
    };

    const versionContainerStyle = {
      borderRadius: 10,
      height: "calc(100vh - 250px)",
      whiteSpace: "pre-wrap",
      overflowY: "scroll",
    };

    const getVersionNumber = () => {
      return [
        Math.min(
          selectedRows.length !== 0 &&
            selectedRows[0] &&
            selectedRows[0].version.props.children[0].props.children.split(
              " "
            )[1],
          selectedRows.length > 1 &&
            selectedRows[1].version.props.children[0].props.children.split(
              " "
            )[1]
        ),
        Math.max(
          selectedRows.length !== 0 &&
            selectedRows[0] &&
            selectedRows[0].version.props.children[0].props.children.split(
              " "
            )[1],
          selectedRows.length > 1 &&
            selectedRows[1].version.props.children[0].props.children.split(
              " "
            )[1]
        ),
      ];
    };

    const diffViewerStyles = {
      wordAdded: {
        padding: "0px 5px",
        borderRadius: "5px",
      },
      wordRemoved: {
        padding: "0px 5px",
        borderRadius: "5px",
      },
      // highlightedLine: {
      //   background: "rgba(255, 235, 59, 0.3)",
      // },
    };

    return (
      <div className="org-view-div">
        <div style={{ padding: "0px 20px" }}>
          {!viewedFile ? (
            <>
              {!mode.view ||
              (contract &&
                contract.contract_status !==
                  ("Contract-in-Execution" ||
                    "Sent for Signature" ||
                    "Contract-Signed")) ? (
                <Row className="mt-10 mb-15" justify="end">
                  <Col span={3} style={{ marginRight: 10 }}>
                    <CompareButton
                      disabled={selectedRows.length === 2 ? false : true}
                      onClick={() => {
                        setCompareModal(true);
                      }}
                    />
                  </Col>
                  <Col span={3}>
                    <UploadButton onClick={() => setUploadContractDoc(true)} />
                    <Modal
                      title={
                        <div style={{ display: "flex" }}>
                          <Image
                            src={UploadButtonIcon}
                            preview={false}
                            style={{ paddingRight: 10 }}
                          />
                          <p>Upload Document</p>
                        </div>
                      }
                      width={400}
                      centered
                      open={uploadContractDoc}
                      footer={null}
                      onCancel={() => setUploadContractDoc(false)}
                    >
                      <Form
                        form={uploadForm}
                        className="mt-30 create-role"
                        onFinish={onUploadFinish}
                        onFinishFailed={onUploadFinishFailed}
                        autoComplete="off"
                      >
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="document_type"
                              label="Document Type"
                              labelCol={{ span: 9 }}
                              labelAlign="left"
                              colon={false}
                            >
                              <Input
                                readOnly
                                style={formItemStyle}
                                defaultValue="Contract"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 8]}>
                          <Col span={24}>
                            <Form.Item
                              name="uploaded_doc"
                              label="Upload Document"
                              labelCol={{ span: 24 }}
                              labelAlign="left"
                              colon={false}
                            >
                              <Upload
                                name="uploaded_document"
                                {...contractDocProps}
                                customRequest={dummyRequest}
                                headers={{
                                  "Access-Control-Allow-Origin": "*",
                                }}
                                maxCount={1}
                                className="custom-upload-wrapper"
                              >
                                <Col span={24}>
                                  <Button
                                    style={{
                                      ...formItemStyle,
                                      fontSize: 13,
                                      color: "var(--color-solid-darkerblue)",
                                      width: "100%",
                                    }}
                                  >
                                    Browse
                                  </Button>
                                </Col>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row type="flex" align="middle">
                          <Col
                            span={24}
                            align="middle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 20,
                            }}
                          >
                            <Col span={8} style={{ marginRight: 5 }}>
                              <CancelButton
                                onClick={() => setUploadContractDoc(false)}
                              />
                            </Col>
                            <Col span={8} style={{ marginLeft: 5 }}>
                              <UploadButton
                                htmlType="submit"
                                disabled={disableUpload}
                              />
                            </Col>
                          </Col>
                        </Row>
                      </Form>
                    </Modal>
                    <Modal
                      title={
                        <>
                          <div style={{ display: "flex" }}>
                            <Image
                              src={CompareIcon}
                              preview={false}
                              style={{ paddingRight: 10 }}
                            />
                            <p>Compare versions of documents</p>
                          </div>
                        </>
                      }
                      width="98%"
                      centered
                      open={compareModal}
                      footer={null}
                      onCancel={() => {
                        setText1("");
                        setText2("");
                        setChangedLines([]);
                        setHighlightLines([]);
                        setCurrentLineIndex(0);
                        setCompareModal(false);
                      }}
                    >
                      <Row
                        type="flex"
                        align="middle"
                        justify="start"
                        className="mb-10"
                        gutter={[0, 0]}
                      >
                        <Col span={9} offset={11}>
                          <div style={{ display: "flex" }}>
                            <Button
                              className="move-up-btn"
                              style={{ marginLeft: "4%", marginRight: 15 }}
                              icon={<CaretLeftOutlined />}
                              onClick={handlePrevious}
                              disabled={currentLineIndex <= 0}
                              title="Highlight Previous Change"
                            />
                            <Button
                              className="move-down-btn"
                              icon={<CaretRightOutlined />}
                              onClick={handleNext}
                              disabled={
                                currentLineIndex >= changedLines.length - 1
                              }
                              title="Highlight Next Change"
                            />
                          </div>
                        </Col>
                        <Col span={2} align="right">
                          <Switch
                            checkedChildren={
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 10,
                                }}
                              >
                                Unified View
                              </p>
                            }
                            unCheckedChildren={
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 10,
                                }}
                              >
                                Split View
                              </p>
                            }
                            onChange={() => setSplitView(!splitView)}
                            onClick={() => setSplitView(!splitView)}
                          />
                        </Col>
                        <Col span={2} align="right">
                          <Switch
                            checkedChildren={
                              <p style={{ fontWeight: 600, fontSize: 10 }}>
                                Dark Mode
                              </p>
                            }
                            unCheckedChildren={
                              <p style={{ fontWeight: 600, fontSize: 10 }}>
                                Light Mode
                              </p>
                            }
                            onChange={() => setDarkMode(!darkMode)}
                            onClick={() => setDarkMode(!darkMode)}
                          />
                        </Col>
                      </Row>
                      <div
                        style={{
                          border: "1px solid var(--color-solid-darkergrey)",
                          ...versionContainerStyle,
                        }}
                        ref={viewerRef}
                      >
                        {text1.length !== 0 && text2.length !== 0 ? (
                          <ReactDiffViewer
                            styles={diffViewerStyles}
                            oldValue={text1}
                            newValue={text2}
                            splitView={splitView}
                            leftTitle={
                              splitView ? (
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 13,
                                    paddingLeft: 10,
                                  }}
                                >{`Version ${getVersionNumber()[0]}`}</p>
                              ) : (
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: 13,
                                    paddingLeft: 10,
                                  }}
                                >{`Unified view of Version ${
                                  getVersionNumber()[0]
                                } and Version ${getVersionNumber()[1]}`}</p>
                              )
                            }
                            rightTitle={
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 13,
                                  paddingLeft: 10,
                                }}
                              >{`Version ${getVersionNumber()[1]}`}</p>
                            }
                            useDarkTheme={darkMode}
                            highlightLines={highlightLines}
                            compareMethod={"diffWords"}
                          />
                        ) : (
                          <Skeleton active paragraph={{ rows: 13 }} />
                        )}
                      </div>
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        className="mt-20"
                      >
                        <Col span={2} style={{ marginLeft: 5 }}>
                          <OkButton
                            onClick={() => {
                              setText1("");
                              setText2("");
                              setChangedLines([]);
                              setHighlightLines([]);
                              setCurrentLineIndex(0);
                              setCompareModal(false);
                            }}
                          />
                        </Col>
                      </Row>
                    </Modal>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row
                style={{
                  borderTop: "2px solid var(--color-solid-lightgrey)",
                }}
              >
                <Col span={24}>
                  <Table
                    pagination={false}
                    showHeader={false}
                    rowSelection={
                      !mode.view
                        ? {
                            type: "checkbox",
                            ...rowSelection,
                          }
                        : null
                    }
                    columns={contractDocColumns}
                    dataSource={contractDocData}
                    scroll={{ y: "calc(100vh - 235px)" }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            url.length !== 0 &&
            !url[0].newUpload && (
              <>
                <div
                  className="cursor-pointer m-10"
                  onClick={() => {
                    setViewedFile(false);
                    setUrl([
                      {
                        fileType: "docx",
                      },
                    ]);
                  }}
                >
                  <Image
                    src={arrow_left}
                    style={{ marginLeft: 10, width: 25 }}
                    preview={false}
                  />
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 13,
                      paddingLeft: 20,
                      color: "var(--color-solid-darkergrey)",
                    }}
                  >
                    &nbsp;{url && url[0] && url[0].fileName}
                  </span>
                </div>
                <div style={{ padding: "0px 20px" }}>
                  <DocViewer
                    className="doc-viewer"
                    pluginRenderers={DocViewerRenderers}
                    documents={url}
                    style={{ height: "calc(100vh - 230px)" }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                      pdfZoom: {
                        defaultZoom: 0.7, // 1 as default,
                      },
                      pdfVerticalScrollByDefault: true, // false as default
                    }}
                  />
                </div>
              </>
            )
          )}
        </div>
      </div>
    );
  };

  const notesAndComments = () => {
    return (
      <NotesAndComments
        detailData={contract}
        viewedFile={viewedFile}
        comments={comments}
        url={url}
        setUrl={setUrl}
        setFile={setContractFile}
        setFileKeysToDelete={setFileKeysToDelete}
        pipelinePath={false}
        setViewedFile={setViewedFile}
        setComments={setComments}
        flag="contract"
      />
    );
  };

  const EntityLog = () => {
    return (
      <div className="org-view-div">
        <div style={{ padding: "0px 20px" }}>
          <DataTable
            type="entityLog"
            logData={
              contract && contract.entity_logs
                ? { entity_logs: contract.entity_logs }
                : {}
            }
          />
        </div>
      </div>
    );
  };

  const TabsIcon = ({ tab, contractTabKey }) => {
    const getTabIcon = (title) => (
      <div className="tabs-ss-title">
        <img
          src={title.icon}
          style={
            title.text === "Contract Info"
              ? { width: 16, height: 16 }
              : title.text === "Entity Log"
              ? { width: 19, height: 19 }
              : {}
          }
        />
        <p>{title.text}</p>
      </div>
    );

    const iconData = {
      1: {
        active: { icon: folder_active, text: "Contract Info" },
        inactive: { icon: folder_inactive, text: "Contract Info" },
      },
      2: {
        active: { icon: adminPanel_active, text: "Commercial" },
        inactive: { icon: adminPanel_inactive, text: "Commercial" },
      },
      3: {
        active: { icon: approval_active, text: "Approval Flow Chart" },
        inactive: { icon: approval_inactive, text: "Approval Flow Chart" },
      },
      4: {
        active: { icon: contract_doc_active, text: "Contract Doc" },
        inactive: { icon: contract_doc_inactive, text: "Contract Doc" },
      },
      5: {
        active: { icon: chat_active, text: "Notes And Comments" },
        inactive: { icon: chat, text: "Notes And Comments" },
      },
      6: {
        active: { icon: log_active, text: "Entity Log" },
        inactive: { icon: log_inactive, text: "Entity Log" },
      },
    };

    const isActive = tab === contractTabKey;

    const title = isActive ? iconData[tab].active : iconData[tab].inactive;

    return <div className="tabs-title-main">{getTabIcon(title)}</div>;
  };

  // const contractInfoTabMemoized = useMemo(
  //   () => contractInfoTab(),
  //   [
  //     mode,
  //     contract,
  //     menuState,
  //     buttonState,
  //     supplierEntityList,
  //     contractForm,
  //     contractStartDateRef,
  //     contractEndDateRef,
  //     authorSwitch,
  //     pricingProfileList,
  //     invoicingProfileList,
  //     fxTableList,
  //     contractPricing,
  //     contractCurrency,
  //     url,
  //   ]
  // );
  // const commercialTabMemoized = useMemo(
  //   () => commercialTab(),
  //   [
  //     commercialMenuState,
  //     mode,
  //     contract,
  //     aggregateTableData,
  //     rateTableData,
  //     commercialRef,
  //     yearsListRef,
  //     daysRef,
  //     costsRef,
  //     contractStartDateRef,
  //     contractEndDateRef,
  //     rateCardTemplate,
  //   ]
  // );
  // const approvalFlowChartMemoized = useMemo(
  //   () => approvalFlowChart(),
  //   [mode, contract, state]
  // );
  // const entityLogMemoized = useMemo(
  //   () => (mode.edit || mode.view ? EntityLog() : null),
  //   [mode]
  // );

  const contractFormTabItems = [
    {
      key: "1",
      label: <TabsIcon tab="1" contractTabKey={contractTabKey} />,
      // children: contractInfoTabMemoized,
      children: contractInfoTab(),
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "2",
      label: <TabsIcon tab="2" contractTabKey={contractTabKey} />,
      disabled: commercialType === "none",
      // children: commercialTabMemoized,
      children: commercialTab(),
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "3",
      label: <TabsIcon tab="3" contractTabKey={contractTabKey} />,
      // children: approvalFlowChartMemoized,
      children: approvalFlowChart(),
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "4",
      label: <TabsIcon tab="4" contractTabKey={contractTabKey} />,
      children: contractDoc(),
    },
    {
      key: "divider4",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "5",
      label: <TabsIcon tab="5" contractTabKey={contractTabKey} />,
      children: notesAndComments(),
    },
    ...(mode.edit || mode.view
      ? [
          {
            key: "divider5",
            label: <Divider className="tab-divider" type="vertical" />,
            disabled: true,
          },
          {
            key: "6",
            label: <TabsIcon tab="6" contractTabKey={contractTabKey} />,
            // children: entityLogMemoized,
            children: EntityLog(),
          },
        ]
      : []),
  ];

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleFileDeletions = async () => {
    if (fileKeysToDelete.length !== 0) {
      await Promise.all(
        fileKeysToDelete.map((fileKey) =>
          deleteS3FileApi({ fileKey, flag: "contract" })
        )
      );
    }
  };

  const handleSaveSuccess = (title, message) => {
    setSaveLoader(false);
    setSubmitLoader(false);
    popupModalInstance.successModal(title, message, "");
  };

  const handleSubmitSuccess = () => {
    setSaveLoader(false);
    setSubmitLoader(false);
    navigate(
      "/contract",
      setHeaderChanger({
        header: "contract",
        headerItem: "2",
        headerItemName: "contractPipeline",
      })
    );
  };

  const populateAllValues = (node) => {
    if (node.baseline_value && node.baseline_value.all !== undefined) {
      node.baseline_value = node.baseline_value.all;
    }
    if (node.incremental_value && node.incremental_value.all !== undefined) {
      node.incremental_value = node.incremental_value.all;
    }

    node.aggregate_value =
      (node.baseline_value || 0) + (node.incremental_value || 0);

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        populateAllValues(child);
      });
    } else {
      delete node.children;
    }

    return node;
  };

  const findTreeNode = (tree, parentCid) => {
    for (const node of tree) {
      if (node._id === parentCid) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundNode = findTreeNode(node.children, parentCid);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };

  const validateAggregateValue = (
    aggregateTableData,
    contract,
    state,
    mode,
    costsRef,
    popupModalInstance,
    setSubmitLoader
  ) => {
    const parentCid = mode.create
      ? state.data.parentData._id
      : contract.parent_cid;

    const foundParent = findTreeNode(
      [populateAllValues(aggregateTableData)],
      parentCid
    );

    console.log("foundParent: ", foundParent);

    if (foundParent) {
      const baselineValue = foundParent.baseline_value || 0;
      const incrementalValue = foundParent.incremental_value || 0;

      const contractValue = getTotalCommercialDaysCosts(
        costsRef.current,
        "cost",
        "table"
      );

      // New aggregate value after adding contract value
      const newAggregateValue =
        baselineValue + incrementalValue + contractValue;

      console.log("Baseline Value: ", baselineValue);
      console.log("Incremental Value: ", incrementalValue);
      console.log("Contract Value: ", contractValue);
      console.log("New Aggregate Value: ", newAggregateValue);

      // Validate that the new aggregate value is not negative
      if (newAggregateValue < 0) {
        popupModalInstance.warningModal(
          "The new contract value would result in an invalid aggregate value for the parent."
        );
        setSubmitLoader(false);
        return false; // Validation failed
      }

      console.log("Validation passed. Proceeding with submission.");
    }

    return true; // Validation passed
  };

  const onFinish = async (values) => {
    if (buttonState === 1) {
      setSaveLoader(true);
    } else if (buttonState === 2) {
      setSubmitLoader(true);
    }

    if (commercialType === "table") {
      values.contract["Contract Value"] = getTotalCommercialDaysCosts(
        costsRef.current,
        "cost",
        "table"
      ).toLocaleString("en-us");
    }

    if (
      mode.create
        ? state.data.contract_class === "amendment"
        : contract && contract.contract_class === "amendment"
    ) {
      const isValid = validateAggregateValue(
        aggregateTableData,
        contract,
        state,
        mode,
        costsRef,
        popupModalInstance,
        setSubmitLoader
      );

      if (!isValid) {
        // Stop the submission process if validation fails
        return;
      }
    }

    const contractFormValues = {
      info: {
        contract: {
          ...values.contract,
          "Contract Value": values.contract["Contract Value"]
            ? values.contract["Contract Value"].replace(/,/g, "")
            : "0",
        },
        scope: values.scope,
        people: values.people,
        financial: values.financial,
      },
    };

    let processedCommercials = [];

    // Processing Commercial Table Data
    try {
      let commercialTableDataSource = JSON.parse(
        JSON.stringify(commercialRef.current)
      );

      function validateAndProcessCommercialData(
        commercialData,
        rateTable,
        buttonState
      ) {
        const processedData = [];

        // Helper function to find the parent row based on matching fields
        function findParentRow(entry, parentCommercialData) {
          return parentCommercialData.find(
            (parentEntry) => parentEntry["Resource Id"] === entry["Resource Id"]
          );
        }

        // Helper function to calculate the difference in Days
        function calculateDaysDifference(currentDays, parentDays) {
          const difference = {};
          for (const year in currentDays) {
            if (currentDays.hasOwnProperty(year)) {
              difference[year] = {};
              for (const month in currentDays[year]) {
                if (currentDays[year].hasOwnProperty(month)) {
                  difference[year][month] =
                    (currentDays[year][month] || 0) -
                    (parentDays[year]?.[month] || 0);
                }
              }
            }
          }

          // console.log("daydifference: ", difference);

          return difference;
        }

        for (const entry of commercialData) {
          const {
            "Resource Id": ResourceId,
            "Start Date": StartDate,
            "End Date": EndDate,
            Days,
          } = entry;

          const rateTableFields = Object.keys(rateTable[0]).filter(
            (key) => key !== "Item Id"
          );

          // Find the corresponding rate row if all rate fields are filled
          const rateRow = rateTable.find((rateEntry) =>
            rateTableFields.every((field) => rateEntry[field] === entry[field])
          );
          const RtItemId = rateRow ? rateRow["Item Id"] : null;

          const filledRateFields = rateTableFields.reduce((acc, field) => {
            if (entry[field] !== undefined && entry[field] !== null) {
              acc[field] = entry[field];
            }
            return acc;
          }, {});

          // Validation for Save (buttonState === 1)
          if (buttonState === 1) {
            const allRateFieldsFilled =
              Object.keys(filledRateFields).length === rateTableFields.length;

            let negationOf = null;
            if (
              (mode.create && state.data.contract_class === "amendment") ||
              (mode.edit && contract && contract.contract_class === "amendment")
            ) {
              negationOf = Object.keys(negatedResourceRef.current).find(
                (key) => negatedResourceRef.current[key] === ResourceId
              );
            }

            if (allRateFieldsFilled) {
              const processedRow = {
                "Resource Id": ResourceId,
                "Start Date": StartDate ?? null,
                "End Date": EndDate ?? null,
                Days: RtItemId ? Days ?? null : null,
                "RtItem Id": RtItemId,
              };

              if (
                (mode.create && state.data.contract_class === "amendment") ||
                (mode.edit &&
                  contract &&
                  contract.contract_class === "amendment")
              ) {
                processedRow.NegationMap = negationOf
                  ? {
                      [negationOf]: negatedResourceRef.current[negationOf],
                    }
                  : null;
              }

              processedData.push(processedRow);
              continue; // Skip the rest of the logic for Save
            }
          }

          // Validation for Submit (buttonState === 2)
          if (buttonState === 2) {
            if (
              mode.create
                ? state.data.contract_class === "amendment"
                : contract && contract.contract_class === "amendment"
            ) {
              const parentRow = findParentRow(
                entry,
                mode.edit
                  ? contract && contract.parent_commercial_data
                  : state.data.parentData.commercial_data
              );

              if (parentRow) {
                // Calculate the difference in Days
                const dayDifference = calculateDaysDifference(
                  Days,
                  parentRow.Days
                );

                // Check if there’s any change in Days
                const isChanged = Object.keys(dayDifference).some((year) =>
                  Object.keys(dayDifference[year]).some(
                    (month) => dayDifference[year][month] !== 0
                  )
                );

                if (!isChanged) {
                  // Skip unchanged rows
                  continue;
                }

                // Only push the row with the modified "Days" difference
                processedData.push({
                  "Resource Id": ResourceId,
                  "Start Date": StartDate,
                  "End Date": EndDate,
                  Days: dayDifference, // Replace Days with the dayDifference
                  "RtItem Id": RtItemId,
                });

                continue;
              }

              const allFieldsComplete =
                ResourceId &&
                StartDate &&
                EndDate &&
                Days &&
                Object.keys(filledRateFields).length === rateTableFields.length;

              if (!allFieldsComplete) {
                popupModalInstance.warningModal(
                  "Commercial Table is incomplete"
                );
                setSubmitLoader(false);
                return;
              }

              processedData.push({
                "Resource Id": ResourceId,
                "Start Date": StartDate,
                "End Date": EndDate,
                Days,
                "RtItem Id": RtItemId,
              });
            } else {
              const allFieldsComplete =
                ResourceId &&
                StartDate &&
                EndDate &&
                Days &&
                Object.keys(filledRateFields).length === rateTableFields.length;

              if (!allFieldsComplete) {
                popupModalInstance.warningModal(
                  "Commercial Table is incomplete"
                );
                setSubmitLoader(false);
                return;
              }

              // Match rate table entry if all fields are complete
              const rateRow = rateTable.find((rateEntry) =>
                rateTableFields.every(
                  (field) => rateEntry[field] === entry[field]
                )
              );
              const RtItemId = rateRow ? rateRow["Item Id"] : null;

              processedData.push({
                "Resource Id": ResourceId,
                "Start Date": StartDate,
                "End Date": EndDate,
                Days,
                "RtItem Id": RtItemId,
              });
              continue;
            }
          }
        }

        return processedData;
      }

      function handleCommercialDataProcessing(
        commercialTableDataSource,
        rateTableData,
        buttonState
      ) {
        // Check if commercialTableDataSource has only the initial untouched entry
        const isUntouchedCommercialTable =
          commercialTableDataSource.length === 1 &&
          commercialTableDataSource[0].key === 0 &&
          commercialTableDataSource[0]["Resource Id"] === "RE_1" &&
          Object.keys(commercialTableDataSource[0]).length === 2;

        if (isUntouchedCommercialTable) {
          if (buttonState === 1) {
            // For save, set commercialTableDataSource to an empty array and skip processing
            return [];
          } else if (buttonState === 2) {
            // For submit, show the warning and do not process further
            popupModalInstance.warningModal("Commercial Table is incomplete");
            return null;
          }
        }

        // Proceed with validation and processing if the commercial table has been touched
        return !isEmptyObject(rateTableData) && rateTableData.ratecard_items
          ? validateAndProcessCommercialData(
              commercialTableDataSource,
              rateTableData.ratecard_items,
              buttonState
            )
          : [];
      }

      processedCommercials = handleCommercialDataProcessing(
        commercialTableDataSource,
        rateTableData,
        buttonState
      );

      if (!processedCommercials) {
        return;
      }
      console.log("commercialResult: ", processedCommercials);
    } catch (error) {
      console.error("commercialError: ", error);

      popupModalInstance
        .errorCodeModal(ModalMessage.CON_FORM.ERR_ComTable)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            return;
          }
        });

      return;
    }

    // console.log("contractFormValues:", contractFormValues);

    let data = {};
    const uploadForm = new FormData();

    // Preparing ContractForm Data to send to API
    try {
      const contractDocDetails =
        contractDocData?.map((contractDoc) => ({
          version: contractDoc.version.props.children[0].props.children,
          updated_by: contractDoc.updated_by.props.children[1].props.children,
          updated_by_id: contractDoc.updated_by_id,
          updated_on: contractDoc.updated_on.props.children[1].props.children,
          file_name: contractDoc.file_name,
        })) || [];

      data = {
        contract_name: contractFormValues.info.contract["Title"],
        contract_type: mode.create
          ? state.data.selectedType === "Framework Agreement"
            ? "MSA"
            : state.data.selectedType
          : contract.contract_type,
        commercial_type: commercialType,
        supplier_id: mode.create
          ? state.data.supplier_id
          : contract.supplier_data._id,
        contract_status:
          buttonState === 1 ? "Contract-in-Draft" : "Contract-Submitted",
        workflow_status: buttonState === 1 ? "Draft" : "Submit",
        parent_type:
          breadcrumbs.length >= 3 &&
          breadcrumbs[breadcrumbs.length - 2].apiArgs.type,
        parent_cid:
          breadcrumbs.length >= 3 &&
          breadcrumbs[breadcrumbs.length - 2].apiArgs.contract_id,
        signed_on: new Date(),
        last_status_change_date: new Date(),
        created_by: getUser,
        start_date:
          contractFormValues.info.contract["Contract Start Date"] &&
          contractFormValues.info.contract["Contract Start Date"],
        end_date:
          contractFormValues.info.contract["Contract End Date"] &&
          contractFormValues.info.contract["Contract End Date"],
        current_owner: mode.create
          ? buttonState === 1
            ? getUser
            : state.data.workflow.workflow_metadata.Level1.approverId[0]
          : contract.current_owner,
        contract_metadata: { ...contractFormValues },
        commercial_data: processedCommercials,
        contract_template: mode.create
          ? state.data.contract_template
          : undefined,
        ratetable_id: rateTableData && rateTableData._id,
        workflow_id: mode.create
          ? state.data.workflow._id
          : contract.contract_workflow_data.workflow_id,
        checkedSd: checkedSd,
        checkedEd: checkedEd,
        rootData: rootData,
        author: userData && userData[0] && userData[0].id ? userData[0].id : "",
        author_name:
          userData && userData[0] && userData[0].first_name
            ? userData[0].first_name
            : "",
        updated_by:
          userData && userData[0] && userData[0].id
            ? userData[0].id
            : userData[0]._id
            ? userData[0]._id
            : "",
        comments: comments ? comments : [],
        contract_doc_details: contractDocDetails,
        docusign_envelope_id: "",
      };

      if (contractFile && contractFile.length > 0) {
        for (let i = 0; i < contractFile.length; i++) {
          uploadForm.append(
            "notesCommentsFiles",
            contractFile[i].originFileObj
          );
        }
      }

      if (contractDocFileList && contractDocFileList.length > 0) {
        for (let i = 0; i < contractDocFileList.length; i++) {
          uploadForm.append(
            "contractDocFiles",
            contractDocFileList[i].originFileObj
          );
        }
      }

      uploadForm.append("uploadForm", JSON.stringify(data));
    } catch (error) {
      console.error("Data Preparation Error: ", error);
      popupModalInstance.errorCodeModal(ModalMessage.CON_FORM.ERR_DataPrep);
      return;
    }

    const resMsg = `Contract Updated & ${
      buttonState === 1 ? "Saved" : "Submitted"
    } Successfully.`;

    // Calling API based on buttonState(1 = Save, 2 = Submit)
    try {
      if (buttonState === 1) {
        await handleFileDeletions();

        if (mode.create) {
          if (createdId === "") {
            createContractAPi(uploadForm).then((res) => {
              setCreatedId(res._id);
              handleSaveSuccess(
                mode.create ? state.data.title : contract.contract_name,
                ModalMessage.CC_Save
              );
            });
          } else {
            editContractAPi(createdId, uploadForm, dagID).then(() => {
              handleSaveSuccess(state.data.title, resMsg);
            });
          }
        } else if (mode.edit) {
          editContractAPi(state.contract_id, uploadForm, dagID).then(() => {
            handleSaveSuccess(contract.contract_name, resMsg);
          });
        }
      }

      if (buttonState === 2) {
        if (mode.create) {
          if (createdId === "") {
            popupModalInstance
              .infoModal(ModalMessage.CC_Submit)
              .then(async (userChoice) => {
                if (userChoice === "ok") {
                  await handleFileDeletions();
                  createContractAPi(uploadForm).then((res) => {
                    setCreatedId(res._id);
                    handleSubmitSuccess();
                  });
                }
                if (userChoice === "cancel") {
                  setSubmitLoader(false);
                }
              });
          } else {
            popupModalInstance
              .infoModal(ModalMessage.CC_Submit)
              .then(async (userChoice) => {
                if (userChoice === "ok") {
                  await handleFileDeletions();
                  editContractAPi(createdId, uploadForm, dagID).then(() => {
                    handleSubmitSuccess();
                  });
                }
                if (userChoice === "cancel") {
                  setSubmitLoader(false);
                }
              });
          }
        } else if (mode.edit) {
          popupModalInstance
            .infoModal(ModalMessage.CC_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                await handleFileDeletions();
                editContractAPi(state.contract_id, uploadForm, dagID).then(
                  () => {
                    handleSubmitSuccess();
                  }
                );
              }
              if (userChoice === "cancel") {
                setSubmitLoader(false);
              }
            });
        }
      }
    } catch (error) {
      console.error("Contract Form API Error: ", error);
      popupModalInstance.errorCodeModal(ModalMessage.CON_FORM.ERR_ConApiErr);
      return;
    }
  };

  const onFinishFailed = (formError) => {
    console.log("Error: ", formError);

    // Group form errors by main key
    const groupedErrors = formError.errorFields.reduce((acc, error) => {
      const [mainKey, subKey] = error.name;
      if (!acc[mainKey]) {
        acc[mainKey] = [];
      }
      acc[mainKey].push(subKey);
      return acc;
    }, {});

    const capitalizeFirstLetter = (str) =>
      str.charAt(0).toUpperCase() + str.slice(1);

    let commercialTableDataSource = JSON.parse(
      JSON.stringify(commercialRef.current)
    );

    // Check for incomplete commercial table
    const isUntouchedCommercialTable =
      commercialTableDataSource.length === 1 &&
      commercialTableDataSource[0].key === 0 &&
      commercialTableDataSource[0]["Resource Id"] === "RE_1" &&
      Object.keys(commercialTableDataSource[0]).length === 2;

    const hasIncompleteCommercialRow = commercialTableDataSource.some(
      (entry) => {
        // Check for an incomplete row (missing inherent fields or rate fields)
        const {
          "Resource Id": ResourceId,
          "Start Date": StartDate,
          "End Date": EndDate,
          Days,
          ...rateFields
        } = entry;

        const filledRateFields = Object.values(rateFields).filter(
          (value) => value !== null && value !== undefined
        );

        return (
          !ResourceId ||
          !StartDate ||
          !EndDate ||
          !Days ||
          (filledRateFields.length > 0 &&
            filledRateFields.length !== Object.keys(rateFields).length)
        );
      }
    );

    // If commercial table is incomplete, add a custom message to groupedErrors
    if (isUntouchedCommercialTable || hasIncompleteCommercialRow) {
      groupedErrors["commercialTable"] = ["Commercial Table"];
    }

    // Build error content
    const errorContent = (
      <div>
        <p>
          <strong>Please fill missing fields:</strong>
        </p>
        <div style={{ overflowY: "auto", maxHeight: 450, marginTop: 10 }}>
          {Object.entries(groupedErrors).map(([mainKey, subKeys], index) => (
            <div key={index} className="error-container">
              {mainKey === "commercialTable"
                ? "Commercial"
                : `About the ${capitalizeFirstLetter(mainKey)}`}
              <ul className="error-list">
                {subKeys.map((field, idx) => (
                  <li key={idx} className="error-item">
                    {capitalizeFirstLetter(field)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );

    // Display the error modal with the message
    popupModalInstance.warningModal(errorContent);
  };

  useEffect(() => {
    const menu =
      location === "pipeline" ? "Contract Pipeline" : "Create Contract";
    document.title = `${
      breadcrumbs[breadcrumbs.length - 1]?.title || menu
    } | ${menu} | Judo`;
  }, [location, breadcrumbs]);

  return (
    <>
      {(location === "createContractForm" || location === "pipeline") && (
        <TopHeader
          headerChange={header}
          feedRow={false}
          searchRow={false}
          menuKey={menuKey}
          sticky=""
        />
      )}
      <Layout
        id={
          location === "pipeline" || location === "createContractForm"
            ? "contract-pipeline-layout"
            : "contract-layout"
        }
      >
        {(!formLoader && contract) || (mode && mode.create) ? (
          <>
            {mode.create && (
              <Row align="middle" gutter={[7, 24]}>
                <Col className="vertical-align" span={4}>
                  <Card id="contract-info-card" bordered={false}>
                    <div style={{ padding: "0px 5px" }}>
                      <Row align="bottom" type="flex" style={{ marginTop: 2 }}>
                        <Col className="vertical-align" span={10}>
                          <span className="role-name-text">
                            {state.data.title}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
                <Col className="vertical-align" span={11}>
                  <Card id="contract-info-card" bordered={false}>
                    <div className="contract-status-container">
                      {[
                        {
                          label: "In Draft",
                          key: "Contract-in-Draft",
                          className: "draft",
                        },
                        {
                          label: "In Approval",
                          key: "Contract-Submitted",
                          className: "approval",
                        },
                        {
                          label: "In Signature",
                          key: "Contract-in-Signature",
                          className: "signature",
                        },
                        {
                          label: "Active",
                          key: "Contract-Active",
                          className: "active",
                        },
                        {
                          label: "Inactive",
                          key: "Contract-Inactive",
                          className: "inactive",
                        },
                      ].map((status) => {
                        const statusClass = mode.create
                          ? status.key === "Contract-in-Draft"
                            ? "status-green"
                            : "status-grey"
                          : contract?.contract_status === status.key
                          ? "status-green"
                          : "status-grey";

                        return (
                          <div
                            key={status.key}
                            className={`status-arrow ${status.className} ${statusClass}`}
                          >
                            <p>{status.label}</p>
                          </div>
                        );
                      })}

                      <Col className="text-end" span={6}>
                        <span id="contract-header-text">
                          Last Update On:&nbsp;&nbsp;
                          <span
                            style={{
                              fontWeight: 600,
                              color: "#000",
                              fontSize: 12,
                            }}
                          >
                            N/A
                          </span>
                        </span>
                      </Col>
                    </div>
                  </Card>
                </Col>
                <Col span={9}>
                  <Card id="contract-info-card" bordered={false}>
                    <Breadcrumbs tabBreadcrumbs={breadcrumbs} />
                  </Card>
                </Col>
              </Row>
            )}

            {(mode.view || mode.edit) && (
              <Row align="middle" gutter={[7, 24]}>
                <Col span={10}>
                  <Card id="contract-info-card" bordered={false}>
                    <Breadcrumbs tabBreadcrumbs={breadcrumbs} />
                  </Card>
                </Col>
                <Col className="vertical-align" span={14}>
                  <Card id="contract-info-card" bordered={false}>
                    <div className="contract-status-container">
                      {[
                        {
                          label: "Indraft",
                          key: "Contract-in-Draft",
                          className: "draft",
                        },
                        {
                          label: "In Approval",
                          key: "Contract-Submitted",
                          className: "approval",
                        },
                        {
                          label: "In Signature",
                          key: "Sent for Signature",
                          className: "signature",
                        },
                        {
                          label: "Active",
                          key: "Contract-in-Execution",
                          className: "active",
                        },
                        {
                          label: "Inactive",
                          key: "Contract-Inactive",
                          className: "inactive",
                        },
                      ].map((status) => {
                        const statusClass =
                          contract.contract_status === status.key
                            ? "status-green"
                            : "status-grey";

                        return (
                          <div
                            key={status.key}
                            className={`status-arrow ${status.className} ${statusClass}`}
                          >
                            <p>{status.label}</p>
                          </div>
                        );
                      })}

                      <Col className="text-end" span={6}>
                        <span id="contract-header-text">
                          Last Update On:&nbsp;&nbsp;
                          <span
                            style={{
                              fontWeight: 600,
                              color: "#000",
                              fontSize: 12,
                            }}
                          >
                            {!isEmptyObject(contract.contract_workflow_data)
                              ? moment(
                                  contract.contract_workflow_data.updated_on
                                ).format("DD/MM/YYYY")
                              : moment(contract.last_status_change_date).format(
                                  "DD/MM/YYYY"
                                )}
                          </span>
                        </span>
                      </Col>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
            <Row align="middle" style={{ marginTop: 8 }}>
              <Col span={24}>
                <Form
                  form={contractForm}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Tabs
                    id="contract-view-tab"
                    defaultActiveKey="1"
                    onChange={(key) => {
                      if (key === "1" && commercialType === "table") {
                        contractForm.setFieldValue(
                          ["contract", "Contract Value"],
                          getTotalCommercialDaysCosts(
                            costsRef.current,
                            "cost",
                            "table"
                          ).toLocaleString("en-us")
                        );
                      }
                      setContractTabKey(key);
                    }}
                    items={contractFormTabItems}
                    tabBarExtraContent={
                      mode.create || mode.edit ? (
                        <Row gutter={[8, 0]} style={{ marginTop: -8 }}>
                          <Col span={8}>
                            <CancelButton
                              onClick={() => {
                                if (location === "createContractForm") {
                                  setHeaderChanger({
                                    header: "contract",
                                    headerItem: "3",
                                    headerItemName: "contract",
                                  });
                                  navigate("/contract");
                                } else if (location === "pipeline") {
                                  setHeaderChanger({
                                    header: "contract",
                                    headerItem: "2",
                                    headerItemName: "contractPipeline",
                                  });
                                  navigate("/contract");
                                }
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <SaveButton
                              loading={saveLoader}
                              htmlType="submit"
                              onClick={() => setButtonState(1)}
                            />
                          </Col>
                          <Col span={8}>
                            <SubmitButton
                              loading={submitLoader}
                              htmlType="submit"
                              // disabled={!isDocumentUploaded}
                              onClick={() => setButtonState(2)}
                            />
                          </Col>
                        </Row>
                      ) : isApprover ? (
                        <Row gutter={[8, 0]} style={{ marginTop: -8 }}>
                          <Col span={8}>
                            <RejectButton
                              type="primary"
                              htmlType="submit"
                              onClick={() => {
                                setRejectModel(true);
                              }}
                              disabled={!contractFile}
                            />
                          </Col>
                          <Col span={8}>
                            <ReturnButton
                              type="primary"
                              htmlType="submit"
                              onClick={() => {
                                setReturnModel(true);
                              }}
                              disabled={!contractFile}
                            />
                          </Col>
                          <Col span={8}>
                            <ApproveButton
                              type="primary"
                              htmlType="submit"
                              onClick={() => {
                                setApproveModel(true);
                              }}
                              disabled={!contractFile}
                            />
                          </Col>

                          {returnModel && (
                            <Modal
                              title={
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image src={reject} preview={false} />{" "}
                                  &nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "var(--color-solid-darkestgrey)",
                                    }}
                                  >
                                    Confirmation for Contract Return
                                  </span>
                                </span>
                              }
                              styles={{ content: { borderRadius: 20 } }}
                              centered
                              open={returnModel}
                              onOk={() => setReturnModel(false)}
                              onCancel={() => setReturnModel(false)}
                              footer={null}
                            >
                              <div>
                                <Form
                                  className="create-role"
                                  onFinish={onReturn}
                                  onFinishFailed={onFailed}
                                  autoComplete="off"
                                >
                                  <Row gutter={[16, 8]}>
                                    <Col span={24} className="mt-10">
                                      <Form.Item
                                        name="description"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter comments!",
                                          },
                                        ]}
                                      >
                                        <TextArea
                                          placeholder="Return comments..."
                                          rows={4}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={[8, 0]}
                                    type="flex"
                                    align="middle"
                                    justify="center"
                                  >
                                    <Col span={7}>
                                      <CancelButton
                                        onClick={() => setReturnModel(false)}
                                      />
                                    </Col>
                                    <Col span={7}>
                                      <ReturnButton
                                        loading={contractApprovalLoader}
                                        htmlType="submit"
                                      />
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </Modal>
                          )}
                          {rejectModel && (
                            <Modal
                              title={
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image src={reject} preview={false} />
                                  &nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "var(--color-solid-darkestgrey)",
                                    }}
                                  >
                                    Confirmation for Contract Rejection
                                  </span>
                                </span>
                              }
                              styles={{ content: { borderRadius: 20 } }}
                              centered
                              open={rejectModel}
                              onOk={() => setRejectModel(false)}
                              onCancel={() => setRejectModel(false)}
                              footer={null}
                            >
                              <div>
                                <Form
                                  className="create-role"
                                  onFinish={onReject}
                                  onFinishFailed={onFailed}
                                  autoComplete="off"
                                >
                                  <Row gutter={[16, 8]}>
                                    <Col span={24} className="mt-10">
                                      <Form.Item
                                        name="description"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter comments!",
                                          },
                                        ]}
                                      >
                                        <TextArea
                                          placeholder="Rejection comments..."
                                          rows={4}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={[8, 0]}
                                    type="flex"
                                    align="middle"
                                    justify="center"
                                  >
                                    <Col span={7}>
                                      <CancelButton
                                        onClick={() => setRejectModel(false)}
                                      />
                                    </Col>
                                    <Col span={7}>
                                      <RejectButton
                                        htmlType="submit"
                                        loading={contractApprovalLoader}
                                      />
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </Modal>
                          )}
                          {approveModel && (
                            <Modal
                              title={
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image src={approve} preview={false} />{" "}
                                  &nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      color: "var(--color-solid-darkestgrey)",
                                    }}
                                  >
                                    Confirmation for Contract Approval
                                  </span>
                                </span>
                              }
                              styles={{ content: { borderRadius: 20 } }}
                              centered
                              open={approveModel}
                              onOk={() => setApproveModel(false)}
                              onCancel={() => setApproveModel(false)}
                              footer={null}
                            >
                              <div>
                                <Form
                                  className="create-role"
                                  onFinish={onApprove}
                                  onFinishFailed={onFailed}
                                  autoComplete="off"
                                >
                                  <Row gutter={[16, 8]}>
                                    <Col span={24} className="mt-10">
                                      <Form.Item
                                        name="description"
                                        labelCol={{ span: 24 }}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please enter comments!",
                                          },
                                        ]}
                                      >
                                        <TextArea
                                          placeholder="Approval comments..."
                                          rows={4}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row
                                    gutter={[8, 0]}
                                    type="flex"
                                    align="middle"
                                    justify="center"
                                  >
                                    <Col span={7}>
                                      <CancelButton
                                        onClick={() => setApproveModel(false)}
                                      />
                                    </Col>
                                    <Col span={7}>
                                      <ApproveButton
                                        htmlType="submit"
                                        loading={contractApprovalLoader}
                                      />
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </Modal>
                          )}
                        </Row>
                      ) : null
                    }
                  />
                </Form>
              </Col>
            </Row>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "var(--color-solid-white)",
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!errorMsg ? (
              <LoadingOutlined
                spin
                style={{ fontSize: 50, color: "var(--color-solid-darkerblue)" }}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    <span style={{ fontSize: 13, fontWeight: 600 }}>
                      {errorMsg}
                    </span>
                  </>
                }
              />
            )}
          </div>
        )}
        {authorSwitch && (
          <Modal
            className="con-modal"
            centered
            open={authorSwitch}
            onOk={() => setAuthorSwitch(false)}
            onCancel={() => {
              setAuthorSwitch(false);
              setAuthorSwitchLoad(false);
            }}
            closable={false}
            footer={null}
            width={750}
          >
            <div>
              <Form
                form={authorForm}
                name="author_change_form"
                onFinish={onChangeAuthor}
                onKeyDown={handleKeyDown}
                onFinishFailed={onFailedAuthorChange}
              >
                <Row
                  align="middle"
                  type="flex"
                  style={{ padding: "15px 20px 5px" }}
                >
                  <Col span={24} className="vertical-align">
                    <div className="div-head text-center">
                      <span>Author Change</span>
                    </div>
                  </Col>
                </Row>
                <hr className="mt-10" />
                <Row gutter={16}>
                  <Col xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="from_user"
                      label="FROM USER"
                      colon={false}
                      initialValue={contract && contract.created_by}
                    >
                      <Select
                        disabled
                        showSearch={true}
                        className=""
                        placeholder="choose user"
                        options={allUsers.map((user) => ({
                          key: user._id,
                          label: `${user.first_name} ${user.last_name}`,
                          value: user._id,
                        }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="to_user"
                      label="TO USER"
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: `Please Select To User`,
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        className=""
                        placeholder="choose to user"
                        options={allUsers
                          .filter((data) => data._id !== state.created_by)
                          .map((user) => ({
                            key: user._id,
                            label: `${user.first_name} ${user.last_name}`,
                            value: user._id,
                          }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  type="flex"
                  style={{ padding: "0px 20px 20px" }}
                  gutter={[8, 16]}
                  className="mt-10"
                >
                  <Col span={16} className="vertical-align"></Col>
                  <Col className="vertical-align" span={4}>
                    <Form.Item>
                      <CancelButton
                        disabled={authorSwitchLoad}
                        type="primary"
                        onClick={() => {
                          setAuthorSwitch(false);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="vertical-align" span={4}>
                    <Form.Item>
                      <SaveButton
                        loading={authorSwitchLoad}
                        type="primary"
                        onClick={(event) => {
                          authorForm.submit();
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        )}
        {compareModal && (
          <CompareDocs
            oldVersion={
              selectedRows.reduce(
                (min, row) => (row.key < min.key ? row : min),
                selectedRows[0]
              ).file_content
            }
            newVersion={
              selectedRows.reduce(
                (max, row) => (row.key > max.key ? row : max),
                selectedRows[0]
              ).file_content
            }
            setText1={setText1}
            setText2={setText2}
          />
        )}
      </Layout>
    </>
  );
};

export default ContractController;
