import { axiosInstance } from "../utils/common";

export const getAllGroupApi = () => {
  return axiosInstance
    .get("v1/groups")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllGroupPaginationApi = (
  page,
  limit,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/groups/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleGroupDetailApi = (groupId) => {
  return axiosInstance
    .get(`v1/groups/${groupId}/detail`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleGroupApi = (groupId) => {
  return axiosInstance
    .get(`v1/groups/${groupId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleGroupApiName = (groupName) => {
  return axiosInstance
    .post(`v1/groups/groupName`, { groupName: groupName })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createGroupAPi = (groupDetails) => {
  return axiosInstance
    .post("v1/groups", groupDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editGroupAPi = (groupId, updatedGroupDetails) => {
  return axiosInstance
    .patch(`v1/groups/${groupId}`, updatedGroupDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const deleteGroupApi = (groupId) => {
  return axiosInstance
    .delete(`v1/groups/${groupId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const addUserToGroupAPi = (groupId, updatedGroupDetails) => {
  return axiosInstance
    .patch(`v1/groups/${groupId}/add-user`, updatedGroupDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const removeUserFromGroupAPi = (groupId, updatedGroupDetails) => {
  return axiosInstance
    .patch(`v1/groups/${groupId}/remove-user`, updatedGroupDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getGroupByUser = (user) => {
  return axiosInstance
    .get(`v1/groups/get/by-user/${user}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllGroupFiltersApi = () => {
  return axiosInstance
    .get(`v1/groups/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
