import React from "react";
// import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../store/store";
import { Layout } from "antd"; //antd components
import TopHeader from "../components/Header";
import "../assets/style/contract.css";

function Obligation() {
  const { Content } = Layout;
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // useEffect(
  //   () =>
  //     setHeaderChanger({
  //       header: "home",
  //       headerItem: "1",
  //       headerItemName: "obligation",
  //     }),
  //   []
  // );
  let header = headerChanger.header;

  const menuKey = "4";

  return (
    <div className="gradient-bg obligation">
      <Layout className="header-layout">
        <TopHeader
          headerChange={header}
          feedRow={false}
          searchRow={false}
          menuKey={menuKey}
        />
        <Content>
          <div className="content-div"></div>
        </Content>
      </Layout>
    </div>
  );
}

export default Obligation;
