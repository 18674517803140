import { Tooltip } from "antd";

export const associationCol = [
  {
    title: "Associated Item",
    dataIndex: "associated_item",
    key: "associated_item",
    ellipsis: {
      showTitle: false,
    },
    render: (associated_item) => (
      <Tooltip placement="bottomLeft" title={associated_item}>
        {associated_item}
      </Tooltip>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: {
      showTitle: false,
    },
    render: (name) => (
      <Tooltip placement="bottomLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    title: "Association Date",
    dataIndex: "association_date",
    key: "association_date",
    ellipsis: {
      showTitle: false,
    },
    render: (association_date) => (
      <Tooltip placement="bottomLeft" title={association_date}>
        {association_date}
      </Tooltip>
    ),
  },
];

export const entityCol = [
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    ellipsis: {
      showTitle: false,
    },
    render: (action) => (
      <Tooltip placement="bottomLeft" title={action}>
        {action}
      </Tooltip>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: {
      showTitle: false,
    },
    render: (description) => (
      <Tooltip placement="bottomLeft" title={description}>
        {description}
      </Tooltip>
    ),
  },
  {
    title: "Updated By",
    dataIndex: "updated_by",
    key: "updated_by",
    ellipsis: {
      showTitle: false,
    },
    render: (updated_by) => (
      <Tooltip placement="bottomLeft" title={updated_by}>
        {updated_by}
      </Tooltip>
    ),
  },
  {
    title: "Updated On",
    dataIndex: "updated_on",
    key: "updated_on",
    ellipsis: {
      showTitle: false,
    },
    render: (updated_on) => (
      <Tooltip placement="bottomLeft" title={updated_on}>
        {updated_on}
      </Tooltip>
    ),
  },
];

export const CostAndDaysTotalTableCol = [
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "January",
    dataIndex: "jan",
    key: "jan",
  },
  {
    title: "February",
    dataIndex: "feb",
    key: "feb",
  },
  {
    title: "March",
    dataIndex: "mar",
    key: "mar",
  },
  {
    title: "April",
    dataIndex: "apr",
    key: "apr",
  },
  {
    title: "May",
    dataIndex: "may",
    key: "may",
  },
  {
    title: "June",
    dataIndex: "jun",
    key: "jun",
  },
  {
    title: "July",
    dataIndex: "jul",
    key: "jul",
  },
  {
    title: "August",
    dataIndex: "aug",
    key: "aug",
  },
  {
    title: "September",
    dataIndex: "sep",
    key: "sep",
  },
  {
    title: "October",
    dataIndex: "oct",
    key: "oct",
  },
  {
    title: "November",
    dataIndex: "nov",
    key: "nov",
  },
  {
    title: "December",
    dataIndex: "dec",
    key: "dec",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];
