import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom"; //router
import {
  Row,
  Col,
  Image,
  Badge,
  Button,
  Input,
  Menu,
  Dropdown,
  Avatar,
  Drawer,
  Divider,
  Empty,
} from "antd"; //antd components
import {
  BellFilled,
  SearchOutlined,
  CloseOutlined,
  CheckOutlined,
  ExperimentTwoTone,
  PlusOutlined,
  NotificationTwoTone,
} from "@ant-design/icons"; //antd icons
import { useRecoilState } from "recoil";
import CryptoJS from "crypto-js";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
  NotificationListAtom,
  NotificationUnreadAtom,
  TabChangerAtom,
  isLiteAtom,
} from "../store/store";
import "../assets/style/header.css";
import judoIcon from "../assets/images/icons/judo_logo.svg";
import accountHome from "../assets/images/icons/account_home.svg";
import passwordKey from "../assets/images/icons/password_key.svg";
import logoutHome from "../assets/images/icons/logout_home.svg";
import MenuBackArrow from "../assets/images/icons/menu-arrow-right.svg";
import SubMenuBg from "../assets/images/icons/header-curve.svg";
import ConfigFile from "../../src/ConfigFile.js";
import dayjs from "dayjs";
import {
  notificationSocket,
  startNotificationListener,
} from "../utils/notificationSocket";
import {
  createNotificationApi,
  markNotificationAsReadApi,
  getNotificationsApi,
  removeNotificationApi,
  markAllNotificationsAsReadApi,
  removeAllNotificationsApi,
} from "../api/notificationsApi";
import { useNotification } from "./NotificationContext";
import GlobalSearch from "./GlobalSearch";

const Header = ({
  headerChange,
  menuKey,
  eventsSeeMore,
  seeMoreDisplay,
  eventsSeeMoreStatus,
  searchView,
  selectedPrev,
  logo = true,
  searchRow = true,
  feedRow = true,
  sticky = "sticky",
  // contractSearchRow = false,
  onMenuClick,
}) => {
  let history = useNavigate(); //router
  const [displayBack, setDisplayBack] = useState("none");
  const [searchToClear, setSearchToClear] = useState(null);
  const [systemSetupAccess, setSystemSetupAccess] = useState();
  const [contractAccess, setContractAccess] = useState();
  const [userRoleAccess, setUserRoleAccess] = useState();
  const roleUser = useRecoilState(RoleAccessAtom); //global state
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [lite] = useRecoilState(isLiteAtom);
  // console.log("userData", userData);
  // console.log("liteAtom", lite);

  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [createContractTabClick, setCreateContractTabClick] =
    useRecoilState(TabChangerAtom);
  const [clickedMore, setClickedMore] = useState(false);
  const [clickedSearch, setClickedSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const { openNotification } = useNotification();
  const [notifications, setNotifications] =
    useRecoilState(NotificationListAtom);
  const [unreadCount, setUnreadCount] = useRecoilState(NotificationUnreadAtom);
  const [headerInitialized, setHeaderInitialized] = useState(() => {
    return sessionStorage.getItem("headerInitialized") === "true";
  });
  const [expandedNotifications, setExpandedNotifications] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [executeSearch, setExecuteSearch] = useState(false);

  const roleAccess = roleUser && roleUser[0] && roleUser[0].page_access;

  const SECRET_KEY = "mysecretkey";
  // let secret = "sessionSecret_key";
  // let encryptValue = encrypt(JSON.stringify(headerChanger), secret);

  const encryptData = (name, data) => {
    // const encryptedKey = CryptoJS.AES.encrypt(
    //   JSON.stringify(data),
    //   SECRET_KEY
    // ).toString();

    const encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    sessionStorage.setItem(name, encryptedValue);
  };

  const hideFeed = () => {
    setDisplayBack("flex");
  };

  const showFeed = () => {
    console.log(searchToClear);
    setSearchToClear(null);
    setDisplayBack("none");
    seeMoreDisplay(false);
    eventsSeeMoreStatus(false);
    searchView(false, null);
  };

  const onChangeSearch = (e) => {
    console.log("e.target.value", e.target.value);
    setSearchToClear(e.target.value);
    if (!e.target.value) {
      searchView(true, null);
    } else {
      searchView(true, e.target.value);
    }
  };

  // const onSearch = (e)=>{
  //   if(!e){
  //     searchView(true, null)

  //   }
  //   else{
  //     searchView(true, e)
  //   }
  // }

  useEffect(() => {
    if (eventsSeeMore) {
      hideFeed();
      searchView(false, searchToClear);
      seeMoreDisplay(true);
    }
  }, [eventsSeeMore]);

  useEffect(() => {
    if (selectedPrev) {
      setSearchToClear(selectedPrev);
      searchView(true, selectedPrev);
    }
  }, [selectedPrev]);

  const hasAnyPermissionTrue = (permissions) => {
    for (const key in permissions) {
      if (permissions[key].view || permissions[key].create) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (
      roleUser &&
      roleUser[0] &&
      roleUser[0].page_access &&
      roleUser[0].page_access.system_setup
    ) {
      setSystemSetupAccess(
        Object.values(roleUser[0].page_access.system_setup || {})
      );
      setContractAccess(Object.values(roleUser[0].page_access.contracts || {}));
      setUserRoleAccess(
        roleUser &&
          roleUser[0] &&
          roleUser[0].role_name &&
          roleUser[0].role_name === "Admin"
          ? true
          : false
      );
    }
  }, []);

  const logout = () => {
    // const keysToRemove = ["USER_TOKENS", "recoil-persist", "loggedInUser"];
    // keysToRemove.forEach((key) => {
    //   if (localStorage.getItem(key)) {
    //     localStorage.removeItem(key);
    //     console.log(`Item '${key}' removed from localStorage.`);
    //   } else {
    //     console.log(`Item '${key}' not found in localStorage.`);
    //   }
    // });
    notificationSocket.emit("logout", { userMail: userData.email });
    localStorage.clear();
    sessionStorage.clear();
    history("/login");
  };

  useEffect(() => {
    // window.sessionStorage.setItem("headerKey", JSON.stringify(headerChanger));
    encryptData("headerKey", headerChanger);
  }, [headerChanger]);

  const items = [
    {
      key: "1",
      label: (
        <span
          style={{ color: "var(--color-solid-darkestgrey)" }}
          onClick={() => history("/reset-password")}
        >
          Change Password
        </span>
      ),
      icon: (
        <Image
          src={passwordKey}
          preview={false}
          style={{ width: 18, paddingBottom: 3 }}
        />
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={logout}
          style={{
            color: "var(--color-solid-darkestgrey)",
            fontWeight: 300,
            display: "block",
            width: "100%",
          }}
        >
          Log Out
        </span>
      ),
      icon: (
        <Image
          src={logoutHome}
          onClick={logout}
          preview={false}
          style={{ width: 18, paddingBottom: 3 }}
        />
      ),
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cleanup = startNotificationListener(
      userData,
      setNotifications,
      setUnreadCount,
      openNotification
    );

    return cleanup;
  }, [openNotification]);

  useEffect(() => {
    if (!headerInitialized) {
      fetchNotifications();
      setHeaderInitialized(true);
      sessionStorage.setItem("headerInitialized", "true");
    }

    // Reset the headerInitialized flag to false before the page reloads
    const handleBeforeUnload = () => {
      sessionStorage.setItem("headerInitialized", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup: Remove the event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [headerInitialized]);

  const fetchNotifications = async () => {
    const notificationsData = await getNotificationsApi(userData.id);
    setNotifications(notificationsData);

    const newUnreadCount = notificationsData.reduce((count, notification) => {
      return notification.read ? count : count + 1;
    }, 0);
    setUnreadCount(newUnreadCount);
  };

  const handleNotificationClick = (notification) => {
    if (!notification.read) {
      handleMarkAsRead(notification.id);
      setUnreadCount((prevUnreadCount) => {
        if (prevUnreadCount > 0) return prevUnreadCount - 1;
        else return prevUnreadCount;
      });
    }
    if (notification.path) {
      if (notification.path.data) {
        setHeaderChanger(notification.path.header);
        history(notification.path.url, {
          state: notification.path.data,
        });
      } else if (notification.path.url) {
        setHeaderChanger(notification.path.header);
        history(notification.path.url);
      }
    }
  };

  // Mark the notification as read
  const handleMarkAsRead = async (notificationId) => {
    await markNotificationAsReadApi(notificationId);
    setNotifications((prevNotifications) => {
      const index = prevNotifications.findIndex(
        (notify) => notify.id === notificationId
      );
      if (index !== -1) {
        const updatedNotifications = [...prevNotifications];
        updatedNotifications[index] = {
          ...updatedNotifications[index],
          read: true,
        };
        return updatedNotifications;
      }
      return prevNotifications;
    });
  };

  // Mark all notifications as read
  const handleMarkAllNotificationsAsRead = async () => {
    await markAllNotificationsAsReadApi(userData.id);

    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
    setNotifications(updatedNotifications);
  };

  // Remove notification
  const handleDeleteNotification = async (notificationId) => {
    await removeNotificationApi(notificationId);

    setNotifications((prevNotifications) => {
      return prevNotifications.filter(
        (notification) => notification.id !== notificationId
      );
    });
  };

  // Remove all notifications
  const handleDeleteAllNotifications = async () => {
    await removeAllNotificationsApi(userData.id);
    setNotifications([]);
  };

  // Update unread notifications count
  const handleCloseNotificationCenter = () => {
    const updatedUnreadCount = notifications.reduce((count, notification) => {
      return notification.read ? count : count + 1;
    }, 0);
    setUnreadCount(updatedUnreadCount);
    setExpandedNotifications({});
    onClose();
  };

  // Check if all notifications are read
  const allNotificationsRead = () => {
    return notifications.every((notification) => notification.read);
  };

  // Function to handle click on "Show more" or "Show less"
  const handleToggleExpand = (notifyId) => {
    setExpandedNotifications((prevState) => ({
      ...prevState,
      [notifyId]: !prevState[notifyId],
    }));
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      setExecuteSearch(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    setExecuteSearch(false);
  }, [headerChanger]);

  return (
    <div id="header" className={sticky}>
      <header className="App-header">
        {headerChanger.headerItemName !== "home" && (
          <>
            <Image
              src={SubMenuBg}
              preview={false}
              rootClassName={`${
                headerChange === "contract"
                  ? "sub-menu-bg-contract"
                  : headerChange === "contractAuthority"
                  ? "sub-menu-bg-con-auth"
                  : headerChange === "commercial"
                  ? "sub-menu-bg-commercial"
                  : headerChange === "actionManagement"
                  ? "sub-menu-bg-act-man"
                  : headerChange === "systemSetup"
                  ? "sub-menu-bg-sys-set"
                  : headerChange === "reports"
                  ? "sub-menu-bg-reports"
                  : headerChange === "userManagement"
                  ? "sub-menu-bg-user-man"
                  : "sub-menu-bg"
              } ${headerChange === "home" ? "hidden" : "left-slide"} ${
                headerChange === "home" ? "right-slide" : ""
              }`}
            />
            <Image
              src={SubMenuBg}
              preview={false}
              rootClassName={`sub-menu-bg-extra ${
                headerChange === "home" ? "hidden" : "left-slide"
              } ${headerChange === "home" ? "right-slide" : ""}`}
            />
          </>
        )}

        <Row
          gutter={[24, 0]}
          className="header-row-new"
          align="middle"
          type="flex"
        >
          <Col span={24} className="header-new">
            <Col span={1}>
              <NavLink
                to="/user-dashboard"
                onClick={() => {
                  setSearchQuery("");
                  setExecuteSearch(false);
                  setClickedSearch(false);
                  setHeaderChanger({
                    header: "home",
                    headerItem: "1",
                    headerItemName: "home",
                  });
                  document.title = "Dashboard | Judo";
                }}
              >
                <img
                  className="home-icon"
                  src={
                    headerChanger.headerItemName === "home"
                      ? judoIcon
                      : judoIcon
                  }
                />
              </NavLink>
            </Col>

           {headerChange === "home" && (
              <Col className="header-menu-items">
                {hasAnyPermissionTrue(roleAccess.contracts) && (
                  <NavLink
                    to="/contract"
                    onClick={() =>
                      setHeaderChanger({
                        header: "contract",
                        headerItem: hasAnyPermissionTrue({
                          contract_tree: roleAccess.contracts.contract_tree,
                        })
                          ? "1"
                          : hasAnyPermissionTrue({
                              contract_pipeline:
                                roleAccess.contracts.contract_pipeline,
                            })
                          ? "2"
                          : hasAnyPermissionTrue({
                              create_contract:
                                roleAccess.contracts.create_contract,
                            })
                          ? "3"
                          : "4",
                        headerItemName: "contract",
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItemName === "contract"
                          ? "menu-label-back"
                          : headerChanger.headerItemName !==
                            headerChanger.header
                          ? "menu-label-back-home"
                          : "menu-label-home"
                      }
                    >
                      {ConfigFile.contracts}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue(roleAccess.contract_authority) &&
                  !lite && (
                    <NavLink
                      to={
                        hasAnyPermissionTrue({
                          clause_library:
                            roleAccess.contract_authority.clause_library,
                        })
                          ? "/contract_authority/clause-library"
                          : "/contract_authority/template"
                      }
                      onClick={() =>
                        setHeaderChanger({
                          header: "contractAuthority",
                          headerItem: hasAnyPermissionTrue({
                            clause_library:
                              roleAccess.contract_authority.clause_library,
                          })
                            ? "1"
                            : "2",
                          headerItemName: "contractAuthority",
                        })
                      }
                    >
                      <span
                        className={
                          headerChanger.headerItemName === "contractAuthority"
                            ? "menu-label-back"
                            : headerChanger.headerItemName !==
                              headerChanger.header
                            ? "menu-label-back-home"
                            : "menu-label-home"
                        }
                      >
                        {ConfigFile.contractAuthority}
                      </span>
                    </NavLink>
                  )}

                {!lite && (
                  <NavLink
                    to="/commercial"
                    onClick={() =>
                      setHeaderChanger({
                        header: "commercial",
                        headerItem: "1",
                        headerItemName: "commercial",
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItemName === "commercial"
                          ? "menu-label-back"
                          : headerChanger.headerItemName !==
                            headerChanger.header
                          ? "menu-label-back-home"
                          : "menu-label-home"
                      }
                    >
                      {ConfigFile.commercial}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue(roleAccess.action_management) &&
                  !lite && (
                    <NavLink
                      to="/action-management"
                      onClick={() =>
                        setHeaderChanger({
                          header: "actionManagement",
                          headerItem: hasAnyPermissionTrue({
                            root_action:
                              roleAccess.action_management.root_action,
                          })
                            ? "1"
                            : "2",
                          headerItemName: "actionManagement",
                        })
                      }
                    >
                      <span
                        className={
                          headerChanger.headerItemName === "actionManagement"
                            ? "menu-label-back"
                            : headerChanger.headerItemName !==
                              headerChanger.header
                            ? "menu-label-back-home"
                            : "menu-label-home"
                        }
                      >
                        {ConfigFile.actionManagement}
                      </span>
                    </NavLink>
                  )}

                {hasAnyPermissionTrue(roleAccess.system_setup) && (
                  <NavLink
                    to="/system-setup"
                    onClick={() =>
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: hasAnyPermissionTrue({
                          supplier_setup:
                            roleAccess.system_setup.supplier_setup,
                          legal_entity_setup:
                            roleAccess.system_setup.legal_entity_setup,
                          cost_center_setup:
                            roleAccess.system_setup.cost_center_setup,
                        })
                          ? "1"
                          : "2",
                        headerItemName: "systemSetup",
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItemName === "systemSetup"
                          ? "menu-label-back"
                          : headerChanger.headerItemName !==
                            headerChanger.header
                          ? "menu-label-back-home"
                          : "menu-label-home"
                      }
                    >
                      {ConfigFile.systemSetup}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue(roleAccess.reports) && (
                  <NavLink
                    to="/reports"
                    onClick={() =>
                      setHeaderChanger({
                        header: "reports",
                        headerItem: hasAnyPermissionTrue({
                          build_report: roleAccess.reports.build_report,
                        })
                          ? "1"
                          : "2",
                        headerItemName: "reports",
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItemName === "reports"
                          ? "menu-label-back"
                          : headerChanger.headerItemName !==
                            headerChanger.header
                          ? "menu-label-back-home"
                          : "menu-label-home"
                      }
                    >
                      {ConfigFile.reports}
                    </span>
                  </NavLink>
                )}

                <NavLink
                  to="/user-management"
                  onClick={() =>
                    setHeaderChanger({
                      header: "userManagement",
                      headerItem: "1",
                      headerItemName: "userManagement",
                    })
                  }
                >
                  {userRoleAccess && (
                    <span
                      className={
                        headerChanger.headerItemName === "userManagement"
                          ? "menu-label-back"
                          : headerChanger.headerItemName !==
                            headerChanger.header
                          ? "menu-label-back-home"
                          : "menu-label-home"
                      }
                    >
                      {ConfigFile.userManagement}
                    </span>
                  )}
                </NavLink>
              </Col>
            )}

            {headerChange === "contract" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">{ConfigFile.contracts}</span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: headerChanger.headerItem,
                        headerItemName: headerChanger.headerItemName,
                        stateSetter: headerChanger.stateSetter,
                      })
                    }
                  />
                </div>

                {hasAnyPermissionTrue({
                  contract_tree: roleAccess.contracts.contract_tree,
                }) && (
                  <NavLink
                    to="/contract"
                    onClick={() =>
                      setHeaderChanger({
                        header: "contract",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "1"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.contractTree}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue({
                  view_contract: roleAccess.contracts.view_contract,
                }) && (
                  <NavLink
                    to="/contract"
                    onClick={() =>
                      setHeaderChanger({
                        header: "contract",
                        headerItem: "4",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "4"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.contractList}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue({
                  contract_pipeline: roleAccess.contracts.contract_pipeline,
                }) && (
                  <NavLink
                    to="/contract"
                    onClick={() =>
                      setHeaderChanger({
                        header: "contract",
                        headerItem: "2",
                        headerItemName: "contractPipeline",
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "2"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.contractPipeline}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue({
                  create_contract: roleAccess.contracts.create_contract,
                }) && (
                  <NavLink
                    to="/contract"
                    onClick={() => {
                      setCreateContractTabClick((prev) => prev + 1);
                      setHeaderChanger({
                        header: "contract",
                        headerItem: "3",
                        headerItemName: headerChanger.headerItemName,
                      });
                    }}
                  >
                    <span
                      className={
                        headerChanger.headerItem === "3"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.createContract}
                    </span>
                  </NavLink>
                )}
              </Col>
            )}

            {headerChange === "contractAuthority" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">
                    {ConfigFile.contractAuthority}
                  </span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: headerChanger.headerItem,
                        headerItemName: headerChanger.headerItemName,
                        stateSetter: headerChanger.stateSetter,
                      })
                    }
                  />
                </div>

                {hasAnyPermissionTrue({
                  clause_library: roleAccess.contract_authority.clause_library,
                }) && (
                  <NavLink
                    to="/contract_authority/clause-library"
                    onClick={() =>
                      setHeaderChanger({
                        header: "contractAuthority",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "1"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.clauseLibrary}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue({
                  templates: roleAccess.contract_authority.templates,
                }) && (
                  <NavLink
                    to="/contract_authority/template"
                    onClick={() =>
                      setHeaderChanger({
                        header: "contractAuthority",
                        headerItem: "2",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "2"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.Template}
                    </span>
                  </NavLink>
                )}
              </Col>
            )}

            {headerChange === "commercial" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">{ConfigFile.commercial}</span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  />
                </div>
                <NavLink
                  to="/commercial"
                  onClick={() =>
                    setHeaderChanger({
                      header: "commercial",
                      headerItem: "1",
                      headerItemName: headerChanger.headerItemName,
                    })
                  }
                >
                  <span
                    className={
                      headerChanger.headerItem === "1"
                        ? "menu-label-active"
                        : "menu-label"
                    }
                  >
                    {ConfigFile.spendOverview}
                  </span>
                </NavLink>
                <NavLink
                  to="/commercial"
                  onClick={() =>
                    setHeaderChanger({
                      header: "commercial",
                      headerItem: "2",
                      headerItemName: headerChanger.headerItemName,
                    })
                  }
                >
                  <span
                    className={
                      headerChanger.headerItem === "2"
                        ? "menu-label-active"
                        : "menu-label"
                    }
                  >
                    {ConfigFile.contractSetup}
                  </span>
                </NavLink>
                <NavLink
                  to="/commercial"
                  onClick={() =>
                    setHeaderChanger({
                      header: "commercial",
                      headerItem: "3",
                      headerItemName: headerChanger.headerItemName,
                    })
                  }
                >
                  <span
                    className={
                      headerChanger.headerItem === "3"
                        ? "menu-label-active"
                        : "menu-label"
                    }
                  >
                    {ConfigFile.invoicingView}
                  </span>
                </NavLink>
              </Col>
            )}

            {headerChange === "actionManagement" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">
                    {ConfigFile.actionManagement}
                  </span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                        stateSetter: headerChanger.stateSetter,
                      })
                    }
                  />
                </div>

                {hasAnyPermissionTrue({
                  root_action: roleAccess.action_management.root_action,
                }) && (
                  <NavLink
                    to="/action-management"
                    onClick={() =>
                      setHeaderChanger({
                        header: "actionManagement",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "1"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.rootAction}
                    </span>
                  </NavLink>
                )}
                {hasAnyPermissionTrue({
                  action_instance: roleAccess.action_management.action_instance,
                }) && (
                  <NavLink
                    to="/action-management"
                    onClick={() =>
                      setHeaderChanger({
                        header: "actionManagement",
                        headerItem: "2",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "2"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.actionInstance}
                    </span>
                  </NavLink>
                )}
              </Col>
            )}

            {headerChange === "systemSetup" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">{ConfigFile.systemSetup}</span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: headerChanger.headerItem,
                        headerItemName: headerChanger.headerItemName,
                        stateSetter: headerChanger.stateSetter,
                      })
                    }
                  />
                </div>

                {hasAnyPermissionTrue({
                  supplier_setup: roleAccess.system_setup.supplier_setup,
                  legal_entity_setup:
                    roleAccess.system_setup.legal_entity_setup,
                  cost_center_setup: roleAccess.system_setup.cost_center_setup,
                }) && (
                  <NavLink
                    to="/system-setup"
                    onClick={() =>
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "1"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.organizationSetup}
                    </span>
                  </NavLink>
                )}

                {hasAnyPermissionTrue({
                  ratecard_setup: roleAccess.system_setup.ratecard_setup,
                  fx_setup: roleAccess.system_setup.fx_setup,
                  pricing_profile: roleAccess.system_setup.pricing_profile,
                  invoicing_profile: roleAccess.system_setup.invoicing_profile,
                  workflow: roleAccess.system_setup.workflow,
                }) && (
                  <NavLink
                    to="/system-setup"
                    onClick={() =>
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "2",
                        headerItemName: headerChanger.headerItemName,
                      })
                    }
                  >
                    <span
                      className={
                        headerChanger.headerItem === "2"
                          ? "menu-label-active"
                          : "menu-label"
                      }
                    >
                      {ConfigFile.contract_Setup}
                    </span>
                  </NavLink>
                )}
              </Col>
            )}

            {headerChange === "reports" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">{ConfigFile.reports}</span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                        stateSetter: headerChanger.stateSetter,
                      })
                    }
                  />
                </div>
              </Col>
            )}

            {headerChange === "userManagement" && (
              <Col className="header-menu-items">
                <div className="back-menu">
                  <span className="menu-border">
                    {ConfigFile.userManagement}
                  </span>
                  <Image
                    rootClassName="menu-back-arrow"
                    src={MenuBackArrow}
                    preview={false}
                    onClick={() =>
                      setHeaderChanger({
                        header: "home",
                        headerItem: "1",
                        headerItemName: headerChanger.headerItemName,
                        stateSetter: headerChanger.stateSetter,
                      })
                    }
                  />
                </div>
              </Col>
            )}

            <Col className="header-right-align">
              <Col className="search-align">
                <Input
                  placeholder="Search"
                  className={
                    clickedSearch ? "all-search-clicked" : "all-search"
                  }
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  allowClear={!clickedSearch ? false : true}
                  prefix={
                    <SearchOutlined
                      style={{
                        color: "#fff",
                        width: 20,
                        height: 30,
                      }}
                      onClick={() => setClickedSearch(!clickedSearch)}
                    />
                  }
                  onPressEnter={() => {
                    setClickedSearch(!clickedSearch);
                    setExecuteSearch(true);
                  }}
                />
              </Col>
              <Col
                className="vertical-align button-align"
                style={{ paddingLeft: "25px", paddingRight: "25px" }}
              >
                <div className="notificationIcon-new">
                  <Badge
                    className="notification-badge"
                    overflowCount={99}
                    count={unreadCount}
                    title="Unread Notifications"
                    onClick={showDrawer}
                  >
                    <BellFilled className="notify-icon" onClick={showDrawer} />
                  </Badge>
                  <Drawer
                    id="notification-center"
                    title="Notifications Center"
                    width={400}
                    closable={true}
                    onClose={handleCloseNotificationCenter}
                    open={open}
                    extra={
                      <div style={{ display: "flex" }}>
                        <Button
                          type="primary"
                          className="mark-read-btn"
                          disabled={
                            notifications.length === 0 || allNotificationsRead()
                          }
                          onClick={handleMarkAllNotificationsAsRead}
                        >
                          Mark All Read
                        </Button>
                        <Button
                          icon={<ExperimentTwoTone />}
                          style={{
                            height: 35,
                            width: 35,
                            fontSize: 12,
                            fontWeight: 600,
                            color: "var(--color-solid-darkerblue)",
                            borderRadius: 25,
                            marginLeft: 10,
                          }}
                          onClick={() => history("/test/playground")}
                        />
                      </div>
                    }
                  >
                    {notifications.length === 0 && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>All Caught Up!</span>}
                      ></Empty>
                    )}
                    {notifications &&
                      notifications.map((notify) => {
                        const maxWidth = 50;
                        const words = notify.content.split(" ");
                        let contentLines = [];
                        let line = "";
                        words.forEach((word) => {
                          const potentialLine = `${line} ${word}`.trim();
                          if (potentialLine.length <= maxWidth) {
                            line = potentialLine;
                          } else {
                            contentLines.push(line + " ");
                            line = word;
                          }
                        });
                        if (line) {
                          contentLines.push(line);
                        }

                        const moreThanTwoLines = contentLines.length > 2;
                        const isExpanded =
                          expandedNotifications[notify.id] || false;

                        return (
                          <div
                            className={
                              notify.read ? "notification-read" : "notification"
                            }
                            onClick={() => handleNotificationClick(notify)}
                          >
                            <Row>
                              <Col span={24}>
                                <Row type="flex" align="middle">
                                  <Col span={!notify.read ? 20 : 22}>
                                    <span
                                      className="notification-title"
                                      title={`${notify.name} - ${notify.tab}`}
                                    >
                                      {notify.name} &nbsp; &#8226; &nbsp;{" "}
                                      {notify.tab}
                                    </span>
                                  </Col>
                                  {!notify.read && (
                                    <Col span={2} align="center">
                                      <CheckOutlined
                                        className="notification-action-icon"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleMarkAsRead(notify.id);
                                        }}
                                      />
                                    </Col>
                                  )}
                                  <Col span={2} align="right">
                                    <CloseOutlined
                                      className="notification-action-icon"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDeleteNotification(notify.id);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Divider className="notification-divider" />
                                <div className="notification-content">
                                  {!isExpanded && (
                                    <>
                                      {contentLines.map((line, index) => {
                                        if (index < 2) {
                                          if (index === 1 && moreThanTwoLines) {
                                            return <span>{line}...</span>;
                                          } else {
                                            return <span>{line}</span>;
                                          }
                                        }
                                      })}
                                      <br />
                                    </>
                                  )}
                                  {isExpanded && (
                                    <>
                                      {contentLines.map((line, index) => (
                                        <span>{line}</span>
                                      ))}
                                      <br />
                                    </>
                                  )}
                                </div>
                              </Col>
                              <Col span={12} className="mt-10">
                                <span className="notification-time">
                                  {dayjs(notify.time).format(
                                    "DD MMMM YYYY, hh:mm a"
                                  )}
                                </span>
                              </Col>
                              {moreThanTwoLines && (
                                <Col span={12} align="right" className="mt-10">
                                  <span
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleToggleExpand(notify.id);
                                    }}
                                    className="notification-expand-toggle"
                                  >
                                    {!isExpanded ? "Show more" : "Show less"}
                                  </span>
                                </Col>
                              )}
                            </Row>
                          </div>
                        );
                      })}
                    {notifications.length !== 0 && (
                      <div className="clear-notify-align">
                        <Button
                          className="clear-notify-btn"
                          type="primary"
                          icon={<CloseOutlined />}
                          onClick={handleDeleteAllNotifications}
                        />
                      </div>
                    )}
                  </Drawer>
                </div>
              </Col>
              <Col className="enmatter-align">
                <Dropdown
                  className="more-menu"
                  menu={{ items }}
                  placement="bottomRight"
                  trigger={["click"]}
                  onOpenChange={() => setClickedMore(false)}
                >
                  <span onClick={() => setClickedMore(!clickedMore)}>
                    {userData.profile_picture ? (
                      <Avatar
                        size={36}
                        src={<img src={userData.profile_picture} />}
                        style={{
                          boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.15)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <Image
                        src={accountHome}
                        preview={false}
                        style={{ width: 35, height: 35, cursor: "pointer" }}
                      />
                    )}
                  </span>
                </Dropdown>
              </Col>
            </Col>
          </Col>
        </Row>

        {searchQuery.length !== 0 && executeSearch && (
          <GlobalSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        )}

        <Row
          className="search-section"
          gutter={[24, 0]}
          type="flex"
          align="middle"
        >
          {searchRow && (
            <>
              <Col span={12} align="left" className="dash-welcome mt-20 mb-20">
                <span className="dash-welcome-holder">
                  Welcome{" "}
                  <span className="dash-welcome-user">
                    {userData.first_name.charAt(0).toUpperCase() +
                      userData.first_name.slice(1)}
                    !
                  </span>
                </span>
              </Col>
              <Col
                span={12}
                align="right"
                className="dash-last-login mt-20 mb-20"
              >
                <span className="dash-last-login-holder">
                  Last Login:{" "}
                  <span className="dash-last-login-date">
                    {!userData.last_login
                      ? "- - - - - -"
                      : dayjs(userData.last_login).format("DD MMM YYYY, HH:mm")}
                  </span>
                </span>
              </Col>
              {/* <Col
                xs={24}
                sm={24}
                md={24}
                lg={{ span: 3, offset: offset && offset > 0 ? 0 : 6 }}
                xl={{ span: 3, offset: offset && offset > 0 ? 0 : 6 }}
                className="vertical-align search-back-column"
              >
                <LeftOutlined
                  onClick={showFeed}
                  className="searchBack"
                  style={{ display: displayBack, justifyContent: "center" }}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                className="vertical-align search-input"
              >
                <Input
                  onFocus={() => {
                    hideFeed();
                    searchView(true, searchToClear);
                  }}
                  value={searchToClear}
                  onChange={onChangeSearch}
                  placeholder="Search"
                  className="header-search"
                />
              </Col> */}
            </>
          )}
        </Row>
      </header>
    </div>
  );
};

export default Header;
