import { axiosInstance } from "../utils/common";

// create cost center
export const costCenterCreateApi = (costCenterData) => {
  return axiosInstance
    .post("v1/cc", costCenterData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// list cost center
export const listCostCenter = () => {
  return axiosInstance
    .get("v1/cc")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleCostCenterDataApi = (costCenterId) => {
  return axiosInstance
    .get(`v1/cc/single/${costCenterId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const costCenterOnlyApi = () => {
  return axiosInstance
    .get(`v1/cc/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// cost center Pagination
export const CostCenterPagination = (
  page,
  limit,
  searchVal,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/cc/pagination/${searchVal}?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// edit supplier
export const editCostCenterApi = (costCenterId, costCenterDetail) => {
  return axiosInstance
    .patch(`v1/cc/${costCenterId}`, costCenterDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((e) => console.log("e", e));
};

// delete cost center by ID
export const deleteCostCenterApi = (costCenterId) => {
  console.log("costCenterId", costCenterId);
  return axiosInstance
    .delete(`v1/cc/${costCenterId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// get cost center by Legal Entity ID
export const getCostCenterByLeIdApi = (leId) => {
  return axiosInstance
    .get(`v1/cc/get/cc/${leId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete legal entity by ID
export const deleteCCApi = (ccId) => {
  return axiosInstance
    .delete(`v1/cc/${ccId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllCostFiltersApi = () => {
  return axiosInstance
    .get(`v1/cc/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
