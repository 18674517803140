import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Form,
  Input,
  Select,
  Table,
  Space,
  DatePicker,
  Pagination,
  message,
  Checkbox,
  Button,
} from "antd";
import moment from "moment";
import {
  DownOutlined,
  UpOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import mail from "../../../assets/images/icons/mark_as_unread.svg";
import { OkButton } from "../../GlobalButton";
import UpArrow from "../../../assets/images/icons/arrow_up_icon.svg";
import DownArrow from "../../../assets/images/icons/arrow_down_icon.svg";
import { suppliersTreeApi } from "../../../api/suppliersApi";
import { getAllApprovedContracts } from "../../../api/contractsApi";
import { getAllActionApi } from "../../../api/actionManagementApi";
import { getUsersApi } from "../../../api/usersApi";
import {
  getSearchInstanceFiltersApi,
  searchActionInstanceApi,
} from "../../../api/actionInstanceApi";
import { useRecoilState } from "recoil";
import { createActivityLogApi } from "../../../api/logApi";
import { createFiltersApi, getFiltersApi } from "../../../api/usersApi";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
} from "../../../store/store";
import GlobalFilter from "../../GlobalFilter";

const Actionsearch = ({ parentTabKey }) => {
  const history = useNavigate(); //react-router-dom navigate method
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom); //global state for changing header
  const roleUser = useRecoilState(RoleAccessAtom); //global state for getting logged-in user role access data
  const userDataFromAtom = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const [tableData, setTableData] = useState([]);
  const [contentSize, setContentSize] = useState("maximize");
  const [supplierData, setSupplierData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dueDate, setDueDate] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  }); // action search table pagination
  const [formValues, setFormValues] = useState({});
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold saved filter conditions
  const [savedFilters, setSavedFilters] = useState([]);
  // State variable to hold column data visibility status
  const [colFilterShow, setColFilterShow] = useState(false);
  // State variable to hold save dialog visibility status
  const [saveDialog, setSaveDialog] = useState(false);
  // State variable to hold save filter visibility status
  const [savedFilterShow, setSavedFilterShow] = useState(false);
  // State variable to hold dynamic filter data
  const [filterDynamic, setFilterDynamic] = useState([]);
  // State variable to hold save icon visibility status
  const [showSaveIcon, setShowSaveIcon] = useState(false);
  // State variable to hold save message context holder
  const [messageApi, saveMessageContextHolder] = message.useMessage();
  // Variable to hold group check box
  const CheckboxGroup = Checkbox.Group;
  const { RangePicker } = DatePicker;

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
  };

  useEffect(() => {
    suppliersTreeApi(userDataFromAtom[0].id).then((res) => {
      let supplierMaster = res.map((data) => {
        delete data.children;
        return data;
      });
      setSupplierData(supplierMaster);
    });
    getAllApprovedContracts().then((res) => {
      setContractData(res);
    });
    getAllActionApi().then((res) => {
      setActionData(res);
    });
    //   api integration for get all users in app
    getUsersApi().then((res) => {
      setUserData(res); // set response from api to 'users' state
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isColumnSorted = (column) => sortedColumn === column;

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: renderColumnTitle("Instance Name", "action_instance_name"),
      dataIndex: "action_instance_name",
      align: "left",
      sorter: (a, b) =>
        a.action_instance_name.props.children[1].props.children.localeCompare(
          b.action_instance_name.props.children[1].props.children
        ),
      sortOrder: isColumnSorted("action_instance_name") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Action Name", "action_name"),
      dataIndex: "action_name",
      align: "left",
      sorter: (a, b) =>
        a.action_name.props.children[1].props.children.localeCompare(
          b.action_name.props.children[1].props.children
        ),
      sortOrder: isColumnSorted("action_name") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Action Status", "action_status"),
      dataIndex: "action_status",
      align: "left",
      sorter: (a, b) => a.action_status.localeCompare(b.action_status),
      sortOrder: isColumnSorted("action_status") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Action Level", "action_level"),
      dataIndex: "action_level",
      align: "left",
      sorter: (a, b) => a.action_level.localeCompare(b.action_level),
      sortOrder: isColumnSorted("action_level") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Owner", "owner"),
      dataIndex: "owner",
      align: "left",
      sorter: (a, b) => a.owner.localeCompare(b.owner),
      sortOrder: isColumnSorted("owner") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Approver", "approver"),
      dataIndex: "approver",
      align: "left",
      sorter: (a, b) => a.approver.localeCompare(b.approver),
      sortOrder: isColumnSorted("approver") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Due By", "due_by"),
      dataIndex: "due_by",
      align: "left",
      sorter: (a, b) => a.due_by.localeCompare(b.due_by),
      sortOrder: isColumnSorted("due_by") ? sortOrder : false,
      show: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      show:
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.action_management.action_instance.view,
    },
  ];

  const onChangeContentSize = (size) => {
    setContentSize(size);
  };

  const getSearchedAction = (values, page, limit) => {
    searchActionInstanceApi(values, page, limit, allFilterVal).then((res) => {
      console.log("res: ", res.docs);
      const actionData =
        res.docs &&
        res.docs.length > 0 &&
        res.docs.map((item) => {
          let tableRow = {
            key: item.id ? item.id : item._id,
            action_instance_name:
              item && item.action_instance_name
                ? item.action_instance_name
                : "",
            root_action_id: item.root_action_id,
            action_name:
              item.root_action && item.root_action.length !== 0
                ? item.root_action[0].action_name
                : "",
            action_status: item.instance_status,
            action_level:
              item.root_action && item.root_action.length !== 0
                ? item.root_action[0].action_level
                : "",
            owner_id: item.action_owner,
            owner: item.action_owner_name,
            approver_id: item.action_approver,
            approver: item.action_approver_name,
            due_by: moment(item.due_date).format("DD MMM YYYY"),
            action: (
              <>
                <div
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  <Image
                    title="Detail"
                    src={mail}
                    preview={false}
                    style={{ width: "20px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      if (
                        roleUser &&
                        roleUser[0] &&
                        roleUser[0].page_access.action_management
                          .action_instance.view
                      ) {
                        setHeaderChanger({
                          header: "actionManagement",
                          headerItem: "2",
                          headerItemName: "actionManagement",
                          stateSetter: item,
                        });
                        let actionLog = {
                          ss_id: item._id,
                          ss_name:
                            item && item.action_instance_name
                              ? item.action_instance_name
                              : "",
                          code: "action_instance",
                          author: userDataFromAtom[0].id,
                          action: "view",
                          notes: "Viewed Action Instance",
                          updated_on: new Date(),
                        };
                        createActivityLogApi(actionLog);
                        history(`/action-management/view-action-instance`, {
                          state: item,
                        });
                      }
                    }}
                  />
                </div>
              </>
            ),
          };
          return tableRow;
        });
      setTableData(actionData);
    });
  };

  const handleDateChange = (dates, dateStrings) => {
    console.log("dates", dates);
    console.log("dateStrings", dateStrings);
    setDueDate(dateStrings); // Set the state with the adjusted dates
  };

  const onFinish = (values) => {
    console.log("values", values);
    setFormValues(values);
    getSearchInstanceFiltersApi().then((res) => {
      console.log("filterRes: ", res);
      setFilterDynamic(res);
    });
    getSearchedAction(values, paginateData.pageNo, paginateData.limit);
  };

  // action search table pagination handler
  const onPageChange = (page, limit) => {
    getSearchedAction(formValues, page, limit);
  };

  useEffect(() => {
    console.log("filterDynamic", filterDynamic);
  }, [filterDynamic]);

  /* Function to handle the create suppiler data form data stored
   * @param values - a user create form values
   */
  const onSave = async (values) => {
    setSaveDialog(false);
    const data = {
      filter_type: values.filter_type,
      filter_name: values.filter_name,
      filter_values: allFilterVal,
    };

    await createFiltersApi({
      created_by: userDataFromAtom[0].id,
      action_management_filter_data: { action_search_filter: [data] },
      created_on: new Date(),
      updated_on: new Date(),
    }).then((res) => {
      messageApi.open({
        type: "success",
        content: (
          <span>
            <span style={{ fontWeight: 600 }}>{values.filter_name} </span>
            {values.filter_type} filter has been saved successfully
          </span>
        ),
      });
      console.log("filter saved");
      getAllFilters();
    });
  };

  // Function to get a all filter details from db
  const getAllFilters = () => {
    getFiltersApi(userDataFromAtom[0].id).then((res) => {
      if (res !== "") {
        console.log("filters res: ", res);
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res
            .flatMap(
              (item) => item.action_management_filter_data?.action_search_filter
            )
            .filter((n) => n);
        console.log("filterData: ", systemSetupFilterData);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  useEffect(() => {
    getAllFilters();
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
       allFilterVal state
     */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      getSearchedAction(formValues, 1, 20);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        getSearchedAction(formValues, 1, 20);
      } else {
        getSearchedAction(formValues, 1, 20);
      }
    }
    console.log("allFilterVal: ", allFilterVal);
  }, [allFilterVal]);

  useEffect(() => {
    console.log("formValues: ", formValues);
  }, [formValues]);

  useEffect(() => {
    setSavedFilterShow(false);
    setColFilterShow(false);
  }, [parentTabKey]);

  return (
    <div className="action-search">
      {saveMessageContextHolder}
      <div
        className="action-body"
        style={{ textAlign: "left", color: "black" }}
      >
        <div className="action-new-div">
          <div className="create-entity-div">
            <Row
              align="middle"
              type="flex"
              style={{
                padding:
                  contentSize === "maximize" ? "9px 30px 0px" : "5px 30px",
              }}
            >
              <Col span={20} className="vertical-align">
                <h4 className="entity-text">Search Action Instance</h4>
              </Col>
              <Col span={4} className="text-end">
                {contentSize !== "maximize" && (
                  <Space>
                    <Image
                      className="cursor-pointer expand-contract-icon"
                      onClick={() => onChangeContentSize("maximize")}
                      src={DownArrow}
                      preview={false}
                    />
                  </Space>
                )}
              </Col>
            </Row>
            {contentSize === "maximize" && (
              <>
                <hr
                  className="mt-10 view-supplier-hr"
                  style={{ color: "grey" }}
                />
                <div className="create-supplier-div action-body-head">
                  <Form onFinish={onFinish}>
                    <Row align="middle" type="flex" gutter={[64, 4]}>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="supplier_name"
                          label="SUPPLIER NAME"
                          colon={false}
                        >
                          <Select
                            allowClear
                            showSearch={true}
                            placeholder="Choose Supplier"
                            mode="multiple"
                            options={
                              supplierData &&
                              supplierData.map((data) => ({
                                key: data.id ? data.id : data._id,
                                label: data.supplier_name,
                                value: data.id ? data.id : data._id,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="contract_name"
                          label="CONTRACT NAME"
                          colon={false}
                        >
                          <Select
                            allowClear
                            showSearch={true}
                            placeholder="Choose Contract"
                            mode="multiple"
                            options={
                              contractData &&
                              contractData.map((data) => ({
                                key: data.id,
                                label: data.contract_name,
                                value: data.id,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="action_name"
                          label="NAME OF THE ACTION"
                          colon={false}
                        >
                          <Select
                            allowClear
                            showSearch={true}
                            mode="multiple"
                            placeholder="Choose Action"
                            options={
                              actionData &&
                              actionData.map((data) => ({
                                key: data._id,
                                label: data.action_name,
                                value: data._id,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="status"
                          label="STATUS"
                          colon={false}
                        >
                          <Select
                            allowClear
                            mode="multiple"
                            showSearch={true}
                            placeholder="Choose Action Status"
                            options={[
                              {
                                key: "Open",
                                label: "Open",
                                value: "Open",
                              },
                              {
                                key: "Submitted",
                                label: "Submitted",
                                value: "Submitted",
                              },
                              {
                                key: "Met",
                                label: "Met",
                                value: "Met",
                              },
                              {
                                key: "Not Met",
                                label: "Not Met",
                                value: "Not Met",
                              },
                              {
                                key: "Cancelled",
                                label: "Cancelled",
                                value: "Cancelled",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="action_owner"
                          label="ACTION OWNER"
                          colon={false}
                        >
                          <Select
                            allowClear
                            mode="multiple"
                            showSearch={true}
                            placeholder="Choose Action Owner"
                            options={
                              userData &&
                              userData.map((data) => ({
                                key: data._id,
                                label: `${data.first_name} ${data.last_name}`,
                                value: data._id,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="action_approver"
                          label="ACTION APPROVER"
                          colon={false}
                        >
                          <Select
                            allowClear
                            mode="multiple"
                            showSearch={true}
                            placeholder="Choose Action Approver"
                            options={
                              userData &&
                              userData.map((data) => ({
                                key: data._id,
                                label: `${data.first_name} ${data.last_name}`,
                                value: data._id,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="due_date"
                          label="DUE DATE"
                          colon={false}
                        >
                          <RangePicker
                            onChange={handleDateChange}
                            showTime={false}
                            format={"DD MMMM YYYY"}
                            className="report-input"
                            placeholder={["From", "To"]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row align="middle" type="flex" className="mt-20">
                      <Col span={13} align="right" className="mb-15">
                        <Col span={4}>
                          <OkButton htmlType="submit" />
                        </Col>
                      </Col>
                      <Col span={11} align="right" className="mb-15">
                        <Image
                          className="cursor-pointer expand-contract-icon"
                          onClick={() => onChangeContentSize("minimize")}
                          src={UpArrow}
                          style={{
                            pointerEvents: contentSize === "minimize" && "none",
                          }}
                          preview={false}
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="filter-white-space-head mt-10">
        <GlobalFilter
          selectedTab={"searchInstance"}
          initialTableData={tableData}
          allFilterVal={allFilterVal}
          setAllFilterVal={setAllFilterVal}
        />
      </div>
      {saveDialog && showSaveIcon && (
        <div className="filter-inner-div save-dialog-box">
          <div className="filter-title">
            <span>Save Filter</span>{" "}
            <CloseCircleOutlined
              style={{
                fontSize: 17,
                color: "var(--color-border)",
              }}
              onClick={() => {
                setSaveDialog(false);
              }}
            />
          </div>
          <div className="inner-div">
            <Form onFinish={onSave}>
              <Form.Item
                name="filter_type"
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: "Please Select Filter Type !",
                  },
                ]}
              >
                <Select
                  placeholder="Select Filter Type"
                  options={[
                    {
                      value: "public",
                      label: "Public",
                    },
                    {
                      value: "private",
                      label: "Private",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                name="filter_name"
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Filter Name !",
                  },
                ]}
              >
                <Input placeholder="Filter Name" />
              </Form.Item>
              <span className="buttons">
                <p onClick={() => setSaveDialog(false)}>Cancel</p>
                <Button
                  htmlType="submit"
                  style={{
                    padding: "0",
                    border: "0",
                    height: "auto",
                  }}
                >
                  <p>Save</p>
                </Button>
              </span>
            </Form>
          </div>
        </div>
      )}
      <Table
        rowClassName={() => "contract-tree-table-row"}
        dataSource={tableData}
        columns={columns}
        showSorterTooltip={false}
        bordered
        size="large "
        scroll={{ x: "calc(300px + 50%)", y: 400 }}
        pagination={false}
        className="system-setup-table mt-10"
      />
      <div className="float-right mb-15 mt-15">
        <Pagination
          defaultPageSize={paginateData.limit}
          responsive
          current={paginateData.pageNo}
          total={paginateData.total}
          showSizeChanger
          onShowSizeChange={onPageChange}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default Actionsearch;
