import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
const NotAuthorized = ({ redirect }) => {
  //global state variable hold the navigating different routes
  const navigate = useNavigate();
  return (
    <Result
      style={{ height: "calc(100vh - 60px)" }}
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => navigate(redirect)}>
          Go Back
        </Button>
      }
    />
  );
};
export default NotAuthorized;
