import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Image,
  Form,
  Input,
  Upload,
  Modal,
  Space,
  message,
  Select,
  Spin,
} from "antd"; //antd components
import { HeaderChangerAtom } from "../../../store/store";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TopHeader from "../../Header";
import { validateFileSize } from "../../../components/FileUploadUtils";

import { useRecoilState } from "recoil";
import CustomLoader from "../../CustomLoader";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
// import browse_activity from "../../../assets/images/icons/browse_activity.svg";
import close from "../../../assets/images/icons/close.svg";
import edit_temp from "../../../assets/images/icons/edit_temp.svg";
import {
  SaveButton,
  NextButton,
  CancelButton,
  BrowseButton,
  PreviewButton,
  UploadButton,
  OnlineButton,
} from "../../GlobalButton";
import info from "../../../assets/images/icons/info.svg";
import "../../../assets/style/template.css";
// import Online_batch from "../../../assets/images/icons/Online_batch.svg";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import { useNavigate, useLocation } from "react-router-dom";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { userDataAtom } from "../../../store/store";
import { getAllMsaApi } from "../../../api/msaApi";
import { getAllowedSupplierList } from "../../../api/suppliersApi";
import {
  saveTemplateApi,
  updateTemplateApi,
} from "../../../api/contractAuthorityApi";
import UploadPreview from "./UploadPreview";
import { createActivityLogApi } from "../../../api/logApi";

function CreateTemplate() {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  // const [uploadRule, setUploadRule] = useState(false); //  upload validation required true or false
  // const [uploadRuleText, setUploadRuleText] = useState(); //  upload validation error text
  let { state } = useLocation();
  console.log("state", state);
  // if (state === null || state === undefined) {
  //   state = headerChanger.stateSetter;
  // }
  const [tempId, setTempId] = useState("");
  const userData = useRecoilState(userDataAtom); //global state
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [buttondisable, setbuttondisable] = useState(true);
  const [headerEditIndex, setHeaderEditIndex] = useState(null);
  const [paragraphEditIndex, setParagraphEditIndex] = useState(null);
  const [loader, setLoader] = useState(true);
  const { TextArea } = Input;
  const [entityField, setEntityField] = useState("upload");
  const history = useNavigate();
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [browseFile, setBrowseFile] = useState(false);
  const [createTemplate, setCreateTemplate] = useState(true);
  const [uploadPreview, setUploadPreview] = useState(false);
  const [TemplateFile, setTemplateFile] = useState();
  const popupModalInstance = PopupModal();
  const menuKey = "5";
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [templateMetaData, setTemplateMetaData] = useState([]);
  const [initialClauseContent, setInitialClauseContent] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [supplementData, setSuplementData] = useState([]);
  const [ccnData, setCcnData] = useState([]);
  const [sowData, setSowData] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [buttonState, setButtonState] = useState(0);
  const [templateDesciption, setTemplateDescription] = useState("");
  const [templateField, setTemplateField] = useState({
    msa: state != null ? state.msa : null,
    contract_template_name: state != null ? state.contract_template_name : null,
    supplier_id: state != null ? state.supplier_id : null,
    template_description: state != null ? state.template_description : null,
  });

  const [msaName, setMsaName] = useState(
    state != null ? state.msa_name && state.msa_name : null
  );
  const [supplierName, setSupplierName] = useState(
    state != null ? state.supplier_name && state.supplier_name : null
  );
  console.log("msaName", msaName, "supplierName", supplierName);
  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
    setTemplateField({
      ...templateField,
      contract_template_name: e.target.value,
    });
  };
  const handleTemplateDescChange = (e) => {
    setTemplateDescription(e.target.value);
    setTemplateField({
      ...templateField,
      template_description: e.target.value,
    });
  };

  console.log("initaltablevalues", initialClauseContent);
  console.log("templateMetaData  ****", templateMetaData);
  const deleteTemplateMeta = (index) => {
    const tempData = templateMetaData
      .filter((num, data) => data !== index)
      .map((element, index) => ({
        ...element,
        h: `${index + 1}. ${element.h.split(". ")[1]}`,
      }));
    console.log("tampData **   ", tempData);

    setTemplateMetaData(tempData);
    setInitialClauseContent(tempData);
  };
  useEffect(() => {
    console.log("templateMetaData *****", templateMetaData);
  }, [templateMetaData]);

  useEffect(() => {
    getAllowedSupplierList(userData[0].id).then((res) => {
      setSupplierData(res);
    });

    // suppliersTreeApi(userData[0].id).then((res) => {
    //   let supplierMaster = res.map((data) => {
    //     delete data.children;
    //     return data;
    //   });
    //   setSupplierData(supplierMaster);
    //   // const suppliername = supplierMaster.filter(
    //   //   (data) => data._id === templateField.supplier_id
    //   // );
    //   // setSupplierName(
    //   //   suppliername && suppliername[0] && suppliername[0].supplier_name
    //   // );
    //   // form.setFieldValue({
    //   //   supplier_name:
    //   //     suppliername && suppliername[0] && suppliername[0].supplier_name,
    //   // });
    //   // getAllMsaApi().then((res) => {
    //   //   let filteredMsa = res.filter(
    //   //     (data) => data.supplier_id === templateField.supplier_id
    //   //   );
    //   //   setMsaName(filteredMsa);
    //   //   console.log("templateField.msa", templateField.msa);
    //   //   const msaname = filteredMsa.filter(
    //   //     (data) => data.id === templateField.msa
    //   //   );
    //   //   console.log("msaname", msaname);
    //   // });
    // });
  }, []);
  // useEffect(() => {
  //   console.log("headingEdit   fdr", headingEdit);
  // }, [headingEdit]);

  const handleEdit = (index, field, event) => {
    const updatedValue = event.target ? event.target.value : event;
    const updatedData = [...initialClauseContent];
    updatedData[index][field] = updatedValue;
    setInitialClauseContent(updatedData); // Update the paragraph data
  };
  const editHeader = (index) => {
    setHeaderEditIndex(index);
    setParagraphEditIndex(null);
  };

  const editParagraph = (index) => {
    setParagraphEditIndex(index); // Set the index of the paragraph being edited
  };
  //On Supplier change
  const onChangeSupplier = (key, value) => {
    console.log("Value: ", value);
    form.setFieldsValue({ msa: null });
    if (typeof value == "undefined") {
      form.setFieldsValue({ msa: null });
      setMsaName(null);
      setTemplateField({
        ...templateField,
        supplier_id: null,
        msa: null,
      });
      setMsaData([]); // Clear MSA data
    } else {
      setTemplateField({
        ...templateField,
        supplier_id: value.key,
      });
      setSupplierName(value.value);
      getAllMsaApi().then((res) => {
        let filteredMsa = res.filter((data) => data.supplier_id === value.key);
        setMsaData(filteredMsa);
      });
    }
  };

  // MSA change
  const onChangeMsa = (key, value) => {
    console.log("value", value);
    console.log("key", key);
    if (typeof value === "undefined") {
      // Clear the MSA field when value is undefined
      form.setFieldsValue({ msa: null });
      setMsaName(null);
      setTemplateField({
        ...templateField,
        msa: null,
      });
    } else {
      setTemplateField({
        ...templateField,
        msa: key,
      });
      setMsaName(value.label);
    }
  };

  //MSA change
  // const onChangeMsa = (value) => {
  //   if (typeof value == "undefined") {
  //     form.setFieldsValue({ supplier_name: null });
  //     form.setFieldsValue({ msa: null });
  //     setTemplateField({
  //       ...templateField,
  //       supplier_id: null,
  //       // supplierName: null,
  //       msa: null,
  //     });
  //   } else {
  //     setTemplateField({
  //       ...templateField,
  //       msa: value,
  //     });
  //   }
  // };

  //CCN change

  // const onChangeCCn = (value) => {
  //   if (typeof value == "undefined") {
  //     form.setFieldsValue({ supplier_name: null });
  //     form.setFieldsValue({ ccn: null });
  //     setTemplateField({
  //       ...templateField,
  //       supplier_id: null,
  //       // supplierName: null,
  //       ccn: null,
  //     });
  //   } else {
  //     setTemplateField({
  //       ...templateField,
  //       ccn: value,
  //     });
  //   }
  // };

  // //SOW change
  // const onChangeSow = (value) => {
  //   if (typeof value == "undefined") {
  //     form.setFieldsValue({ supplier_name: null });
  //     form.setFieldsValue({ sow: null });
  //     setTemplateField({
  //       ...templateField,
  //       supplier_id: null,
  //       // supplierName: null,
  //       sow: null,
  //     });
  //   } else {
  //     setTemplateField({
  //       ...templateField,
  //       sow: value,
  //     });
  //   }
  // };

  // //Supplement change
  // const onChangeSupplement = (value) => {
  //   if (typeof value == "undefined") {
  //     form.setFieldsValue({ supplier_name: null });
  //     form.setFieldsValue({ template_supplement: null });
  //     setTemplateField({
  //       ...templateField,
  //       supplier_id: null,
  //       // supplierName: null,
  //       template_supplement: null,
  //     });
  //   } else {
  //     setTemplateField({
  //       ...templateField,
  //       template_supplement: value,
  //     });
  //   }
  // };

  const onFinish = async (values) => {
    console.log("values", values);
    Object.keys(templateField).forEach((key) => {
      // If the value is empty or undefined, replace it with "null"
      if (!templateField[key]) {
        templateField[key] = "null";
      }
    });

    values = {
      ...templateField,
      clause_content: templateMetaData,
      created_on: new Date(),
      updated_on: new Date(),
      created_by: userData[0].id,
      updated_by: userData[0].id,
    };
    console.log("values", values);
    if (buttonState === 1) {
      setSaveTableLoader(true);
      values["status"] = "Draft";
      if (tempId === "") {
        await saveTemplateApi(values).then((res) => {
          console.log("res  ", res);
          if (res.status === 304) {
            popupModalInstance.errorModalOfSupplier(res.message);
          } else {
            let ssLog = {
              ss_id: res.data.id ? res.data.id : res.data_id,
              ss_name: values.contract_template_name,
              code: "template",
              author: userData[0].id,
              action: "view",
              notes: "Created contract template",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            setSaveTableLoader(false);
            setTempId(res.data.id);
            popupModalInstance.successModal(
              values.contract_template_name,
              ModalMessage.CA_TE_Save
            );
          }
        });
      } else {
        setSaveTableLoader(false);
        console.log("tempId", tempId);
        await updateTemplateApi(tempId, values).then((res) => {
          let ssLog = {
            ss_id: res.id ? res.id : res._id,
            ss_name: values.contract_template_name,
            code: "template",
            author: userData[0].id,
            action: "view",
            notes: "Created contract template",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          setSaveTableLoader(false);
          popupModalInstance.successModal(
            values.contract_template_name,
            ModalMessage.CA_TE_Update
          );
        });
      }
    } else if (buttonState === 2) {
      // navigate(`/contract_authority/template/upload-preview`, {
      //   state: values,
      // });
      setUploadPreview(true);
      setBrowseFile(false);
      setCreateTemplate(false);
    }
    console.log("values....", values);
  };

  // const state = {
  //   button: 1,
  // };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const props = {
    name: "file",
    listType: "picture",
    accept: ["pdf"],
    maxCount: 1,
    // onRemove() {
    //   setTableData([]);
    // },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length !== 0) {
          setTemplateFile(info.file);
        } else {
          setTemplateFile();
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setTemplateFile(info.file);
        } else {
          setTemplateFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      if (!validateFileSize(file)) {
        return Upload.LIST_IGNORE;
      }
      setConfirmationModel(false);
      const acceptedFormats = ["pdf"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);

      if (!formatCheck) {
        popupModalInstance.errorModal(ModalMessage.Workflow_Excel_Upload_Error);
      } else {
        console.log("ubnuid iubsdbs");
        popupModalInstance
          .infoModal(ModalMessage.CAC_Temp_Info)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              setBrowseFile(true);
              setCreateTemplate(false);

              const formData = new FormData();
              formData.append("file", file);
              console.log("FormDatavalue", formData);

              try {
                const response = fetch(
                  "https://g2d1ry31gh.execute-api.ap-south-1.amazonaws.com/v1/extract",
                  {
                    method: "post",
                    headers: {
                      "Content-Type": "application/pdf",
                    },
                    body: formData,
                  }
                )
                  .then(async (response) => {
                    console.log("response", response);
                    let data = await response.json();
                    let setData = JSON.parse(data.body);

                    // Log data before filtering
                    console.log("Data before filtering", setData);

                    // Filter out headers with fewer than 5 characters
                    setData = setData.filter(
                      (item) => item.h && item.h.trim().length >= 5
                    );

                    // Log data after filtering
                    console.log("Data after filtering", setData);

                    setTemplateMetaData(setData);
                    setInitialClauseContent(setData);
                    setLoader(false);
                  })
                  .catch((error) =>
                    console.error("Something went wrong!", error)
                  );
              } catch (error) {
                console.error("Error uploading file:", error);
                message.error("An error occurred while uploading the file");
              }
            }
          });
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const stripHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <Form onFinish={onFinish} onKeyDown={handleKeyDown} form={form}>
        {createTemplate && (
          <div className="system-setup1">
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={16}
                md={18}
                lg={18}
                xl={22}
              >
                <Card className="setup-header-card">
                  <Row type="flex" gutter={[8, 16]}>
                    <Col
                      className="vertical-align"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={2}
                    >
                      <Row type="flex" gutter={[8, 16]}>
                        <Col span={12}>
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`/contract_authority/template`)
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <Image
                            src={arrow_right_disable}
                            style={{ width: 20 }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="vertical-align"
                      xs={17}
                      sm={17}
                      md={19}
                      lg={19}
                      xl={22}
                    >
                      <span>Contract Authority</span> &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span
                        className="cursor-pointer"
                        onClick={() => navigate(`/contract_authority/template`)}
                      >
                        Template
                      </span>
                      &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span>Create Template</span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SaveButton
                  type="primary"
                  htmlType="submit"
                  loading={saveTableLoader}
                  onClick={() => setButtonState(1)}
                  disabled={!templateField.contract_template_name}
                />
              </Col>
              {/* <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <NextButton type="primary" name="next-btn" />
              </Col> */}

              <div className="price-profile-content">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={24}>
                        <div className="create-supplier-div">
                          <div className="m-20">
                            <Row align="middle" type="flex">
                              <Col span={24}>
                                <Row>
                                  <Col span={8}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="template_name"
                                      label="TEMPLATE NAME"
                                      initialValue={
                                        templateField.contract_template_name
                                      }
                                      colon={false}
                                      rules={[
                                        {
                                          message: "Please enter template name",
                                          pattern: /^(?!\s*$).+/,
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter your title"
                                        onChange={handleTemplateNameChange}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="template_description"
                                      label="ENTER TEMPLATE DESCRIPTION"
                                      initialValue={
                                        templateField.template_description !==
                                        "null"
                                          ? templateField.template_description
                                          : ""
                                      }
                                      colon={false}
                                      rules={[
                                        {
                                          message:
                                            "Please enter template description",
                                          pattern: /^(?!\s*$).+/,
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter your Description"
                                        onChange={handleTemplateDescChange}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="supplier name"
                                  label="SUPPLIER NAME"
                                  initialValue={supplierName}
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    onChange={(key, value) =>
                                      onChangeSupplier(key, value)
                                    }
                                    showSearch={true}
                                    placeholder="Choose Supplier"
                                    options={
                                      supplierData &&
                                      supplierData.map((data) => ({
                                        key: data._id,
                                        label: data.supplier_name,
                                        value: data.supplier_name,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="msa"
                                  label="FRAMEWORK AGREEMENT / MSA"
                                  initialValue={msaName}
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    onChange={(key, value) =>
                                      onChangeMsa(key, value)
                                    }
                                    showSearch={true}
                                    placeholder="Choose MSA"
                                    options={
                                      msaData &&
                                      msaData.map((data) => ({
                                        key: data.id,
                                        label: data.contract_name,
                                        value: data.id,
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              {/* <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="template_supplement"
                                  label="SUPPLEMENT"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    onChange={onChangeSupplement}
                                    showSearch={true}
                                    placeholder="Choose Supplement"
                                    options={
                                      supplementData &&
                                      supplementData.map((data) => ({
                                        key: data.id,
                                        label: data.contract_name,
                                        value: data.id,
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="ccn"
                                  label="CCN"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    onChange={onChangeCCn}
                                    showSearch={true}
                                    placeholder="Choose CCN"
                                    options={
                                      ccnData &&
                                      ccnData.map((data) => ({
                                        key: data.id,
                                        label: data.contract_name,
                                        value: data.id,
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="sow"
                                  label="SOW"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    onChange={onChangeSow}
                                    showSearch={true}
                                    placeholder="Choose SOW"
                                    options={
                                      sowData &&
                                      sowData.map((data) => ({
                                        key: data.id,
                                        label: data.contract_name,
                                        value: data.id,
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col> */}
                            </Row>
                          </div>
                          {entityField === "upload" && (
                            <>
                              <Row
                                align="middle"
                                gutter={[40, 16]}
                                justify="center"
                                className="mt-25"
                              >
                                <Col
                                  span={8}
                                  align="right"
                                  className="vertical-align"
                                >
                                  <div className="choose-input-div">
                                    <Form.Item>
                                      <Row type="flex" gutter={[64, 56]}>
                                        <Col
                                          span={24}
                                          align="middle"
                                          className="vertical-align"
                                        >
                                          <span className="upload-note">
                                            Click below to enter Template
                                            Details through Uploading
                                          </span>
                                        </Col>
                                        <Col
                                          span={24}
                                          align="middle"
                                          className="vertical-align"
                                        >
                                          <Col
                                            span={10}
                                            style={{ marginRight: 5 }}
                                          >
                                            <UploadButton
                                              className="template-upload"
                                              type="primary"
                                              onClick={() =>
                                                setConfirmationModel(true)
                                              }
                                              disabled={
                                                !(
                                                  templateField.contract_template_name &&
                                                  templateField.supplier_id
                                                )
                                              }
                                            />
                                          </Col>
                                        </Col>
                                        <Col span={24} align="middle">
                                          <Upload
                                            customRequest={dummyRequest}
                                          ></Upload>
                                        </Col>
                                        <Col
                                          span={24}
                                          className="vertical-align"
                                          align="middle"
                                        ></Col>
                                      </Row>
                                    </Form.Item>
                                  </div>
                                </Col>
                                {confirmationModel && (
                                  <Modal
                                    centered
                                    open={confirmationModel}
                                    onOk={() => setConfirmationModel(false)}
                                    onCancel={() => {
                                      setConfirmationModel(false);
                                    }}
                                    width={450}
                                    footer={false}
                                  >
                                    <Space align="">
                                      <div>
                                        <h2>Select Document File</h2>
                                        <span style={{ fontSize: "12px" }}>
                                          Select the File to Show in Template
                                        </span>
                                      </div>
                                    </Space>

                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                    >
                                      <Col span={24}>
                                        <Row gutter={[16, 8]}>
                                          <Col span={24}>
                                            <Form.Item labelCol={{ span: 24 }}>
                                              <TextArea rows={3} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row className="mt-15">
                                      <Col
                                        span={24}
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                          alignItems: "end",
                                        }}
                                      >
                                        <Col span={8}>
                                          <div className="main-test">
                                            <Upload
                                              {...props}
                                              customRequest={dummyRequest}
                                              className="contract_authority-upload-wrapper"
                                            >
                                              <Col
                                                span={24}
                                                align="middle"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "end",
                                                  alignItems: "end",
                                                }}
                                              >
                                                <Col
                                                  span={24}
                                                  style={{ marginRight: 5 }}
                                                >
                                                  <BrowseButton />
                                                </Col>
                                              </Col>
                                            </Upload>
                                          </div>
                                        </Col>
                                        <Col span={8}>
                                          <CancelButton
                                            onClick={() =>
                                              setConfirmationModel(false)
                                            }
                                          />
                                        </Col>
                                      </Col>
                                    </Row>
                                  </Modal>
                                )}

                                <Col
                                  span={8}
                                  align="right"
                                  className="vertical-align"
                                >
                                  <div className="choose-input-div">
                                    <Form.Item>
                                      <Row type="flex" gutter={[64, 56]}>
                                        <Col
                                          span={24}
                                          align="middle"
                                          className="vertical-align"
                                        >
                                          <span className="upload-note">
                                            Click below to enter Template
                                            Details through Online mode
                                          </span>
                                        </Col>
                                        <Col
                                          span={24}
                                          align="middle"
                                          className="vertical-align"
                                        >
                                          <Col
                                            span={10}
                                            style={{ marginRight: 5 }}
                                          >
                                            <OnlineButton
                                              className="template-upload"
                                              type="primary"
                                              disabled={
                                                !(
                                                  templateField.contract_template_name &&
                                                  templateField.supplier_id
                                                )
                                              }
                                              onClick={() => {
                                                const stateValues = {
                                                  ...templateField,
                                                  supplier_name: supplierName,
                                                  msa_name: msaName,
                                                };
                                                setHeaderChanger({
                                                  header: "contractAuthority",
                                                  headerItem: "2",
                                                  headerItemName:
                                                    "contractAuthority",
                                                  stateSetter: stateValues,
                                                });
                                                navigate(
                                                  `/contract_authority/online/template`,
                                                  {
                                                    state: stateValues,
                                                  }
                                                );
                                              }}
                                            />
                                          </Col>
                                          {/* <Button
                                            className="template-upload"
                                            type="primary"
                                            disabled={
                                              !templateField.contract_template_name
                                            }
                                            onClick={() =>
                                              navigate(
                                                `/contract_authority/online/template`,
                                                { state: templateField }
                                              )
                                            }
                                          >
                                            <Image
                                              upload
                                              src={Online_batch}
                                              preview={false}
                                              style={{
                                                Color: "white",
                                                padding: "0px 8px 0px 0px",
                                              }}
                                            ></Image>
                                            Online
                                          </Button> */}
                                        </Col>
                                        <Col span={24} align="middle">
                                          <Upload
                                            customRequest={dummyRequest}
                                          ></Upload>
                                        </Col>
                                        <Col
                                          span={24}
                                          className="vertical-align"
                                          align="middle"
                                        ></Col>
                                      </Row>
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        )}

        {browseFile && (
          <Layout className="header-layout">
            <div className="system-setup1">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={18}
                  lg={18}
                  xl={20}
                >
                  <Card className="setup-header-card">
                    <Row type="flex" gutter={[8, 16]}>
                      <Col
                        className="vertical-align"
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={2}
                      >
                        <Row type="flex" gutter={[8, 16]}>
                          <Col span={12}>
                            <Image
                              src={arrow_left}
                              style={{ width: 25 }}
                              preview={false}
                              className="cursor-pointer"
                              onClick={() => {
                                // navigate(`/contract_authority/create/template`)
                                setCreateTemplate(true);
                                setBrowseFile(false);
                              }}
                            />
                          </Col>
                          <Col span={12}>
                            <Image
                              src={arrow_right_disable}
                              style={{ width: 20 }}
                              preview={false}
                              className="cursor-not-allowed"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={17}
                        sm={17}
                        md={19}
                        lg={19}
                        xl={22}
                      >
                        <span>Contract Authority</span> &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`/contract_authority/template`)
                          }
                        >
                          Templates
                        </span>
                        &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span>Create Templates</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SaveButton
                    disabled={loader}
                    type="primary"
                    htmlType="submit"
                    onClick={() => setButtonState(1)}
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <PreviewButton
                    disabled={loader}
                    // onClick={() =>
                    //   navigate(`/contract_authority/preview/template`, {
                    //     state: values,
                    //   })
                    // }
                    type="primary"
                    htmlType="submit"
                    onClick={() => setButtonState(2)}
                  />
                </Col>
              </Row>
              {!loader && (
                <div className="price-profile-content m-10">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[8, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={24}>
                          <div className="create-supplier-div">
                            <Row align="middle" gutter={[40, 16]}>
                              <Col span={9} className="vertical-align">
                                <div className="org-temp-div mt-10">
                                  <Space align="">
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <Row>
                                        <Col>
                                          <h3
                                            style={{
                                              color: "#2684ff",
                                            }}
                                          >
                                            Extracted Clause Headers
                                          </h3>
                                        </Col>
                                      </Row>
                                      <Image
                                        src={info}
                                        preview={false}
                                        style={{
                                          padding: "0px 5px 0px 0px",
                                        }}
                                      ></Image>
                                      <span
                                        style={{
                                          fontSize: "11px",
                                        }}
                                      >
                                        Select to view the Contents, Re-arrange
                                        the headers, as required
                                      </span>
                                    </div>
                                  </Space>
                                  <hr className="mt-10 view-supplier-hr" />
                                  {initialClauseContent.map((data, index) => {
                                    console.log("data: ", data);
                                    return (
                                      <Form.Item>
                                        <Row
                                          type="flex"
                                          align="middle"
                                          className="template-header-names"
                                        >
                                          <Col
                                            span={21}
                                            className="vertical-align"
                                          >
                                            <Input
                                              className="template-setup1"
                                              disabled={
                                                index !== headerEditIndex
                                              }
                                              value={
                                                // initialClauseContent[index].h
                                                data.h
                                              } // Ensure this value is correct
                                              onChange={(e) =>
                                                handleEdit(index, "h", e)
                                              }
                                            />
                                          </Col>
                                          <Col
                                            span={3}
                                            className="vertical-align"
                                          >
                                            <Space>
                                              <Image
                                                className="cursor-pointer"
                                                disabled={
                                                  index !== headerEditIndex
                                                }
                                                src={edit_temp}
                                                preview={false}
                                                onClick={() =>
                                                  editHeader(index)
                                                }
                                              />
                                              <Image
                                                className="cursor-pointer"
                                                src={close}
                                                preview={false}
                                                onClick={() =>
                                                  deleteTemplateMeta(index)
                                                }
                                              />
                                            </Space>
                                          </Col>
                                        </Row>
                                      </Form.Item>
                                    );
                                  })}
                                </div>
                              </Col>
                              <Col span={15} className="vertical-align">
                                <div className="org-temp-div mt-10">
                                  <Space align="">
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          color: "#2684ff",
                                        }}
                                      >
                                        Clause Content
                                      </h3>
                                      <Image
                                        src={info}
                                        preview={false}
                                        style={{
                                          padding: "0px 5px 0px 0px",
                                        }}
                                      ></Image>
                                      <span
                                        style={{
                                          fontSize: "11px",
                                        }}
                                      >
                                        Select the required content
                                      </span>
                                    </div>
                                  </Space>
                                  <hr className="mt-10 view-supplier-hr" />
                                  {initialClauseContent.map((data, index) => (
                                    <div key={index}>
                                      <Row
                                        type="flex"
                                        align="middle"
                                        className="mt-15"
                                      >
                                        <Col
                                          span={23}
                                          className="vertical-align"
                                        >
                                          <Input
                                            style={{
                                              border: "none",
                                              fontWeight: 600,
                                            }}
                                            readOnly
                                            value={data.h} // Clause header
                                            onChange={(e) =>
                                              handleEdit(index, "h", e)
                                            } // Existing header edit logic
                                          />
                                        </Col>
                                        <Col
                                          span={1}
                                          className="vertical-align float-right"
                                        >
                                          <Image
                                            src={edit_temp}
                                            preview={false}
                                            className="cursor-pointer"
                                            onClick={() => editParagraph(index)} // Set this paragraph as editable
                                          />
                                        </Col>
                                      </Row>

                                      {/* Conditionally render ReactQuill when the paragraph is being edited */}
                                      {index === paragraphEditIndex ? (
                                        <ReactQuill
                                          id="min-quill"
                                          theme="snow"
                                          value={data.p || ""} // Set the content for the paragraph
                                          onChange={(content) =>
                                            handleEdit(index, "p", content)
                                          } // Update the paragraph content
                                          modules={{ toolbar: toolbarOptions }} // Set the toolbar options
                                        />
                                      ) : (
                                        <Input
                                          className="template-setup2"
                                          disabled={
                                            index !== paragraphEditIndex
                                          } // Disable input unless it's the edited paragraph
                                          value={stripHtml(data.p)} // Paragraph content as plain text by stripping HTML tags
                                          onChange={(e) =>
                                            handleEdit(index, "p", e)
                                          } // Update the paragraph content
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
              {loader && <CustomLoader />}
            </div>
          </Layout>
        )}
      </Form>
      {uploadPreview && (
        <UploadPreview
          createState={{
            ...templateField,
            clause_content: templateMetaData,
            created_on: new Date(),
            updated_on: new Date(),
            created_by: userData[0].id,
            updated_by: userData[0].id,
          }}
          createTemplate={setCreateTemplate}
          uploadPreview={setUploadPreview}
          browseFile={setBrowseFile}
        />
      )}
    </Layout>
  );
}

export default CreateTemplate;
