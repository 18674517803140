import { axiosInstance } from "../utils/common";

export const createNotificationApi = (notificationBody) => {
  return axiosInstance
    .post("v1/notifications/", notificationBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getNotificationsApi = (userId) => {
  return axiosInstance
    .get(`v1/notifications/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const markNotificationAsReadApi = (notificationId) => {
  return axiosInstance
    .patch(`v1/notifications/${notificationId}/read`, { read: true })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const markAllNotificationsAsReadApi = (userId) => {
  return axiosInstance
    .patch(`v1/notifications/${userId}/mark-all-read`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const removeNotificationApi = (notificationId) => {
  return axiosInstance
    .delete(`v1/notifications/${notificationId}/remove`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const removeAllNotificationsApi = (userId) => {
  return axiosInstance
    .delete(`v1/notifications/${userId}/remove-all`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
