import React, { useState, useEffect } from "react";
import { Row, Col, Layout, Tabs, Checkbox, Form, Input, Popover } from "antd"; //antd components
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/style/setup.css";
import "../../assets/style/settings.css";
import TopHeader from "../../components/Header";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../../store/store";
import { getUsersByRole } from "../../api/usersApi";
import { dataTable } from "../../utils/common";
import { associationCol } from "../../utils/tableColumns";
import HeaderCard from "../HeaderCard";
import DataTable from "../Tables/DataTable";
import RoleAccessController from "./RoleAccessController";

const ViewRole = () => {
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold the header local data
  let header = headerChanger.header;
  // State variable to hold key of local component
  const [tabkey, setKey] = useState("1");
  // Variable to use navigate function
  const navigate = useNavigate();
  // Variable to hold local form values
  let { state } = useLocation();
  // Assign the header values into state variable
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  const [associationData, setAssociationData] = useState([]);
  const checked = state && state.page_access ? state.page_access : {};
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedPrimaryContracts, setSelectedPrimaryContracts] = useState([]);
  const [selectedSecondaryContracts, setSelectedSecondaryContracts] = useState(
    []
  );

  useEffect(() => {
    getUsersByRole(state.id ? state.id : state._id).then((res) => {
      console.log("user res", res);
      let association = res.map((data) => {
        let tableRow = {
          associated_item: "User",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "userManagement",
                  headerItem: "2",
                  headerItemName: "userManagement",
                  stateSetter: data,
                });
                navigate(`/user-management/user/view`, {
                  state: data,
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.first_name + " " + data.last_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        return tableRow;
      });
      setAssociationData(association);
    });
  }, [state]);

  // setSelectedSuppliers(
  //   (state.access_rights[0] && state.access_rights[0].suppliers) || []
  // );
  // setSelectedPrimaryContracts(
  //   (state.access_rights[0] && state.access_rights[0].primary) || []
  // );
  // setSelectedSecondaryContracts(
  //   (state.access_rights[0] && state.access_rights[0].secondary) || []
  // );

  useEffect(() => {
    const accessRights = Array.isArray(state.access_rights)
      ? state.access_rights[0] || {}
      : state.access_rights || {};

    setSelectedSuppliers(accessRights.suppliers || []);
    setSelectedPrimaryContracts(accessRights.primary || []);
    setSelectedSecondaryContracts(accessRights.secondary || []);
  }, [state]);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  // Function to handle the return of the data from the API
  const roleDetails = () => {
    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="legal-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="role_name"
                    label="ROLE NAME"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="role_description"
                    label="ROLE DESCRIPTION"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="data_access"
                    label="DATA ACCESS"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" />
                  </Form.Item>
                </Col> */}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  // Function to return the page access for Contrct and System setup
  const pageAccess = () => {
    return (
      <div className="create-supplier-div">
        <Row>
          <Col span={24}>
            <div className="create-user-entity-div">
              <h4 className="access-title">Contracts</h4>
              <div className="access-content">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[4, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess-div">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Contract Tree</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.contracts.contract_tree.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>View Contract</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.contracts.view_contract.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Contract Pipeline</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.contracts.contract_pipeline.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Create Contract</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.contracts.create_contract.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.contracts.create_contract.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="create-user-entity-div mt-30">
              <h4 className="access-title">Contract Authority</h4>
              <div className="access-content">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[4, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess-div">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Clause Library</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.contract_authority.clause_library.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.contract_authority.clause_library
                                    .create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Templates</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.contract_authority.templates.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.contract_authority.templates.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="create-user-entity-div mt-30">
              <h4 className="access-title">Action Management</h4>
              <div className="access-content">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[4, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess-div">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Root Action</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.action_management.root_action.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.action_management.root_action.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Action Instance</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.action_management.action_instance.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.action_management.action_instance
                                    .create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="create-user-entity-div mt-30">
              <h4 className="access-title">Reports</h4>
              <div className="access-content">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[4, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess-div">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Build Report</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.reports.build_report.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.reports.build_report.create}
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Scheduled Report</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.reports.scheduled_report.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.reports.scheduled_report.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="create-user-entity-div mt-30">
              <h4 className="access-title">System Setup</h4>
              <div className="access-content">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[4, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess-div">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Supplier Setup</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.supplier_setup.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.supplier_setup.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Legal Entity Setup</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.legal_entity_setup.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.legal_entity_setup.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Cost Center Setup</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.cost_center_setup.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.cost_center_setup.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Rate Card Setup</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.ratecard_setup.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.ratecard_setup.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Fx Setup</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.system_setup.fx_setup.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.system_setup.fx_setup.create}
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Indexation</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.system_setup.indexation.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.system_setup.indexation.create}
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Pricing Profile</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.pricing_profile.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.pricing_profile.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Invoicing Profile</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.invoicing_profile.view
                                }
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={
                                  checked.system_setup.invoicing_profile.create
                                }
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="create-pageaccess">
                          <Row type="flex">
                            <Col span={16}>
                              <p>Workflow</p>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.system_setup.workflow.view}
                              >
                                View
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox
                                checked={checked.system_setup.workflow.create}
                              >
                                Create
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  // Function to return the entity access for create, view and action
  const dataAccess = () => {
    return (
      // <div className="create-supplier-div">
      //   <Row>
      //     <Col span={24}>
      //       <div className="create-user-entity-div">
      //         <h4 className="access-title">Select Data</h4>

      //         <div className="access-content">
      //           <Row
      //             align="middle"
      //             type="flex"
      //             gutter={[4, 16]}
      //             className="m-0 mt-30"
      //           >
      //             <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      //               {state &&
      //                 state.entity_rights &&
      //                 state.entity_rights.length > 0 &&
      //                 state.entity_rights.map((added) => {
      //                   console.log("addedd on return", added);
      //                   return (
      //                     <Row
      //                       align="middle"
      //                       type="flex"
      //                       gutter={[4, 16]}
      //                       className="m-0 mt-10"
      //                       key={added.supplier}
      //                     >
      //                       <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      //                         <div className="added-div">
      //                           <Col span={24} className="added-data-col">
      //                             <Row
      //                               align="middle"
      //                               type="flex"
      //                               gutter={[24, 16]}
      //                             >
      //                               <Col span={8}>
      //                                 <h5>SUPPLIER</h5>
      //                                 <Input
      //                                   className="ellipsis-input mt-10"
      //                                   defaultValue={added.supplier_name}
      //                                   readOnly
      //                                 />
      //                               </Col>

      //                               <Col span={8}>
      //                                 <h5>MSA</h5>
      //                                 <Popover
      //                                   content={
      //                                     <div>
      //                                       {added.msa.length > 0 ? (
      //                                         added.msa.map((item, index) => (
      //                                           <div key={index}>
      //                                             {item.msa_name}
      //                                           </div>
      //                                         ))
      //                                       ) : (
      //                                         <div key={1}>N/A</div>
      //                                       )}
      //                                     </div>
      //                                   }
      //                                   trigger="click"
      //                                 >
      //                                   <Input
      //                                     className="ellipsis-input mt-10"
      //                                     value={
      //                                       added.msa.length > 0
      //                                         ? added.msa
      //                                             .map((val) => val.msa_name)
      //                                             .join(", ")
      //                                         : "N/A"
      //                                     }
      //                                     readOnly
      //                                   />
      //                                 </Popover>
      //                               </Col>

      //                               <Col span={8}>
      //                                 <h5>CONTRACT</h5>
      //                                 <Popover
      //                                   content={
      //                                     <div>
      //                                       {added.contract.length > 0 ? (
      //                                         added.contract.map(
      //                                           (item, index) => (
      //                                             <div key={index}>
      //                                               {item.contract_name}
      //                                             </div>
      //                                           )
      //                                         )
      //                                       ) : (
      //                                         <div key={1}>N/A</div>
      //                                       )}
      //                                     </div>
      //                                   }
      //                                   trigger="click"
      //                                 >
      //                                   <Input
      //                                     value={
      //                                       added.contract.length > 0
      //                                         ? added.contract
      //                                             .map(
      //                                               (val) => val.contract_name
      //                                             )
      //                                             .join(", ")
      //                                         : "N/A"
      //                                     }
      //                                     readOnly
      //                                     className="ellipsis-input mt-10"
      //                                   />
      //                                 </Popover>
      //                               </Col>
      //                             </Row>
      //                           </Col>
      //                         </div>
      //                       </Col>
      //                     </Row>
      //                   );
      //                 })}
      //             </Col>
      //           </Row>
      //         </div>
      //       </div>
      //     </Col>
      //   </Row>
      // </div>
      <div className="create-supplier-div">
        <RoleAccessController
          mode={{ view: true, edit: false, create: false }}
          selectedSuppliers={selectedSuppliers}
          setSelectedSuppliers={setSelectedSuppliers}
          selectedPrimaryContracts={selectedPrimaryContracts}
          setSelectedPrimaryContracts={setSelectedPrimaryContracts}
          selectedSecondaryContracts={selectedSecondaryContracts}
          setSelectedSecondaryContracts={setSelectedSecondaryContracts}
        />
      </div>
    );
  };

  const Associations = () => {
    return <DataTable type="association" dataTableData={associationData} />;
  };

  // Variable to hold a two components of page access and entity access
  const items = [
    {
      key: "1",
      label: `Role Details`,
      children: roleDetails(),
    },
    {
      key: "2",
      label: `Page Access`,
      children: pageAccess(),
    },
    {
      key: "3",
      label: `Data Access`,
      children: dataAccess(),
    },
    {
      key: "4",
      label: `Association`,
      children: Associations(),
    },
  ];

  return (
    <Layout>
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      <div className="system-setup1">
        <Form
          className="mt-10 create-role"
          autoComplete="off"
          initialValues={state && state}
        >
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={20}
              md={21}
              lg={21}
              xl={24}
            >
              <HeaderCard
                mainMenu="User Management"
                subMenu="Roles"
                itemName={state.role_name}
                itemStatus={state.status}
                goBackLink="/user-management/?back=set-roles"
                state={state}
                showDetails={"view"}
              />
            </Col>
          </Row>
          <div className="scrolling-content">
            <Tabs defaultActiveKey={tabkey} items={items} className="m-15" />
          </div>
        </Form>
      </div>
    </Layout>
  );
};

export default ViewRole;
