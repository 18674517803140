import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  message,
  Upload,
  Layout,
  Tabs,
  Card,
  Image,
  Select,
  DatePicker,
} from "antd"; //antd components
import { RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { getAllRolesApi } from "../../api/rolesApi";
import {
  userDataAtom,
  HeaderChangerAtom,
  SupplierTreeDataAtom,
} from "../../store/store";
import PopupModal from "../../PopupModal";
import { useNavigate, useLocation } from "react-router-dom";
import { CancelButton } from "../GlobalButton";
import TopHeader from "../Header";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../assets/images/icons/arrow_back_ios.svg";
import { getGroupByUser } from "../../api/groupsApi";
import { gettRootActionByUser } from "../../api/actionManagementApi";
import { getWorkflowByUser } from "../../api/workflowApi";
import { getContractByUser } from "../../api/contractsApi";
import ContractFile from "../Contract/ContractFile";
import { getUsersApi } from "../../api/usersApi";
import dayjs from "dayjs";
import { dataTable } from "../../utils/common";
import { associationCol } from "../../utils/tableColumns";
import HeaderCard from "../HeaderCard";
import DataTable from "../Tables/DataTable";

function ViewUser() {
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom); //supplier tree data from global state
  // Variable to use navigate function
  const navigate = useNavigate();
  // Variable to hold particular legal entity data
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  const oldProfilePicture = state["profile_picture"];
  const modifiedUserData = {
    ...state,
    role:
      state &&
      state.roleData &&
      state.roleData.length !== 0 &&
      state.roleData[0].role_name,
  };
  // Remove profile_picture from modifiedUserData
  delete modifiedUserData["profile_picture"];
  // State variable to hold user information
  const authorData = useRecoilState(userDataAtom);
  // State variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // State variable to hold table loading visibility status
  const [tableLoader, setTableLoader] = useState(false);
  const menuKey = "7";
  // State variable to hold role data
  const [roleData, setRoleData] = useState([]);

  // Variable to hold local header data
  let header = headerChanger.header;
  // variabel hold the button visibility css
  let disableVal = "disable-btn";
  const [profilePictureBase64, setProfilePictureBase64] = useState(null);
  const [associationData, setAssociationData] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [userData, setUserData] = useState([]);
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);

  /* Function to handle the get all roles  */
  const getAllRoles = () => {
    getAllRolesApi().then((res) => {
      setRoleData(res.filter((res) => res.role_name !== "Admin"));
    });
  };

  /*  Function that will be called when the component initiate and call the getAllRoles function */
  useEffect(() => {
    getAllRoles();
    getUsersApi().then((res) => {
      console.log("user-res", res);
      setUserData(res); // set response from api to 'users' state
    });
  }, []);

  const [responseData, setResponseData] = useState([]); //tree data
  useEffect(() => {
    // set supplier tree data from global state
    if (supplierTreeData.length) {
      setResponseData([...supplierTreeData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierTreeData]);

  // secondary function for set path of selected node
  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    setHeaderChanger({
      header: "systemSetup",
      headerItem: "2",
      headerItemName: "systemSetup",
    });
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    let allAssociations = [];
    getGroupByUser(state.email).then((res) => {
      console.log("group res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Group",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "userManagement",
                  headerItem: "3",
                  headerItemName: "userManagement",
                  stateSetter: data,
                });
                navigate(`/user-management/groups/view`, {
                  state: data,
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.group_name}
            </span>
          ),
          association_date: moment(data.created_on).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    gettRootActionByUser(state._id).then((res) => {
      console.log("action res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Root Action",
          name: (
            <span
              onClick={() => {
                let finalPathOnModify = [
                  { id: "home", name: "home", type: "" },
                ];
                responseData.some((res) => {
                  const pathVal = getPath(res, data._id);
                  if (pathVal) {
                    finalPathOnModify = [...finalPathOnModify, ...pathVal];
                  }
                });
                setHeaderChanger({
                  header: "actionManagement",
                  headerItem: "1",
                  headerItemName: "actionManagement",
                  stateSetter: {
                    actionData: data,
                    finalPath: finalPathOnModify,
                  },
                });
                navigate(`/action-management/view-action`, {
                  state: { actionData: data, finalPath: finalPathOnModify },
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.action_name}
            </span>
          ),
          association_date: moment(data.created_on).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    getContractByUser(state._id).then((res) => {
      console.log("contract res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Contract",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "contract",
                  headerItem: "2",
                  headerItemName: "contract",
                  stateSetter: data,
                });
                getTopPath(data);
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.contract_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    getWorkflowByUser(state._id).then((res) => {
      console.log("wf res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Workflow",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "systemSetup",
                  headerItem: "2",
                  headerItemName: "systemSetup",
                  stateSetter: data,
                });
                navigate(`/contract-setup/view/workflow`, {
                  state: data,
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.workflow_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    setAssociationData(allAssociations);
  }, [state]);

  const props = {
    name: "file",
    listType: "picture",
    showUploadList: {
      showRemoveIcon: false,
    },
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length !== 0) {
          const reader = new FileReader();
          reader.readAsDataURL(info.file.originFileObj);
          reader.onloadend = () => {
            setProfilePictureBase64(reader.result);
          };
        } else {
          setProfilePictureBase64(null);
        }
      }

      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          const reader = new FileReader();
          reader.readAsDataURL(info.file.originFileObj);
          reader.onloadend = () => {
            setProfilePictureBase64(reader.result);
          };
        } else {
          setProfilePictureBase64(null);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/svg+xml",
      ];
      const formatCheck = acceptedFormats.includes(file.type);

      if (!formatCheck) {
        message.error({
          content:
            "You can only upload JPEG, JPG, PNG, GIF, BMP, WEBP, or SVG images.",
          top: 150,
        });
        return Upload.LIST_IGNORE;
      }

      const isSizeValid = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB

      if (!isSizeValid) {
        message.error({
          content: "File size must be less than 10MB.",
          top: 150,
        });
        return Upload.LIST_IGNORE;
      }
      return isSizeValid;
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // Function to handle the return of the data from the API
  const UserDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="legal-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="first_name"
                    label="FIRST NAME"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="last_name"
                    label="LAST NAME"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="role"
                    label="ROLE"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="line_manager"
                    label="LINE MANAGER"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" type="email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="country"
                    label="Country"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" type="email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="timezone"
                    label="TimeZone"
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Input readOnly placeholder="" className="" type="email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="profile_picture"
                    label="PROFILE PICTURE"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    className="org-supplier-label"
                    colon={false}
                  >
                    <Upload
                      name="userProfilePicture"
                      {...props}
                      customRequest={dummyRequest}
                      headers={{
                        "Access-Control-Allow-Origin": "*",
                      }}
                      defaultFileList={
                        oldProfilePicture
                          ? [
                              {
                                uid: "-1",
                                name: "Current Profile Picture",
                                status: "done",
                                url: oldProfilePicture,
                              },
                            ]
                          : []
                      }
                      onRemove={() => setProfilePictureBase64(null)}
                      className="custom-upload-wrapper"
                    ></Upload>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const Delegation = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="legal-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    name="delegated_to"
                    label="DELEGATED TO"
                    className="org-supplier-label"
                    colon={false}
                    initialValue={
                      state &&
                      state.delegated_details &&
                      state.delegated_details.delegated_to
                        ? state.delegated_details.delegated_to
                        : "N/A"
                    }
                  >
                    <Select
                      disabled
                      className=""
                      placeholder="choose user"
                      options={userData
                        .filter((data) => data._id !== state._id)
                        .map((user) => ({
                          key: user._id,
                          label: `${user.first_name} ${user.last_name}`,
                          value: user._id,
                        }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="delegated_start_date"
                    label="DELEGATED START DATE"
                    colon={false}
                    initialValue={
                      state &&
                      state.delegated_details &&
                      state.delegated_details.delegated_start_date
                        ? dayjs(state.delegated_details.delegated_start_date)
                        : undefined
                    }
                  >
                    <DatePicker
                      disabled
                      format={"DD MMM YYYY"}
                      className="report-input"
                      placeholder="Select Start Date"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="delegated_end_date"
                    label="DELEGATED END DATE"
                    colon={false}
                    initialValue={
                      state &&
                      state.delegated_details &&
                      state.delegated_details.delegated_end_date
                        ? dayjs(state.delegated_details.delegated_end_date)
                        : undefined
                    }
                  >
                    <DatePicker
                      disabled
                      format={"DD MMM YYYY"}
                      className="report-input"
                      placeholder="Select End Date"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const Associations = () => {
    return <DataTable type="association" dataTableData={associationData} />;
  };

  // Variable to hold the component returned by the function.
  const items = [
    {
      key: "1",
      label: `User Details`,
      children: UserDetails(),
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
    },
    {
      key: "3",
      label: `Delegation`,
      children: Delegation(),
    },
  ];

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {!selectedContract && (
        <div className="system-setup1">
          <Form
            className="mt-10 create-role"
            autoComplete="off"
            initialValues={modifiedUserData}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={20}
                md={21}
                lg={21}
                xl={22}
              >
                <HeaderCard
                  mainMenu="User Management"
                  subMenu="View User Details"
                  itemName={`${state.first_name} ${state.last_name}`}
                  // itemStatus={state.status}
                  goBackLink="/user-management/?back=set-users"
                  // state={""}
                  // showDetails={"create"}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <CancelButton
                  onClick={() => navigate(`/user-management/?back=set-users`)}
                />
              </Col>
            </Row>

            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs items={items} />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      )}
      {selectedContract && (
        <div className="pipeline-click">
          <Row
            align="top"
            type="flex"
            gutter={[8, 0]}
            className="m-10"
            style={{ display: "none" }}
          >
            <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
              <div className="pipeline-breadcrumb mt-10">
                <span>
                  <Image
                    src={arrow_left}
                    style={{ width: 25 }}
                    preview={false}
                    onClick={() => {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "1",
                        headerItemName: "systemSetup",
                      });
                      setSelectedContract();
                    }}
                    className="cursor-pointer"
                  />
                  <span className="breadcrumb-path">
                    {finalPath &&
                      finalPath.map((item, i) => {
                        return (
                          <>
                            <span key={item.id} className="path-text-pipeline">
                              {item.name === "home" ? "Suppliers" : item.name}
                            </span>
                            <span
                              style={{
                                display:
                                  i === finalPath.length - 1
                                    ? "none"
                                    : "inline-block",
                              }}
                              className="path-icon"
                            >
                              <RightOutlined />
                            </span>
                          </>
                        );
                      })}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row type="flex" gutter={[8, 0]}>
            <Col span={24}>
              <ContractFile
                pipelinePath={finalPath}
                backButton={backButton}
                selected={selectedContract}
                changeHomeTab={changeHomeTab}
              />
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
}

export default ViewUser;
