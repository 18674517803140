import React, { useEffect, useState, useRef } from "react";
import { HeaderChangerAtom } from "../../store/store";
import TopHeader from "../../components/Header";
import { useRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import info from "../../assets/images/icons/info.svg";
import close from "../../assets/images/icons/close.svg";
import attach from "../../assets/images/icons/attach.svg";
import send from "../../assets/images/icons/send.svg";
import edit_temp from "../../assets/images/icons/dot_pointer.svg";
import "../../assets/style/onlineauthoring.css";
import { Divider, Steps } from "antd";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
} from "../../components/GlobalButton";
import {
  Layout,
  Row,
  Col,
  Card,
  Form,
  Upload,
  Input,
  Table,
  Select,
  DatePicker,
  Space,
  Image,
  FloatButton,
} from "antd"; //antd components

import { suppliersOnlyApi } from "../../api/suppliersApi";
import { getAllPricingProfileApi } from "../../api/pricingProfileApi";
import { getAllFxTableApi } from "../../api/fxTableApi";
import {
  getAllWorkflowsApi,
  getAllWorkflowsOnlyApi,
} from "../../api/workflowApi";
import { getAllInvoicingProfileApi } from "../../api/invoicingProfileApi";
import { listCostCenter } from "../../api/costCenterApi";

function OnlineAuthoring() {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  const menuKey = "2";
  const history = useNavigate();
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  // const [contractCoverFile, setcontractCoverFile] = useState(false);
  // const [contractAuthoringFile, setContractAuthoringFile] = useState(false);
  // state varaiable hold the supplier data from fetch api
  const [suppliersList, setSuppliersList] = useState([]);
  // state  varaiable hold the pricing profile data from fetch in api
  const [pricingProfile, setPricingProfile] = useState([]);
  // state  varaiable hold the fx data from fetch in api
  const [fxProfile, setFxProfile] = useState([]);
  // state  varaiable hold the invoiceing profile data from fetch in api
  const [invoiceingProfileData, SetInvoiceingProfileData] = useState([]);
  // state  varaiable hold the cost Center profile data from fetch in api
  const [costCenterData, setCostCenterData] = useState([]);
  //To handle comments values
  const [commentEntered, setCommentEntered] = useState("");
  const [comments, setComments] = useState([]);
  const handleTabItemClick = (event) => {
    console.log("Clicked:", event.target.textContent);
  };

  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const disabledDate = (dateValue, field) => {
    if (field === "start_date") {
      if (!childDate.endDate) {
        return false;
      }
      return dateValue.isAfter(childDate.endDate, "day");
    } else {
      if (!childDate.startDate) {
        return false;
      }
      return dateValue.isBefore(childDate.startDate, "day");
    }
  };

  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    console.log("onChange:", value);
    setCurrent(value);
  };

  const description = "";

  const [childDate, setChildDate] = useState({
    startDate: "",
    endDate: "",
  });

  const column = [
    {
      title: "RESOURCE TYPE",
      dataIndex: "resource_type",
      key: "resource_type",
    },
    {
      title: "RESOURCE GRADE",
      dataIndex: "resource_grade",
      key: "resource_grade",
    },
    {
      title: "LOCATION",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "START DATE",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "END DATE",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "CHARGING YEAR",
      dataIndex: "charging_year",
      key: "charging_year",
    },
    {
      title: "RATE",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "DAYS",
      dataIndex: "days",
      key: "days",
    },
  ];

  const data = [
    {
      key: "1",
      resource_type: "-",
      resource_grade: "-",
      location: "-",
      start_date: "-",
      end_date: "-",
      charging_year: "-",
      rate: "-",
      days: "-",
    },
    {
      key: "2",
      resource_type: "-",
      resource_grade: "-",
      location: "-",
      start_date: "-",
      end_date: "-",
      charging_year: "-",
      rate: "-",
      days: "-",
    },
    {
      key: "3",
      resource_type: "-",
      resource_grade: "-",
      location: "-",
      start_date: "-",
      end_date: "-",
      charging_year: "-",
      rate: "-",
      days: "-",
    },
  ];

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  useEffect(() => {
    suppliersOnlyApi().then((data) => {
      setSuppliersList(data.filter((res) => res.id === state.supplier_id));
    });

    getAllPricingProfileApi().then((res) => {
      let filteredPP = res.filter(
        (data) => data.supplier_id === state.supplier_id
      );
      setPricingProfile(filteredPP);
    });

    getAllInvoicingProfileApi().then((res) => {
      SetInvoiceingProfileData(
        res.filter((data) => data.supplier_id === state.supplier_id)
      );
    });

    getAllFxTableApi().then((res) => {
      console.log("fp-res", res);
      let filteredFP = res.filter(
        (data) => data.supplier_id === state.supplier_id
      );
      setFxProfile(filteredFP);
    });
  }, [state]);
  console.log("state values***************", state);
  console.log("state values name ", state.title);
  console.log("templatename", state.contract_template.contract_template_name);

  const onChangeComment = (e) => {
    setCommentEntered(e.target.value);
  };

  const onAddComment = () => {
    if (commentEntered !== "") {
      setComments([...comments, `text-${commentEntered}`]);
      setCommentEntered("");
    }
  };
  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <div className="system-setup1">
        <Form
          name="online_Authoring"
          //  onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          initialValues={{
            ["Title"]: state.title,
            // ["supplier_name_short"]: state.supplier_name_short,
          }}
        >
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={18}
            >
              {/* <Card className="setup-header-card"> */}
              <Row align={"middle"} type="flex">
                <Col span={24} align="center" type="flex">
                  <div className="card-header">
                    <Row className="headerlist" type="flex" gutter={[8, 16]}>
                      <Col span={12} type="flex" className="tablist-div">
                        <div className="list-div">
                          <div
                            className={`tab-item ${
                              activeTab === "tab1" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab1")}
                            style={{
                              backgroundColor:
                                activeTab === "tab1"
                                  ? "lightgreen"
                                  : "lightgrey",
                              color: "white",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius:
                                activeTab === "tab1" ? "20px" : "0px",
                              borderRadius:
                                activeTab !== "tab1" ? "20px" : "0px",
                            }}
                          >
                            1
                          </div>

                          <div
                            className={`tab-item ${
                              activeTab === "tab2" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab2")}
                            style={{
                              backgroundColor:
                                activeTab === "tab2"
                                  ? "lightgreen"
                                  : "lightgrey",
                              color: "white",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius:
                                activeTab === "tab2" ? "20px" : "0px",
                              borderRadius:
                                activeTab !== "tab2" ? "20px" : "0px",
                            }}
                          >
                            2
                          </div>
                          <div
                            className={`tab-item ${
                              activeTab === "tab3" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab3")}
                            style={{
                              backgroundColor:
                                activeTab === "tab3"
                                  ? "lightgreen"
                                  : "lightgrey",
                              color: "white",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius:
                                activeTab === "tab3" ? "20px" : "0px",
                              borderRadius:
                                activeTab !== "tab3" ? "20px" : "0px",
                            }}
                          >
                            3
                          </div>
                          <div
                            className={`tab-item ${
                              activeTab === "tab4" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab4")}
                            style={{
                              backgroundColor:
                                activeTab === "tab4"
                                  ? "lightgreen"
                                  : "lightgrey",
                              color: "white",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius:
                                activeTab === "tab4" ? "20px" : "0px",
                              borderRadius:
                                activeTab !== "tab4" ? "20px" : "0px",
                            }}
                          >
                            4
                          </div>
                          <div
                            className={`tab-item ${
                              activeTab === "tab5" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("tab5")}
                            style={{
                              backgroundColor:
                                activeTab === "tab5"
                                  ? "lightgreen"
                                  : "lightgrey",
                              color: "white",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius:
                                activeTab === "tab5" ? "20px" : "0px",
                              borderRadius:
                                activeTab !== "tab5" ? "20px" : "0px",
                            }}
                          >
                            5
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              {/* </Card> */}
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <CancelButton
                onClick={() => {
                  history(
                    "/contract",
                    setHeaderChanger({
                      header: "contract",
                      headerItem: "3",
                      headerItemName: headerChanger.headerItemName,
                    })
                  );
                }}
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SaveButton
                // onClick={() => (State.button = 1)}
                type="primary"
                htmlType="submit"
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SubmitButton
                // onClick={() => (State.button = 2)}
                type="primary"
                htmlType="submit"
              />
            </Col>

            {activeTab === "tab1" && (
              <div className="create-supplier-div">
                <Row>
                  <Col span={24} align="center" type="flex">
                    <div className="commerical mt-10">
                      <h3>Contract Cover</h3>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={24}>
                        <div className="view-div mt-15">
                          <Row
                            align="middle"
                            type="flex"
                            style={{ padding: "10px 30px 0px" }}
                          >
                            <Col
                              span={24}
                              align="left"
                              className="vertical-align"
                            >
                              <h4 className="entity-text">
                                About the Contract
                              </h4>
                            </Col>
                          </Row>
                          <hr className="mt-10 view-supplier-hr" />
                          <div className="m-20">
                            <Row>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Title"
                                  label="TITLE"
                                  colon={false}
                                  // initialValue={state && state.title}
                                  // value="dumy title"
                                >
                                  <Input
                                    readOnly
                                    placeholder="Enter your title"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Client Entity"
                                  label="CLIENT ENTITY"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Client Entity"
                                    className="basic-input"
                                    allowClear={true}
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Supplier Entity"
                                  label="SUPPLIER ENTITY"
                                  colon={false}
                                >
                                  <Select
                                    placeholder="Select Supplier Entity Name"
                                    className="basic-input"
                                    allowClear={true}
                                    options={
                                      suppliersList &&
                                      suppliersList["0"] &&
                                      suppliersList["0"].supplier_entities &&
                                      suppliersList["0"].supplier_entities.map(
                                        (data) => ({
                                          key: data.supplier_entity,
                                          label: data.supplier_entity,
                                          value: data.supplier_entity,
                                        })
                                      )
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Business Area Department"
                                  label="BUSINESS AREA/DEPARTMENT"
                                  colon={false}
                                >
                                  <Select
                                    placeholder="Select Business Area / Department"
                                    className="basic-input"
                                    allowClear={true}
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Contract Start Date"
                                  label="CONTRACT START DATE"
                                  colon={false}
                                >
                                  <DatePicker
                                    disabledDate={(dateValue) =>
                                      disabledDate(dateValue, "start_date")
                                    }
                                    format="DD MMM YYYY"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Contract End Date"
                                  label="CONTRACT END DATE"
                                  colon={false}
                                >
                                  <DatePicker
                                    disabledDate={(dateValue) =>
                                      disabledDate(dateValue, "end_date")
                                    }
                                    format="DD MMM YYYY"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Priority"
                                  label="PRIORITY"
                                  colon={false}
                                >
                                  <Select
                                    placeholder="Select Priority"
                                    className="basic-input"
                                    allowClear={true}
                                  >
                                    <Select.Option value="P1">P1</Select.Option>
                                    <Select.Option value="P2">P2</Select.Option>
                                    <Select.Option value="P3">P3</Select.Option>
                                    <Select.Option value="P4">P4</Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Contract Currency"
                                  label="CONTRACT CURRENCY"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Contract Currency"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Contract Value"
                                  label="CONTRACT VALUE"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Contract Value"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="view-div mt-15">
                          <Row
                            align="middle"
                            type="flex"
                            style={{ padding: "10px 30px 0px" }}
                          >
                            <Col
                              span={24}
                              align="left"
                              className="vertical-align"
                            >
                              <h4 className="entity-text">About the Scope</h4>
                            </Col>
                          </Row>
                          <hr className="mt-10 view-supplier-hr" />
                          <div className="m-20">
                            <Row>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Scope"
                                  label="SCOPE"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Scope"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Scope In Detail"
                                  label="SCOPE IN DETAIL"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Scope In Detail"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Assumptions"
                                  label="ASSUMPTIONS"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Assumptions"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Dependences"
                                  label="DEPENDENCIES"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Dependences"
                                    className="basic-input"
                                    allowClear={true}
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Other Details"
                                  label="OTHER DETAILS"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter Other Details"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="view-div mt-15">
                          <Row
                            align="middle"
                            type="flex"
                            style={{ padding: "10px 30px 0px" }}
                          >
                            <Col
                              span={24}
                              align="left"
                              className="vertical-align"
                            >
                              <h4 className="entity-text">About the People</h4>
                            </Col>
                          </Row>
                          <hr className="mt-10 view-supplier-hr" />
                          <div className="m-20">
                            <Row>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Department Head"
                                  label="DEPARTMENT HEAD"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter your Department Head"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Department Lead"
                                  label="DEPARTMENT LEAD"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter your Department Lead"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Department Manager"
                                  label="DEPARTMENT MANAGER"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter your Department Manager"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Contract Manager"
                                  label="CONTRACT MANAGER"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter your Contract Manager"
                                    className="basic-input"
                                    allowClear={true}
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Supllier Partner Lead"
                                  label="SUPPLIER PARTNER LEAD"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter your Supllier Partner Lead"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="Supllier Partner Manager"
                                  label="SUPPLIER PARTNER MANAGER"
                                  colon={false}
                                >
                                  <Input
                                    placeholder="Enter your Supllier Partner Manage"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <div className="view-div mt-15">
                          <Row
                            align="middle"
                            type="flex"
                            style={{ padding: "10px 30px 0px" }}
                          >
                            <Col
                              span={24}
                              align="left"
                              className="vertical-align"
                            >
                              <h4 className="entity-text">
                                About the Financials
                              </h4>
                            </Col>
                          </Row>
                          <hr className="mt-10 view-supplier-hr" />
                          <div className="m-20">
                            <Row>
                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  colon={false}
                                  name="Pricing Profile"
                                  label="PRICING PROFILE"
                                >
                                  <Select
                                    // onChange={(key, value) =>
                                    //   handlePricingProfile(key, value)
                                    // }
                                    allowClear
                                    className="basic-input"
                                    showSearch={true}
                                    placeholder="Choose Pricing Profile"
                                    options={
                                      pricingProfile &&
                                      pricingProfile.map((data) => ({
                                        key: data._id,
                                        label: data.price_profile_name,
                                        value: data._id,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  colon={false}
                                  name="Invoicing Profile"
                                  label="INVOICING PROFILE"
                                >
                                  <Select
                                    placeholder="Choose Invoice Profile"
                                    className="basic-input"
                                    allowClear={true}
                                    options={
                                      invoiceingProfileData &&
                                      invoiceingProfileData.map((data) => ({
                                        key: data._id,
                                        label: data.invoice_profile_name,
                                        value: data._id,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  colon={false}
                                  name="Fx Profile"
                                  label="FX PROFILE"
                                >
                                  <Select
                                    allowClear
                                    className="basic-input"
                                    showSearch={true}
                                    placeholder="Choose Fx Profile"
                                    options={
                                      fxProfile &&
                                      fxProfile.map((data) => ({
                                        key: data._id,
                                        label: data.fx_table_name,
                                        value: data._id,
                                      }))
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  colon={false}
                                  name="Contract Pricing"
                                  label="CONTRACT PRICING"
                                >
                                  <Input
                                    placeholder="Enter Contract Pricing"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} md={24} lg={12} xl={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  colon={false}
                                  name="Contracting Currency"
                                  label="CONTRACTING CURRENCY"
                                >
                                  <Input
                                    placeholder="Enter Contracting Currency"
                                    className="basic-input"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}

            {activeTab === "tab2" && (
              <Col span={24}>
                <div className="create-supplier-div">
                  <Row>
                    <Col span={24} align="center" type="flex">
                      <div className="commerical mt-10">
                        <h3>Contract Authoring</h3>
                        <Row align="middle" type="flex">
                          <Col span={24}>
                            <Row>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="contract_template"
                                  label="CONTRACT TEMPLATE"
                                  colon={false}
                                  initialValue={
                                    state &&
                                    state.contract_template
                                      .contract_template_name
                                  }
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]}>
                    <Col span={9} className="vertical-align">
                      <div className="org-contract-div">
                        <Space align="">
                          <div
                            style={{
                              marginTop: "10px",
                              paddingLeft: "20px",
                            }}
                          >
                            <Row>
                              <Col>
                                <h3
                                  style={{
                                    color: "#2684ff",
                                  }}
                                >
                                  Extracted Clause Headers
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        </Space>
                        <hr className="mt-10 view-supplier-hr" />
                        {state.contract_template.clause_content.map(
                          (item, index) => (
                            <Form.Item key={index}>
                              <div key={index}>
                                {/* Wrap each Input in a separate div */}
                                <Row
                                  type="flex"
                                  align="middle"
                                  className="template-header"
                                >
                                  <Col span={21} className="vertical-align">
                                    <Input
                                      className="templateSetup-2"
                                      //disabled={index !== headerEditIndex}
                                      value={item.h}
                                      // onChange={(e) =>
                                      //   handleEdit(index, "h", e)
                                      // }
                                    />
                                  </Col>
                                  <Col span={3} className="vertical-align">
                                    <Space>
                                      <Image
                                        className="cursor-pointer"
                                        src={edit_temp}
                                        preview={false}
                                        // onClick={() => editHeader(index)}
                                      />
                                      <Image
                                        className="cursor-pointer"
                                        src={close}
                                        preview={false}
                                        // onClick={() =>
                                        //   deleteTemplateMeta(index)
                                        // }
                                      />
                                    </Space>
                                  </Col>
                                </Row>
                              </div>
                            </Form.Item>
                          )
                        )}

                        {/* <Row
                          type="flex"
                          align="middle"
                          className="template-header mt-15"
                        >
                          <Col span={21} className="vertical-align">
                            <p>2. Scope</p>
                          </Col>
                          <Col
                            span={2}
                            align="right"
                            className="vertical-align"
                          >
                            <Space>
                              <Image
                                className="cursor-pointer"
                                src={close}
                                preview={false}
                              />
                            </Space>
                          </Col>
                        </Row>

                        <Row
                          type="flex"
                          align="middle"
                          className="template-header mt-15"
                        >
                          <Col span={21} className="vertical-align">
                            <p>3. Commercial</p>
                          </Col>
                          <Col
                            span={2}
                            align="right"
                            className="vertical-align"
                          >
                            <Space>
                              <Image
                                className="cursor-pointer"
                                src={close}
                                preview={false}
                              />
                            </Space>
                          </Col>
                        </Row>

                        <Row
                          type="flex"
                          align="middle"
                          className="template-header mt-15"
                        >
                          <Col span={21} className="vertical-align">
                            <p>4. Bonds</p>
                          </Col>
                          <Col
                            span={2}
                            align="right"
                            className="vertical-align"
                          >
                            <Space>
                              <Image
                                className="cursor-pointer"
                                src={close}
                                preview={false}
                              />
                            </Space>
                          </Col>
                        </Row> */}
                      </div>
                    </Col>

                    <Col span={15} className="vertical-align">
                      <div className="org-contract-div">
                        <Space align="">
                          <div
                            style={{
                              marginTop: "10px",
                              paddingLeft: "20px",
                            }}
                          >
                            <h3
                              style={{
                                color: "#2684ff",
                              }}
                            >
                              Clause Content
                            </h3>
                          </div>
                        </Space>
                        <hr className="mt-10 view-supplier-hr" />
                        <>
                          {state.contract_template.clause_content.map(
                            (item, index) => (
                              <Form.Item key={index}>
                                <div key={index}>
                                  <Row
                                    type="flex"
                                    align="middle"
                                    // className="mt-15"
                                  >
                                    <Col span={23} className="vertical-align">
                                      <h3>{item.h}</h3>
                                    </Col>
                                    <Col
                                      span={1}
                                      className="vertical-align float-right"
                                    >
                                      <Image
                                        src={edit_temp}
                                        preview={false}
                                        className="cursor-pointer"
                                        // onClick={() => editParagraph(index)}
                                      />
                                    </Col>
                                  </Row>
                                  <Row
                                    type="flex"
                                    align="middle"
                                    className="mt-15"
                                  >
                                    <Col span={24}>
                                      {/* Render your text box here */}
                                      <Input
                                        className="templateSetup-1"
                                        // disabled={
                                        //   index !== paragraphEditIndex
                                        // }
                                        value={item.p}
                                        // onChange={(e) =>
                                        //   handleEdit(index, "p", e)
                                        // }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Item>
                            )
                          )}
                        </>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}

            {activeTab === "tab3" && (
              <Col span={24}>
                <div className="create-supplier-div">
                  <Col span={24} align="center" type="flex">
                    <div className="commerical mt-15">
                      <h3>Contract Commercial</h3>
                      <div className="contract-table">
                        <Table
                          id="workflow-table"
                          className="workflow-setup-table"
                          columns={column}
                          dataSource={data}
                          pagination={false}
                          style={{ padding: "0 30px " }}
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>
            )}

            {activeTab === "tab4" && (
              <Col span={24}>
                <div className="create-supplier-div">
                  <Row>
                    <Col span={24} align="center" type="flex">
                      <div className="commerical mt-10">
                        <h3>Contract Preview</h3>
                        <div className="create-supplier-div">
                          {/* // {state && state.title} */}
                          <div className="contract-preview mt-15">
                            {state && state.title}
                            {state &&
                              state.contract_template.clause_content.map(
                                (pdfObject) => {
                                  return (
                                    <div className="mt-15">
                                      <h4>{pdfObject.h} </h4>
                                      <p className="mt-10">
                                        {pdfObject.p !== "" ? (
                                          pdfObject.p
                                        ) : (
                                          <div className="empty-p"></div>
                                        )}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}

            {activeTab === "tab5" && (
              <Col span={24}>
                <div className="create-supplier-div">
                  <Row>
                    <Col span={24} align="center" type="flex">
                      <div className="commerical mt-10">
                        <h3>Review & Submit</h3>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]}>
                    <Col span={18} className="vertical-align">
                      <div className="org-contract-div mt-15">
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_template"
                              label="CONTRACT TEMPLATE"
                              colon={false}
                            >
                              <Input placeholder="Enter your Contract Template" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_name"
                              label="CONTRACT NAME"
                              colon={false}
                            >
                              <Input placeholder="Enter your Contract Name" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="from_entity"
                              label="FROM ENTITY"
                              colon={false}
                            >
                              <Select placeholder="Select From ENtity" />
                            </Form.Item>
                          </Col>

                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="to_entity"
                              label="TO ENTITY"
                              colon={false}
                            >
                              <Select placeholder="Select To Entity" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_owner"
                              label="CONTRACT OWNER"
                              colon={false}
                            >
                              <Input placeholder="Enter your Contract Owner" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_start_date"
                              label="CONTRACT START DATE"
                              colon={false}
                            >
                              <Select placeholder="Enter Contract Start Date" />
                            </Form.Item>
                          </Col>

                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_end_date"
                              label="CONTRACT END DATE"
                              colon={false}
                            >
                              <Input placeholder="Enter your End Date" />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_value"
                              label="CONTRACT VALUE"
                              colon={false}
                            >
                              <Input placeholder="Enter Your Contract Value" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col span={6} className="vertical-align">
                      <div className="org-contract-div mt-15">
                        <Space align="">
                          <div
                            style={{
                              marginTop: "10px",
                              paddingLeft: "20px",
                            }}
                          >
                            <h3>Notes & Comments</h3>
                          </div>
                        </Space>
                        <hr className="mt-10 view-supplier-hr" />
                        <div className="contract-org-view-div">
                          <Input
                            value={commentEntered}
                            onChange={onChangeComment}
                            // onPressEnter={handlePressEnter}
                            className="contract-notes-input"
                            placeholder="Comments"
                            suffix={
                              <>
                                <Upload>
                                  <Image
                                    title="Upload"
                                    className="cursor-pointer"
                                    src={attach}
                                    preview={false}
                                  />
                                </Upload>

                                <Image
                                  title="Add Comment"
                                  className="cursor-pointer"
                                  style={{
                                    paddingLeft: 10,
                                  }}
                                  src={send}
                                  preview={false}
                                />
                              </>
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </Layout>
  );
}

export default OnlineAuthoring;
