import React, { useEffect, useState, useCallback } from "react";
import { useRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import {
  Image,
  Row,
  Col,
  Layout,
  Tree,
  Table,
  Button,
  Divider,
  Card,
  Modal,
  Input,
  Checkbox,
} from "antd"; //antd components
import {
  FileOutlined,
  PushpinFilled,
  DownOutlined,
  RightOutlined,
  LeftOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ContractFile from "./ContractFile";
import "../../assets/style/setup.css";
import "../../assets/style/contracttree.css";
import add from "../../assets/images/icons/Add_round.svg";
import frameworkAgreement from "../../assets/images/icons/framework_agreement.svg";
import supplement from "../../assets/images/icons/supplement.svg";
import sow from "../../assets/images/icons/sow.svg";
import ccn from "../../assets/images/icons/ccn.svg";
import cr from "../../assets/images/icons/cr.svg";
import others from "../../assets/images/icons/others.svg";
import FolderImg from "../../assets/images/icons/folder.svg";
import expand_content from "../../assets/images/icons/expand_content.svg";
import { useRecoilValue } from "recoil";
import {
  SupplierTreeDataAtom,
  ContractTreeUpdateAtom,
  userDataAtom,
  RoleAccessAtom,
} from "../../store/store";
import dayjs from "dayjs";
import { getUserLogApi } from "../../api/logApi";
import { createActivityLogApi } from "../../api/logApi";
const { TreeNode } = Tree;

const renderTreeNodes = (data) => {
  data.map((item) => {
    if (
      item.children !== "undefined" && item.children
        ? item.children.length !== 0
        : ""
    ) {
      return (
        <>
          <TreeNode
            title={
              <div
                className="title-div"
                style={{ display: "inline-block", width: "100%" }}
              >
                <span>
                  {item.contract_name ? item.contract_name : item.supplier_name}
                </span>
                {/* <span className="pin">
                  {item.pinned ? (
                    <PushpinFilled
                      style={{ fontSize: "11px", marginRight: "1px" }}
                    />
                  ) : (
                    ""
                  )}
                </span> */}
              </div>
            }
            icon={
              item.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={15} />
                  </span>
                </>
              ) : item.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : item.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "CCN" ? (
                <>
                  <span>
                    <Image src={ccn} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "CR" ? (
                <>
                  <span>
                    <Image src={cr} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Image src={others} preview={false} width={20} />
                  </span>
                </>
              )
            }
            key={item._id}
            id={item._id}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        </>
      );
    }
    return (
      <TreeNode
        title={
          <span>
            {item.contract_name ? item.contract_name : item.supplier_name}
          </span>
        }
        key={item._id}
        id={item._id}
        icon={
          item.contract_type === "Supplier" ? (
            <>
              <span>
                <Image src={FolderImg} preview={false} width={15} />
              </span>
            </>
          ) : item.contract_type === "MSA" ? (
            <>
              <span>
                <Image src={frameworkAgreement} preview={false} width={15} />
              </span>
            </>
          ) : item.contract_type === "Supplement" ? (
            <>
              <span>
                <Image src={supplement} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "Sub-Supplement" ? (
            <>
              <span>
                <Image src={supplement} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "SOW" ? (
            <>
              <span>
                <Image src={sow} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "CCN" ? (
            <>
              <span>
                <Image src={ccn} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "CR" ? (
            <>
              <span>
                <Image src={cr} preview={false} width={20} />
              </span>
            </>
          ) : (
            <>
              <span>
                <Image src={others} preview={false} width={20} />
              </span>
            </>
          )
        }
      />
    );
  });
};

function ContractTree({
  changeScreenSize,
  addPane,
  itemLength,
  addContractTab,
}) {
  const userData = useRecoilState(userDataAtom); //global state
  const roleUser = useRecoilState(RoleAccessAtom);
  const { Content } = Layout;
  const { state } = useLocation();
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [pathId, setPathId] = useState(["home"]);
  const [pathIdIndex, setPathIdIndex] = useState(pathId.length - 1);
  const [alignedData, setAlignedData] = useState([]);
  const [flattenedData, setFlattenedData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [finalPath, setFinalPath] = useState([{ id: "home", name: "home" }]);
  const [type, setType] = useState("folder");
  const [selectedItem, setSelectedItem] = useState(null);
  const [parentItem, setParentItem] = useState([]);
  const [screenSize, setScreenSize] = useState("min");
  const [colEditor, openColEditor] = useState(false);
  const [contractTreeUpdate, setContractTreeUpdate] = useRecoilState(
    ContractTreeUpdateAtom
  );
  const [contractKey, setContractKey] = useState({});
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [listedCol, setListedCol] = useState(
    contractTreeUpdate.selectedCol.concat(contractTreeUpdate.unSelectedCol)
  );
  const [selectedCol, setSelectedCol] = useState(
    contractTreeUpdate.selectedCol
  );
  const [selectedBackup, setSelectedBackup] = useState(
    contractTreeUpdate.selectedCol
  );
  const [searchQuery, setSearchQuery] = useState("");

  const isColumnSorted = (column) => sortedColumn === column;

  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  // let selectedCol = contractTreeUpdate.selectedCol;
  // let unSelectedCol = contractTreeUpdate.unSelectedCol;
  const [selectedColLoc, setSelectedColLoc] = useState(
    contractTreeUpdate.selectedCol
  );
  const [unSelectedColLoc, setUnSelectedColLoc] = useState(
    contractTreeUpdate.unSelectedCol
  );
  const [count, setCount] = useState(0);
  let selected = false;

  useEffect(() => {
    if (state && state.selected) {
      addContractTab(state.selected, "");
    }
  }, [state]);

  useEffect(() => {
    if (supplierTreeData.length) {
      console.log("supplierTreeData", supplierTreeData);
      setResponseData([...supplierTreeData]);
      let newData = [...supplierTreeData];
      // for (let i = newData.length - 1; i > 0; i--) {
      //   if (newData[i].pinned) {
      //     let cut = newData.splice(i, 1);
      //     newData.unshift(cut[0]);
      //   }
      // }
      setFlattenedData(flat(newData));
      setAlignedData(newData);
      if (pathId.length === 1) {
        const selectedTreeData = newData.map((item, index) => {
          // console.log("*****", item);
          let tableRow = {
            id: item._id,
            key: item._id,
            name: (
              <div className="name cursor-pointer">
                <span>
                  {item.contract_type === "Supplier" ? (
                    <>
                      <span>
                        <Image src={FolderImg} preview={false} width={15} />
                      </span>
                    </>
                  ) : item.contract_type === "MSA" ? (
                    <>
                      <span>
                        <Image
                          src={frameworkAgreement}
                          preview={false}
                          width={15}
                        />
                      </span>
                    </>
                  ) : item.contract_type === "Supplement" ? (
                    <>
                      <span>
                        <Image src={supplement} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "Sub-Supplement" ? (
                    <>
                      <span>
                        <Image src={supplement} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "SOW" ? (
                    <>
                      <span>
                        <Image src={sow} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "CCN" ? (
                    <>
                      <span>
                        <Image src={ccn} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "CR" ? (
                    <>
                      <span>
                        <Image src={cr} preview={false} width={20} />
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <Image src={others} preview={false} width={20} />
                      </span>
                    </>
                  )}{" "}
                </span>
                <span
                  onClick={() => {
                    if (count === 0) {
                      return;
                    } else {
                      onSelectRight(item._id);
                    }
                    displayContract(item, item.type);
                  }}
                  style={{ padding: "15px 15px 15px 5px" }}
                >
                  {item.contract_name ? item.contract_name : item.supplier_name}
                </span>
              </div>
            ),
            date: item.created_on ? item.created_on.substring(0, 10) : "",
            // ? item.createdAt.slice(0, 19).split("T")[0] +
            //   " " +
            //   item.createdAt.slice(0, 19).split("T")[1]
            // : "",
            // warnings: item.warnings ? item.warnings : "-----",
            // tags: item.tags ? item.tags : "",
            contract_type: item.contract_type,
            status: item.supplier_status
              ? item.supplier_status
              : item.contract_status,
            supplier_name_short: item.supplier_name_short
              ? item.supplier_name_short
              : "",
          };
          return tableRow;
        });
        setTableData(selectedTreeData);
      }
      setCount(count + 1);
    }
  }, [supplierTreeData]);

  useEffect(() => {
    console.log("parentItemmmmm: ", parentItem);
  }, [pathId]);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const recursivelyFindKeyValue = (key, keyValue, list) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      for (const key of Object.keys(item)) {
        if (Array.isArray(item[key])) {
          const res = recursivelyFindKeyValue(key, keyValue, item[key]);
          if (res.found === true) return res;
        } else if (item[key] === keyValue) {
          return { found: true, containingArray: list, keyValue: keyValue };
        }
      }
    }

    return { found: false, containingArray: [], keyValue: keyValue };
  };

  // useEffect(() => {
  //   console.log("contractKey: ", contractKey);
  //   if (contractKey.from === "select-left") {
  //     let nestArr = recursivelyFindKeyValue("id", contractKey.id, alignedData);
  //     let obj = nestArr.containingArray.find((o) => o._id === contractKey.id);
  //     let parentObj = flattenedData.find((node1) => node1._id === obj._id);
  //     let tableRow = {
  //       id: parentObj._id,
  //       key: parentObj._id,
  //       name: (
  //         <div className="name cursor-pointer">
  //           <span>
  //             {parentObj.contract_type === "Supplier" ? (
  //               <>
  //                 <span>
  //                   <Image src={FolderImg} preview={false} width={15} />
  //                 </span>
  //               </>
  //             ) : parentObj.contract_type === "MSA" ? (
  //               <>
  //                 <span>
  //                   <Image
  //                     src={frameworkAgreement}
  //                     preview={false}
  //                     width={15}
  //                   />
  //                 </span>
  //               </>
  //             ) : parentObj.contract_type === "Supplement" ? (
  //               <>
  //                 <span>
  //                   <Image src={supplement} preview={false} width={20} />
  //                 </span>
  //               </>
  //             ) : parentObj.contract_type === "Sub-Supplement" ? (
  //               <>
  //                 <span>
  //                   <Image src={supplement} preview={false} width={20} />
  //                 </span>
  //               </>
  //             ) : parentObj.contract_type === "SOW" ? (
  //               <>
  //                 <span>
  //                   <Image src={sow} preview={false} width={20} />
  //                 </span>
  //               </>
  //             ) : parentObj.contract_type === "CCN" ? (
  //               <>
  //                 <span>
  //                   <Image src={ccn} preview={false} width={20} />
  //                 </span>
  //               </>
  //             ) : (
  //               <>
  //                 <FileOutlined />
  //               </>
  //             )}{" "}
  //           </span>
  //           <span
  //             onClick={() => {
  //               console.log("obj: ", parentObj._id);
  //               onSelectRight(parentObj._id);
  //               displayContractParent(parentObj, parentObj.type);
  //             }}
  //             style={{ padding: "15px 15px 15px 5px" }}
  //           >
  //             {parentObj.contract_name
  //               ? parentObj.contract_name
  //               : parentObj.supplier_name}
  //           </span>
  //         </div>
  //       ),
  //       date: parentObj.createdAt ? parentObj.createdAt.substring(0, 10) : "",
  //       contract_type: parentObj.contract_type,
  //       status: parentObj.contract_status
  //         ? parentObj.contract_status
  //         : parentObj.supplier_status,
  //     };

  //     setParentItem([tableRow]);

  //     if (obj.type === "folder") {
  //       setType("folder");
  //       const selectedTreeData =
  //         obj.children &&
  //         obj.children.map((item, index) => {
  //           console.log("folder>>>>", item);
  //           let tableRow = {
  //             id: item._id,
  //             key: item._id,
  //             name: (
  //               <div className="name cursor-pointer">
  //                 <span>
  //                   {item.contract_type === "Supplier" ? (
  //                     <>
  //                       <span>
  //                         <Image src={FolderImg} preview={false} width={15} />
  //                       </span>
  //                     </>
  //                   ) : item.contract_type === "MSA" ? (
  //                     <>
  //                       <span>
  //                         <Image
  //                           src={frameworkAgreement}
  //                           preview={false}
  //                           width={15}
  //                         />
  //                       </span>
  //                     </>
  //                   ) : item.contract_type === "Supplement" ? (
  //                     <>
  //                       <span>
  //                         <Image src={supplement} preview={false} width={20} />
  //                       </span>
  //                     </>
  //                   ) : item.contract_type === "Sub-Supplement" ? (
  //                     <>
  //                       <span>
  //                         <Image src={supplement} preview={false} width={20} />
  //                       </span>
  //                     </>
  //                   ) : item.contract_type === "SOW" ? (
  //                     <>
  //                       <span>
  //                         <Image src={sow} preview={false} width={20} />
  //                       </span>
  //                     </>
  //                   ) : item.contract_type === "CCN" ? (
  //                     <>
  //                       <span>
  //                         <Image src={ccn} preview={false} width={20} />
  //                       </span>
  //                     </>
  //                   ) : (
  //                     <>
  //                       <FileOutlined />
  //                     </>
  //                   )}{" "}
  //                 </span>
  //                 <span
  //                   onClick={() => {
  //                     console.log("obj: ", parentObj._id);
  //                     onSelectRight(item._id);
  //                     displayContract(item, item.type);
  //                   }}
  //                   style={{ padding: "15px 15px 15px 5px" }}
  //                 >
  //                   {item.contract_name
  //                     ? item.contract_name
  //                     : item.supplier_name}
  //                 </span>
  //               </div>
  //             ),
  //             date: item.created_on ? item.created_on.substring(0, 10) : "",
  //             // ? item.createdAt.slice(0, 19).split("T")[0] +
  //             //   " " +
  //             //   item.createdAt.slice(0, 19).split("T")[1]
  //             // : "",
  //             // warnings: item.warnings ? item.warnings : "-----",
  //             // tags: item.tags ? item.tags : "",
  //             contract_type: item.contract_type,
  //             status: item.contract_status
  //               ? item.contract_status
  //               : item.supplier_status,
  //           };
  //           return tableRow;
  //         });
  //       setTableData(selectedTreeData);
  //     } else if (obj.type === "file") {
  //       setTableData([]);
  //       setParentItem([]);
  //       setType("file");
  //       displayContract(obj, obj.type);
  //     }
  //   }

  //   if (contractKey.from === "select-right") {
  //     if (contractKey.id !== "home") {
  //       let nestArr = recursivelyFindKeyValue(
  //         "id",
  //         contractKey.id,
  //         responseData
  //       );
  //       let obj = nestArr.containingArray.find((o) => o._id === contractKey.id);
  //       console.log("objjj: ", obj);
  //       let parentObj = flattenedData.find((node1) => node1._id === obj._id);
  //       let tableRow = {
  //         id: parentObj._id,
  //         key: parentObj._id,
  //         name: (
  //           <div className="name cursor-pointer">
  //             <span>
  //               {parentObj.contract_type === "Supplier" ? (
  //                 <>
  //                   <span>
  //                     <Image src={FolderImg} preview={false} width={20} />
  //                   </span>
  //                 </>
  //               ) : parentObj.contract_type === "MSA" ? (
  //                 <>
  //                   <span>
  //                     <Image
  //                       src={frameworkAgreement}
  //                       preview={false}
  //                       width={15}
  //                     />
  //                   </span>
  //                 </>
  //               ) : parentObj.contract_type === "Supplement" ? (
  //                 <>
  //                   <span>
  //                     <Image src={supplement} preview={false} width={20} />
  //                   </span>
  //                 </>
  //               ) : parentObj.contract_type === "Sub-Supplement" ? (
  //                 <>
  //                   <span>
  //                     <Image src={supplement} preview={false} width={20} />
  //                   </span>
  //                 </>
  //               ) : parentObj.contract_type === "SOW" ? (
  //                 <>
  //                   <span>
  //                     <Image src={sow} preview={false} width={20} />
  //                   </span>
  //                 </>
  //               ) : parentObj.contract_type === "CCN" ? (
  //                 <>
  //                   <span>
  //                     <Image src={ccn} preview={false} width={20} />
  //                   </span>
  //                 </>
  //               ) : (
  //                 <>
  //                   <FileOutlined />
  //                 </>
  //               )}{" "}
  //             </span>
  //             <span
  //               onClick={() => {
  //                 onSelectRight(parentObj._id);
  //                 displayContractParent(parentObj, parentObj.type);
  //               }}
  //               style={{ padding: "15px 15px 15px 5px" }}
  //             >
  //               {parentObj.contract_name
  //                 ? parentObj.contract_name
  //                 : parentObj.supplier_name}
  //             </span>
  //           </div>
  //         ),
  //         date: parentObj.created_on
  //           ? parentObj.created_on.substring(0, 10)
  //           : "",
  //         contract_type: parentObj.contract_type,
  //         status: parentObj.contract_status
  //           ? parentObj.contract_status
  //           : parentObj.supplier_status,
  //       };

  //       setParentItem([tableRow]);
  //       if (obj.type === "folder") {
  //         setType("folder");
  //         const selectedTreeData =
  //           obj.children &&
  //           obj.children.map((item, index) => {
  //             console.log("folder1>>>>", item);
  //             let tableRow = {
  //               id: item._id,
  //               key: item._id,
  //               name: (
  //                 <div className="name cursor-pointer">
  //                   <span>
  //                     {item.contract_type === "Supplier" ? (
  //                       <>
  //                         <span>
  //                           <Image src={FolderImg} preview={false} width={15} />
  //                         </span>
  //                       </>
  //                     ) : item.contract_type === "MSA" ? (
  //                       <>
  //                         <span>
  //                           <Image
  //                             src={frameworkAgreement}
  //                             preview={false}
  //                             width={15}
  //                           />
  //                         </span>
  //                       </>
  //                     ) : item.contract_type === "Supplement" ? (
  //                       <>
  //                         <span>
  //                           <Image
  //                             src={supplement}
  //                             preview={false}
  //                             width={20}
  //                           />
  //                         </span>
  //                       </>
  //                     ) : item.contract_type === "Sub-Supplement" ? (
  //                       <>
  //                         <span>
  //                           <Image
  //                             src={supplement}
  //                             preview={false}
  //                             width={20}
  //                           />
  //                         </span>
  //                       </>
  //                     ) : item.contract_type === "SOW" ? (
  //                       <>
  //                         <span>
  //                           <Image src={sow} preview={false} width={20} />
  //                         </span>
  //                       </>
  //                     ) : item.contract_type === "CCN" ? (
  //                       <>
  //                         <span>
  //                           <Image src={ccn} preview={false} width={20} />
  //                         </span>
  //                       </>
  //                     ) : (
  //                       <>
  //                         <FileOutlined />
  //                       </>
  //                     )}{" "}
  //                   </span>
  //                   <span
  //                     onClick={() => {
  //                       onSelectRight(item._id);
  //                       displayContract(item, item.type);
  //                     }}
  //                   >
  //                     {item.contract_name
  //                       ? item.contract_name
  //                       : item.supplier_name}
  //                   </span>
  //                 </div>
  //               ),
  //               date: item.created_on ? item.created_on.substring(0, 10) : "",
  //               // ? item.createdAt.slice(0, 19).split("T")[0] +
  //               //   " " +
  //               //   item.createdAt.slice(0, 19).split("T")[1]
  //               // : "",
  //               // warnings: item.warnings ? item.warnings : "-----",
  //               // tags: item.tags ? item.tags : "",
  //               contract_type: item.contract_type,
  //               status: item.contract_status
  //                 ? item.contract_status
  //                 : item.supplier_status,
  //             };
  //             return tableRow;
  //           });
  //         setTableData(selectedTreeData);
  //       } else if (obj.type === "file") {
  //         setTableData([]);
  //         setParentItem([]);
  //         setType("file");
  //         // setSelectedItem(obj);
  //         displayContract(obj, obj.type);
  //       }
  //     } else {
  //       let newData = [...responseData];
  //       const selectedTreeData = newData.map((item, index) => {
  //         console.log("file>>>>", item);
  //         let tableRow = {
  //           id: item._id,
  //           key: item._id,
  //           name: (
  //             <div className="name cursor-pointer">
  //               <span>
  //                 {item.contract_type === "Supplier" ? (
  //                   <>
  //                     <span>
  //                       <Image src={FolderImg} preview={false} width={15} />
  //                     </span>
  //                   </>
  //                 ) : item.contract_type === "MSA" ? (
  //                   <>
  //                     <span>
  //                       <Image
  //                         src={frameworkAgreement}
  //                         preview={false}
  //                         width={15}
  //                       />
  //                     </span>
  //                   </>
  //                 ) : item.contract_type === "Supplement" ? (
  //                   <>
  //                     <span>
  //                       <Image src={supplement} preview={false} width={20} />
  //                     </span>
  //                   </>
  //                 ) : item.contract_type === "Sub-Supplement" ? (
  //                   <>
  //                     <span>
  //                       <Image src={supplement} preview={false} width={20} />
  //                     </span>
  //                   </>
  //                 ) : item.contract_type === "SOW" ? (
  //                   <>
  //                     <span>
  //                       <Image src={sow} preview={false} width={20} />
  //                     </span>
  //                   </>
  //                 ) : item.contract_type === "CCN" ? (
  //                   <>
  //                     <span>
  //                       <Image src={ccn} preview={false} width={20} />
  //                     </span>
  //                   </>
  //                 ) : (
  //                   <>
  //                     <FileOutlined />
  //                   </>
  //                 )}{" "}
  //               </span>
  //               <span
  //                 onClick={() => {
  //                   onSelectRight(item._id);
  //                   displayContract(item, item.type);
  //                 }}
  //                 style={{ padding: "15px 15px 15px 5px" }}
  //               >
  //                 {item.contract_name ? item.contract_name : item.supplier_name}
  //               </span>
  //             </div>
  //           ),
  //           date: item.createdAt ? item.createdAt.substring(0, 10) : "",
  //           // ? item.createdAt.slice(0, 19).split("T")[0] +
  //           //   " " +
  //           //   item.createdAt.slice(0, 19).split("T")[1]
  //           // : "",
  //           // warnings: item.warnings ? item.warnings : "-----",
  //           // tags: item.tags ? item.tags : "",
  //           contract_type: item.contract_type,
  //           status: item.supplier_status
  //             ? item.supplier_status
  //             : item.contract_status,
  //         };
  //         return tableRow;
  //       });
  //       setTableData(selectedTreeData);
  //     }
  //   }
  // }, [contractKey]);

  const onSelect = (e, selectedKeys) => {
    console.log("selectedKeysssssss", selectedKeys);
    setTableData([]);
    setPathId([...pathId, selectedKeys._id]);
    getTopPath(selectedKeys._id);
    // setPathIdIndex(pathId.length)
    let nestArr = recursivelyFindKeyValue("id", selectedKeys._id, alignedData);
    let obj = nestArr.containingArray.find((o) => o._id === selectedKeys._id);
    setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));
    setContractKey({ id: selectedKeys._id, from: "select-left" });
    let parentObj = flattenedData.find((node1) => node1._id === obj._id);
    let tableRow = {
      id: parentObj._id,
      key: parentObj._id,
      name: (
        <div className="name cursor-pointer">
          <span>
            {parentObj.contract_type === "Supplier" ? (
              <>
                <span>
                  <Image src={FolderImg} preview={false} width={15} />
                </span>
              </>
            ) : parentObj.contract_type === "MSA" ? (
              <>
                <span>
                  <Image src={frameworkAgreement} preview={false} width={15} />
                </span>
              </>
            ) : parentObj.contract_type === "Supplement" ? (
              <>
                <span>
                  <Image src={supplement} preview={false} width={20} />
                </span>
              </>
            ) : parentObj.contract_type === "Sub-Supplement" ? (
              <>
                <span>
                  <Image src={supplement} preview={false} width={20} />
                </span>
              </>
            ) : parentObj.contract_type === "SOW" ? (
              <>
                <span>
                  <Image src={sow} preview={false} width={20} />
                </span>
              </>
            ) : parentObj.contract_type === "CCN" ? (
              <>
                <span>
                  <Image src={ccn} preview={false} width={20} />
                </span>
              </>
            ) : parentObj.contract_type === "CR" ? (
              <>
                <span>
                  <Image src={cr} preview={false} width={20} />
                </span>
              </>
            ) : (
              <>
                <span>
                  <Image src={others} preview={false} width={20} />
                </span>
              </>
            )}{" "}
          </span>
          <span
            onClick={() => {
              console.log("obj: ", parentObj._id);
              onSelectRight(parentObj._id);
              displayContractParent(parentObj, parentObj.type);
            }}
            style={{ padding: "15px 15px 15px 5px" }}
          >
            {parentObj.contract_name
              ? parentObj.contract_name
              : parentObj.supplier_name}
          </span>
        </div>
      ),
      // date: parentObj.createdAt ? parentObj.createdAt.substring(0, 10) : "",
      date: parentObj.created_on ? parentObj.created_on.substring(0, 10) : "",
      contract_type: parentObj.contract_type,
      status: parentObj.contract_status
        ? parentObj.contract_status
        : parentObj.supplier_status,
    };

    setParentItem([tableRow]);

    if (obj.type === "folder") {
      setType("folder");
      const selectedTreeData =
        obj.children &&
        obj.children.map((item, index) => {
          console.log("folder>>>>", item);
          let tableRow = {
            id: item._id,
            key: item._id,
            name: (
              <div className="name cursor-pointer">
                <span>
                  {item.contract_type === "Supplier" ? (
                    <>
                      <span>
                        <Image src={FolderImg} preview={false} width={15} />
                      </span>
                    </>
                  ) : item.contract_type === "MSA" ? (
                    <>
                      <span>
                        <Image
                          src={frameworkAgreement}
                          preview={false}
                          width={15}
                        />
                      </span>
                    </>
                  ) : item.contract_type === "Supplement" ? (
                    <>
                      <span>
                        <Image src={supplement} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "Sub-Supplement" ? (
                    <>
                      <span>
                        <Image src={supplement} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "SOW" ? (
                    <>
                      <span>
                        <Image src={sow} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "CCN" ? (
                    <>
                      <span>
                        <Image src={ccn} preview={false} width={20} />
                      </span>
                    </>
                  ) : item.contract_type === "CR" ? (
                    <>
                      <span>
                        <Image src={cr} preview={false} width={20} />
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <Image src={others} preview={false} width={20} />
                      </span>
                    </>
                  )}{" "}
                </span>
                <span
                  onClick={() => {
                    if (!selectedKeys.disabled) {
                      console.log("obj: ", parentObj._id);
                      onSelectRight(item._id);
                      displayContract(item, item.type);
                    }
                  }}
                  style={{ padding: "15px 15px 15px 5px" }}
                >
                  {item.contract_name ? item.contract_name : item.supplier_name}
                </span>
              </div>
            ),
            date: item.created_on ? item.created_on.substring(0, 10) : "",
            // ? item.createdAt.slice(0, 19).split("T")[0] +
            //   " " +
            //   item.createdAt.slice(0, 19).split("T")[1]
            // : "",
            // warnings: item.warnings ? item.warnings : "-----",
            // tags: item.tags ? item.tags : "",
            contract_type: item.contract_type,
            status: item.contract_status
              ? item.contract_status
              : item.supplier_status,
          };
          return tableRow;
        });
      setTableData(selectedTreeData);
    } else if (obj.type === "file" && !selectedKeys.disabled) {
      setTableData([]);
      // setParentItem([]);
      setType("file");
      displayContract(obj, obj.type);
    }
  };

  const onSelectRight = (selectedKeys) => {
    if (selectedKeys === null) {
      selectedKeys = "home";
    }
    console.log("selectedKeys: ", selectedKeys);
    setContractKey({ selectedKeys, from: "select-right" });

    if (selectedKeys !== "home") {
      setPathId((prevState) => [...prevState, selectedKeys]);
      getTopPath(selectedKeys);

      let nestArr = recursivelyFindKeyValue("id", selectedKeys, responseData);
      let obj = nestArr.containingArray.find((o) => o._id === selectedKeys);
      setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));

      let parentObj = flattenedData.find((node1) => node1._id === obj._id);
      let tableRow = {
        id: parentObj._id,
        key: parentObj._id,
        name: (
          <div className="name cursor-pointer">
            <span>
              {parentObj.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : parentObj.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "CCN" ? (
                <>
                  <span>
                    <Image src={ccn} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "CR" ? (
                <>
                  <span>
                    <Image src={cr} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Image src={others} preview={false} width={20} />
                  </span>
                </>
              )}{" "}
            </span>
            <span
              onClick={() => {
                onSelectRight(parentObj._id);
                if (!obj.disabled) {
                  displayContractParent(parentObj, parentObj.type);
                }
              }}
              style={{ padding: "15px 15px 15px 5px" }}
            >
              {parentObj.contract_name
                ? parentObj.contract_name
                : parentObj.supplier_name}
            </span>
          </div>
        ),
        date: parentObj.created_on ? parentObj.created_on.substring(0, 10) : "",
        contract_type: parentObj.contract_type,
        status: parentObj.contract_status
          ? parentObj.contract_status
          : parentObj.supplier_status,
      };

      setParentItem([tableRow]);
      if (obj.type === "folder") {
        setType("folder");
        const selectedTreeData =
          obj.children &&
          obj.children.map((item, index) => {
            console.log("folder1>>>>", item);
            let tableRow = {
              id: item._id,
              key: item._id,
              name: (
                <div className="name cursor-pointer">
                  <span>
                    {item.contract_type === "Supplier" ? (
                      <>
                        <span>
                          <Image src={FolderImg} preview={false} width={15} />
                        </span>
                      </>
                    ) : item.contract_type === "MSA" ? (
                      <>
                        <span>
                          <Image
                            src={frameworkAgreement}
                            preview={false}
                            width={15}
                          />
                        </span>
                      </>
                    ) : item.contract_type === "Supplement" ? (
                      <>
                        <span>
                          <Image src={supplement} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "Sub-Supplement" ? (
                      <>
                        <span>
                          <Image src={supplement} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "SOW" ? (
                      <>
                        <span>
                          <Image src={sow} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "CCN" ? (
                      <>
                        <span>
                          <Image src={ccn} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "CR" ? (
                      <>
                        <span>
                          <Image src={cr} preview={false} width={20} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <Image src={others} preview={false} width={20} />
                        </span>
                      </>
                    )}{" "}
                  </span>
                  <span
                    onClick={() => {
                      onSelectRight(item._id);
                      if (!obj.disabled) {
                        displayContract(item, item.type);
                      }
                    }}
                  >
                    {item.contract_name
                      ? item.contract_name
                      : item.supplier_name}
                  </span>
                </div>
              ),
              date: item.created_on ? item.created_on.substring(0, 10) : "",
              // ? item.createdAt.slice(0, 19).split("T")[0] +
              //   " " +
              //   item.createdAt.slice(0, 19).split("T")[1]
              // : "",
              // warnings: item.warnings ? item.warnings : "-----",
              // tags: item.tags ? item.tags : "",
              contract_type: item.contract_type,
              status: item.contract_status
                ? item.contract_status
                : item.supplier_status,
            };
            return tableRow;
          });
        setTableData(selectedTreeData);
      } else if (obj.type === "file") {
        setTableData([]);
        // setParentItem([]);
        setType("file");
        // setSelectedItem(obj);
        if (!obj.disabled) {
          displayContract(obj, obj.type);
        }
      }
    } else {
      setType("folder");
      setParentItem([]);
      setPathId((prevState) => [...prevState, "home"]);
      getTopPath("home");

      let newData = [...responseData];
      // for (let i = newData.length - 1; i > 0; i--) {
      //   if (newData[i].pinned) {
      //     let cut = newData.splice(i, 1);
      //     newData.unshift(cut[0]);
      //   }
      // }
      setAlignedData(newData);
      const selectedTreeData = newData.map((item, index) => {
        console.log("file>>>>", item);
        let tableRow = {
          id: item._id,
          key: item._id,
          name: (
            <div className="name cursor-pointer">
              <span>
                {item.contract_type === "Supplier" ? (
                  <>
                    <span>
                      <Image src={FolderImg} preview={false} width={15} />
                    </span>
                  </>
                ) : item.contract_type === "MSA" ? (
                  <>
                    <span>
                      <Image
                        src={frameworkAgreement}
                        preview={false}
                        width={15}
                      />
                    </span>
                  </>
                ) : item.contract_type === "Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "Sub-Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "SOW" ? (
                  <>
                    <span>
                      <Image src={sow} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CCN" ? (
                  <>
                    <span>
                      <Image src={ccn} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CR" ? (
                  <>
                    <span>
                      <Image src={cr} preview={false} width={20} />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <Image src={others} preview={false} width={20} />
                    </span>
                  </>
                )}{" "}
              </span>
              <span
                onClick={() => {
                  onSelectRight(item._id);
                  if (!item.disabled) {
                    displayContract(item, item.type);
                  }
                }}
                style={{ padding: "15px 15px 15px 5px" }}
              >
                {item.contract_name ? item.contract_name : item.supplier_name}
              </span>
            </div>
          ),
          date: item.created_on ? item.created_on.substring(0, 10) : "",
          // ? item.createdAt.slice(0, 19).split("T")[0] +
          //   " " +
          //   item.createdAt.slice(0, 19).split("T")[1]
          // : "",
          // warnings: item.warnings ? item.warnings : "-----",
          // tags: item.tags ? item.tags : "",
          contract_type: item.contract_type,
          status: item.supplier_status
            ? item.supplier_status
            : item.contract_status,
          supplier_name_short: item.supplier_name_short,
        };
        return tableRow;
      });
      setTableData(selectedTreeData);
    }
  };

  const onClickBack = () => {
    const selectedKeys = pathId[pathIdIndex - 1];
    setPathIdIndex(pathIdIndex - 1);
    getTopPath(selectedKeys);

    if (selectedKeys !== "home") {
      let nestArr = recursivelyFindKeyValue("id", selectedKeys, responseData);
      let obj = nestArr.containingArray.find((o) => o.id === selectedKeys);
      setSelectedItem(flattenedData.find((node1) => node1.id === obj.id));
      let parentObj = flattenedData.find((node1) => node1.id === obj.id);
      let tableRow = {
        id: parentObj.id,
        key: parentObj.id,
        name: (
          <div className="name cursor-pointer">
            <span>
              {parentObj.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={15} />
                  </span>
                </>
              ) : parentObj.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : parentObj.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "CCN" ? (
                <>
                  <span>
                    <Image src={ccn} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "CR" ? (
                <>
                  <span>
                    <Image src={cr} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Image src={others} preview={false} width={20} />
                  </span>
                </>
              )}{" "}
            </span>
            <span
              onClick={() => {
                onSelectRight(parentObj.id);
                displayContractParent(parentObj, parentObj.type);
              }}
            >
              {parentObj.contract_name
                ? parentObj.contract_name
                : parentObj[`${parentObj.contract_type.toLowerCase()}_name`]}
            </span>
          </div>
        ),
        date: parentObj.createdAt ? parentObj.createdAt.substring(0, 10) : "",
        contract_type: parentObj.contract_type,
        status: parentObj.supplier_status,
      };

      setParentItem([tableRow]);
      if (obj.type === "folder") {
        setType("folder");
        const selectedTreeData =
          obj.children &&
          obj.children.map((item, index) => {
            let tableRow = {
              id: item.id,
              key: item.id,
              name: (
                <div className="name cursor-pointer">
                  <span>
                    {item.contract_type === "Supplier" ? (
                      <>
                        <span>
                          <Image src={FolderImg} preview={false} width={15} />
                        </span>
                      </>
                    ) : item.contract_type === "MSA" ? (
                      <>
                        <span>
                          <Image
                            src={frameworkAgreement}
                            preview={false}
                            width={15}
                          />
                        </span>
                      </>
                    ) : item.contract_type === "Supplement" ? (
                      <>
                        <span>
                          <Image src={supplement} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "Sub-Supplement" ? (
                      <>
                        <span>
                          <Image src={supplement} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "SOW" ? (
                      <>
                        <span>
                          <Image src={sow} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "CCN" ? (
                      <>
                        <span>
                          <Image src={ccn} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "CR" ? (
                      <>
                        <span>
                          <Image src={cr} preview={false} width={20} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <Image src={others} preview={false} width={20} />
                        </span>
                      </>
                    )}{" "}
                  </span>
                  <span
                    onClick={() => {
                      onSelectRight(item.id);
                      displayContract(item, item.type);
                    }}
                  >
                    {item.contract_name
                      ? item.contract_name
                      : item[`${item.contract_type.toLowerCase()}_name`]}
                  </span>
                </div>
              ),
              // date: item.createdAt ? item.createdAt.substring(0, 10) : "",
              date: item.created_on ? item.created_on.substring(0, 10) : "",
              // ? item.createdAt.slice(0, 19).split("T")[0] +
              //   " " +
              //   item.createdAt.slice(0, 19).split("T")[1]
              // : "",
              // warnings: item.warnings ? item.warnings : "-----",
              // tags: item.tags ? item.tags : "",
              contract_type: item.contract_type,
              status: item.supplier_status,
            };
            return tableRow;
          });
        setTableData(selectedTreeData);
      } else if (obj.type === "file") {
        setTableData([]);
        setParentItem([]);
        setType("file");
        displayContract(obj, obj.type);
      }
    } else {
      setType("folder");
      setParentItem([]);
      let newData = [...responseData];
      for (let i = newData.length - 1; i > 0; i--) {
        if (newData[i].pinned) {
          let cut = newData.splice(i, 1);
          newData.unshift(cut[0]);
        }
      }
      setAlignedData(newData);
      const selectedTreeData = newData.map((item, index) => {
        let tableRow = {
          id: item.id,
          key: item.id,
          name: (
            <div className="name cursor-pointer">
              <span>
                {item.contract_type === "Supplier" ? (
                  <>
                    <span>
                      <Image src={FolderImg} preview={false} width={15} />
                    </span>
                  </>
                ) : item.contract_type === "MSA" ? (
                  <>
                    <span>
                      <Image
                        src={frameworkAgreement}
                        preview={false}
                        width={15}
                      />
                    </span>
                  </>
                ) : item.contract_type === "Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "Sub-Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "SOW" ? (
                  <>
                    <span>
                      <Image src={sow} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CCN" ? (
                  <>
                    <span>
                      <Image src={ccn} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CR" ? (
                  <>
                    <span>
                      <Image src={cr} preview={false} width={20} />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <Image src={others} preview={false} width={20} />
                    </span>
                  </>
                )}{" "}
              </span>
              <span
                onClick={() => {
                  onSelectRight(item.id);
                  displayContract(item, item.type);
                }}
              >
                {item.contract_name
                  ? item.contract_name
                  : item[`${item.contract_type.toLowerCase()}_name`]}
              </span>
            </div>
          ),
          date: item.createdAt ? item.createdAt.substring(0, 10) : "",
          // ? item.createdAt.slice(0, 19).split("T")[0] +
          //   " " +
          //   item.createdAt.slice(0, 19).split("T")[1]
          // : "",
          // warnings: item.warnings ? item.warnings : "-----",
          // tags: item.tags ? item.tags : "",
          contract_type: item.contract_type,
          status: item.supplier_status,
        };
        return tableRow;
      });
      setTableData(selectedTreeData);
    }
  };

  const onClickForward = () => {
    const selectedKeys = pathId[pathIdIndex + 1];
    setPathIdIndex(pathIdIndex + 1);
    getTopPath(selectedKeys);

    if (selectedKeys !== "home") {
      let nestArr = recursivelyFindKeyValue("id", selectedKeys, responseData);
      let obj = nestArr.containingArray.find((o) => o.id === selectedKeys);
      setSelectedItem(flattenedData.find((node1) => node1.id === obj.id));
      let parentObj = flattenedData.find((node1) => node1.id === obj.id);
      let tableRow = {
        id: parentObj.id,
        key: parentObj.id,
        name: (
          <div className="name cursor-pointer">
            <span>
              {parentObj.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={15} />
                  </span>
                </>
              ) : parentObj.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : parentObj.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "CCN" ? (
                <>
                  <span>
                    <Image src={ccn} preview={false} width={20} />
                  </span>
                </>
              ) : parentObj.contract_type === "CR" ? (
                <>
                  <span>
                    <Image src={cr} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Image src={others} preview={false} width={20} />
                  </span>
                </>
              )}{" "}
            </span>
            <span
              onClick={() => {
                onSelectRight(parentObj.id);
                displayContractParent(parentObj, parentObj.type);
              }}
            >
              {parentObj.contract_name
                ? parentObj.contract_name
                : parentObj[`${parentObj.contract_type.toLowerCase()}_name`]}
            </span>
          </div>
        ),
        date: parentObj.createdAt ? parentObj.createdAt.substring(0, 10) : "",
        contract_type: parentObj.contract_type,
        status: parentObj.supplier_status,
      };

      setParentItem([tableRow]);
      if (obj.type === "folder") {
        setType("folder");
        const selectedTreeData =
          obj.children &&
          obj.children.map((item, index) => {
            let tableRow = {
              id: item.id,
              key: item.id,
              name: (
                <div className="name cursor-pointer">
                  <span>
                    {item.contract_type === "Supplier" ? (
                      <>
                        <span>
                          <Image src={FolderImg} preview={false} width={15} />
                        </span>
                      </>
                    ) : item.contract_type === "MSA" ? (
                      <>
                        <span>
                          <Image
                            src={frameworkAgreement}
                            preview={false}
                            width={15}
                          />
                        </span>
                      </>
                    ) : item.contract_type === "Supplement" ? (
                      <>
                        <span>
                          <Image src={supplement} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "Sub-Supplement" ? (
                      <>
                        <span>
                          <Image src={supplement} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "SOW" ? (
                      <>
                        <span>
                          <Image src={sow} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "CCN" ? (
                      <>
                        <span>
                          <Image src={ccn} preview={false} width={20} />
                        </span>
                      </>
                    ) : item.contract_type === "CR" ? (
                      <>
                        <span>
                          <Image src={cr} preview={false} width={20} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <Image src={others} preview={false} width={20} />
                        </span>
                      </>
                    )}{" "}
                  </span>
                  <span
                    onClick={() => {
                      onSelectRight(item.id);
                      displayContract(item, item.type);
                    }}
                  >
                    {item.contract_name
                      ? item.contract_name
                      : item[`${item.contract_type.toLowerCase()}_name`]}
                  </span>
                </div>
              ),
              // date: item.createdAt ? item.createdAt.substring(0, 10) : "",
              date: item.created_on ? item.created_on.substring(0, 10) : "",
              // ? item.createdAt.slice(0, 19).split("T")[0] +
              //   " " +
              //   item.createdAt.slice(0, 19).split("T")[1]
              // : "",
              // warnings: item.warnings ? item.warnings : "-----",
              // tags: item.tags ? item.tags : "",
              contract_type: item.contract_type,
              status: item.supplier_status,
            };
            return tableRow;
          });
        setTableData(selectedTreeData);
      } else if (obj.type === "file") {
        setTableData([]);
        setParentItem([]);
        setType("file");
        displayContract(obj, obj.type);
      }
    } else {
      setType("folder");
      setParentItem([]);
      let newData = [...responseData];
      for (let i = newData.length - 1; i > 0; i--) {
        if (newData[i].pinned) {
          let cut = newData.splice(i, 1);
          newData.unshift(cut[0]);
        }
      }
      setAlignedData(newData);
      const selectedTreeData = newData.map((item, index) => {
        let tableRow = {
          id: item.id,
          key: item.id,
          name: (
            <div className="name cursor-pointer">
              <span>
                {item.contract_type === "Supplier" ? (
                  <>
                    <span>
                      <Image src={FolderImg} preview={false} width={15} />
                    </span>
                  </>
                ) : item.contract_type === "MSA" ? (
                  <>
                    <span>
                      <Image
                        src={frameworkAgreement}
                        preview={false}
                        width={15}
                      />
                    </span>
                  </>
                ) : item.contract_type === "Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "Sub-Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "SOW" ? (
                  <>
                    <span>
                      <Image src={sow} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CCN" ? (
                  <>
                    <span>
                      <Image src={ccn} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CR" ? (
                  <>
                    <span>
                      <Image src={cr} preview={false} width={20} />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <Image src={others} preview={false} width={20} />
                    </span>
                  </>
                )}{" "}
              </span>
              <span
                onClick={() => {
                  onSelectRight(item.id);
                  displayContract(item, item.type);
                }}
              >
                {item.contract_name
                  ? item.contract_name
                  : item[`${item.contract_type.toLowerCase()}_name`]}
              </span>
            </div>
          ),
          //date: item.createdAt,
          date: item.createdAt ? item.createdAt.substring(0, 10) : "",
          // ? item.createdAt.slice(0, 19).split("T")[0] +
          //   " " +
          //   item.createdAt.slice(0, 19).split("T")[1]
          // : "",

          // warnings: item.warnings ? item.warnings : "-----",
          // tags: item.tags ? item.tags : "",
          contract_type: item.contract_type,
          status: item.supplier_status,
        };
        return tableRow;
      });
      setTableData(selectedTreeData);
    }
  };

  const columns = [
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Contract Name
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("name") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "name",
      align: "left",
      show: selectedColLoc.includes("Name") ? true : false,
      sorter: (a, b) =>
        a.name.props.children[1].props.children.localeCompare(
          b.name.props.children[1].props.children
        ),
      sortOrder: isColumnSorted("name") ? sortOrder : false,
      ellipsis: true,
      // width: 380,
    },
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Contract Type
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("contract_type") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "contract_type",
      align: "left",
      show: selectedColLoc.includes("Contract Type") ? true : false,
      sorter: (a, b) => a.contract_type.localeCompare(b.contract_type),
      sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
      ellipsis: true,
      width: 200,
    },
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Status
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("status") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "status",
      align: "left",
      show: selectedColLoc.includes("Status") ? true : false,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
      width: 200,
    },
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Created Date
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("date") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "date",
      align: "left",
      show: selectedColLoc.includes("Created Date") ? true : false,
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortOrder: isColumnSorted("date") ? sortOrder : false,
      ellipsis: true,
      width: 200,
    },
    // {
    //   title: (
    //     <Row align="middle" type="flex">
    //       <Col span={22} className="sorted-title">
    //         Supplier Name Short
    //       </Col>
    //       <Col span={2} align="right">
    //         {isColumnSorted("supplier_name_short") && (
    //           <div className="custom-sort">
    //             {sortOrder === "ascend" && <UpOutlined />}
    //             {sortOrder === "descend" && <DownOutlined />}
    //           </div>
    //         )}
    //       </Col>
    //     </Row>
    //   ),
    //   dataIndex: "supplier_name_short",
    //   align: "left",
    //   show: selectedColLoc.includes("Supplier Name Short") ? true : false,
    //   sorter: (a, b) =>
    //     a.supplier_name_short.localeCompare(b.supplier_name_short),
    //   sortOrder: isColumnSorted("supplier_name_short") ? sortOrder : false,
    // },
    // {
    //   title: "Warnings",
    //   dataIndex: "warnings",
    // },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    // },
    {
      title: (
        <Image
          src={add}
          className="cursor-pointer"
          preview={false}
          onClick={() => openColEditor(true)}
        />
      ),
      dataIndex: "add",
      align: "center",
      width: 100,
    },
  ];

  const columnToShow = columns.filter((col) => col.show !== false);

  // additional column function
  // const selectCol = (column) => {
  //   // setContractTreeUpdate({
  //   //   selectedCol: [...selectedCol, column],
  //   //   unSelectedCol: unSelectedCol.filter((col) => col !== column),

  //   // });
  //   setSelectedCol([...selectedCol, column]);
  //   setUnSelectedCol(unSelectedCol.filter((col) => col !== column));
  // };

  // const unSelectCol = (column) => {
  //   // setContractTreeUpdate({
  //   //   unSelectedCol: [...unSelectedCol, column],
  //   //   selectedCol: selectedCol.filter((col) => col !== column),
  //   // });
  //   setUnSelectedCol([...unSelectedCol, column]);
  //   setSelectedCol(selectedCol.filter((col) => col !== column));
  // };

  const filterSearch = (value) => {
    const searched = listedCol.filter((col) =>
      col.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const unselected = searched.filter((element) => !value.includes(element));
    const newSelected = [...new Set([...selectedCol, ...value])];
    const updatedSelectedCol = newSelected.filter(
      (element) => !unselected.includes(element)
    );
    setSelectedCol(updatedSelectedCol);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const flat = (array) => {
    let result = [];
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flat(a.children));
      }
    });
    return result;
  };

  useEffect(() => {
    setPathIdIndex(pathId.length - 1);
  }, [pathId]);

  const getPath = (model, id) => {
    let path,
      item = {
        id: model._id,
        name: model.contract_name ? model.contract_name : model.supplier_name,
      };

    if (!model || typeof model !== "object") return;

    if (model._id === id) return [item];

    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (currentId) => {
    if (currentId !== "home") {
      responseData.some((res) => {
        const pathVal = getPath(res, currentId);
        if (pathVal) {
          setFinalPath([{ id: "home", name: "home" }, ...pathVal]);
          return pathVal;
        }
        return false;
      });
    } else {
      const finalP = [{ id: "home", name: "home" }];
      setFinalPath(finalP);
    }
  };
  // const addViewContractTab = () => {
  //   console.log("addViewContractTab", selectedItem);
  //   addPane({
  //     label: selectedItem.contract_name
  //       ? selectedItem.contract_name
  //       : selectedItem[`${selectedItem.contract_type.toLowerCase()}_name`],
  //     children: (
  //       <ContractFile selectedFile={selectedItem} screenSize={screenSize} />
  //     ),
  //     key: selectedItem.contract_name
  //       ? selectedItem.contract_name
  //       : selectedItem[`${selectedItem.contract_type.toLowerCase()}_name`],
  //   });
  // };

  const addViewContractTab = (item, finalPath) => {
    console.log("Item: ", item);
    console.log("finalPath before: ", finalPath);
    // addPane({
    //   label: item.contract_name
    //     ? item.contract_name
    //     : item[`${item.contract_type.toLowerCase()}_name`],
    //   children: <ContractFile selectedFile={item} screenSize={screenSize} />,
    //   key: item.contract_name
    //     ? item.contract_name
    //     : item[`${item.contract_type.toLowerCase()}_name`],
    // });
    let contractLog = {
      contract_id: item._id,
      contract_name: item.contract_name,
      code: "Contract-Viewed",
      author: userData[0].id,
      action: "Viewed",
      notes: "Viewed Contract",
      updated_on: new Date(),
    };
    createActivityLogApi(contractLog).then((res) => {
      console.log("LogRes: ", res);
    });
    addContractTab(item, finalPath);
  };

  useEffect(() => {
    changeScreenSize(screenSize);
  }, [screenSize]);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      //Do whatever when esc is pressed
      setScreenSize("min");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const displayContract = (item, type) => {
    console.log("displayContract: ", type);

    // addViewContractTab();
    if (
      type === "file" &&
      roleUser &&
      roleUser[0] &&
      roleUser[0].page_access.contracts.contract_tree.view
    ) {
      // setSelectedItem(item);
      addViewContractTab(item, finalPath);
    }
  };

  const displayContractParent = (item, type) => {
    addViewContractTab(item, finalPath);
  };

  useEffect(() => {
    console.log("selectedItem", selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    console.log("FinalPath: ", finalPath);
  }, [finalPath]);

  useEffect(() => {
    document.title = "Contract Tree | Judo";
  }, []);

  return (
    <Content>
      <Row
        align="top"
        type="flex"
        gutter={[12, 0]}
        className="m-0 breadcrumb-row"
      >
        <Col
          className="p-0"
          xs={24}
          sm={24}
          md={{
            span: screenSize === "min" ? 21 : 23,
            offset: screenSize === "min" ? 1 : 0,
          }}
          lg={{
            span: screenSize === "min" ? 21 : 23,
            offset: screenSize === "min" ? 1 : 0,
          }}
          xl={{
            span: screenSize === "min" ? 24 : 24,
            offset: screenSize === "min" ? 0 : 0,
          }}
        >
          <div className="breadcrumb">
            <span>
              {/* <Button disabled={pathIdIndex === 0} onClick={onClickBack}>
                <LeftOutlined />
              </Button>
              <Button
                disabled={pathIdIndex === pathId.length - 1}
                onClick={onClickForward}
              >
                <RightOutlined />
              </Button> */}

              <span className="breadcrumb-path">
                {finalPath &&
                  finalPath.map((item, i) => {
                    return (
                      <>
                        <span
                          key={item.id}
                          onClick={() => onSelectRight(item.id)}
                          className="path-text"
                        >
                          {item.name === "home" ? "Suppliers" : item.name}
                        </span>
                        <span
                          style={{
                            display:
                              i === finalPath.length - 1
                                ? "none"
                                : "inline-block",
                          }}
                          className="path-icon"
                        >
                          <RightOutlined />
                        </span>
                      </>
                    );
                  })}
              </span>
            </span>
          </div>
        </Col>

        {/* <Col className="p-0" xs={24} sm={24} md={1} lg={1} xl={1}>
          <div
            className="screen-sizer cursor-pointer"
            onClick={() => {
              setScreenSize(screenSize === "min" ? "max" : "min");
            }}
          >
            <span>
              <Image src={expand_content} preview={false} />
            </span>
          </div>
        </Col> */}

        {/* <Col xs={24} sm={24} md={24} lg={4} xl={5}>
          <Input
            placeholder="Find in page"
            suffix={<SearchOutlined style={{ color: "#b5b5b5" }} />}
            style={{ border: "none", padding: "5px 20px" }}
          />
        </Col> */}
      </Row>
      <Row align="top" type="flex" gutter={[12, 0]} className="m-0 table-row">
        <Col
          className="p-0"
          xs={24}
          sm={24}
          md={{
            span: screenSize === "min" ? 4 : 5,
            offset: screenSize === "min" ? 1 : 0,
          }}
          lg={{
            span: screenSize === "min" ? 4 : 5,
            offset: screenSize === "min" ? 1 : 0,
          }}
          xl={{
            span: screenSize === "min" ? 4 : 5,
            offset: screenSize === "min" ? 0 : 0,
          }}
        >
          <div
            className="glass-div"
            style={{
              maxHeight: screenSize === "min" ? "53vh" : "83vh",
              minHeight: screenSize === "min" ? "calc(100vh - 150px)" : "83vh",
            }}
          >
            <Tree
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              onClick={onSelect}
              treeData={alignedData}
              defaultExpandAll
              selectable={false}
              className="system-setup-table"
              showIcon
              titleRender={(node) => {
                const item = flattenedData.find(
                  (node1) => node1._id === node._id
                );
                if (selectedItem) {
                  if (selectedItem.supplier_name == item.supplier_name) {
                    selected = true;
                  } else {
                    selected = false;
                  }
                }
                return (
                  <div
                    className="title-div"
                    style={{
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <span
                      className={selected ? "tree-item-selected" : "tree-item"}
                    >
                      {item && item.contract_name
                        ? item.contract_name
                        : item.supplier_name}
                    </span>
                    {/* <span className="pin">
                      {item.pinned ? (
                        <PushpinFilled
                          style={{ fontSize: "11px", marginRight: "1px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span> */}
                  </div>
                );
              }}
              icon={(node) => {
                return node.data.contract_type === "Supplier" ? (
                  <>
                    <Image src={FolderImg} preview={false} width={10} />
                  </>
                ) : node.data.contract_type === "MSA" ? (
                  <>
                    <span>
                      <Image
                        src={frameworkAgreement}
                        preview={false}
                        width={15}
                      />
                    </span>
                  </>
                ) : node.data.contract_type === "Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : node.data.contract_type === "Sub-Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : node.data.contract_type === "SOW" ? (
                  <>
                    <span>
                      <Image src={sow} preview={false} width={20} />
                    </span>
                  </>
                ) : node.data.contract_type === "CCN" ? (
                  <>
                    <span>
                      <Image src={ccn} preview={false} width={20} />
                    </span>
                  </>
                ) : node.data.contract_type === "CR" ? (
                  <>
                    <span>
                      <Image src={cr} preview={false} width={20} />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <Image src={others} preview={false} width={20} />
                    </span>
                  </>
                );
              }}
              switcherIcon={<DownOutlined />}
            >
              {renderTreeNodes(alignedData)}
            </Tree>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: screenSize === "min" ? 18 : 19, offset: 0 }}
          lg={{ span: screenSize === "min" ? 18 : 19, offset: 0 }}
          xl={{ span: screenSize === "min" ? 20 : 19, offset: 0 }}
          style={{ minHeight: "calc(100vh - 150px)" }}
        >
          {parentItem &&
            parentItem.length > 0 &&
            parentItem[0].contract_type !== "Supplier" && (
              <>
                {/* <Card
                    title={
                      selectedItem.contract_name
                        ? selectedItem.contract_name
                        : selectedItem[
                            `${selectedItem.contract_type.toLowerCase()}_name`
                          ]
                    }
                    bordered={false}
                  >
                    <Button onClick={addViewContractTab}>View Contract</Button>
                  </Card> */}
                {tableData.length !== 0 && (
                  <h4 className="parent-contract-title">Parent Contract</h4>
                )}
                <Table
                  // rowSelection={rowSelection}
                  dataSource={parentItem}
                  rowClassName={() => "contract-tree-table-row"}
                  columns={columnToShow}
                  onChange={handleTableChange}
                  showSorterTooltip={false}
                  bordered
                  size="large "
                  scroll={{ x: "calc(300px + 50%)", y: 400 }}
                  pagination={false}
                  className="system-setup-table mt-10"
                />
              </>
            )}

          {tableData && tableData.length ? (
            <>
              {pathId &&
                pathId.length > 0 &&
                pathId[pathId.length - 1] !== "home" &&
                parentItem &&
                parentItem.length > 0 &&
                parentItem[0].contract_type !== "Supplier" && (
                  <h4 className="mt-10 parent-contract-title">
                    Child Contracts
                  </h4>
                )}
              <Table
                // rowSelection={rowSelection}
                dataSource={tableData}
                rowClassName={() => "contract-tree-table-row"}
                columns={columnToShow}
                onChange={handleTableChange}
                showSorterTooltip={false}
                bordered
                size="large"
                scroll={
                  pathId &&
                  pathId.length > 0 &&
                  pathId[pathId.length - 1] !== "home" &&
                  parentItem &&
                  parentItem.length > 0 &&
                  parentItem[0].contract_type !== "Supplier"
                    ? { x: "calc(300px + 50%)", y: "calc(100vh - 482px)" }
                    : { x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }
                }
                pagination={{ pageSize: 20 }}
                className="system-setup-table"
              />
            </>
          ) : (
            <></>
          )}

          {/* {type === "file" && selectedItem && (
            <>
              <ContractFile
                selectedItem={selectedItem}
                screenSize={screenSize}
              />
            </>
          )} */}
        </Col>
      </Row>
      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => {
            openColEditor(false);
            // setSelectedColLoc(contractPipelineUpdate.selectedCol);
            // setUnSelectedColLoc(contractPipelineUpdate.unSelectedCol);
          }}
          onCancel={() => {
            setSearchQuery("");
            openColEditor(false);
            // setSelectedColLoc(contractPipelineUpdate.selectedCol);
            // setUnSelectedColLoc(contractPipelineUpdate.unSelectedCol);
            setSelectedCol(selectedBackup);
            setSelectedColLoc(selectedBackup);
          }}
          closable={true}
          footer={null}
        >
          <div>
            <h3>Add Column</h3>
            <span className="col-editor-head">
              Select or Deselect the tag to show in Contract Tree
            </span>
          </div>
          <Row type="flex">
            <Col span={24} className="mt-20">
              <Input
                placeholder="Search column tags"
                allowClear
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
          </Row>
          <div className="mt-20">
            <span className="col-editor-head">
              Different options in choosing the tag for Contract Tree
            </span>
          </div>
          <div className="col-list p-10">
            <Row gutter={[8, 16]} type="flex">
              <Col span={24}>
                {listedCol &&
                listedCol.length !== 0 &&
                listedCol.filter((col) =>
                  col.toLowerCase().includes(searchQuery.toLowerCase())
                ).length !== 0 ? (
                  <Checkbox.Group
                    style={{
                      display: "block",
                      overflowY: "scroll",
                      height: 250,
                    }}
                    options={listedCol
                      .filter((col) =>
                        col.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((col) => ({
                        label: col,
                        value: col,
                        disabled:
                          selectedCol.length === 1 && selectedCol.includes(col),
                      }))}
                    defaultValue={selectedCol}
                    onChange={(value) => {
                      if (searchQuery != "") {
                        filterSearch(value);
                        return;
                      }
                      setSelectedCol(value);
                    }}
                  />
                ) : (
                  <p>No column name matches search query</p>
                )}
              </Col>
            </Row>
          </div>
          <Row type="flex">
            <Col span={24} align="middle" className="mt-20">
              <Button
                className="save-btn"
                onClick={() => {
                  setSearchQuery("");
                  setSelectedBackup(selectedCol);
                  setSelectedColLoc(selectedCol);
                  setContractTreeUpdate({
                    selectedCol: selectedCol,
                    unSelectedCol: listedCol.filter(
                      (element) => !selectedCol.includes(element)
                    ),
                  });
                  openColEditor(false);
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Content>
  );
}

export default ContractTree;
