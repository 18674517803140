// External imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; //router
import { Button, Form, Input, Row, Col, Modal, Image } from "antd"; //antd components
import CryptoJS from "crypto-js";
import "../assets/style/login.css";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  UserTokensAtom,
  RoleAccessAtom,
  userDataAtom,
  isLiteAtom,
} from "../store/store";
import Password from "../assets/images/icons/password.svg";
import emailIcon from "../assets/images/icons/mark_email_unread.svg";
import { loginApi, getPasswordLinkApi, editUserApi } from "../api/usersApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import judoIcon from "../assets/images/icons/judo_logo.svg";

// functional component for dashboard page
function LoginPage() {
  // Variable to hold the user tolen
  const setUserToken = useSetRecoilState(UserTokensAtom);
  // State variable to hold logged roles data
  const [roleuser, setRoleUser] = useRecoilState(RoleAccessAtom);
  // State variable to hold logger user data
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [lite, setIsLite] = useRecoilState(isLiteAtom);
  // State variable to hold email data
  const [email, setEmail] = useState("");
  // State variable to hold enter email id
  const [enteredEmail, setEnteredEmail] = useState("");
  // State variable to hold password
  const [password, setPassword] = useState("");
  // State variable to hold password link
  const [passwordLink, setPasswordLink] = useState("");
  // State variable to hold table loder visibiity status
  const [tableLoader, setTableLoader] = useState(false);
  // State variable to hold send meg visibility status
  const [linkSendMsg, setLinkSendMsg] = useState(false);
  // State variable to hold navigate
  let history = useNavigate();
  // Variable to hold mysecretkey value
  const SECRET_KEY = "mysecretkey";
  /* Function to handle encrypt the password
   * @Param name - a loggedInUser data
   * @Param data - a logged user id
   */
  const encryptData = (name, data) => {
    const encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    localStorage.setItem(name, encryptedValue);
  };
  /* Function to handle save the form data
   * @Param values - a form values
   */
  const onFinish = async (values) => {
    console.log("Values: ", values);
    setTableLoader(true);
    const data = await loginApi(values).then((res) => {
      console.log("res", res);
      if (res.status === true) {
        setUserToken(res.tokens);
        console.log("res", res);
        let accessDetail = {
          page_access:
            res &&
            res.roleAccess &&
            res.roleAccess.page_access &&
            res.roleAccess.page_access,
          entity_rights:
            res &&
            res.roleAccess &&
            res.roleAccess.entity_rights &&
            res.roleAccess.entity_rights,
          role_name:
            res &&
            res.roleAccess &&
            res.roleAccess.role_name &&
            res.roleAccess.role_name,
        };
        let secret = "localSecret_key";
        setRoleUser(accessDetail);
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const currentDate = dayjs();
        const gmtTime = currentDate.tz("Etc/Greenwich");
        const userDetails = { ...res.user };
        const userId = res.user.id;
        delete userDetails.isEmailVerified;
        delete userDetails.status;
        delete userDetails.id;
        userDetails["last_login"] = userDetails["current_login"]
          ? userDetails["current_login"]
          : undefined;
        userDetails["current_login"] = gmtTime;
        userDetails["last_name"] =
          userDetails["last_name"] === "" ? " " : userDetails["last_name"];
        editUserApi(userId, userDetails);
        res.user["last_login"] = userDetails["last_login"];
        setUserData(res.user);
        setIsLite(res.isLite);
        let loggedInUservalue = res.user.id;
        encryptData("loggedInUser", res.user.id);
        history(`/user-dashboard`);
      } else if (res.status === "set-password") {
        setPasswordLink(true);
        setEnteredEmail(values.email);
      } else {
        Modal.error({
          title: "Failed to Login",
          className: "popup-model-success",
          centered: true,
          icon: true,
          content: (
            <>
              <div>{res.data}</div>
            </>
          ),
        });
      }
    });
  };
  /* Function to handle the form submission error
   * @Param errorInfo - a error message
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // Function to handle password visibility change
  const getPasswordLink = () => {
    getPasswordLinkApi(enteredEmail, "set").then((res) => {
      if (res.status) {
        setPasswordLink(false);
        setLinkSendMsg(true);
      }
    });
  };

  useEffect(() => {
    document.title = "Login | Judo";
  }, []);

  return (
    <div>
      <div className="login-page login-bg gradient-bg">
        {!linkSendMsg && !passwordLink && (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="login-form p-30"
          >
            <div style={{ textAlign: "center" }}>
              <img src={judoIcon} alt="logo" style={{ width: "60px" }} /> &nbsp;
            </div>{" "}
            &nbsp;
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                className="vertical-align"
              >
                <Form.Item
                  label=""
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                  validateTrigger={["onBlur"]}
                >
                  <Input
                    size="large"
                    className="login-input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                className="vertical-align"
              >
                <Form.Item
                  label=""
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="login-input"
                    size="large"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                className="vertical-align"
              >
                <Form.Item>
                  <div
                    className="float-right forgot-psw-link"
                    onClick={() => {
                      history(`/forgot-password`);
                    }}
                  >
                    Forgot Password
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                className="vertical-align"
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={email && password ? false : true}
                    className="signin-btn"
                    style={{ width: "100%" }}
                  >
                    Sign In
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <p
              style={{ textAlign: "center", fontSize: "12px" }}
              className="p-30"
            >
              Not a member?{" "}
              <span className="registernow">
                <a href="/">Register now</a>
              </span>
            </p>
          </Form>
        )}
        {!linkSendMsg && passwordLink && (
          <div className="login-form text-center p-30">
            <div>
              <Image src={Password} preview={false} />
            </div>
            <div className="verification-msg mt-10">Create Your Password</div>
            <div className="mt-30">
              You haven't set a password for your account. If you'd like to
              proceed, please click here to receive a link for setting up your
              account password.
            </div>
            <Button
              type="primary"
              htmlType="submit"
              onClick={getPasswordLink}
              className="set-password-btn mt-30"
              style={{ width: "25%" }}
            >
              Get Link
            </Button>
          </div>
        )}
        {linkSendMsg && (
          <div className="login-form text-center p-30">
            <div>
              <Image src={emailIcon} preview={false} />
            </div>
            <div className="auth-head-msg mt-10">Email has been sent</div>
            <div className="mt-30">
              A password reset link has been sent to your account. Kindly set
              your password before attempting to log in again.
            </div>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                setLinkSendMsg(false);
                setPasswordLink(false);
              }}
              className="signin-btn mt-20"
              style={{ width: "25%" }}
            >
              Go Back
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
