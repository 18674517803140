import { message } from "antd";

export const validateFileSize = (file, maxSizeMB = 10) => {
  const isWithinSizeLimit = file.size / 1024 / 1024 <= maxSizeMB;
  if (!isWithinSizeLimit) {
    message.error(`File size must not exceed ${maxSizeMB} MB.`);
    return false;
  }
  return true;
};
