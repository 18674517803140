import React, { useEffect } from "react";

const URLextractor = () => {
  useEffect(() => {
    const extractURLs = async () => {
      try {
        const response = await fetch("http://localhost:3000");
        console.log("response", response);
        const html = await response.text();
        console.log("html", html);

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        console.log("doc", doc);

        const anchorTags = doc.getElementsByTagName("a");
        console.log("anchorTags", anchorTags);
        const urls = Array.from(anchorTags).map((a) => a.href);

        console.log(urls);
      } catch (error) {
        console.error("Error fetching the website:", error);
      }
    };

    extractURLs();
  }, []);

  return null;
};

export default URLextractor;
