import { axiosInstance } from "../utils/common";

export const getAllRolesApi = () => {
  return axiosInstance
    .get("v1/userRoles")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllRolesPaginationApi = (
  page,
  limit,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/userRoles/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleRolesApi = (roleId) => {
  return axiosInstance
    .get(`v1/userRoles/${roleId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createRoleAPi = (roleDetails) => {
  return axiosInstance
    .post("v1/userRoles", roleDetails)
    .then((res) => {
      console.log("APIres: ", res);
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const editRoleAPi = (roleId, updatedRoleDetails) => {
  return axiosInstance
    .patch(`v1/userRoles/${roleId}`, updatedRoleDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const deleteUserRoleAPi = (roleId) => {
  return axiosInstance
    .delete(`v1/userRoles/${roleId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllRoleFiltersApi = () => {
  return axiosInstance
    .get(`v1/userRoles/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSupplierForRole = (page = 1, limit = 20, search = "") => {
  return axiosInstance
    .get(
      `v1/userRoles/role/supplier?page=${page}&limit=${limit}&search=${search}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getPrimaryContractForRole = (
  page = 1,
  limit = 20,
  search = "",
  supplierIds = []
) => {
  return axiosInstance
    .post(
      `v1/userRoles/role/primary?page=${page}&limit=${limit}&search=${search}`,
      { supplierIds: supplierIds.join(",") }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSecondaryContractForRole = (
  page = 1,
  limit = 20,
  search = "",
  primaryContractIds = []
) => {
  return axiosInstance
    .post(
      `v1/userRoles/role/secondary?page=${page}&limit=${limit}&search=${search}`,
      { primaryContractIds: primaryContractIds.join(",") }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
