import React, { useState, useEffect } from "react";
import { Image, Layout, Card, Button, Spin } from "antd"; //antd components
import { useLocation, useParams } from "react-router-dom";
import TopHeader from "../components/Header";
import "../assets/style/contract.css";
import { getSingleContractApi } from "../api/contractsApi";
import { useNavigate } from "react-router-dom";
import ContractFile from "../components/Contract/ContractFile";
import link_off from "../assets/images/icons/link_off.svg";
import CustomLoader from "../components/CustomLoader";

function ContrcatReview() {
  // Variable to use on navigate the page
  const history = useNavigate();
  // Variable to hold manu key
  const menuKey = "2";
  // State variable to hold screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold selected contract data
  const [selectedContract, setSelectedContract] = useState([]);
  // Variable to hold the contract id and expiry time
  const { contractId, expiry_time, supplier } = useParams();
  // State variable to hold expired mes visibility status
  const [expiredMsg, setExpiredMsg] = useState(false);
  // State variable to hold button loader visibility status
  const [loader, setLoader] = useState(true);
  // Variable to hold todat date object
  let myDate = new Date();
  // Convert the Date object (myDate) to Unix timestamp by dividing its time in milliseconds by 1000
  const unixTimestamp = Math.floor(myDate.getTime() / 1000);
  // Function called when change the contractid then get the selected contract  data
  useEffect(() => {
    if (expiry_time <= unixTimestamp) {
      setExpiredMsg(true);
      setLoader(false);
    }
    getSingleContractApi(contractId).then((res) => {
      console.log("res", res);
      setSelectedContract(res.docs);
      setLoader(false);
      // if (supplier !== "Wayne Internationals") {
      history(`/contract/edit/general`, { state: res.docs[0] });
      // } else {
      //   history(`/contract/edit/wayne`, { state: res.docs[0] });
      // }
    });
  }, [contractId]);
  /* Function to handle change the screen size
   * @Param val - a screen size
   */
  const changeScreenSize = (val) => {
    setScreenSize(val);
  };

  return (
    <div className="gradient-bg contract">
      <Layout className="header-layout">
        <div style={{ display: screenSize === "min" ? "block" : "none" }}>
          <TopHeader
            feedRow={false}
            searchRow={false}
            menuKey={menuKey}
            sticky=""
          />
        </div>
        {loader && <CustomLoader />}
        {expiredMsg && !loader && (
          <div className="auth-bg auth-page-bg">
            <Card className="auth-card p-30">
              <div>
                <Image src={link_off} preview={false} />
              </div>
              <div className="regenerate-msg mt-10">Link Expired</div>
              <div className="mt-20">
                The link is valid for only 24 hours. Please contact admin for
                further details.
              </div>
            </Card>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default ContrcatReview;
