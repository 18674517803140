import React, { useEffect, useState } from "react";
import { Row, Col, Layout, Tabs } from "antd";
import Actionsearch from "./ActionSearch";
import MySpace from "./MySpace";
import AllActionInstanceView from "./AllActionInstanceView";
import { json } from "react-router";

const ActionInstance = () => {
  const [parentTabKey, setParentTabKey] = useState(
    sessionStorage.getItem("action Instance TabKey")
      ? JSON.stringify(
          Number(JSON.parse(sessionStorage.getItem("action Instance TabKey")))
        )
      : "1"
  );

  const onChange = (key) => {
    setParentTabKey(key);
    sessionStorage.setItem("action Instance TabKey", key);
  };

  const items = [
    {
      key: "1",
      label: "My Space",
      children: <MySpace parentTabKey={parentTabKey} />,
    },
    {
      key: "2",
      label: "Search",
      children: <Actionsearch parentTabKey={parentTabKey} />,
    },
    {
      key: "3",
      label: "View",
      children: <AllActionInstanceView parentTabKey={parentTabKey} />,
    },
  ];

  useEffect(() => {
    document.title = "Action Instance | Judo";
  }, []);

  return (
    <Layout className="header-layout">
      <div className="contract-pipeline">
        <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={15}
            lg={24}
            xl={24}
            align="center"
          >
            <Tabs
              className="action-instance-tab"
              defaultActiveKey={parentTabKey}
              items={items}
              onChange={onChange}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
export default ActionInstance;
