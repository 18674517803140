import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "antd"; //antd components
import { dataTable } from "../../utils/common";
import { associationCol, entityCol } from "../../utils/tableColumns";
import moment from "moment";

const DataTable = ({ type, dataTableData, logData, colData, ...props }) => {
  // console.log("logData", logData);
  const [entityData, setEntityData] = useState([]);

  useEffect(() => {
    let entityLog =
      logData && logData.entity_logs && logData.entity_logs.length > 0
        ? logData.entity_logs
            .map((data) => {
              // console.log("adata", data);

              if (data.action) {
                return {
                  ...data,
                  updated_on: data.updated_on
                    ? moment(data.updated_on).format("DD MMM YYYY, hh:mm")
                    : " ",
                  updated_by: data.author_name,
                };
              }
            })
            .filter((entry) => entry !== undefined)
        : [];
    setEntityData(entityLog);
  }, [logData]);

  return (
    <div>
      <Row>
        <Col span={24}>
          <div
            className={
              type === "versionDetail" ||
              type === "fx" ||
              type === "group-member-view" ||
              type === "group-member" ||
              type === "report" ||
              type === "clause-library" ||
              type === "template"
                ? ""
                : "org-view-div"
            }
          >
            {/* <div
              style={{
                display: "flex",
                padding: type === "versionDetail" ? "0" : "45px 32px 12px 10px",
              }}
            /> */}
            <Table
              className={`${dataTable} ${
                type !== "fx" ? "mb-10 mt-25" : "mt-10"
              }`}
              columns={
                type === "association"
                  ? associationCol
                  : type === "entityLog"
                  ? entityCol
                  : colData
              }
              dataSource={type === "entityLog" ? entityData : dataTableData}
              pagination={false}
              style={{ padding: "0% 20% " }}
              scroll={{
                x: "calc(300px + 50%)",
                y:
                  type === "fx"
                    ? 350
                    : type === "group-member-view"
                    ? 300
                    : type === "group-member"
                    ? 260
                    : 400,
              }}
              {...props}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DataTable;
