export const timezones = [
  {
    countryCode: "AF",
    countryName: "Afghanistan",
    timeZones: ["Asia/Kabul"],
    UTCOffset: ["UTC +04:30"],
  },
  {
    countryCode: "AX",
    countryName: "Aland Islands",
    timeZones: ["Europe/Mariehamn"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "AL",
    countryName: "Albania",
    timeZones: ["Europe/Tirane"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "DZ",
    countryName: "Algeria",
    timeZones: ["Africa/Algiers"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "AS",
    countryName: "American Samoa",
    timeZones: ["Pacific/Pago_Pago"],
    UTCOffset: ["UTC -11:00"],
  },
  {
    countryCode: "AD",
    countryName: "Andorra",
    timeZones: ["Europe/Andorra"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "AO",
    countryName: "Angola",
    timeZones: ["Africa/Luanda"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "AI",
    countryName: "Anguilla",
    timeZones: ["America/Anguilla"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    timeZones: [
      "Antarctica/Casey",
      "Antarctica/Davis",
      "Antarctica/DumontDUrville",
      "Antarctica/Mawson",
      "Antarctica/McMurdo",
      "Antarctica/Palmer",
      "Antarctica/Rothera",
      "Antarctica/Syowa",
      "Antarctica/Troll",
      "Antarctica/Vostok",
    ],
    UTCOffset: [
      "UTC +08:00",
      "UTC +07:00",
      "UTC +10:00",
      "UTC +05:00",
      "UTC +12:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC +03:00",
      "UTC +02:00",
      "UTC +06:00",
    ],
  },
  {
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    timeZones: ["America/Antigua"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    timeZones: [
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
    ],
    UTCOffset: [
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
    ],
  },
  {
    countryCode: "AM",
    countryName: "Armenia",
    timeZones: ["Asia/Yerevan"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "AW",
    countryName: "Aruba",
    timeZones: ["America/Aruba"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    timeZones: [
      "Antarctica/Macquarie",
      "Australia/Adelaide",
      "Australia/Brisbane",
      "Australia/Broken_Hill",
      "Australia/Currie",
      "Australia/Darwin",
      "Australia/Eucla",
      "Australia/Hobart",
      "Australia/Lindeman",
      "Australia/Lord_Howe",
      "Australia/Melbourne",
      "Australia/Perth",
      "Australia/Sydney",
    ],
    UTCOffset: [
      "UTC +11:00",
      "UTC +09:30",
      "UTC +10:00",
      "UTC +09:30",
      "UTC +10:00",
      "UTC +09:30",
      "UTC +08:45",
      "UTC +10:00",
      "UTC +10:00",
      "UTC +10:30",
      "UTC +10:00",
      "UTC +08:00",
      "UTC +10:00",
    ],
  },
  {
    countryCode: "AT",
    countryName: "Austria",
    timeZones: ["Europe/Vienna"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "AZ",
    countryName: "Azerbaijan",
    timeZones: ["Asia/Baku"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "BS",
    countryName: "Bahamas",
    timeZones: ["America/Nassau"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "BH",
    countryName: "Bahrain",
    timeZones: ["Asia/Bahrain"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "BD",
    countryName: "Bangladesh",
    timeZones: ["Asia/Dhaka"],
    UTCOffset: ["UTC +06:00"],
  },
  {
    countryCode: "BB",
    countryName: "Barbados",
    timeZones: ["America/Barbados"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "BY",
    countryName: "Belarus",
    timeZones: ["Europe/Minsk"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "BE",
    countryName: "Belgium",
    timeZones: ["Europe/Brussels"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "BZ",
    countryName: "Belize",
    timeZones: ["America/Belize"],
    UTCOffset: ["UTC -06:00"],
  },
  {
    countryCode: "BJ",
    countryName: "Benin",
    timeZones: ["Africa/Porto-Novo"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "BM",
    countryName: "Bermuda",
    timeZones: ["Atlantic/Bermuda"],
    UTCOffset: ["UTC -03:00"],
  },
  {
    countryCode: "BT",
    countryName: "Bhutan",
    timeZones: ["Asia/Thimphu"],
    UTCOffset: ["UTC +06:00"],
  },
  {
    countryCode: "BO",
    countryName: "Bolivia",
    timeZones: ["America/La_Paz"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "BQ",
    countryName: "Bonaire, Saint Eustatius and Saba",
    timeZones: ["America/Kralendijk"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    timeZones: ["Europe/Sarajevo"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "BW",
    countryName: "Botswana",
    timeZones: ["Africa/Gaborone"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    timeZones: [
      "America/Araguaina",
      "America/Bahia",
      "America/Belem",
      "America/Boa_Vista",
      "America/Campo_Grande",
      "America/Cuiaba",
      "America/Eirunepe",
      "America/Fortaleza",
      "America/Maceio",
      "America/Manaus",
      "America/Noronha",
      "America/Porto_Velho",
      "America/Recife",
      "America/Rio_Branco",
      "America/Santarem",
      "America/Sao_Paulo",
    ],
    UTCOffset: [
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -05:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -04:00",
      "UTC -02:00",
      "UTC -04:00",
      "UTC -03:00",
      "UTC -05:00",
      "UTC -03:00",
      "UTC -03:00",
    ],
  },
  {
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
    timeZones: ["Indian/Chagos"],
    UTCOffset: ["UTC +06:00"],
  },
  {
    countryCode: "VG",
    countryName: "British Virgin Islands",
    timeZones: ["America/Tortola"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "BN",
    countryName: "Brunei",
    timeZones: ["Asia/Brunei"],
    UTCOffset: ["UTC +08:00"],
  },
  {
    countryCode: "BG",
    countryName: "Bulgaria",
    timeZones: ["Europe/Sofia"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "BF",
    countryName: "Burkina Faso",
    timeZones: ["Africa/Ouagadougou"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "BI",
    countryName: "Burundi",
    timeZones: ["Africa/Bujumbura"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "KH",
    countryName: "Cambodia",
    timeZones: ["Asia/Phnom_Penh"],
    UTCOffset: ["UTC +07:00"],
  },
  {
    countryCode: "CM",
    countryName: "Cameroon",
    timeZones: ["Africa/Douala"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    timeZones: [
      "America/Atikokan",
      "America/Blanc-Sablon",
      "America/Cambridge_Bay",
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Edmonton",
      "America/Fort_Nelson",
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Inuvik",
      "America/Iqaluit",
      "America/Moncton",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Regina",
      "America/Resolute",
      "America/St_Johns",
      "America/Swift_Current",
      "America/Thunder_Bay",
      "America/Toronto",
      "America/Vancouver",
      "America/Whitehorse",
      "America/Winnipeg",
      "America/Yellowknife",
    ],
    UTCOffset: [
      "UTC -05:00",
      "UTC -04:00",
      "UTC -06:00",
      "UTC -07:00",
      "UTC -07:00",
      "UTC -07:00",
      "UTC -06:00",
      "UTC -07:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -03:00",
      "UTC -06:00",
      "UTC -04:00",
      "UTC -03:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -05:00",
      "UTC -05:00",
      "UTC -06:00",
      "UTC -05:00",
      "UTC -02:30",
      "UTC -06:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -07:00",
      "UTC -07:00",
      "UTC -05:00",
      "UTC -06:00",
    ],
  },
  {
    countryCode: "CV",
    countryName: "Cape Verde",
    timeZones: ["Atlantic/Cape_Verde"],
    UTCOffset: ["UTC -01:00"],
  },
  {
    countryCode: "KY",
    countryName: "Cayman Islands",
    timeZones: ["America/Cayman"],
    UTCOffset: ["UTC -05:00"],
  },
  {
    countryCode: "CF",
    countryName: "Central African Republic",
    timeZones: ["Africa/Bangui"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "TD",
    countryName: "Chad",
    timeZones: ["Africa/Ndjamena"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "CL",
    countryName: "Chile",
    timeZones: ["America/Punta_Arenas", "America/Santiago", "Pacific/Easter"],
    UTCOffset: ["UTC -03:00", "UTC -04:00", "UTC -06:00"],
  },
  {
    countryCode: "CN",
    countryName: "China",
    timeZones: ["Asia/Shanghai", "Asia/Urumqi"],
    UTCOffset: ["UTC +08:00", "UTC +06:00"],
  },
  {
    countryCode: "CX",
    countryName: "Christmas Island",
    timeZones: ["Indian/Christmas"],
    UTCOffset: ["UTC +07:00"],
  },
  {
    countryCode: "CC",
    countryName: "Cocos Islands",
    timeZones: ["Indian/Cocos"],
    UTCOffset: ["UTC +06:30"],
  },
  {
    countryCode: "CO",
    countryName: "Colombia",
    timeZones: ["America/Bogota"],
    UTCOffset: ["UTC -05:00"],
  },
  {
    countryCode: "KM",
    countryName: "Comoros",
    timeZones: ["Indian/Comoro"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "CK",
    countryName: "Cook Islands",
    timeZones: ["Pacific/Rarotonga"],
    UTCOffset: ["UTC -10:00"],
  },
  {
    countryCode: "CR",
    countryName: "Costa Rica",
    timeZones: ["America/Costa_Rica"],
    UTCOffset: ["UTC -06:00"],
  },
  {
    countryCode: "HR",
    countryName: "Croatia",
    timeZones: ["Europe/Zagreb"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "CU",
    countryName: "Cuba",
    timeZones: ["America/Havana"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "CW",
    countryName: "Curaçao",
    timeZones: ["America/Curacao"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
    timeZones: ["Asia/Famagusta", "Asia/Nicosia"],
    UTCOffset: ["UTC +03:00", "UTC +03:00"],
  },
  {
    countryCode: "CZ",
    countryName: "Czech Republic",
    timeZones: ["Europe/Prague"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    timeZones: ["Africa/Kinshasa", "Africa/Lubumbashi"],
    UTCOffset: ["UTC +01:00", "UTC +02:00"],
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
    timeZones: ["Europe/Copenhagen"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "DJ",
    countryName: "Djibouti",
    timeZones: ["Africa/Djibouti"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "DM",
    countryName: "Dominica",
    timeZones: ["America/Dominica"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "DO",
    countryName: "Dominican Republic",
    timeZones: ["America/Santo_Domingo"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "TL",
    countryName: "East Timor",
    timeZones: ["Asia/Dili"],
    UTCOffset: ["UTC +09:00"],
  },
  {
    countryCode: "EC",
    countryName: "Ecuador",
    timeZones: ["America/Guayaquil", "Pacific/Galapagos"],
    UTCOffset: ["UTC -05:00", "UTC -06:00"],
  },
  {
    countryCode: "EG",
    countryName: "Egypt",
    timeZones: ["Africa/Cairo"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SV",
    countryName: "El Salvador",
    timeZones: ["America/El_Salvador"],
    UTCOffset: ["UTC -06:00"],
  },
  {
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    timeZones: ["Africa/Malabo"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "ER",
    countryName: "Eritrea",
    timeZones: ["Africa/Asmara"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
    timeZones: ["Europe/Tallinn"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "ET",
    countryName: "Ethiopia",
    timeZones: ["Africa/Addis_Ababa"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "FK",
    countryName: "Falkland Islands",
    timeZones: ["Atlantic/Stanley"],
    UTCOffset: ["UTC -03:00"],
  },
  {
    countryCode: "FO",
    countryName: "Faroe Islands",
    timeZones: ["Atlantic/Faroe"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "FJ",
    countryName: "Fiji",
    timeZones: ["Pacific/Fiji"],
    UTCOffset: ["UTC +12:00"],
  },
  {
    countryCode: "FI",
    countryName: "Finland",
    timeZones: ["Europe/Helsinki"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "FR",
    countryName: "France",
    timeZones: ["Europe/Paris"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "GF",
    countryName: "French Guiana",
    timeZones: ["America/Cayenne"],
    UTCOffset: ["UTC -03:00"],
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
    timeZones: ["Pacific/Gambier", "Pacific/Marquesas", "Pacific/Tahiti"],
    UTCOffset: ["UTC -09:00", "UTC -09:30", "UTC -10:00"],
  },
  {
    countryCode: "TF",
    countryName: "French Southern Territories",
    timeZones: ["Indian/Kerguelen"],
    UTCOffset: ["UTC +05:00"],
  },
  {
    countryCode: "GA",
    countryName: "Gabon",
    timeZones: ["Africa/Libreville"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "GM",
    countryName: "Gambia",
    timeZones: ["Africa/Banjul"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "GE",
    countryName: "Georgia",
    timeZones: ["Asia/Tbilisi"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    timeZones: ["Europe/Berlin", "Europe/Busingen"],
    UTCOffset: ["UTC +02:00", "UTC +02:00"],
  },
  {
    countryCode: "GH",
    countryName: "Ghana",
    timeZones: ["Africa/Accra"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "GI",
    countryName: "Gibraltar",
    timeZones: ["Europe/Gibraltar"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "GR",
    countryName: "Greece",
    timeZones: ["Europe/Athens"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    timeZones: [
      "America/Danmarkshavn",
      "America/Godthab",
      "America/Scoresbysund",
      "America/Thule",
    ],
    UTCOffset: ["UTC", "UTC -02:00", "UTC", "UTC -03:00"],
  },
  {
    countryCode: "GD",
    countryName: "Grenada",
    timeZones: ["America/Grenada"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "GP",
    countryName: "Guadeloupe",
    timeZones: ["America/Guadeloupe"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "GU",
    countryName: "Guam",
    timeZones: ["Pacific/Guam"],
    UTCOffset: ["UTC +10:00"],
  },
  {
    countryCode: "GT",
    countryName: "Guatemala",
    timeZones: ["America/Guatemala"],
    UTCOffset: ["UTC -06:00"],
  },
  {
    countryCode: "GG",
    countryName: "Guernsey",
    timeZones: ["Europe/Guernsey"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "GN",
    countryName: "Guinea",
    timeZones: ["Africa/Conakry"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "GW",
    countryName: "Guinea-Bissau",
    timeZones: ["Africa/Bissau"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "GY",
    countryName: "Guyana",
    timeZones: ["America/Guyana"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "HT",
    countryName: "Haiti",
    timeZones: ["America/Port-au-Prince"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "HN",
    countryName: "Honduras",
    timeZones: ["America/Tegucigalpa"],
    UTCOffset: ["UTC -06:00"],
  },
  {
    countryCode: "HK",
    countryName: "Hong Kong",
    timeZones: ["Asia/Hong_Kong"],
    UTCOffset: ["UTC +08:00"],
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
    timeZones: ["Europe/Budapest"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "IS",
    countryName: "Iceland",
    timeZones: ["Atlantic/Reykjavik"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "IN",
    countryName: "India",
    timeZones: ["Asia/Kolkata"],
    UTCOffset: ["UTC +05:30"],
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    timeZones: [
      "Asia/Jakarta",
      "Asia/Jayapura",
      "Asia/Makassar",
      "Asia/Pontianak",
    ],
    UTCOffset: ["UTC +07:00", "UTC +09:00", "UTC +08:00", "UTC +07:00"],
  },
  {
    countryCode: "IR",
    countryName: "Iran",
    timeZones: ["Asia/Tehran"],
    UTCOffset: ["UTC +04:30"],
  },
  {
    countryCode: "IQ",
    countryName: "Iraq",
    timeZones: ["Asia/Baghdad"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "IE",
    countryName: "Ireland",
    timeZones: ["Europe/Dublin"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "IM",
    countryName: "Isle of Man",
    timeZones: ["Europe/Isle_of_Man"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "IL",
    countryName: "Israel",
    timeZones: ["Asia/Jerusalem"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "IT",
    countryName: "Italy",
    timeZones: ["Europe/Rome"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "CI",
    countryName: "Ivory Coast",
    timeZones: ["Africa/Abidjan"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "JM",
    countryName: "Jamaica",
    timeZones: ["America/Jamaica"],
    UTCOffset: ["UTC -05:00"],
  },
  {
    countryCode: "JP",
    countryName: "Japan",
    timeZones: ["Asia/Tokyo"],
    UTCOffset: ["UTC +09:00"],
  },
  {
    countryCode: "JE",
    countryName: "Jersey",
    timeZones: ["Europe/Jersey"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "JO",
    countryName: "Jordan",
    timeZones: ["Asia/Amman"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    timeZones: [
      "Asia/Almaty",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Atyrau",
      "Asia/Oral",
      "Asia/Qyzylorda",
    ],
    UTCOffset: [
      "UTC +06:00",
      "UTC +05:00",
      "UTC +05:00",
      "UTC +05:00",
      "UTC +05:00",
      "UTC +06:00",
    ],
  },
  {
    countryCode: "KE",
    countryName: "Kenya",
    timeZones: ["Africa/Nairobi"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "KI",
    countryName: "Kiribati",
    timeZones: ["Pacific/Enderbury", "Pacific/Kiritimati", "Pacific/Tarawa"],
    UTCOffset: ["UTC +13:00", "UTC +14:00", "UTC +12:00"],
  },
  {
    countryCode: "KW",
    countryName: "Kuwait",
    timeZones: ["Asia/Kuwait"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    timeZones: ["Asia/Bishkek"],
    UTCOffset: ["UTC +06:00"],
  },
  {
    countryCode: "LA",
    countryName: "Laos",
    timeZones: ["Asia/Vientiane"],
    UTCOffset: ["UTC +07:00"],
  },
  {
    countryCode: "LV",
    countryName: "Latvia",
    timeZones: ["Europe/Riga"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "LB",
    countryName: "Lebanon",
    timeZones: ["Asia/Beirut"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "LS",
    countryName: "Lesotho",
    timeZones: ["Africa/Maseru"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "LR",
    countryName: "Liberia",
    timeZones: ["Africa/Monrovia"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "LY",
    countryName: "Libya",
    timeZones: ["Africa/Tripoli"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "LI",
    countryName: "Liechtenstein",
    timeZones: ["Europe/Vaduz"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "LT",
    countryName: "Lithuania",
    timeZones: ["Europe/Vilnius"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    timeZones: ["Europe/Luxembourg"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MO",
    countryName: "Macao",
    timeZones: ["Asia/Macau"],
    UTCOffset: ["UTC +08:00"],
  },
  {
    countryCode: "MK",
    countryName: "Macedonia",
    timeZones: ["Europe/Skopje"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MG",
    countryName: "Madagascar",
    timeZones: ["Indian/Antananarivo"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "MW",
    countryName: "Malawi",
    timeZones: ["Africa/Blantyre"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MY",
    countryName: "Malaysia",
    timeZones: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
    UTCOffset: ["UTC +08:00", "UTC +08:00"],
  },
  {
    countryCode: "MV",
    countryName: "Maldives",
    timeZones: ["Indian/Maldives"],
    UTCOffset: ["UTC +05:00"],
  },
  {
    countryCode: "ML",
    countryName: "Mali",
    timeZones: ["Africa/Bamako"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "MT",
    countryName: "Malta",
    timeZones: ["Europe/Malta"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MH",
    countryName: "Marshall Islands",
    timeZones: ["Pacific/Kwajalein", "Pacific/Majuro"],
    UTCOffset: ["UTC +12:00", "UTC +12:00"],
  },
  {
    countryCode: "MQ",
    countryName: "Martinique",
    timeZones: ["America/Martinique"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "MR",
    countryName: "Mauritania",
    timeZones: ["Africa/Nouakchott"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "MU",
    countryName: "Mauritius",
    timeZones: ["Indian/Mauritius"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "YT",
    countryName: "Mayotte",
    timeZones: ["Indian/Mayotte"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    timeZones: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Chihuahua",
      "America/Hermosillo",
      "America/Matamoros",
      "America/Mazatlan",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
      "America/Ojinaga",
      "America/Tijuana",
    ],
    UTCOffset: [
      "UTC -05:00",
      "UTC -05:00",
      "UTC -06:00",
      "UTC -07:00",
      "UTC -05:00",
      "UTC -06:00",
      "UTC -05:00",
      "UTC -05:00",
      "UTC -05:00",
      "UTC -06:00",
      "UTC -07:00",
    ],
  },
  {
    countryCode: "FM",
    countryName: "Micronesia",
    timeZones: ["Pacific/Chuuk", "Pacific/Kosrae", "Pacific/Pohnpei"],
    UTCOffset: ["UTC +10:00", "UTC +11:00", "UTC +11:00"],
  },
  {
    countryCode: "MD",
    countryName: "Moldova",
    timeZones: ["Europe/Chisinau"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "MC",
    countryName: "Monaco",
    timeZones: ["Europe/Monaco"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
    timeZones: ["Asia/Choibalsan", "Asia/Hovd", "Asia/Ulaanbaatar"],
    UTCOffset: ["UTC +08:00", "UTC +07:00", "UTC +08:00"],
  },
  {
    countryCode: "ME",
    countryName: "Montenegro",
    timeZones: ["Europe/Podgorica"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MS",
    countryName: "Montserrat",
    timeZones: ["America/Montserrat"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "MA",
    countryName: "Morocco",
    timeZones: ["Africa/Casablanca"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "MZ",
    countryName: "Mozambique",
    timeZones: ["Africa/Maputo"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "MM",
    countryName: "Myanmar",
    timeZones: ["Asia/Yangon"],
    UTCOffset: ["UTC +06:30"],
  },
  {
    countryCode: "NA",
    countryName: "Namibia",
    timeZones: ["Africa/Windhoek"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "NR",
    countryName: "Nauru",
    timeZones: ["Pacific/Nauru"],
    UTCOffset: ["UTC +12:00"],
  },
  {
    countryCode: "NP",
    countryName: "Nepal",
    timeZones: ["Asia/Kathmandu"],
    UTCOffset: ["UTC +05:45"],
  },
  {
    countryCode: "NL",
    countryName: "Netherlands",
    timeZones: ["Europe/Amsterdam"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "NC",
    countryName: "New Caledonia",
    timeZones: ["Pacific/Noumea"],
    UTCOffset: ["UTC +11:00"],
  },
  {
    countryCode: "NZ",
    countryName: "New Zealand",
    timeZones: ["Pacific/Auckland", "Pacific/Chatham"],
    UTCOffset: ["UTC +12:00", "UTC +12:45"],
  },
  {
    countryCode: "NI",
    countryName: "Nicaragua",
    timeZones: ["America/Managua"],
    UTCOffset: ["UTC -06:00"],
  },
  {
    countryCode: "NE",
    countryName: "Niger",
    timeZones: ["Africa/Niamey"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "NG",
    countryName: "Nigeria",
    timeZones: ["Africa/Lagos"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "NU",
    countryName: "Niue",
    timeZones: ["Pacific/Niue"],
    UTCOffset: ["UTC -11:00"],
  },
  {
    countryCode: "NF",
    countryName: "Norfolk Island",
    timeZones: ["Pacific/Norfolk"],
    UTCOffset: ["UTC +11:00"],
  },
  {
    countryCode: "KP",
    countryName: "North Korea",
    timeZones: ["Asia/Pyongyang"],
    UTCOffset: ["UTC +09:00"],
  },
  {
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
    timeZones: ["Pacific/Saipan"],
    UTCOffset: ["UTC +10:00"],
  },
  {
    countryCode: "NO",
    countryName: "Norway",
    timeZones: ["Europe/Oslo"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "OM",
    countryName: "Oman",
    timeZones: ["Asia/Muscat"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "PK",
    countryName: "Pakistan",
    timeZones: ["Asia/Karachi"],
    UTCOffset: ["UTC +05:00"],
  },
  {
    countryCode: "PW",
    countryName: "Palau",
    timeZones: ["Pacific/Palau"],
    UTCOffset: ["UTC +09:00"],
  },
  {
    countryCode: "PS",
    countryName: "Palestinian Territory",
    timeZones: ["Asia/Gaza", "Asia/Hebron"],
    UTCOffset: ["UTC +03:00", "UTC +03:00"],
  },
  {
    countryCode: "PA",
    countryName: "Panama",
    timeZones: ["America/Panama"],
    UTCOffset: ["UTC -05:00"],
  },
  {
    countryCode: "PG",
    countryName: "Papua New Guinea",
    timeZones: ["Pacific/Bougainville", "Pacific/Port_Moresby"],
    UTCOffset: ["UTC +11:00", "UTC +10:00"],
  },
  {
    countryCode: "PY",
    countryName: "Paraguay",
    timeZones: ["America/Asuncion"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "PE",
    countryName: "Peru",
    timeZones: ["America/Lima"],
    UTCOffset: ["UTC -05:00"],
  },
  {
    countryCode: "PH",
    countryName: "Philippines",
    timeZones: ["Asia/Manila"],
    UTCOffset: ["UTC +08:00"],
  },
  {
    countryCode: "PN",
    countryName: "Pitcairn",
    timeZones: ["Pacific/Pitcairn"],
    UTCOffset: ["UTC -08:00"],
  },
  {
    countryCode: "PL",
    countryName: "Poland",
    timeZones: ["Europe/Warsaw"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    timeZones: ["Atlantic/Azores", "Atlantic/Madeira", "Europe/Lisbon"],
    UTCOffset: ["UTC", "UTC +01:00", "UTC +01:00"],
  },
  {
    countryCode: "PR",
    countryName: "Puerto Rico",
    timeZones: ["America/Puerto_Rico"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "QA",
    countryName: "Qatar",
    timeZones: ["Asia/Qatar"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "CG",
    countryName: "Republic of the Congo",
    timeZones: ["Africa/Brazzaville"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "RE",
    countryName: "Reunion",
    timeZones: ["Indian/Reunion"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "RO",
    countryName: "Romania",
    timeZones: ["Europe/Bucharest"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    timeZones: [
      "Asia/Anadyr",
      "Asia/Barnaul",
      "Asia/Chita",
      "Asia/Irkutsk",
      "Asia/Kamchatka",
      "Asia/Khandyga",
      "Asia/Krasnoyarsk",
      "Asia/Magadan",
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk",
      "Asia/Sakhalin",
      "Asia/Srednekolymsk",
      "Asia/Tomsk",
      "Asia/Ust-Nera",
      "Asia/Vladivostok",
      "Asia/Yakutsk",
      "Asia/Yekaterinburg",
      "Europe/Astrakhan",
      "Europe/Kaliningrad",
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Samara",
      "Europe/Saratov",
      "Europe/Simferopol",
      "Europe/Ulyanovsk",
      "Europe/Volgograd",
    ],
    UTCOffset: [
      "UTC +12:00",
      "UTC +07:00",
      "UTC +09:00",
      "UTC +08:00",
      "UTC +12:00",
      "UTC +09:00",
      "UTC +07:00",
      "UTC +11:00",
      "UTC +07:00",
      "UTC +07:00",
      "UTC +06:00",
      "UTC +11:00",
      "UTC +11:00",
      "UTC +07:00",
      "UTC +10:00",
      "UTC +10:00",
      "UTC +09:00",
      "UTC +05:00",
      "UTC +04:00",
      "UTC +02:00",
      "UTC +03:00",
      "UTC +03:00",
      "UTC +04:00",
      "UTC +04:00",
      "UTC +03:00",
      "UTC +04:00",
      "UTC +03:00",
    ],
  },
  {
    countryCode: "RW",
    countryName: "Rwanda",
    timeZones: ["Africa/Kigali"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "BL",
    countryName: "Saint Barthélemy",
    timeZones: ["America/St_Barthelemy"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "SH",
    countryName: "Saint Helena",
    timeZones: ["Atlantic/St_Helena"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    timeZones: ["America/St_Kitts"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "LC",
    countryName: "Saint Lucia",
    timeZones: ["America/St_Lucia"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "MF",
    countryName: "Saint Martin",
    timeZones: ["America/Marigot"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "PM",
    countryName: "Saint Pierre and Miquelon",
    timeZones: ["America/Miquelon"],
    UTCOffset: ["UTC -02:00"],
  },
  {
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    timeZones: ["America/St_Vincent"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "WS",
    countryName: "Samoa",
    timeZones: ["Pacific/Apia"],
    UTCOffset: ["UTC +13:00"],
  },
  {
    countryCode: "SM",
    countryName: "San Marino",
    timeZones: ["Europe/San_Marino"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "ST",
    countryName: "Sao Tome and Principe",
    timeZones: ["Africa/Sao_Tome"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "SA",
    countryName: "Saudi Arabia",
    timeZones: ["Asia/Riyadh"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "SN",
    countryName: "Senegal",
    timeZones: ["Africa/Dakar"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "RS",
    countryName: "Serbia",
    timeZones: ["Europe/Belgrade"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SC",
    countryName: "Seychelles",
    timeZones: ["Indian/Mahe"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "SL",
    countryName: "Sierra Leone",
    timeZones: ["Africa/Freetown"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "SG",
    countryName: "Singapore",
    timeZones: ["Asia/Singapore"],
    UTCOffset: ["UTC +08:00"],
  },
  {
    countryCode: "SX",
    countryName: "Sint Maarten",
    timeZones: ["America/Lower_Princes"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
    timeZones: ["Europe/Bratislava"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SI",
    countryName: "Slovenia",
    timeZones: ["Europe/Ljubljana"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SB",
    countryName: "Solomon Islands",
    timeZones: ["Pacific/Guadalcanal"],
    UTCOffset: ["UTC +11:00"],
  },
  {
    countryCode: "SO",
    countryName: "Somalia",
    timeZones: ["Africa/Mogadishu"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "ZA",
    countryName: "South Africa",
    timeZones: ["Africa/Johannesburg"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "GS",
    countryName: "South Georgia and the South Sandwich Islands",
    timeZones: ["Atlantic/South_Georgia"],
    UTCOffset: ["UTC -02:00"],
  },
  {
    countryCode: "KR",
    countryName: "South Korea",
    timeZones: ["Asia/Seoul"],
    UTCOffset: ["UTC +09:00"],
  },
  {
    countryCode: "SS",
    countryName: "South Sudan",
    timeZones: ["Africa/Juba"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    timeZones: ["Africa/Ceuta", "Atlantic/Canary", "Europe/Madrid"],
    UTCOffset: ["UTC +02:00", "UTC +01:00", "UTC +02:00"],
  },
  {
    countryCode: "LK",
    countryName: "Sri Lanka",
    timeZones: ["Asia/Colombo"],
    UTCOffset: ["UTC +05:30"],
  },
  {
    countryCode: "SD",
    countryName: "Sudan",
    timeZones: ["Africa/Khartoum"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SR",
    countryName: "Suriname",
    timeZones: ["America/Paramaribo"],
    UTCOffset: ["UTC -03:00"],
  },
  {
    countryCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
    timeZones: ["Arctic/Longyearbyen"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SZ",
    countryName: "Swaziland",
    timeZones: ["Africa/Mbabane"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
    timeZones: ["Europe/Stockholm"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    timeZones: ["Europe/Zurich"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "SY",
    countryName: "Syria",
    timeZones: ["Asia/Damascus"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "TW",
    countryName: "Taiwan",
    timeZones: ["Asia/Taipei"],
    UTCOffset: ["UTC +08:00"],
  },
  {
    countryCode: "TJ",
    countryName: "Tajikistan",
    timeZones: ["Asia/Dushanbe"],
    UTCOffset: ["UTC +05:00"],
  },
  {
    countryCode: "TZ",
    countryName: "Tanzania",
    timeZones: ["Africa/Dar_es_Salaam"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "TH",
    countryName: "Thailand",
    timeZones: ["Asia/Bangkok"],
    UTCOffset: ["UTC +07:00"],
  },
  {
    countryCode: "TG",
    countryName: "Togo",
    timeZones: ["Africa/Lome"],
    UTCOffset: ["UTC"],
  },
  {
    countryCode: "TK",
    countryName: "Tokelau",
    timeZones: ["Pacific/Fakaofo"],
    UTCOffset: ["UTC +13:00"],
  },
  {
    countryCode: "TO",
    countryName: "Tonga",
    timeZones: ["Pacific/Tongatapu"],
    UTCOffset: ["UTC +13:00"],
  },
  {
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    timeZones: ["America/Port_of_Spain"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "TN",
    countryName: "Tunisia",
    timeZones: ["Africa/Tunis"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "TR",
    countryName: "Turkey",
    timeZones: ["Europe/Istanbul"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "TM",
    countryName: "Turkmenistan",
    timeZones: ["Asia/Ashgabat"],
    UTCOffset: ["UTC +05:00"],
  },
  {
    countryCode: "TC",
    countryName: "Turks and Caicos Islands",
    timeZones: ["America/Grand_Turk"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "TV",
    countryName: "Tuvalu",
    timeZones: ["Pacific/Funafuti"],
    UTCOffset: ["UTC +12:00"],
  },
  {
    countryCode: "VI",
    countryName: "U.S. Virgin Islands",
    timeZones: ["America/St_Thomas"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "UG",
    countryName: "Uganda",
    timeZones: ["Africa/Kampala"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "UA",
    countryName: "Ukraine",
    timeZones: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
    UTCOffset: ["UTC +03:00", "UTC +03:00", "UTC +03:00"],
  },
  {
    countryCode: "AE",
    countryName: "United Arab Emirates",
    timeZones: ["Asia/Dubai"],
    UTCOffset: ["UTC +04:00"],
  },
  {
    countryCode: "GB",
    countryName: "United Kingdom",
    timeZones: ["Europe/London"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "US",
    countryName: "United States",
    timeZones: [
      "America/Adak",
      "America/Anchorage",
      "America/Boise",
      "America/Chicago",
      "America/Denver",
      "America/Detroit",
      "America/Indiana/Indianapolis",
      "America/Indiana/Knox",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Tell_City",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Juneau",
      "America/Kentucky/Louisville",
      "America/Kentucky/Monticello",
      "America/Los_Angeles",
      "America/Menominee",
      "America/Metlakatla",
      "America/New_York",
      "America/Nome",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Phoenix",
      "America/Sitka",
      "America/Yakutat",
      "Pacific/Honolulu",
    ],
    UTCOffset: [
      "UTC -09:00",
      "UTC -08:00",
      "UTC -06:00",
      "UTC -05:00",
      "UTC -06:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -05:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -05:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -08:00",
      "UTC -04:00",
      "UTC -04:00",
      "UTC -07:00",
      "UTC -05:00",
      "UTC -08:00",
      "UTC -04:00",
      "UTC -08:00",
      "UTC -05:00",
      "UTC -05:00",
      "UTC -05:00",
      "UTC -07:00",
      "UTC -08:00",
      "UTC -08:00",
      "UTC -10:00",
    ],
  },
  {
    countryCode: "UM",
    countryName: "United States Minor Outlying Islands",
    timeZones: ["Pacific/Midway", "Pacific/Wake"],
    UTCOffset: ["UTC -11:00", "UTC +12:00"],
  },
  {
    countryCode: "UY",
    countryName: "Uruguay",
    timeZones: ["America/Montevideo"],
    UTCOffset: ["UTC -03:00"],
  },
  {
    countryCode: "UZ",
    countryName: "Uzbekistan",
    timeZones: ["Asia/Samarkand", "Asia/Tashkent"],
    UTCOffset: ["UTC +05:00", "UTC +05:00"],
  },
  {
    countryCode: "VU",
    countryName: "Vanuatu",
    timeZones: ["Pacific/Efate"],
    UTCOffset: ["UTC +11:00"],
  },
  {
    countryCode: "VA",
    countryName: "Vatican",
    timeZones: ["Europe/Vatican"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "VE",
    countryName: "Venezuela",
    timeZones: ["America/Caracas"],
    UTCOffset: ["UTC -04:00"],
  },
  {
    countryCode: "VN",
    countryName: "Vietnam",
    timeZones: ["Asia/Ho_Chi_Minh"],
    UTCOffset: ["UTC +07:00"],
  },
  {
    countryCode: "WF",
    countryName: "Wallis and Futuna",
    timeZones: ["Pacific/Wallis"],
    UTCOffset: ["UTC +12:00"],
  },
  {
    countryCode: "EH",
    countryName: "Western Sahara",
    timeZones: ["Africa/El_Aaiun"],
    UTCOffset: ["UTC +01:00"],
  },
  {
    countryCode: "YE",
    countryName: "Yemen",
    timeZones: ["Asia/Aden"],
    UTCOffset: ["UTC +03:00"],
  },
  {
    countryCode: "ZM",
    countryName: "Zambia",
    timeZones: ["Africa/Lusaka"],
    UTCOffset: ["UTC +02:00"],
  },
  {
    countryCode: "ZW",
    countryName: "Zimbabwe",
    timeZones: ["Africa/Harare"],
    UTCOffset: ["UTC +02:00"],
  },
];
