// External imports
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; //router
import PasswordChecklist from "react-password-checklist"; //password strength checklist package
import { Button, Form, Input, Row, Col, Card, Image, Spin, Modal } from "antd"; //antd components
import { useRecoilState } from "recoil";

import "../assets/style/auth.css";
import encrypted from "../assets/images/icons/encrypted.svg";
import check_circle from "../assets/images/icons/check_circle.svg";
import verified from "../assets/images/icons/verified.svg";
import {
  setPasswordApi,
  verifyPasswordApi,
  RegeneratePasswordLink,
  resetPasswordApi,
} from "../api/usersApi";

import link_off from "../assets/images/icons/link_off.svg";
import { userDataAtom } from "../store/store";
import CustomLoader from "../components/CustomLoader";

// functional component for dashboard page
function ResetPassword() {
  //global varaiable hold the getting user id from url param
  const { id, expiry_time, type } = useParams();
  // // state variable hold the loading icon while fetch the response from API
  const [tableLoader, setTableLoader] = useState(false);
  // state varaiable hold the msd once message send successfully
  const [doneMsg, setDoneMsg] = useState(false);
  // state varaiable hold the authorized purpose
  const [authorized, setAuthorized] = useState(false);
  //stae varaiable hold the password purpose
  const [password, setPassword] = useState("");
  //state varaiable hold the for confirm-password
  const [cPassword, setCPassword] = useState("");
  const [oldPassword, setOldPassword] = useState(""); // to store old password value
  //state varaiable hold the for password checklist display
  const [display, setDisplay] = useState("none");
  //state varaiable hold the for confirm-password checklist display
  const [displayAgain, setDisplayAgain] = useState("none");
  //state varaiable hold the for confirm-password checklist display
  const [validP, setValidP] = useState(false);
  //state varaiable hold the for verify password valid or not//
  const [validCp, setValidCp] = useState(false);
  //state varaiable hold the for verify confirm-password valid or not
  let history = useNavigate(); //router
  //global state variable hold the navigating different routes
  let myDate = new Date();
  // global varaiable hold the get data and cureent time
  const unixTimestamp = Math.floor(myDate.getTime() / 1000);
  // state  varaiable hold the expired msg purpose
  const [expiredMsg, setExpiredMsg] = useState(false);
  // state  varaiable hold the sent link purpose
  const [sentLink, setSentLink] = useState(false);
  // state varaible hold the save loader fectch data from api
  const [loader, setLoader] = useState(true);
  const userData = useRecoilState(userDataAtom);

  //use effecct the api integration to verify user email
  useEffect(() => {
    document.title = "Change Password | Judo";
    if (type === "set") {
      verifyPasswordApi(id).then((res) => {
        if (res.status === true) {
          setAuthorized(false);
          setLoader(false);
        } else {
          setAuthorized(true);
          setLoader(false);
        }
      });
    }
    if (expiry_time != null && unixTimestamp > expiry_time) {
      setExpiredMsg(true);
    } else {
      setLoader(false);
    }
  }, [id]);

  //function handle on password field change
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setDisplay("block");
    } else {
      setDisplay("none");
    }
  };

  //function handle on confirm-password field change
  const onCPasswordChange = (e) => {
    setCPassword(e.target.value);
    if (e.target.value) {
      setDisplayAgain("block");
    } else {
      setDisplayAgain("none");
    }
  };

  /* Function to handle submit form
   * @param value - collection of component data in value
   */
  const onFinish = async () => {
    const userId =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id;

    if (password === oldPassword) {
      Modal.error({
        title: "Error",
        className: "popup-model-success",
        centered: true,
        icon: true,
        content: (
          <>
            <div>New password cannot be the same as the Current password</div>
          </>
        ),
      });
      return;
    }

    if (validP && validCp) {
      let data = {
        oldPassword: oldPassword,
        password: password,
      };

      await resetPasswordApi(userId, data)
        .then((res) => {
          // console.log("res", res);
          if (res.status === true) {
            setTableLoader(false);
            setDoneMsg(true);
          } else {
            Modal.error({
              title: "Error",
              className: "popup-model-success",
              centered: true,
              icon: true,
              content: (
                <>
                  <div>{res.message}</div>
                </>
              ),
            });
            setTableLoader(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  /* Function to handle submit form failed
  @ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // function handle the regenerating link
  // const regenerateLink = () => {
  //   RegeneratePasswordLink(id, "set").then((res) => {
  //     if (res.status) {
  //       setLoader(false);
  //       setSentLink(true);
  //       setAuthorized(false);
  //       setExpiredMsg(false);
  //     }
  //   });
  // };

  return (
    <div>
      <div className="auth-bg auth-page-bg">
        {!doneMsg && !authorized && !expiredMsg && !sentLink && !loader && (
          <Card className="auth-card p-30">
            <div>
              <Image src={encrypted} preview={false} />
            </div>
            <div className="auth-head-msg mt-10">New Credentials</div>
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="mt-15"
            >
              <Row align="middle" type="flex" gutter={[8, 16]}>
                <Col span={22} offset={1} className="vertical-align">
                  <Form.Item
                    className="set-password-input"
                    name="oldpassword"
                    label="Current password"
                    labelCol={{ span: 24 }}
                  >
                    <Input.Password
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder="Current Password"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" type="flex" gutter={[8, 16]}>
                <Col span={22} offset={1} className="vertical-align">
                  <Form.Item
                    className="set-password-input"
                    name="password"
                    label="New password"
                    labelCol={{ span: 24 }}
                  >
                    <Input.Password
                      onChange={onPasswordChange}
                      placeholder="New Password"
                    />

                    {/* password strength checklist */}
                    <PasswordChecklist
                      className="pass-checklist"
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "lowercase",
                      ]}
                      minLength={8}
                      value={password}
                      valueAgain={cPassword}
                      style={{ display: display }}
                      messages={{
                        lowercase: "Password must contain a lowercase letter",
                        capital: "Password must contain a capital letter",
                        number: "Password must contain a number",
                        specialChar:
                          "Password must contain a special character",
                        minLength: "Password must contain atleast 8 characters",
                      }}
                      onChange={(isValid) => {
                        setValidP(isValid);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" type="flex" gutter={[8, 16]}>
                <Col span={22} offset={1} className="vertical-align">
                  <Form.Item
                    className="set-password-input"
                    name="cpassword"
                    label="Confirm New Password"
                    labelCol={{ span: 24 }}
                  >
                    <Input.Password
                      onChange={onCPasswordChange}
                      placeholder="Confirm New Password"
                    />

                    {/* confirm-password strength checklist */}
                    <PasswordChecklist
                      className="pass-checklist"
                      rules={["match"]}
                      value={password}
                      valueAgain={cPassword}
                      style={{ display: displayAgain }}
                      messages={{
                        match: "Password must match",
                      }}
                      onChange={(isValid) => {
                        setValidCp(isValid);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                align="middle"
                type="flex"
                className="mt-15"
                gutter={[8, 16]}
              >
                <Col
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 18, offset: 3 }}
                  md={{ span: 16, offset: 4 }}
                  className="vertical-align"
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={tableLoader}
                      className="signin-btn"
                      style={{ width: "80%" }}
                      disabled={
                        !password || !cPassword || !oldPassword || !validCp
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
        {doneMsg && !authorized && !expiredMsg && !sentLink && !loader && (
          <Card className="auth-card p-30">
            <div>
              <Image src={verified} preview={false} />
            </div>
            <div className="auth-head-msg mt-10">Saved Your Password</div>
            <div className="mt-20">
              Password for your account has been reset successfully. Now you can
              login to Judo software using your new credentials.
            </div>
            <div className="mt-20">
              <Button
                type="primary"
                onClick={() => history(`/login`)}
                className="signin-btn"
                shape="round"
                style={{ width: "25%" }}
              >
                Login
              </Button>
            </div>
          </Card>
        )}
        {authorized && !expiredMsg && !sentLink && !doneMsg && !loader && (
          <Card className="auth-card p-30">
            <div>
              <Image src={verified} preview={false} />
            </div>
            <div className="auth-head-msg mt-10">
              You have already set your password
            </div>
            <div className="mt-20">
              Please try to login or <a href="/">click here</a> to reset your
              password
            </div>
            <div className="mt-20">
              <Button
                type="primary"
                onClick={() => history(`/login`)}
                className="signin-btn"
                shape="round"
              >
                Go to Login
              </Button>
            </div>
          </Card>
        )}
        {/* {expiredMsg && !authorized && !doneMsg && !sentLink && !loader && (
          <div className="auth-bg auth-page-bg">
            <Card className="auth-card p-30">
              <div>
                <Image src={link_off} preview={false} />
              </div>
              <div className="regenerate-msg mt-10">Link Expired</div>
              <div className="mt-20">
                The link is valid for only 24 hours. Please regenerate the link.
              </div>
              <div className="mt-20">
                <Button
                  type="primary"
                  onClick={regenerateLink}
                  className="regenerate-btn"
                  shape="round"
                >
                  Regenerate Link
                </Button>
              </div>
            </Card>
          </div>
        )}
        {sentLink && !authorized && !expiredMsg && !doneMsg && !loader && (
          <div className="auth-bg auth-page-bg">
            <Card className="auth-card p-30">
              <div>
                <Image src={check_circle} preview={false} />
              </div>
              <div className="verification-msg mt-10">Link has been sent</div>
              <div className="mt-20">
                A verification link has been sent to your email account. Kindly
                set your password before attempting to log in again.
              </div>
              <div className="mt-20">
                <Button
                  type="primary"
                  onClick={() => history(`/login`)}
                  className="set-password-btn"
                  shape="round"
                >
                  Login
                </Button>
              </div>
            </Card>
          </div>
        )} */}
        {loader && <CustomLoader />}
      </div>
    </div>
  );
}

export default ResetPassword;
