import React, { useEffect, useState } from "react";
import {
  Image,
  Card,
  Row,
  Col,
  Button,
  Form,
  Upload,
  Input,
  Layout,
  Select,
  Modal,
  message,
} from "antd"; //antd components
import arrow_back from "../assets/images/icons/arrow_back_ios.svg";

function WorkflowManagement() {
  //state varaiable hold the state value
  const [searchVal, setSearchVal] = useState("null");

  return (
    <div>
      {" "}
      <Row align="middle" type="flex" gutter={[24, 0]}>
        <Col
          className="p-1 vertical-align"
          xs={24}
          sm={24}
          md={17}
          lg={17}
          xl={22}
        >
          <Card className="settings-header-card">
            <Row align="middle" type="flex" gutter={[8, 16]}>
              <Col className="vertical-align" span={24}>
                <span>Settings</span> &nbsp; &nbsp;
                <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                <span>Workflow</span>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col className="vertical-align" xs={24} sm={24} md={2} lg={2} xl={2}>
          <Button type="primary" style={{ borderRadius: "15px" }}>
            Create Flow
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default WorkflowManagement;
