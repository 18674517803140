import React from "react";

const ViewScheduleReport = () => {
  return (
    <div>
      <h1>Welcome</h1>
    </div>
  );
};

export default ViewScheduleReport;
