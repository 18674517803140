import React, { useState, useEffect } from "react";
import { Image, Row, Col, Layout, Tabs, Space } from "antd"; //antd components
import { useSearchParams, useNavigate } from "react-router-dom";
import TopHeader from "../components/Header";
import "../assets/style/commercial.css";
import sort_drop from "../assets/images/icons/arrow_drop_down.svg";
import expand_content from "../assets/images/icons/expand_content.svg";
import SpendOverView from "../components/Commercial/SpendOverview";
import ContractSetup from "../components/Commercial/ContractSetup";
import InvoicingView from "../components/Commercial/InvoicingView";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../store/store";

function Commercial() {
  // Variable to hold manu key
  const menuKey = "3";
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Function to set the headerchnager state variable
  useEffect(
    () =>
      setHeaderChanger({
        header: "commercial",
        headerItem: "1",
        headerItemName: "commercial",
      }),
    []
  );
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to hold header item value
  const tabkey = headerChanger.headerItem;
  // State variable to hold toggle visibility status
  const [toggleChange, setToggleChange] = useState(false);
  // Variable to hold all tap screen names
  const items = [
    {
      key: "1",
      label: `Spend Overview`,
    },
    {
      key: "2",
      label: `Contract Setup`,
    },
    {
      key: "3",
      label: `Invoicing View`,
    },
  ];

  return (
    <div className="gradient-bg commercial">
      <Layout className="header-layout">
        <>
          <div className="sticky">
            <TopHeader
              headerChange={header}
              feedRow={false}
              searchRow={false}
              menuKey={menuKey}
              sticky=""
            />
            <div style={{ padding: "0 47px" }}>
              <Row>
                <Col span={12}>
                  <Space>
                    <span className="switch-text">CTD</span>
                    <div
                      className="switch-track"
                      onClick={() => setToggleChange(!toggleChange)}
                    >
                      {toggleChange ? (
                        <span className="switch ctd-switch"></span>
                      ) : (
                        <span className="switch ytd-switch float-right"></span>
                      )}
                    </div>
                    <span className="switch-text">YTD</span>
                  </Space>
                </Col>
                <Col span={12} className="text-end">
                  <Space>
                    <div className="sorting cursor-pointer">
                      <Space size="middle">
                        <span>Sort by</span>
                        <Image src={sort_drop} preview={false} />
                      </Space>
                    </div>
                    <div className="expand cursor-pointer">
                      <Image src={expand_content} preview={false} />
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
          </div>

          <div className="commercial-tab-content" style={{ padding: "0 47px" }}>
            {tabkey === "1" && (
              <div>
                <SpendOverView />
              </div>
            )}
            {tabkey === "2" && (
              <div>
                <ContractSetup />
              </div>
            )}
            {tabkey === "3" && (
              <div>
                <InvoicingView />
              </div>
            )}
          </div>
        </>
      </Layout>
    </div>
  );
}

export default Commercial;
