import React from "react";
import { Spin } from "antd";

const ReportLoader = () => (
  <Spin id="report-spin" tip="Preparing Report" size="large" className="mt-30">
    <div />
  </Spin>
);

export default ReportLoader;
