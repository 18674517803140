import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Image,
  Card,
  Form,
  Input,
  Divider,
  Tabs,
} from "antd"; //antd components
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import TopHeader from "../../Header";
import { HeaderChangerAtom } from "../../../store/store";
import "../../../assets/style/template.css";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import download from "../../../assets/images/icons/download.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import DataTable from "../../Tables/DataTable";

const ViewTemplate = () => {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const menuKey = "5";
  const navigate = useNavigate();
  let header = headerChanger.header;
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const col = [
    {
      title: "Document Version",
      dataIndex: "document_version",
      key: "document_version",
    },
    {
      title: "Updated On",
      dataIndex: "updated_on",
      key: "updated_on",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const createPDF = async (content) => {
    // Embed the Times Roman font

    const pdfDoc = await PDFDocument.create();
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const margin = 40; // Adjust margin as needed
    const fontSize = 12; // Adjust font size as needed

    // Calculate number of pages needed
    // const lines = splitTextIntoLines(content, width - 2 * margin, fontSize);
    const lines = splitTextIntoLines(content, width - 2 * margin, fontSize, 14);
    const totalPages = calculateTotalPages(lines, height, margin, fontSize);

    // Iterate through pages and add text
    let currentPageIndex = 0;
    let y = height - margin; // Start y-coordinate at the top of the page

    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      if (pageIndex !== 0) {
        page = pdfDoc.addPage();
        y = height - margin; // Reset y-coordinate for new page
      }

      while (currentPageIndex < lines.length && y > margin) {
        const line = lines[currentPageIndex];
        if (y - line.height < margin) {
          // If current line exceeds page height, break loop and move to next page
          break;
        }

        // Draw text on the page
        page.drawText(line.text, {
          x: margin,
          y,
          size: line.size,
          fontWeight: line.fontWeight,
          font: timesRomanFont,
          color: line.color,
          textAlign: line.align ? line.align : "",
        });
        // Update y-coordinate for the next line
        y -= line.height + 3; // Adjust line spacing as needed
        currentPageIndex++;
      }
    }

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };
  const pageWidth = 612; // Assuming page width is 612 units
  const marginX = 100; // Example margin X value
  const marginY = 200; // Example margin Y value

  const splitTextIntoLines = (
    content,
    maxWidth,
    fontSize,
    paragraphSpacing
  ) => {
    const lines = [
      {
        text: `${state.contract_template_name} Template`,

        width:
          `${state.contract_template_name} Template`.length * (fontSize * 0.5),
        height: 30,
        fontWeight: 600, // Add font weight attribute
        size: 30,
        color: rgb(0, 0, 0),
      },
    ];
    // text.setAlignment(TextAlignment.Center);

    for (const item of content) {
      // Add heading
      lines.push({
        text: item.h,
        width: item.h.length * (fontSize * 0.5),
        height: 15,
        fontWeight: 600, // Add font weight attribute
        size: 14,
        color: rgb(0, 0, 0),
      });

      // Add content (if it exists)
      if (item.p) {
        const words = item.p.split(/\s+/);
        let currentLine = {
          text: "",
          width: 0,
          height: fontSize,
          fontWeight: 100,
          size: 12,
          color: rgb(0.2, 0.2, 0.2),
        };

        for (const word of words) {
          const wordWidth = word.length * (fontSize * 0.5); // Approximate word width
          if (currentLine.width + wordWidth < maxWidth) {
            currentLine.text += (currentLine.text ? " " : "") + word;
            currentLine.width += wordWidth;
          } else {
            lines.push(currentLine);
            currentLine = {
              text: word,
              width: wordWidth,
              height: fontSize,
              fontWeight: 100,
              size: 12,
              color: rgb(0.2, 0.2, 0.2),
            };
          }
        }
        if (currentLine.text) lines.push(currentLine);
      }

      // Add paragraph spacing
      lines.push({ text: "", width: 0, height: paragraphSpacing });
    }

    return lines;
  };

  const calculateTotalPages = (lines, pageHeight, margin, fontSize) => {
    let totalPages = 1;
    let remainingLines = lines.length;
    let currentY = pageHeight - margin;

    for (const line of lines) {
      if (currentY - line.height < margin) {
        totalPages++;
        currentY = pageHeight - margin; // Reset y-coordinate for new page
      }
      currentY -= line.height + 3; // Adjust line spacing as needed
      remainingLines--;
      if (remainingLines === 0) break; // All lines processed
    }

    return totalPages;
  };

  const viewPDF = () => {
    navigate(`/contract_authority/template/upload-preview`, {
      state: {
        ...state,
        viewTemp: true,
      },
    });
  };
  const downloadPDF = async () => {
    const content = state.clause_content;
    if (content && content.length > 0) {
      const pdfBytes = await createPDF(content);
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = `${state.contract_template_name} - Original File`;
      link.click();
    } else {
      setDownloadButtonDisabled(true);
    }
  };

  const dataSource = [
    {
      key: "1",
      document_version: "Original File",
      updated_on: moment(state.updatedAt).format("DD-MMM-YYYY"),
      actions: (
        <>
          <div
            style={{
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            <EyeOutlined
              title="view"
              style={{
                fontSize: "20px",
                color: "var(--color-solid-darkgrey)",
              }}
              className="cursor-pointer"
              onClick={viewPDF}
            />
            <Image
              title="Download"
              src={download}
              preview={false}
              style={{
                width: "16px",
                display: "flex",
                marginTop: "2px",
                cursor: downloadButtonDisabled ? "not-allowed" : "pointer",
              }}
              //  className={downloadButtonDisabled ? "" : "cursor-pointer"}
              onClick={!downloadButtonDisabled ? downloadPDF : undefined}
            />
          </div>
        </>
      ),
    },
  ];

  const TemplateDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="org-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="contract_template_name"
                        label="TEMPLATE NAME"
                        colon={false}
                      >
                        <Input disabled className="templateview-input" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="template_description"
                        label="TEMPLATE DESCRIPTION"
                        colon={false}
                      >
                        <Input disabled className="templateview-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="supplier_name"
                    label="SUPPLIER NAME"
                    colon={false}
                  >
                    <Input disabled className="templateview-input" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="msa_name"
                    label="FRAMEWORK AGREEMENT / MSA"
                    colon={false}
                  >
                    <Input disabled className="templateview-input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                align="middle"
                type="flex"
                style={{ padding: "10px 30px 0px" }}
              ></Row>
              <Row>
                <DataTable
                  type="template"
                  dataTableData={dataSource}
                  colData={col}
                  showSorterTooltip={false}
                />
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state} />;
  };

  // Variable to hold the component returned by the function.
  const items = [
    {
      key: "1",
      label: `Template Details`,
      children: TemplateDetails(),
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "2",
      label: `Entity Log`,
      children: EntityLog(),
    },
  ];

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <div className="system-setup1">
        <Form
          name="view-contract-Templates"
          initialValues={{
            ["contract_template_name"]: state.contract_template_name || null,
            ["template_description"]:
              state.template_description === "null"
                ? ""
                : state.template_description || null,
            ["supplier_name"]: state.supplier_name || null,
            ["msa_name"]: state.msa_name || null,
          }}
        >
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={24}
            >
              <Card className="setup-header-card">
                <Row type="flex" gutter={[8, 16]}>
                  <Col
                    className="vertical-align"
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={2}
                  >
                    <Row type="flex" gutter={[8, 16]}>
                      <Col span={12}>
                        <Image
                          src={arrow_left}
                          style={{ width: 25 }}
                          preview={false}
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`/contract_authority/template`)
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Image
                          src={arrow_right_disable}
                          style={{ width: 20 }}
                          preview={false}
                          className="cursor-not-allowed"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={17}
                    sm={17}
                    md={19}
                    lg={19}
                    xl={22}
                  >
                    <span>Contract Authority</span> &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate(`/contract_authority/template`)}
                    >
                      Template
                    </span>
                    &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span>
                      {state.contract_template_name
                        ? state.contract_template_name
                        : "View Template"}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div className="price-profile-content m-10">
            <Row type="flex" gutter={[14, 0]} className="m-0">
              <Col span={24}>
                <Tabs items={items} />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Layout>
  );
};

export default ViewTemplate;
