// External Imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Layout,
  DatePicker,
  Modal,
  InputNumber,
  Dropdown,
  Space,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";
import { CaretDownOutlined } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Internal Imports
import {
  HeaderChangerAtom,
  userDataAtom,
  RoleAccessAtom,
} from "../../../store/store";
import TopHeader from "../../Header";
import "../../../assets/style/actionmanagement.css";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/checkselect.css";
import account_tree from "../../../assets/images/icons/account_tree.svg";
import { getUsersApi } from "../../../api/usersApi";
import repeat from "../../../assets/images/icons/repeat.svg";
import repeat_small from "../../../assets/images/icons/repeat_small.svg";
import { editRootActionAPi } from "../../../api/actionManagementApi";
import { createActivityLogApi } from "../../../api/logApi";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { CancelButton, OkButton, SaveButton } from "../../GlobalButton";
import { getAllGroupApi } from "../../../api/groupsApi";
import NotAuthorized from "../../NotAuthorized";

// Functional Component for Edit Root Action
const EditRootAction = () => {
  const history = useNavigate(); //react-router-dom navigate method
  const [form] = Form.useForm(); //custom hook for managing form
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  const popupModalInstance = PopupModal(); //global popup modal
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom); //Global state for header
  let { state } = useLocation(); //state data which is sent from parent component
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  // useState hooks used in this component
  const [createModel, setCreateModel] = useState(false); //useState hook for open custom trigger create modal
  const [users, setUsers] = useState([]); //state for set all users in application
  const [groups, setGroups] = useState([]); //Stateto set all groups in application
  const [timeCount, setTimeCount] = useState(
    state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.time_count
      ? state.actionData.custom_repeat.time_count
      : 1
  ); // state for set custom trigger time-count
  const [unitofTime, setUnitofTime] = useState(
    state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.unit_of_time
      ? state.actionData.custom_repeat.unit_of_time
      : "Week"
  ); // state for set custom trigger unit-of-time
  const [selectedDays, setSelectedDays] = useState(
    state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.days &&
      state.actionData.custom_repeat.days > 0
      ? state.actionData.custom_repeat.days
      : ["Monday"]
  ); // state for set custom trigger selected days
  const [selectedDaysIndex, setSelectedDaysIndex] = useState([0]); // state for set custom trigger selected days corresponding index for css use
  const [customDates, setCustomDates] = useState({
    startDate:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.start_date &&
      state.actionData.custom_repeat.start_date,
    endDate:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.end_date &&
      state.actionData.custom_repeat.end_date,
  }); // state for set custom trigger start date and end date
  const [actionRepeat, setActionRepeat] = useState(
    state &&
      state.actionData &&
      state.actionData.action_repeat &&
      state.actionData.action_repeat
  ); // state for set action trigger type
  const [loader, setLoader] = useState(false); //submit loader on api integration
  const [saveTableLoader, setSaveTableLoader] = useState(false); //save loader on api integration
  const [stateButton, setStateButton] = useState(1); //state for define save and submit button loader
  const [customRepeat, setCustomRepeat] = useState({
    time_count:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.time_count
        ? state.actionData.custom_repeat.time_count
        : 1,
    unit_of_time:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.unit_of_time
        ? state.actionData.custom_repeat.unit_of_time
        : "Week",
    days:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.days &&
      state.actionData.custom_repeat.days.length > 0
        ? state.actionData.custom_repeat.days
        : ["Monday"],
  }); // state for store all data regarding custom trigger
  const [actionDates, setActionDates] = useState({
    startDate:
      state &&
      state.actionData &&
      state.actionData.start_date &&
      state.actionData.start_date,
    endDate:
      state &&
      state.actionData &&
      state.actionData.end_date &&
      state.actionData.end_date,
    triggerDate:
      state &&
      state.actionData &&
      state.actionData.trigger_date &&
      state.actionData.trigger_date,
    dueDate:
      state &&
      state.actionData &&
      state.actionData.due_date &&
      dayjs.utc(state.actionData.due_date).format("DD MMM YYYY"),
  }); // state for store startdate, enddate, triggerdate and duedate of action
  const [dueIn, setDueIn] = useState(
    state &&
      state.actionData &&
      state.actionData.due_in &&
      state.actionData.due_in
  ); // state to hold value of trigger input
  const [errorMessage, setErrorMessage] = useState(""); // state to handle trigger input error message
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  let header = headerChanger.header; // assign header key to a variable
  const menuKey = "6"; //menu key of current page
  // form layout label and formitem alignment prop
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const actionType = ["Obligation", "Performance", "Actions", "Issues"]; //action type dropdown options
  const actionCategory = ["Contractual", "Commercial", "Operational"]; //action category dropdown options
  const actionPriority = ["High", "Medium", "Low"]; //action priority dropdown options
  const days = ["M", "T", "W", "T", "F", "S", "S"]; //Days shortcut array for button in custom trigger
  const unitTime = [
    {
      label: "Week",
      key: "Week",
    },
    {
      label: "Month",
      key: "Month",
    },
    {
      label: "Year",
      key: "Year",
    },
  ]; //options for unit of time in custom trigger
  const items = [
    {
      key: "Dont Repeat",
      label: "Dont Repeat",
    },
    {
      key: "Daily",
      label: "Daily",
    },
    {
      key: "Weekly",
      label: "Weekly",
    },
    {
      key: "Monthly",
      label: "Monthly",
    },
    {
      key: "Yearly",
      label: "Yearly",
    },
    {
      key: "Custom",
      label: "Custom",
      onClick: () => {
        setCreateModel(true);
      },
    },
  ]; //menu items for action repeat dropdown

  useEffect(() => {
    //   api integration for get all users in app
    getUsersApi().then((res) => {
      setUsers(res); // set response from api to 'users' state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllGroupApi().then((res) => {
      console.log("group-res", res);
      setGroups(res); //ser response from getgroupsapi to 'groups' state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setting initial value for the state 'selectedDaysIndex' according to data from parent
    if (
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.days &&
      state.actionData.custom_repeat.days
    ) {
      // Define a mapping object
      const dayNumberMapping = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6,
      };
      // Create a new array by mapping the days to their corresponding numbers
      const resultDayIndex =
        state &&
        state.actionData &&
        state.actionData.custom_repeat &&
        state.actionData.custom_repeat.days &&
        state.actionData.custom_repeat.days.length > 0 &&
        state.actionData.custom_repeat.days.map((day) => dayNumberMapping[day]);
      setSelectedDaysIndex(resultDayIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // function for handle time count change on custom trigger
  const onChangeCount = (item) => {
    setTimeCount(item); //set changed value in timeCount state
  };

  // function for handle unit of time change on custom trigger
  const onChangeUnitOfTime = (item) => {
    setUnitofTime(item); //set changed value in unitOfTime state
  };

  // function for handle selected days on custom trigger
  const onSelectDays = (index) => {
    let dayName =
      index === 0
        ? "Monday"
        : index === 1
        ? "Tuesday"
        : index === 2
        ? "Wednesday"
        : index === 3
        ? "Thursday"
        : index === 4
        ? "Friday"
        : index === 5
        ? "Saturday"
        : "Sunday"; // assign an index value for each days

    // if selectedDays state already have currently selected day then, remove it from selectedDays else push it to selectedDays
    if (selectedDays.includes(dayName)) {
      if (selectedDays.length > 1) {
        setSelectedDays(selectedDays.filter((days) => days !== dayName)); // remove
      }
    } else {
      setSelectedDays([...selectedDays, dayName]); //push
    }

    // if selectedDaysIndex state already have currently selected day's corresponding index value then, remove it from selectedDaysIndex else push it.
    if (selectedDaysIndex.includes(index)) {
      if (selectedDaysIndex.length > 1) {
        setSelectedDaysIndex(selectedDaysIndex.filter((ind) => ind !== index)); //remove
      }
    } else {
      setSelectedDaysIndex([...selectedDaysIndex, index]); //push
    }
  };

  // function for handle change of action repeat
  const onChangeActionRepeat = (item) => {
    setActionRepeat(item.key); //set changed value to actionRepeat state
  };

  // function for handle, close custom trigger modal
  const closeCustomTriggerModal = () => {
    // Define a mapping object
    const dayNumberMapping = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };
    // Create a new array by mapping the days to their corresponding numbers
    const resultDayIndex =
      customRepeat.days &&
      customRepeat.days.length > 0 &&
      customRepeat.days.map((day) => dayNumberMapping[day]);

    // if user click cancel after enter all data in custom trigger form, set all field values to it,s previous date.
    setCreateModel(false);
    setTimeCount(customRepeat.time_count);
    setUnitofTime(customRepeat.unit_of_time);
    setSelectedDays(customRepeat.days);
    setSelectedDaysIndex(resultDayIndex);
    setCustomDates({
      startDate: customRepeat.start_date,
      endDate: customRepeat.end_date,
    });
  };

  // function for handle, save custom trigger
  const saveCustomTrigger = () => {
    // if user click save data in custom trigger form, update all state's regarding to custom trigger.
    setCustomRepeat({
      ...customRepeat,
      time_count: timeCount,
      unit_of_time: unitofTime,
      days: selectedDays,
    });
    setCreateModel(false); // close modal after stored all data
  };

  // function for disable dates
  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    if (field === "start_date") {
      if (!actionDates.endDate) {
        return dateValue && dateValue < moment().startOf("day");
      }
      const isAfterEndDate = dateValue.isAfter(actionDates.endDate, "day");
      const isPastDate = dateValue && dateValue < moment().startOf("day");
      return isAfterEndDate || isPastDate;
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!actionDates.startDate) {
        return false;
      }
      return dateValue.isBefore(actionDates.startDate, "day");
    }

    // disable date in trigger-date datepicker
    // if (field === "trigger_before") {
    //   if (!actionDates.dueDate) {
    //     return false;
    //   }
    //   return dateValue.isAfter(actionDates.dueDate, "day");
    // }

    // disable date in due-date datepicker
    if (field === "trigger_date") {
      if (!actionDates.startDate || !actionDates.endDate) {
        return false;
      }
      const startDatePlusOne = moment(actionDates.startDate).add(1, "day");
      return (
        dateValue.isBefore(startDatePlusOne, "day") ||
        dateValue.isAfter(actionDates.endDate, "day")
      );
    }

    // disable date in custom-trigger-start-date datepicker
    // if (field === "custom_start_date") {
    //   if (!customDates.endDate) {
    //     return false;
    //   }
    //   return dateValue.isAfter(customDates.endDate, "day");
    // }

    // disable date in custom-trigger-end-date datepicker
    if (field === "custom_end_date") {
      if (!customDates.startDate) {
        return false;
      }
      return dateValue.isBefore(customDates.startDate, "day");
    }
  };

  // function for handle date change in all datepicker's in form
  const onDateChange = (date, dateString, field) => {
    // for start-date datepicker
    if (field === "start_date") {
      setActionDates({
        ...actionDates,
        startDate: dateString,
      });
    }

    // for end-date datepicker
    if (field === "end_date") {
      setActionDates({
        ...actionDates,
        endDate: dateString,
      });
    }

    // for trigger-date datepicker
    // if (field === "trigger_before") {
    //   setActionDates({
    //     ...actionDates,
    //     triggerBefore: dateString,
    //   });
    // }

    // for due-date datepicker
    if (field === "trigger_date") {
      setActionDates({
        ...actionDates,
        triggerDate: dateString,
      });
    }
  };

  // on submit form
  const onFinish = async (values) => {
    // payload which is sent along with api
    values = {
      ...values,
      action_repeat: actionRepeat,
      custom_repeat:
        actionRepeat !== "Custom"
          ? {}
          : {
              time_count: timeCount,
              unit_of_time: unitofTime,
              days: selectedDays,
            },
      due_in: Number(dueIn),
      due_date: dayjs(actionDates.dueDate),
      contract_id: state && state.selectedContract.id,
      supplier_id: state && state.selectedContract.supplier,
      action_level: state && state.selectedContract.type,
      updated_by: userData[0].id ? userData[0].id : userData[0]._id,
      updated_on: new Date(),
      created_by: userData[0].id ? userData[0].id : userData[0]._id,
      created_on:
        state && state.actionData && state.actionData.createdAt
          ? state.actionData.createdAt
          : state.actionData.created_on,
    };

    // on save button handler
    if (stateButton === 1) {
      values["action_status"] = "Draft";
      console.log("values", values);
      setSaveTableLoader(true);
      // api integration for save as draft
      await editRootActionAPi(
        state &&
          state.actionData &&
          state.actionData._id &&
          state.actionData._id,
        values
      ).then((res) => {
        let ssLog = {
          ss_id: res.id ? res.id : res._id,
          ss_name: values["action_name"],
          code: "root_action",
          author: userData[0].id ? userData[0].id : userData[0]._id,
          action: "edit",
          notes: "Updated Root Action & Saved As Draft",
          updated_on: new Date(),
        };
        // api integration for store a record on activity log
        createActivityLogApi(ssLog);
        setSaveTableLoader(false);
        // global popup modal
        popupModalInstance.successModal(
          values.action_name,
          ModalMessage.RA_Edit_Save,
          `/action-management`
        );
      });
    }
    // on ok button handler
    if (stateButton === 2) {
      setLoader(true);
      values["action_status"] = "Active";
      //global popup for confirmation
      popupModalInstance
        .infoModal(ModalMessage.RA_Edit_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            // api integration for submit
            await editRootActionAPi(
              state &&
                state.actionData &&
                state.actionData._id &&
                state.actionData._id,
              values
            ).then((res) => {
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["action_name"],
                code: "root_action",
                author: userData[0].id ? userData[0].id : userData[0]._id,
                action: "edit",
                notes: "Updated Root Action",
                updated_on: new Date(),
              };
              // api integration for store a record on activity log
              createActivityLogApi(ssLog);
              setLoader(false);
              history(`/action-management`, {
                state: {
                  finalPath: state && state.finalPath,
                  selectedContract: state && state.selectedContract,
                },
              }); // go back to main page
            });
          }
          if (userChoice === "cancel") {
            setLoader(false);
          }
        });
    }
  };

  //error on form submit
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // handle trigger_before input validate
  const handleTriggerChange = (e) => {
    const pattern = /^(?:[1-9]|[12]\d|3[01])$/; // Regex pattern to allow numbers from 1 to 31
    if (pattern.test(e) || e === "") {
      setDueIn(e);
      setErrorMessage("");
      let due_date = moment(new Date(actionDates.triggerDate))
        .clone()
        .add(e, "day");
      setActionDates({
        ...actionDates,
        dueDate: moment(due_date).format("DD MMM YYYY"),
      });
    } else {
      setErrorMessage("Please enter a number between 1 and 31");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      {/* Header for component */}
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {/* Edit Root Action form */}
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <Form
          form={form}
          onFinish={onFinish}
          onKeyDown={handleKeyDown}
          onFinishFailed={onFinishFailed}
        >
          <div id="action-management-id" className="contract-pipeline">
            <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
              {/* Breadcrumb for define action level */}
              <Col
                className="p-0 vertical-align"
                xs={24}
                sm={24}
                md={15}
                lg={15}
                xl={18}
                // style={{ background: "white", height: "24px" }}
              >
                <div className="action-breadcrumb">
                  <Row align="middle" type="flex" gutter={[8, 16]}>
                    <Col
                      className="vertical-align"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={2}
                    >
                      <Row align="middle" type="flex" gutter={[8, 16]}>
                        <Col span={12}>
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => history(`/action-management`)}
                          />
                        </Col>
                        <Col span={12}>
                          <Image
                            src={arrow_right_disable}
                            style={{ width: 20 }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="vertical-align"
                      xs={17}
                      sm={17}
                      md={19}
                      lg={19}
                      xl={22}
                    >
                      <span>
                        <span className="breadcrumb-path">
                          {state &&
                            state.finalPath &&
                            state.finalPath.map((item, i) => {
                              return (
                                <>
                                  <span
                                    key={item.id}
                                    className="create-root-action-path-text"
                                  >
                                    {item.name === "home" ? (
                                      <Image
                                        className="cursor-pointer"
                                        src={account_tree}
                                        onClick={() =>
                                          history(`/action-management`)
                                        }
                                        preview={false}
                                      />
                                    ) : (
                                      item.name
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      display:
                                        i === state &&
                                        state.finalPath.length - 1
                                          ? "none"
                                          : "inline-block",
                                    }}
                                    className="path-icon"
                                  >
                                    <Image src={arrow_back} preview={false} />{" "}
                                    &nbsp; &nbsp;
                                  </span>
                                </>
                              );
                            })}
                          &nbsp;
                          <span className="create-root-action-path-text">
                            {state &&
                              state.actionData &&
                              state.actionData.action_name}
                          </span>
                        </span>
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* Cancel button */}
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <div className="">
                  <CancelButton onClick={() => history(`/action-management`)} />
                </div>
              </Col>

              {/* Save Button */}
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <div className="">
                  <SaveButton
                    htmlType="submit"
                    onClick={() => setStateButton(1)}
                    loading={saveTableLoader}
                  />
                </div>
              </Col>

              {/* Ok Button */}
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <div className="">
                  <OkButton
                    htmlType="submit"
                    onClick={() => setStateButton(2)}
                    loading={loader}
                  />
                </div>
              </Col>
            </Row>

            {/* Main form content */}
            <div className="action-div-head mt-10">
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "black", fontSize: "17px" }}>
                  <h4 className="mt-10">Root Action</h4>
                </span>

                <div
                  className="action-body-head"
                  style={{ textAlign: "left", color: "black" }}
                >
                  <div className="action-new-div">
                    <div className="create-entity-div">
                      <Row
                        align="middle"
                        type="flex"
                        style={{ padding: "13px 30px 0px" }}
                      >
                        <Col span={19} className="vertical-align">
                          <h4 className="entity-text">Basic</h4>
                        </Col>
                      </Row>
                      <hr
                        className="mt-10 view-supplier-hr"
                        style={{ color: "grey" }}
                      />

                      <Row
                        align="middle"
                        type="flex"
                        gutter={[4, 16]}
                        className="m-0"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Col span={24}>
                            <div className="create-supplier-div">
                              <div className="m-10">
                                <Row align="middle" type="flex">
                                  {/* Action Name */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_name"
                                      label="ACTION NAME"
                                      colon={false}
                                      rules={[
                                        {
                                          required: true,
                                          message: `Action Name is required`,
                                          pattern: /^(?!\s*$).+/,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_name
                                      }
                                    >
                                      <Input placeholder="Enter Action Name" />
                                    </Form.Item>
                                  </Col>

                                  {/* Action Description */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_description"
                                      label="DESCRIPTION"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Description is required`,
                                          pattern: /^(?!\s*$).+/,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_description &&
                                        state.actionData.action_description
                                      }
                                    >
                                      <Input placeholder="Enter your description" />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row align="middle" type="flex">
                                  {/* Action Category */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_category"
                                      label="CATEGORY"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Category`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_category &&
                                        state.actionData.action_category
                                      }
                                    >
                                      <Select
                                        allowClear
                                        showSearch={true}
                                        placeholder="Choose category"
                                        options={
                                          actionCategory &&
                                          actionCategory.map((data) => ({
                                            key: data,
                                            label: data,
                                            value: data,
                                          }))
                                        }
                                      ></Select>
                                    </Form.Item>
                                  </Col>

                                  {/* Action Type */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_type"
                                      label="TYPE"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Action Type`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_type &&
                                        state.actionData.action_type
                                      }
                                    >
                                      <Select
                                        allowClear
                                        showSearch={true}
                                        placeholder="Choose TYPE"
                                        options={
                                          actionType &&
                                          actionType.map((data) => ({
                                            key: data,
                                            label: data,
                                            value: data,
                                          }))
                                        }
                                      ></Select>
                                    </Form.Item>
                                  </Col>

                                  {/* Action Priority */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_priority"
                                      label="PRIORITY"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Priority`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_priority &&
                                        state.actionData.action_priority
                                      }
                                    >
                                      <Select
                                        allowClear
                                        showSearch={true}
                                        placeholder="Choose PRIORITY"
                                        options={
                                          actionPriority &&
                                          actionPriority.map((data) => ({
                                            key: data,
                                            label: data,
                                            value: data,
                                          }))
                                        }
                                      ></Select>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row align="middle" type="flex">
                                  {/* Action Start Date */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="start_date"
                                      label="START DATE"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Start Date`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.start_date
                                          ? dayjs.utc(
                                              state.actionData.start_date
                                            )
                                          : undefined
                                      }
                                    >
                                      <DatePicker
                                        disabledDate={(dateValue) =>
                                          disabledDate(dateValue, "start_date")
                                        }
                                        onChange={(date, dateString) =>
                                          onDateChange(
                                            date,
                                            dateString,
                                            "start_date"
                                          )
                                        }
                                        format={"DD MMM YYYY"}
                                        className="report-input"
                                        placeholder="Select Start Date"
                                      />
                                    </Form.Item>
                                  </Col>

                                  {/* Action end Date */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="end_date"
                                      label="END DATE"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select End Date`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.end_date
                                          ? dayjs.utc(state.actionData.end_date)
                                          : undefined
                                      }
                                    >
                                      <DatePicker
                                        disabledDate={(dateValue) =>
                                          disabledDate(dateValue, "end_date")
                                        }
                                        onChange={(date, dateString) =>
                                          onDateChange(
                                            date,
                                            dateString,
                                            "end_date"
                                          )
                                        }
                                        format={"DD MMM YYYY"}
                                        className="report-input"
                                        placeholder="Select End Date"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div
                  className="action-body-head"
                  style={{ textAlign: "left", color: "black" }}
                >
                  <div className="action-new-div">
                    <div className="create-entity-div">
                      <Row
                        align="middle"
                        type="flex"
                        style={{ padding: "13px 30px 0px" }}
                      >
                        <Col span={19} className="vertical-align">
                          <h4 className="entity-text">Trigger & Repeat</h4>
                        </Col>
                      </Row>
                      <hr
                        className="mt-10 view-supplier-hr"
                        style={{ color: "grey" }}
                      />
                      <Row
                        align="middle"
                        type="flex"
                        gutter={[4, 16]}
                        className="m-0"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Col span={24}>
                            <div className="create-supplier-div">
                              <div className="m-10">
                                <Row align="middle" type="flex">
                                  {/* Action Due Date */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="trigger_date"
                                      label="TRIGGER DATE"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Trigger Date`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.trigger_date
                                          ? dayjs.utc(
                                              state.actionData.trigger_date
                                            )
                                          : undefined
                                      }
                                    >
                                      <DatePicker
                                        disabledDate={(dateValue) =>
                                          disabledDate(
                                            dateValue,
                                            "trigger_date"
                                          )
                                        }
                                        onChange={(date, dateString) =>
                                          onDateChange(
                                            date,
                                            dateString,
                                            "trigger_date"
                                          )
                                        }
                                        format={"DD MMM YYYY"}
                                        className="report-input"
                                        placeholder="Select Trigger Date"
                                      />
                                    </Form.Item>
                                  </Col>
                                  {/* Action Repeat */}
                                  <Col span={5}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_repeat"
                                      label="ACTION REPEAT"
                                      colon={false}
                                    >
                                      <Dropdown
                                        trigger={["click"]}
                                        menu={{
                                          items,
                                          selectable: true,
                                          defaultSelectedKeys: [actionRepeat],
                                          onClick: (item) =>
                                            onChangeActionRepeat(item),
                                          className: "trigger-dropdown",
                                        }}
                                        placement="bottomLeft"
                                      >
                                        <Button className="wf-create-btn">
                                          {actionRepeat}
                                        </Button>
                                      </Dropdown>
                                    </Form.Item>

                                    {/* Modal for custom trigger */}
                                    {createModel && (
                                      <Modal
                                        maskClosable={false}
                                        closeIcon={false}
                                        className="create-wf-modal"
                                        footer={null}
                                        open={createModel}
                                        onCancel={() => {
                                          setCreateModel(false);
                                        }}
                                        width={450}
                                      >
                                        {" "}
                                        <div>
                                          <Col span={24}>
                                            <div>
                                              <Space align="">
                                                <Image
                                                  src={repeat}
                                                  preview={false}
                                                />
                                                <div>
                                                  <h3>Repeat</h3>
                                                  <span
                                                    style={{
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    For Repeat the Action
                                                    Instance
                                                  </span>
                                                </div>
                                              </Space>

                                              <div className="mt-15">
                                                <Row>
                                                  <Col span={16} offset={4}>
                                                    <>
                                                      <Image
                                                        src={repeat_small}
                                                        preview={false}
                                                      />
                                                      &nbsp;&nbsp;
                                                      <Space>
                                                        Repeat &nbsp;every
                                                        &nbsp;
                                                      </Space>
                                                      {/* Time count select dropdown */}
                                                      <Select
                                                        className="custom-trigger-select"
                                                        suffixIcon={
                                                          <CaretDownOutlined
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                        }
                                                        onChange={(value) =>
                                                          onChangeCount(value)
                                                        }
                                                        defaultValue={timeCount}
                                                        showSearch={true}
                                                        options={Array.from(
                                                          { length: 99 },
                                                          (_, index) => ({
                                                            label: (
                                                              index + 1
                                                            ).toString(),
                                                            key: (
                                                              index + 1
                                                            ).toString(),
                                                            value: (
                                                              index + 1
                                                            ).toString(),
                                                          })
                                                        )}
                                                      ></Select>
                                                      &nbsp;
                                                      {/* Unit of time select dropdown */}
                                                      <Select
                                                        className="custom-trigger-select"
                                                        suffixIcon={
                                                          <CaretDownOutlined
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                        }
                                                        defaultValue={
                                                          unitofTime
                                                        }
                                                        onChange={(value) =>
                                                          onChangeUnitOfTime(
                                                            value
                                                          )
                                                        }
                                                        showSearch={true}
                                                        options={
                                                          unitTime &&
                                                          unitTime.map(
                                                            (data) => ({
                                                              key: data.key,
                                                              label: data.label,
                                                              value: data.key,
                                                            })
                                                          )
                                                        }
                                                      ></Select>
                                                    </>
                                                  </Col>
                                                </Row>

                                                {/* Days select Button's */}
                                                <Row>
                                                  <Col span={16} offset={4}>
                                                    <div
                                                      className="mt-10"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map(
                                                          (day, index) => {
                                                            return (
                                                              <Button
                                                                onClick={() =>
                                                                  onSelectDays(
                                                                    index
                                                                  )
                                                                }
                                                                type="primary"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  height: 30,
                                                                  width: 30,
                                                                  padding: 0,
                                                                  backgroundColor:
                                                                    selectedDaysIndex.includes(
                                                                      index
                                                                    )
                                                                      ? "var(--color-solid-darkblue)"
                                                                      : "grey",
                                                                  marginRight: 5,
                                                                  boxShadow:
                                                                    "none",
                                                                }}
                                                              >
                                                                {day}
                                                              </Button>
                                                            );
                                                          }
                                                        )}
                                                    </div>
                                                  </Col>
                                                </Row>

                                                {/* Row for render selected days */}
                                                <Row className="mt-10">
                                                  <Col
                                                    span={16}
                                                    offset={4}
                                                    className="text-center"
                                                    style={{
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    Occurs Every{" "}
                                                    {selectedDays &&
                                                      selectedDays.length > 0 &&
                                                      selectedDays.map(
                                                        (day, index) => {
                                                          return (
                                                            <>
                                                              <span>{day}</span>
                                                              <span>
                                                                {selectedDays.length -
                                                                  1 !==
                                                                index
                                                                  ? ", "
                                                                  : ""}
                                                              </span>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                  </Col>
                                                </Row>
                                                <Row
                                                  gutter={[16, 8]}
                                                  className="mt-10"
                                                >
                                                  <Col
                                                    span={16}
                                                    offset={4}
                                                    // className="text-center"
                                                  >
                                                    <Row
                                                      type="flex"
                                                      align={"middle"}
                                                    >
                                                      <Col span={11} offset={1}>
                                                        {/* Cancel button */}
                                                        <CancelButton
                                                          onClick={
                                                            closeCustomTriggerModal
                                                          }
                                                        />
                                                      </Col>
                                                      <Col span={11} offset={1}>
                                                        {/* Save custom trigger button */}
                                                        <SaveButton
                                                          onClick={
                                                            saveCustomTrigger
                                                          }
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                          </Col>
                                        </div>
                                      </Modal>
                                    )}
                                  </Col>
                                </Row>
                                <Row align="middle" type="flex">
                                  {/* Action Trigger Date */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="due_in"
                                      label="DUE IN"
                                      colon={false}
                                    >
                                      <InputNumber
                                        onChange={(e) => handleTriggerChange(e)}
                                        style={{ width: "100%" }}
                                        type="number"
                                        placeholder="Enter a number"
                                        // max={31}
                                        className=""
                                        size="large"
                                        defaultValue={dueIn}
                                      />
                                      {errorMessage && (
                                        <div style={{ color: "red" }}>
                                          {errorMessage}
                                        </div>
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      label="DUE DATE"
                                      colon={false}
                                    >
                                      <Input
                                        readOnly
                                        value={actionDates.dueDate}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div
                  className="action-body-head "
                  style={{ textAlign: "left", color: "black" }}
                >
                  <div className="action-new-div mb-10">
                    <div className="create-entity-div">
                      <Row
                        align="middle"
                        type="flex"
                        style={{ padding: "13px 30px 0px" }}
                      >
                        <Col span={19} className="vertical-align">
                          <h4 className="entity-text">Assignment</h4>
                        </Col>
                      </Row>
                      <hr
                        className="mt-10 view-supplier-hr"
                        style={{ color: "grey" }}
                      />
                      <Row
                        align="middle"
                        type="flex"
                        gutter={[4, 16]}
                        className="m-0"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Col span={24}>
                            <div className="create-supplier-div">
                              <div className="m-10">
                                <Row align="middle" type="flex">
                                  {/* Action Owner */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_owner"
                                      label="ACTION OWNER"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Action Owner`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_owner &&
                                        state.actionData.action_owner
                                      }
                                    >
                                      <Select
                                        allowClear
                                        showSearch={true}
                                        placeholder="Choose category"
                                        options={[
                                          ...(users &&
                                            users
                                              .filter(
                                                (filData) =>
                                                  filData.roleData.page_access
                                                    .action_management
                                                    .root_action.create
                                              )
                                              .map((user) => ({
                                                key: user._id,
                                                label: `${user.first_name} ${user.last_name}`,
                                                value: user._id,
                                              }))),
                                          ...(groups &&
                                            groups.map((group) => ({
                                              key: group._id,
                                              label: group.group_name,
                                              value: group._id,
                                            }))),
                                        ]}
                                      ></Select>
                                    </Form.Item>
                                  </Col>

                                  {/* Action Approver */}
                                  <Col span={6}>
                                    <Form.Item
                                      {...formItemLayout}
                                      className="org-supplier-label"
                                      name="action_approver"
                                      label="ACTION APPROVER"
                                      colon={false}
                                      rules={[
                                        {
                                          required:
                                            stateButton === 2 ? true : false,
                                          message: `Please Select Action Approver`,
                                        },
                                      ]}
                                      initialValue={
                                        state &&
                                        state.actionData &&
                                        state.actionData.action_approver &&
                                        state.actionData.action_approver
                                      }
                                    >
                                      <Select
                                        allowClear
                                        showSearch={true}
                                        placeholder="Choose category"
                                        options={[
                                          ...(users &&
                                            users
                                              .filter(
                                                (filData) =>
                                                  filData.roleData.page_access
                                                    .action_management
                                                    .root_action.create
                                              )
                                              .map((user) => ({
                                                key: user._id,
                                                label: `${user.first_name} ${user.last_name}`,
                                                value: user._id,
                                              }))),
                                          ...(groups &&
                                            groups.map((group) => ({
                                              key: group._id,
                                              label: group.group_name,
                                              value: group._id,
                                            }))),
                                        ]}
                                      ></Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <NotAuthorized redirect={`/action-management`} />
      )}
    </Layout>
  );
};

// export Edit Root Action Component
export default EditRootAction;
