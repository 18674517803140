import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Table,
  Tabs,
  Form,
  Select,
  Input,
  Radio,
  Layout,
} from "antd"; //antd components
import { LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../../Header";
import HeaderCard from "../../HeaderCard";
import "../../../assets/style/setup.css";
import { listLegalEntity } from "../../../api/legalEntityApi";
import {
  costCenterCreateApi,
  editCostCenterApi,
} from "../../../api/costCenterApi";
import moment from "moment";
import { createActivityLogApi } from "../../../api/logApi";
import {
  HeaderChangerAtom,
  RoleAccessAtom,
  userDataAtom,
} from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { CancelButton, SaveButton, SubmitButton } from "../../GlobalButton";
import NotAuthorized from "../../NotAuthorized";
import DataTable from "../../Tables/DataTable";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";

function CostCenterController() {
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //global state variable hold the header
  let header = headerChanger.header;
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  // global state variable hold the key while switch header name
  const menuKey = "5";
  //global state variable hold the navigating different routes
  const navigate = useNavigate();

  let { state } = useLocation();
  console.log("state", state);
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  //global state variable hold the corresponding supplier details using location
  const stateButton = {
    button: 1,
  };
  const [ccId, setCcId] = useState("");
  // state variable hold the loading icon while fetch the response from API
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  // state variable hold the legal name id given in  input field
  const [legalEntityId, setLegalEntityId] = useState(
    state.data && state.data.le_id && state.data.le_id
  );
  // state variable hold the cost Center name ,legal entity,cost center number given in  input field
  const [costCenterField, setCostCenterField] = useState({
    cc_nbr: state.data && state.data.cc_nbr ? state.data.cc_nbr : null,
    le_name: state.data && state.data.le_id ? state.data.le_id : null,
    cc_name: state.data && state.data.cc_name ? state.data.cc_name : null,
  });
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [associationData, setAssociationData] = useState([]);
  const [status, setStatus] = useState(state.data && state.data.cc_status);
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [leFile, setLeFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  /*
   * Function to check the label name and update value corresponding component
   * @param label - check name component corresponding name
   * @param e- changes values
   * @param key - key for corresponding selected value
   * @param value - value of selected name
   */
  const onCreateCostCenter = (label, e, key, value) => {
    console.log("label", label);
    console.log("key", key);
    if (label === "cc_nbr") {
      setCostCenterField({
        ...costCenterField,
        cc_nbr: e.target.value,
      });
      e.target.value !== "" &&
      state.data &&
      state.data.cc_nbr !== e.target.value
        ? setDisable("")
        : setDisable(disableVal);
    } else if (label === "le_name") {
      if (key !== undefined) {
        setCostCenterField({
          ...costCenterField,
          le_name: key.value,
        });
        setLegalEntityId(key.key);
      } else {
        setCostCenterField({
          ...costCenterField,
          le_name: null,
        });
        setLegalEntityId();
      }
      key !== undefined && state.data && state.data.le_id !== key.key
        ? setDisable("")
        : setDisable(disableVal);
    } else if (label === "cc_name") {
      setCostCenterField({
        ...costCenterField,
        cc_name: e.target.value,
      });
      e.target.value !== "" &&
      state.data &&
      state.data.cc_name !== e.target.value
        ? setDisable("")
        : setDisable(disableVal);
    }
  };

  let disableVal = "disable-btn";
  const [disable, setDisable] = useState(disableVal);

  // state variable hold the legalentity name from API response
  const [legalEntityData, setLegalEntityData] = useState([]);
  // state variable hold the loading icon while fetch the response from API
  const [tableLoader, setTableLoader] = useState(false);

  /* Function to handle submit form
   * @paran value - collection of component data in value
   */

  const onFinish = async (values) => {
    console.log("values", values);

    values["updated_by"] = userData[0].id;
    values["author"] =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id
        ? userData[0]._id
        : "";
    values["author_name"] =
      userData && userData[0] && userData[0].first_name
        ? userData[0].first_name
        : "";
    values["comments"] = comments.length !== 0 ? comments : [];

    const uploadForm = new FormData();
    if (leFile && leFile.length > 0) {
      for (let i = 0; i < leFile.length; i++) {
        uploadForm.append("notesCommentsFiles", leFile[i].originFileObj);
      }
    }

    if (state.flag === "edit") {
      values["cc_status"] =
        state.data && state.data.cc_status === "In Draft"
          ? stateButton.button === 1
            ? "In Draft"
            : "Active"
          : stateButton.button === 1
          ? "In Draft"
          : status;
      values["le_id"] = state.le_id ? state.le_id : legalEntityId;
      console.log("values", values);
      values["updated_by"] = userData[0].id;

      if (stateButton.button === 2) {
        uploadForm.append("bodyData", JSON.stringify(values));
        setTableLoader(true);
        popupModalInstance
          .infoModal(ModalMessage.CC_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              editCostCenterApi(
                state.data._id ? state.data._id : state.data.id,
                uploadForm
              ).then((res) => {
                console.log("res", res);
                let ssLog = {
                  ss_id: res.id ? res.id : res._id,
                  ss_name: values["cc_name"],
                  code: "cost_center",
                  author: userData[0].id,
                  action: "edit",
                  notes: "Edited Cost Center",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                setTableLoader(false);
                navigate(`/system-setup/?back=org-ccs`);
              });
            } else {
              setTableLoader(false);
            }
          });
      } else {
        uploadForm.append("bodyData", JSON.stringify(values));
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        setSaveTableLoader(false);
        await editCostCenterApi(
          state.data._id ? state.data._id : state.data.id,
          uploadForm
        ).then((res) => {
          let ssLog = {
            ss_id: res.id ? res.id : res._id,
            ss_name: values["cc_name"],
            code: "cost_center",
            author: userData[0].id,
            action: "edit",
            notes: "Edited Cost Center",
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog).then((res) => {
            console.log("LogRes: ", res);
          });
          setTableLoader(false);
          setSaveTableLoader(false);
          popupModalInstance.successModal(
            values.cc_name,
            ModalMessage.CC_Create_Save,
            ""
          );
        });
      }
    } else if (state.flag === "clone") {
      console.log("clone-values", values);
      values["le_id"] = legalEntityId;

      if (stateButton.button === 1) {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        // Save button in clone mode
        values["cc_status"] = "In Draft";
        setSaveTableLoader(true);
        values["created_by"] = userData[0].id;
        uploadForm.append("bodyData", JSON.stringify(values));
        await costCenterCreateApi(uploadForm).then((res) => {
          if (res.status === 304) {
            popupModalInstance.errorModal(ModalMessage.CostCenter_Create_Error);
            setSaveTableLoader(false);
          } else {
            setCcId(res.id ? res.id : res._id);
            let ssLog = {
              ss_id: res.id ? res.id : res._id,
              ss_name: values["cc_name"],
              code: "cost_center",
              author: userData[0].id,
              action: "view",
              notes: "Cloned Cost Center",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog).then((res) => {
              console.log("LogRes: ", res);
            });
            console.log("res-clone", res);
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              values.cc_name,
              ModalMessage.CC_Clone_Save,
              ``
            );
          }
        });
      } else if (stateButton.button === 2) {
        // Submit button in clone mode
        values["cc_status"] = "Active";
        setTableLoader(true);
        uploadForm.append("bodyData", JSON.stringify(values));
        popupModalInstance
          .infoModal(ModalMessage.CC_Clone_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              values["created_by"] = userData[0].id;
              costCenterCreateApi(uploadForm).then((res) => {
                if (res.status === 304) {
                  popupModalInstance.errorModal(
                    ModalMessage.CostCenter_Create_Error
                  );
                  setTableLoader(false);
                } else {
                  setCcId(res.id ? res.id : res._id);
                  let ssLog = {
                    ss_id: res.id ? res.id : res._id,
                    ss_name: values["cc_name"],
                    code: "cost_center",
                    author: userData[0].id,
                    action: "view",
                    notes: "Cloned Cost Center",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {
                    console.log("LogRes: ", res);
                  });
                  console.log("clone-res-submit", res);
                  setTableLoader(false);
                  navigate(`/system-setup/?back=org-ccs`);
                }
              });
            } else {
              setTableLoader(false);
            }
          });
      }
    } else {
      if (stateButton.button === 1) {
        setSaveTableLoader(true);
        values["cc_status"] = "In Draft";
        values["le_id"] = legalEntityId;

        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }

        if (ccId === "") {
          values["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(values));
          await costCenterCreateApi(uploadForm).then((res) => {
            if (res.status === 304) {
              popupModalInstance.errorModal(
                ModalMessage.CostCenter_Create_Error
              );
              setSaveTableLoader(false);
            } else {
              setCcId(res.id ? res.id : res._id);
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["cc_name"],
                code: "cost_center",
                author: userData[0].id,
                action: "view",
                notes: "Created Cost Center",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              console.log("res-draft", res);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                values.cc_name,
                ModalMessage.CC_Create_Save,
                ``
              );
            }
          });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          await editCostCenterApi(ccId, uploadForm).then((res) => {
            let ssLog = {
              ss_id: res.id ? res.id : res._id,
              ss_name: values["cc_name"],
              code: "cost_center",
              author: userData[0].id,
              action: "save",
              notes: "Updated Cost Center",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog).then((res) => {
              console.log("LogRes: ", res);
            });
            console.log("res-draft", res);
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              values.cc_name,
              ModalMessage.CC_Create_Save,
              ``
            );
          });
        }
      }

      if (stateButton.button === 2) {
        setTableLoader(true);
        values["cc_status"] = "Active";
        values["le_id"] = legalEntityId;
        uploadForm.append("bodyData", JSON.stringify(values));
        if (ccId === "") {
          popupModalInstance
            .infoModal(ModalMessage.CC_Create_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                costCenterCreateApi(uploadForm).then((res) => {
                  if (res.status === 304) {
                    popupModalInstance.errorModal(
                      ModalMessage.CostCenter_Create_Error
                    );
                    setTableLoader(false);
                  } else {
                    setCcId(res.id ? res.id : res._id);
                    let ssLog = {
                      ss_id: res.id ? res.id : res._id,
                      ss_name: values["cc_name"],
                      code: "cost_center",
                      author: userData[0].id,
                      action: "view",
                      notes: "Created Cost Center",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog).then((res) => {
                      console.log("LogRes: ", res);
                    });
                    console.log("clone-res-submit", res);
                    setTableLoader(false);
                    navigate(`/system-setup/?back=org-ccs`);
                  }
                });
              } else {
                setTableLoader(false);
              }
            });
        } else {
          popupModalInstance
            .infoModal(ModalMessage.CC_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                editCostCenterApi(ccId, uploadForm).then((res) => {
                  let ssLog = {
                    ss_id: res.id ? res.id : res._id,
                    ss_name: values["cc_name"],
                    code: "cost_center",
                    author: userData[0].id,
                    action: "edit",
                    notes: "Edited Cost Center",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {
                    console.log("LogRes: ", res);
                  });
                  setTableLoader(false);
                  navigate(`/system-setup/?back=org-ccs`);
                });
              } else {
                setTableLoader(false);
              }
            });
        }
      }
    }
  };

  const handleRadioClick = (value) => {
    console.log("status checked", value);
    if (
      value !== "Active" &&
      state.data.cc_status === "Active" &&
      associationData.length > 0
    ) {
      popupModalInstance
        .infoModal(ModalMessage.CC_Status_Change)
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setStatus(value);
            state.data.cc_status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    } else {
      popupModalInstance
        .infoModal(
          value === "Active"
            ? ModalMessage.CC_Status_Change_To_Active
            : ModalMessage.CC_Status_Change_Confirm
        )
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setStatus(value);
            state.data.cc_status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    }
  };

  /* Function to handle submit form failed
  @ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /*
   * use effect handle a retrive legal entity name from API response
   *
   */
  useEffect(() => {
    listLegalEntity().then((res) => {
      console.log("le-res", res);
      setLegalEntityData(res);
    });
  }, []);

  /*
   * Function to handle component box layout
   *
   */
  const CostCenterDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="org-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="cc_name"
                    label="Cost Center Name"
                    colon={false}
                    rules={[
                      {
                        required: "true",
                        message: "Please enter cost center name",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter cost center name"
                      readOnly={state.flag === "view"}
                      onChange={(e) => onCreateCostCenter("cc_name", e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="cc_nbr"
                    label="Cost Center Number"
                    colon={false}
                    rules={[
                      {
                        message: "Please enter cost center number",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter cost center number"
                      readOnly={state.flag === "view"}
                      onChange={(e) => onCreateCostCenter("cc_nbr", e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="le_name"
                    label="Legal Entity"
                    colon={false}
                  >
                    {state.flag === "view" ? (
                      <Input
                        disabled={state.flag === "view"}
                        placeholder="Choose Legal Name"
                      ></Input>
                    ) : (
                      <Select
                        allowClear
                        disabled={state.flag === "view"}
                        onChange={(key, value) => {
                          onCreateCostCenter("le_name", key, value);
                        }}
                        showSearch={true}
                        placeholder="Choose Legal Name"
                        options={
                          legalEntityData &&
                          legalEntityData.length > 0 &&
                          legalEntityData.map((data) => ({
                            key: data._id,
                            label: data.le_name,
                            value: data.le_name,
                          }))
                        }
                      ></Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {state.flag === "edit" &&
                state.data &&
                state.data.cc_status !== "In Draft" && (
                  <Row align="middle" type="flex">
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="cc_status"
                        label="COST CENTER STATUS"
                        colon={false}
                      >
                        <div className="association-radio-status">
                          <Radio.Group
                            buttonStyle="solid"
                            // onChange={(e) => setGroupStatus(e.target.value)}
                            value={status}
                          >
                            <Radio
                              value={"Active"}
                              onClick={(e) => handleRadioClick(e.target.value)}
                            >
                              Active
                            </Radio>
                            <Radio
                              value={"Inactive"}
                              onClick={(e) => handleRadioClick(e.target.value)}
                            >
                              Inactive
                            </Radio>
                          </Radio.Group>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  const items = [
    {
      key: "1",
      label: `Cost Center Details`,
      children: CostCenterDetails(),
      show: true,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
      show:
        state.flag === "view" ||
        state.flag === "edit" ||
        state.flag === "clone",
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view",
    },
    {
      key: "3",
      label: `Entity Log`,
      children: EntityLog(),
      show: state.flag === "view",
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "4",
      label: `Notes And Comments`,
      children: (
        <NotesAndComments
          detailData={state.data}
          viewedFile={viewedFile}
          comments={comments}
          url={url}
          setUrl={setUrl}
          setFile={setLeFile}
          setFileKeysToDelete={setFileKeysToDelete}
          setViewedFile={setViewedFile}
          setComments={setComments}
          flag="setup"
          setDisable={setDisable}
        />
      ),
      show: true,
    },
  ].filter((items) => items.show);

  useEffect(() => {
    document.title = "Cost Center Setup | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleTabChange = (key) => {
    setLeTabKey(key);
  };

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (leFile && leFile.length > 0) {
      for (let i = 0; i < leFile.length; i++) {
        uploadForm.append("file", leFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "cc"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="create-legal-entity"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
            initialValues={{
              ["cc_name"]: state.data && state.data.cc_name,
              ["cc_nbr"]: state.data && state.data.cc_nbr,
              ["le_name"]: state.data && state.data.le_name,
              ["cc_status"]: state.data && state.data.cc_status,
            }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={state.flag === "view" ? 24 : 20}
                md={state.flag === "view" ? 24 : 21}
                lg={state.flag === "view" ? 24 : 21}
                xl={state.flag === "view" ? 24 : 18}
              >
                <HeaderCard
                  mainMenu="Organization Setup"
                  subMenu="Cost Center"
                  itemName={
                    state.data?.cc_name || state.flag === "create"
                      ? "New Cost Center"
                      : "Clone Cost Center"
                  }
                  itemStatus={state.data?.cc_status}
                  goBackLink="/system-setup/?back=org-ccs"
                  state={state.data}
                  showDetails={state.flag === "view" || state.flag === "edit"}
                />
              </Col>
              {state.flag !== "view" && (
                <>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <CancelButton
                      onClick={() => navigate(`/system-setup/?back=org-ss`)}
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SaveButton
                      onClick={() => (stateButton.button = 1)}
                      type="primary"
                      htmlType="submit"
                      loading={saveTableLoader}
                      disabled={
                        state.flag === "create"
                          ? costCenterField.cc_name
                            ? false
                            : true
                          : state.flag === "edit"
                          ? state.data && state.data.cc_status !== "In Draft"
                          : false
                      }
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SubmitButton
                      onClick={() => (stateButton.button = 2)}
                      type="primary"
                      htmlType="submit"
                      loading={tableLoader}
                      disabled={
                        (state.data && state.data.cc_status === "In Draft") ||
                        state.flag === "create" ||
                        state.flag === "clone"
                          ? costCenterField.cc_name &&
                            costCenterField.cc_nbr &&
                            costCenterField.le_name
                            ? false
                            : true
                          : disable
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs
                    items={items}
                    onChange={handleTabChange}
                    tabBarExtraContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: -6,
                        }}
                      >
                        {state.flag === "view" && leTabKey === "4" && (
                          <SaveButton
                            loading={notesSaveLoader}
                            onClick={() => {
                              saveNotesCommentsData();
                            }}
                            style={{ width: 140 }}
                          />
                        )}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=org-ccs`} />
      )}
    </Layout>
  );
}

export default CostCenterController;
