import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Table, Image, Modal, Row, Col, Button } from "antd"; //antd components
import { MoreOutlined } from "@ant-design/icons";
import "../../assets/style/setup.css";
import add from "../../assets/images/icons/Add_round.svg";
import { spendOverviewTableData } from "../../utils/data";
import { SpendOverviewUpdateAtom } from "../../store/store";

function SpendOverview() {
  const [spendOverviewUpdate, setSpendOverviewUpdate] = useRecoilState(
    SpendOverviewUpdateAtom
  );
  let selectedCol = spendOverviewUpdate.selectedCol;
  let unSelectedCol = spendOverviewUpdate.unSelectedCol;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showiconsOf, setShowiconsOf] = useState(null);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [colEditor, openColEditor] = useState(false);
  // const [selectedCol, setSelectedCol] = useState(["Contract", "CYV", "TCV"])
  // const [unSelectedCol, setUnSelectedCol] = useState(["Start Date", "End Date"])

  const allColumns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      align: "center",
      show: true,
      filters: [
        {
          text: "S1",
          value: "S1",
        },
        {
          text: "S2",
          value: "S2",
        },
      ],
      filterIcon: (filtered) => (
        <MoreOutlined
          style={{ color: filtered ? "#1890ff" : undefined, fontSize: "17px" }}
        />
      ),
      onFilter: (value, record) =>
        record.supplier.props.children.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Contract",
      dataIndex: "contract",
      align: "center",
      show: selectedCol.includes("Contract") ? true : false,
      filters: [
        {
          text: "A",
          value: "A",
        },
        {
          text: "C",
          value: "C",
        },
      ],
      filterIcon: (filtered) => (
        <MoreOutlined
          style={{ color: filtered ? "#1890ff" : undefined, fontSize: "17px" }}
        />
      ),
      onFilter: (value, record) => record.contract.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "CYV (KEUR)",
      dataIndex: "cyv",
      align: "center",
      show: selectedCol.includes("CYV") ? true : false,
      filters: [
        {
          text: "30000",
          value: "30000",
        },
        {
          text: "23000",
          value: "23000",
        },
      ],
      filterIcon: (filtered) => (
        <MoreOutlined
          style={{ color: filtered ? "#1890ff" : undefined, fontSize: "17px" }}
        />
      ),
      onFilter: (value, record) => record.cyv.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "TCV (KEUR)",
      dataIndex: "tcv",
      align: "center",
      show: selectedCol.includes("TCV") ? true : false,
      filters: [
        {
          text: "200000",
          value: "200000",
        },
        {
          text: "90000",
          value: "90000",
        },
      ],
      filterIcon: (filtered) => (
        <MoreOutlined
          style={{ color: filtered ? "#1890ff" : undefined, fontSize: "17px" }}
        />
      ),
      onFilter: (value, record) => record.tcv.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      align: "center",
      show: selectedCol.includes("Start Date") ? true : false,
      filters: [
        {
          text: "25.11.2022",
          value: "25.11.2022",
        },
        {
          text: "10.12.2022",
          value: "10.12.2022",
        },
      ],
      filterIcon: (filtered) => (
        <MoreOutlined
          style={{ color: filtered ? "#1890ff" : undefined, fontSize: "17px" }}
        />
      ),
      onFilter: (value, record) => record.start_date.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      align: "center",
      show: selectedCol.includes("End Date") ? true : false,
      filters: [
        {
          text: "20.09.2023",
          value: "20.09.2023",
        },
        {
          text: "09.07.2023",
          value: "09.07.2023",
        },
      ],
      filterIcon: (filtered) => (
        <MoreOutlined
          style={{ color: filtered ? "#1890ff" : undefined, fontSize: "17px" }}
        />
      ),
      onFilter: (value, record) => record.end_date.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: (
        <Image
          src={add}
          className="cursor-pointer"
          preview={false}
          onClick={() => openColEditor(true)}
        />
      ),
      dataIndex: "add",
      align: "center",
      width: "7rem",
      show: true,
    },
  ];

  const columnToShow = allColumns.filter((col) => col.show !== false);

  const spendOverviewData = () => {
    const spendOverviewData = spendOverviewTableData.map((item, index) => {
      let tableRow = {
        key: item.id,
        supplier: (
          <span
            onClick={() => rowClick(item.id)}
            style={{
              color: "var(--color-solid-darkblue)",
              fontWeight: "100",
              cursor: "pointer",
            }}
          >
            {item.supplier}
          </span>
        ),
        contract: item.contract,
        cyv: item.cyv,
        tcv: item.tcv,
        start_date: item.start_date,
        end_date: item.end_date,
      };
      return tableRow;
    });
    let totalData = {
      supplier: "Total",
      cyv: spendOverviewTableData.reduce((cyv, object) => {
        return cyv + object.cyv;
      }, 0),
      tcv: spendOverviewTableData.reduce((tcv, object) => {
        return tcv + object.tcv;
      }, 0),
    };
    spendOverviewData.push(totalData);
    setTableData(spendOverviewData);
  };

  useEffect(() => {
    spendOverviewData();
  }, [showiconsOf]);

  const rowClick = (rowId) => {
    console.log("rowId", rowId);
  };

  const selectCol = (currency) => {
    // setSelectedCol((prevActiveCurrency) =>
    //   [...prevActiveCurrency, currency]
    // );
    // setUnSelectedCol((col) => {
    //   return col.filter(
    //     (col) => col !== currency
    //   );
    // })

    setSpendOverviewUpdate({
      selectedCol: [...selectedCol, currency],
      unSelectedCol: unSelectedCol.filter((col) => col !== currency),
    });
  };

  const unSelectCol = (currency) => {
    // setUnSelectedCol((prevActiveCurrency) =>
    //   [...prevActiveCurrency, currency]
    // );
    // setSelectedCol((col) => {
    //   return col.filter(
    //     (col) => col !== currency
    //   );
    // })

    setSpendOverviewUpdate({
      unSelectedCol: [...unSelectedCol, currency],
      selectedCol: selectedCol.filter((col) => col !== currency),
    });
  };

  return (
    <div className="commercial-tab-render spend-overview">
      <Table
        rowSelection={rowSelection}
        dataSource={tableData}
        columns={columnToShow}
        bordered
        size="large "
        scroll={{ x: "calc(300px + 50%)", y: 400 }}
        pagination={false}
        className="commercial-table"
        onRow={(record, rowIndex) => {
          return {
            // onClick: () => { rowClick(record.key) },
            onMouseOver: () => {
              setShowiconsOf(record.key);
            },
            onMouseOut: () => {
              setShowiconsOf(null);
            },
          };
        }}
      />

      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => openColEditor(false)}
          onCancel={() => openColEditor(false)}
          closable={false}
          footer={null}
        >
          <div>
            <span className="col-editor-head">
              Select the tag to show in Spend Overview.
            </span>
            <span
              className="float-right col-editor-save"
              onClick={() => openColEditor(false)}
            >
              save
            </span>
          </div>
          <div className="col-list p-10 mt-15">
            <Row gutter={[8, 16]}>
              {selectedCol &&
                selectedCol.length !== 0 &&
                selectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="selected-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        unSelectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
            <Row className="mt-20" gutter={[8, 16]}>
              {unSelectedCol &&
                unSelectedCol.length !== 0 &&
                unSelectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="select-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        // selectedCol.push(col);
                        selectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default SpendOverview;
