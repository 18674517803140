import React, { useEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import "../assets/style/flowdiagram.css";
import "../assets/style/viewcontract.css";
import {
  Row,
  Col,
  Space,
  Avatar,
  Divider,
  Image,
  Popover,
  Button,
  Modal,
  Select,
  Tag,
  message,
} from "antd"; //antd components
import { UserOutlined } from "@ant-design/icons";
import MailIcon from "../assets/images/icons/mail_icon.svg";
import ApproverInQueue from "../assets/images/icons/approver_in_queue.svg";
import CurrentApprover from "../assets/images/icons/approver_current_queue.svg";
import ApproverRejected from "../assets/images/icons/approver_rejected.svg";
import ApproverApproved from "../assets/images/icons/approver_approved.svg";
import MultipleApprovers from "../assets/images/icons/multiple_approver_icon.svg";
import moment from "moment";
import axios from "axios";
import { getUsersApi } from "../api/usersApi";
import { getAllGroupApi } from "../api/groupsApi";
import { updateWorkflowApi } from "../api/contractWorkflowApi";
import { CancelButton, OkButton } from "../components/GlobalButton";
import UploadDocument from "../pages/UploadDocument";
import DocuSignRecipients from "../pages/DocuSignRecipients";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../store/store";
import { reTriggerDocuSignFlowApi } from "../api/docuSignApi";

const FlowDiagram = ({ flowData, from, contract_id, selectedFile }) => {
  // console.log("selectedFilee", selectedFile);
  const position = { x: 0, y: 0 };
  const edgeType = "smoothstep";
  const [flowDataState, setFlowDataState] = useState(flowData);
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  const [approverEditor, setApproverEditor] = useState(false);
  const [clickedNode, setClickedNode] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersBackup, setSelectedUsersBackup] = useState([]);
  const [allAvailableUsers, setAllAvailableUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [docuSignModal, setDocuSignModal] = useState(false);
  const [docuSignModalWarn, setDocuSignModalWarn] = useState(false);
  const userDataRecoil = useRecoilState(userDataAtom);

  useEffect(() => {
    console.log("allAvailableUsers: ", allAvailableUsers);
    console.log("availableUsers: ", availableUsers);
    console.log("selectedUsers: ", selectedUsers);
  }, [availableUsers, selectedUsers, allAvailableUsers]);

  useEffect(() => {
    console.log("flowDataState: ", flowDataState);
  }, [flowDataState]);

  const handleSearch = (value) => {
    const filteredUsers = allAvailableUsers.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const email = user.email ? user.email.toLowerCase() : "";
      const groupName = user.group_name ? user.group_name.toLowerCase() : "";
      const searchValue = value.toLowerCase();
      return (
        fullName.includes(searchValue) ||
        email.includes(searchValue) ||
        groupName.includes(searchValue)
      );
    });
    const remainingUsers = filteredUsers.filter(
      (user) => !selectedUsers.includes(user)
    );
    setAvailableUsers(remainingUsers);
  };

  const handleUserSelect = (userId) => {
    const selectedUser = allAvailableUsers.find((user) => user._id === userId);
    const updatedSelectedUsers = [...selectedUsers, selectedUser].sort(
      (a, b) => {
        const firstNameA = a.first_name || a.group_name;
        const firstNameB = b.first_name || b.group_name;
        return firstNameA.localeCompare(firstNameB);
      }
    );
    setSelectedUsers(updatedSelectedUsers);

    // Filter out the selected user from availableUsers
    const remainingAvailableUsers = allAvailableUsers.filter(
      (user) =>
        user._id !== userId &&
        !updatedSelectedUsers.some((selected) => selected._id === user._id)
    );

    setAvailableUsers(remainingAvailableUsers);
  };

  const handleTagClose = (userId) => {
    if (selectedUsers.length > 1) {
      const removedUser = selectedUsers.find((user) => user._id === userId);
      const updatedSelectedUsers = selectedUsers.filter(
        (user) => user._id !== userId
      );
      setSelectedUsers(updatedSelectedUsers);

      // Filter out the removed user from allAvailableUsers and selectedUsers
      const remainingAvailableUsers = [...availableUsers, removedUser].filter(
        (user) =>
          !updatedSelectedUsers.some((selected) => selected._id === user._id)
      );

      setAvailableUsers(remainingAvailableUsers);
    }
  };

  useEffect(() => {
    Promise.all([getUsersApi(), getAllGroupApi()])
      .then(([userRes, groupRes]) => {
        const sortedRes = [...userRes, ...groupRes].sort((a, b) => {
          const firstNameA = a.first_name || a.group_name;
          const firstNameB = b.first_name || b.group_name;
          return firstNameA.localeCompare(firstNameB);
        });

        setAllAvailableUsers(sortedRes);
        setAvailableUsers(sortedRes);

        const userDetails = Object.entries(flowDataState || {})
          .map(([key, value]) => {
            console.log("valuee", value);
            return value.approverList.map((item) => {
              const userData = userRes.find(
                (user) => user.email === item.emailId[0]
              );
              if (userData) {
                return {
                  full_name: `${userData.first_name} ${userData.last_name}`,
                  email: userData.email,
                  role: userData.role_name,
                  profile_picture: userData.profile_picture || undefined,
                };
              }
            });
          })
          .flat()
          .filter((n) => n);

        setUserProfileDetails(userDetails);
      })
      .catch((error) => {
        console.error("Error fetching users/groups:", error);
      });
  }, [flowDataState]);

  // Function to create label content
  const createLabel = (
    approver,
    emailId,
    status,
    updatedOn,
    comments,
    profilePicture,
    roleName
  ) => {
    const statusColor =
      status === "Approved"
        ? "#03A800"
        : status === "Rejected"
        ? "#F2473B"
        : status === "Current Approver"
        ? "#0177FB"
        : "#626166";
    if (approver.toLowerCase().startsWith("multiple")) {
      approver = approver.split("_")[0];
    }
    return (
      <>
        <Popover
          content={
            <div>
              <Row>
                <Col span={24}>
                  <Row type="flex">
                    <Col>
                      {profilePicture !== undefined ? (
                        <Avatar
                          size={25}
                          src={<img src={profilePicture} />}
                          style={{
                            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      ) : approver.toLowerCase().startsWith("multiple") ? (
                        <Avatar
                          size={25}
                          src={
                            <div>
                              <img
                                src={MultipleApprovers}
                                style={{
                                  width: 25,
                                  height: 25,
                                }}
                              />
                            </div>
                          }
                          style={{
                            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      ) : (
                        <Avatar
                          size={25}
                          style={{
                            backgroundColor: statusColor,
                            fontSize: 14,
                          }}
                        >
                          {approver.charAt(0).toUpperCase()}
                        </Avatar>
                      )}
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontSize: 16,
                          paddingLeft: 10,
                          fontWeight: 600,
                          width: 170,
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {approver}
                      </span>
                    </Col>
                  </Row>
                  {roleName && (
                    <Row>
                      <Col>
                        <span
                          style={{
                            fontSize: 13,
                            paddingLeft: 36,
                            fontWeight: 600,
                            width: 170,
                            color: "var(--color-solid-darkergrey)",
                          }}
                        >
                          {roleName}
                        </span>
                      </Col>
                    </Row>
                  )}
                  <Divider
                    style={{
                      margin: "12px 0px 12px 0px",
                      backgroundColor: "var(--color-solid-lightgrey)",
                      height: 1,
                    }}
                  />
                  <Row type="flex" className="mt-10">
                    <Col span={4}>
                      <Image
                        src={MailIcon}
                        preview={false}
                        width={18}
                        height={18}
                        style={{ marginLeft: 5, marginTop: -4 }}
                      />
                    </Col>
                    <Col span={20}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            color: "var(--color-solid-darkerblue)",
                            width: 170,
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          title={
                            Array.isArray(emailId)
                              ? emailId.join(", ")
                              : emailId
                          }
                        >
                          {Array.isArray(emailId)
                            ? emailId.join(", ")
                            : emailId}
                        </span>
                        {status === "In Queue" && (
                          <Button
                            style={{
                              padding: "0px 15px",
                              height: 20,
                              fontSize: 10,
                              fontWeight: 600,
                              border: "none",
                              backgroundColor: "#f1f1f1",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setApproverEditor(true);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          placement="bottom"
          arrow={false}
          trigger="click"
          overlayStyle={{
            border:
              status === "Approved"
                ? "3px solid #03A800"
                : status === "Rejected"
                ? "3px solid #F2473B"
                : status === "Current Approver"
                ? "3px solid #0177FB"
                : "3px solid #626166",
            borderRadius: 10,
            minWidth: 250,
          }}
          zIndex={99}
          overlayClassName="node-hover-card"
        >
          <Avatar
            size={150}
            src={
              !approver.toLowerCase().startsWith("multiple") &&
              profilePicture ? (
                <img src={profilePicture} />
              ) : approver.toLowerCase().startsWith("multiple") ? (
                <Image
                  src={
                    status === "Approved"
                      ? ApproverApproved
                      : status === "Rejected"
                      ? ApproverRejected
                      : status === "Current Approver"
                      ? CurrentApprover
                      : MultipleApprovers
                  }
                  height={150}
                  width={120}
                  preview={false}
                />
              ) : (
                !approver.toLowerCase().startsWith("multiple") &&
                !profilePicture && (
                  <Image
                    src={
                      status === "Approved"
                        ? ApproverApproved
                        : status === "Rejected"
                        ? ApproverRejected
                        : status === "Current Approver"
                        ? CurrentApprover
                        : ApproverInQueue
                    }
                    height={140}
                    width={80}
                    preview={false}
                  />
                )
              )
            }
            className="avatar"
          />
        </Popover>
        <Row
          className="mt-15"
          style={{
            backgroundColor: "#fff",
            minWidth: 280,
            padding: 10,
            borderRadius: 10,
          }}
        >
          <Col span={24}>
            <Row type="flex" className="mt-10">
              <Col>
                {profilePicture ? (
                  <Avatar
                    size={35}
                    src={<img src={profilePicture} />}
                    style={{
                      boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                ) : (
                  <Avatar
                    size={35}
                    style={{
                      backgroundColor: statusColor,
                      fontSize: 18,
                      boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {approver.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </Col>
              <Col>
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    paddingLeft: 15,
                    width: 200,
                  }}
                  title={approver}
                >
                  {/* <span
                    style={{ fontSize: 20, paddingLeft: 15, fontWeight: 600 }}
                  > */}
                  {approver}
                  {/* </span> */}
                </span>
              </Col>
            </Row>
            {from !== "workflow-view" && from !== "create-contract-view" && (
              <Row>
                <Col>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      marginLeft: 50,
                      wordBreak: "keep-all",
                      whiteSpace: "nowrap",
                      color: statusColor,
                    }}
                  >
                    {status === "Approved"
                      ? "Approval Complete"
                      : status === "Rejected"
                      ? "Rejected"
                      : status === "Current Approver"
                      ? "Current Approver"
                      : "In Queue"}
                  </span>
                </Col>
              </Row>
            )}
            {status !== "In Queue" &&
              status !== "Current Approver" &&
              from !== "workflow-view" &&
              from !== "create-contract-view" && (
                <>
                  <Row className="mt-5">
                    <Col>
                      <span
                        style={{
                          fontSize: 16,
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                          fontWeight: 600,
                        }}
                      >
                        {status === "Approved"
                          ? "Approval on: "
                          : status === "Rejected"
                          ? "Rejected on: "
                          : ""}
                        <span
                          style={{
                            fontSize: 16,
                            wordBreak: "keep-all",
                            whiteSpace: "nowrap",
                            fontWeight: 100,
                          }}
                        >
                          {moment(updatedOn).format("DD MMM YYYY, hh:mm A")}
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 270,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                        title={comments}
                      >
                        {"Comments: "}
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 100,
                          }}
                        >
                          {comments && comments.length !== 0
                            ? comments
                            : "No Comments"}
                        </span>
                      </span>
                    </Col>
                  </Row>
                </>
              )}
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    let allNodes = [];
    let allEdges = [];
    Object.entries(flowDataState || {}).forEach(([key, level]) => {
      level.approverList.forEach((item, index) => {
        const matchingUser = userProfileDetails.find(
          (userData) =>
            userData.email ===
            (item.emailId.length > 1 ? item.emailId : item.emailId[0])
        );
        // Create initialNodes
        const labelContent = createLabel(
          item.approver,
          item.emailId,
          item.status,
          item.updated_on,
          item.comments ? item.comments : "",
          matchingUser?.profile_picture,
          matchingUser?.role
        );
        const node = {
          id: `${item.approver}`,
          levelInfo: `[${item.approver}]_[${key}_${index}]`,
          data: {
            label: labelContent,
          },
          type:
            item.from[0] === "START"
              ? "input"
              : item.to[0] === "END"
              ? "output"
              : "",
          isConnectable: false,
          position,
          className: "approver-node",
          style: {
            border:
              item.status === "Approved"
                ? "3px solid #03A800"
                : item.status === "Rejected"
                ? "3px solid #F2473B"
                : item.status === "Current Approver"
                ? "3px solid #0177FB"
                : "3px solid #626166",
            borderRadius: "100%",
            boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
          },
          labelStyle: {
            fontFamily: "Heebo, sans-serif",
            fontSize: "12px",
            color: "#333",
          },
        };
        allNodes.push(node);

        // Create initialEdges
        item.to.forEach((target) => {
          const edge = {
            id: `edge-${initialEdges.length + 1}`,
            source: item.approver,
            target,
            type: edgeType,
            arrowHeadType: "arrowclosed", // Arrowhead type for this edge
            isConnectable: false,
            style: {
              stroke:
                item.status === "Approved"
                  ? "#03A800"
                  : item.status === "Rejected"
                  ? "#F2473B"
                  : item.status === "Current Approver"
                  ? "#0177FB"
                  : "#626166",
              strokeWidth: 4,
            },
          };
          allEdges.push(edge);
        });
      });
    });

    setInitialNodes(allNodes);
    setInitialEdges(allEdges);
  }, [userProfileDetails]);

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 382;
  const nodeHeight = 266;

  const getLayoutedElements = (nodes, edges, direction = "LR") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes && initialNodes,
    initialEdges && initialEdges
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = (params) => {
    const sourceNode = initialNodes.find((el) => el.id === params.source);
    const targetNode = initialNodes.find((el) => el.id === params.target);

    if (!sourceNode.isConnectable || !targetNode.isConnectable) {
      return;
    }
    setEdges((eds) =>
      addEdge({ ...params, type: ConnectionLineType.SmoothStep }, eds)
    );
  };

  const onNodeClick = (event, node) => {
    console.log("Clicked node:", node);
    setClickedNode(node);

    const matches = node.levelInfo.match(/\[([^[\]]+)\]$/);
    let level, index;

    if (matches && matches.length > 1) {
      const [extractedLevel, extractedIndex] = matches[1].split("_");
      level = extractedLevel;
      index = extractedIndex;
    }

    const levelEmails = flowDataState[level]["approverList"][index].emailId;

    const preSelectedUsers = allAvailableUsers.filter((user) => {
      return (
        (user.email && levelEmails.includes(user.email)) ||
        (user.group_name && levelEmails.includes(user.group_name))
      );
    });

    const remainingUsers = allAvailableUsers.filter(
      (user) => !preSelectedUsers.includes(user)
    );

    setAvailableUsers(remainingUsers);
    setSelectedUsers(preSelectedUsers);
  };

  const handleEditApprover = () => {
    const newFlowData = JSON.parse(JSON.stringify(flowDataState));

    const matches = clickedNode.levelInfo.match(/\[([^[\]]+)\]$/);
    let level, index;

    if (matches && matches.length > 1) {
      const [extractedLevel, extractedIndex] = matches[1].split("_");
      level = extractedLevel;
      index = extractedIndex;
    }

    let oldApprovers = [];

    // Check if level and index are valid
    if (
      level &&
      index !== undefined &&
      newFlowData[level]?.approverList[index]
    ) {
      // Get the existing sublevel
      const existingSubLevel = newFlowData[level].approverList[index];

      oldApprovers = flowDataState[level].approverList[index].emailId;

      // If there's only one selected user, update the existing sublevel
      if (selectedUsers.length === 1) {
        const selectedUser = selectedUsers[0];
        if (selectedUser.email) {
          existingSubLevel.approver =
            selectedUser.first_name + " " + selectedUser.last_name;
          existingSubLevel.emailId = [selectedUser.email];
          existingSubLevel.userId = selectedUser._id;
        } else if (selectedUser.group_name) {
          existingSubLevel.approver = `Multiple Approvers_[${level}_${index}]`;
          existingSubLevel.emailId = [selectedUser.group_name];
          existingSubLevel.userId = null;
        }
      } else {
        const selectedUserEmails = [];
        selectedUsers.map((user) => {
          if (user.email) {
            selectedUserEmails.push(user.email);
          } else if (user.group_name) {
            selectedUserEmails.push(user.group_name);
          }
        });

        existingSubLevel.approver = `Multiple Approvers_[${level}_${index}]`;
        existingSubLevel.emailId = selectedUserEmails;
        existingSubLevel.userId = null;
      }
    } else {
      console.error("Invalid level or index");
    }

    // Iterate through all the levels to update "from"/"to" fields to reflect the new changes
    const levelsArray = Object.keys(newFlowData);
    levelsArray.forEach((level, index) => {
      let currentLevel = newFlowData[level];
      let nextLevel = levelsArray[index + 1]
        ? newFlowData[levelsArray[index + 1]]
        : null;

      // Iterate through sublevels of current level
      currentLevel.approverList.forEach((sublevel, subindex) => {
        if (index !== levelsArray.length - 1) {
          // Populate "to" field with approver names from next sublevel
          sublevel.to = nextLevel.approverList.map(
            (nextSublevel) => nextSublevel.approver
          );
        } else {
          sublevel.to = []; // Last level has no next level
        }

        if (index !== 0) {
          // Populate "from" field with approver names from previous sublevels
          sublevel.from = newFlowData[levelsArray[index - 1]].approverList.map(
            (prevSublevel) => prevSublevel.approver
          );
        } else {
          sublevel.from = ["Start"]; // First level has "Start"
        }
      });
    });

    const newApprovers = newFlowData[level].approverList[index].emailId;

    setSelectedUsers([]);
    setFlowDataState(newFlowData);
    updateWorkflowApi(contract_id, {
      contract_workflow_metadata: newFlowData,
      oldApprovers: oldApprovers,
      newApprovers: newApprovers,
    });
  };

  // const handleEditApprover = () => {
  //   const newFlowData = { ...flowDataState };

  //   const matches = clickedNode.levelInfo.match(/\[([^[\]]+)\]$/);
  //   let level, index;

  //   if (matches && matches.length > 1) {
  //     const [extractedLevel, extractedIndex] = matches[1].split("_");
  //     level = extractedLevel;
  //     index = extractedIndex;
  //   }

  //   // Check if level and index are valid
  //   if (level && index !== undefined) {
  //     // Get the existing sublevel
  //     const existingSubLevel = newFlowData[level][index];

  //     // If there's only one selected user, update the existing sublevel
  //     if (selectedUsers.length === 1) {
  //       const selectedUser = selectedUsers[0];
  //       if (selectedUser.email) {
  //         existingSubLevel.approver =
  //           selectedUser.first_name + " " + selectedUser.last_name;
  //         existingSubLevel.emailId = [selectedUser.email];
  //         existingSubLevel.userId = selectedUser._id;
  //       } else if (selectedUser.group_name) {
  //         existingSubLevel.approver = `Multiple Approvers_[${level}_${index}]`;
  //         existingSubLevel.emailId = [selectedUser.group_name];
  //         existingSubLevel.userId = null;
  //       }
  //     } else {
  //       const selectedUserEmails = [];
  //       selectedUsers.map((user) => {
  //         if (user.email) {
  //           selectedUserEmails.push(user.email);
  //         } else if (user.group_name) {
  //           selectedUserEmails.push(user.group_name);
  //         }
  //       });

  //       existingSubLevel.approver = `Multiple Approvers_[${level}_${index}]`;
  //       existingSubLevel.emailId = selectedUserEmails;
  //       existingSubLevel.userId = null;
  //     }
  //   } else {
  //     console.error("Invalid level or index");
  //   }

  //   // Iterate through all the levels to update "from"/"to" fields to reflect the new changes
  //   Object.keys(newFlowData).forEach((level, index, levelsArray) => {
  //     let currentLevel = newFlowData[level];
  //     let nextLevel = levelsArray[index + 1]
  //       ? newFlowData[levelsArray[index + 1]]
  //       : null;

  //     // Iterate through sublevels of current level
  //     currentLevel.forEach((sublevel, subindex) => {
  //       if (index !== levelsArray.length - 1) {
  //         // Populate "to" field with approver names from next sublevel
  //         sublevel.to = nextLevel.map((nextSublevel) => nextSublevel.approver);
  //       } else {
  //         sublevel.to = []; // Last level has no next level
  //       }

  //       if (index !== 0) {
  //         // Populate "from" field with approver names from previous sublevels
  //         sublevel.from = newFlowData[levelsArray[index - 1]].map(
  //           (prevSublevel) => prevSublevel.approver
  //         );
  //       } else {
  //         sublevel.from = ["Start"]; // First level has "Start"
  //       }
  //     });
  //   });

  //   console.log("newFlowData: ", newFlowData);

  //   setSelectedUsers([]);
  //   setFlowDataState(newFlowData);
  //   updateWorkflowApi(contract_id, {
  //     contract_workflow_metadata: newFlowData,
  //   });
  // };

  const showdocuSignModal = () => {
    setDocuSignModal(true);
  };

  const showdocuSignModalWarn = () => {
    setDocuSignModalWarn(true);
  };

  const handleCancel = () => {
    setDocuSignModal(false);
    setDocuSignModalWarn(false);
  };

  const reTriggerDocuSignFlow = async () => {
    console.log("Re-triggering the DocuSign flow...");
    try {
      const contractId = selectedFile?._id;
      const response = await reTriggerDocuSignFlowApi(contractId);
      console.log("Re-trigger DocuSign response:", response);
    } catch (error) {
      console.error("Error re-triggering docusign flow:", error);
    }
  };

  // const showdocuSignModalWarn = () => {
  //   message.warning("Contract not ready for signature");
  // };

  return (
    <>
      <div
        className={
          from === "contract-pipeline-view"
            ? "react-flow-pipeline"
            : from === "create-contract-view"
            ? "react-flow-create-contract"
            : "react-flow-main"
        }
      >
        <ReactFlow
          proOptions={{ hideAttribution: true }}
          nodes={initialNodes}
          edges={initialEdges}
          onNodeClick={onNodeClick}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.SmoothStep}
          nodesDraggable={false}
          fitView
          fitViewOptions={{
            minZoom: -1,
            maxZoom: 0.7,
            padding: 0.1,
            animate: true,
          }}
        >
          {from !== "workflow-view" && (
            <>
              <Panel
                position="top-right"
                style={{
                  backgroundColor: "#fff",
                  padding: 5,
                  borderRadius: 5,
                  border: "1px solid var(--color-solid-lightgrey)",
                }}
              >
                <Space>
                  <div>
                    <span className="approved"></span>&nbsp;&nbsp;
                    <span>Approved</span>
                  </div>
                  <div>
                    <span className="rejected"></span>&nbsp;&nbsp;
                    <span>Rejected</span>
                  </div>
                  <div>
                    <span className="current-approver"></span>&nbsp;&nbsp;
                    <span>Current Approver</span>
                  </div>
                  <div>
                    <span className="queue"></span>&nbsp;&nbsp;
                    <span>In Queue</span>
                  </div>
                </Space>
              </Panel>
              <Panel
                position="top-left"
                style={{
                  backgroundColor: "#fff",
                  padding: 5,
                  borderRadius: 5,
                  border: "1px solid var(--color-solid-lightgrey)",
                }}
                className="ml-10"
              >
                <Space>
                  {selectedFile ? (
                    selectedFile.contract_status === "Signature-Rejected" ? (
                      <div>
                        <Button
                          onClick={showdocuSignModalWarn}
                          style={{ cursor: "pointer" }}
                        >
                          Contract Rejected
                        </Button>
                        <Modal
                          title={
                            <div style={{ textAlign: "center", width: "100%" }}>
                              Contract Status
                            </div>
                          }
                          open={docuSignModalWarn}
                          onCancel={handleCancel}
                          footer={null}
                        >
                          <div style={{ textAlign: "center" }}>
                            This contract was rejected and is no longer
                            available for signing.
                            {userDataRecoil &&
                              userDataRecoil[0] &&
                              userDataRecoil[0].id &&
                              userDataRecoil[0].id ===
                                selectedFile?.created_by && (
                                <div style={{ marginTop: "20px" }}>
                                  <a
                                    onClick={reTriggerDocuSignFlow}
                                    style={{
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    }}
                                  >
                                    Re-trigger the DocuSign flow
                                  </a>
                                </div>
                              )}
                          </div>
                          <DocuSignRecipients selectedFile={selectedFile} />
                        </Modal>
                      </div>
                    ) : selectedFile.contract_status ===
                        "Contract-in-Execution" ||
                      selectedFile.contract_status === "Sent for Signature" ||
                      selectedFile.contract_status === "Contract-Signed" ? (
                      <div>
                        <Button
                          onClick={showdocuSignModal}
                          style={{ cursor: "pointer" }}
                        >
                          Recipient Status
                        </Button>
                        <Modal
                          title={
                            <div style={{ textAlign: "center", width: "100%" }}>
                              eSign Contract
                            </div>
                          }
                          open={docuSignModal}
                          onCancel={handleCancel}
                          footer={null}
                        >
                          <DocuSignRecipients selectedFile={selectedFile} />
                        </Modal>
                      </div>
                    ) : (
                      <div>
                        <Button
                          onClick={showdocuSignModalWarn}
                          style={{ cursor: "pointer" }}
                        >
                          Sign Contract
                        </Button>
                        <Modal
                          title={
                            <div style={{ textAlign: "center", width: "100%" }}>
                              DocuSign
                            </div>
                          }
                          open={docuSignModalWarn}
                          onCancel={handleCancel}
                          footer={null}
                        >
                          Contract is not yet ready for signing.
                        </Modal>
                      </div>
                    )
                  ) : selectedFile === undefined ? (
                    <></>
                  ) : (
                    ""
                  )}
                </Space>
              </Panel>
            </>
          )}
          {/* <Panel position="top-right">
            <Space>
              <Button onClick={() => onLayout("LR")}>Horizontal Layout</Button>
              <Button onClick={() => onLayout("TB")}>Vertical Layout</Button>
            </Space>
          </Panel> */}
        </ReactFlow>
        {approverEditor && (
          <Modal
            width={600}
            className="approver-edit-modal"
            centered
            open={approverEditor}
            onOk={() => {
              setApproverEditor(false);
            }}
            onCancel={() => {
              setSelectedUsers([]);
              setAvailableUsers(allAvailableUsers);
              setApproverEditor(false);
            }}
            closable={true}
            footer={null}
          >
            <div>
              <h3>Add User/Email-ID</h3>
              <span className="approver-editor-head">
                Select User Name or Email-ID
              </span>
            </div>
            <Row type="flex">
              <Col span={24} className="mt-20">
                <Select
                  showSearch
                  placeholder="Search for Usernames or Email-IDs"
                  optionFilterProp="children"
                  onSearch={handleSearch}
                  onChange={handleUserSelect}
                  filterOption={false}
                  style={{ width: "100%" }}
                  value={null}
                  options={availableUsers.map((user) => ({
                    value: user._id,
                    label: user.email ? (
                      <div style={{ display: "flex" }}>
                        <div className="user-select-option-type">User</div>
                        <span>
                          {`${user.first_name} ${user.last_name}`} &nbsp;
                        </span>
                        <span
                          style={{ color: "var(--color-solid-darkergrey)" }}
                        >
                          &#8226; &nbsp;{user.email}
                        </span>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div className="group-select-option-type">Group</div>
                        {`${user.group_name}`}
                      </div>
                    ),
                  }))}
                />
              </Col>
            </Row>
            <div className="mt-20">
              <span className="approver-editor-head">List of Users to add</span>
            </div>
            <div className="approver-list p-10">
              <Row gutter={[8, 16]} type="flex">
                <Col span={24}>
                  {selectedUsers &&
                    selectedUsers.map((user) => {
                      return user.email ? (
                        <Tag
                          key={user._id}
                          className="user-approver-tag"
                          closable={selectedUsers.length > 1 ? true : false}
                          icon={<UserOutlined />}
                          onClose={() => handleTagClose(user._id)}
                        >
                          {`${user.first_name + " " + user.last_name}`}
                        </Tag>
                      ) : (
                        <Tag
                          key={user._id}
                          className="group-approver-tag"
                          closable={selectedUsers.length > 1 ? true : false}
                          icon={<UserOutlined />}
                          onClose={() => handleTagClose(user._id)}
                        >
                          {`${user.group_name}`}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>
            </div>
            <Row type="flex" align="middle">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={6} style={{ marginRight: 5 }}>
                  <CancelButton
                    onClick={() => {
                      setApproverEditor(false);
                      setAvailableUsers(allAvailableUsers);
                      setSelectedUsers([]);
                    }}
                  />
                </Col>
                <Col span={6} style={{ marginLeft: 5 }}>
                  <OkButton
                    onClick={() => {
                      setApproverEditor(false);
                      handleEditApprover();
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </Modal>
        )}
      </div>
    </>
  );
};

export default FlowDiagram;
