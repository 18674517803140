import React, { useEffect, useState } from "react";
import { Image, Row, Col, Table, Modal, Pagination } from "antd";
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import edit from "../assets/images/icons/edit_pen.svg";
import deleteIcon from "../assets/images/icons/delete.svg";
import account_box from "../assets/images/icons/account_box.svg";
import "../assets/style/contractpipeline.css";
import "../assets/style/setup.css";
import { getUsersPaginationApi, deleteUserApi } from "../api/usersApi";
import { createActivityLogApi } from "../api/logApi";
import { userDataAtom, HeaderChangerAtom } from "../store/store";
import { useRecoilState } from "recoil";
import CreateUser from "../components/Users/CreateUser";
import PopupModal from "../PopupModal";
import ModalMessage from "../ModalMessage";
import { CreateButton } from "../components/GlobalButton";
import { useNavigate } from "react-router-dom";
import { getGroupByUser } from "../api/groupsApi";
import { gettRootActionByUser } from "../api/actionManagementApi";
import GlobalFilter from "../components/GlobalFilter";

const UserManagement = ({ tabKey }) => {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  // State variable to hold  header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // State variable to hold table data
  const [tableData, setTableData] = useState([]);
  // State variable to hold filter success modal visibility status
  const [open, setOpen] = useState(false);
  // State variable to hold loder  visibility status
  const [loader, setLoader] = useState(false);
  // Variable to use navigate function
  const navigate = useNavigate();
  //  State variables for pagination
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold filter data
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    listUsers(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };
  /*
   * Function to handle the get all user data with pagination
   * @param {Number} page - The number of pages
   * @param {Number} size - The number of items per page
   * @param {String} sortOn - The field on which sorting is done
   */
  const listUsers = (page, limit, allFilterVal, sortColumn) => {
    setLoader(true);
    getUsersPaginationApi(page, limit, allFilterVal, sortColumn).then((res) => {
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      console.log("res---", res.docs);
      let userMaster = res.docs.map((data) => {
        let tableRow = {
          key: data._id,
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "userManagement",
                  headerItem: "2",
                  headerItemName: "userManagement",
                  stateSetter: data,
                });
                let ssLog = {
                  ss_id: data.id ? data.id : data._id,
                  ss_name: data.first_name,
                  code: "users",
                  author: userData[0].id,
                  action: "view",
                  notes: "Viewed User",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {});
                navigate(`/user-management/user/view`, {
                  state: data,
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.first_name + " " + data.last_name}
            </span>
          ),
          email: data.email,
          role: data.roleData.length !== 0 && data.roleData[0].role_name,
          isEmailVerified: data.isEmailVerified,
          status: data.status,
          line_manager: data.line_manager,
          profile_picture: data.profile_picture,
          action: (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <EyeOutlined
                title="View"
                style={{
                  fontSize: "20px",
                  color: "var(--color-solid-darkgrey)",
                }}
                className="cursor-pointer"
                onClick={() => {
                  setHeaderChanger({
                    header: "userManagement",
                    headerItem: "2",
                    headerItemName: "userManagement",
                    stateSetter: data,
                  });
                  let ssLog = {
                    ss_id: data.id ? data.id : data._id,
                    ss_name: data.first_name,
                    code: "users",
                    author: userData[0].id,
                    action: "view",
                    notes: "Viewed User",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {});
                  navigate(`/user-management/user/view`, {
                    state: data,
                  });
                }}
              />
              <Image
                title="Edit"
                src={edit}
                preview={false}
                style={{ width: "20px" }}
                className="cursor-pointer"
                onClick={() => {
                  setHeaderChanger({
                    header: "userManagement",
                    headerItem: "2",
                    headerItemName: "userManagement",
                    stateSetter: data,
                  });
                  navigate(`/user-management/user/edit`, {
                    state: data,
                  });
                }}
              />
              <Image
                title="Delete"
                src={deleteIcon}
                style={{ width: "20px" }}
                preview={false}
                onClick={() => {
                  onClickDelete(data);
                  console.log("dats deteled");
                }}
                className="cursor-pointer"
              />
            </div>
          ),
        };
        return tableRow;
      });
      setTableData(userMaster);
    });
  };

  const onClickDelete = async (data) => {
    let allAssociations = [];
    await getGroupByUser(data.email).then((res) => {
      console.log("group res", res);
      allAssociations.push(...res);
    });
    await gettRootActionByUser(data._id).then((res) => {
      console.log("action res", res);
      allAssociations.push(...res);
    });
    // await getWorkflowByUser(data.email).then((res) => {
    //   console.log("wf res", res);
    // allAssociations.push(...res);
    // });

    if (allAssociations.length === 0) {
      popupModalInstance
        .confirModal(ModalMessage.User_Delete_Info)
        .then(async (userchoice) => {
          if (userchoice.action === "delete") {
            deleteUser(
              data._id,
              `${data.first_name} ${data.last_name}`,
              userchoice.inputValue
            );
          }
        });
    } else {
      popupModalInstance.warningModal(ModalMessage.User_Delete_Warning);
    }
  };

  /* Function to handle delete the user
   *  @param {string} id - The unique identifier for each user in the database
   *  @param {string} name - The full name of the user that is going to be deleted
   *  @param {string} reason - The input name must been matched user name
   */
  const deleteUser = (userId, userName, inputUserName) => {
    if (inputUserName === userName) {
      deleteUserApi(userId).then((res) => {
        let umLog = {
          ss_id: userId,
          ss_name: userName,
          code: "users",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted User",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog).then((res) => {});
        popupModalInstance
          .successModal(userName, ModalMessage.User_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              listUsers(1, 20, {}, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.User_Delete_Error);
    }
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // variable to hold column headings
  const allColumns = [
    {
      title: renderColumnTitle("Name", "name"),
      dataIndex: "name",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("name") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Email", "email"),
      dataIndex: "email",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("email") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Role", "role"),
      dataIndex: "role",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("role") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Line Manager", "line_manager"),
      dataIndex: "line_manager",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("line_manager") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
      width: 100,
    },
    {
      title: "isEmailVerified",
      dataIndex: "isEmailVerified",
      align: "left",
      width: 150,
    },
  ];

  // variable to hold filter the all columns (out of isEmailVerified)
  let columns = allColumns.filter((col) => col.dataIndex !== "isEmailVerified");

  /*
   * Function to handle once email id verified is false it apply customize css in row
   *@ param record - selector imformation
   *@ param index -
   */
  const rowClassName = (record, index) => {
    if (record.isEmailVerified === false) {
      return "custom-row-style";
    }
    return "";
  };

  /* Funstion to handle page change to get a user data
   * @param page - page number
   * @param limit - number of rows per page
   */
  const onPageChange = (page, limit) => {
    listUsers(page, limit, allFilterVal, sortColumnOptions);
  };

  /* Function to handle to set true the message api success modal
   */
  const showModal1 = () => {
    setOpen(true);
  };

  /* Function to handle to set false the message api success modal
   */
  const handleCancel = () => {
    setOpen(false);
  };

  /* Function to handle select the filter from list and show its details
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      listUsers(1, 20, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        listUsers(1, 20, allFilterVal, sortColumnOptions);
      } else {
        listUsers(1, 20, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "User Management | Judo";
  }, []);

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={15}
          lg={15}
          xl={22}
        >
          <GlobalFilter
            selectedTab={"users"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        <Col className="vertical-align" xs={24} sm={24} md={2} lg={2} xl={2}>
          <CreateButton type="primary" onClick={showModal1} />
          {open && (
            <Modal
              title={
                <span style={{ display: "flex" }}>
                  <Image
                    src={account_box}
                    preview={false}
                    style={{ width: 25, height: 25 }}
                  />
                  &nbsp;&nbsp;
                  <p style={{ fontSize: "17px", paddingTop: 1 }}>
                    Creating User
                  </p>
                </span>
              }
              open={open}
              centered
              footer={null}
              onCancel={handleCancel}
            >
              <CreateUser setOpen={setOpen} listUsers={listUsers} />
            </Modal>
          )}
        </Col>
      </Row>
      <div className="mt-10">
        <Table
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columns}
          rowClassName={rowClassName}
          onChange={handleTableChange}
          bordered
          size="large "
          loading={loader}
          pagination={false}
          scroll={
            tableData.length === 0
              ? {}
              : { x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }
          }
          className="system-setup-table mt-10"
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
