import React, { useState, useEffect } from "react";
import { Image, Layout, Tabs } from "antd"; //antd components
import { useSearchParams, useLocation } from "react-router-dom";
import TopHeader from "../components/Header";
import "../assets/style/actionmanagement.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { HeaderChangerAtom } from "../store/store";
import { SupplierTreeDataAtom, userDataAtom } from "../store/store";
import RootActions from "../components/ActionManagement/RootAction/RootActions";
import ActionInstance from "../components/ActionManagement/ActionInstance/ActionInstance";
import { suppliersTreeApi, treeDataApi } from "../api/suppliersApi";

function ActionManagement() {
  const [searchParams] = useSearchParams();
  let backToTab = searchParams.get("view");
  const menuKey = "2";
  let { state } = useLocation(); //state data which is sent from parent component

  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const setSupplierTreeData = useSetRecoilState(SupplierTreeDataAtom);
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  console.log(headerChanger);
  let header = headerChanger.header;
  const tabkey = headerChanger.headerItem;

  // const [tabkey, setTabKey] = useState(location.state ? location.state : "1");
  const [screenSize, setScreenSize] = useState("min");

  useEffect(() => {
    treeDataApi(userData[0].id, "ContractTree").then((res) => {
      console.log("tree res: ", res);
      setSupplierTreeData(res);
    });
  }, []);

  return (
    <div className="contract">
      <Layout className="header-layout">
        <div style={{ display: screenSize === "min" ? "block" : "none" }}>
          <TopHeader
            headerChange={header}
            feedRow={false}
            searchRow={false}
            menuKey={menuKey}
            sticky=""
          />
          <div className="contract-tab-content">
            {tabkey === "1" && (
              <div className="tree-div">
                <RootActions />
              </div>
            )}

            {tabkey === "2" && (
              <div>
                <ActionInstance />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ActionManagement;
