import React, { useState } from "react";
import { Layout, Row, Col, Card, Image, Input, Form } from "antd";
import TopHeader from "../../Header";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../../../store/store";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import { CancelButton, SubmitButton, SaveButton } from "../../GlobalButton";
import { useNavigate, useLocation } from "react-router-dom";
import {
  saveTemplateApi,
  updateTemplateApi,
} from "../../../api/contractAuthorityApi";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import Quill from "quill";
import { userDataAtom } from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";

function OnlinePreview() {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);

  const navigate = useNavigate();
  const menuKey = "5";
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  const userData = useRecoilState(userDataAtom);
  const popupModalInstance = PopupModal();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [stateButton, setStateButton] = useState(1);
  console.log("state", state);
  let header = headerChanger.header;
  const onFinish = async (values) => {
    console.log("values", values);
    Object.keys(state).forEach((key) => {
      if (!state[key]) {
        state[key] = "null";
      }
    });

    values = {
      ...state,
      clause_content:
        state.clause_content.length > 0 ? state.clause_content : [],
    };
    if (!state["updated_by"]) {
      state["updated_on"] = new Date();
    }
    if (!state["updated_by"]) {
      state["updated_by"] = state.user_id;
    }
    values["msa_name"] = state.msa_name;
    console.log("values", values);
    try {
      if (stateButton === 1 || stateButton === 2) {
        values.msa_name && delete values.msa_name;
        values.supplier_name && delete values.supplier_name;
        values.checkedList && delete values.checkedList;
        values.selectedContent && delete values.selectedContent;
        values.selectedHeader && delete values.selectedHeader;
        values.selectedBatches && delete values.selectedBatches;
        values.finalClause && delete values.finalClause;
      }
      if (!values["status"]) {
        if (stateButton === 1) {
          values["status"] = "Draft";
          console.log("values", values);

          await saveTemplateApi(values).then((res) => {
            let ssLog = {
              ss_id: res.data.id ? res.data.id : res.data._id,
              ss_name: values.contract_template_name,
              code: "template",
              author: userData[0].id,
              action: "view",
              notes: "saved contract template",
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            console.log("res-draft", res);
            // setTableLoader(false);
            popupModalInstance.successModal(
              state.contract_template_name,
              ModalMessage.CA_TE_Save
              // `/contract_authority/template`
            );
          });
        } else if (stateButton === 2) {
          console.log("checking submit");
          values["status"] = "Active";
          popupModalInstance
            .infoModal(ModalMessage.TEM_Create_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                console.log("checking ok");
                await saveTemplateApi(values).then((res) => {
                  let ssLog = {
                    ss_id: res.data.id ? res.data.id : res.data._id,
                    ss_name: values.contract_template_name,
                    code: "template",
                    author: userData[0].id,
                    action: "view",
                    notes: "submited contract template",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog);
                  console.log("res-draft", res);
                  // setTableLoader(false);
                  popupModalInstance.successModal(
                    state.contract_template_name,
                    ModalMessage.CA_TE_Submit,
                    `/contract_authority/template`
                  );
                });
              } else if (userChoice === "cancel") {
                setSubmitLoader(false);
              }
            });
        }
      } else {
        if (stateButton === 1) {
          updateTemplateApi(state.id ? state.id : state._id, values)
            .then((res) => {
              console.log("resss", res);
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values.contract_template_name,
                code: "template",
                author: userData[0].id,
                action: "edit",
                notes: "saved contract template",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              popupModalInstance.successModal(
                "",
                ModalMessage.TEM_Edit_Info,
                "/contract_authority/template"
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (stateButton === 2) {
          const userChoice = await popupModalInstance.infoModal(
            ModalMessage.TEM_Edit_Submit
          );
          if (userChoice === "ok") {
            await updateTemplateApi(
              state.id ? state.id : state._id,
              values
            ).then((res) => {
              console.log("values", values);

              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values.contract_template_name,
                code: "template",
                author: userData[0].id,
                action: "edit",
                notes: "submited contract template",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              popupModalInstance.successModal(
                "",
                ModalMessage.TEM_Edit_Info,
                "/contract_authority/template"
              );
            });
          } else if (userChoice === "cancel") {
            setSubmitLoader(false);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderContent = (pdfObject) => {
    if (typeof pdfObject.p === "object") {
      const quill = new Quill(document.createElement("div"));
      quill.setContents(pdfObject.p);
      return quill.root.innerHTML;
    }

    // If the content contains an image object
    if (pdfObject.p.includes("img")) {
      return pdfObject.p;
    }

    return pdfObject.p;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <Form onFinish={onFinish} onKeyDown={handleKeyDown}>
        <div className="system-setup1">
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={18}
            >
              <Card className="setup-header-card">
                <Row type="flex" gutter={[8, 16]}>
                  <Col
                    className="vertical-align"
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={2}
                  >
                    <Row type="flex" gutter={[8, 16]}>
                      <Col span={12}>
                        <Image
                          src={arrow_left}
                          style={{ width: 25 }}
                          preview={false}
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`/contract_authority/online/template`, {
                              state: { ...state },
                            })
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Image
                          src={arrow_right_disable}
                          style={{ width: 20 }}
                          preview={false}
                          className="cursor-not-allowed"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={17}
                    sm={17}
                    md={19}
                    lg={19}
                    xl={22}
                  >
                    <span>Contract Authority</span> &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/contract_authority/online/template`, {
                          state: { ...state },
                        })
                      }
                    >
                      Templates
                    </span>
                    &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span>Create Templates</span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <CancelButton
                onClick={() => navigate(`/contract_authority/template`)}
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SaveButton
                type="primary"
                htmlType="submit"
                onClick={() => setStateButton(1)}
              />
            </Col>
            <Col className="vertical-align" xs={8} sm={4} md={3} lg={3} xl={2}>
              <SubmitButton
                type="primary"
                htmlType="submit"
                onClick={() => setStateButton(2)}
              />
            </Col>
          </Row>
          <div className="price-profile-content m-10">
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
              <Col span={24}>
                <div className="create-supplier-div">
                  <Col span={12} offset={6}>
                    <div className="preview-div mb-10">
                      <div className="template-head mb-25">
                        {state && state.contract_template_name}
                      </div>
                      {state &&
                        state.clause_content.map((pdfObject, index) => {
                          const content = renderContent(pdfObject);
                          return (
                            <div className="mt-15" key={index}>
                              <h4>{pdfObject.h}</h4>
                              <div
                                className="mt-10"
                                dangerouslySetInnerHTML={{ __html: content }}
                              ></div>
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Layout>
  );
}

export default OnlinePreview;
