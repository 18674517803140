import { logDOM } from "@testing-library/react";
import { axiosInstance } from "../utils/common";

export const getAllRatecardApi = () => {
  return axiosInstance
    .get("v1/ratecards")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getRatecardPagination = (
  userId,
  page,
  limit,
  searchVal,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(
      `v1/ratecards/pagination/${searchVal}?userId=${userId}&limit=${limit}&page=${page}`,
      {
        allFilterVal: filterObj,
        sortColumn,
      }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getRatecardOnlyApi = () => {
  return axiosInstance
    .get(`v1/ratecards/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleRateCardDataApi = (ratecardID) => {
  return axiosInstance
    .get(`v1/ratecards/single/${ratecardID}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getRatecardReportApi = (ratecardID) => {
  return axiosInstance
    .get(`v1/ratecards/report/${ratecardID}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createRatecardAPi = (ratecardDetail) => {
  return axiosInstance
    .post("v1/ratecards", ratecardDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const editRatecardAPi = (ratecardId, ratecardDetail) => {
  return axiosInstance
    .patch(`v1/ratecards/${ratecardId}`, ratecardDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editRateTableDataAPi = (rateTableId, rateTableDetail) => {
  return axiosInstance
    .put(`v1/ratecards/table/${rateTableId}`, rateTableDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getRatecardBySupplier = (supplierId) => {
  return axiosInstance
    .get(`v1/ratecards/get/ratecard/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete rc by ID
export const deleteRCApi = (rcId) => {
  return axiosInstance
    .delete(`v1/ratecards/${rcId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllRateCardFiltersApi = (userId) => {
  return axiosInstance
    .get(`v1/ratecards/filters/all?userId=${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
