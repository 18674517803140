// import React, { useState, useEffect } from "react";
// import { Upload, Button, Form, message } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import axios from "axios";
// import { useLocation } from "react-router-dom";
// import { useRecoilState } from "recoil";
// import { DocuSignUri } from "../store/store";
// import "../assets/style/uploaddocument.css";
// import DocuSignStatus from "./DocuSignStatus";
// import { docuSignData } from "../utils/common";

// const UploadDocument = ({ signersLists, selectedFile }) => {
//   const [fileList, setFileList] = useState([]);
//   //   const [recipientName, setRecipientName] = useState("");
//   //   const [recipientEmail, setRecipientEmail] = useState("");
//   const [accessToken, setAccessToken] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [approversList, setApproversList] = useState(signersLists);
//   const [envelopeId, setEnvelopeId] = useState(null);
//   const [authorizeCode, setAuthorizeCode] = useState();
//   const s3Uri = useRecoilState(DocuSignUri);
//   console.log("s3Uri", s3Uri);

//   const location = useLocation();

//   const startDocuSignFlow = () => {
//     const authUrl = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=${docuSignData.scope}&client_id=${docuSignData.integrationKey}&redirect_uri=${docuSignData.redirectUri}`;
//     window.location.href = authUrl;
//   };

//   console.log("approversList", approversList);
//   console.log("selectedFile", selectedFile);
//   // console.log("sendS3File", sendS3File);

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const authorizationCode = params.get("code");
//     console.log("authorizationCode", authorizationCode);
//     setAuthorizeCode(authorizationCode);

//     if (authorizationCode) {
//       const fetchAccessToken = async () => {
//         try {
//           await axios
//             .get(
//               `http://localhost:4000/api/v1/docusign/callback?code=${authorizationCode}`
//             )
//             .then((res) => {
//               console.log("ressss", res);
//               setAccessToken(res.data.accessToken);
//               message.success("You are ready to progress");
//             });
//           console.log("authorizationCodeeee", authorizationCode);
//         } catch (error) {
//           console.error("Error fetching access token:", error);
//           message.error("Failed to obtain access token");
//         }
//       };
//       fetchAccessToken();
//     }
//   }, [location.search]);
//   console.log("accessToken", accessToken);

//   const handleUpload = async () => {
//     // if (!fileList.length) {
//     //   message.error("Please upload a file before submitting.");
//     //   return;
//     // }

//     if (!s3Uri) {
//       message.error("No file selected from S3.");
//       return;
//     }

//     if (!accessToken) {
//       message.error("Please complete the OAuth flow to get the access token.");
//       return;
//     }

//     const formData = new FormData();
//     // formData.append("document", fileList[0].originFileObj);
//     // formData.append("recipientName", recipientName);
//     // formData.append("recipientEmail", recipientEmail);
//     formData.append("fileUrl", s3Uri[0][0].uri); // S3 file URL
//     formData.append("fileType", s3Uri[0][0].fileType); // S3 file type
//     formData.append("accessToken", accessToken);
//     formData.append("approversList", JSON.stringify(approversList));

//     setIsLoading(true);
//     try {
//       const response = await axios.post(
//         "http://localhost:4000/api/v1/docusign/sign-document",
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       console.log("response", response);
//       message.success("Document sent for signing!");
//       setEnvelopeId(response.data.envelopeId);
//     } catch (error) {
//       console.error(error);
//       message.error("Failed to send document.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   console.log("envelopeId", envelopeId);

//   const handleChange = ({ fileList }) => setFileList(fileList);

//   return (
//     <div className="center-container">
//       <div className="form-container">
//         {/* <Form layout="vertical" onFinish={handleUpload} className="mt-20">
//           <Form.Item>
//             <Button
//               target="_blank"
//               onClick={startDocuSignFlow}
//               className="sign-document-btn"
//               block
//             >
//               Sign Document with DocuSign
//             </Button>
//           </Form.Item>

//           <Form.Item label="Upload Document" required>
//             <Upload
//               beforeUpload={() => false}
//               fileList={fileList}
//               onChange={handleChange}
//               className="doc-upload-btn"
//               maxCount={1}
//             >
//               <Button icon={<UploadOutlined />} block>
//                 Select File
//               </Button>
//             </Upload>
//           </Form.Item>

//           <Form.Item>
//             <Button type="primary" htmlType="submit" loading={isLoading} block>
//               Send Document for Signing
//             </Button>
//           </Form.Item>
//         </Form> */}
//         <Form layout="vertical" onFinish={handleUpload} className="mt-20">
//           <Form.Item>
//             <Button
//               target="_blank"
//               onClick={startDocuSignFlow}
//               className="sign-document-btn"
//               block
//             >
//               Sign Document with DocuSign
//             </Button>
//           </Form.Item>
//           <Form.Item>
//             <Button
//               target="_blank"
//               // onClick={() => window.open(s3Uri[0][0].uri, "_blank")}
//               onClick={() => {
//                 const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
//                   s3Uri[0][0].uri
//                 )}&embedded=true`;
//                 window.open(googleDocsViewerUrl, "_blank");
//               }}
//               className="sign-document-btn"
//               block
//             >
//               Preview S3 File
//             </Button>
//           </Form.Item>

//           <Form.Item>
//             <Button type="primary" htmlType="submit" loading={isLoading} block>
//               Send Document for Signing
//             </Button>
//           </Form.Item>
//         </Form>
//         {accessToken && envelopeId && (
//           <DocuSignStatus accessToken={accessToken} envelopeId={envelopeId} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default UploadDocument;

// import React, { useState, useEffect } from "react";
// import { Button, Form, message, Modal } from "antd";
// import axios from "axios";
// import { useLocation } from "react-router-dom";
// import { useRecoilState } from "recoil";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import { DocuSignUri } from "../store/store";
// import "../assets/style/uploaddocument.css";
// import DocuSignStatus from "./DocuSignStatus";
// import { docuSignData } from "../utils/common";

// const UploadDocument = ({ signersLists }) => {
//   const [accessToken, setAccessToken] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [approversList, setApproversList] = useState(signersLists);
//   const [envelopeId, setEnvelopeId] = useState(null);
//   const [authorizeCode, setAuthorizeCode] = useState();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const s3Uri = useRecoilState(DocuSignUri);
//   const location = useLocation();
//   console.log("s3Uri", s3Uri);

//   const startDocuSignFlow = () => {
//     const authUrl = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=${docuSignData.scope}&client_id=${docuSignData.integrationKey}&redirect_uri=${docuSignData.redirectUri}`;
//     window.location.href = authUrl;
//   };
//   console.log("approversList", approversList);

//   useEffect(() => {
//     // Extract the authorization code from the URL after the OAuth flow
//     const params = new URLSearchParams(location.search);
//     const authorizationCode = params.get("code");
//     setAuthorizeCode(authorizationCode);

//     if (authorizationCode) {
//       const fetchAccessToken = async () => {
//         try {
//           const response = await axios.get(
//             `http://localhost:4000/api/v1/docusign/callback?code=${authorizationCode}`
//           );
//           setAccessToken(response.data.accessToken);
//           message.success("You are ready to proceed");
//         } catch (error) {
//           console.error("Error fetching access token:", error);
//           message.error("Failed to obtain access token");
//         }
//       };
//       fetchAccessToken();
//     }
//   }, [location.search]);

//   const handleSendDocument = async () => {
//     if (!s3Uri || !accessToken) {
//       message.error("Missing required information.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("fileUrl", s3Uri[0][0].uri);
//     // formData.append("fileType", s3Uri[0][0].fileType);
//     formData.append("accessToken", accessToken);
//     formData.append("approversList", JSON.stringify(approversList));

//     setIsLoading(true);
//     try {
//       const response = await axios.post(
//         "http://localhost:4000/api/v1/docusign/sign-document",
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       message.success("Document sent for signing!");
//       setEnvelopeId(response.data.envelopeId);
//     } catch (error) {
//       console.error(error);
//       message.error("Failed to send document.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handlePreviewFile = () => {
//     setIsModalVisible(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const documentPreviewModal = (
//     <Modal
//       title="Document Preview"
//       open={isModalVisible}
//       onCancel={handleCancel}
//       footer={null}
//       width={800}
//     >
//       {s3Uri && s3Uri[0] && s3Uri[0][0] ? (
//         <DocViewer
//           documents={[{ uri: s3Uri[0][0].uri, fileType: "application/pdf" }]}
//           pluginRenderers={DocViewerRenderers}
//         />
//       ) : (
//         <p>No document available to preview</p>
//       )}
//     </Modal>
//   );

//   return (
//     <div className="center-container">
//       <div className="form-container">
//         <Form layout="vertical" onFinish={handleSendDocument} className="mt-20">
//           <Form.Item>
//             <Button
//               target="_blank"
//               onClick={startDocuSignFlow}
//               className="sign-document-btn"
//               block
//             >
//               Sign Contract with DocuSign
//             </Button>
//           </Form.Item>
//           <Form.Item>
//             <Button
//               target="_blank"
//               onClick={handlePreviewFile}
//               className="sign-document-btn"
//               block
//             >
//               View Original Contract
//             </Button>
//           </Form.Item>
//           <Form.Item>
//             <Button type="primary" htmlType="submit" loading={isLoading} block>
//               Send Document for Signing
//             </Button>
//           </Form.Item>
//         </Form>
//         {accessToken && envelopeId && (
//           <DocuSignStatus accessToken={accessToken} envelopeId={envelopeId} />
//         )}
//       </div>
//       {documentPreviewModal}
//     </div>
//   );
// };

// export default UploadDocument;

import React, { useState, useEffect } from "react";
import { Button, Form, message, Modal } from "antd";
import { Document, Page } from "react-pdf";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DocuSignUri } from "../store/store";
import "../assets/style/uploaddocument.css";
import DocuSignStatus from "./DocuSignStatus";
import { docuSignData } from "../utils/common";

const UploadDocument = ({ signersLists }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [approversList, setApproversList] = useState(signersLists);
  const [envelopeId, setEnvelopeId] = useState(null);
  const [authorizeCode, setAuthorizeCode] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDocuSignAuthenticated, setIsDocuSignAuthenticated] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const s3Uri = useRecoilState(DocuSignUri);
  console.log("s3Uri", s3Uri);
  const location = useLocation();

  const startDocuSignFlow = () => {
    const authUrl = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=${docuSignData.scope}&client_id=${docuSignData.integrationKey}&redirect_uri=${docuSignData.redirectUri}`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get("code");
    setAuthorizeCode(authorizationCode);

    if (authorizationCode) {
      const fetchAccessToken = async () => {
        try {
          const response = await axios.get(
            `http://localhost:4000/api/v1/docusign/callback?code=${authorizationCode}`
          );
          setAccessToken(response.data.accessToken);
          setIsDocuSignAuthenticated(true);
          message.success("You are ready to proceed");
        } catch (error) {
          console.error("Error fetching access token:", error);
          message.error("Failed to obtain access token");
        }
      };
      fetchAccessToken();
    }
  }, [location.search]);

  // useEffect(() => {
  //   if (isDocuSignAuthenticated) {
  //     handleSendDocument();
  //   }
  // }, [isDocuSignAuthenticated]);

  const handleSendDocument = async () => {
    if (!s3Uri || !accessToken) {
      message.error("Missing required information.");
      return;
    }

    const formData = new FormData();
    formData.append("fileUrl", s3Uri[0][0].uri); // S3 file URL
    formData.append("accessToken", accessToken);
    formData.append("approversList", JSON.stringify(approversList));

    setIsLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:4000/api/v1/docusign/sign-document",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Document sent for signing!");
      setEnvelopeId(response.data.envelopeId);
    } catch (error) {
      console.error(error);
      message.error("Failed to send document.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPdfFile = async () => {
    try {
      const response = await axios.get(s3Uri[0][0]?.uri, {
        responseType: "blob",
      });
      setPdfFile(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePreviewFile = () => {
    setIsModalVisible(true);
    fetchPdfFile();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="center-container">
      <div className="form-container">
        <Form layout="vertical" onFinish={handleSendDocument} className="mt-20">
          <Form.Item>
            <Button
              target="_blank"
              onClick={startDocuSignFlow}
              className="sign-document-btn"
              block
            >
              Sign Contract with DocuSign
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handlePreviewFile}
              className="sign-document-btn"
              block
            >
              View Original Contract
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!isDocuSignAuthenticated || !s3Uri[0][0]?.uri}
              block
            >
              Send Document for Signing
            </Button>
          </Form.Item>
        </Form>

        {accessToken && envelopeId && (
          <DocuSignStatus accessToken={accessToken} envelopeId={envelopeId} />
        )}
      </div>

      <Modal
        title="PDF Preview"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        {pdfFile ? (
          <Document file={pdfFile} onLoadSuccess={handleLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          <p>Loading...</p>
        )}
      </Modal>
    </div>
  );
};

export default UploadDocument;
