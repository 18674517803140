import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Row,
  Col,
  Tabs,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Table,
  Layout,
  Modal,
  Checkbox,
  Image,
  Menu,
  Divider,
  Switch,
  message,
  Upload,
  Card,
  Skeleton,
} from "antd";
import {
  RightOutlined,
  UserOutlined,
  FileOutlined,
  DownloadOutlined,
  SwapRightOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import account_tree from "../../assets/images/icons/account_tree.svg";
import dotPointer from "../../assets/images/icons/dot_pointer.svg";
import folder_active from "../../assets/images/icons/folder.svg";
import folder_inactive from "../../assets/images/icons/folder_inactive.svg";
import adminPanel_active from "../../assets/images/icons/admin_panel_active.png";
import adminPanel_inactive from "../../assets/images/icons/admin_panel_settings.png";
import approval_active from "../../assets/images/icons/approval_flow_icon.svg";
import approval_inactive from "../../assets/images/icons/approval_flow_icon_active.svg";
import contract_doc_active from "../../assets/images/icons/contract_doc_icon.svg";
import contract_doc_inactive from "../../assets/images/icons/contract_doc_icon_active.svg";
import chat from "../../assets/images/icons/chat.svg";
import chat_active from "../../assets/images/icons/chat_active.svg";
import visibility from "../../assets/images/icons/visibility.svg";
import Downloading from "../../assets/images/icons/Downloadimg.svg";
import check_box from "../../assets/images/icons/check_box.svg";
import Group from "../../assets/images/icons/Group.svg";
import UploadButtonIcon from "../../assets/images/icons/upload_icon_blue.svg";
import attach from "../../assets/images/icons/attach.svg";
import send from "../../assets/images/icons/send.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import CompareIcon from "../../assets/images/icons/compare_arrows_blue.svg";
import VersionIcon from "../../assets/images/icons/version_icon.svg";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import "../../assets/style/createdocument.css";
import "../../assets/style/contractfile.css";
import TopHeader from "../../components/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createContractAPi,
  getContractParentData,
  getContractParent,
  editContractAPi,
} from "../../api/contractsApi";
import { listLegalEntity } from "../../api/legalEntityApi";
import {
  suppliersOnlyApi,
  getSingleSupplierDataApi,
} from "../../api/suppliersApi";
import { getAllPricingProfileApi } from "../../api/pricingProfileApi";
import { getAllFxTableApi } from "../../api/fxTableApi";
import { getSingleWorkflowsApi } from "../../api/workflowApi";
import { getAllInvoicingProfileApi } from "../../api/invoicingProfileApi";
import { listCostCenter } from "../../api/costCenterApi";
import moment from "moment";
import dayjs from "dayjs";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  HeaderChangerAtom,
  userDataAtom,
  SupplierTreeDataAtom,
} from "../../store/store";
import AggregateValue from "../../components/Contract/AggregateValue";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import {
  CancelButton,
  OkButton,
  SaveButton,
  SubmitButton,
  UploadButton,
  CompareButton,
} from "../../components/GlobalButton";
import FlowDiagram from "../../components/FlowDiagram";
import CompareDocs from "../../components/CompareDocs";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactDiffViewer from "react-diff-viewer-continued";
import { diffLines } from "diff";
import DataTable from "../../components/Tables/DataTable";
import CommercialTable from "../../components/Contract/CommercialTable";
import { deleteS3FileApi } from "../../api/commonApi";
import { FullscreenOutlined } from "@ant-design/icons"; //antd icons

const GeneralForm = () => {
  // global variable hold the tabs switching
  const { TabPane } = Tabs;
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  //global state variable hold the header
  let header = headerChanger.header;
  // state variable hold the tab keys switching
  const [tabkey, setKey] = useState("1");
  // global state variable hold the key while switch header name
  const menuKey = "2";
  //global state variable hold the navigating different routes
  const history = useNavigate();
  //global state variable hold the corresponding contract details using location
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("stateGF", state);
  // State variable to hold on workFlow Approver ID
  const [approverId, setApproverId] = useState();
  // global variable hold the clear in row by using true or false
  let rowClear = false;
  // global variable hold the row key
  let rowKey;
  //global varaiable hold the main form instanse
  const [mainForm] = Form.useForm();
  // global variaable hold the main form using useref
  const mainFormRef = useRef();
  // state varaiable hold the supplier data from fetch api
  const [suppliersList, setSuppliersList] = useState([]);
  // state  varaiable hold the pricing profile data from fetch in api
  const [pricingProfile, setPricingProfile] = useState([]);
  // state  varaiable hold the fx data from fetch in api
  const [fxProfile, setFxProfile] = useState([]);
  // state  varaiable hold the invoiceing profile data from fetch in api
  const [invoiceingProfileData, SetInvoiceingProfileData] = useState([]);
  // state  varaiable hold the cost Center profile data from fetch in api
  const [costCenterData, setCostCenterData] = useState([]);
  // state  varaiable hold the Save button loader
  const [saveLoader, setSaveLoader] = useState(false);
  // state  varaiable hold the Submit button loader
  const [submitLoader, setSubmitLoader] = useState(false);
  //state varaiable hold the key 0 or 1 for validiation
  const [buttonState, setButtonState] = useState(0);
  // state variable hold the data for commerical data
  const [rtData, setRtData] = useState(null);
  // state varaiable hold  the table data show
  const [comTable, showComTable] = useState(false);
  // state varaiable hold the dynamic column
  const [filteredOpt, setFilteredOpt] = useState({ 0: [] });
  // state varaiable hold the cost value
  const [cost, setCost] = useState({});
  // state varaiable hold the commerical data
  const [pp, setPP] = useState();
  // state variable hold the data for lenglth
  const [sowComData, setSowComData] = useState([]);
  // state varaiable hold the contract data in new
  const [newContractData, setNewContractData] = useState([]);
  // state varaiable hold the corresponding data for inital
  const [rootData, setRootData] = useState({
    rootId: "",
    rootName: "",
    startDate: "",
    endDate: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [CommentMode, setCommentAction] = useState("");
  const [editorDelta, setEditorDelta] = useState();
  const handleEditorChange = (content, delta, source, editor) => {
    setEditorDelta(editor.getContents());
  };
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const closeModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
    setCommentAction("create");
  };
  // state varaible hold the main start and end date has null
  const [rootDates, setRootDates] = useState({
    startDate: "",
    endDate: "",
  });
  // state variable hold the children start and end date has null
  const [childDate, setChildDate] = useState({
    startDate: "",
    endDate: "",
  });
  // state varaible hold the start date set true or false
  const [checkedSd, setCheckedSd] = useState(false);
  // state varaible hold the start date set true or false
  const [checkedEd, setCheckedEd] = useState(false);
  // state varaible hold the year list object
  const [yearsList, setYearsList] = useState({});
  const [createdId, setCreatedId] = useState("");
  const aggregateContractDataRef = useRef({
    contractName: state.title,
    mode: "create",
    startDate: "",
    endDate: "",
    cost: {},
    contractType: state.contractType,
    contracts: {},
    newContract: [],
  });
  // state varaible hold the display resoursec field by value is present
  const [disabledResource, setDisabledResource] = useState([]);
  // state variable hold the decryption data
  const [getUser, setGetUser] = useState();
  // state varaible hold the default fix for resource id
  const [commercialTableData, setCommercialTableData] = useState({
    0: {
      "Resource Id": "RE_1",
    },
  });

  const [commentEntered, setCommentEntered] = useState("");
  const [comments, setComments] = useState([]);
  const [contractFile, setContractFile] = useState();
  const [notesTableData, setNotesTableData] = useState([]);
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const [flowData, setFlowData] = useState(); // Stores approval flow chart data
  // State variable to hold upload contract document Modal visibility status
  const [uploadContractDoc, setUploadContractDoc] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbsClick, setBreadcrumbsClick] = useState(false);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [selectedRows, setSelectedRows] = useState(0);
  const [compareModal, setCompareModal] = useState(false);
  const [uploadForm] = Form.useForm();
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [contractDocData, setContractDocData] = useState([]);
  const [contractDocFileList, setContractDocFileList] = useState([]);
  const [disableUpload, setDisableUpload] = useState(true);
  const [newDocFileUpload, setNewDocFileUpload] = useState([]);
  const [splitView, setSplitView] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [highlightLines, setHighlightLines] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [changedLines, setChangedLines] = useState([]);
  const viewerRef = useRef(null);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  const [viewedFile, setViewedFile] = useState(false);

  // state variable hold the legalentity name from API response
  const [legalEntityData, setLegalEntityData] = useState([]);
  const [contractPricing, setContractPricing] = useState([]);
  const [contractCurrency, setContractCurrency] = useState([]);

  // global variable hold the value
  const SECRET_KEY = "mysecretkey";
  const innerTabRef = useRef(null);

  // state varaiable hold the dynamic column name
  const [dynamicAddName, setDynamicAddName] = useState({});
  //functionlity handle the table data
  const [datasourceObj, setDatasourceObj] = useState({
    key: 0,
    ["Resource Id"]: "RE_1",
    ["Start Date"]: new Date(),
    ["End Date"]: new Date(),
    ["Days"]: "",
    ["Cost"]: "",
  });
  // state variable hold the commercial table functionalities
  const [dataSourceCom, setDataSourceCom] = useState([datasourceObj]);
  const [commercialRateCard, setCommercialRateCard] = useState([]);
  const rateCardTemplate = useMemo(() => {
    if (!commercialRateCard || commercialRateCard.length === 0)
      return {
        key: 0,
        "Resource Id": "RE_1",
        "Start Date": undefined,
        "End Date": undefined,
        Days: undefined,
        Cost: undefined,
      };

    return commercialRateCard.reduce(
      (acc, curr) => {
        Object.keys(curr)
          .filter((column) => column !== "Item Id")
          .forEach((key) => {
            acc[key] = undefined;
          });

        return acc;
      },
      {
        key: 0,
        "Resource Id": "RE_1",
        "Start Date": undefined,
        "End Date": undefined,
        Days: undefined,
        Cost: undefined,
      }
    );
  }, [commercialRateCard]);

  const commercialRef = useRef([{ ...rateCardTemplate }]);
  const yearsListRef = useRef({});
  const daysRef = useRef({});
  const costsRef = useRef({});
  const contractStartDateRef = useRef(undefined);
  const contractEndDateRef = useRef(undefined);

  // function handle the decryt and retrieve user data from logged user id
  const decryptAndRetrieveUserData = () => {
    const encryptedUserData = localStorage.getItem("loggedInUser");

    if (encryptedUserData) {
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(encryptedUserData, SECRET_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // Use the decrypted data (for example, set it in state)
      setGetUser(decryptedData);
    }
  };

  useEffect(() => {
    console.log("breadCrumbs: ", breadcrumbs);
  }, [breadcrumbs]);

  useEffect(() => {
    listLegalEntity().then((res) => {
      // console.log("le-res", res);
      setLegalEntityData(res);
    });
  }, []);

  useEffect(() => {
    console.log("rtData: ", rtData);
  }, [rtData]);

  // use effeect handle retrive data from invoicing and cost center as well as if contract check CR and CCN get parent contract data
  useEffect(() => {
    console.log("state /**", state);
    //For getting breadcrumbs path, we use this to get the Supplier Name
    const getBreadcrumbs = (data, supplierName, contractName) => {
      console.log("bfeubyu", data);
      let path = [];
      const findInArray = (arr) => {
        for (const item of arr) {
          console.log("item ,", item);
          console.log("supplierName", supplierName);
          if (item.supplier_name === supplierName) {
            path.push({
              contract_names: item.supplier_name,
              contract_id: item._id,
              contract_types: item.contract_type,
            });
            return findInChildren(item.children);
          }
        }
        return false;
      };
      const findInChildren = (children) => {
        for (const child of children) {
          // console.log("child ,", child);
          path.push({
            contract_names: child.contract_name,
            contract_id: child._id,
            contract_types: child.contract_type,
          });
          if (
            child.contract_name === contractName ||
            (child.children && findInChildren(child.children))
          ) {
            return true;
          }
          path.pop();
        }
        return false;
      };
      if (!findInArray(data)) {
        return path.concat({ contract_names: contractName });
      }
      return path;
    };
    const updateBreadcrumbs = (contractBreadCrumbs) => {
      const uniqueNames = new Set();
      const uniqueContractBreadCrumbs = contractBreadCrumbs.filter((item) => {
        if (!uniqueNames.has(item.contract_names)) {
          uniqueNames.add(item.contract_names);
          return true;
        }
        return false;
      });

      setBreadcrumbs(uniqueContractBreadCrumbs);
    };
    console.log("supplierTreeData", supplierTreeData);
    const contractSupplier = supplierTreeData.find(
      (supplier) => supplier._id === state.supplier_id
    );
    if (!contractSupplier) {
      getSingleSupplierDataApi(state.supplier_id).then((res) => {
        getContractParent(state.parent.id).then((resdata) => {
          console.log("resdata", resdata);
          const supplierName = { contract_names: res.supplier_name };
          const contractNames = resdata.map((parentdata) => ({
            contract_names: parentdata.contract_name,
          }));
          updateBreadcrumbs([supplierName, ...contractNames]);
        });
      });
    } else {
      console.log("supplierTreeData ******", supplierTreeData);
      const contractBreadcumbs = getBreadcrumbs(
        supplierTreeData,
        contractSupplier.supplier_name,
        state.parent.name
      );
      updateBreadcrumbs(contractBreadcumbs);
    }

    getSingleWorkflowsApi(state.workflow_id).then((workFlow) => {
      console.log("workflowRes: ", workFlow);
      setFlowData(workFlow.workflow_metadata);
      setApproverId(workFlow.workflow_metadata.Level1.approverId[0]);
    });

    getAllInvoicingProfileApi().then((res) => {
      SetInvoiceingProfileData(
        res.filter((data) => data.supplier_id === state.supplier_id)
      );
    });
    listCostCenter().then((res) => {
      setCostCenterData(res);
    });
    if (state.contractType === "CR" || state.contractType === "CCN") {
      getContractParentData(state.parent.id, state.parent.type).then((res) => {
        console.log("Strart date", res);
        aggregateContractDataRef.current = {
          ...aggregateContractDataRef.current,
          contracts: res[0],
        };

        setRootData({
          ...rootData,
          rootId: res[0]._id,
          rootName: res[0].contract_name,
          startDate: res[0].start_date,
          endDate: res[0].end_date,
        });
        setRootDates({
          ...rootDates,
          startDate: res[0].start_date,
          endDate: res[0].end_date,
        });

        setPP(
          res[0] &&
            res[0].commercial_data &&
            res[0].commercial_data.length !== 0 &&
            res[0].commercial_data[0] &&
            res[0].commercial_data[0].pricingprofile_data &&
            res[0].commercial_data[0].pricingprofile_data.length !== 0 &&
            res[0].commercial_data[0].pricingprofile_data[0]
        );
        setContractCurrency(
          res[0] &&
            res[0].commercial_data &&
            res[0].commercial_data.length !== 0 &&
            res[0].commercial_data[0] &&
            res[0].commercial_data[0].pricingprofile_data &&
            res[0].commercial_data[0].pricingprofile_data.length !== 0 &&
            res[0].commercial_data[0].pricingprofile_data[0].contract_cc
        );
        setContractPricing(
          res[0] &&
            res[0].commercial_data &&
            res[0].commercial_data.length !== 0 &&
            res[0].commercial_data[0] &&
            res[0].commercial_data[0].pricingprofile_data &&
            res[0].commercial_data[0].pricingprofile_data.length !== 0 &&
            res[0].commercial_data[0].pricingprofile_data[0].contract_pricing
        );
        setRtData(
          res[0] &&
            res[0].commercial_data &&
            res[0].commercial_data.length !== 0 &&
            res[0].commercial_data[0] &&
            res[0].commercial_data[0].ratetable_data &&
            res[0].commercial_data[0].ratetable_data.length !== 0 &&
            res[0].commercial_data[0].ratetable_data[0]
        );

        let ratecardData = [];
        if (
          res[0] &&
          res[0].commercial_data &&
          res[0].commercial_data.length !== 0 &&
          res[0].commercial_data[0] &&
          res[0].commercial_data[0].ratetable_data &&
          res[0].commercial_data[0].ratetable_data.length !== 0
        ) {
          const keys =
            res[0].commercial_data[0].ratetable_data[0].rt_items_desc;
          ratecardData =
            res[0].commercial_data[0].ratetable_data[0].rt_items_detail.map(
              (detail) => {
                let dataObj = {};
                for (let i = 0; i < keys.length; i++) {
                  dataObj[keys[i]] = detail[i];
                }
                return dataObj;
              }
            );
        }

        setCommercialRateCard(ratecardData);

        let sowData = [];
        if (res.length !== 0 && res[0].commercial_data.length !== 0) {
          for (
            let i = 0;
            i < res[0].commercial_data[0].commercial_details.length;
            i++
          ) {
            let detail = res[0].commercial_data[0].commercial_details[i];
            let dataObj = {
              created_on: res[0].created_on,
              contract_status: res[0].contract_status,
            };
            for (
              let j = 0;
              j < res[0].commercial_data[0].commercial_desc.length;
              j++
            ) {
              dataObj[res[0].commercial_data[0].commercial_desc[j]] = detail[j];
            }
            sowData.push(dataObj);
          }
        }
        if (res.length !== 0 && res[0].children !== 0) {
          res[0].children.map((data) => {
            if (data.commercial_data && data.commercial_data.length !== 0) {
              for (
                let i = 0;
                i < data.commercial_data.commercial_details.length;
                i++
              ) {
                let detail = data.commercial_data.commercial_details[i];
                let dataObj = {
                  created_on: data.created_on,
                  contract_status: data.contract_status,
                };
                for (
                  let j = 0;
                  j < data.commercial_data.commercial_desc.length;
                  j++
                ) {
                  dataObj[data.commercial_data.commercial_desc[j]] = detail[j];
                }
                sowData.push(dataObj);
              }
            }
          });
        }

        let finalSowData = [];
        let initialKeysArr = [];
        sowData &&
          sowData.length !== 0 &&
          sowData.map((t1, index) => {
            initialKeysArr.push(index);
            Object.assign(t1, { key: index });
            t1["Start Date"] = dayjs(t1["Start Date"]);
            t1["End Date"] = dayjs(t1["End Date"]);
            finalSowData.push({
              ...t1,
              ...(ratecardData &&
                ratecardData.length !== 0 &&
                ratecardData.find((t2) => {
                  return t2["Item Id"] === t1["RtItem Id"];
                })),
            });
          });

        let reArr = [];
        finalSowData &&
          finalSowData.length !== 0 &&
          finalSowData.map((data) => {
            if (
              data.contract_status !== "Contract-in-Execution" &&
              !disabledResource.includes(data["Resource Id"])
            ) {
              reArr.push(data["Resource Id"]);
            }
          });
        setDisabledResource(reArr);

        // Filter out in-active items
        const filteredItems = finalSowData.filter(
          (item) => item.Status !== "In-Active"
        );

        // Group items by Resource Id
        const groupedItems = filteredItems.reduce((groups, item) => {
          const resourceId = item["Resource Id"];
          if (!groups[resourceId]) {
            groups[resourceId] = [];
          }
          groups[resourceId].push(item);
          return groups;
        }, {});

        // Transform grouped items
        let transformedItems = Object.values(groupedItems || {}).map(
          (group) => {
            const resourceId = group[0]["Resource Id"];
            // Find farthest start date and closest end date
            let startDate = group[0]["Start Date"];
            let endDate = group[0]["End Date"];

            for (const item of group) {
              if (item["Start Date"] < startDate) {
                startDate = item["Start Date"];
              }
              if (item["End Date"] > endDate) {
                endDate = item["End Date"];
              }
            }

            // Combine "Days" for each year and month
            const combinedDays = {};
            for (const item of group) {
              const days = item.Days;
              for (const year in days) {
                if (!combinedDays[year]) {
                  combinedDays[year] = {};
                }
                for (const month in days[year]) {
                  combinedDays[year][month] =
                    (combinedDays[year][month] || 0) +
                    parseInt(days[year][month]);
                }
              }
            }

            return {
              ...group[0],
              "Start Date": startDate,
              "End Date": endDate,
              Days: combinedDays,
              "Resource Id": resourceId,
              Status: "Active", // Since we filtered out in-active items
            };
          }
        );

        transformedItems = transformedItems.map((data, index) => {
          return {
            ...data,
            key: index,
          };
        });

        let initialYearsList = {};
        transformedItems &&
          transformedItems.length !== 0 &&
          transformedItems.map((data) => {
            const startDate = new Date(data["Start Date"]);
            const endDate = new Date(data["End Date"]);
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();
            let output = {};
            let currentYear = startYear;
            let currentMonth = startDate.getMonth(); // 0 (January) to 11 (December)
            while (
              currentYear < endYear ||
              (currentYear === endYear && currentMonth <= endDate.getMonth())
            ) {
              const monthAbbreviation = dayjs()
                .month(currentMonth)
                .format("MMM")
                .toLowerCase();
              if (!output[currentYear]) {
                output[currentYear] = [];
              }
              output[currentYear].push(monthAbbreviation);
              if (currentMonth === 11) {
                // December, move to next year
                currentMonth = 0;
                currentYear++;
              } else {
                currentMonth++;
              }
            }
            initialYearsList = {
              ...initialYearsList,
              [data["key"]]: output,
            };
          });
        setYearsList(initialYearsList);

        yearsListRef.current = initialYearsList;

        transformedItems &&
          transformedItems.length !== 0 &&
          transformedItems.map((data) => {
            delete data["Item Id"];
            delete data["contract_status"];
          });

        setSowComData(
          transformedItems && transformedItems.length !== 0
            ? transformedItems
            : []
        );

        showComTable(ratecardData.length !== 0 ? true : false);
        setCommercialTableData(
          transformedItems && transformedItems.length !== 0
            ? transformedItems
            : {
                0: {
                  "Resource Id": "RE_1",
                },
              }
        );

        setDataSourceCom(
          transformedItems && transformedItems.length !== 0
            ? transformedItems
            : [
                {
                  key: 0,
                  ["Resource Id"]: "RE_1",
                  ["Start Date"]: new Date(),
                  ["End Date"]: new Date(),
                  ["Days"]: "",
                  ["Cost"]: "",
                },
              ]
        );

        commercialRef.current =
          transformedItems && transformedItems.length !== 0
            ? transformedItems
            : [
                {
                  key: 0,
                  ["Resource Id"]: "RE_1",
                  ["Start Date"]: undefined,
                  ["End Date"]: undefined,
                  ["Days"]: "",
                  ["Cost"]: "",
                },
              ];

        setNewContractData(
          transformedItems && transformedItems.length !== 0
            ? transformedItems
            : []
        );

        setDynamicAddName({
          ...dynamicAddName,
          ...transformedItems.map((data, index) => (index = [data])),
        });

        daysRef.current = {
          ...daysRef.current,
          ...transformedItems.map((data, index) => (index = data["Days"])),
        };

        let costObj = {};
        transformedItems.map((src, index) => {
          Object.keys(src["Days"] || {}).forEach((key) => {
            costObj[index] = costObj[index] || {};
            costObj[index][key] = Number(
              src["Rate Period"]
                ? src["Rate Period"] !== "Yearly Rate"
                  ? Number(src["Days"][key]) * Number(src["Price"])
                  : Number.isInteger(
                      (Number(src["Days"][key]) / 12) * Number(src["Price"])
                    )
                  ? (Number(src["Days"][key]) / 12) * Number(src["Price"])
                  : (Number(src["Days"][key]) / 12) *
                    Number(src["Price"]).toFixed(2)
                : Number(src["Days"][key]) * Number(src["Price"])
            );
          });
        });

        costsRef.current = costObj;

        const updatedCommercialData =
          transformedItems &&
          transformedItems.map((item) => {
            const costData = costObj[item.key];
            return {
              ...item,
              Cost: costData,
            };
          });

        // Update the commercialRef.current with the added Cost keys for each row
        commercialRef.current = updatedCommercialData;
      });
    }
    decryptAndRetrieveUserData();
  }, [state]);

  /*function handle check box enable or disable
   *  @param e- selected date corresponding from data picker
   * @param field - wjeater start date and end date
   */
  const onApplyChange = (e, field) => {
    if (field === "start_date") {
      setCheckedSd(e.target.checked);
      if (!e.target.checked) {
        setRootData({ ...rootData, startDate: rootDates.startDate });
      }
      if (e.target.checked && childDate.startDate !== "") {
        setRootData({ ...rootData, startDate: childDate.startDate });
      }
    } else {
      setCheckedEd(e.target.checked);
      if (!e.target.checked) {
        setRootData({ ...rootData, endDate: rootDates.endDate });
      }
      if (e.target.checked && childDate.endDate !== "") {
        setRootData({ ...rootData, endDate: childDate.endDate });
      }
    }
  };

  /*function handle on change for date
   *  @param data- selected date corresponding from data picker
   * @param field - wheater start date and end date
   */

  const onDateChange = (date, dateString, field) => {
    // console.log("date: ", date);
    // console.log("dateString: ", dateString);
    // commercialRef.current = commercialRef.current.map((data) => {
    //   data["Start Date"] = undefined;
    //   data["End Date"] = undefined;
    //   data["Days"] = {};
    //   data["Cost"] = {};
    //   return data;
    // });
    // yearsListRef.current = {};
    // daysRef.current = {};
    // costsRef.current = {};
    switch (field) {
      case "start_date":
        contractStartDateRef.current = date;
      case "end_date":
        contractEndDateRef.current = date;
    }

    if (field === "start_date") {
      setStartDateSelected(true);
      setChildDate({ ...childDate, startDate: dateString });

      aggregateContractDataRef.current = {
        ...aggregateContractDataRef.current,
        startDate: dateString,
      };
    }
    if (field === "start_date" && checkedSd) {
      setRootData({ ...rootData, startDate: dateString });
    }
    if (field === "end_date") {
      setChildDate({ ...childDate, endDate: dateString });
      setEndDateSelected(true);

      aggregateContractDataRef.current = {
        ...aggregateContractDataRef.current,
        endDate: dateString,
      };
    }
    if (field === "end_date" && checkedEd) {
      setRootData({ ...rootData, endDate: dateString });
    }

    // Update dataSourceCom
    // const updatedDataSourceCom = dataSourceCom.map((item) => {
    //   if (item["Start Date"] && field === "start_date") {
    //     item["Start Date"] = dayjs(dateString, "DD MMM YYYY");
    //     // item["Start Date"] = null;
    //   }
    //   if (item["End Date"] && field === "end_date") {
    //     item["End Date"] = dayjs(dateString, "DD MMM YYYY");
    //     // item["End Date"] = null;
    //   }
    //   return item;
    // });

    const arrayOfObj = Object.values(commercialTableData || {});
    let aligned =
      arrayOfObj &&
      arrayOfObj.length > 0 &&
      arrayOfObj.map((data, index) => {
        // if (data["Start Date"] && field === "start_date") {
        //   data["Start Date"] = dayjs(dateString, "DD MMM YYYY");
        //   // data["Start Date"] = null;
        // }
        // if (data["End Date"] && field === "end_date") {
        //   data["End Date"] = dayjs(dateString, "DD MMM YYYY");
        //   // data["End Date"] = null;
        // }
        return {
          ...data,
          ["Start Date"]: data["Start Date"]
            ? field === "start_date"
              ? dayjs(dateString, "DD MMM YYYY")
              : data["Start Date"]
            : new Date(),
          ["End Date"]: data["End Date"]
            ? field === "end_date"
              ? dayjs(dateString, "DD MMM YYYY")
              : data["End Date"]
            : new Date(),
          // ["End Date"]: data["End Date"] ? data["End Date"] : new Date(),
          ["key"]: index,
          ["Resource Id"]: `RE_${index + 1}`,
        };
      });

    setDataSourceCom(aligned);

    // Update startDate or endDate objects
    const updatedStartDate = { ...startDate };
    const updatedEndDate = { ...endDate };

    if (field === "start_date") {
      onChangeContractDates(dayjs(dateString, "DD MMM YYYY"), "start_date");
      Object.keys(updatedStartDate).forEach((key) => {
        updatedStartDate[key] = dayjs(dateString, "DD MMM YYYY");
        // updatedStartDate[key] = null;
      });
      setStartDate(updatedStartDate);
    }

    if (field === "end_date") {
      onChangeContractDates(dayjs(dateString, "DD MMM YYYY"), "end_date");
      Object.keys(updatedEndDate).forEach((key) => {
        updatedEndDate[key] = dayjs(dateString, "DD MMM YYYY");
        // updatedEndDate[key] = null;
      });
      setEndDate(updatedEndDate);
    }
  };

  // Function that runs when contract Start/End date is changed
  const onChangeContractDates = (date, field) => {
    const newStartDate = {};
    const newEndDate = {};
    const newYearsList = {};
    const newChyear = {};
    const newCommercialTableData = { ...commercialTableData };

    // Assuming startDate and endDate have the same keys
    const allRecords = Object.keys(startDate);

    allRecords.forEach((record) => {
      if (field === "start_date") {
        newStartDate[record] = date;
        const startYear = date.year();
        const endYear = endDate[record]
          ? dayjs(endDate[record]).year()
          : dayjs().year();
        let output = {};

        for (let year = startYear; year <= endYear; year++) {
          const startMonth = year === startYear ? date.month() : 0;
          const endMonth =
            year === endYear ? dayjs(endDate[record]).month() : 11;
          const months = [];

          for (let month = startMonth; month <= endMonth; month++) {
            const monthName = dayjs(new Date(year, month, 1)).format("MMM");
            months.push(monthName);
          }

          output[year] = months;
        }

        newYearsList[record] = output;
        newChyear[record] = output;

        newCommercialTableData[record] = {
          ...newCommercialTableData[record],
          ["Start Date"]: date.format("DD MMM YYYY"),
        };
      } else {
        newEndDate[record] = date;
        const startYear = startDate[record]
          ? dayjs(startDate[record]).year()
          : date.year();
        const endYear = date.year();
        let output = {};

        for (let year = startYear; year <= endYear; year++) {
          const startMonth =
            year === startYear ? dayjs(startDate[record]).month() : 0;
          const endMonth = year === endYear ? date.month() : 11;
          const months = [];

          for (let month = startMonth; month <= endMonth; month++) {
            const monthName = dayjs(new Date(year, month, 1)).format("MMM");
            months.push(monthName);
          }

          output[year] = months;
        }

        newYearsList[record] = output;
        newChyear[record] = output;

        newCommercialTableData[record] = {
          ...newCommercialTableData[record],
          ["End Date"]: date.format("DD MMM YYYY"),
        };
      }
    });

    if (field === "start_date") {
      setStartDate(newStartDate);
    } else {
      setEndDate(newEndDate);
    }

    setYearsList(newYearsList);
    setCommercialTableData(newCommercialTableData);
  };

  /*function handle disable date
   *  @param datavalue - selected date corresponding from data picker
   * @param field - wheater start date and end date
   */
  const disabledDate = (dateValue, field) => {
    if (field === "start_date") {
      if (!childDate.endDate) {
        return false;
      }
      return dateValue.isAfter(childDate.endDate, "day");
    } else {
      if (!childDate.startDate) {
        return false;
      }
      return dateValue.isBefore(childDate.startDate, "day");
    }
  };

  useEffect(() => {
    suppliersOnlyApi().then((data) => {
      setSuppliersList(data.filter((res) => res.id === state.supplier_id));
    });

    getAllPricingProfileApi().then((res) => {
      let filteredPP = res.filter(
        (data) => data.supplier_id === state.supplier_id
      );
      setPricingProfile(filteredPP);
    });

    getAllFxTableApi().then((res) => {
      console.log("fp-res", res);
      let filteredFP = res.filter(
        (data) => data.supplier_id === state.supplier_id
      );
      setFxProfile(filteredFP);
    });
  }, [state]);

  // function handle the keys
  const onChange = (key) => {
    setKey(key);
  };
  // state varaiable hold the start date
  const [startDate, setStartDate] = useState({});
  //state variable hold the end date
  const [endDate, setEndDate] = useState({});

  /*function handle on change pricing profile data functionality
   *  @param key - corresponding selected value key
   * @param value - corresponding selected value
   */
  const handlePricingProfile = (key, value) => {
    console.log("kv", key, value);
    showComTable(value !== undefined ? true : false);
    setDataSourceCom([
      {
        key: 0,
        ["Resource Id"]: "RE_1",
        ["Start Date"]: new Date(),
        ["End Date"]: new Date(),
        ["Days"]: "",
        ["Cost"]: "",
      },
    ]);
    setNewContractData([]);
    setSowComData([]);
    setYearsList({});
    setDisabledResource([]);
    setCommercialTableData({
      0: {
        "Resource Id": "RE_1",
      },
    });
    setStartDate({});
    setEndDate({});
    let filteredRt = pricingProfile.filter((data) => data._id === value.key);
    setRtData(filteredRt[0].rate_card[0]);
    setContractCurrency(filteredRt[0].contract_cc);
    setContractPricing(filteredRt[0].contract_pricing);

    const keys = filteredRt[0].rate_card[0].rt_items_desc;
    let ratecardData = filteredRt[0].rate_card[0].rt_items_detail.map(
      (detail) => {
        let dataObj = {};
        for (let i = 0; i < keys.length; i++) {
          dataObj[keys[i]] = detail[i];
        }
        return dataObj;
      }
    );

    console.log("ratecardData: ", ratecardData);

    setCommercialRateCard(ratecardData);
  };

  const updateShowComTable = () => {
    if (state.pricing_profile) {
      showComTable(true);
      setCommercialRateCard(state.rateCardData);
    }
  };

  useEffect(() => {
    updateShowComTable();
  }, [state.pricing_profile]);

  // state variable hold the performance table functionalities
  useEffect(() => {
    rtData &&
      rtData.rt_items_desc &&
      rtData.rt_items_desc
        .filter((data) => data !== "Item Id")
        .forEach((data, index) =>
          setDatasourceObj((datasourceObj) => ({
            ...datasourceObj,
            [data]: "",
          }))
        );
  }, [rtData]);

  // function handle the basic column heading details
  const contractFields = [
    "Title",
    "Client Entity",
    "Business Area Department",
    "Contract Start Date",
    "Contract End Date",
    "Priority",
    // "Contract Currency",
    "Contract Value",
    "Supplier Entity Name",
  ];
  // function handle the scope column heading details
  const scopeFields = [
    "Scope",
    "Scope In Detail",
    // "Additional Information",
    "Assumptions",
    // "Delivery Center",
    "Dependencies",
    "Other Details",
  ];
  // function handle the people column heading details
  const peopleFields = [
    "Department Head",
    "Department Lead",
    "Department Manager",
    "Contract Manager",
    "Supplier Partner Lead",
    "Supplier Partner Manager",
    "Docusign Signers",
  ];
  // function handle the financial column heading details
  const financialFields =
    state.contractType === "CR" || state.contractType === "CCN"
      ? [
          "Invoicing Profile",
          "Contract Currency",
          "Fx Profile",
          "Contract Pricing",
        ]
      : [
          "Invoicing Profile",
          "Contract Currency",
          "Fx Profile",
          "Contract Pricing",
          "Pricing Profile",
        ];

  /* Function to handle save form
   * @paran value - collection of component data in value
   */
  const onFinish = async (value) => {
    value["info"]["contract"]["Contract Value"] = getTotalCommercialDaysCosts(
      costsRef.current,
      "cost",
      "table"
    );
    value["commercial_data"] = JSON.parse(
      JSON.stringify(commercialRef.current)
    );
    console.log("daysRef: ", daysRef.current);

    console.log("valuee", value);
    if (
      commercialMenuState === "aggregate" &&
      buttonState === 2 &&
      (!value.info.contract ||
        !value.info.scope ||
        !value.info.financial ||
        !value.info.people)
    ) {
      popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
    } else if (
      commercialMenuState !== "aggregate" &&
      buttonState === 2 &&
      !value.info.contract
    ) {
      popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
    } else if (
      commercialMenuState !== "aggregate" &&
      buttonState === 2 &&
      !value.info.scope
    ) {
      popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
    } else if (
      commercialMenuState !== "aggregate" &&
      buttonState === 2 &&
      !value.info.financial
    ) {
      popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
    } else if (
      commercialMenuState !== "aggregate" &&
      buttonState === 2 &&
      !value.info.people
    ) {
      popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
    } else if (
      commercialMenuState !== "aggregate" &&
      buttonState === 2 &&
      !value.commercial_data
    ) {
      popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
    } else {
      let status =
        Object.values(daysRef.current).length === value.commercial_data.length
          ? "success"
          : "error";

      console.log("status: ", status);

      if (
        (buttonState === 2 && status === "success") ||
        (buttonState === 1 && status === "success") ||
        (buttonState === 1 && status !== "success")
      ) {
        // Function to check if all required fields are present
        const checkFields = (fields, object) => {
          return fields.every((field) =>
            Object.keys(object || {}).includes(field)
          );
        };

        // Check fields
        const contractFieldsPresent = checkFields(
          contractFields,
          value.info.contract
        );
        const scopeFieldsPresent = checkFields(scopeFields, value.info.scope);
        const financialFieldsPresent = checkFields(
          financialFields,
          value.info.financial
        );
        const peopleFieldsPresent = checkFields(
          peopleFields,
          value.info.people
        );

        // Generate messages based on field presence
        if (
          commercialMenuState === "aggregate" &&
          buttonState === 2 &&
          (!contractFieldsPresent ||
            !scopeFieldsPresent ||
            !financialFieldsPresent ||
            !peopleFieldsPresent)
        ) {
          popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
        } else if (
          commercialMenuState !== "aggregate" &&
          buttonState === 2 &&
          !contractFieldsPresent
        ) {
          popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
        } else if (
          commercialMenuState !== "aggregate" &&
          buttonState === 2 &&
          !scopeFieldsPresent
        ) {
          popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
        } else if (
          commercialMenuState !== "aggregate" &&
          buttonState === 2 &&
          !financialFieldsPresent
        ) {
          popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
        } else if (
          commercialMenuState !== "aggregate" &&
          buttonState === 2 &&
          !peopleFieldsPresent
        ) {
          popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
        } else {
          if (buttonState === 1) {
            setSaveLoader(true);
          } else if (buttonState === 2) {
            setSubmitLoader(true);
          }
          if (state.contractType === "CR" || state.contractType === "CCN") {
            if (!value.info.financial) {
              value.info.financial = {};
            }
            value.info.financial["Pricing Profile"] = pp ? pp._id : "";

            const copiedComValue = JSON.parse(
              JSON.stringify(
                value.commercial_data && value.commercial_data.length !== 0
                  ? value.commercial_data
                  : []
              )
            ); // Existing Commercial Table Data during Creation (Array of Objects)

            if (copiedComValue && copiedComValue.length !== 0) {
              Object.keys(daysRef.current || {}).map((key) => {
                // key = index
                if (!copiedComValue[key]) {
                  copiedComValue[key] = {}; // Make the year empty object
                }
                copiedComValue[key]["Days"] = JSON.parse(
                  JSON.stringify(daysRef.current || {})
                )[key];
              });

              copiedComValue &&
                copiedComValue.length !== 0 &&
                copiedComValue.map((data, index) => {
                  data["key"] = data["key"] ? data["key"] : index;
                  return data;
                });

              const keysToRemove = [
                "key",
                "Resource Id",
                "Start Date",
                "End Date",
                "Days",
                "Cost",
                "Action",
              ];

              // Function to get the matching RtItem Id for a single newItem object
              const getMatchingRtItemId = (newItem, commercialRateCard) => {
                const filteredNewObj = Object.keys(newItem)
                  .filter((key) => !keysToRemove.includes(key))
                  .reduce((acc, key) => {
                    acc[key] = newItem[key];
                    return acc;
                  }, {});

                const matchedItem = commercialRateCard.find((rateCard) => {
                  return Object.keys(filteredNewObj).every(
                    (key) => rateCard[key] === filteredNewObj[key]
                  );
                });

                return matchedItem ? matchedItem["Item Id"] : null;
              };

              let output = [];
              let newArr = JSON.parse(JSON.stringify(copiedComValue));
              let oldArr = JSON.parse(JSON.stringify(sowComData));

              console.log("copiedComValue: ", copiedComValue);
              console.log("sowComData: ", sowComData);

              // Iterate through newArr and compare with oldArr
              for (const newItem of newArr) {
                let found = false;
                for (const oldItem of oldArr) {
                  if (newItem["Resource Id"] === oldItem["Resource Id"]) {
                    found = true;
                    // Calculate the differences in the "Days" object
                    const dayDifferences = {};
                    for (const year in newItem.Days) {
                      dayDifferences[year] = {};
                      for (const month in newItem.Days[year]) {
                        const newCount = newItem.Days[year][month];
                        const oldCount =
                          oldItem.Days &&
                          Object.keys(oldItem.Days || {}).length !== 0 &&
                          oldItem.Days[year] &&
                          oldItem.Days[year][month]
                            ? oldItem.Days[year][month]
                            : 0;

                        if (newCount !== oldCount) {
                          dayDifferences[year][month] =
                            Number(newCount) - Number(oldCount);
                        }
                      }
                      if (
                        Object.keys(dayDifferences[year] || {}).length === 0
                      ) {
                        delete dayDifferences[year];
                      }
                    }

                    // if (Object.keys(dayDifferences).length > 0) {
                    output.push({
                      ["RtItem Id"]: newItem["RtItem Id"]
                        ? newItem["RtItem Id"]
                        : getMatchingRtItemId(newItem, commercialRateCard),
                      ["Start Date"]: newItem["Start Date"],
                      ["End Date"]: newItem["End Date"],
                      ["Days"]:
                        Object.keys(dayDifferences || {}).length > 0
                          ? dayDifferences
                          : {},
                      ["Resource Id"]: newItem["Resource Id"],
                      ["Status"]: "Active",
                    });
                    // }
                    const keysToCompare = [
                      "Status",
                      ...rtData.rt_items_desc.filter(
                        (item) => item !== "Item Id"
                      ),
                    ];
                    let keyDifference = false;
                    for (const key of keysToCompare) {
                      if (newItem[key] !== oldItem[key]) {
                        keyDifference = true;
                        break;
                      }
                    }

                    // if (keyDifference) {
                    output.push({
                      ["RtItem Id"]: newItem["RtItem Id"]
                        ? newItem["RtItem Id"]
                        : getMatchingRtItemId(newItem, commercialRateCard),
                      ["Start Date"]: newItem["Start Date"],
                      ["End Date"]: newItem["End Date"],
                      ["Days"]:
                        Object.keys(newItem["Days"] || {}).length === 0
                          ? {}
                          : newItem["Days"],
                      ["Resource Id"]: newItem["Resource Id"],
                      ["Status"]: "Active",
                    });
                    // }
                    break;
                  }
                }
                if (!found) {
                  output.push({
                    ["RtItem Id"]: newItem["RtItem Id"]
                      ? newItem["RtItem Id"]
                      : getMatchingRtItemId(newItem, commercialRateCard),
                    ["Start Date"]: newItem["Start Date"],
                    ["End Date"]: newItem["End Date"],
                    ["Days"]:
                      Object.keys(newItem["Days"] || {}).length === 0
                        ? {}
                        : newItem["Days"],
                    ["Resource Id"]: newItem["Resource Id"],
                    ["Status"]: "Active",
                  });
                }
              }
              value.commercial_data = output;
            } else {
              value.commercial_data = [];
            }
          } else {
            console.log("rateCardData: ", commercialRateCard);
            const keysToRemove = [
              "key",
              "Resource Id",
              "Start Date",
              "End Date",
              "Days",
              "Cost",
              "Action",
            ];

            let commercialTableDataSource = JSON.parse(
              JSON.stringify(commercialRef.current)
            );

            commercialTableDataSource = commercialTableDataSource.map(
              (item) => {
                const originalItem = { ...item };
                const cleanedItem = { ...item };
                originalItem["Status"] = "Active";

                keysToRemove.forEach((key) => delete cleanedItem[key]);

                const matchingItem = commercialRateCard.find((rateCardItem) => {
                  return Object.keys(cleanedItem).every(
                    (key) => rateCardItem[key] === cleanedItem[key]
                  );
                });

                if (matchingItem) {
                  originalItem["RtItem Id"] = matchingItem["Item Id"];
                }
                delete originalItem["key"];
                delete originalItem["Cost"];
                rtData &&
                  rtData.rt_items_desc
                    .filter((item) => item !== "Item Id")
                    .map((data) => {
                      delete originalItem[data];
                    });
                return originalItem;
              }
            );

            value["commercial_data"] = commercialTableDataSource.filter(
              (item) =>
                (item["Days"] !== "" || item["Days"] !== undefined) &&
                (item["Cost"] !== "" || item["Cost"] !== undefined)
            );

            console.log("commercial_data: ", value["commercial_data"]);
          }

          let contractDocDetails =
            contractDocData && contractDocData.length !== 0
              ? contractDocData.map((contractDoc) => {
                  return {
                    version:
                      contractDoc.version.props.children[0].props.children,
                    updated_by:
                      contractDoc.updated_by.props.children[1].props.children,
                    updated_by_id: contractDoc.updated_by_id,
                    updated_on:
                      contractDoc.updated_on.props.children[1].props.children,
                    file_name: contractDoc.file_name,
                  };
                })
              : [];

          let data = {
            contract_name: value.info.contract["Title"],
            contract_type:
              state.contractType === "Framework Agreement"
                ? "MSA"
                : state.contractType,
            supplier_id: state.supplier_id,
            contract_status:
              buttonState === 1 ? "Contract-in-Draft" : "Contract-Submitted",
            workflow_status: buttonState === 1 ? "Draft" : "Submit",
            parent_type: state.parent.type,
            parent_cid: state.parent.id,
            signed_on: new Date(),
            last_status_change_date: new Date(),
            created_by: getUser,
            start_date:
              value.info.contract["Contract Start Date"] &&
              value.info.contract["Contract Start Date"],
            end_date:
              value.info.contract["Contract End Date"] &&
              value.info.contract["Contract End Date"],
            current_owner: buttonState === 1 ? getUser : approverId,
            contract_metadata: value,
            commercial_data: value.commercial_data,
            // && value.commercial_data.length === 0
            // ? []
            // : value.commercial_data &&
            //   value.commercial_data.length !== 0 &&
            //   value.commercial_data.map((data) => {
            //     return {
            //       ["RtItem Id"]: data["RtItem Id"]
            //         ? data["RtItem Id"]
            //         : data["Item Id"],
            //       ["Start Date"]: data["Start Date"],
            //       ["End Date"]: data["End Date"],
            //       ["Days"]:
            //         Object.keys(data["Days"] || {}).length === 0
            //           ? {}
            //           : data["Days"],
            //       ["Resource Id"]: data["Resource Id"],
            //       ["Status"]: data["Status"] ? data["Status"] : "Active",
            //     };
            //   }),
            contract_template: state.contract_template,
            ratetable_id: rtData && rtData._id && rtData._id,
            workflow_id: state.workflow_id,
            checkedSd: checkedSd,
            checkedEd: checkedEd,
            rootData: rootData,
            author:
              userData && userData[0] && userData[0].id ? userData[0].id : "",
            author_name:
              userData && userData[0] && userData[0].first_name
                ? userData[0].first_name
                : "",
            comments: comments ? comments : [],
            contract_doc_details: contractDocDetails,
            docusign_envelope_id: "",
          };
          console.log("contractData to store", data);

          console.log("contractFile", contractFile);
          console.log(
            "contractFile List",
            contractFile && contractFile.map((file) => file.originFileObj)
          );
          // Prepare form data
          const uploadForm = new FormData();
          if (contractFile && contractFile.length > 0) {
            for (let i = 0; i < contractFile.length; i++) {
              uploadForm.append(
                "notesCommentsFiles",
                contractFile[i].originFileObj
              );
            }
          }

          if (contractDocFileList && contractDocFileList.length > 0) {
            for (let i = 0; i < contractDocFileList.length; i++) {
              uploadForm.append(
                "contractDocFiles",
                contractDocFileList[i].originFileObj
              );
            }
          }
          uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
          console.log("formdata", uploadForm);

          let resMsg =
            buttonState === 1
              ? "Contract Updated & Saved Successfully."
              : "Contract Updated & Submitted Successfully.";

          if (buttonState === 1) {
            if (fileKeysToDelete.length !== 0) {
              fileKeysToDelete.map(async (fileKey) => {
                await deleteS3FileApi({ fileKey: fileKey, flag: "contract" });
              });
            }
            if (createdId === "") {
              createContractAPi(uploadForm).then((res) => {
                setCreatedId(res._id);
                console.log("res*****", res);
                console.log("uploadedcontractData", uploadForm.contract_name);
                console.log("resContractNAme", res.contractName);
                console.log("state contractNAme", state.contract_name);
                setSaveLoader(false);
                setSubmitLoader(false);
                popupModalInstance.successModal(
                  state.title,
                  ModalMessage.CC_Save,
                  ""
                );
                // .then(async (userChoice) => {
                // if (userChoice === "ok") {
                //   history(
                //     "/contract",
                //     setHeaderChanger({
                //       header: "contract",
                //       headerItem: "2",
                //       headerItemName: "contractPipeline",
                //     })
                //   );
                // }
                // });
              });
            } else {
              editContractAPi(createdId, uploadForm).then((res) => {
                console.log("res", res);
                setSaveLoader(false);
                setSubmitLoader(false);
                Modal.success({
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: <div>{resMsg}</div>,
                  // onOk() {
                  //   // history(
                  //   //   `/contract`,
                  //   //   setHeaderChanger({
                  //   //     headerItem: "2",
                  //   //   })
                  //   // );
                  //   // history(
                  //   //   "/contract",
                  //   //   setHeaderChanger({
                  //   //     header: "contract",
                  //   //     headerItem: "2",
                  //   //     headerItemName: "contractPipeline",
                  //   //   })
                  //   // );
                  // },
                });
              });
            }
          }

          if (buttonState === 2) {
            if (createdId === "") {
              popupModalInstance
                .infoModal(ModalMessage.CC_Submit)
                .then(async (userChoice) => {
                  if (userChoice === "ok") {
                    if (fileKeysToDelete.length !== 0) {
                      fileKeysToDelete.map(async (fileKey) => {
                        await deleteS3FileApi({
                          fileKey: fileKey,
                          flag: "contract",
                        });
                      });
                    }
                    createContractAPi(uploadForm).then((res) => {
                      setCreatedId(res._id);
                      setSaveLoader(false);
                      setSubmitLoader(false);
                      history(
                        "/contract",
                        setHeaderChanger({
                          header: "contract",
                          headerItem: "2",
                          headerItemName: "contractPipeline",
                        })
                      );
                    });
                  }
                  if (userChoice === "cancel") {
                    setSubmitLoader(false);
                  }
                });
            } else {
              popupModalInstance
                .infoModal(ModalMessage.CC_Submit)
                .then(async (userChoice) => {
                  if (userChoice === "ok") {
                    if (fileKeysToDelete.length !== 0) {
                      fileKeysToDelete.map(async (fileKey) => {
                        await deleteS3FileApi({
                          fileKey: fileKey,
                          flag: "contract",
                        });
                      });
                    }
                    editContractAPi(createdId, uploadForm).then((res) => {
                      setSaveLoader(false);
                      setSubmitLoader(false);
                      history(
                        "/contract",
                        setHeaderChanger({
                          header: "contract",
                          headerItem: "2",
                          headerItemName: "contractPipeline",
                        })
                      );
                    });
                  }
                  if (userChoice === "cancel") {
                    setSubmitLoader(false);
                  }
                });
            }
          }
        }
      } else {
        popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  /* Function to handle submit form failed
  @ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo", errorInfo);
    popupModalInstance.warningModal(ModalMessage.CC_Submit_Warnimg);
  };

  const [refValue, setRefValue] = useState(null);

  useEffect(() => {
    console.log("daysRef.current: ", daysRef.current);
  }, [refValue]);

  // function handle the new contract data and days
  useEffect(() => {
    console.log("newContractData: ", newContractData);

    let newConOutput =
      newContractData && newContractData.length !== 0
        ? newContractData.map((item) => {
            if (item.key in daysRef.current) {
              console.log("item.key: ", item.key);

              if (item.addedType === "new") {
                return { ...item, Days: daysRef.current[item.key] };
              } else {
                const diff = {};
                const itemDays = item.Days;
                const daysData = daysRef.current[item.key];

                for (const year in itemDays || {}) {
                  if (year in daysData || {}) {
                    const yearDiff = {};
                    const itemYear = itemDays[year];
                    const daysYear = daysData[year];

                    for (const month in daysYear) {
                      if (month in itemYear) {
                        const monthDiff = daysYear[month] - itemYear[month];
                        if (monthDiff !== 0) {
                          yearDiff[month] = monthDiff;
                        }
                      } else {
                        yearDiff[month] = daysYear[month];
                      }
                    }

                    if (Object.keys(yearDiff || {}).length > 0) {
                      diff[year] = yearDiff;
                    }
                  } else {
                    diff[year] = itemDays[year];
                  }
                }

                for (const year in daysData) {
                  if (!(item.Days && item.Days[year])) {
                    diff[year] = daysData[year];
                  }
                }

                if (Object.keys(diff || {}).length > 0) {
                  return { ...item, Days: diff };
                } else {
                  const { Days, ...rest } = item;
                  return rest;
                }
              }
            } else {
              return item;
            }
          })
        : [];

    console.log("commercialRef: ", commercialRef.current);
    console.log("newConOutput: ", newConOutput);

    newConOutput =
      newConOutput &&
      newConOutput.length !== 0 &&
      newConOutput.map((data) => {
        return {
          ...data,
          ["Price"]:
            commercialRef.current &&
            commercialRef.current[data.key] &&
            commercialRef.current[data.key].length === 1
              ? commercialRef.current[data.key]["Price"]
              : "",
          contract_name: state.title,
          contract_type: state.contractType,
          parent_cid: state.parent.id,
          contract_id: "TEMP_0000",
        };
      });

    aggregateContractDataRef.current = {
      ...aggregateContractDataRef.current,
      newContract: newConOutput,
    };
  }, [refValue, newContractData]);

  const [menuState, setMenuState] = useState("contract");
  const [commercialMenuState, setCommercialMenuState] = useState("contract");

  // function handle the component box layout
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onDeleteFile = async (filename) => {
    try {
      setNotesTableData((prevTableNewData) => {
        const updatedTableNewData = prevTableNewData.filter(
          (document) => document.document_name.props.children !== filename
        );
        return updatedTableNewData;
      });

      setComments((prevComments) =>
        prevComments.filter((comment) => comment.content !== filename)
      );
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // download uploaded file
  const onDownloadNewFile = async (fileInfo) => {
    console.log("fileInfo", fileInfo);
    // Extract necessary information
    const { name, originFileObj } = fileInfo;

    // Create a blob from the file object
    const blob = new Blob([originFileObj], { type: originFileObj.type });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = name;

    // Programmatically trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  // document file props
  const contractDocProps = {
    name: "file",
    listType: "picture",
    showUploadList: true,
    accept: ".docx,.doc,.pdf",
    onChange(info) {
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setNewDocFileUpload(info.fileList[0]);
          setDisableUpload(false);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/pdf",
      ];
      const formatCheck = acceptedFormats.includes(file.type);

      if (!formatCheck) {
        message.error({
          content: "You can only upload DOCX or DOC or pdf files.",
          top: 150,
        });
        return Upload.LIST_IGNORE;
      }

      return formatCheck;
    },
    onRemove() {
      setNewDocFileUpload();
      setDisableUpload(true);
    },
  };

  const notesFileProps = {
    name: "file",
    listType: "picture",
    showUploadList: false,
    // accept: ["xlsx", "xls"],
    // maxCount: 1,
    onChange(info) {
      const maxSize = 25 * 1024 * 1024;

      if (info.file.size > maxSize) {
        // Checking if the file is within the 25MB limit
        message.error({
          content: `${info.file.name} is too large. Please upload a file smaller than 25MB.`,
          top: 100,
        });
        return;
      }

      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setContractFile(info.fileList);
          setComments((prevComments) => [
            ...prevComments,
            { type: "file", content: `${info.file.name}` },
          ]);
          let data = [
            {
              document_name: (
                <span
                  style={{
                    color: "var(--color-solid-darkblue)",
                    fontWeight: "100",
                  }}
                  onClick={() => {}}
                >
                  {info.file.name}
                </span>
              ),
              uploaded_on: moment(new Date()).format("DD MMM YYYY"),
              uploaded_by: userData[0].first_name,
              action: (
                <>
                  <div
                    style={{
                      justifyContent: "space-evenly",
                      display: "flex",
                    }}
                  >
                    <DownloadOutlined
                      title="Download"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        onDownloadNewFile(info.file);
                      }}
                    />
                    <Image
                      title="Delete"
                      src={deleteIcon}
                      preview={false}
                      style={{ width: "20px" }}
                      className="cursor-pointer"
                      onClick={() => onDeleteFile(info.file.name)}
                    />
                  </div>
                </>
              ),
            },
          ];
          setNotesTableData([...data, ...notesTableData]);
        } else {
          setContractFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
  };

  /* Function to uplaod the file  and set succes
   * @param file - upload file imformation
   * @param onSuccess - indicate success while file upload correctly
   */
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // function handle the reset main form from table
  useEffect(() => {
    mainForm.setFieldsValue({
      commercial_data:
        dataSourceCom &&
        dataSourceCom.length !== 0 &&
        dataSourceCom.map((data) => {
          return {
            ...data,
            ["Start Date"]: dayjs(new Date(data["Start Date"])),
            ["End Date"]: dayjs(new Date(data["End Date"])),
          };
        }),
    });
  }, [dataSourceCom]);

  const versionHistoryColumns = [
    {
      title: "Document Type",
      dataIndex: "document_type",
      key: "document_type",
      render: () => (
        <p
          style={{
            fontSize: "15px",
            padding: "0px 8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          Commercial <Image Group src={Group} preview={false}></Image>
        </p>
      ),
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
      key: "document_name",
    },
    {
      title: "Version Number",
      dataIndex: "version_number",
      key: "version_number",
    },
    {
      title: "Version Date",
      dataIndex: "version_date",
      key: "version_date",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploaded_by",
      key: "uploaded_by",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <>
          <div
            style={{
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            <Image src={visibility} preview={false} />
            <Image src={Downloading} preview={false} />
            <Image src={check_box} preview={false} />
          </div>
        </>
      ),
    },
  ];

  const contractDocColumns = [
    {
      title: "Version",
      dataIndex: "version",
      ellipsis: true,
      width: 200,
    },
    {
      title: "Last Updated Contract",
      dataIndex: "last_updated_contract",
      ellipsis: true,
      width: 170,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      ellipsis: true,
      width: 350,
    },
    {
      title: "Updated On",
      dataIndex: "updated_on",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      ellipsis: true,
      width: 100,
    },
  ];

  useEffect(() => {
    // Compute the changed lines
    const diff = diffLines(text1, text2);
    const changes = [];
    let leftLineNumber = 0;
    let rightLineNumber = 0;

    diff.forEach((part) => {
      const lines = part.value.split("\n");
      lines.pop(); // Remove the last empty element due to split on '\n'

      lines.forEach((line, index) => {
        if (part.added || part.removed) {
          changes.push({
            left: part.removed ? leftLineNumber + 1 + index : null,
            right: part.added ? rightLineNumber + 1 + index : null,
          });
        }
      });

      if (!part.added) {
        leftLineNumber += lines.length;
      }
      if (!part.removed) {
        rightLineNumber += lines.length;
      }
    });

    if (changes.length > 0) {
      const firstChange = changes[0];
      const highlights = [];
      if (firstChange.left !== null) highlights.push(`L-${firstChange.left}`);
      if (firstChange.right !== null) highlights.push(`R-${firstChange.right}`);
      setHighlightLines(highlights);
      // setTimeout(() => scrollToHighlight(firstChange), 0);
    }

    setChangedLines(changes);
    if (text1.length !== 0 && text2.length !== 0) {
      const table = viewerRef.current.querySelector("table");
      if (table) {
        const rows = table.querySelectorAll("tr");
        console.log("rows:", rows);
      }
    }
  }, [text1, text2]);

  const handleNext = () => {
    if (currentLineIndex < changedLines.length - 1) {
      const nextIndex = currentLineIndex + 1;
      setCurrentLineIndex(nextIndex);
      const line = changedLines[nextIndex];
      const highlights = [];
      if (line.left !== null) highlights.push(`L-${line.left}`);
      if (line.right !== null) highlights.push(`R-${line.right}`);
      setHighlightLines(highlights);
    }
  };

  const handlePrevious = () => {
    if (currentLineIndex > 0) {
      const prevIndex = currentLineIndex - 1;
      setCurrentLineIndex(prevIndex);
      const line = changedLines[prevIndex];
      const highlights = [];
      if (line.left !== null) highlights.push(`L-${line.left}`);
      if (line.right !== null) highlights.push(`R-${line.right}`);
      setHighlightLines(highlights);
    }
  };

  const scrollToLine = (highlight) => {
    if (viewerRef.current) {
      const table = viewerRef.current.querySelector("table");
      if (table) {
        const rows = Array.from(table.querySelectorAll("tr"));
        rows.shift(); // Remove the first row if it's a header row

        const [side, lineNumber] = highlight[0].split("-");
        const targetLineNumber = parseInt(lineNumber) - 1;

        for (const row of rows) {
          const cells = row.cells;
          let cell;

          if (side === "L") {
            cell = cells[0];
          } else if (side === "R") {
            cell = cells[3];
          }

          if (cell) {
            const pre = cell.childNodes[0];
            if (pre && pre.childNodes[0]) {
              const textContent = pre.childNodes[0].textContent.trim();
              if (parseInt(textContent, 10) === targetLineNumber) {
                row.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                break;
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (highlightLines.length !== 0) scrollToLine(highlightLines);
  }, [highlightLines]);

  const updateCommercialData = (
    commercialTableData,
    yearsListData,
    daysData,
    costsData
  ) => {
    commercialRef.current = commercialTableData;
    yearsListRef.current = yearsListData;
    daysRef.current = daysData;
    costsRef.current = costsData;
    aggregateContractDataRef.current = {
      ...aggregateContractDataRef.current,
      cost: costsData,
      days: daysData,
    };
    // console.log("commercialRef.current: ", commercialRef.current);
    console.log("daysRef.current: ", daysRef.current);
    console.log("costsRef.current: ", costsRef.current);
  };

  const prepareCommercialData = (commericalExtractionData) => {
    // const commericalExtractionData = [
    //   [
    //     {
    //       type: "COST_PER_DAY",
    //       mentionText: "80",
    //     },
    //     {
    //       type: "DAYS",
    //       mentionText: "20",
    //     },
    //     {
    //       type: "END_DATE",
    //       mentionText: "01/04/2025",
    //     },
    //     {
    //       type: "GRADE",
    //       mentionText: "C",
    //     },
    //     {
    //       type: "QUANTITY",
    //       mentionText: "4",
    //     },
    //     {
    //       type: "RATE_BASIS",
    //       mentionText: "T&M",
    //     },
    //     {
    //       type: "ROLE",
    //       mentionText: "Full Stack Developer",
    //     },
    //     {
    //       type: "START_DATE",
    //       mentionText: "01/03/2025",
    //     },
    //     {
    //       type: "TOTAL_COST",
    //       mentionText: "3200",
    //     },
    //   ],
    //   [
    //     {
    //       type: "COST_PER_DAY",
    //       mentionText: "120",
    //     },
    //     {
    //       type: "DAYS",
    //       mentionText: "20",
    //     },
    //     {
    //       type: "END_DATE",
    //       mentionText: "01/04/2025",
    //     },
    //     {
    //       type: "GRADE",
    //       mentionText: "B",
    //     },
    //     {
    //       type: "QUANTITY",
    //       mentionText: "4",
    //     },
    //     {
    //       type: "RATE_BASIS",
    //       mentionText: "T&M",
    //     },
    //     {
    //       type: "ROLE",
    //       mentionText: "Senior Analyst",
    //     },
    //     {
    //       type: "START_DATE",
    //       mentionText: "01/03/2025",
    //     },
    //     {
    //       type: "TOTAL_COST",
    //       mentionText: "4800",
    //     },
    //   ],
    // ];

    const getMonthsInRange = (startDate, endDate) => {
      const months = [];
      let currentDate = dayjs(startDate);

      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "month")
      ) {
        months.push({
          year: currentDate.year(),
          month: currentDate.format("MMM").toLowerCase(),
        });
        currentDate = currentDate.add(1, "month");
      }

      return months;
    };

    // Function to transform the data
    const transformData = (data) => {
      const result = [];

      data.forEach((itemArray) => {
        let days = 0;
        let cost = 0;
        let startDate, endDate;
        let quantity = 1;

        // Process each entry and map type to appropriate keys
        const baseObject = {};

        itemArray.forEach(({ type, mentionText }) => {
          switch (type) {
            case "DAYS":
              days = parseInt(mentionText);
              break;
            case "END_DATE":
              endDate = dayjs(mentionText, "DD/MM/YYYY"); // Store as dayjs object
              baseObject["End Date"] = endDate; // Keep as dayjs
              break;
            case "START_DATE":
              startDate = dayjs(mentionText, "DD/MM/YYYY"); // Store as dayjs object
              baseObject["Start Date"] = startDate; // Keep as dayjs
              break;
            case "TOTAL_COST":
              cost = parseInt(mentionText);
              break;
            case "GRADE":
              baseObject["Grade"] = mentionText;
              break;
            case "QUANTITY":
              quantity = parseInt(mentionText);
              break;
            case "RATE_BASIS":
              baseObject["Rate Basis"] = mentionText;
              break;
            case "RATE_PERIOD":
              baseObject["Rate Period"] = mentionText;
              break;
            case "LOCATION":
              baseObject["Location"] = mentionText;
              break;
            case "CURRENCY":
              baseObject["Currency"] = mentionText;
              break;
            case "ROLE":
              baseObject["Role"] = mentionText;
              break;
            case "PRICE":
              baseObject["Price"] = mentionText;
              break;
            default:
              break;
          }
        });

        // Calculate the distribution of days and costs
        const monthsInRange = getMonthsInRange(startDate, endDate);
        const daysPerMonth = Math.floor(days / monthsInRange.length);
        const remainingDays = days % monthsInRange.length;
        const costPerMonth = Math.floor(cost / monthsInRange.length);
        const remainingCost = cost % monthsInRange.length;

        const daysObj = {};
        const costObj = {};

        // Distribute days and costs across months
        monthsInRange.forEach((month, index) => {
          const { year, month: monthKey } = month;
          if (!daysObj[year]) daysObj[year] = {};
          if (!costObj[year]) costObj[year] = {};

          daysObj[year][monthKey] =
            daysPerMonth + (index < remainingDays ? 1 : 0);
          costObj[year][monthKey] =
            costPerMonth + (index < remainingCost ? 1 : 0);
        });

        baseObject["Days"] = daysObj;
        baseObject["Cost"] = costObj;

        // Duplicate the object based on the quantity
        for (let i = 0; i < quantity; i++) {
          result.push({ ...baseObject });
        }
      });

      return result;
    };

    const transformedData = transformData(commericalExtractionData);
    console.log("transformedData1", transformedData);
    daysRef.current = {
      ...transformedData.map((data, index) => (index = data["Days"])),
    };
    console.log("daysRef.current", daysRef.current);
    costsRef.current = {
      ...transformedData.map((data, index) => (index = data["Cost"])),
    };
    console.log("costsRef.current", costsRef.current);
    let initialYearsList = {};
    transformedData &&
      transformedData.length !== 0 &&
      transformedData.map((data) => {
        const startDate = new Date(data["Start Date"]);
        const endDate = new Date(data["End Date"]);
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        let output = {};
        let currentYear = startYear;
        let currentMonth = startDate.getMonth(); // 0 (January) to 11 (December)
        while (
          currentYear < endYear ||
          (currentYear === endYear && currentMonth <= endDate.getMonth())
        ) {
          const monthAbbreviation = dayjs()
            .month(currentMonth)
            .format("MMM")
            .toLowerCase();
          if (!output[currentYear]) {
            output[currentYear] = [];
          }
          output[currentYear].push(monthAbbreviation);
          if (currentMonth === 11) {
            // December, move to next year
            currentMonth = 0;
            currentYear++;
          } else {
            currentMonth++;
          }
        }
        initialYearsList = {
          ...initialYearsList,
          [data["key"]]: output,
        };
      });
    console.log("initialYearsList", initialYearsList);
    // setYearsList(initialYearsList);
    yearsListRef.current = initialYearsList;
    return transformedData;
  };

  // function handle the functionality for commerical tab
  const commercialTab = () => {
    const ContractMenuContent = () => {
      return (
        <div style={{ margin: "10px 10px 10px 20px" }}>
          {comTable ? (
            <CommercialTable
              state={state}
              rateCardData={{
                assembledData:
                  commercialRateCard && commercialRateCard.length !== 0
                    ? [...commercialRateCard]
                    : [],
                disassembledData: {
                  keys: rtData && rtData.rt_items_desc,
                  values: rtData && rtData.rt_items_detail,
                },
              }}
              rateCardTemplate={rateCardTemplate}
              parentCommercialRef={
                state.commercialExtractionData
                  ? prepareCommercialData(state.commercialExtractionData)
                  : commercialRef
                // prepareCommercialData(state.commercialExtractionData)
              }
              parentYearsListRef={yearsListRef}
              parentDaysRef={daysRef}
              parentCostsRef={costsRef}
              contractStartDateRef={contractStartDateRef}
              contractEndDateRef={contractEndDateRef}
              onUpdateCommercialData={updateCommercialData}
            />
          ) : (
            <div className="text-center mt-15 no-table-text">
              Note: Please Select Pricing Profile in Contract Info tab to Fill
              Commercial data.
            </div>
          )}
        </div>
      );
    };

    const AggregateMenuContent = () => {
      return (
        <Row style={{ marginLeft: 20 }}>
          <Col span={24}>
            <div className="contract-info-view">
              <AggregateValue contractData={aggregateContractDataRef.current} />
            </div>
          </Col>
        </Row>
      );
    };

    const VersionHistory = () => {
      return (
        <Row className="m-20">
          <DataTable
            type="view-contract"
            dataTableData={contractDocData}
            colData={versionHistoryColumns}
          />
        </Row>
      );
    };

    const getItem = (label, key, icon, children, type) => {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    };

    const menuitems = [
      getItem(
        "Contract Value",
        "1",
        commercialMenuState === "contract" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "Aggregate Value",
        "2",
        commercialMenuState === "aggregate" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "Version History",
        "3",
        commercialMenuState === "version" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
    ];

    return (
      <div>
        <div className="outer-tab-div">
          <div className="inner-tab-div">
            <Row type="flex">
              <Col md={4} lg={4} xl={3}>
                <Menu
                  onClick={(key) => {
                    if (key.key === "1") {
                      setCommercialMenuState("contract");
                    } else if (key.key === "2") {
                      setRefValue(daysRef.current);
                      setCommercialMenuState("aggregate");
                    } else if (key.key === "3") {
                      setCommercialMenuState("version");
                    }
                  }}
                  defaultSelectedKeys={["1"]}
                  mode="vertical"
                  items={menuitems}
                />
              </Col>
              <Col md={20} lg={20} xl={21}>
                {commercialMenuState === "contract" ? (
                  <ContractMenuContent />
                ) : commercialMenuState === "aggregate" ? (
                  <AggregateMenuContent />
                ) : commercialMenuState === "version" ? (
                  <VersionHistory />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const approvalFlowChart = () => {
    return (
      <div className="outer-tab-div">
        <FlowDiagram
          flowData={flowData && flowData}
          from={"create-contract-view"}
        />
      </div>
    );
  };

  const onUploadFinish = (values) => {
    console.log("uploadValues: ", values);
    const newKey = contractDocData.length;
    const newVersionNumber =
      contractDocData.length === 0 ? 1 : contractDocData.length + 1;

    let newUpload = {
      key: newKey,
      version: (
        <div style={{ display: "flex" }}>
          <span
            style={{ color: "var(--color-solid-darkerblue)", fontWeight: 600 }}
          >{`Version ${newVersionNumber}`}</span>
          <Image
            src={VersionIcon}
            preview={false}
            height={20}
            width={20}
            style={{ marginTop: -6, marginLeft: 8 }}
          />
        </div>
      ),
      file_content:
        values.uploaded_doc.fileList.length !== 0
          ? values.uploaded_doc.fileList[0].originFileObj
          : "",
      file_name: values.uploaded_doc.file.name,
      last_updated_contract: <SwapRightOutlined className="contract-arrow" />,
      updated_on: (
        <div style={{ display: "flex" }}>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkergrey)" }}
          >
            Updated On&nbsp;:&nbsp;
          </span>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkestblue)" }}
          >{`${dayjs(new Date()).format("DD/MM/YYYY")}`}</span>
        </div>
      ),
      updated_by: (
        <div style={{ display: "flex" }}>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkergrey)" }}
          >
            Updated By&nbsp;:&nbsp;
          </span>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkestblue)" }}
          >{`${userData[0].first_name + " " + userData[0].last_name}`}</span>
        </div>
      ),
      updated_by_id: userData[0].id ? userData[0].id : userData[0]._id,
      action: (
        <span
          style={{
            fontWeight: 600,
            color: "var(--color-solid-darkerblue)",
            cursor: "pointer",
          }}
        >
          View
        </span>
      ),
    };
    setContractDocData((prev) => [newUpload, ...prev]);
    setContractDocFileList((docFileList) => [...docFileList, newDocFileUpload]);
    setUploadContractDoc(false);
    setDisableUpload(true);
    uploadForm.resetFields();
  };

  const onUploadFinishFailed = (err) => {
    console.log("uploadErr: ", err);
  };

  // rowSelection object indicates the need for Contract Doc row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        selectedRows.length >= 2 &&
        !selectedRows.map((row) => row.key).includes(record.key),
      name: record.name,
    }),
  };

  const contractDoc = () => {
    const formItemStyle = {
      border: "1px solid var(--color-solid-blue)",
      borderRadius: 8,
      height: 32,
    };

    const versionContainerStyle = {
      borderRadius: 10,
      padding: 10,
      height: "calc(100vh - 250px)",
      whiteSpace: "pre-wrap",
      overflowY: "scroll",
    };

    const getVersionNumber = () => {
      return [
        Math.min(
          selectedRows.length !== 0 &&
            selectedRows[0] &&
            selectedRows[0].version.props.children[0].props.children.split(
              " "
            )[1],
          selectedRows.length > 1 &&
            selectedRows[1].version.props.children[0].props.children.split(
              " "
            )[1]
        ),
        Math.max(
          selectedRows.length !== 0 &&
            selectedRows[0] &&
            selectedRows[0].version.props.children[0].props.children.split(
              " "
            )[1],
          selectedRows.length > 1 &&
            selectedRows[1].version.props.children[0].props.children.split(
              " "
            )[1]
        ),
      ];
    };

    const diffViewerStyles = {
      wordAdded: {
        padding: "0px 5px",
        borderRadius: "5px",
      },
      wordRemoved: {
        padding: "0px 5px",
        borderRadius: "5px",
      },
      // highlightedLine: {
      //   background: "rgba(255, 235, 59, 0.3)",
      // },
    };

    return (
      <div className="outer-tab-div">
        <div style={{ padding: "3px 20px" }}>
          {!viewedFile ? (
            <>
              <Row className="mt-10 mb-15" justify="end">
                <Col span={3} style={{ marginRight: 10 }}>
                  <CompareButton
                    disabled={selectedRows.length === 2 ? false : true}
                    onClick={() => {
                      setCompareModal(true);
                    }}
                  />
                </Col>
                <Col span={3}>
                  <UploadButton onClick={() => setUploadContractDoc(true)} />
                  <Modal
                    title={
                      <div style={{ display: "flex" }}>
                        <Image
                          src={UploadButtonIcon}
                          preview={false}
                          style={{ paddingRight: 10 }}
                        />
                        <p>Upload Document</p>
                      </div>
                    }
                    width={400}
                    centered
                    open={uploadContractDoc}
                    footer={null}
                    onCancel={() => setUploadContractDoc(false)}
                  >
                    <Form
                      form={uploadForm}
                      className="mt-30 create-role"
                      onFinish={onUploadFinish}
                      onFinishFailed={onUploadFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="document_type"
                            label="Document Type"
                            labelCol={{ span: 9 }}
                            labelAlign="left"
                            colon={false}
                          >
                            <Input
                              readOnly
                              style={formItemStyle}
                              defaultValue="Contract"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="uploaded_doc"
                            label="Upload Document"
                            labelCol={{ span: 24 }}
                            labelAlign="left"
                            colon={false}
                          >
                            <Upload
                              name="uploaded_document"
                              {...contractDocProps}
                              customRequest={dummyRequest}
                              headers={{
                                "Access-Control-Allow-Origin": "*",
                              }}
                              maxCount={1}
                              className="custom-upload-wrapper"
                            >
                              <Col span={24}>
                                <Button
                                  style={{
                                    ...formItemStyle,
                                    fontSize: 13,
                                    color: "var(--color-solid-darkerblue)",
                                    width: "100%",
                                  }}
                                >
                                  Browse
                                </Button>
                              </Col>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex" align="middle">
                        <Col
                          span={24}
                          align="middle"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Col span={8} style={{ marginRight: 5 }}>
                            <CancelButton
                              onClick={() => setUploadContractDoc(false)}
                            />
                          </Col>
                          <Col span={8} style={{ marginLeft: 5 }}>
                            <UploadButton
                              htmlType="submit"
                              disabled={disableUpload}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                  <Modal
                    title={
                      <>
                        <div style={{ display: "flex" }}>
                          <Image
                            src={CompareIcon}
                            preview={false}
                            style={{ paddingRight: 10 }}
                          />
                          <p>Compare versions of documents</p>
                        </div>
                      </>
                    }
                    width="98%"
                    centered
                    open={compareModal}
                    footer={null}
                    onCancel={() => {
                      setText1("");
                      setText2("");
                      setChangedLines([]);
                      setHighlightLines([]);
                      setCurrentLineIndex(0);
                      setCompareModal(false);
                    }}
                  >
                    <Row
                      type="flex"
                      align="middle"
                      justify="start"
                      className="mb-10"
                      gutter={[0, 0]}
                    >
                      <Col span={9} offset={11}>
                        <div style={{ display: "flex" }}>
                          <Button
                            className="move-up-btn"
                            style={{ marginLeft: "4%", marginRight: 15 }}
                            icon={<CaretLeftOutlined />}
                            onClick={handlePrevious}
                            disabled={currentLineIndex <= 0}
                            title="Highlight Previous Change"
                          />
                          <Button
                            className="move-down-btn"
                            icon={<CaretRightOutlined />}
                            onClick={handleNext}
                            disabled={
                              currentLineIndex >= changedLines.length - 1
                            }
                            title="Highlight Next Change"
                          />
                        </div>
                      </Col>
                      <Col span={2} align="right">
                        <Switch
                          checkedChildren={
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                              }}
                            >
                              Unified View
                            </p>
                          }
                          unCheckedChildren={
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                              }}
                            >
                              Split View
                            </p>
                          }
                          onChange={() => setSplitView(!splitView)}
                          onClick={() => setSplitView(!splitView)}
                        />
                      </Col>
                      <Col span={2} align="right">
                        <Switch
                          checkedChildren={
                            <p style={{ fontWeight: 600, fontSize: 10 }}>
                              Dark Mode
                            </p>
                          }
                          unCheckedChildren={
                            <p style={{ fontWeight: 600, fontSize: 10 }}>
                              Light Mode
                            </p>
                          }
                          onChange={() => setDarkMode(!darkMode)}
                          onClick={() => setDarkMode(!darkMode)}
                        />
                      </Col>
                    </Row>
                    <div
                      style={{
                        border: "1px solid var(--color-solid-darkergrey)",
                        ...versionContainerStyle,
                      }}
                      ref={viewerRef}
                    >
                      {text1.length !== 0 && text2.length !== 0 ? (
                        <ReactDiffViewer
                          styles={diffViewerStyles}
                          oldValue={text1}
                          newValue={text2}
                          splitView={splitView}
                          leftTitle={
                            splitView ? (
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 13,
                                  paddingLeft: 10,
                                }}
                              >{`Version ${getVersionNumber()[0]}`}</p>
                            ) : (
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 13,
                                  paddingLeft: 10,
                                }}
                              >{`Unified view of Version ${
                                getVersionNumber()[0]
                              } and Version ${getVersionNumber()[1]}`}</p>
                            )
                          }
                          rightTitle={
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 13,
                                paddingLeft: 10,
                              }}
                            >{`Version ${getVersionNumber()[1]}`}</p>
                          }
                          useDarkTheme={darkMode}
                          highlightLines={highlightLines}
                          compareMethod={"diffWords"}
                        />
                      ) : (
                        <Skeleton active paragraph={{ rows: 13 }} />
                      )}
                    </div>
                    <Row
                      type="flex"
                      align="middle"
                      justify="center"
                      className="mt-20"
                    >
                      <Col span={2} style={{ marginLeft: 5 }}>
                        <OkButton
                          onClick={() => {
                            setText1("");
                            setText2("");
                            setChangedLines([]);
                            setHighlightLines([]);
                            setCurrentLineIndex(0);
                            setCompareModal(false);
                          }}
                        />
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
              <Row
                style={{
                  borderTop: "2px solid var(--color-solid-lightgrey)",
                }}
              >
                <Col span={24}>
                  <Table
                    pagination={false}
                    showHeader={false}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    columns={contractDocColumns}
                    dataSource={contractDocData}
                    scroll={{ y: "calc(100vh - 235px)" }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            url.length !== 0 &&
            !url[0].newUpload && (
              <>
                <div
                  className="cursor-pointer m-10"
                  onClick={() => {
                    setViewedFile(false);
                    setUrl([
                      {
                        fileType: "docx",
                      },
                    ]);
                  }}
                >
                  <Image
                    src={arrow_left}
                    style={{ marginLeft: 10, width: 25 }}
                    preview={false}
                  />
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 13,
                      paddingLeft: 20,
                      color: "var(--color-solid-darkergrey)",
                    }}
                  >
                    &nbsp;{url && url[0] && url[0].fileName}
                  </span>
                </div>
                <div style={{ padding: "0px 20px" }}>
                  <DocViewer
                    className="doc-viewer"
                    pluginRenderers={DocViewerRenderers}
                    documents={url}
                    style={{ height: "calc(100vh - 230px)" }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                      pdfZoom: {
                        defaultZoom: 0.7, // 1 as default,
                      },
                      pdfVerticalScrollByDefault: true, // false as default
                    }}
                  />
                </div>
              </>
            )
          )}
        </div>
      </div>
    );
  };

  const notesAndDocuments = () => {
    return (
      <>
        <div className="notes-and-documents">
          <Row>
            <Col span={24}>
              <div
              // className="notes-and-documents"
              // className={pipelinePath ? "pipeline-org-view" : "org-view-div"}
              >
                <div className="m-20">
                  <div
                    className="pipeline-documents-div"
                    // className={
                    //   pipelinePath ? "pipeline-documents-div" : "documents-div"
                    // }
                  >
                    <Row
                      className="p-10"
                      style={{ borderBottom: "1px solid var(--color-border)" }}
                    >
                      <Col span={24} align="center">
                        <p
                          style={{
                            fontSize: 13,
                            fontWeight: 600,
                            color: "var(--color-solid-darkergrey)",
                          }}
                        >
                          Notes & Comments
                        </p>
                      </Col>
                    </Row>
                    <div className="p-5">
                      <div className="contract-comments-div">
                        <div className="contract-comments-align-right">
                          {comments &&
                            comments.length !== 0 &&
                            comments.map((comment) => {
                              return (
                                <div className="mt-15">
                                  <div
                                    className="contract-author-data"
                                    style={{ textAlign: "end" }}
                                  >
                                    <span>
                                      <UserOutlined
                                        style={{ fontSize: "11px" }}
                                      />
                                    </span>
                                    &nbsp; You
                                  </div>
                                  {comment.type === "text" ? (
                                    <div className="contract-comments-new">
                                      {" "}
                                      {comment.content}
                                    </div>
                                  ) : (
                                    <div className="contract-comments-new">
                                      <span>
                                        <FileOutlined
                                          style={{
                                            color:
                                              "var(--color-solid-darkblue)",
                                          }}
                                        />
                                      </span>
                                      &nbsp;&nbsp;
                                      <span
                                        style={{
                                          color: "var(--color-solid-darkblue)",
                                        }}
                                        className="cursor-pointer"
                                      >
                                        {comment.content}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      {/* {(state.action_approver === userData[0].id ||
                    state.action_owner === userData[0].id) &&
                    state.instance_status !== "Met" &&
                    state.instance_status !== "Delinquent" && ( */}
                      <div
                        className="contract-org-view-div"
                        style={{ padding: "0 20%" }}
                      >
                        <Input
                          value={commentEntered}
                          // onPressEnter={handlePressEnter}
                          onChange={onChangeComment}
                          className="contract-notes-input"
                          placeholder="Comments"
                          suffix={
                            <>
                              <Button
                                type="primary"
                                icon={<FullscreenOutlined />}
                                onClick={openModal}
                              />
                              <Upload
                                {...notesFileProps}
                                customRequest={dummyRequest}
                              >
                                <Image
                                  title="Upload"
                                  className="cursor-pointer"
                                  src={attach}
                                  preview={false}
                                />
                              </Upload>

                              <Image
                                title="Add Comment"
                                className="cursor-pointer"
                                style={{
                                  paddingLeft: 10,
                                  pointerEvents:
                                    commentEntered === "" ? "none" : "",
                                }}
                                src={send}
                                preview={false}
                                onClick={onAddComment}
                              />
                            </>
                          }
                        />
                        {/* <div className="instance-notes-input">
                            <p>Comments</p>
                            <div className="instance-notes-icons">
                              
                            </div>
                          </div> */}
                      </div>
                    </div>

                    {/* )} */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            className="maximize-batch-content"
            title="Notes & Commands"
            open={modalVisible}
            onCancel={closeModal}
            footer={null}
            width="90%"
            styles={{
              body: {
                height: "80vh",
                overflow: "auto",
              },
            }}
            // closeIcon={
            //   <FullscreenExitOutlined className="exit-fullscreen-icon" />
            // }
            centered
          >
            <ReactQuill
              id="max-quill"
              theme="snow"
              value={editorDelta}
              onChange={handleEditorChange}
              modules={{
                toolbar: toolbarOptions,
              }}
              style={{ height: "100%" }}
              placeholder="Notes & Commands"
            />
          </Modal>
          <div style={{ margin: "0px 20px 20px 20px" }}>
            <DataTable
              rowClassName={() => "contract-tree-table-row"}
              type="view-contract"
              dataTableData={[...notesTableData]}
              colData={nDColumns}
              showSorterTooltip={false}
            />
          </div>
        </div>
      </>
    );
  };

  // function handle the tab switch

  useEffect(() => {
    console.log("dataSourceCom:", dataSourceCom);
  }, [dataSourceCom]);

  const TabsIcon = (tab) => {
    const TabsIconHelper = (title) => {
      return (
        <div className="tabs-ss-title">
          <img src={title.state.icon} /> <p>{title.state.text}</p>
        </div>
      );
    };
    return (
      <>
        <div className="tabs-title-main">
          {tab.state.selected === "1" ? (
            tab.state.tabkey === "1" ? (
              <TabsIconHelper
                state={{ icon: folder_active, text: "Contract Info" }}
              />
            ) : (
              <TabsIconHelper
                state={{ icon: folder_inactive, text: "Contract Info" }}
              />
            )
          ) : tab.state.selected === "2" ? (
            tab.state.tabkey === "2" ? (
              <TabsIconHelper
                state={{
                  icon: adminPanel_active,
                  text: "Commercial",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: adminPanel_inactive,
                  text: "Commercial",
                }}
              />
            )
          ) : tab.state.selected === "3" ? (
            tab.state.tabkey === "3" ? (
              <TabsIconHelper
                state={{
                  icon: approval_inactive,
                  text: "Approval Flow Chart",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: approval_active,
                  text: "Approval Flow Chart",
                }}
              />
            )
          ) : tab.state.selected === "4" ? (
            tab.state.tabkey === "4" ? (
              <TabsIconHelper
                state={{
                  icon: contract_doc_inactive,
                  text: "Contract Doc",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: contract_doc_active,
                  text: "Contract Doc",
                }}
              />
            )
          ) : tab.state.selected === "5" ? (
            tab.state.tabkey === "5" ? (
              <TabsIconHelper
                state={{
                  icon: chat_active,
                  text: "Notes And Comments",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: chat,
                  text: "Notes And Comments",
                }}
              />
            )
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const nDColumns = [
    {
      title: "Document Name",
      dataIndex: "document_name",
      align: "left",
    },
    {
      title: "Doc Uploaded On",
      dataIndex: "uploaded_on",
      align: "left",
    },
    {
      title: "Doc Uploaded By",
      dataIndex: "uploaded_by",
      align: "left",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
    },
  ];

  const onChangeComment = (e) => {
    setCommentEntered(e.target.value);
  };

  const onAddComment = () => {
    if (commentEntered !== "") {
      setComments([...comments, { type: "text", content: commentEntered }]);
      setCommentEntered("");
    }
  };

  /* function handle the formvalue
   *@param changed value - updatedd change value
   *@param all values - all field value
   */
  const handleFormValuesChange = (changedValues, allValues) => {
    console.log("allValues", allValues);
    if (rowClear) {
      const updatedAllValues = { ...allValues };
      console.log("updatedAllValues", updatedAllValues);
      updatedAllValues.commercial_data.forEach((item, key) => {
        rtData.rt_items_desc.forEach((data) => {
          if (rowKey === key) {
            delete item[data];
          }
        });
      });
      const arrayOfObj = Object.values(commercialTableData || {});
      let aligned =
        arrayOfObj &&
        arrayOfObj.length > 0 &&
        arrayOfObj.map((data, index) => {
          return {
            ...data,
            ["Start Date"]: data["Start Date"]
              ? data["Start Date"]
              : new Date(),
            ["End Date"]: data["End Date"] ? data["End Date"] : new Date(),
            ["key"]: index,
            ["Resource Id"]: `RE_${index + 1}`,
          };
        });
      const matchingData = aligned.map((data) => {
        if (data.key === rowKey) {
          for (let key in data) {
            if (key !== "key") {
              data[key] = undefined;
              data["Resource Id"] = data["Resource Id"];
              data["Start Date"] = data["Start Date"]
                ? data["Start Date"]
                : new Date();
              data["End Date"] = data["End Date"]
                ? data["End Date"]
                : new Date();
            }
          }
          return data;
        }
        return data;
      });
      setDataSourceCom(matchingData);
      setNewContractData(matchingData);

      // const updatedCommercialTableData = { ...commercialTableData };

      // for (let key in updatedCommercialTableData) {
      //   if (String(key) === String(rowKey)) {
      //     for (let innerKey in updatedCommercialTableData[key]) {
      //       updatedCommercialTableData[key][innerKey] = undefined;
      //     }
      //   }
      // }

      // setCommercialTableData(updatedCommercialTableData);
    }
    rowClear = false;
  };

  useEffect(() => {
    document.title = "Create Contract | Judo";
  }, []);

  const getTotalCommercialDaysCosts = (
    data,
    calculationType,
    calculationFor
  ) => {
    const sumValues = (yearData) => {
      return Object.values(yearData).reduce((yearAcc, months) => {
        return (
          yearAcc +
          Object.values(months).reduce((monthAcc, value) => {
            return monthAcc + (isNaN(value) ? 0 : value);
          }, 0)
        );
      }, 0);
    };

    if (calculationFor === "single_row") {
      return data ? sumValues(data) : 0;
    }

    if (calculationFor === "table") {
      return Object.values(data).reduce((tableAcc, row) => {
        const rowData = calculationType === "days" ? row : row;
        return tableAcc + (rowData ? sumValues(rowData) : 0);
      }, 0);
    }

    return 0;
  };

  return (
    <div className="create-doc">
      <Layout className="header-layout">
        <TopHeader
          headerChange={header}
          feedRow={false}
          searchRow={false}
          menuKey={menuKey}
          sticky=""
        />
        <Row
          align="middle"
          type="flex"
          gutter={[4, 16]}
          className="m-0 mt-5"
          style={{ padding: "0px 15px 0px 15px" }}
        >
          <Col
            className="vertical-align"
            xs={24}
            sm={20}
            md={12}
            lg={12}
            xl={12}
          >
            <Card bordered={false} className="setup-header-card2">
              <Row align="top" type="flex" gutter={[24, 0]} className="m-0">
                <Col
                  className="p-0"
                  xs={24}
                  sm={24}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                >
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[24, 0]}
                    className="m-0"
                  >
                    <Col
                      className="p-0 vertical-align"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <div className="white-card">
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[24, 0]}
                          className="m-0"
                        >
                          <Col
                            className="p-0 vertical-align"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={10}
                          >
                            <span
                              className="role-name-text"
                              title={state.title}
                            >
                              {state.title}
                            </span>
                          </Col>
                          <Col
                            className="p-0 vertical-align"
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={14}
                          >
                            <Row
                              align="middle"
                              type="flex"
                              gutter={[24, 0]}
                              className="m-0"
                            >
                              <Col
                                className="p-0 vertical-align text-center"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <span id="contract-header-text">
                                  Last Update On:&nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: "var(--color-solid-black)",
                                    }}
                                  >
                                    N/A
                                  </span>
                                </span>
                              </Col>
                              <Col
                                className="p-0 vertical-align text-end"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <span id="contract-header-text">
                                  Status:&nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: "var(--color-solid-yellow)",
                                    }}
                                  >
                                    In-Draft
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            className="vertical-align"
            xs={24}
            sm={20}
            md={12}
            lg={12}
            xl={12}
          >
            <Card className="setup-header-card">
              <Row type="flex" gutter={[8, 16]}>
                <Col
                  className="vertical-align"
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={2}
                >
                  <Row type="flex" gutter={[16, 16]}>
                    <Col span={12}>
                      <Image
                        src={account_tree}
                        onClick={() => {
                          history(
                            "/contract",
                            setHeaderChanger({
                              header: "contract",
                              headerItem: "3",
                              headerItemName: headerChanger.headerItemName,
                            })
                          );
                        }}
                        style={{ width: 20 }}
                        preview={false}
                        className="cursor-pointer"
                      />
                    </Col>
                    <Col span={12}>
                      <span className="path-icon">
                        <RightOutlined />
                      </span>
                    </Col>
                  </Row>
                </Col>
                {breadcrumbsClick ? (
                  <Col xl={22} align="left">
                    <span
                      className="contract-breadcrumb"
                      title={"Suppliers > " + breadcrumbs.join(" > ")}
                    >
                      <span
                        className="path-text-pipeline"
                        style={{ cursor: "not-allowed" }}
                      >
                        Suppliers
                      </span>
                      {breadcrumbs.length !== 0 ? (
                        breadcrumbs.map((crumb) => {
                          return (
                            <>
                              <span className="path-icon">
                                <RightOutlined />
                              </span>
                              <span
                                className="path-text-pipeline"
                                style={{ cursor: "not-allowed" }}
                                key={crumb.id}
                                disabled={breadcrumbsClick}
                                // onClick={() => onClickBreadcrumb(crumb)}
                              >
                                {crumb.contract_names}
                              </span>
                            </>
                          );
                        })
                      ) : (
                        <Skeleton.Input active={true} size={"small"} />
                      )}
                    </span>
                  </Col>
                ) : (
                  <Col xl={22} align="left">
                    <span
                      className="contract-breadcrumb"
                      title={"Suppliers > " + breadcrumbs.join(" > ")}
                    >
                      <span className="path-text-pipeline">Suppliers</span>
                      {breadcrumbs.length !== 0 ? (
                        breadcrumbs.map((crumb) => {
                          return (
                            <>
                              <span className="path-icon">
                                <RightOutlined />
                              </span>
                              <span
                                className="path-text-pipeline"
                                key={crumb.id}
                              >
                                &nbsp;&nbsp;{crumb.contract_names}
                              </span>
                            </>
                          );
                        })
                      ) : (
                        <Skeleton.Input active={true} size={"small"} />
                      )}
                    </span>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
        <Form
          form={mainForm}
          ref={mainFormRef}
          name="create_contract_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          initialValues={{
            ["Title"]: state && state.title,
            // ["Pricing Profile"]:
            //   state && state.pricing_profile ? state.pricing_profile : "",
          }}
          onValuesChange={handleFormValuesChange}
        >
          <Tabs
            defaultActiveKey={tabkey}
            tabBarExtraContent={
              <div className="doc-btn">
                <Col span={12} style={{ marginRight: 7 }}>
                  <CancelButton
                    onClick={() => {
                      history(
                        "/contract",
                        setHeaderChanger({
                          header: "contract",
                          headerItem: "3",
                          headerItemName: headerChanger.headerItemName,
                        })
                      );
                    }}
                  />
                </Col>
                <Col span={12} style={{ marginRight: 7 }}>
                  <SaveButton
                    onClick={() => setButtonState(1)}
                    type="primary"
                    htmlType="submit"
                    loading={saveLoader}
                  />
                </Col>
                <Col span={12}>
                  <SubmitButton
                    onClick={() => setButtonState(2)}
                    htmlType="submit"
                    type="primary"
                    loading={submitLoader}
                  />
                </Col>
              </div>
            }
            // items={items}
            type="card"
            onChange={onChange}
            className="mt-5"
          >
            <TabPane
              tab={<TabsIcon state={{ tabkey, selected: "1" }} />}
              key="1"
            >
              {/* {contractInfoTab()} */}
              <div>
                <div className="outer-tab-div">
                  <div className="inner-tab-div">
                    <Row type="flex">
                      <Col md={4} lg={4} xl={3}>
                        <Menu
                          onClick={(key) => {
                            if (key.key === "1") {
                              setMenuState("contract");
                            } else if (key.key === "2") {
                              setMenuState("scope");
                            } else if (key.key === "3") {
                              setMenuState("people");
                            } else if (key.key === "4") {
                              setMenuState("financial");
                            }
                          }}
                          defaultSelectedKeys={["1"]}
                          mode="vertical"
                          forceSubMenuRender
                          // items={menuitems}
                        >
                          <Menu.Item
                            key="1"
                            icon={
                              menuState === "contract" ? (
                                <Image
                                  src={dotPointer}
                                  preview={false}
                                  style={{ paddingRight: 20 }}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            About the Contract
                          </Menu.Item>
                          <Menu.Item
                            key="2"
                            icon={
                              menuState === "scope" ? (
                                <Image
                                  src={dotPointer}
                                  preview={false}
                                  style={{ paddingRight: 20 }}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            About the Scope
                          </Menu.Item>
                          <Menu.Item
                            key="3"
                            id="about-the-people"
                            icon={
                              menuState === "people" ? (
                                <Image
                                  src={dotPointer}
                                  preview={false}
                                  style={{ paddingRight: 20 }}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            About the People
                          </Menu.Item>
                          <Menu.Item
                            // onClick={() => updateShowComTable()}
                            key="4"
                            icon={
                              menuState === "financial" ? (
                                <Image
                                  src={dotPointer}
                                  preview={false}
                                  style={{ paddingRight: 20 }}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            About the Financials
                          </Menu.Item>
                        </Menu>
                      </Col>
                      <Col md={20} lg={20} xl={21}>
                        <Form.List name="info">
                          {() => (
                            <div>
                              {menuState === "contract" ? (
                                <Form.List name="contract">
                                  {() => (
                                    <div
                                      ref={innerTabRef}
                                      className="form-div mt-10"
                                    >
                                      <Row>
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="Title"
                                            label="TITLE"
                                            colon={false}
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: "Please enter Title!",
                                              },
                                            ]}
                                            initialValue={state && state.title}
                                          >
                                            <Input
                                              readOnly
                                              placeholder="Enter your title"
                                              className="basic-input"
                                            />
                                          </Form.Item>
                                        </Col>

                                        {/* CLIENT ENTITY */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .FOR_LEGAL_ENTITY ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Client Entity"
                                              label="CLIENT ENTITY"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Client Entity`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .FOR_LEGAL_ENTITY
                                              }
                                            >
                                              <Input
                                                placeholder="Enter Client Entity"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Client Entity"
                                              label="CLIENT ENTITY"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Client Entity`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Client Entity"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .SUPPLIER_ENTITY_NAME &&
                                        state.contractExtractData
                                          .SUPPLIER_ENTITY_NAME.length > 0 ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Supplier Entity Name"
                                              label="SUPPLIER ENTITY NAME"
                                              colon={false}
                                              rules={[
                                                {
                                                  required: buttonState === 2,
                                                  message: `Please enter Supplier Entity Name`,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .SUPPLIER_ENTITY_NAME[0]
                                              }
                                            >
                                              <Select
                                                placeholder="Select Supplier Entity Name"
                                                className="basic-input"
                                                allowClear={true}
                                                // options={
                                                //   typeof state
                                                //     .contractExtractData
                                                //     ?.SUPPLIER_ENTITY_NAME ===
                                                //   "string"
                                                //     ? state.contractExtractData.SUPPLIER_ENTITY_NAME.split(
                                                //         ", "
                                                //       ).map((item) => ({
                                                //         key: item,
                                                //         label: item,
                                                //         value: item,
                                                //       }))
                                                //     : []
                                                // }
                                                options={state.contractExtractData.SUPPLIER_ENTITY_NAME.map(
                                                  (item) => ({
                                                    key: item,
                                                    label: item,
                                                    value: item,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Supplier Entity Name"
                                              label="SUPPLIER ENTITY NAME"
                                              colon={false}
                                              rules={[
                                                {
                                                  required: buttonState === 2,
                                                  message: `Please enter Supplier Entity Name`,
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select Supplier Entity Name"
                                                className="basic-input"
                                                allowClear={true}
                                                options={
                                                  suppliersList &&
                                                  suppliersList["0"] &&
                                                  suppliersList["0"]
                                                    .supplier_entities &&
                                                  suppliersList[
                                                    "0"
                                                  ].supplier_entities.map(
                                                    (data) => ({
                                                      key: data.supplier_entity,
                                                      label:
                                                        data.supplier_entity,
                                                      value:
                                                        data.supplier_entity,
                                                    })
                                                  )
                                                }
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Business Area Department */}
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="Business Area Department"
                                            label="BUSINESS AREA/DEPARTMENT"
                                            colon={false}
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please Enter Business Area Department`,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Business Area / Department"
                                              className="basic-input"
                                            />
                                          </Form.Item>
                                        </Col>

                                        {/* Contract Start Date */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData.START_DATE ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Contract Start Date"
                                              label="CONTRACT START DATE"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please select Start Date`,
                                                },
                                              ]}
                                              initialValue={
                                                state &&
                                                state.contractExtractData &&
                                                state.contractExtractData
                                                  ? dayjs(
                                                      state.contractExtractData
                                                        .START_DATE
                                                    )
                                                  : undefined
                                              }
                                            >
                                              <DatePicker
                                                id="contract-start-date"
                                                style={{ zIndex: "10000" }}
                                                disabledDate={(dateValue) =>
                                                  disabledDate(
                                                    dateValue,
                                                    "start_date"
                                                  )
                                                }
                                                size="small"
                                                showToday={false}
                                                onChange={(date, dateString) =>
                                                  onDateChange(
                                                    date,
                                                    dateString,
                                                    "start_date"
                                                  )
                                                }
                                                getPopupContainer={(trigger) =>
                                                  trigger.parentNode
                                                }
                                                format="DD MMM YYYY"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                            {(state.contractType === "CR" ||
                                              state.contractType === "CCN") && (
                                              <>
                                                <Checkbox
                                                  style={{ margin: "-21px" }}
                                                  checked={checkedSd}
                                                  disabled={!startDateSelected}
                                                  onChange={(e) => {
                                                    onApplyChange(
                                                      e,
                                                      "start_date"
                                                    );
                                                  }}
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                  style={{ marginLeft: "20px" }}
                                                >
                                                  Apply to Root Contract
                                                </span>
                                              </>
                                            )}
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Contract Start Date"
                                              label="CONTRACT START DATE"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please select Start Date`,
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                id="contract-start-date"
                                                disabledDate={(dateValue) =>
                                                  disabledDate(
                                                    dateValue,
                                                    "start_date"
                                                  )
                                                }
                                                size="small"
                                                showToday={false}
                                                onChange={(date, dateString) =>
                                                  onDateChange(
                                                    date,
                                                    dateString,
                                                    "start_date"
                                                  )
                                                }
                                                // getPopupContainer={
                                                //   getInfoPopupContainer
                                                // }
                                                format="DD MMM YYYY"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                            {(state.contractType === "CR" ||
                                              state.contractType === "CCN") && (
                                              <>
                                                <Checkbox
                                                  style={{ margin: "-21px" }}
                                                  checked={checkedSd}
                                                  disabled={!startDateSelected}
                                                  onChange={(e) => {
                                                    onApplyChange(
                                                      e,
                                                      "start_date"
                                                    );
                                                  }}
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                  style={{ marginLeft: "20px" }}
                                                >
                                                  Apply to Root Contract
                                                </span>
                                              </>
                                            )}
                                          </Col>
                                        )}

                                        {/* Contract End Date */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData.END_DATE ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Contract End Date"
                                              label="CONTRACT END DATE"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please select End Date`,
                                                },
                                              ]}
                                              initialValue={
                                                state &&
                                                state.contractExtractData &&
                                                state.contractExtractData
                                                  ? dayjs(
                                                      state.contractExtractData
                                                        .END_DATE
                                                    )
                                                  : undefined
                                              }
                                            >
                                              <DatePicker
                                                id="contract-end-date"
                                                disabledDate={(dateValue) =>
                                                  disabledDate(
                                                    dateValue,
                                                    "end_date"
                                                  )
                                                }
                                                size="small"
                                                showToday={false}
                                                onChange={(date, dateString) =>
                                                  onDateChange(
                                                    date,
                                                    dateString,
                                                    "end_date"
                                                  )
                                                }
                                                // getPopupContainer={
                                                //   getInfoPopupContainer
                                                // }
                                                format="DD MMM YYYY"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                            {(state.contractType === "CR" ||
                                              state.contractType === "CCN") && (
                                              <>
                                                <Checkbox
                                                  style={{ margin: "-21px" }}
                                                  checked={checkedEd}
                                                  disabled={!endDateSelected}
                                                  onChange={(e) => {
                                                    onApplyChange(
                                                      e,
                                                      "end_date"
                                                    );
                                                  }}
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                  style={{ marginLeft: "20px" }}
                                                >
                                                  Apply to Root Contract
                                                </span>
                                              </>
                                            )}
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Contract End Date"
                                              label="CONTRACT END DATE"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please select End Date`,
                                                },
                                              ]}
                                            >
                                              <DatePicker
                                                id="contract-end-date"
                                                disabledDate={(dateValue) =>
                                                  disabledDate(
                                                    dateValue,
                                                    "end_date"
                                                  )
                                                }
                                                size="small"
                                                showToday={false}
                                                onChange={(date, dateString) =>
                                                  onDateChange(
                                                    date,
                                                    dateString,
                                                    "end_date"
                                                  )
                                                }
                                                // getPopupContainer={
                                                //   getInfoPopupContainer
                                                // }
                                                format="DD MMM YYYY"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                            {(state.contractType === "CR" ||
                                              state.contractType === "CCN") && (
                                              <>
                                                <Checkbox
                                                  style={{ margin: "-21px" }}
                                                  checked={checkedEd}
                                                  disabled={!endDateSelected}
                                                  onChange={(e) => {
                                                    onApplyChange(
                                                      e,
                                                      "end_date"
                                                    );
                                                  }}
                                                />
                                                &nbsp;&nbsp;
                                                <span
                                                  style={{ marginLeft: "20px" }}
                                                >
                                                  Apply to Root Contract
                                                </span>
                                              </>
                                            )}
                                          </Col>
                                        )}

                                        {/* //To Set Date for CCN/CR */}
                                        {(state.contractType === "CR" ||
                                          state.contractType === "CCN") && (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Root Contract Start Date"
                                              label="ROOT CONTRACT START DATE"
                                              colon={false}
                                            >
                                              {/* <Input
                                              readOnly
                                              defaultValue={
                                                rootData.startDate &&
                                                rootData.startDate !== "" &&
                                                moment(
                                                  rootData.startDate
                                                ).format("DD MMM YYYY")
                                              }
                                              className="basic-input"
                                            /> */}
                                              <div className="root-contract-input">
                                                {rootData.startDate &&
                                                  rootData.startDate !== "" &&
                                                  moment(
                                                    rootData.startDate
                                                  ).format("DD MMM YYYY")}
                                              </div>
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {(state.contractType === "CR" ||
                                          state.contractType === "CCN") && (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Root Contract End Date"
                                              label="ROOT CONTRACT END DATE"
                                              colon={false}
                                            >
                                              {/* <Input
                                              readOnly
                                              defaultValue={moment(
                                                rootData.endDate
                                              ).format("DD MMM YYYY")}
                                              className="basic-input"
                                            /> */}
                                              <div className="root-contract-input">
                                                {rootData.endDate &&
                                                  rootData.endDate !== "" &&
                                                  moment(
                                                    rootData.endDate
                                                  ).format("DD MMM YYYY")}
                                              </div>
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Priority */}
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="Priority"
                                            label="PRIORITY"
                                            colon={false}
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please select Priority`,
                                              },
                                            ]}
                                          >
                                            <Select
                                              placeholder="Select"
                                              className="basic-input"
                                              allowClear={true}
                                            >
                                              <Select.Option value="P1">
                                                P1
                                              </Select.Option>
                                              <Select.Option value="P2">
                                                P2
                                              </Select.Option>
                                              <Select.Option value="P3">
                                                P3
                                              </Select.Option>
                                              <Select.Option value="P4">
                                                P4
                                              </Select.Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <div className="mt-5">
                                            <label
                                              style={{
                                                fontSize: "13px",
                                                fontWeight: 600,
                                                color: `var(--color-solid-darkergrey)`,
                                                textTransform: "uppercase",
                                              }}
                                            >
                                              Contract Value
                                            </label>
                                            <div
                                              className="pp-div mt-10"
                                              style={{
                                                padding: "8px 15px 10px 15px",
                                                height: "42px",
                                              }}
                                            >
                                              {getTotalCommercialDaysCosts(
                                                costsRef.current,
                                                "cost",
                                                "table"
                                              ).toLocaleString("en-us")}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Form.List>
                              ) : menuState === "scope" ? (
                                <Form.List name="scope">
                                  {() => (
                                    <div className="form-div mt-10">
                                      <Row>
                                        {/* Scope */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData.SCOPE ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Scope"
                                              label="SCOPE"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Scope`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              // initialValue={
                                              //   state.contractExtractData.SCOPE.split(
                                              //     ","
                                              //   )[0]
                                              // }
                                              initialValue={
                                                state.contractExtractData
                                                  .SCOPE[0]
                                              }
                                            >
                                              <Input.TextArea
                                                rows={1}
                                                placeholder="Enter Scope"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Scope"
                                              label="SCOPE"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Scope`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Scope"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Scope In Detail */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData.SCOPE ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Scope In Detail"
                                              label="SCOPE IN DETAIL"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Scope In Detail`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              // initialValue={state.contractExtractData.SCOPE.split(
                                              //   ","
                                              // )
                                              //   .slice(1)
                                              //   .join()}
                                              initialValue={
                                                state.contractExtractData
                                                  .SCOPE[1]
                                              }
                                            >
                                              <Input.TextArea
                                                rows={1}
                                                placeholder="Enter Scope In Detail"
                                                className="basic-input-textarea"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Scope In Detail"
                                              label="SCOPE IN DETAIL"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Scope In Detail`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Scope In Detail"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Assumptions */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .ASSUMPTIONS ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Assumptions"
                                              label="ASSUMPTIONS"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Assumption`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .ASSUMPTIONS
                                              }
                                            >
                                              <Input.TextArea
                                                rows={1}
                                                placeholder="Enter Assumptions"
                                                className="basic-input-textarea"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Assumptions"
                                              label="ASSUMPTIONS"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Assumption`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Assumptions"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Dependencies */}
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="Dependencies"
                                            label="DEPENDENCIES"
                                            colon={false}
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please enter Dependencies`,
                                                pattern: /^(?!\s*$).+/,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Dependences"
                                              className="basic-input"
                                            />
                                          </Form.Item>
                                        </Col>

                                        {/* Other Details */}
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="Other Details"
                                            label="OTHER DETAILS"
                                            colon={false}
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please enter Other Details`,
                                                pattern: /^(?!\s*$).+/,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Enter Other Details"
                                              className="basic-input"
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Form.List>
                              ) : menuState === "people" ? (
                                <Form.List name="people">
                                  {() => (
                                    <div className="form-div mt-10">
                                      <Row>
                                        {/* Department Head */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .VENDOR_MANAGER ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Department Head"
                                              label="DEPARTMENT HEAD"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Department Head`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .VENDOR_MANAGER[0]
                                              }
                                            >
                                              <Input
                                                placeholder="Enter your Department Head"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Department Head"
                                              label="DEPARTMENT HEAD"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Department Head`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter your Department Head"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Department Lead */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .PROJECT_MANAGER_NAME ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Department Lead"
                                              label="DEPARTMENT LEAD"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Department Lead`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .PROJECT_MANAGER_NAME[0]
                                              }
                                            >
                                              <Input
                                                placeholder="Enter your Department Lead"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Department Lead"
                                              label="DEPARTMENT LEAD"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Department Lead`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter your Department Lead"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Department Manager */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .SERVICE_MANAGER ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Department Manager"
                                              label="DEPARTMENT MANAGER"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Department Manager`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData.SERVICE_MANAGER.split(
                                                  ","
                                                )[0]
                                              }
                                            >
                                              <Input
                                                placeholder="Enter your Department Manager"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Department Manager"
                                              label="DEPARTMENT MANAGER"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Department Manager`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter your Department Manager"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Contract Manager */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .SERVICE_MANAGER ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Contract Manager"
                                              label="CONTRACT MANAGER"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Contract Manager`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData.SERVICE_MANAGER.split(
                                                  ","
                                                )[0]
                                              }
                                            >
                                              <Input
                                                placeholder="Enter your Contract Manager"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Contract Manager"
                                              label="CONTRACT MANAGER"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Contract Manager`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter your Contract Manager"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Supplier Partner Lead */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .CLIENT_DESIGNATED_CONTACTS ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Supplier Partner Lead"
                                              label="SUPPLIER PARTNER LEAD"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Supplier Partner Lead`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .CLIENT_DESIGNATED_CONTACTS
                                              }
                                            >
                                              <Input
                                                placeholder="Enter your Supplier Partner Lead"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Supplier Partner Lead"
                                              label="SUPPLIER PARTNER LEAD"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Supplier Partner Lead`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter your Supplier Partner Lead"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Supplier Partner Manager */}
                                        {state &&
                                        state.contractExtractData &&
                                        state.contractExtractData
                                          .SIGNATURE_AUTHORITIES_CLIENT_NAME ? (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Supplier Partner Manager"
                                              label="SUPPLIER PARTNER MANAGER"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Supplier Partner Manager`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                              initialValue={
                                                state.contractExtractData
                                                  .SIGNATURE_AUTHORITIES_CLIENT_NAME
                                              }
                                            >
                                              <Input
                                                placeholder="Enter your Supplier Partner Manage"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        ) : (
                                          <Col xs={24} md={24} lg={12} xl={8}>
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              name="Supplier Partner Manager"
                                              label="SUPPLIER PARTNER MANAGER"
                                              colon={false}
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please enter Supplier Partner Manager`,
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter your Supplier Partner Manage"
                                                className="basic-input"
                                              />
                                            </Form.Item>
                                          </Col>
                                        )}

                                        {/* Docusign Signers */}
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            name="Docusign Signers"
                                            label="DOCUSIGN SIGNERS"
                                            colon={false}
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please enter docusign signers`,
                                                pattern: /^(?!\s*$).+/,
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Docusign Signers"
                                              className="basic-input"
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Form.List>
                              ) : menuState === "financial" ? (
                                <Form.List name="financial">
                                  {() => (
                                    <div className="form-div mt-10">
                                      <Row>
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          {state.contractType === "CR" ||
                                          state.contractType === "CCN" ? (
                                            <div className="mt-5">
                                              <label
                                                style={{
                                                  fontSize: "13px",
                                                  fontWeight: 600,
                                                  color: `var(--color-solid-darkergrey)`,
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                Pricing Profile
                                              </label>
                                              <div
                                                className="pp-div mt-10"
                                                style={{
                                                  padding: "8px 15px 10px 15px",
                                                  height: "42px",
                                                }}
                                              >
                                                {pp &&
                                                  pp.price_profile_name &&
                                                  pp.price_profile_name}
                                              </div>
                                            </div>
                                          ) : (
                                            <Form.Item
                                              {...formItemLayout}
                                              className="org-supplier-label"
                                              colon={false}
                                              name="Pricing Profile"
                                              label="PRICING PROFILE"
                                              rules={[
                                                {
                                                  required:
                                                    buttonState === 2
                                                      ? true
                                                      : false,
                                                  message: `Please select Pricing Profile`,
                                                },
                                              ]}
                                              initialValue={
                                                state && state.pricing_profile
                                              }
                                            >
                                              {state?.pricing_profile ? (
                                                <Input
                                                  className="basic-input"
                                                  // value={state.pricing_profile}
                                                  // value="bb"
                                                  readOnly
                                                />
                                              ) : (
                                                <Select
                                                  onChange={(key, value) =>
                                                    handlePricingProfile(
                                                      key,
                                                      value
                                                    )
                                                  }
                                                  allowClear
                                                  className="basic-input"
                                                  showSearch={true}
                                                  placeholder="Choose Pricing Profile"
                                                  options={
                                                    pricingProfile &&
                                                    pricingProfile.map(
                                                      (data) => ({
                                                        key: data._id,
                                                        label:
                                                          data.price_profile_name,
                                                        value: data._id,
                                                      })
                                                    )
                                                  }
                                                ></Select>
                                              )}
                                            </Form.Item>
                                          )}
                                        </Col>
                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            colon={false}
                                            name="Invoicing Profile"
                                            label="INVOICING PROFILE"
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please select Invoicing Profile`,
                                              },
                                            ]}
                                          >
                                            <Select
                                              placeholder="Choose Invoice Profile"
                                              className="basic-input"
                                              allowClear={true}
                                              options={
                                                invoiceingProfileData &&
                                                invoiceingProfileData.map(
                                                  (data) => ({
                                                    key: data._id,
                                                    label:
                                                      data.invoice_profile_name,
                                                    value: data._id,
                                                  })
                                                )
                                              }
                                            ></Select>
                                          </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            colon={false}
                                            name="Fx Profile"
                                            label="FX PROFILE"
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please Select Fx`,
                                              },
                                            ]}
                                          >
                                            <Select
                                              allowClear
                                              className="basic-input"
                                              showSearch={true}
                                              placeholder="Choose Fx Profile"
                                              options={
                                                fxProfile &&
                                                fxProfile.map((data) => ({
                                                  key: data._id,
                                                  label: data.fx_table_name,
                                                  value: data._id,
                                                }))
                                              }
                                            ></Select>
                                          </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            colon={false}
                                            name="Contract Pricing"
                                            label="CONTRACT PRICING"
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please enter Contract Pricing`,
                                              },
                                            ]}
                                          >
                                            {contractPricing &&
                                            contractPricing.length > 0 ? (
                                              <Select
                                                allowClear
                                                className="basic-input"
                                                showSearch={true}
                                                placeholder="Choose Contract Pricing"
                                                options={
                                                  contractPricing &&
                                                  contractPricing
                                                    .filter(
                                                      (filData) =>
                                                        filData !== "All"
                                                    )
                                                    .map((data) => ({
                                                      key: data,
                                                      label: data,
                                                      value: data,
                                                    }))
                                                }
                                              ></Select>
                                            ) : (
                                              <Select
                                                className="basic-input"
                                                showSearch={true}
                                                placeholder="No Contract Pricing"
                                                options=""
                                              ></Select>
                                            )}
                                          </Form.Item>
                                        </Col>

                                        <Col xs={24} md={24} lg={12} xl={8}>
                                          <Form.Item
                                            {...formItemLayout}
                                            className="org-supplier-label"
                                            colon={false}
                                            name="Contract Currency"
                                            label="CONTRACT CURRENCY"
                                            rules={[
                                              {
                                                required:
                                                  buttonState === 2
                                                    ? true
                                                    : false,
                                                message: `Please enter Contract Currency`,
                                              },
                                            ]}
                                          >
                                            {contractCurrency &&
                                            contractCurrency.length > 0 ? (
                                              <Select
                                                allowClear
                                                className="basic-input"
                                                showSearch={true}
                                                placeholder="Choose Contract Currency"
                                                options={
                                                  contractCurrency &&
                                                  contractCurrency.map(
                                                    (data) => ({
                                                      key: data,
                                                      label: data,
                                                      value: data,
                                                    })
                                                  )
                                                }
                                              ></Select>
                                            ) : (
                                              <Select
                                                className="basic-input"
                                                showSearch={true}
                                                placeholder="No Contract Currency"
                                                options=""
                                              ></Select>
                                            )}
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Form.List>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={<Divider className="tab-divider" type="vertical" />}
              key="divider1"
              disabled
            />
            <TabPane
              tab={<TabsIcon state={{ tabkey, selected: "2" }} />}
              key="2"
            >
              {commercialTab()}
            </TabPane>
            <TabPane
              tab={<Divider className="tab-divider" type="vertical" />}
              key="divider2"
              disabled
            />
            <TabPane
              tab={<TabsIcon state={{ tabkey, selected: "3" }} />}
              key="3"
            >
              {approvalFlowChart()}
            </TabPane>
            <TabPane
              tab={<Divider className="tab-divider" type="vertical" />}
              key="divider3"
              disabled
            />
            <TabPane
              tab={<TabsIcon state={{ tabkey, selected: "4" }} />}
              key="4"
            >
              {contractDoc()}
            </TabPane>
            <TabPane
              tab={<Divider className="tab-divider" type="vertical" />}
              key="divider4"
              disabled
            />
            <TabPane
              tab={<TabsIcon state={{ tabkey, selected: "5" }} />}
              key="5"
            >
              {notesAndDocuments()}
            </TabPane>
          </Tabs>
        </Form>
        {compareModal && (
          <CompareDocs
            oldVersion={
              selectedRows.reduce(
                (min, row) => (row.key < min.key ? row : min),
                selectedRows[0]
              ).file_content
            }
            newVersion={
              selectedRows.reduce(
                (max, row) => (row.key > max.key ? row : max),
                selectedRows[0]
              ).file_content
            }
            setText1={setText1}
            setText2={setText2}
          />
        )}
      </Layout>
    </div>
  );
};

export default GeneralForm;
