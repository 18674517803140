import React from "react";
import { Row, Col, Form, Select, Table } from "antd";
import { monthsShort } from "../../utils/data";
import { OkButton } from "../GlobalButton";

const TotalCostModal = ({
  handleYearChange,
  selectedYear,
  uniqueYears,
  daysCostsDataSource,
  calculateDaysCostsSummary,
  CostAndDaysTotalTableCol,
  openTotalCostModal,
}) => {
  const [totalDaysForm] = Form.useForm();

  return (
    <div>
      <Form
        form={totalDaysForm}
        name="total_days_form"
        className="days-form"
        initialValues={{}}
      >
        <Row align="middle" type="flex" style={{ padding: "15px 20px 5px" }}>
          <Col span={24} className="vertical-align">
            <div className="div-head text-center">
              <span>
                Year &nbsp; &nbsp;
                <Select
                  onChange={handleYearChange}
                  defaultValue={selectedYear}
                  showSearch={true}
                  placeholder="Year"
                  options={uniqueYears.map((year) => ({
                    key: year,
                    label: year,
                    value: year,
                  }))}
                />
              </span>
            </div>
          </Col>
        </Row>
        <hr className="mt-10" />
        <div style={{ overflow: "auto" }}>
          <Table
            dataSource={daysCostsDataSource}
            columns={CostAndDaysTotalTableCol}
            pagination={false}
            id="total-commercial-days-costs"
            className="data-table"
            summary={() => {
              const summary = calculateDaysCostsSummary(daysCostsDataSource);
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  {monthsShort.map((month, index) => (
                    <Table.Summary.Cell key={month} index={index + 1}>
                      {summary[month]}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={monthsShort.length + 1}>
                    {summary.total}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </div>
        <Row
          align="middle"
          type="flex"
          justify="center"
          style={{ padding: "10px 0px" }}
        >
          <Col className="vertical-align" span={3}>
            <OkButton
              onClick={() => openTotalCostModal(false)}
              type="primary"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TotalCostModal;
