import React, { useState, useEffect } from "react";
import { Row, Col, Table, Pagination } from "antd"; //antd components
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
} from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import { CreateButton } from "../../GlobalButton";

import { useNavigate } from "react-router-dom";
import { LegalEntityUpdateAtom, CostCenterAtom } from "../../../store/store";
import {
  CostCenterPagination,
  deleteCCApi,
  costCenterOnlyApi,
} from "../../../api/costCenterApi";
import moment from "moment";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import ActionButton from "../../ActionButton";
import GlobalFilter from "../../GlobalFilter";

function CostCenterSetup() {
  const popupModalInstance = PopupModal();
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state variable hold the navigating different routes
  const navigate = useNavigate();
  //state varaiable hold the recoil value about supplier setup
  const [legalEntityUpdate, setLegalEntityUpdate] = useRecoilState(
    LegalEntityUpdateAtom
  );
  const [costCenterName, setCostCenterName] = useRecoilState(CostCenterAtom);
  //state avraible hold the selectedd column data
  let selectedCol = legalEntityUpdate.selectedCol;
  //state avraible hold the unselectedd column data
  let unSelectedCol = legalEntityUpdate.unSelectedCol;
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    renderCostCenter(
      paginateData.pageNo,
      paginateData.limit,
      searchVal,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle("Cost Center Name", "cost_center_name"),
      dataIndex: "cost_center_name",
      align: "left",
      width: 400,
      sorter: true,
      sortOrder: isColumnSorted("cost_center_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Cost Center Number", "cost_center_number"),
      dataIndex: "cost_center_number",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("cost_center_number") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Legal Entity Name", "legal_entity_name"),
      dataIndex: "legal_entity_name",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("legal_entity_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("CC Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: true,
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.cost_center_setup.view ||
          roleUser[0].page_access.system_setup.cost_center_setup.create)
          ? true
          : false,
    },
  ];

  // State variabel to hold filter the all column datas
  const columnToShow = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleviewCostCenter = (item) => {
    let ssLog = {
      ss_id: item.id ? item.id : item._id,
      ss_name: item.cc_name,
      code: "cost_center",
      author: userData[0].id,
      action: "view",
      notes: "Viewed Cost Center",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };

  /* Function to handle the get Fx table data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const renderCostCenter = (
    page,
    limit,
    searchVal,
    allFilterVal,
    sortColumn
  ) => {
    setLoader(true);
    CostCenterPagination(page, limit, searchVal, allFilterVal, sortColumn).then(
      (res) => {
        setLoader(false);
        console.log("res--", res);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });
        const costCenterMaster = res.docs.map((item, index) => {
          let tableRow = {
            key: item.id ? item.id : item._id,
            cost_center_id: item.id ? item.id : item._id,
            cost_center_name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.cost_center_setup.view
                  ) {
                    handleviewCostCenter(item);
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "1",
                      headerItemName: "systemSetup",
                      stateSetter: item,
                    });
                    navigate(`/organization-setup/view/cost-center`, {
                      state: { data: item, flag: "view" },
                    });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.cost_center_setup.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.cost_center_setup.view
                      ? "pointer"
                      : "default",
                }}
              >
                {item.cc_name}
              </span>
            ),
            cost_center_number: item.cc_nbr ? item.cc_nbr : "-",
            status: item.cc_status,
            legal_entity_name: item.le_name ? item.le_name : "-",
            created_on: moment(item.createdAt).format("DD-MMM-YY"),
            created_by: item.created_user_name,
            updated_on: moment(item.updatedAt).format("DD-MMM-YY"),
            updated_by: item.updated_user_name,
            action: (
              <ActionButton
                AccessCheck={
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.cost_center_setup
                }
                data={item}
                dataStatus={item.cc_status}
                itemName={item.cc_name}
                setHeaderChanger={setHeaderChanger}
                headerValue={{
                  header: "systemSetup",
                  headerItem: "1",
                  headerItemName: "systemSetup",
                }}
                menuItem="organization-setup"
                subMenuItem="cost-center"
                deleteInfo={ModalMessage.CC_Delete_Info}
                handleDelete={handleDeleteCC}
                handleView={handleviewCostCenter}
              />
            ),
          };
          return tableRow;
        });
        console.log("costCenterMaster: ", costCenterMaster);
        setTableData(costCenterMaster);
      }
    );
  };

  const handleDeleteCC = (ccId, cc_name, inputCCName) => {
    if (inputCCName === cc_name) {
      deleteCCApi(ccId).then((res) => {
        let umLog = {
          ss_id: ccId,
          ss_name: cc_name,
          code: "cost_center",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted Cost Center",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(cc_name, ModalMessage.CC_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              renderCostCenter(1, 20, searchVal, {}, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.CC_Delete_Error);
    }
  };

  // store costcenter_name in global state
  const getAllCostCenter = async () => {
    await costCenterOnlyApi().then((res) => {
      setCostCenterName(res.map((i) => i.cc_name));
    });
  };

  useEffect(() => {
    getAllCostCenter();
  }, []);

  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, [searchVal]);

  const onPageChange = (page, limit) => {
    renderCostCenter(page, limit, searchVal, allFilterVal, sortColumnOptions);
  };

  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      renderCostCenter(1, 20, searchVal, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        renderCostCenter(1, 20, searchVal, allFilterVal, sortColumnOptions);
      } else {
        renderCostCenter(1, 20, searchVal, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Cost Center Setup | Judo";
  }, []);

  const onCreateCostCenter = () => {
    navigate(`/organization-setup/create/cost-center`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.cost_center_setup.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.cost_center_setup.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.cost_center_setup.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"costCenter"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.cost_center_setup.create && (
            <Col xl={2} md={6} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateCostCenter} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          // rowSelection={rowSelection}
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columnToShow}
          onChange={handleTableChange}
          bordered
          size="large "
          loading={loader}
          scroll={
            tableData.length === 0
              ? {}
              : {
                  x: selectedCol.length !== 0 ? 1600 : 1000,
                  y: "calc(100vh - 265px)",
                }
          }
          pagination={false}
          className="system-setup-table"
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            // simple
            // pageSizeOptions={[5, 10, 20, 50, 100]}
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CostCenterSetup;
