import { axiosInstance } from "../utils/common";

export const fetchRecipientStatusApi = async (envelopeId, contractId) => {
  return axiosInstance
    .get(`v1/docusign/recipient-status/${envelopeId}?contractId=${contractId}`)
    .then((res) => res.data)
    .catch((error) => console.error("Error fetching recipient status:", error));
};

export const fetchSignedDocumentApi = async (envelopeId) => {
  return axiosInstance
    .get(`v1/docusign/signed-document/${envelopeId}`)
    .then((res) => res.data)
    .catch((error) => console.error("Error fetching signed document:", error));
};

export const reTriggerDocuSignFlowApi = async (contractId) => {
  return axiosInstance
    .get(`v1/docusign/retrigger-docusign?contractId=${contractId}`)
    .then((res) => res)
    .catch((error) => console.error("error", error));
};
