import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../Header";
import {
  Row,
  Col,
  Button,
  Layout,
  Form,
  Card,
  Image,
  Input,
  Select,
  DatePicker,
  Radio,
  Table,
  Modal,
  Checkbox,
  Empty,
} from "antd"; //antd components
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import "../../assets/style/setup.css";
import "../../assets/style/reports.css";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../assets/images/icons/arrow_back_ios.svg";
import moment from "moment";
import * as XLSX from "xlsx";
import AggregateValue from "../Contract/AggregateValue";
import {
  getAllowedSupplierList,
  suppliersOnlyApi,
} from "../../api/suppliersApi";
import {
  contractsAllApi,
  contractFilterApi,
  getAggregateValueApi,
  contractFilterControllerApi,
  getContractBySupplier,
  getContractParentData,
} from "../../api/contractsApi";
import { legalEntitysOnlyApi } from "../../api/legalEntityApi";
import { costCenterOnlyApi } from "../../api/costCenterApi";
import {
  getRatecardOnlyApi,
  getRatecardReportApi,
} from "../../api/ratecardApi";
import { getSupplierFxApi, getFxTableReportApi } from "../../api/fxTableApi";
import {
  getPricingProfileOnlyApi,
  getPricingProfileReportApi,
} from "../../api/pricingProfileApi";
import {
  getAllInvoicingProfileOnlyApi,
  getAllInvoicingProfileReportApi,
} from "../../api/invoicingProfileApi";
import {
  getAllWorkflowsOnlyApi,
  getSingleWorkflowsReportApi,
} from "../../api/workflowApi";
import { createReportApi, getAllReportData } from "../../api/reportApi";
import { reportArrays } from "../../utils/data";
import { userDataAtom, RoleAccessAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../../store/store";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import {
  DownloadButton,
  GenerateButton,
  SaveButton,
  ScheduleButton,
} from "../GlobalButton";
import NotAuthorized from "../NotAuthorized";
import ReportLoader from "../ReportLoader";
import HeaderCard from "../HeaderCard";
import DataTable from "../Tables/DataTable";
import { getAllMsaApi } from "../../api/msaApi";

const CreateReportController = () => {
  const [headerChanger] = useRecoilState(HeaderChangerAtom); // Holds the top header global atom
  let header = headerChanger.header; // Holds the top header state extracted from the header atom
  const navigate = useNavigate(); // Stores navigate function to navigate to other pages
  const popupModalInstance = PopupModal(); // Stores an instance of popupModal for displaying success/information modal messages
  const userData = useRecoilState(userDataAtom); // Stores logged in user details from global state atom
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  let { state } = useLocation();
  console.log("state", state);

  /* Holds value based on which page needs to be displayed.
   * Values that formLevel holds:
   * "" : Form to enter all the report details to be generated.
   * "generate": Displays the page with the generated report, after entering the report details in the form.  */
  const [formLevel, setFormLevel] = useState("");
  const [categorySelect, setCategorySelect] = useState(false); // Stores boolean value on whether main type of report has been selected or not
  const [categoryType, setCategoryType] = useState(""); // Stores name of main type of report (Contract/Commercial/Setup)
  const [setupCategoryType, setSetupCategoryType] = useState(""); // Stores names of type of reports based on Report Category
  const [setupSubCategoryType, setSetupSubCategoryType] = useState(""); // Stores names of type of reports based on Report SubCategory
  const [schedule, setSchedule] = useState("null"); // Stores value of when the report needs to be scheduled
  const [generateCon, SetGenerateCon] = useState(false); // Stores boolean value on whether Generate Button was clicked
  const [finalValues, setFinalValues] = useState(); // Stores the processed form values
  const [supplierData, setSupplierData] = useState([]); // Stores all active supplier details
  const [rateCardData, setRateCardData] = useState([]); // Stores all Rate Card details
  const [pricingProfileData, setPricingProfileData] = useState([]); // Stores all Pricing Profile details
  const [invoicingProfileData, setInvoicingProfileData] = useState([]); //Stores all Invoicing Profile details
  const [workflowData, setWorkflowData] = useState([]); // Stores all Workflow details
  const [supplierId, setSupplierId] = useState(); // Stores selected Supplier ID
  const [rateCardId, setrateCardId] = useState(); // Stores selected Rate Card ID
  const [contractData, setContractData] = useState(); // Stores all Contract details
  const [fxData, setFxData] = useState(); // Stores all FX Setup details
  const [contractId, setContractId] = useState(null); // Stores selected Contract ID
  const [contractType, setContractType] = useState(null); // Stores Contract type (MSA/Supplement/CR/CCN)
  const [fxTableId, setfxTableId] = useState(null); // Stores selected FX Table ID
  const [invoiceProfileId, setInvoiceProfileId] = useState(null); // Stores selected Invoicing Profile ID
  const [workflowId, setWorkflowId] = useState(null); // Stores selected Workflow ID
  const [fxTableType, setfxTableType] = useState(null); // Stores FX Table type (Contractual/SpotRates)
  const [pricingProfileID, setPricingProfileID] = useState(null); // Stores selected Pricing Profile ID
  const [startDate, setStartDate] = useState(null); // Stores selected Start Date
  const [endDate, setEndDate] = useState(null); // Stores selected End Date
  const [status, setStatus] = useState("undefined"); // Stores selected Contract Status
  const [reportDataSource, setReportDataSource] = useState(["Empty"]); // Stores API response of the selected report & used when downloading report
  const [reportName, setReportName] = useState(""); // Stores Report name
  const [selected, setSelected] = useState([]); // Stores all the selected Report column names that needs to be displayed
  const [selectedCol, setSelectedCol] = useState([]); // Intermediary state which briefly holds change in report columns that needs to be displayed
  const [selectedColLoc, setSelectedColLoc] = useState([]); // Stores final change in the report columns that needs to be displayed
  const [listedCol, setListedCol] = useState([]); // Stores all the report column names that can be displayed
  const [rateCardSetup, setRateCardSetup] = useState([]); // Stores names of all Rate Card columns
  const [allRateCardColumns, setAllRateCardColumns] = useState([]); //Stores description of all RateCard Columns
  /* Stores raw Contract Commercial data from API which is sent to AggregateValue component, to display the Contract Aggregate Table.
   * Object parameters:
   * mode: Set to "view" so that the Aggregate table is in view only mode.
   * report: Set to boolean true when Contract Commercial report is generated.
   * contracts: Key added to commercialData after getting the raw Contract commercial data from API. */
  const [commercialData, setCommercialData] = useState({
    mode: "view",
    report: false,
  });
  /* Stores the processed Contract Commercial Aggregate data.
   * setAggregateData function is passed to AggregateValue component as a custom prop,
     so that aggregate data can be passed down to this component to view the Aggregate table or download the excel. */
  const [aggregateData, setAggregateData] = useState({});
  const [searchQuery, setSearchQuery] = useState(""); // Stores searched value of column name when filtering columns
  const [msaData, setMsaData] = useState([]);
  const [msaChildren, setMsaChildren] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  }); //Contract List table pagination
  const [contractDates, setContractDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedMsa, setSelectedMsa] = useState([]);
  const [selectedContractName, setSelectedContractName] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [supplierLoader, setSupplierLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [msaLoader, setMsaLoader] = useState(false);
  const [contractLoader, setContractLoader] = useState(false);

  const [form] = Form.useForm(); // Form controller to change value of Form Items

  const aggregateOrder = reportArrays["aggregateOrder"]; // Stores the correct order of Aggregate table columns
  const aggregateExpandedOrder = reportArrays["aggregateExpandedOrder"]; // Stores the correct order of expanded Aggregate table columns
  const monthMapping = reportArrays["monthMapping"]; // Stores the names of months of the year
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);
  // Function that fetches all the active supplier details
  //   useEffect(() => {
  //     suppliersOnlyApi().then((res) => {
  //       setSupplierData(res);
  //       console.log("suppliersOnlyApi", res);
  //       console.log("contractId", contractId);
  //       console.log("setupCategoryType", setupCategoryType);
  //     });
  //   }, []);

  useEffect(() => {
    getAllowedSupplierList(userData[0].id).then((res) => {
      setSupplierData(res);
      console.log("getAllowedSupplierList", res);
      console.log("contractId", contractId);
      console.log("setupCategoryType", setupCategoryType);
    });
  }, []);

  useEffect(() => {
    console.log("reportDataSource", reportDataSource.length);
  }, [reportDataSource]);
  // Function that fetches all Ratecard, Pricing, Invoicing and Workflow details
  useEffect(() => {
    if (setupSubCategoryType === "ratecard_setup") {
      getRatecardOnlyApi().then((res) => {
        setRateCardData(res);
        console.log("getRatecardOnlyApi", res);
      });
    } else if (setupSubCategoryType === "pricing_setup") {
      getPricingProfileOnlyApi().then((res) => {
        setPricingProfileData(res);
      });
    } else if (setupSubCategoryType === "invoicing_setup") {
      getAllInvoicingProfileOnlyApi().then((res) => {
        setInvoicingProfileData(res);
        console.log("all invoice", res);
      });
    } else if (setupSubCategoryType === "workflow_setup") {
      getAllWorkflowsOnlyApi().then((res) => {
        setWorkflowData(
          res.filter((workflow) => workflow.status !== "In Draft")
        );
        console.log("workflow data", res);
      });
    }
  }, [setupSubCategoryType]);

  // Function that fetches all Contracts and FX Table details
  useEffect(() => {
    if (categoryType === "contract_data") {
      if (typeof supplierId !== "undefined") {
        contractsAllApi(supplierId).then((res) => {
          setContractData(res);
        });
      }
    }
    if (setupSubCategoryType === "fx_setup") {
      if (typeof supplierId !== "undefined") {
        getSupplierFxApi(supplierId).then((res) => {
          setFxData(res);
          console.log("hi surya", res);
        });
      }
    }
    if (categoryType === "commercial_data") {
      if (typeof supplierId !== "undefined") {
        contractsAllApi(supplierId).then((res) => {
          setContractData(res);
        });
      }
    }
  }, [supplierId]);

  // Contract reports column descriptions
  const allContractColumns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      align: "left",
      show: selectedColLoc.includes("Supplier") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Type",
      dataIndex: "contract_type",
      align: "left",
      show: selectedColLoc.includes("Contract Type") ? true : false,
      ellipsis: true,
    },
    {
      title: "Parent Contract",
      dataIndex: "parent_contract",
      align: "left",
      show: selectedColLoc.includes("Parent Contract") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Name",
      dataIndex: "contract_name",
      align: "left",
      show: selectedColLoc.includes("Contract Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      show: selectedColLoc.includes("Start Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      show: selectedColLoc.includes("End Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "ACV (EUR)",
      dataIndex: "acv_(eur)",
      show: selectedColLoc.includes("ACV (EUR)") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "TCV (EUR)",
      dataIndex: "tcv_(eur)",
      show: selectedColLoc.includes("TCV (EUR)") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Initiator",
      dataIndex: "initiator",
      show: selectedColLoc.includes("Initiator") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Approval Levels",
      dataIndex: "approval_levels",
      show: selectedColLoc.includes("Approval Levels") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Current Approval Level",
      dataIndex: "current_approval_level",
      show: selectedColLoc.includes("Current Approval Level") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Current Approver",
      dataIndex: "current_approver",
      show: selectedColLoc.includes("Current Approver") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Submitted Date",
      dataIndex: "submitted_date",
      show: selectedColLoc.includes("Submitted Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Ageing",
      dataIndex: "ageing",
      show: selectedColLoc.includes("Ageing") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Approved Date",
      dataIndex: "approved_date",
      show: selectedColLoc.includes("Approved Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Rejection Level",
      dataIndex: "rejection_level",
      show: selectedColLoc.includes("Rejection Level") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Rejected Date",
      dataIndex: "rejected_date",
      show: selectedColLoc.includes("Rejected Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Reason For Rejection",
      dataIndex: "reason_for_rejection",
      show: selectedColLoc.includes("Reason For Rejection") ? true : false,
      align: "left",
      ellipsis: true,
    },
  ];

  // Supplier report column descriptions
  const allSupplierColumns = [
    {
      title: "Supplier ID",
      dataIndex: "supplier_id",
      align: "left",
      show: selectedColLoc.includes("Supplier ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      align: "left",
      show: selectedColLoc.includes("Supplier Name") ? true : false,
      ellipsis: true,
    },
    // {
    //   title: "Supplier Name Short",
    //   dataIndex: "supplier_name_short",
    //   align: "left",
    //   show: selectedColLoc.includes("Supplier Name Short") ? true : false,
    //   ellipsis: true,
    // },
    {
      title: "Supplier Status",
      dataIndex: "supplier_status",
      align: "left",
      show: selectedColLoc.includes("Supplier Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Entity",
      dataIndex: "supplier_entity",
      align: "left",
      show: selectedColLoc.includes("Supplier Entity") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Address",
      dataIndex: "supplier_address",
      align: "left",
      show: selectedColLoc.includes("Supplier Address") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Country",
      dataIndex: "supplier_country",
      align: "left",
      show: selectedColLoc.includes("Supplier Country") ? true : false,
      ellipsis: true,
    },
  ];

  // Legal entity report column descriptions
  const allLegalColumns = [
    {
      title: "Legal Entity ID",
      dataIndex: "legal_entity_id",
      align: "left",
      show: selectedColLoc.includes("Legal Entity ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Name",
      dataIndex: "legal_entity_name",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Status",
      dataIndex: "legal_entity_status",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Address",
      dataIndex: "legal_entity_address",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Address") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Country",
      dataIndex: "legal_entity_country",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Country") ? true : false,
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      align: "left",
      show: selectedColLoc.includes("Created On") ? true : false,
      ellipsis: true,
    },
    {
      title: "Last Updated On",
      dataIndex: "last_updated_on",
      align: "left",
      show: selectedColLoc.includes("Last Updated On") ? true : false,
      ellipsis: true,
    },
  ];

  // Cost center report column descriptions
  const allCostColumns = [
    {
      title: "Cost Center ID",
      dataIndex: "cost_center_id",
      align: "left",
      show: selectedColLoc.includes("Cost Center ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center Name",
      dataIndex: "cost_center_name",
      align: "left",
      show: selectedColLoc.includes("Cost Center Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center Number",
      dataIndex: "cost_center_number",
      align: "left",
      show: selectedColLoc.includes("Cost Center Number") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center Status",
      dataIndex: "cost_center_status",
      align: "left",
      show: selectedColLoc.includes("Cost Center Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity ID",
      dataIndex: "legal_entity_id",
      align: "left",
      show: selectedColLoc.includes("Legal Entity ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Name",
      dataIndex: "legal_entity_name",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      align: "left",
      show: selectedColLoc.includes("Created On") ? true : false,
      ellipsis: true,
    },
    {
      title: "Last Updated On",
      dataIndex: "last_updated_on",
      align: "left",
      show: selectedColLoc.includes("Last Updated On") ? true : false,
      ellipsis: true,
    },
  ];

  // Ratecard column descriptions are defined inside getAllRateCardData() because it is dynamic

  // Contractual FX report column descriptions
  const allFxContractualColumns = [
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
      align: "left",
      show: selectedColLoc.includes("Billing Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "Year",
      dataIndex: "year",
      align: "left",
      show: selectedColLoc.includes("Year") ? true : false,
      ellipsis: true,
    },
  ];

  // Spot rates FX report column descriptions
  const allFxSpotColumns = [
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
      align: "left",
      show: selectedColLoc.includes("Billing Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "Jan",
      dataIndex: "jan",
      align: "left",
      show: selectedColLoc.includes("Jan") ? true : false,
      ellipsis: true,
    },
    {
      title: "Feb",
      dataIndex: "feb",
      align: "left",
      show: selectedColLoc.includes("Feb") ? true : false,
      ellipsis: true,
    },
    {
      title: "Mar",
      dataIndex: "mar",
      align: "left",
      show: selectedColLoc.includes("Mar") ? true : false,
      ellipsis: true,
    },
    {
      title: "Apr",
      dataIndex: "apr",
      align: "left",
      show: selectedColLoc.includes("Apr") ? true : false,
      ellipsis: true,
    },
    {
      title: "May",
      dataIndex: "may",
      align: "left",
      show: selectedColLoc.includes("May") ? true : false,
      ellipsis: true,
    },
    {
      title: "Jun",
      dataIndex: "jun",
      align: "left",
      show: selectedColLoc.includes("Jun") ? true : false,
      ellipsis: true,
    },
    {
      title: "Jul",
      dataIndex: "jul",
      align: "left",
      show: selectedColLoc.includes("Jul") ? true : false,
      ellipsis: true,
    },
    {
      title: "Aug",
      dataIndex: "aug",
      align: "left",
      show: selectedColLoc.includes("Aug") ? true : false,
      ellipsis: true,
    },
    {
      title: "Sep",
      dataIndex: "sep",
      align: "left",
      show: selectedColLoc.includes("Sep") ? true : false,
      ellipsis: true,
    },
    {
      title: "Oct",
      dataIndex: "oct",
      align: "left",
      show: selectedColLoc.includes("Oct") ? true : false,
      ellipsis: true,
    },
    {
      title: "Nov",
      dataIndex: "nov",
      align: "left",
      show: selectedColLoc.includes("Nov") ? true : false,
      ellipsis: true,
    },
    {
      title: "Dec",
      dataIndex: "dec",
      align: "left",
      show: selectedColLoc.includes("Dec") ? true : false,
      ellipsis: true,
    },
  ];

  // Pricing profile report column descriptions
  const allPricingColumns = [
    {
      title: "Pricing Profile ID",
      dataIndex: "pricing_profile_id",
      align: "left",
      show: selectedColLoc.includes("Pricing Profile ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Pricing Profile Name",
      dataIndex: "pricing_profile_name",
      align: "left",
      show: selectedColLoc.includes("Pricing Profile Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Pricing Profile Status",
      dataIndex: "pricing_profile_status",
      align: "left",
      show: selectedColLoc.includes("Pricing Profile Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier ID",
      dataIndex: "supplier_id",
      align: "left",
      show: selectedColLoc.includes("Supplier ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      align: "left",
      show: selectedColLoc.includes("Supplier Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Pricing",
      dataIndex: "contract_pricing",
      align: "left",
      show: selectedColLoc.includes("Contract Pricing") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Currency",
      dataIndex: "contract_currency",
      align: "left",
      show: selectedColLoc.includes("Contract Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "Delivery Currency",
      dataIndex: "delivery_currency",
      align: "left",
      show: selectedColLoc.includes("Delivery Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "FX Table",
      dataIndex: "fx_table",
      align: "left",
      show: selectedColLoc.includes("FX Table") ? true : false,
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      align: "left",
      show: selectedColLoc.includes("Created On") ? true : false,
      ellipsis: true,
    },
    {
      title: "Last Updated On",
      dataIndex: "last_updated_on",
      align: "left",
      show: selectedColLoc.includes("Last Updated On") ? true : false,
      ellipsis: true,
    },
  ];

  // Invoicing profile report column descriptions
  const allInvoicingColumns = [
    {
      title: "From Entity",
      dataIndex: "from_entity",
      align: "left",
      show: selectedColLoc.includes("From Entity") ? true : false,
      ellipsis: true,
    },
    {
      title: "To Entity",
      dataIndex: "to_entity",
      align: "left",
      show: selectedColLoc.includes("To Entity") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center",
      dataIndex: "cost_center",
      align: "left",
      show: selectedColLoc.includes("Cost Center") ? true : false,
      ellipsis: true,
    },
    {
      title: "Allocation Percentage",
      dataIndex: "allocation_percentage",
      align: "left",
      show: selectedColLoc.includes("Allocation Percentage") ? true : false,
      ellipsis: true,
    },
  ];

  // Workflow report column descriptions
  const allWorkflowColumns = [
    {
      title: "Step",
      dataIndex: "step",
      align: "left",
      show: selectedColLoc.includes("Step") ? true : false,
      ellipsis: true,
    },
    {
      title: "Step Fulfillment",
      dataIndex: "step_fulfillment",
      align: "left",
      show: selectedColLoc.includes("Step Fulfillment") ? true : false,
      ellipsis: true,
    },
    {
      title: "From",
      dataIndex: "from",
      align: "left",
      show: selectedColLoc.includes("From") ? true : false,
      ellipsis: true,
    },
    {
      title: "To",
      dataIndex: "to",
      align: "left",
      show: selectedColLoc.includes("To") ? true : false,
      ellipsis: true,
    },
    {
      title: "Approver",
      dataIndex: "approver",
      align: "left",
      show: selectedColLoc.includes("Approver") ? true : false,
      ellipsis: true,
    },
    {
      title: "Approval Type",
      dataIndex: "approval_type",
      align: "left",
      show: selectedColLoc.includes("Approval Type") ? true : false,
      ellipsis: true,
    },
    {
      title: "Email ID",
      dataIndex: "email_id",
      align: "left",
      show: selectedColLoc.includes("Email ID") ? true : false,
      ellipsis: true,
    },
  ];

  /* Function to handle change in choosing main category of reports
   * @Param value - Holds the selected main category of report
   */
  const onChangeCategory = (value) => {
    if (value !== undefined) {
      setCategoryType(value);
      setCategorySelect(true);
    } else {
      setCategorySelect(false);
      form.setFieldsValue({ report: null, setup_report: null });
      setSetupCategoryType("");
      setCategoryType("");
    }
  };

  /* Function to handle change in choosing Supplier name
   * @Param key - Holds the selected Supplier ID
   * @Param value - Holds an array with key, value and label of the selected Supplier
   */
  //   const onChangeSupplier = (key, value) => {
  //     if (value !== undefined) {
  //       console.log("key", key, "val", value);
  //       setSupplierId(value.key);
  //       form.setFieldsValue({ contract_name: null });
  //       setContractId(null);
  //     }
  //   };

  //   const onChangeSupplier = (key, value) => {
  //     if (value !== undefined) {
  //       console.log("key", key, "val", value);
  //       getAllMsaApi().then((res) => {
  //         // console.log("msares", res);
  //         let filteredMsa = res.filter((data) => data.supplier_id === value.key);
  //         setMsaData(
  //           filteredMsa
  //             ? filteredMsa
  //             : res.docs.filter((data) => data.supplier_id === value.key)
  //         );
  //       });
  //       setSupplierId(value.key);
  //       //   form.setFieldsValue({ contract_name: null });
  //       //   setContractId(null);
  //     }
  //   };

  // const onChangeSupplier = (keys, values) => {
  //   if (keys.includes("all")) {
  //     // Select all supplier options internally when "All" is clicked
  //     const allSupplierKeys = supplierData.map((data) => data._id);
  //     form.setFieldsValue({ supplier_name: ["all"] }); // Set "All" as the visible selection
  //     setSupplierId(allSupplierKeys);

  //     // Automatically select all MSAs
  //     getAllMsaApi().then((res) => {
  //       const allMsaKeys = res.map((data) => data.id);
  //       form.setFieldsValue({ msa: ["all"] });
  //       setMsaData(res);
  //     });
  //   } else {
  //     // Handle specific supplier selection
  //     setSupplierId(keys);
  //     getAllMsaApi().then((res) => {
  //       let filteredMsa = res.filter((data) => keys.includes(data.supplier_id));
  //       setMsaData(filteredMsa.length > 0 ? filteredMsa : res);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   handleSupplierChange(["All"]);
  // }, []);

  const handleSupplierChange = (value) => {
    setSelectedSupplier(value);
    if (value.includes("All")) {
      form.setFieldsValue({
        supplier_name: ["All"],
      });
    }
    if (value.length !== 0) {
      setMsaLoader(true);
      getContractBySupplier(value, "MSA").then((res) => {
        let msa = [
          {
            _id: "All",
            contract_name: "All",
          },
          ...res,
        ];
        setMsaLoader(false);
        setMsaData(msa);
      });
    } else {
      setMsaData([]);
      form.setFieldsValue({
        msa: undefined,
        contract_name: undefined,
        status: undefined,
        start_date: undefined,
        end_date: undefined,
      });
      setMsaChildren([]);
      setMsaData([]);
      setSelectedMsa([]);
      setSelectedContractName([]);
      setSelectedStatus([]);
      setTableData([]);
    }
  };

  const handleMsaChange = (value) => {
    setSelectedMsa(value);
    if (value.includes("All")) {
      form.setFieldsValue({
        msa: ["All"],
      });
    }
    if (value.length !== 0) {
      setContractLoader(true);
      getContractParentData(value, "MSA").then((res) => {
        let msaChilds = [];
        if (value.includes("All")) {
          msaChilds = [
            {
              _id: "All",
              contract_name: "All",
            },
            ...res,
          ];
        } else {
          msaChilds = [
            {
              _id: "All",
              contract_name: "All",
            },
            ...res.map((contract) => contract.children).flat(),
          ];
        }
        setContractLoader(false);
        setMsaChildren(msaChilds);
      });
    } else {
      setMsaChildren([]);
    }
  };

  const customTagRender = (props, type) => {
    const { label, value, closable, onClose } = props;

    // Display "All" tag if selected
    if (value === "all") {
      return (
        <span className={`custom-tag-${type}`} style={{ color: "#1890ff" }}>
          {label}
        </span>
      );
    }

    // Default behavior for other tags
    return (
      <span
        className={`custom-tag-${type}`}
        onMouseDown={(e) => e.preventDefault()}
      >
        {label}
      </span>
    );
  };

  // const onChangeMsa = (key, value) => { };

  const onChangeMsa = (keys, values) => {
    if (keys.includes("all")) {
      // Select all MSA options internally when "All" is clicked
      const allMsaKeys = msaData.map((data) => data.id);
      form.setFieldsValue({ msa: ["all"] }); // Set "All" as the visible selection
    } else {
      console.log("Selected MSAs:", keys, values);
    }
  };

  /* Function to handle change in choosing RateCard name
   * @Param key - Holds the selected RateCard ID
   * @Param value - Holds an array with key, value and label of the selected RateCard
   */
  const onChangeRateCard = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "val", value);
      setrateCardId(value.key);
    }
  };

  /* Function to handle change in choosing Contract name
   * @Param key - Holds the selected Contract ID
   * @Param value - Holds an array with key, value, type and label of the selected Contract
   */
  const onChangeContract = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setContractId(value.key);
      setContractType(value.type);
    }
  };

  /* Function to handle change in choosing FX Table name
   * @Param key - Holds the selected FX Table ID
   * @Param value - Holds an array with key, value and label of the selected FX Table
   */
  const onChangeFxTable = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setfxTableId(value.key);
    }
  };

  /* Function to handle change in choosing between ContractualFX or SpotRates FX
   * @Param key - Holds the type of FX Table selected (contratual/spot)
   * @Param value - Holds an array with value and label of the selected FX Table
   */
  const onChangeFxTableType = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setfxTableType(value.value);
    }
  };

  /* Function to handle change in choosing Pricing Profile name
   * @Param key - Holds the selected Pricing Profile ID
   * @Param value - Holds an array with key, value and label of the selected Pricing Profile
   */
  const onChangePricingProfile = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setPricingProfileID(value.key);
    }
  };

  /* Function to handle change in choosing Invoicing Profile name
   * @Param key - Holds the selected Invoicing Profile ID
   * @Param value - Holds an array with key, value and label of the selected Invoicing Profile
   */
  const onChangeInvoiceProfile = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setInvoiceProfileId(value.key);
    }
  };

  /* Function to handle change in choosing Workflow name
   * @Param key - Holds the selected Workflow ID
   * @Param value - Holds an array with key, value and label of the selected Workflow
   */
  const onChangeWorkflow = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setWorkflowId(value.key);
    }
  };

  /* Function to handle change in choosing Contract Status
   * @Param key - Holds the name of Contract Status
   * @Param value - Holds an array with value and label of the selected Contract Status
   */
  const onChangeStatus = (key, value) => {
    if (value !== undefined) {
      console.log("key", key, "value", value);
      setStatus(key);
    }
  };

  // Function which handles downloading Report as an Excel file
  //   const exportExcel = () => {
  //     console.log("rd", reportDataSource);
  //     let excelData = [];
  //     let columnWidths = [];
  //     const capitalize = (column_name) => {
  //       //Capitalize first letter and remove "_"
  //       let words = column_name.split("_");
  //       let capitalizedWords = words.map(
  //         (word) => word.charAt(0).toUpperCase() + word.slice(1)
  //       );
  //       let result = capitalizedWords.join(" ");
  //       let temp = result.split(" ");
  //       if (result === "Acv (eur)" || result === "Tcv (eur)") {
  //         result = result.toUpperCase();
  //       } else if (result.split(" ")[result.split(" ").length - 1] === "Id") {
  //         temp[temp.length - 1] = temp[temp.length - 1].toUpperCase();
  //         result = temp.join(" ");
  //       } else if (result.split(" ")[0] === "Cr") {
  //         temp[0] = temp[0].toUpperCase();
  //         result = temp.join(" ");
  //       }
  //       return result;
  //     };
  //     if (Object.keys(aggregateData).length !== 0) {
  //       //Executes for Commercial Report
  //       const flattenData = (data, order) => {
  //         return data.reduce((result, item) => {
  //           const {
  //             children,
  //             contract,
  //             original_value,
  //             cr_value,
  //             net_value,
  //             jan,
  //             feb,
  //             mar,
  //             apr,
  //             may,
  //             jun,
  //             jul,
  //             aug,
  //             sep,
  //             oct,
  //             nov,
  //             dec,
  //             total,
  //           } = item;
  //           const currentContract = contract;
  //           const orderedObject = {
  //             contract: currentContract,
  //             original_value,
  //             cr_value,
  //             net_value,
  //             jan,
  //             feb,
  //             mar,
  //             apr,
  //             may,
  //             jun,
  //             jul,
  //             aug,
  //             sep,
  //             oct,
  //             nov,
  //             dec,
  //             total,
  //           };
  //           const flattenedItem = Object.fromEntries(
  //             order.map((key) => [monthMapping[key] || key, orderedObject[key]])
  //           );
  //           result.push(flattenedItem);
  //           if (children) {
  //             result = result.concat(flattenData(children, order));
  //           }
  //           return result;
  //         }, []);
  //       };

  //       const aggregateDataSource = flattenData(
  //         aggregateData.tableData,
  //         aggregateData.expanded ? aggregateExpandedOrder : aggregateOrder
  //       );
  //       console.log("AggregateDataSource: ", aggregateDataSource);
  //       excelData = aggregateDataSource.map((row) => {
  //         let rowArr = Object.entries(row);
  //         rowArr.map((column) => {
  //           column[0] = capitalize(column[0]);
  //           return column;
  //         });
  //         return Object.fromEntries(rowArr);
  //       });
  //       columnWidths = Object.entries(excelData[0]).map((column) => ({
  //         wch: column[0].length + 8,
  //       }));
  //       console.log("Aggregate ExcelData: ", excelData);
  //     } else {
  //       //Executes for Contract/Setup Report
  //       excelData = reportDataSource.map((row) => {
  //         let rowArr = Object.entries(row);
  //         let selectedRowArr = rowArr.filter((column) => {
  //           if (selectedColLoc.includes(capitalize(column[0]))) {
  //             column[0] = capitalize(column[0]);
  //             return column;
  //           }
  //         });
  //         return Object.fromEntries(selectedRowArr);
  //       });

  //       console.log("ExcelData: ", excelData);

  //       columnWidths = selectedColLoc.map((columnName) => ({
  //         wch: columnName.length + 8, //Adjusting Column Width in the Excel Sheet
  //       }));
  //     }

  //     const workBook = XLSX.utils.book_new();
  //     const workSheet = XLSX.utils.json_to_sheet(excelData, {
  //       cols: columnWidths,
  //     });
  //     workSheet["!cols"] = columnWidths;

  //     XLSX.utils.book_append_sheet(
  //       workBook,
  //       workSheet,
  //       //This parameter is for setting the Excel Sheet Name
  //       `${capitalize(
  //         categoryType !== "setup_data"
  //           ? setupCategoryType === "cr_report" ||
  //             setupCategoryType === "msa_report"
  //             ? setupCategoryType
  //             : `${setupCategoryType + "_report"}`
  //           : setupSubCategoryType
  //       )}`
  //     );
  //     XLSX.writeFile(workBook, `${reportName}.xlsx`);
  //     console.log("Exported");
  //   };

  const exportExcel = () => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(reportDataSource);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Report Data");
    XLSX.writeFile(workBook, "report_data.xlsx");
    console.log("done");
  };

  // Function to handle filtering RateCard columns
  const filterRateCard = () => {
    let temp = [...allRateCardColumns];
    temp = temp.map((element) => {
      if (selectedCol.includes(element.title)) {
        element.show = true;
      } else {
        element.show = false;
      }
      return element;
    });
    setAllRateCardColumns(temp);
  };

  // Function to handle searching column names while filtering columns
  const filterSearch = (value) => {
    const searched = listedCol.filter((col) =>
      col.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const unselected = searched.filter((element) => !value.includes(element));
    const newSelected = [...new Set([...selectedCol, ...value])];
    const updatedSelectedCol = newSelected.filter(
      (element) => !unselected.includes(element)
    );
    setSelectedCol(updatedSelectedCol);
  };

  // Variable which stores the column description of the selected type of report & passed to AntD Table
  let columnToShow =
    categoryType === "setup_data"
      ? setupSubCategoryType === "supplier_setup"
        ? allSupplierColumns.filter((col) => col.show !== false)
        : setupSubCategoryType === "legal_setup"
        ? allLegalColumns.filter((col) => col.show !== false)
        : setupSubCategoryType === "cost_setup"
        ? allCostColumns.filter((col) => col.show !== false)
        : setupSubCategoryType === "ratecard_setup"
        ? allRateCardColumns.filter((col) => col.show !== false)
        : setupSubCategoryType === "fx_setup"
        ? fxTableType === "contractual"
          ? allFxContractualColumns.filter((col) => col.show !== false)
          : fxTableType === "spot"
          ? allFxSpotColumns.filter((col) => col.show !== false)
          : ""
        : setupSubCategoryType === "pricing_setup"
        ? allPricingColumns.filter((col) => col.show !== false)
        : setupSubCategoryType === "invoicing_setup"
        ? allInvoicingColumns.filter((col) => col.show !== false)
        : setupSubCategoryType === "workflow_setup"
        ? allWorkflowColumns.filter((col) => col.show !== false)
        : ""
      : categoryType === "contract_data" || categoryType === "commercial_data"
      ? allContractColumns.filter((col) => col.show !== false)
      : "";

  // Function which handles fetching & processing of raw API data into report data
  const getReportDetails = () => {
    const getAllContractData = (resData) => {
      const reportApiData =
        resData &&
        resData.map((i, index) => {
          let tableRow = {
            key: i._id,
            supplier: i.supplier_name,
            contract_type: i.contract_type,
            parent_contract: i.parent_contract,
            contract_name: i.contract_name,
            // contract_description: "",
            start_date: i.start_date
              ? moment(i.start_date).format("DD MMMM YYYY")
              : "",
            end_date: i.end_date
              ? moment(i.end_date).format("DD MMMM YYYY")
              : "",
            "acv_(eur)": i.contract_value_acv,
            "tcv_(eur)": i.contract_value_tcv,
            initiator: i.initiator,
            approval_levels: i.approval_levels,
            current_approval_level: i.current_approval_level,
            current_approver: i.current_approver,
            submitted_date: i.submitted_date
              ? moment(i.submitted_date).format("DD MMMM YYYY")
              : "",
            ageing: i.ageing,
            approved_date: i.updatedAt
              ? moment(i.updatedAt).format("DD MMMM YYYY")
              : "",
            rejection_level: i.rejection_level,
            rejected_date: i.rejected_date,
            reason_for_rejection: i.reason_for_rejection,
          };
          return tableRow;
        });
      console.log("Contract Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllSupplierData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          supplier_id: i.id,
          supplier_name: i.supplier_name,
          supplier_name_short: i.supplier_name_short,
          supplier_status: i.supplier_status,
          supplier_entity:
            i.supplier_entities &&
            i.supplier_entities.length !== 0 &&
            i.supplier_entities
              .map((entity) => entity.supplier_entity)
              .join(" | "),
          supplier_address:
            i.supplier_entities &&
            i.supplier_entities.length !== 0 &&
            i.supplier_entities
              .map((entity) => entity.supplier_address)
              .join(" | "),
          supplier_country:
            i.supplier_entities &&
            i.supplier_entities.length !== 0 &&
            i.supplier_entities
              .map((entity) => entity.supplier_country)
              .join(" | "),
        };
        return tableRow;
      });
      console.log("Supplier Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllLegalData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          legal_entity_id: i._id,
          legal_entity_name: i.le_name,
          legal_entity_status: i.le_status,
          legal_entity_address: i.le_address,
          legal_entity_country: i.le_country,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Legal Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllCostData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          cost_center_id: i._id,
          cost_center_name: i.cc_name,
          cost_center_number: i.cc_nbr,
          cost_center_status: i.cc_status,
          legal_entity_id: i.le_id,
          legal_entity_name: i.le_name,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Cost Center Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllRateCardData = (resData) => {
      const rateColName = resData[0].rt_items_desc;
      setRateCardSetup(rateColName);
      console.log("RateColName: ", rateColName);
      const rateData = resData[0].rt_items_detail;
      const allRateColumns = rateColName.map((element) => {
        return {
          title: element,
          dataIndex: element.toLowerCase().replace(/\s+/g, "_"),
          align: "left",
          // show: selectedColLoc.includes(element) ? true : false,
          show: true,
          ellipsis: true,
        };
      });
      console.log("RateColumns: ", allRateColumns);
      setAllRateCardColumns(allRateColumns);
      const reportApiData = rateData.map((row) => {
        const rowData = {};
        rateColName.forEach((colHeader, index) => {
          const formattedKey = colHeader.toLowerCase().replace(/\s+/g, "_"); // Convert to lowercase and replace spaces with underscores
          rowData[formattedKey] = row[index];
        });
        return rowData;
      });
      console.log("Rate Card Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllFxData = (resData, fx_type) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow =
          fx_type === "contractual"
            ? {
                billing_currency: i.billing_currency,
                year: i.year,
              }
            : fx_type === "spot"
            ? {
                billing_currency: i.billing_currency,
                jan: i.Jan,
                feb: i.Feb,
                mar: i.Mar,
                apr: i.Apr,
                may: i.May,
                jun: i.Jun,
                jul: i.Jul,
                aug: i.Aug,
                sep: i.Sep,
                oct: i.Oct,
                nov: i.Nov,
                dec: i.Dec,
              }
            : "";
        return tableRow;
      });
      console.log("FX Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllPricingData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let contract_cc = i.contract_cc.join(", ");
        let delivery_cc = i.delivery_cc.join(", ");
        let fx_table = i.fx_table_name;
        let contract_pricing =
          i.contract_pricing && i.contract_pricing.join(", ");
        let tableRow = {
          pricing_profile_id: i._id,
          pricing_profile_name: i.price_profile_name,
          pricing_profile_status: i.status,
          supplier_id: i.supplier_id,
          supplier_name: i.supplier_name,
          contract_pricing: contract_pricing,
          contract_currency: contract_cc,
          delivery_currency: delivery_cc,
          fx_table: fx_table,
          created_on: i.created_on
            ? moment(i.created_on).format("DD MMMM YYYY")
            : i.createdAt
            ? moment(i.createdAt).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updated_on
            ? moment(i.updated_on).format("DD MMMM YYYY")
            : i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Pricing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllInvoicingData = (resData) => {
      let reportApiData = [];
      if (Object.values(resData[0]).slice(0, 3).join("") === "N/A") {
        reportApiData = [];
      } else {
        reportApiData = resData.map((i, index) => {
          let tableRow = {
            from_entity: i.from_entity,
            to_entity: i.to_entity,
            cost_center: i.cc,
            allocation_percentage: i.allocation_p,
          };
          return tableRow;
        });
      }
      console.log("Invoicing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllWorkflowData = (resData) => {
      const levelData = resData[0].workflow_metadata;
      const reportApiData = [];
      Object.keys(levelData).forEach((level, levelIndex) => {
        levelData[level].forEach((approverData) => {
          let fromNode = approverData.from.join(", ");
          let toNode = approverData.to.join(", ");
          const rowData = {
            step: levelIndex + 1,
            step_fulfillment: approverData.stepFulfillment,
            from: fromNode,
            to: toNode.length !== 0 ? toNode : "End",
            approver: approverData.approver,
            approval_type: approverData.approvalType,
            email_id:
              approverData.emailId.length > 1
                ? approverData.emailId.join(", ")
                : approverData.emailId,
          };
          reportApiData.push(rowData);
        });
      });
      console.log("Workflow Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    console.log(
      "setupCategoryType: ",
      setupCategoryType,
      "supplierId: ",
      supplierId,
      "contractId: ",
      contractId,
      "startData: ",
      startDate,
      "endDate: ",
      endDate,
      "Status: ",
      status
    );
    if (categoryType === "contract_data") {
      contractFilterApi(
        setupCategoryType,
        supplierId,
        contractId,
        startDate,
        endDate,
        status
      ).then((res) => {
        console.log("contractFilterApi response", res);
        if (Object.keys(res).length !== 0) {
          getAllContractData(res);
        } else {
          setReportDataSource([]);
        }
      });
    } else if (categoryType === "commercial_data") {
      if (setupCategoryType === "commercial") {
        getAggregateValueApi(contractId, contractType).then((res) => {
          console.log("commercialFilterApi response", res);
          if (res) {
            setCommercialData({
              ...commercialData,
              report: true,
              contracts: res.docs && res.docs[0],
            });
          } else {
            setCommercialData({ ...commercialData, report: true });
          }
        });
      }
    } else if (categoryType === "setup_data") {
      if (setupSubCategoryType === "supplier_setup") {
        suppliersOnlyApi().then((res) => {
          console.log("supplierFilterApi response", res);
          getAllSupplierData(res);
        });
      } else if (setupSubCategoryType === "legal_setup") {
        legalEntitysOnlyApi().then((res) => {
          console.log("legalFilterApi response", res);
          getAllLegalData(res);
        });
      } else if (setupSubCategoryType === "cost_setup") {
        costCenterOnlyApi().then((res) => {
          console.log("costFilterApi response", res);
          getAllCostData(res);
        });
      } else if (setupSubCategoryType === "ratecard_setup") {
        console.log("rateCardId", rateCardId);
        getRatecardReportApi(rateCardId).then((res) => {
          console.log("rateCardFilterApi response", res);
          getAllRateCardData(res);
        });
      } else if (setupSubCategoryType === "fx_setup") {
        console.log("fxTableId, fxTableType", fxTableId, fxTableType);
        getFxTableReportApi(fxTableId, fxTableType).then((res) => {
          console.log("fxFilterApi response", res);
          getAllFxData(res, fxTableType);
        });
      } else if (setupSubCategoryType === "pricing_setup") {
        getPricingProfileReportApi(pricingProfileID).then((res) => {
          console.log("pricingFilterApi response", res);
          getAllPricingData(res);
        });
      } else if (setupSubCategoryType === "invoicing_setup") {
        getAllInvoicingProfileReportApi(invoiceProfileId).then((res) => {
          console.log("invoicingFilterApi response", res);
          getAllInvoicingData(res);
        });
      } else if (setupSubCategoryType === "workflow_setup") {
        getSingleWorkflowsReportApi(workflowId).then((res) => {
          console.log("workflowFilterApi response", res);
          getAllWorkflowData(res);
        });
      }
    }
  };

  /* Function to handle getting the names of report columns according to type of report
   * @Param reportType - Holds the selected type of report name
   */
  const getReportColumns = (reportType) => {
    console.log("ReportType: ", reportType);
    let activeColumns = [];
    let setupDataColumns = [
      "supplier_setup",
      "legal_setup",
      "cost_setup",
      "ratecard_setup",
      "fx_setup",
      "pricing_setup",
      "invoicing_setup",
      "workflow_setup",
    ];
    let repCol =
      reportType === "fx_setup"
        ? reportArrays[reportType][fxTableType]
        : reportType === "ratecard_setup"
        ? rateCardSetup
        : reportArrays[reportType];
    //filtering columns based on selected report
    if (setupDataColumns.includes(reportType)) {
      repCol.map((column) => {
        activeColumns.push(column);
      });
    } else {
      allContractColumns.map((column) => {
        if (repCol.includes(column.title)) {
          activeColumns.push(column.title);
        }
      });
    }
    setSelected(activeColumns);
    setSelectedCol(activeColumns);
    setSelectedColLoc(activeColumns);
    setListedCol(activeColumns);
  };

  // Function which gets the names of Ratecard report columns
  useEffect(() => {
    if (rateCardSetup.length !== 0) getReportColumns(setupSubCategoryType);
  }, [rateCardSetup]);

  /* Function which runs on Form submittion
   * @Param values - Holds all the user submitted form values
   */
  //   const onFinish = (values) => {
  //     Object.keys(values).forEach(
  //       //Removed keys with undefined values
  //       (key) => values[key] === undefined && delete values[key]
  //     );
  //     values["created_by"] = userData && userData[0] && userData[0].id;
  //     if (values["contract_name"]) {
  //       values["contract_type"] = contractType;
  //     }
  //     console.log("formValues: ", values);
  //     setFinalValues(values);
  //     if (values["setup_report"] === "ratecard_setup") {
  //       getReportDetails();
  //       values["schedule"] = schedule;
  //       setReportName(values["report_name"]);
  //       setFormLevel("generate");
  //       return;
  //     }
  //     if (generateCon) {
  //       getReportDetails();
  //       values["schedule"] = schedule;
  //       setReportName(values["report_name"]);
  //       if (values["setup_report"]) {
  //         getReportColumns(values["setup_report"]);
  //       } else {
  //         getReportColumns(values["report"]);
  //       }
  //       setFormLevel("generate");
  //     } else {
  //       let columns =
  //         values["category"] === "contract_data" ||
  //         values["category"] === "contract_data"
  //           ? reportArrays[values["report"]]
  //           : values["category"] === "setup_data"
  //           ? values["setup_report"] === "fx_setup"
  //             ? reportArrays[values["setup_report"]][values["fx_table_type"]]
  //             : values["setup_report"] === "ratecard_setup"
  //             ? rateCardSetup
  //             : reportArrays[values["setup_report"]]
  //           : [];
  //       console.log("columns edited: ", columns);
  //       onSubmit(values, columns);
  //     }
  //   };

  const onFinish = async (values) => {
    values["report_type"] = state;
    console.log("formvalues", values);
    contractFilterControllerApi(values).then((res) => {
      console.log("contractFilterControllerApires", res);
      getReportColumns("msa_report");
      const reportApiData =
        res.docs &&
        res.docs.map((i, index) => {
          let tableRow = {
            key: i._id,
            supplier: i.supplier_id,
            contract_type: i.contract_type,
            parent_contract: i.parent_cid,
            contract_name: i.contract_name,
            // contract_description: "",
            start_date: i.start_date
              ? moment(i.start_date).format("DD MMMM YYYY")
              : "",
            end_date: i.end_date
              ? moment(i.end_date).format("DD MMMM YYYY")
              : "",
            "acv_(eur)": i.annual_contract_value,
            "tcv_(eur)": i.total_contract_value,
            initiator: i.initiator,
            approval_levels: i.approval_levels,
            current_approval_level: i.current_approval_level,
            current_approver: i.current_approver,
            submitted_date: i.last_status_change_date
              ? moment(i.last_status_change_date).format("DD MMMM YYYY")
              : "",
            ageing: i.contract_ageing,
            approved_date: i.updatedAt
              ? moment(i.updatedAt).format("DD MMMM YYYY")
              : "",
            rejection_level: i.rejection_level,
            rejected_date: i.rejected_date,
            reason_for_rejection: i.reason_for_rejection,
          };
          return tableRow;
        });
      console.log("Contract Data: ", reportApiData);
      setReportDataSource(reportApiData);
      // setReportDataSource(res.docs);
      setFormLevel("generate");
    });
  };

  /* Function which runs when there was an error on Form submission
   * @Param errorInfo - Holds the error information which prevented form submission
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Error: ", errorInfo);
  };

  /* Function which calls the create report API after receiving the final form values
   * @Param values - Holds the final report form values
   */
  const createReport = (values) => {
    console.log("check the model");
    console.log("values  *******", values);
    createReportApi(values).then((res) => {
      console.log("new result ", res);
      if (res && generateCon) {
        popupModalInstance.successModal(
          values.report_name,
          ModalMessage.Report_Save,
          `/reports`
        );
      } else if (res && !generateCon) {
        popupModalInstance.successModal(
          values.report_name,
          ModalMessage.Report_Schedule_Later,
          `/reports`
        );
      }
    });
  };

  /* Function which runs after processing report form values
   * @Param finalValues - Holds the processed form values
   * @Param columns - Holds the names of report columns selected in the form
   */
  const onSubmit = (finalValues, columns) => {
    console.log("selectedColLoc", selectedColLoc);
    console.log("finalValues", finalValues);
    finalValues["column_desc"] = columns ? columns : selectedColLoc;
    console.log("after add columns", finalValues);
    console.log("after add columns length", finalValues.column_desc.length);
    getAllReportData().then((res) => {
      console.log("getall report", res);

      let duplicateCheck = res.some(
        (value) => value.report_name === finalValues.report_name
      );

      if (!duplicateCheck) {
        if (!generateCon && schedule) {
          console.log("schedule success***********");
          createReport(finalValues);
        } else {
          if (finalValues.column_desc.length !== 0) {
            console.log("success***********");
            createReport(finalValues);
          } else {
            Modal.warning({
              title: "warning",
              className: "popup-model-success",
              centered: true,
              icon: false,
              content: <div>Must be Select minimum one Column.. </div>,
              onOk() {},
            });
          }
        }
      } else {
        console.log("failed***********");
        Modal.warning({
          title: "warning",
          className: "popup-model-success",
          centered: true,
          icon: false,
          content: <div>Report Name already existed.. </div>,
          onOk() {},
        });
      }
    });
  };

  // Stores the column width spans for Form Labels and it's wrappers
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  /* Function which calculates individual column widths based on the length of the column title
   * @Param columnToShow - Holds the names of all report columns
   */
  const calcScrollWidth = (columnToShow) => {
    let totalWidth = 0;
    columnToShow &&
      columnToShow.map((column) => {
        totalWidth = totalWidth + column.title.length * 20;
      });
    return totalWidth;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          {formLevel === "" && (
            <Form
              form={form}
              name="create-report"
              onFinish={onFinish}
              onKeyDown={handleKeyDown}
              onFinishFailed={onFinishFailed}
              //   initialValues={[{ report_type: state & state }]}
            >
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <HeaderCard
                    mainMenu="Reports"
                    subMenu="Build Report"
                    itemName={`Create Report`}
                    // itemStatus={state.status}
                    goBackLink="/reports"
                    // state={""}
                    // showDetails={"create"}
                  />
                </Col>
              </Row>
              <div className="price-profile-content m-10">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-0"
                >
                  <Col span={24}>
                    <div
                      id="create-report-form-section"
                      className="create-supplier-div"
                    >
                      <div className="m-20">
                        <Row align="middle" type="flex">
                          {/* Report type */}
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="report_type"
                              label="REPORT TYPE"
                              colon={false}
                            >
                              <Input defaultValue={state} readOnly />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row align="middle" type="flex">
                          {/* <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="supplier_name"
                              label="SUPPLIER NAME"
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Supplier",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Supplier Name"
                                allowClear
                                showSearch
                                mode="multiple"
                                onChange={(key, value) =>
                                  onChangeSupplier(key, value)
                                }
                                options={[
                                  { key: "all", label: "All", value: "all" },
                                  ...(supplierData
                                    ? supplierData.map((data) => ({
                                        key: data._id,
                                        label: data.supplier_name,
                                        value: data._id,
                                      }))
                                    : []),
                                ]}
                                tagRender={(props) =>
                                  customTagRender(props, "supplier")
                                }
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                // onClear={() => {
                                //   form.setFieldsValue({
                                //     supplier_name: null,
                                //     contract_name: null,
                                //   });
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="msa"
                              label="MSA"
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select MSA",
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                onChange={(key, value) =>
                                  onChangeMsa(key, value)
                                }
                                showSearch={true}
                                mode="multiple"
                                placeholder="Choose MSA"
                                options={[
                                  { key: "all", label: "All", value: "all" },
                                  ...(msaData
                                    ? msaData.map((data) => ({
                                        key: data.id,
                                        label: data.contract_name,
                                        value: data.id,
                                      }))
                                    : []),
                                ]}
                                tagRender={(props) =>
                                  customTagRender(props, "msa")
                                }
                              ></Select>
                            </Form.Item>
                          </Col> */}
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="supplier_name"
                              label="SUPPLIER NAME"
                              colon={false}
                            >
                              <Select
                                allowClear
                                showSearch={true}
                                placeholder="Choose Supplier"
                                mode="multiple"
                                maxTagCount={2}
                                notFoundContent={
                                  supplierLoader && <LoadingOutlined />
                                }
                                onChange={handleSupplierChange}
                                options={
                                  supplierData &&
                                  supplierData.map((data) => ({
                                    key: data.id ? data.id : data._id,
                                    label: data.supplier_name,
                                    value: data.id ? data.id : data._id,
                                    disabled:
                                      selectedSupplier.length > 0 &&
                                      selectedSupplier.includes("All") &&
                                      data._id !== "All",
                                  }))
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="msa"
                              label="MSA"
                              colon={false}
                            >
                              <Select
                                allowClear
                                showSearch={true}
                                placeholder="Choose MSA"
                                mode="multiple"
                                maxTagCount={2}
                                onChange={handleMsaChange}
                                notFoundContent={
                                  msaLoader && <LoadingOutlined />
                                }
                                options={
                                  msaData &&
                                  msaData.map((data) => ({
                                    key: data.id ? data.id : data._id,
                                    label: data.contract_name,
                                    value: data.id ? data.id : data._id,
                                    disabled:
                                      selectedMsa.length > 0 &&
                                      selectedMsa.includes("All") &&
                                      data._id !== "All",
                                  }))
                                }
                                onClear={() => setMsaChildren([])}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row align="middle" type="flex">
                          {/* contract start date */}
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_start_date"
                              label="CONTRACT START DATE"
                              colon={false}
                            >
                              <DatePicker
                                format={"DD MMMM YYYY"}
                                className="report-input"
                                placeholder="Select Start Date"
                                onChange={(date) => setStartDate(date)}
                              />
                            </Form.Item>
                          </Col>
                          {/* contract end date */}
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="contract_end_date"
                              label="CONTRACT END DATE"
                              colon={false}
                            >
                              <DatePicker
                                format={"DD MMMM YYYY"}
                                className="report-input"
                                placeholder="Select End Date"
                                onChange={(date) => setEndDate(date)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row align="middle" type="flex">
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="created_after_date"
                              label="CREATED AFTER DATE"
                              colon={false}
                            >
                              <DatePicker
                                format={"DD MMMM YYYY"}
                                className="report-input"
                                placeholder="Select Date"
                                onChange={(date) => setEndDate(date)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="created_before_date"
                              label="CREATED BEFORE DATE"
                              colon={false}
                            >
                              <DatePicker
                                format={"DD MMMM YYYY"}
                                className="report-input"
                                placeholder="Select Date"
                                onChange={(date) => setEndDate(date)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row align="middle" type="flex" className="mt-10">
                          <Col span={3}>
                            <GenerateButton
                              type="primary"
                              htmlType="submit"
                              onClick={() => SetGenerateCon(true)}
                            />
                          </Col>

                          {schedule !== "null" && (
                            <Col span={3} style={{ marginLeft: 20 }}>
                              <ScheduleButton
                                htmlType="submit"
                                onClick={() => {
                                  SetGenerateCon(false);
                                }}
                                disabled={schedule !== "null" ? false : true}
                              />
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
          {formLevel === "generate" && (
            <>
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={18}
                  lg={18}
                  xl={20}
                >
                  <Card className="setup-header-card">
                    <Row type="flex" gutter={[8, 16]}>
                      <Col
                        className="vertical-align"
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={1}
                      >
                        <Row type="flex" gutter={[8, 16]}>
                          <Col span={12}>
                            <Image
                              src={arrow_left}
                              style={{ width: 25 }}
                              preview={false}
                              className="cursor-pointer"
                              onClick={() => {
                                setReportDataSource(["Empty"]);
                                setCommercialData({
                                  mode: "view",
                                  report: false,
                                });
                                setAggregateData({});
                                setFinalValues({});
                                setListedCol([]);
                                setSelected([]);
                                setSelectedCol([]);
                                setSelectedColLoc([]);
                                setFormLevel("");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={17}
                        sm={17}
                        md={19}
                        lg={19}
                        xl={22}
                      >
                        <span>Reports</span> &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span
                          className="cursor-pointer"
                          onClick={() => navigate(`/reports`)}
                        >
                          Build Report
                        </span>
                        &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span>Create Report</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <DownloadButton
                    disabled={reportDataSource.length > 0 ? false : true}
                    onClick={exportExcel}
                  />
                </Col>
                {/* <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SaveButton
                    disabled={reportDataSource.length > 0 ? false : true}
                    onClick={() => onSubmit(finalValues)}
                  />
                </Col> */}
              </Row>
              <div className="price-profile-content m-10">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-0"
                >
                  <Col span={24}>
                    <div className="create-supplier-div">
                      <div className="m-20">
                        <Row type="flex">
                          {setupCategoryType === "commercial" &&
                          commercialData &&
                          commercialData.report &&
                          Object.hasOwn(commercialData, "contracts") &&
                          reportDataSource[0] === "Empty" ? (
                            <AggregateValue
                              contractData={commercialData}
                              sendAggregate={setAggregateData}
                            />
                          ) : setupCategoryType === "commercial" &&
                            commercialData.report === false ? (
                            <ReportLoader />
                          ) : (
                            setupCategoryType === "commercial" &&
                            commercialData &&
                            commercialData.report &&
                            !Object.hasOwn(commercialData, "contracts") && (
                              <>
                                <Col span={24} align="center">
                                  <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                      "No Commercial Report was found"
                                    }
                                  />
                                </Col>
                              </>
                            )
                          )}
                          {setupCategoryType !== "commercial" &&
                          reportDataSource.length !== 0 &&
                          reportDataSource[0] !== "Empty" ? (
                            <>
                              {/* <Col span={24} align="right">
                                <Button
                                  icon={<EditOutlined />}
                                  className="filter-btn"
                                  type="primary"
                                  shape="round"
                                  onClick={() => openColEditor(true)}
                                >
                                  <span
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Edit Columns
                                  </span>
                                </Button>
                                {colEditor && (
                                  <Modal
                                    className="col-edit-modal"
                                    centered
                                    open={colEditor}
                                    onOk={() => {
                                      openColEditor(false);
                                    }}
                                    onCancel={() => {
                                      setSearchQuery("");
                                      openColEditor(false);
                                      setSelectedCol(selected);
                                      setSelectedColLoc(selected);
                                    }}
                                    closable={true}
                                    footer={null}
                                  >
                                    <div>
                                      <h3>Add Column</h3>
                                      <span className="col-editor-head">
                                        Select or Deselect the tag to show in
                                        Reports
                                      </span>
                                    </div>
                                    <Row type="flex">
                                      <Col span={24} className="mt-20">
                                        <Input
                                          placeholder="Search column tags"
                                          allowClear
                                          value={searchQuery}
                                          onChange={(e) =>
                                            setSearchQuery(e.target.value)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <div className="mt-20">
                                      <span className="col-editor-head">
                                        Different options in choosing the tag
                                        for Report
                                      </span>
                                    </div>
                                    <div className="col-list p-10">
                                      <Row gutter={[8, 16]} type="flex">
                                        <Col span={24}>
                                          {listedCol &&
                                          listedCol.length !== 0 &&
                                          listedCol.filter((col) =>
                                            col
                                              .toLowerCase()
                                              .includes(
                                                searchQuery.toLowerCase()
                                              )
                                          ).length !== 0 ? (
                                            <Checkbox.Group
                                              style={{
                                                display: "block",
                                                overflowY: "scroll",
                                                height: 250,
                                              }}
                                              options={listedCol
                                                .filter((col) =>
                                                  col
                                                    .toLowerCase()
                                                    .includes(
                                                      searchQuery.toLowerCase()
                                                    )
                                                )
                                                .map((col) => ({
                                                  label: col,
                                                  value: col,
                                                  disabled:
                                                    selectedCol.length === 1 &&
                                                    selectedCol.includes(col),
                                                }))}
                                              defaultValue={selectedCol}
                                              onChange={(value) => {
                                                if (searchQuery != "") {
                                                  filterSearch(value);
                                                  return;
                                                }
                                                setSelectedCol(value);
                                              }}
                                            />
                                          ) : (
                                            <p>
                                              No column name matches search
                                              query
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                    <Row type="flex">
                                      <Col
                                        span={24}
                                        align="middle"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginTop: 20,
                                        }}
                                      >
                                        <Col
                                          span={6}
                                          align="middle"
                                          className="mt-20"
                                        >
                                          <SaveButton
                                            onClick={() => {
                                              if (
                                                setupSubCategoryType ===
                                                "ratecard_setup"
                                              ) {
                                                filterRateCard();
                                              }
                                              setSearchQuery("");
                                              setSelected(selectedCol);
                                              setSelectedColLoc(selectedCol);
                                              openColEditor(false);
                                            }}
                                          />
                                        </Col>
                                      </Col>
                                    </Row>
                                  </Modal>
                                )}
                              </Col> */}
                              <DataTable
                                type="report"
                                dataTableData={reportDataSource}
                                colData={allContractColumns}
                                pagination={true}
                              />
                            </>
                          ) : setupCategoryType !== "commercial" &&
                            reportDataSource[0] === "Empty" ? (
                            <ReportLoader />
                          ) : (
                            setupCategoryType !== "commercial" &&
                            reportDataSource.length === 0 && (
                              <>
                                <Col span={24} align="center">
                                  <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={"No Report was found"}
                                  />
                                </Col>
                              </>
                            )
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      ) : (
        <NotAuthorized redirect={`/reports`} />
      )}
    </Layout>
  );
};

export default CreateReportController;
