import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Image,
  Row,
  Col,
  Form,
  Input,
  Select,
  Layout,
  Table,
  Badge,
  Space,
  Upload,
  message,
} from "antd";
import TopHeader from "../components/Header";
import save_filter from "../assets/images/icons/save_filter.svg";
import { FilterOutlined, DownloadOutlined } from "@ant-design/icons";
import { HeaderChangerAtom, userDataAtom } from "../store/store";
import { useRecoilState } from "recoil";
import "../assets/style/contractfile.css";
import attach from "../assets/images/icons/attach.svg";
import send from "../assets/images/icons/send.svg";
import { CancelButton, OkButton } from "../components/GlobalButton";
import moment from "moment";
import {
  downloadFileApi,
  updateActionInstanceApi,
} from "../api/actionInstanceApi";
import { getInstanceByIdApi } from "../api/actionInstanceApi";
import { createActivityLogApi } from "../api/logApi";
import PopupModal from "../PopupModal";
import ModalMessage from "../ModalMessage";

const InstanceApprovalPage = () => {
  const { instanceId } = useParams();
  const popupModalInstance = PopupModal(); //global popup modal
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const history = useNavigate(); //react-router-dom navigate method
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [tableNewData, setTableNewData] = useState([]);
  const [tableOldData, setTableOldData] = useState([]);
  const [actionFile, setActionFile] = useState();
  const [comments, setComments] = useState([]);
  const [oldComments, setOldComments] = useState([]);
  const [commentEntered, setCommentEntered] = useState("");
  const [saveTableLoader, setSaveTableLoader] = useState(false); //save loader on api integration
  const [instanceData, setInstanceData] = useState();
  console.log("instanceId", instanceId);

  useEffect(() => {
    getInstanceByIdApi(instanceId).then((res) => {
      console.log("Instance res", res);
      setInstanceData(res[0]);

      const fileData =
        res[0] &&
        res[0].action_data &&
        res[0].action_data.length > 0 &&
        res[0].action_data.map((item) => {
          setOldComments([...oldComments, ...item.comments]);
          let tableRow = {
            document_name: (
              <span
                style={{
                  color: "var(--color-solid-darkblue)",
                  fontWeight: "100",
                }}
                onClick={() => {}}
              >
                {item.file_name}
              </span>
            ),
            action_instance_status: res[0] && res[0].instance_status,
            doc_uploaded_on: moment(item.uploaded_on).format("DD MMM YYYY"),
            doc_uploader_name: item.author_name,
            doc_uploaded_by: item.uploaded_by,
            action: (
              <>
                <div
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  <DownloadOutlined
                    title="Download"
                    style={{
                      fontSize: "20px",
                      color: "var(--color-solid-darkgrey)",
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      onDownloadFile(item.file_name_on_s3);
                    }}
                  />
                </div>
              </>
            ),
          };
          return tableRow;
        });
      setTableOldData(fileData && fileData.length > 0 ? fileData : []);
      setTableNewData(fileData && fileData.length > 0 ? fileData : []);
      const instanceComment =
        res[0] &&
        res[0].action_data &&
        res[0].action_data.length > 0 &&
        res[0].action_data.flatMap((obj) => obj.comments);
      setOldComments(
        instanceComment && instanceComment.length > 0 ? instanceComment : []
      );
    });
  }, [instanceId]);

  let header = headerChanger.header;
  const menuKey = "6";
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const columns = [
    {
      title: "Document Name",
      dataIndex: "document_name",
      align: "left",
    },
    {
      title: "Action Instance Status",
      dataIndex: "action_instance_status",
      align: "left",
    },
    {
      title: "Doc Uploaded On",
      dataIndex: "doc_uploaded_on",
      align: "left",
    },
    {
      title: "Doc Uploaded By",
      dataIndex: "doc_uploader_name",
      align: "left",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
    },
  ];

  const onDownloadFile = async (filename) => {
    console.log("filename", filename);

    try {
      const response = await downloadFileApi({ fileKey: filename });
      console.log("response file", response);
      // Create a temporary anchor element and trigger the download
      const url = window.URL.createObjectURL(
        new Blob([response], { type: response.type })
      ); // Specify content type
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const props = {
    name: "file",
    listType: "picture",
    showUploadList: false,
    // accept: ["xlsx", "xls"],
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length !== 0) {
          setActionFile(info.file);
          let data = [
            {
              document_name: (
                <span
                  style={{
                    color: "var(--color-solid-darkblue)",
                    fontWeight: "100",
                  }}
                  onClick={() => {}}
                >
                  {info.file.name.split(".")[0]}
                </span>
              ),
              action_instance_status:
                instanceData && instanceData.instance_status,
              doc_uploaded_on: moment(new Date()).format("DD MMM YYYY"),
              doc_uploader_name: userData[0].first_name,
              doc_uploaded_by: userData[0].id,
              action: (
                <>
                  <div
                    style={{
                      justifyContent: "space-evenly",
                      display: "flex",
                    }}
                  >
                    <DownloadOutlined
                      title="Download"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      // onClick={() => {onDownloadFile(item._id)}}
                    />
                  </div>
                </>
              ),
            },
          ];
          setTableNewData([...tableOldData, ...data]);
        } else {
          setActionFile();
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setActionFile(info.file);
        } else {
          setActionFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeComment = (e) => {
    setCommentEntered(e.target.value);
  };

  const onAddComment = () => {
    setComments([...comments, commentEntered]);
    setCommentEntered("");
  };

  useEffect(() => {
    console.log("comments", comments);
  }, [comments]);

  const markActionOption =
    instanceData &&
    instanceData.instance_status === "Submitted" &&
    instanceData &&
    instanceData.action_owner === userData[0].id
      ? ["Request Cancellation"]
      : instanceData &&
        instanceData.instance_status === "Open" &&
        instanceData &&
        instanceData.action_owner === userData[0].id
      ? ["Submitted", "Request Cancellation"]
      : instanceData &&
        instanceData.instance_status === "Open" &&
        instanceData &&
        instanceData.action_approver === userData[0].id
      ? ["Cancel Instance"]
      : instanceData &&
        instanceData.instance_status === "Submitted" &&
        instanceData &&
        instanceData.action_approver === userData[0].id
      ? ["Met", "Not Met", "Cancel Instance"]
      : instanceData &&
        instanceData.instance_status === "Submitted" &&
        instanceData &&
        instanceData.action_owner === userData[0].id &&
        instanceData.action_approver === userData[0].id
      ? ["Met", "Not Met", "Cancel Instance"]
      : [];

  const onFinish = async (values) => {
    let data = {
      root_action_id: instanceData.root_action[0]._id,
      instance_status: values.mark_action,
      updated_on: new Date(),
      updated_by: userData[0].id,
      due_date: instanceData.due_date,
      comments: comments,
      author_name: userData[0].first_name,
    };

    // Prepare form data
    const formData = new FormData();
    formData.append("file", actionFile && actionFile.originFileObj);
    formData.append("formData", JSON.stringify(data)); // Attach other form
    console.log("formdata", formData);

    updateActionInstanceApi(
      instanceData._id,
      instanceData.iteration_no,
      formData
    ).then((res) => {
      let ssLog = {
        ss_id: res.id ? res.id : res._id,
        ss_name: instanceData.root_action[0].action_name,
        code: "action-intance-update",
        author: userData[0].id,
        action: "edit",
        notes: "Updated Action instance status",
        updated_on: new Date(),
      };
      // api integration for store a record on activity log
      createActivityLogApi(ssLog);
      setSaveTableLoader(false);
      // global popup modal
      popupModalInstance.successModal(
        values.action_name,
        ModalMessage.AI_Update,
        `/action-management`
      );
    });
  };

  const handlePressEnter = () => {
    // Add logic to execute when Enter key is pressed
    console.log("Enter key pressed");
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {instanceData && (
        <Form onFinish={onFinish}>
          <div id="action-management-id" className="contract-pipeline">
            <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
              <Col
                className="p-0 vertical-align"
                xs={24}
                sm={24}
                md={16}
                lg={16}
                xl={
                  ((instanceData &&
                    instanceData.action_approver === userData[0].id) ||
                    (instanceData &&
                      instanceData.action_owner === userData[0].id)) &&
                  instanceData &&
                  instanceData.instance_status !== "Met" &&
                  instanceData &&
                  instanceData.instance_status !== "Delinquent"
                    ? 20
                    : 22
                }
                // style={{ background: "white", height: "24px" }}
              >
                <div className="filter-white-space-head">
                  <Col
                    className="p-0 vertical-align"
                    xs={24}
                    sm={24}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{ background: "white" }}
                  >
                    <Image
                      className="cursor-pointer saved-filter-image"
                      src={save_filter}
                      preview={false}
                      // style={{ width: "50%" }}
                    />
                  </Col>
                  <Col
                    className="p-0 vertical-align"
                    xs={24}
                    sm={24}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{ background: "white" }}
                  >
                    <div className="cursor-pointer">
                      <Badge>
                        <FilterOutlined
                          className="cursor-pointer filter-image"
                          // style={{ width: "18px", height: "19px", marginTop: "5px" }}
                        />
                      </Badge>
                    </div>
                  </Col>
                  <Space
                    className="filter-white-space"
                    style={{ width: "90%" }}
                    size={[0, 8]}
                  >
                    <p className="placeholder-text">Add Filters</p>
                  </Space>
                </div>
              </Col>
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <div className="">
                  <CancelButton
                    onClick={() => {
                      setHeaderChanger({
                        header: "actionManagement",
                        headerItem: "2",
                        headerItemName: "actionManagement",
                      });
                      history(`/action-management`, {
                        state: {
                          tabKey: 2,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
              {(instanceData.action_approver === userData[0].id ||
                instanceData.action_owner === userData[0].id) &&
                instanceData.instance_status !== "Met" &&
                instanceData.instance_status !== "Delinquent" && (
                  <Col xl={2} md={4} xs={24} className="vertical-align">
                    <div className="">
                      <OkButton
                        loading={saveTableLoader}
                        disabled={!actionFile}
                        type="primary"
                        htmlType="submit"
                      />
                    </div>
                  </Col>
                )}
            </Row>
            <div
              className="action-div-head"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              <span style={{ color: "black", fontSize: "17px" }}>
                <h4 className="mt-10">Action Instance</h4>
              </span>

              <div
                className="action-body-head"
                style={{ textAlign: "left", color: "black" }}
              >
                <div className="action-new-div">
                  <div className="create-entity-div">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "13px 30px 0px" }}
                    >
                      <Col span={19} className="vertical-align">
                        <h4 className="entity-text">Action Instance Details</h4>
                      </Col>
                    </Row>
                    <hr
                      className="mt-10 view-supplier-hr"
                      style={{ color: "grey" }}
                    />

                    <Row
                      align="middle"
                      type="flex"
                      gutter={[4, 16]}
                      className="m-0"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Col span={24}>
                          <div className="create-supplier-div">
                            <div className="m-10">
                              <Row align="middle" type="flex">
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_name"
                                    label="ACTION NAME"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.root_action &&
                                      instanceData.root_action.length !== 0 &&
                                      instanceData.root_action[0].action_name
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="iteration_no"
                                    label="ACTION INSTANCE ITERATION NO"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.iteration_no &&
                                      instanceData.iteration_no
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_description"
                                    label="DESCRIPTION"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.root_action &&
                                      instanceData.root_action.length !== 0 &&
                                      instanceData.root_action[0]
                                        .action_description
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row align="middle" type="flex">
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_category"
                                    label="CATEGORY"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.root_action &&
                                      instanceData.root_action.length !== 0 &&
                                      instanceData.root_action[0]
                                        .action_category
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_type"
                                    label="TYPE"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.root_action &&
                                      instanceData.root_action.length !== 0 &&
                                      instanceData.root_action[0].action_type
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_priority"
                                    label="PRIORITY"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.root_action &&
                                      instanceData.root_action.length !== 0 &&
                                      instanceData.root_action[0]
                                        .action_priority
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row align="middle" type="flex">
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="trigger_date"
                                    label="TRIGGER DATE"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.trigger_date &&
                                      moment(instanceData.trigger_date).format(
                                        "DD MMM YYYY"
                                      )
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="due_date"
                                    label="DUE DATE"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.due_date &&
                                      moment(instanceData.due_date).format(
                                        "DD MMM YYYY"
                                      )
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row align="middle" type="flex">
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_owner"
                                    label="ACTION OWNER"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.action_owner_name &&
                                      instanceData.action_owner_name
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_approver"
                                    label="ACTION APPROVER"
                                    colon={false}
                                    initialValue={
                                      instanceData &&
                                      instanceData.action_approver_name &&
                                      instanceData.action_approver_name
                                    }
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {(instanceData.action_approver === userData[0].id ||
                instanceData.action_owner === userData[0].id) &&
                instanceData.instance_status !== "Met" &&
                instanceData.instance_status !== "Delinquent" && (
                  <div
                    className="action-body-head"
                    style={{ textAlign: "left", color: "black" }}
                  >
                    <div className="action-new-div">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          style={{ padding: "13px 30px 0px" }}
                        >
                          <Col span={19} className="vertical-align">
                            <h4 className="entity-text">Fulfillment</h4>
                          </Col>
                        </Row>
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[4, 16]}
                          className="m-0"
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Col span={24}>
                              <div className="create-supplier-div">
                                <div className="m-10">
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="mark_action"
                                        label="MARK ACTION"
                                        colon={false}
                                      >
                                        <Select
                                          allowClear
                                          placeholder="Select Action"
                                          options={
                                            markActionOption &&
                                            markActionOption.map((data) => ({
                                              key: data,
                                              label: data,
                                              value: data,
                                            }))
                                          }
                                        ></Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}

              <div
                className="action-body-head "
                style={{ textAlign: "left", color: "black" }}
              >
                <div className="action-new-div mb-10">
                  <div className="create-entity-div">
                    <Row
                      align="middle"
                      type="flex"
                      style={{ padding: "13px 30px 0px" }}
                    >
                      <Col span={19} className="vertical-align">
                        <h4 className="entity-text">Upload & Docs</h4>
                      </Col>
                    </Row>
                    <hr
                      className="mt-10 view-supplier-hr"
                      style={{ color: "grey" }}
                    />
                    <Row align="middle" type="flex">
                      <hr
                        className="mt-10 view-supplier-hr"
                        style={{ color: "grey" }}
                      />
                      <Col span={20} className="vertical-align" offset={2}>
                        <div className="action-notes-div ">
                          <div className="text-center notes-header">
                            <h4>Notes & Comments</h4>
                          </div>
                          <div className="comments-div">
                            <div className="comments-align-right">
                              {comments &&
                                comments.length !== 0 &&
                                comments.map((comment) => {
                                  return (
                                    <div className="comments-new mt-10">
                                      {comment}
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="comments-align-left">
                              {oldComments &&
                                oldComments.length !== 0 &&
                                oldComments.map((comment) => {
                                  return (
                                    <div className="comments-old mt-10">
                                      {comment}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          {(instanceData.action_approver === userData[0].id ||
                            instanceData.action_owner === userData[0].id) &&
                            instanceData.instance_status !== "Met" &&
                            instanceData.instance_status !== "Delinquent" && (
                              <div className="action-org-view-div">
                                <Input
                                  // ref={inputRef}
                                  value={commentEntered}
                                  onPressEnter={handlePressEnter}
                                  onChange={onChangeComment}
                                  // style={{ background: "transparent" }}
                                  className="instance-notes-input"
                                  placeholder="Comments"
                                  suffix={
                                    <>
                                      <Upload
                                        {...props}
                                        customRequest={dummyRequest}
                                      >
                                        <Image
                                          title="Upload"
                                          className="cursor-pointer"
                                          src={attach}
                                          preview={false}
                                        />
                                      </Upload>

                                      <Image
                                        title="Add Comment"
                                        className="cursor-pointer"
                                        style={{ paddingLeft: 10 }}
                                        src={send}
                                        preview={false}
                                        onClick={onAddComment}
                                      />
                                    </>
                                  }
                                />
                                {/* <div className="instance-notes-input">
                            <p>Comments</p>
                            <div className="instance-notes-icons">
                              
                            </div>
                          </div> */}
                              </div>
                            )}
                        </div>
                        <Table
                          dataSource={tableNewData}
                          rowClassName={() => "contract-tree-table-row"}
                          columns={columns}
                          showSorterTooltip={false}
                          bordered
                          size="large "
                          scroll={{ x: "calc(300px + 50%)", y: 400 }}
                          pagination={false}
                          className="system-setup-table mt-10 mb-15"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Layout>
  );
};

export default InstanceApprovalPage;
