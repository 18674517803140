import { axiosInstance } from "../utils/common";

// create legalEntity
export const legalEntityCreateApi = (legalEntityData) => {
  return axiosInstance
    .post("v1/legalEntity", legalEntityData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// Get all details of a single Legal Entity by Legal Entity ID
export const getSingleLegalEntityDataApi = (legalEntityId) => {
  return axiosInstance
    .get(`v1/legalEntity/single/${legalEntityId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// Get all details of a single Legal Entity by Legal Entity ID
export const getLegalEntityByIdApi = (legalEntityId) => {
  return axiosInstance
    .get(`v1/legalEntity/${legalEntityId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// list legalEntity
export const listLegalEntity = () => {
  return axiosInstance
    .get("v1/legalEntity")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// list only active Legal Entities
export const legalEntitysOnlyApi = () => {
  return axiosInstance
    .get(`v1/legalEntity/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// list all Legal Entities
export const allLegalEntitysApi = () => {
  return axiosInstance
    .get(`v1/legalEntity/all`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// legalEntity pagination
export const listLegalEntityPagination = (
  page,
  limit,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/legalEntity/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// edit supplier
export const editLegalEntityApi = (legalEntityId, legalEntityDetail) => {
  return axiosInstance
    .patch(`v1/legalEntity/${legalEntityId}`, legalEntityDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete legal entity by ID
export const deleteLegalEntityApi = (legalEntityId) => {
  console.log("legalEntityId", legalEntityId);
  return axiosInstance
    .delete(`v1/legalEntity/${legalEntityId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllLegalFiltersApi = () => {
  return axiosInstance
    .get(`v1/legalEntity/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
