import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Layout, Table, Pagination, Button } from "antd"; //antd components
import {
  UpOutlined,
  EyeOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "../../assets/style/setup.css";
import "../../assets/style/contractpipeline.css";
import * as XLSX from "xlsx";
import { reportArrays, BuildReportData } from "../../utils/data";
import AggregateValue from "../Contract/AggregateValue";

import { useRecoilState } from "recoil";
import { userDataAtom, RoleAccessAtom } from "../../store/store";
import { reportPaginationApi } from "../../api/reportApi";
import {
  contractFilterApi,
  getAggregateValueApi,
} from "../../api/contractsApi";
import { suppliersOnlyApi } from "../../api/suppliersApi";
import { legalEntitysOnlyApi } from "../../api/legalEntityApi";
import { costCenterOnlyApi } from "../../api/costCenterApi";
import { getRatecardReportApi } from "../../api/ratecardApi";
import { getFxTableReportApi } from "../../api/fxTableApi";
import { getPricingProfileReportApi } from "../../api/pricingProfileApi";
import { getAllInvoicingProfileReportApi } from "../../api/invoicingProfileApi";
import { getSingleWorkflowsReportApi } from "../../api/workflowApi";
import { CreateButton } from "../GlobalButton";
import moment from "moment";
import GlobalFilter from "../GlobalFilter";

const ReportsController = () => {
  const userData = useRecoilState(userDataAtom); // Stores logged in user details from global state atom
  const navigate = useNavigate(); // Stores navigate function to navigate to other pages
  const [reportDataSource, setReportDataSource] = useState([]); // Stores API response of the selected report & used when downloading report
  const [rateCardSetup, setRateCardSetup] = useState([]); // Stores RateCard report column names
  const [commercialData, setCommercialData] = useState({
    mode: "view",
    report: false,
  });
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  const [selectedColLoc, setSelectedColLoc] = useState([]); // Stores the names of Report Columns that needs to be displayed
  const [download, setDownload] = useState(false); // Set to boolean true when the report needs to be downloaded
  const [generateReportData, SetGenerateReportData] = useState(); // Stores report details when report needs to be downloaded
  const [buildTabletData, setBuildTabletData] = useState([]); // Stores the Build Report table list of reports & passed to AntD table
  const [loader, setLoader] = useState(false); // Stores boolean value on whether to trigger the loading animation of table
  /* Stores the processed Contract Commercial Aggregate data.
  * setAggregateData function is passed to AggregateValue component as a custom prop,
    so that aggregate data can be passed down to this component to view the Aggregate table or download the excel. */
  const [aggregateData, setAggregateData] = useState({});
  const aggregateOrder = reportArrays["aggregateOrder"]; // Stores the correct order of Aggregate table columns
  const aggregateExpandedOrder = reportArrays["aggregateExpandedOrder"]; // Stores the correct order of expanded Aggregate table columns
  const monthMapping = reportArrays["monthMapping"]; // Stores the names of months of the year
  const [allFilterVal, setAllFilterVal] = useState({}); // Stores all the filter conditions
  const [sortOrder, setSortOrder] = useState(null); // State variable to hold sort order data
  const [sortedColumn, setSortedColumn] = useState(null); // State variable to hold sort column data
  const [sortColumnOptions, setSortColumnOptions] = useState({}); // Stores which columns need to sorted in which order

  const isColumnSorted = (column) => sortedColumn === column;

  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    listReport(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };

  /* Function to handle getting data from API according to type of report
   * @Param data - Holds the selected report details
   */
  const getReportDetails = (data) => {
    const getAllContractData = (resData) => {
      const reportApiData =
        resData &&
        resData.map((i) => {
          let tableRow = {
            key: i._id,
            supplier: i.supplier_name,
            contract_type: i.contract_type,
            parent_contract: i.parent_contract,
            contract_name: i.contract_name,
            start_date: i.start_date
              ? moment(i.start_date).format("DD MMMM YYYY")
              : "",
            end_date: i.end_date
              ? moment(i.end_date).format("DD MMMM YYYY")
              : "",
            "acv_(eur)": i.contract_value_acv,
            "tcv_(eur)": i.contract_value_tcv,
            initiator: i.initiator,
            approval_levels: i.approval_levels,
            current_approval_level: i.current_approval_level,
            current_approver: i.current_approver,
            submitted_date: i.submitted_date
              ? moment(i.submitted_date).format("DD MMMM YYYY")
              : "",
            ageing: i.ageing,
            approved_date: i.updatedAt
              ? moment(i.updatedAt).format("DD MMMM YYYY")
              : "",
            rejection_level: i.rejection_level,
            rejected_date: i.rejected_date,
            reason_for_rejection: i.reason_for_rejection,
          };
          return tableRow;
        });
      console.log("Contract Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllSupplierData = (resData) => {
      const reportApiData = resData.map((i) => {
        let tableRow = {
          supplier_id: i.id,
          supplier_name: i.supplier_name,
          // supplier_name_short: i.supplier_name_short,
          supplier_entity: i.supplier_entity,
          supplier_status: i.supplier_status,
          supplier_address: i.supplier_address,
          supplier_country: i.supplier_country,
        };
        return tableRow;
      });
      console.log("Supplier Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllLegalData = (resData) => {
      console.log("resData: ", resData);
      const reportApiData = resData.map((i) => {
        let tableRow = {
          legal_entity_id: i._id,
          legal_entity_name: i.le_name,
          legal_entity_status: i.le_status,
          legal_entity_address: i.le_address,
          legal_entity_country: i.le_country,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Legal Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllCostData = (resData) => {
      const reportApiData = resData.map((i) => {
        let tableRow = {
          cost_center_id: i._id,
          cost_center_name: i.cc_name,
          cost_center_number: i.cc_nbr,
          cost_center_status: i.cc_status,
          legal_entity_id: i.le_id,
          legal_entity_name: i.le_name,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Cost Center Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllRateCardData = (resData) => {
      const rateColName = resData[0].rt_items_desc;
      setRateCardSetup(rateColName);
      console.log("RateColName: ", rateColName);
      const rateData = resData[0].rt_items_detail;
      const reportApiData = rateData.map((row) => {
        const rowData = {};
        rateColName.forEach((colHeader, index) => {
          const formattedKey = colHeader.toLowerCase().replace(/\s+/g, "_"); // Convert to lowercase and replace spaces with underscores
          rowData[formattedKey] = row[index];
        });
        return rowData;
      });
      console.log("Rate Card Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllFxData = (resData, fx_type) => {
      console.log(resData, fx_type);
      const reportApiData = resData.map((i) => {
        let tableRow =
          fx_type === "contractual"
            ? {
                billing_currency: i.billing_currency,
                year: i.year,
              }
            : fx_type === "spot"
            ? {
                billing_currency: i.billing_currency,
                jan: i.Jan,
                feb: i.Feb,
                mar: i.Mar,
                apr: i.Apr,
                may: i.May,
                jun: i.Jun,
                jul: i.Jul,
                aug: i.Aug,
                sep: i.Sep,
                oct: i.Oct,
                nov: i.Nov,
                dec: i.Dec,
              }
            : "";
        return tableRow;
      });
      console.log("FX Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllPricingData = (resData) => {
      const reportApiData = resData.map((i) => {
        let contract_cc = i.contract_cc.join(", ");
        let delivery_cc = i.delivery_cc.join(", ");
        let fx_table = i.fx_table.join(", ");
        let contract_pricing = i.contract_pricing
          .map((ele) => {
            return ele === 1
              ? "Fixed"
              : ele === 2
              ? "Variable"
              : ele === 3
              ? "Agreed"
              : ele === 4
              ? "All"
              : "";
          })
          .join(", ");
        let tableRow = {
          pricing_profile_id: i._id,
          pricing_profile_name: i.price_profile_name,
          pricing_profile_status: i.status,
          supplier_id: i.supplier_id,
          supplier_name: i.supplier_name,
          contract_pricing: contract_pricing,
          contract_currency: contract_cc,
          delivery_currency: delivery_cc,
          fx_table: fx_table,
          created_on: i.created_on
            ? moment(i.created_on).format("DD MMMM YYYY")
            : i.createdAt
            ? moment(i.createdAt).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updated_on
            ? moment(i.updated_on).format("DD MMMM YYYY")
            : i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Pricing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllInvoicingData = (resData) => {
      const reportApiData = resData.map((i) => {
        let tableRow = {
          from_entity: i.from_entity,
          to_entity: i.to_entity,
          allocation_percentage: i.allocation_p,
        };
        return tableRow;
      });
      console.log("Invoicing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllWorkflowData = (resData) => {
      const levelData = resData[0].workflow_metadata;
      const reportApiData = [];
      Object.keys(levelData).forEach((level, levelIndex) => {
        levelData[level].forEach((approverData) => {
          let fromNode = approverData.from.join(", ");
          let toNode = approverData.to.join(", ");
          const rowData = {
            step: levelIndex + 1,
            step_fulfillment: approverData.stepFulfillment,
            from: fromNode,
            to: toNode,
            approver: approverData.approver,
            approval_type: approverData.approvalType,
            email_id: approverData.emailId,
          };
          reportApiData.push(rowData);
        });
      });
      console.log("Workflow Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    console.log(data, "data");
    console.log(
      "setupCategoryType: ",
      data.type_of_report,
      "supplierId: ",
      data.supplier_id,
      "contractId: ",
      data.contract_id,
      "startData: ",
      data.contract_start_date !== undefined
        ? data.contract_start_date
        : "null",
      "endDate: ",
      data.contract_end_date !== undefined ? data.contract_end_date : "null",
      "Status: ",
      data.contract_status
    );
    if (data.report_category === "contract_data") {
      contractFilterApi(
        data.type_of_report,
        data.supplier_id,
        data.contract_id !== undefined ? data.contract_id : "null",
        data.contract_start_date !== undefined
          ? data.contract_start_date
          : "null",
        data.contract_end_date !== undefined ? data.contract_end_date : "null",
        data.contract_status
      ).then((res) => {
        console.log("contractFilterApi response", res);
        getAllContractData(res);
      });
    } else if (data.report_category === "commercial_data") {
      if (data.type_of_report === "commercial") {
        getAggregateValueApi(data.contract_id, data.contract_type).then(
          (res) => {
            console.log("commercialFilterApi response", res);
            setCommercialData({
              ...commercialData,
              report: true,
              // download: true,
              contracts: res.docs[0],
            });
          }
        );
      }
    } else if (data.report_category === "setup_data") {
      if (data.setup_report === "supplier_setup") {
        suppliersOnlyApi().then((res) => {
          console.log("supplierFilterApi response", res);
          getAllSupplierData(res);
        });
      } else if (data.setup_report === "legal_setup") {
        legalEntitysOnlyApi().then((res) => {
          console.log("legalFilterApi response", res);
          getAllLegalData(res);
        });
      } else if (data.setup_report === "cost_setup") {
        costCenterOnlyApi().then((res) => {
          console.log("costFilterApi response", res);
          getAllCostData(res);
        });
      } else if (data.setup_report === "ratecard_setup") {
        console.log("rateCardId", data.ratecard_id);
        getRatecardReportApi(data.ratecard_id).then((res) => {
          console.log("rateCardFilterApi response", res);
          getAllRateCardData(res);
        });
      } else if (data.setup_report === "fx_setup") {
        console.log(
          "fxTableId, fxTableType",
          data.fx_table_id,
          data.fx_table_type
        );
        getFxTableReportApi(data.fx_table_id, data.fx_table_type).then(
          (res) => {
            console.log("fxFilterApi response", res);
            getAllFxData(res, data.fx_table_type);
          }
        );
      } else if (data.setup_report === "pricing_setup") {
        getPricingProfileReportApi(data.pricing_profile_id).then((res) => {
          console.log("pricingFilterApi response", res);
          getAllPricingData(res);
        });
      } else if (data.setup_report === "invoicing_setup") {
        getAllInvoicingProfileReportApi(data.invoicing_profile_id).then(
          (res) => {
            console.log("invoicingFilterApi response", res);
            getAllInvoicingData(res);
          }
        );
      } else if (data.setup_report === "workflow_setup") {
        getSingleWorkflowsReportApi(data.workflow_id).then((res) => {
          console.log("workflowFilterApi response", res);
          getAllWorkflowData(res);
        });
      }
    }
  };

  /* Function to handle processing of report data into a format which can be downloaded as an Excel file
   * @Param data - Holds the selected report details
   */
  const exportExcel = (data) => {
    let excelData = [];
    let columnWidths = [];
    const capitalize = (column_name) => {
      //Capitalize first letter and remove "_"
      let words = column_name.split("_");
      let capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      let result = capitalizedWords.join(" ");
      let temp = result.split(" ");
      if (result === "Acv (eur)" || result === "Tcv (eur)") {
        result = result.toUpperCase();
      } else if (result.split(" ")[result.split(" ").length - 1] === "Id") {
        temp[temp.length - 1] = temp[temp.length - 1].toUpperCase();
        result = temp.join(" ");
      } else if (result.split(" ")[0] === "Cr") {
        temp[0] = temp[0].toUpperCase();
        result = temp.join(" ");
      }
      return result;
    };
    console.log("Aggregate Data: ", aggregateData);
    if (
      Object.keys(aggregateData).length !== 0 &&
      generateReportData.type_of_report === "commercial"
    ) {
      //Executes for Commercial Report
      const flattenData = (data, order) => {
        return data.reduce((result, item) => {
          const {
            children,
            contract,
            original_value,
            cr_value,
            net_value,
            jan,
            feb,
            mar,
            apr,
            may,
            jun,
            jul,
            aug,
            sep,
            oct,
            nov,
            dec,
            total,
          } = item;
          const currentContract = contract;
          const orderedObject = {
            contract: currentContract,
            original_value,
            cr_value,
            net_value,
            jan,
            feb,
            mar,
            apr,
            may,
            jun,
            jul,
            aug,
            sep,
            oct,
            nov,
            dec,
            total,
          };
          const flattenedItem = Object.fromEntries(
            order.map((key) => [monthMapping[key] || key, orderedObject[key]])
          );
          result.push(flattenedItem);
          if (children) {
            result = result.concat(flattenData(children, order));
          }
          return result;
        }, []);
      };

      const aggregateDataSource = flattenData(
        aggregateData.tableData,
        aggregateData.expanded ? aggregateExpandedOrder : aggregateOrder
      );
      console.log("AggregateDataSource: ", aggregateDataSource);
      excelData = aggregateDataSource.map((row) => {
        let rowArr = Object.entries(row);
        rowArr.map((column) => {
          column[0] = capitalize(column[0]);
          return column;
        });
        return Object.fromEntries(rowArr);
      });
      columnWidths = Object.entries(excelData[0]).map((column) => ({
        wch: column[0].length + 8,
      }));
      console.log("Aggregate ExcelData: ", excelData);
    } else {
      //Executes for Contract/Setup Report
      excelData = reportDataSource.map((row) => {
        let rowArr = Object.entries(row);
        let selectedRowArr = rowArr.filter((column) => {
          if (selectedColLoc.includes(capitalize(column[0]))) {
            column[0] = capitalize(column[0]);
            return column;
          }
        });
        return Object.fromEntries(selectedRowArr);
      });

      console.log("ExcelData: ", excelData);

      columnWidths = selectedColLoc.map((columnName) => ({
        wch: columnName.length + 8, //Adjusting Column Width in the Excel Sheet
      }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(excelData, {
      cols: columnWidths,
    });
    workSheet["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      //This parameter is for setting the Excel Sheet Name
      `${capitalize(
        data.setup_report !== "setup_data"
          ? data.type_of_report === "cr_report" ||
            data.type_of_report === "msa_report"
            ? data.type_of_report
            : `${data.type_of_report + "_report"}`
          : data.type_of_report
      )}`
    );
    XLSX.writeFile(workBook, `${data.report_name}.xlsx`);
    console.log("Exported");
    setDownload(false);
    setLoader(false);
  };

  /* Precursor function to exportExcel function which prepares the selected report data
   * @Param data - Holds the selected report details
   */
  const downloadExcel = (data) => {
    setSelectedColLoc(
      data.column_desc.length !== 0
        ? data.column_desc
        : data.type_of_report === "fx_setup"
        ? reportArrays[data.type_of_report][data.fx_table_type]
        : data.type_of_report === "ratecard_setup"
        ? rateCardSetup
        : reportArrays[data.type_of_report]
    );
    console.log(data);
    getReportDetails(data);
  };

  // Function which initiates download of report as Excel after selected report data is ready
  useEffect(() => {
    if (
      download &&
      (generateReportData.report_category === "contract_data" ||
        generateReportData.report_category === "setup_data")
    ) {
      console.log("generateReportData: ", generateReportData);
      exportExcel(generateReportData);
    }
  }, [reportDataSource]);

  // Function which initiates download of Contract Commercial Report after aggregate data is ready
  useEffect(() => {
    if (
      download &&
      generateReportData.type_of_report === "commercial" &&
      aggregateData.tableData.length !== 0 &&
      aggregateData.tableData[0]
    ) {
      exportExcel(generateReportData);
    }
  }, [aggregateData]);

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Stores the column descriptions of Build Report Table
  const buildReportColumns = [
    {
      title: renderColumnTitle("Report Name", "report_name"),
      dataIndex: "report_name",
      align: "left",
      show: true,
      sorter: true,
      sortOrder: isColumnSorted("report_name") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Action", "action"),
      dataIndex: "action",
      align: "left",
      show: true,
      sorter: true,
      sortOrder: isColumnSorted("action") ? sortOrder : false,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   align: "center",
    //   width: 250,
    //   show:
    //     roleUser &&
    //     roleUser[0] &&
    //     roleUser[0].page_access.reports.build_report.view
    //       ? true
    //       : false,
    // },
  ];
  // State variabel to hold filter the all column datas
  const columns = buildReportColumns.filter((col) => col.show !== false);

  // Stores Pagination data for switching between pages of table
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  /* Function which gets report pagination data & processes it to display on the Build Report Table
   * @param page - Current active page number
   * @param limit - Number of records per page
   * @param allFilterVal - All applied filters of the table
   */
  //   const listReport = (page, limit, allFilterVal, sortColumn) => {
  //     setLoader(true);
  //     reportPaginationApi(page, limit, allFilterVal, sortColumn).then((res) => {
  //       setLoader(false);
  //       console.log("res--", res);
  //       setPaginateData({
  //         ...paginateData,
  //         pageNo: page,
  //         limit: limit,
  //         total: res.totalDocs,
  //       });
  //       let reportMaster = res.docs.map((data) => {
  //         const tableRow = {
  //           key: data._id,
  //           report_name: data.report_name,
  //           created_by: data.creater_first_name + " " + data.creater_last_name,
  //           action: (
  //             <>
  //               <div
  //                 style={{
  //                   justifyContent: "space-evenly",
  //                   display: "flex",
  //                 }}
  //               >
  //                 {roleUser &&
  //                   roleUser[0] &&
  //                   roleUser[0].page_access.reports.build_report.view && (
  //                     <EyeOutlined
  //                       title="View"
  //                       style={{
  //                         fontSize: "20px",
  //                         color: "var(--color-solid-darkgrey)",
  //                       }}
  //                       className="cursor-pointer"
  //                       onClick={() => {
  //                         navigate(`/reports/build-report/view/report`, {
  //                           state: data,
  //                         });
  //                       }}
  //                     />
  //                   )}
  //                 {roleUser &&
  //                   roleUser[0] &&
  //                   roleUser[0].page_access.reports.build_report.view && (
  //                     <DownloadOutlined
  //                       title="Download"
  //                       style={{
  //                         fontSize: "20px",
  //                         color: "var(--color-solid-darkgrey)",
  //                       }}
  //                       className="cursor-pointer"
  //                       onClick={() => {
  //                         setLoader(true);
  //                         setDownload(true);
  //                         SetGenerateReportData(data);
  //                         downloadExcel(data);
  //                       }}
  //                     />
  //                   )}
  //               </div>
  //             </>
  //           ),
  //         };
  //         return tableRow;
  //       });
  //       setBuildTabletData(reportMaster);
  //     });
  //     };

  const listReport = () => {
    // setLoader(true);
    // reportPaginationApi(page, limit, allFilterVal, sortColumn).then((res) => {
    // setLoader(false);
    // console.log("res--", res);
    // setPaginateData({
    //   ...paginateData,
    //   pageNo: page,
    //   limit: limit,
    //   total: res.totalDocs,
    // });
    let reportMaster = BuildReportData.map((data) => {
      console.log("data", data);
      const tableRow = {
        key: data._id || "",
        report_name: data,
        // created_by: data.creater_first_name + " " + data.creater_last_name,
        action: (
          <>
            <div
              style={{
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <Button
                onClick={() => {
                  navigate(`/reports/create/report`, {
                    state: data,
                  });
                }}
              >
                Generate
              </Button>
              {/* {roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.reports.build_report.view && (
                  <EyeOutlined
                    title="View"
                    style={{
                      fontSize: "20px",
                      color: "var(--color-solid-darkgrey)",
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/reports/build-report/view/report`, {
                        state: data,
                      });
                    }}
                  />
                )} */}
              {/* {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.reports.build_report.view && (
                    <DownloadOutlined
                      title="Download"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setLoader(true);
                        setDownload(true);
                        SetGenerateReportData(data);
                        downloadExcel(data);
                      }}
                    />
                  )} */}
            </div>
          </>
        ),
      };
      return tableRow;
    });
    setBuildTabletData(reportMaster);
    // });
  };

  /* Function to handle switching between pages of the table
   * @param page - Selected page number
   * @param limit - Number of records per page
   */
  // const onPageChange = (page, limit) => {
  //   listReport(page, limit, allFilterVal, sortColumnOptions);
  // };

  // Function which processes the selected filter & updates the table, everytime a filter is applied
  // useEffect(() => {
  //   if (Object.keys(allFilterVal || {}).length === 0) {
  //     listReport(1, 20, allFilterVal, sortColumnOptions);
  //   } else {
  //     for (let key in allFilterVal) {
  //       if (
  //         Array.isArray(allFilterVal[key]) &&
  //         allFilterVal[key].length === 0
  //       ) {
  //         delete allFilterVal[key];
  //       }
  //     }
  //     if (Object.keys(allFilterVal || {}).length === 0) {
  //       listReport(1, 20, allFilterVal, sortColumnOptions);
  //     } else {
  //       listReport(1, 20, allFilterVal, sortColumnOptions);
  //     }
  //   }
  // }, [allFilterVal]);
  useEffect(() => {
    listReport();
  }, []);

  useEffect(() => {
    document.title = "Build Report | Judo";
  }, []);

  return (
    <Layout className="header-layout">
      <div id="sys-setup-id" className="contract-pipeline">
        {/* <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.reports.build_report.create
                ? 15
                : 24
            }
            lg={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.reports.build_report.create
                ? 15
                : 24
            }
            xl={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.reports.build_report.create
                ? 22
                : 24
            }
          >
            <GlobalFilter
              selectedTab={"buildReport"}
              initialTableData={buildTabletData}
              allFilterVal={allFilterVal}
              setAllFilterVal={setAllFilterVal}
            />
          </Col>
          {roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.reports.build_report.create && (
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <Link id="create-report" to="/reports/create/report">
                  <CreateButton />
                </Link>
              </Col>
            )}
        </Row> */}
        <Row align="middle" type="flex" className="mt-10">
          <Col className="p-0 vertical-align">
            <Table
              showSorterTooltip={false}
              dataSource={buildTabletData}
              columns={columns}
              onChange={handleTableChange}
              bordered
              size="large"
              loading={loader}
              scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }}
              pagination={false}
              className="system-setup-table"
            />
            {/* <div className="float-right mb-15 mt-15">
              <Pagination
                // pageSizeOptions={[5, 10, 20, 50, 100]}
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onPageChange}
                onChange={onPageChange}
              />
            </div> */}
          </Col>
        </Row>
        {download && commercialData && commercialData.contracts && (
          <AggregateValue
            contractData={commercialData}
            sendAggregate={setAggregateData}
          />
        )}
      </div>
    </Layout>
  );
};

export default ReportsController;
