// External imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
  Input,
  Layout,
  Modal,
  Space,
  Tabs,
  Table,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Internal Imports
import TopHeader from "../../Header";
import { HeaderChangerAtom, RoleAccessAtom } from "../../../store/store";
import "../../../assets/style/actionmanagement.css";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/checkselect.css";
import account_tree from "../../../assets/images/icons/account_tree.svg";
import repeat from "../../../assets/images/icons/repeat.svg";
import repeat_small from "../../../assets/images/icons/repeat_small.svg";
import NotAuthorized from "../../NotAuthorized";
import { dataTable } from "../../../utils/common";
import DataTable from "../../Tables/DataTable";

// Functional componnet for view Root action
const ViewRootAction = () => {
  const history = useNavigate(); //react-router-dom navigate method
  const [form] = Form.useForm(); //custom hook for managing form
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  let { state } = useLocation(); //state data which is sent from parent component
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);

  const [createModel, setCreateModel] = useState(false);
  const [selectedDaysIndex, setSelectedDaysIndex] = useState([0]); // state for set custom trigger selected days corresponding index for css use
  const [tabKey, setTabKey] = useState("1"); //store active tab key
  const [tableLoader, setTableLoader] = useState(false); //action instance table loader
  const [dataSource, setDataSource] = useState([]); // datasource for action instance table
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  useEffect(() => {
    // setting initial value for the state 'selectedDaysIndex' according to data from parent
    if (state && state.actionData && state.actionData.custom_repeat) {
      // Define a mapping object
      const dayNumberMapping = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6,
      };
      // Create a new array by mapping the days to their corresponding numbers
      const resultDayIndex =
        state.actionData.custom_repeat.days &&
        state.actionData.custom_repeat.days.map((day) => dayNumberMapping[day]);
      setSelectedDaysIndex(resultDayIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  let header = headerChanger.header; // assign header key to a variable
  const menuKey = "6"; //menu key of current page
  // form layout label and formitem alignment prop
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const days = ["M", "T", "W", "T", "F", "S", "S"]; //Days shortcut array for button in custom trigger
  // tab items
  const tabItems = [
    {
      key: "1",
      label: "Root Action",
    },
    {
      key: "2",
      label: "Action Instance",
    },
    {
      key: "3",
      label: "Entity Log",
    },
  ];

  useEffect(() => {
    setTableLoader(true);
    if (
      state &&
      state.actionData &&
      state.actionData.action_repeat &&
      state.actionData.action_repeat === "Don't Repeat"
    ) {
      let instanceData = [
        {
          iteration_no: 1,
          action_name: `${
            state &&
            state.actionData &&
            state.actionData.action_name &&
            state.actionData.action_name
          } - 1`,
          instance_status:
            state &&
            state.actionData &&
            state.actionData.action_instance &&
            state.actionData.action_instance.length === 0
              ? "Upcoming Action"
              : state &&
                state.actionData &&
                state.actionData.action_instance[0].instance_status &&
                state.actionData.action_instance[0].instance_status,
          trigger_date:
            state &&
            state.actionData &&
            state.actionData.trigger_date &&
            dayjs.utc(state.actionData.trigger_date).format("DD MMM YYYY"),
          due_date:
            state &&
            state.actionData &&
            state.actionData.due_date &&
            dayjs.utc(state.actionData.due_date).format("DD MMM YYYY"),
          owner:
            state &&
            state.actionData &&
            state.actionData.action_owner_name &&
            state.actionData.action_owner_name,
        },
      ];
      setDataSource(instanceData);
      setTableLoader(false);
    }

    if (
      state &&
      state.actionData &&
      state.actionData.action_repeat &&
      state.actionData.action_repeat === "Custom"
    ) {
      console.log("custom instance");
      // const instanceData = [];
      // const {
      //   trigger_date,
      //   end_date,
      //   action_owner_name,
      //   due_in,
      //   custom_repeat,
      // } = state && state.actionData && state.actionData;
      // let iterationNo = 1;
      // let currentDate = new Date(trigger_date);

      // // function formatDate(date) {
      // //   const options = { day: "2-digit", month: "short", year: "numeric" };
      // //   return new Date(date).toLocaleDateString("en-US", options);
      // // }

      // // Function to add days to a date
      // const addDays = (date, days) => {
      //   const result = new Date(date);
      //   result.setDate(result.getDate() + days);
      //   return result;
      // };

      // // Function to add weeks to a date
      // const addWeeks = (date, weeks) => {
      //   const result = new Date(date);
      //   result.setDate(result.getDate() + weeks * 7);
      //   return result;
      // };

      // // Function to add months to a date
      // const addMonths = (date, months) => {
      //   const result = new Date(date);
      //   result.setMonth(result.getMonth() + months);
      //   return result;
      // };

      // // Function to add years to a date
      // const addYears = (date, years) => {
      //   const result = new Date(date);
      //   result.setFullYear(result.getFullYear() + years);
      //   return result;
      // };

      // while (currentDate <= new Date(end_date)) {
      //   const existingInstance =
      //     state &&
      //     state.actionData &&
      //     state.actionData.action_instance &&
      //     state.actionData.action_instance.find(
      //       (instance) => instance.iteration_no === iterationNo++
      //     );
      //   let instanceStatus = "Upcoming";
      //   if (existingInstance) {
      //     instanceStatus = existingInstance.instance_status;
      //   }

      //   custom_repeat.days.forEach((day) => {
      //     const dayIndex = [
      //       "Sunday",
      //       "Monday",
      //       "Tuesday",
      //       "Wednesday",
      //       "Thursday",
      //       "Friday",
      //       "Saturday",
      //     ].indexOf(day);
      //     const dayDifference = dayIndex - currentDate.getDay();
      //     let targetDate;

      //     if (dayDifference >= 0) {
      //       targetDate = addDays(currentDate, dayDifference);
      //     } else {
      //       targetDate = addDays(currentDate, dayDifference + 7);
      //     }

      //     if (targetDate <= new Date(end_date)) {
      //       instanceData.push({
      //         iteration_no: iterationNo,
      //         trigger_date: targetDate,
      //         due_date: addDays(targetDate, due_in),
      //         action_name: `${
      //           state &&
      //           state.actionData &&
      //           state.actionData.action_name &&
      //           state.actionData.action_name
      //         } - ${iterationNo}`,
      //         instance_status: instanceStatus,
      //         owner: action_owner_name,
      //       });
      //     }
      //     iterationNo++;
      //   });

      //   switch (custom_repeat.unit_of_time) {
      //     case "Week":
      //       currentDate = addWeeks(currentDate, custom_repeat.time_count);
      //       break;
      //     case "Month":
      //       currentDate = addMonths(currentDate, custom_repeat.time_count);
      //       break;
      //     case "Year":
      //       currentDate = addYears(currentDate, custom_repeat.time_count);
      //       break;
      //     default:
      //       break;
      //   }
      // }

      function generateIterations(input) {
        const instanceData = [];
        let iterationNo = 1;
        let currentDate = new Date(input.trigger_date);

        function formatDate(date) {
          const options = { day: "2-digit", month: "short", year: "numeric" };
          const formattedDate = new Date(date);
          formattedDate.setUTCDate(formattedDate.getUTCDate() - 1); // Subtract one day
          return formattedDate.toLocaleDateString("en-US", options);
        }

        // Function to add days to a date
        const addDays = (date, days) => {
          const result = new Date(date);
          result.setUTCDate(result.getUTCDate() + days);
          return result;
        };

        // Function to add weeks to a date
        const addWeeks = (date, weeks) => {
          const result = new Date(date);
          result.setUTCDate(result.getUTCDate() + weeks * 7);
          return result;
        };

        // Function to add months to a date
        const addMonths = (date, months) => {
          const result = new Date(date);
          result.setUTCMonth(result.getUTCMonth() + months);
          return result;
        };

        // Function to add years to a date
        const addYears = (date, years) => {
          const result = new Date(date);
          result.setUTCFullYear(result.getUTCFullYear() + years);
          return result;
        };

        while (currentDate <= new Date(input.end_date)) {
          const existingInstance =
            state &&
            state.actionData &&
            state.actionData.action_instance &&
            state.actionData.action_instance.find(
              (instance) => instance.iteration_no === iterationNo++
            );
          let instanceStatus = "Upcoming";
          if (existingInstance) {
            instanceStatus = existingInstance.instance_status;
          }

          input.custom_repeat.days.forEach((day) => {
            const dayIndex = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ].indexOf(day);
            const dayDifference = dayIndex - currentDate.getUTCDay();
            let targetDate;

            if (dayDifference >= 0) {
              targetDate = addDays(currentDate, dayDifference);
            } else {
              targetDate = addDays(currentDate, dayDifference + 7);
            }

            if (targetDate <= new Date(input.end_date)) {
              instanceData.push({
                iteration_no: iterationNo,
                trigger_date: formatDate(targetDate), // Convert to ISO string for consistent formatting
                due_date: formatDate(addDays(targetDate, input.due_in)),
                action_name: `${
                  state &&
                  state.actionData &&
                  state.actionData.action_name &&
                  state.actionData.action_name
                } - ${iterationNo}`,
                instance_status: instanceStatus,
                owner: input.action_owner_name,
              });
              iterationNo++;
            }
          });

          switch (input.custom_repeat.unit_of_time) {
            case "Week":
              currentDate = addWeeks(
                currentDate,
                input.custom_repeat.time_count
              );
              break;
            case "Month":
              currentDate = addMonths(
                currentDate,
                input.custom_repeat.time_count
              );
              break;
            case "Year":
              currentDate = addYears(
                currentDate,
                input.custom_repeat.time_count
              );
              break;
            default:
              break;
          }
        }

        return instanceData;
      }

      // Example usage:
      const input = {
        start_date: "2024-05-08T18:30:00.000Z",
        end_date: "2024-12-31T18:30:00.000Z",
        due_in: 1,
        trigger_date: "2024-05-13T18:30:00.000Z",
        due_date: "2024-05-14T18:30:00.000Z",
        action_repeat: "Custom",
        custom_repeat: {
          time_count: "3",
          unit_of_time: "Week",
          days: ["Monday", "Wednesday"],
        },
      };

      const instanceData = generateIterations(
        state && state.actionData && state.actionData
      );

      console.log("instanceData", instanceData);
      setDataSource(instanceData);
      setTableLoader(false);
    } else {
      const instanceData = [];
      const { trigger_date, end_date, action_owner_name, due_in } =
        state && state.actionData && state.actionData;
      let currentTriggerDate = dayjs.utc(trigger_date);
      let iteration = 1;

      while (currentTriggerDate.isBefore(dayjs.utc(end_date).add(1, "day"))) {
        let dueIn = currentTriggerDate.clone().add(due_in, "days");

        const existingInstance =
          state &&
          state.actionData &&
          state.actionData.action_instance &&
          state.actionData.action_instance.find(
            (instance) => instance.iteration_no === iteration
          );
        let instanceStatus = "Upcoming";
        if (existingInstance) {
          instanceStatus = existingInstance.instance_status;
        }

        instanceData.push({
          iteration_no: iteration,
          trigger_date: dayjs
            .utc(currentTriggerDate.toISOString())
            .format("DD MMM YYYY"),
          due_date: dayjs.utc(dueIn.toISOString()).format("DD MMM YYYY"),
          action_name: `${
            state &&
            state.actionData &&
            state.actionData.action_name &&
            state.actionData.action_name
          } - ${iteration}`,
          instance_status: instanceStatus,
          owner: action_owner_name,
        });

        iteration++;
        currentTriggerDate =
          state &&
          state.actionData &&
          state.actionData.action_repeat &&
          state.actionData.action_repeat === "Daily"
            ? currentTriggerDate.clone().add(1, "day")
            : state &&
              state.actionData &&
              state.actionData.action_repeat &&
              state.actionData.action_repeat === "Yearly"
            ? currentTriggerDate.clone().add(1, "year")
            : state &&
              state.actionData &&
              state.actionData.action_repeat &&
              state.actionData.action_repeat === "Weekly"
            ? currentTriggerDate.clone().add(1, "week")
            : currentTriggerDate.clone().add(1, "month");
      }
      console.log("instanceData", instanceData);
      setDataSource(instanceData);
      setTableLoader(false);
    }
  }, [state]);

  // action instance table column
  const columns = [
    {
      title: "Iteration No",
      dataIndex: "iteration_no",
      key: "iteration_no",
    },
    {
      title: "Name",
      dataIndex: "action_name",
      key: "action_name",
    },
    {
      title: "Status",
      dataIndex: "instance_status",
      key: "instance_status",
    },
    {
      title: "Trigger Date",
      dataIndex: "trigger_date",
      key: "trigger_date",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
  ];

  // function for handle on tab change
  const onChangeTabs = (key) => {
    setTabKey(key);
  };

  // function for handle custom repeat modal
  const onChangeActionRepeat = (actionRepeat) => {
    if (actionRepeat === "Custom") {
      setCreateModel(true);
    }
  };

  return (
    <Layout className="header-layout">
      {/* View root action page header */}
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <Form form={form}>
          <div
            id="action-management-id"
            className="contract-pipeline"
            style={{ padding: "0 25px" }}
          >
            {/* Breadcrumb for indicate viewed action level */}
            <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
              <Col
                className="p-0 vertical-align"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <div className="action-breadcrumb mt-10">
                  <Row align="middle" type="flex" gutter={[8, 16]}>
                    <Col
                      className="vertical-align"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={2}
                    >
                      <Row align="middle" type="flex" gutter={[8, 16]}>
                        <Col span={12}>
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => history(`/action-management`)}
                          />
                        </Col>
                        <Col span={12}>
                          <Image
                            src={arrow_right_disable}
                            style={{ width: 20 }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="vertical-align"
                      xs={17}
                      sm={17}
                      md={19}
                      lg={19}
                      xl={22}
                    >
                      <span>
                        <span className="breadcrumb-path">
                          {state.finalPath &&
                            state.finalPath.map((item, i) => {
                              return (
                                <>
                                  <span
                                    key={item.id}
                                    className="create-root-action-path-text"
                                  >
                                    {item.name === "home" ? (
                                      <Image
                                        className="cursor-pointer"
                                        src={account_tree}
                                        onClick={() =>
                                          history(`/action-management`)
                                        }
                                        preview={false}
                                      />
                                    ) : (
                                      item.name
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      display:
                                        i === state.finalPath.length - 1
                                          ? "none"
                                          : "inline-block",
                                    }}
                                    className="path-icon"
                                  >
                                    <Image src={arrow_back} preview={false} />{" "}
                                    &nbsp; &nbsp;
                                  </span>
                                </>
                              );
                            })}
                          <Image src={arrow_back} preview={false} /> &nbsp;
                          &nbsp;
                          <span className="create-root-action-path-text">
                            {state &&
                              state.actionData &&
                              state.actionData.action_name}
                          </span>
                        </span>
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <>
              {/* Tab for view root action and action instance */}
              <Tabs
                className="action-instance-tab mt-10"
                defaultActiveKey="1"
                items={tabItems}
                onChange={onChangeTabs}
              />
            </>
            {/* Root action tab content */}
            {tabKey === "1" && (
              <div className="action-div-head-view mb-10">
                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "black", fontSize: "17px" }}>
                    <h4 className="mt-10">Root Action</h4>
                  </span>
                  <div
                    className="action-body-head"
                    style={{ textAlign: "left", color: "black" }}
                  >
                    <div className="action-new-div">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          style={{ padding: "13px 30px 0px" }}
                        >
                          <Col span={19} className="vertical-align">
                            <h4 className="entity-text">Basic</h4>
                          </Col>
                        </Row>
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />

                        <Row
                          align="middle"
                          type="flex"
                          gutter={[4, 16]}
                          className="m-0"
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Col span={24}>
                              <div className="create-supplier-div">
                                <div className="m-10">
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_name"
                                        label="ACTION NAME"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.action_name &&
                                          state.actionData.action_name
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_description"
                                        label="DESCRIPTION"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.action_description &&
                                          state.actionData.action_description
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_category"
                                        label="CATEGORY"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.action_category &&
                                          state.actionData.action_category
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_type"
                                        label="TYPE"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.action_type &&
                                          state.actionData.action_type
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_priority"
                                        label="PRIORITY"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.action_priority &&
                                          state.actionData.action_priority
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="start_date"
                                        label="START DATE"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.start_date &&
                                          dayjs
                                            .utc(state.actionData.start_date)
                                            .format("DD MMM YYYY")
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="end_date"
                                        label="END DATE"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.end_date &&
                                          dayjs
                                            .utc(state.actionData.end_date)
                                            .format("DD MMM YYYY")
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    className="action-body-head"
                    style={{ textAlign: "left", color: "black" }}
                  >
                    <div className="action-new-div">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          style={{ padding: "13px 30px 0px" }}
                        >
                          <Col span={19} className="vertical-align">
                            <h4 className="entity-text">Trigger & Repeat</h4>
                          </Col>
                        </Row>
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[4, 16]}
                          className="m-0"
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Col span={24}>
                              <div className="create-supplier-div">
                                <div className="m-10">
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="trigger_date"
                                        label="TRIGGER DATE"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.trigger_date &&
                                          dayjs
                                            .utc(state.actionData.trigger_date)
                                            .format("DD MMM YYYY")
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_repeat"
                                        label="ACTION REPEAT"
                                        colon={false}
                                      >
                                        {state &&
                                          state.actionData &&
                                          state.actionData.action_repeat && (
                                            <Button
                                              className="wf-create-btn"
                                              onClick={() =>
                                                onChangeActionRepeat(
                                                  state.actionData.action_repeat
                                                )
                                              }
                                            >
                                              {state.actionData.action_repeat}
                                            </Button>
                                          )}
                                      </Form.Item>

                                      {/* Custom trigger details modal */}
                                      {createModel && (
                                        <Modal
                                          className="create-wf-modal"
                                          footer={null}
                                          open={createModel}
                                          onCancel={() => {
                                            setCreateModel(false);
                                          }}
                                          width={450}
                                        >
                                          {" "}
                                          <div>
                                            <Col span={24}>
                                              <div>
                                                <Space align="">
                                                  <Image
                                                    src={repeat}
                                                    preview={false}
                                                  />
                                                  <div>
                                                    <h3>Repeat</h3>
                                                    <span
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      For Repeat the Action
                                                      Instance
                                                    </span>
                                                  </div>
                                                </Space>

                                                <div className="mt-15">
                                                  <Row>
                                                    <Col span={16} offset={4}>
                                                      <>
                                                        <Image
                                                          src={repeat_small}
                                                          preview={false}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <Space>
                                                          Repeat &nbsp;every
                                                          &nbsp;
                                                        </Space>
                                                        <b>
                                                          {state &&
                                                            state.actionData &&
                                                            state.actionData
                                                              .custom_repeat &&
                                                            state.actionData
                                                              .custom_repeat
                                                              .time_count &&
                                                            state.actionData
                                                              .custom_repeat
                                                              .time_count}
                                                        </b>
                                                        &nbsp; &nbsp;
                                                        <b>
                                                          {state &&
                                                            state.actionData &&
                                                            state.actionData
                                                              .custom_repeat &&
                                                            state.actionData
                                                              .custom_repeat
                                                              .unit_of_time &&
                                                            state.actionData
                                                              .custom_repeat
                                                              .unit_of_time}
                                                        </b>
                                                      </>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col span={16} offset={4}>
                                                      <div
                                                        className="mt-10"
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        {days &&
                                                          days.map(
                                                            (day, index) => {
                                                              return (
                                                                <Button
                                                                  type="primary"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    height: 30,
                                                                    width: 30,
                                                                    padding: 0,
                                                                    backgroundColor:
                                                                      selectedDaysIndex.includes(
                                                                        index
                                                                      )
                                                                        ? "var(--color-solid-darkblue)"
                                                                        : "grey",
                                                                    marginRight: 5,
                                                                    boxShadow:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  {day}
                                                                </Button>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  <Row className="mt-10">
                                                    <Col
                                                      span={16}
                                                      offset={4}
                                                      className="text-center"
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      Occurs Every{" "}
                                                      {state &&
                                                        state.actionData &&
                                                        state.actionData
                                                          .custom_repeat &&
                                                        state.actionData
                                                          .custom_repeat.days &&
                                                        state.actionData.custom_repeat.days.map(
                                                          (day, index) => {
                                                            return (
                                                              <>
                                                                <span>
                                                                  {day}
                                                                </span>
                                                                <span>
                                                                  {state
                                                                    .actionData
                                                                    .custom_repeat
                                                                    .days
                                                                    .length -
                                                                    1 !==
                                                                  index
                                                                    ? ", "
                                                                    : ""}
                                                                </span>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                    </Col>
                                                  </Row>
                                                  <Row
                                                    gutter={[16, 8]}
                                                    className="mt-10"
                                                  >
                                                    <Col
                                                      span={24}
                                                      className="text-center"
                                                    >
                                                      <Space align="center">
                                                        <Button
                                                          className="wf-create-btn"
                                                          onClick={() =>
                                                            setCreateModel(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          Close
                                                        </Button>
                                                      </Space>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>
                                            </Col>
                                          </div>
                                        </Modal>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[4, 16]}
                          className="m-0"
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Col span={24}>
                              <div className="create-supplier-div">
                                <div className="m-10">
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="due_in"
                                        label="DUE IN"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.due_in &&
                                          state.actionData.due_in
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="due_date"
                                        label="DUE DATE"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.due_date &&
                                          dayjs
                                            .utc(state.actionData.due_date)
                                            .format("DD MMM YYYY")
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div
                    className="action-body-head "
                    style={{ textAlign: "left", color: "black" }}
                  >
                    <div className="action-new-div mb-10">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          style={{ padding: "13px 30px 0px" }}
                        >
                          <Col span={19} className="vertical-align">
                            <h4 className="entity-text">Assignment</h4>
                          </Col>
                        </Row>
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[4, 16]}
                          className="m-0"
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Col span={24}>
                              <div className="create-supplier-div">
                                <div className="m-10">
                                  <Row align="middle" type="flex">
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_owner"
                                        label="ACTION OWNER"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData.action_owner_name &&
                                          state.actionData.action_owner_name
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                      <Form.Item
                                        {...formItemLayout}
                                        className="org-supplier-label"
                                        name="action_approver"
                                        label="ACTION APPROVER"
                                        colon={false}
                                        initialValue={
                                          state &&
                                          state.actionData &&
                                          state.actionData
                                            .action_approver_name &&
                                          state.actionData.action_approver_name
                                        }
                                      >
                                        <Input readOnly />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Action instance tab content */}
            {tabKey === "2" && (
              <div className="action-div-head-view-instance">
                <Row className="mt-10">
                  <DataTable
                    type="root-action"
                    dataTableData={dataSource}
                    colData={columns}
                    loading={tableLoader}
                  />
                </Row>
              </div>
            )}

            {/* Entity Log table */}
            {tabKey === "3" && (
              <div className="action-div-head-view-instance">
                <Row className="mt-10">
                  <DataTable type="entityLog" logData={state.actionData} />
                </Row>
              </div>
            )}
          </div>
        </Form>
      ) : (
        <NotAuthorized redirect={`/action-management`} />
      )}
    </Layout>
  );
};

// export view root action component
export default ViewRootAction;
