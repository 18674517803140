import { axiosInstance } from "../utils/common";

// get contract metadata by ID
export const getContractWorkflowApi = (contractId) => {
  return axiosInstance
    .get(`v1/contractWorkflow/${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateWorkflowApi = (contractId, updateBody) => {
  return axiosInstance
    .patch(
      `v1/contractWorkflow/contractWorkflowUpdate/${contractId}`,
      updateBody
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
