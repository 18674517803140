import React from "react";
import { useNavigate } from "react-router-dom"; //router
import { useRecoilState } from "recoil";
import {
  NotificationListAtom,
  NotificationUnreadAtom,
  HeaderChangerAtom,
} from "../store/store";
import { notification, Divider, Row, Col } from "antd"; //antd components
import { NotificationTwoTone, CheckOutlined } from "@ant-design/icons"; //antd icons
import { markNotificationAsReadApi } from "../api/notificationsApi";

const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
  const navigate = useNavigate();
  const [, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [, setNotifications] = useRecoilState(NotificationListAtom);
  const [, setUnreadCount] = useRecoilState(NotificationUnreadAtom);

  const openNotification = (receivedNotification) => {
    const handleNotificationClick = async (receivedNotification, action) => {
      await markNotificationAsReadApi(receivedNotification.id);

      setNotifications((prevNotifications) => {
        const index = prevNotifications.findIndex(
          (notify) => notify.id === receivedNotification.id
        );
        if (index !== -1) {
          const updatedNotifications = [...prevNotifications];
          updatedNotifications[index] = {
            ...updatedNotifications[index],
            read: true,
          };
          return updatedNotifications;
        }
        return prevNotifications;
      });

      setUnreadCount((prevUnreadCount) => {
        if (prevUnreadCount > 0) return prevUnreadCount - 1;
        else return prevUnreadCount;
      });
      notification.destroy(receivedNotification.id);

      if (action === "navigate") {
        if (receivedNotification.path) {
          if (receivedNotification.path.data) {
            setHeaderChanger(receivedNotification.path.header);
            navigate(receivedNotification.path.url, {
              state: receivedNotification.path.data,
            });
          } else if (receivedNotification.path.url) {
            setHeaderChanger(receivedNotification.path.header);
            navigate(receivedNotification.path.url);
          }
        }
      }
    };

    notification.open({
      key: receivedNotification.id,
      message: (
        <Row>
          <Col span={19}>
            <span
              className="push-notification-title"
              title={`${receivedNotification.name} - ${receivedNotification.tab}`}
            >
              {receivedNotification.name}&nbsp; &#8226; &nbsp;
              {receivedNotification.tab}
            </span>
          </Col>
          <Col span={5}>
            <CheckOutlined
              className="push-notification-action-icon"
              onClick={(event) => {
                event.stopPropagation();
                handleNotificationClick(receivedNotification, "read");
              }}
            />
          </Col>
        </Row>
      ),
      description: (
        <>
          <Divider className="push-notification-divider" />
          <span style={{ fontSize: 13 }}>{receivedNotification.content}</span>
        </>
      ),
      duration: 5,
      icon: (
        <NotificationTwoTone style={{ width: 20, height: 20, marginTop: 2 }} />
      ),
      className: "push-notification",
      onClick: () => handleNotificationClick(receivedNotification, "navigate"),
    });
  };

  return (
    <NotificationContext.Provider value={{ openNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  return React.useContext(NotificationContext);
};

export { NotificationProvider, useNotification };
