import { axiosInstance } from "../utils/common";

export const getAllActionInstanceApi = (
  page,
  limit,
  searchVal,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/actionInstance/get/pagination/?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getOpenActionsCountApi = (userId) => {
  return axiosInstance
    .get(`v1/actionInstance/get/action-instance/open/count/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getActionInstanceByUserApi = (
  userId,
  page,
  limit,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(
      `v1/actionInstance/get/action-instance/${userId}?limit=${limit}&page=${page}`,
      { allFilterVal: filterObj, sortColumn }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getActionInstanceByUserdetails = (userId) => {
  return axiosInstance
    .get(`v1/actionInstance/get/action-instance/details/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const getInstanceByIdApi = (instanceId) => {
  return axiosInstance
    .get(`v1/actionInstance/${instanceId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const updateActionInstanceApi = (
  instanceId,
  iterationNo,
  updateBody
) => {
  return axiosInstance
    .patch(
      `v1/actionInstance/update/${instanceId}/${iterationNo}`,
      updateBody,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const downloadFileApi = (updateBody) => {
  return axiosInstance
    .post(`v1/actionInstance/download/file`, updateBody, {
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const searchActionInstanceApi = (searchBody, page, limit, filterObj) => {
  return axiosInstance
    .post(`v1/actionInstance/search/instance/?limit=${limit}&page=${page}`, {
      searchBody: searchBody,
      allFilterVal: filterObj,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllMySpaceFiltersApi = (userId) => {
  return axiosInstance
    .get(`v1/actionInstance/filters/my-space/all/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSearchInstanceFiltersApi = (searchBody) => {
  return axiosInstance
    .post(`v1/actionInstance/filters/search/`, searchBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllInstanceFiltersApi = () => {
  return axiosInstance
    .get(`v1/actionInstance/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getOverdueActionsCountApi = (userId) => {
  return axiosInstance
    .get(`v1/actionInstance/overdue/count/${userId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
