import React, { useState, useEffect } from "react";
import axios from "axios";
import { docuSignData } from "../utils/common";
import SignedDocumentViewer from "./SignedDocumentViewer";

const DocuSignStatus = ({ accessToken, envelopeId }) => {
  const [signers, setSigners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sendSignedStatus, setSendSignedStatus] = useState(false);
  const [docuSignAccessToken] = useState(accessToken);
  const [docuSignEnvelopeId] = useState(envelopeId);

  useEffect(() => {
    // DocuSign API expects such calls to originate from a server, not from a browser
    const fetchRecipientStatus = async () => {
      try {
        const response = await axios.get(
          "http://localhost:4000/api/v1/docusign/recipients",
          {
            params: { accessToken, envelopeId },
          }
        );
        console.log("signers", response);
        setSigners(response.data.signers);
        const completedRecipients = response.data.signers.filter(
          (recipient) => recipient.status === "completed"
        );
        // const lastCompletedRecipient =
        //   completedRecipients[completedRecipients.length - 1];
        // console.log("lastCompletedRecipient", lastCompletedRecipient);
        // if (lastCompletedRecipient !== undefined) {
        //   setSendSignedStatus(true);
        // }
        if (completedRecipients.length === response.data.signers.length) {
          setSendSignedStatus(true);
        }
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch recipient status");
        setLoading(false);
      }
    };

    if (accessToken && envelopeId) {
      fetchRecipientStatus();
      const intervalId = setInterval(fetchRecipientStatus, 9000);
      console.log("iiiii");
      return () => clearInterval(intervalId);
    }
  }, [accessToken, envelopeId]);

  if (loading) {
    return <div>Loading signer details...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Signer Status</h2>
      <ul>
        {signers.map((signer, index) => (
          <li key={index}>
            <strong>Signer {index + 1}:</strong> {signer.name} ({signer.email})
            - Status: {signer.status}
          </li>
        ))}
      </ul>
      {sendSignedStatus && (
        <SignedDocumentViewer
          sendSignedStatus={sendSignedStatus}
          accessToken={docuSignAccessToken}
          envelopeId={docuSignEnvelopeId}
        />
      )}
    </div>
  );
};

export default DocuSignStatus;
