import React, { useEffect, useState } from "react";
import { Image, Row, Col, Button, Radio, Input, Form, Select, Tag } from "antd"; //antd components
import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import AddGroupMember from "../../assets/images/icons/add_group_member_icon.svg";
import RemoveLead from "../../assets/images/icons/row_remove_icon.svg";
import ArrowLeft from "../../assets/images/icons/arrow_left.svg";
import "../../assets/style/contractpipeline.css";
import "../../assets/style/setup.css";
import { getUsersApi, searchUserApi } from "../../api/usersApi";
import { createGroupAPi } from "../../api/groupsApi";
import { createActivityLogApi } from "../../api/logApi";
import { userDataAtom, GroupNameAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import { CreateButtonShort, CancelButton } from "../GlobalButton";
import { debounce } from "lodash";

function CreateGroup({ setOpenCreate, groupTableData }) {
  // Variable to hold the login user data
  const userData = useRecoilState(userDataAtom);
  // Varaiable to access the global popup
  const popupModalInstance = PopupModal();
  // State variable to hold button loading visibility
  const [loader, setLoader] = useState(false);
  //global state varaiable get the group name using useRecoilState
  const getGroupName = useRecoilState(GroupNameAtom);
  const [form] = Form.useForm(); //custom hook for managing form
  const [groupSelection, setGroupSelection] = useState("Functional Group");
  const [allUsers, setAllUsers] = useState([]);
  const [addMemberDialog, setAddMemberDialog] = useState("main");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [leads, setLeads] = useState([]);
  const [departmentHead, setDepartmentHead] = useState({
    department_head: "",
    department_members: [],
  });
  // Variable to hold text Area input fields
  const { TextArea } = Input;

  const AddMemberIcon = () => {
    return (
      <Image src={AddGroupMember} preview={false} height={20} width={20} />
    );
  };

  const RemoveLeadIcon = () => {
    return <Image src={RemoveLead} preview={false} height={20} width={20} />;
  };

  const BackButton = () => {
    return <Image src={ArrowLeft} preview={false} height={20} width={20} />;
  };

  // Custom validation function using regex
  const validateGroupName = (rule, value, callback) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Regex to allow only letters, numbers, and whitespace
    if (!regex.test(value)) {
      callback("Group name can only contain letters, numbers, and whitespace");
    } else {
      callback(); // Validation successful
    }
  };

  /* Function to handle the create Group Data form data stored
   * @param values - a user create form values
   */
  const onFinish = (values) => {
    values["group_status"] = "Active";
    values["created_by"] = userData[0].id;
    values["created_on"] = new Date();
    values["updated_by"] = userData[0].id;
    values["updated_on"] = new Date();
    //handle group name to be unique
    // if (
    //   getGroupName
    //     ? getGroupName[0].includes(values.group_name)
    //     : window.alert("Something went wrong, Try again later")
    // )
    {
      popupModalInstance.errorModal(ModalMessage.Group_Create_Error);
    }
    if (values.group_selection === "Functional Group") {
      let str = values.group_members.replace(" ", "");
      let emailArray = str.split(","); //convert to array
      let emailArrayReplace = emailArray.map((elem) => elem.replace(" ", ""));
      let uniqueArray = emailArrayReplace.filter(function (item, pos) {
        return emailArrayReplace.indexOf(item) == pos;
      });

      setLoader(true);
      values["group_members"] = uniqueArray;
      values["group_selection"] = values.group_selection;
      console.log("values", values);
      popupModalInstance
        .infoModal(ModalMessage.Group_Create)
        .then(async (userchoice) => {
          if (userchoice === "ok") {
            await createGroupAPi(values).then((res) => {
              console.log("res", res);
              if (res.group_members.length === 0) {
                setLoader(false);
                popupModalInstance.errorModal(ModalMessage.Group_Create_Error);
              } else {
                setOpenCreate(false);
                setLoader(false);
                let umLog = {
                  ss_id: res.id ? res.id : res._id,
                  ss_name: res.group_name,
                  code: "groups",
                  author: userData[0].id,
                  action: "view",
                  notes: "Created Group",
                  updated_on: new Date(),
                };
                createActivityLogApi(umLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                groupTableData(1, 20, {});
              }
            });
          }
          if (userchoice === "cancel") {
            setLoader(false);
          }
        });
    } else if (values.group_selection === "Departmental Group") {
      Object.keys(values).forEach((key) => {
        if (key.startsWith("lead_")) {
          delete values[key];
        }
      });
      values.department_members = departmentHead.department_members;
      values.leads = leads;
      values.group_type = "Public";
      console.log("values", values);
      setLoader(true);
      popupModalInstance
        .infoModal(ModalMessage.Group_Create)
        .then(async (userchoice) => {
          if (userchoice === "ok") {
            await createGroupAPi(values).then((res) => {
              console.log("res", res);
              setOpenCreate(false);
              setLoader(false);
              let umLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: res.group_name,
                code: "groups",
                author: userData[0].id,
                action: "view",
                notes: "Created Group",
                updated_on: new Date(),
              };
              createActivityLogApi(umLog).then((res) => {
                console.log("LogRes: ", res);
              });
              groupTableData(1, 20, {});
            });
          }
          if (userchoice === "cancel") {
            setLoader(false);
          }
        });
    }
  };

  /* Function to handle the create Group get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onGroupSelection = (e) => {
    console.log("radio checked", e.target.value);
    setGroupSelection(e.target.value);
    if (e.target.value === "Departmental Group" && allUsers.length === 0) {
      // getUsersApi().then((userRes) => {
      //   setAllUsers(userRes);
      // });
    }
  };

  // useEffect(() => {
  //   console.log("allUsers: ", allUsers);
  //   console.log("selectedMembers: ", selectedMembers);
  // }, [selectedMembers, allUsers]);

  useEffect(() => {
    console.log("Leads: ", leads);
    // console.log("form: ", form.getFieldsValue());
  }, [leads]);

  // Debounce the searchUsers function
  const debouncedSearchUsers = debounce(async (query) => {
    if (query.length !== 0) {
      setSearchQuery(query);
      const users = await searchUserApi(query);
      setAllUsers(users);
    } else {
      setAllUsers([]);
    }
  }, 500); // 500ms debounce delay

  // Function to handle closing of a tag
  const handleTagClose = (removedTag, type) => {
    const newSelectedUsers = selectedMembers.filter(
      (tag) => tag !== removedTag
    );
    form.setFieldsValue(
      type === "lead"
        ? { lead_members: newSelectedUsers }
        : { head_members: newSelectedUsers }
    );
    setSelectedMembers(newSelectedUsers);
  };

  const handleChange = (value, type) => {
    console.log(`selected ${value}`);
    const validEmails = value.filter((email) => isValidEmail(email));
    console.log("validEmails: ", validEmails);
    setSelectedMembers(validEmails);
    setSearchQuery(validEmails);
    form.setFieldsValue(
      type === "lead"
        ? { lead_members: validEmails }
        : { head_members: validEmails }
    );
  };

  // Function to validate email addresses using regular expression
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const checkForDuplicateLead = () => {
    const leadArray = [];
    const leadSet = new Set();
    leads.forEach((lead) => {
      if (!leadSet.has(lead.lead)) {
        leadArray.push(lead.lead);
        leadSet.add(lead.lead);
      }
    });
    return leadArray;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Form
        form={form}
        className="mt-10 create-role"
        onFinish={onFinish}
        onKeyDown={handleKeyDown}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {addMemberDialog === "main" && (
          <>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Form.Item
                  name="group_name"
                  label="Group Name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Group Name!",
                      pattern: /^(?!\s*$).+/,
                    },
                    {
                      validator: validateGroupName, // Custom validation function using regex
                    },
                  ]}
                >
                  <Input placeholder="" className="" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Form.Item
                  name="group_selection"
                  label="Group Selection"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Group Selection!",
                    },
                  ]}
                  initialValue={groupSelection}
                >
                  <Radio.Group
                    onChange={onGroupSelection}
                    value={groupSelection}
                  >
                    <Radio value="Functional Group">Functional Group</Radio>
                    <Radio value="Departmental Group">Departmental Group</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {groupSelection === "Functional Group" && (
              <>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name="group_type"
                      label="Group Type"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select Group Type!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder=""
                        options={[
                          {
                            value: "Private",
                            label: "private",
                          },
                          {
                            value: "Public",
                            label: "public",
                          },
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name="group_members"
                      label="Add Users"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please add users to group!",
                        },
                        {
                          pattern: new RegExp(
                            /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/
                          ),
                          message: "Enter valid email",
                        },
                      ]}
                      validateTrigger={["onBlur"]}
                    >
                      <TextArea
                        rows={3}
                        placeholder="Invite users by email id"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {groupSelection === "Departmental Group" && (
              <>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Row align="middle" gutter={[2, 12]}>
                      <Col span={14}>
                        <Form.Item
                          name="department_head"
                          label="Department Head"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select Department Head!",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            placeholder="Select Department Head"
                            onChange={(value) => {
                              const newDepartmentHead = { ...departmentHead };
                              newDepartmentHead.department_head = value;
                              setDepartmentHead(newDepartmentHead);
                            }}
                            notFoundContent={
                              <p
                                style={{
                                  color: "var(--color-solid-darkgrey)",
                                  padding: 5,
                                }}
                              >
                                Type Username or Email-ID
                              </p>
                            }
                            showSearch
                            optionLabelProp="value"
                            onSearch={(value) => debouncedSearchUsers(value)}
                            options={
                              allUsers &&
                              allUsers.length !== 0 &&
                              allUsers.map((user) => {
                                return {
                                  label: (
                                    <span>
                                      {`${
                                        user.first_name + " " + user.last_name
                                      }`}
                                      &nbsp; &#8226; &nbsp;{`${user.email}`}
                                    </span>
                                  ),
                                  value: user.email,
                                  disabled: checkForDuplicateLead().includes(
                                    user.email
                                  )
                                    ? true
                                    : false,
                                };
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5} className="mt-15">
                        <Button
                          className="add-member-btn"
                          style={{ marginLeft: 10 }}
                          disabled={
                            departmentHead.department_head ? false : true
                          }
                          icon={<AddMemberIcon />}
                          onClick={() => {
                            // setAddMemberDialog("lead");
                            setAllUsers([]);
                            let new_lead = { lead: null, lead_members: [] };
                            setLeads([...leads, new_lead]);
                          }}
                        >
                          Add Lead
                        </Button>
                      </Col>
                      <Col span={4} className="mt-15">
                        <Button
                          className="add-member-btn"
                          disabled={
                            departmentHead.department_head ? false : true
                          }
                          icon={
                            departmentHead.department_members.length !== 0 ? (
                              <EyeOutlined
                                style={{
                                  color: "var(--color-solid-darkerblue)",
                                  fontSize: 20,
                                }}
                              />
                            ) : (
                              <AddMemberIcon />
                            )
                          }
                          onClick={() => {
                            setAddMemberDialog("head");
                            setSelectedMembers([
                              ...departmentHead.department_members,
                            ]);
                            form.setFieldsValue({
                              head_members: departmentHead.department_members,
                            });
                            setAllUsers([]);
                          }}
                        >
                          {departmentHead.department_members.length !== 0
                            ? "Members"
                            : "Add Members"}
                        </Button>
                      </Col>
                    </Row>
                    {leads &&
                      leads.map((lead, index) => {
                        const leadKey = `lead_${index}`;
                        return (
                          <Row id={index} align="middle" gutter={[2, 12]}>
                            <Col span={14}>
                              <Form.Item
                                name={leadKey}
                                label={`Lead ${index + 1}`}
                                labelCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Lead!",
                                  },
                                ]}
                              >
                                <Select
                                  notFoundContent={
                                    <p
                                      style={{
                                        color: "var(--color-solid-darkgrey)",
                                        padding: 5,
                                      }}
                                    >
                                      Type Username or Email-ID
                                    </p>
                                  }
                                  allowClear
                                  placeholder="Select Lead"
                                  optionLabelProp="value"
                                  onChange={(value) => {
                                    form.setFieldsValue({ [leadKey]: value });
                                    const updatedLeads = [...leads];
                                    updatedLeads[index].lead = value;
                                    setLeads(updatedLeads);
                                  }}
                                  onClear={() => setAllUsers([])}
                                  // value={lead.lead}
                                  showSearch
                                  onSearch={(value) =>
                                    debouncedSearchUsers(value)
                                  }
                                  options={
                                    allUsers &&
                                    allUsers.length !== 0 &&
                                    allUsers.map((user) => {
                                      return {
                                        label: (
                                          <span>
                                            {`${
                                              user.first_name +
                                              " " +
                                              user.last_name
                                            }`}
                                            &nbsp; &#8226; &nbsp;
                                            {`${user.email}`}
                                          </span>
                                        ),
                                        value: user.email,
                                        disabled:
                                          checkForDuplicateLead().includes(
                                            user.email
                                          ) ||
                                          (departmentHead &&
                                            departmentHead.department_head ===
                                              user.email)
                                            ? true
                                            : false,
                                      };
                                    })
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5} className="mt-15">
                              <Button
                                className="add-member-btn"
                                style={{ marginLeft: 10 }}
                                icon={<RemoveLeadIcon />}
                                onClick={() => {
                                  // setAddMemberDialog("lead");
                                  setAllUsers([]);
                                  setLeads((prevLeads) => {
                                    const updatedLeads = prevLeads.filter(
                                      (_, i) => i !== index
                                    );

                                    if (updatedLeads.length === 0) {
                                      form.setFieldsValue({
                                        lead_0: null,
                                      });
                                    } else {
                                      updatedLeads.forEach((lead, i) => {
                                        const leadKey = `lead_${i}`;
                                        const leadKeyNext = `lead_${i + 1}`;
                                        form.setFieldsValue({
                                          [leadKey]: lead.lead,
                                          [leadKeyNext]: null,
                                        });
                                      });
                                    }

                                    return updatedLeads;
                                  });
                                }}
                              >
                                Lead
                              </Button>
                            </Col>
                            <Col span={4} className="mt-15">
                              <Button
                                className="add-member-btn"
                                disabled={leads[index].lead ? false : true}
                                icon={
                                  leads[index].lead_members.length !== 0 ? (
                                    <EyeOutlined
                                      style={{
                                        color: "var(--color-solid-darkerblue)",
                                        fontSize: 20,
                                      }}
                                    />
                                  ) : (
                                    <AddMemberIcon />
                                  )
                                }
                                onClick={() => {
                                  setAddMemberDialog(`lead_${index}`);
                                  if (leads[index].lead_members.length !== 0) {
                                    setSelectedMembers([
                                      ...leads[index].lead_members,
                                    ]);
                                  }
                                  form.setFieldsValue({
                                    lead_members: leads[index].lead_members,
                                  });
                                  setAllUsers([]);
                                }}
                              >
                                {leads[index].lead_members.length !== 0
                                  ? "Members"
                                  : "Add Members"}
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              </>
            )}
            <Row type="flex" align="middle" className="mt-10" justify="center">
              <Col span={6} style={{ marginRight: 5 }}>
                <CancelButton
                  onClick={() => {
                    setOpenCreate(false);
                    setLeads([]);
                  }}
                />
              </Col>
              <Col span={6} style={{ marginLeft: 5 }}>
                <CreateButtonShort loading={loader} htmlType="submit" />
              </Col>
            </Row>
          </>
        )}
        {addMemberDialog === "head" && (
          <>
            <Row align="middle" className="mb-10">
              <Button
                icon={<BackButton />}
                className="add-member-btn"
                onClick={() => {
                  const newDepartmentMembers = [...selectedMembers];
                  setDepartmentHead({
                    ...departmentHead,
                    department_members: [...newDepartmentMembers],
                  });
                  setSelectedMembers([]);
                  setSearchQuery("");
                  setAddMemberDialog("main");
                  form.setFieldsValue({ head_members: undefined });
                }}
              />
              <span style={{ marginLeft: 10 }}>Department Head Members</span>
            </Row>
            <Form.Item name="head_members" labelCol={{ span: 24 }}>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                notFoundContent={
                  <p
                    style={{
                      color: "var(--color-solid-darkgrey)",
                      padding: 5,
                    }}
                  >
                    Type Username or Email-ID
                  </p>
                }
                placeholder="Search for Users"
                value={searchQuery}
                onChange={(value) => handleChange(value, "head")}
                tokenSeparators={[","]}
                autoClearSearchValue
                maxTagCount={undefined}
                optionLabelProp="value"
                onSearch={(value) => debouncedSearchUsers(value)}
                options={
                  allUsers && allUsers.length !== 0
                    ? allUsers.map((user) => {
                        return {
                          label: (
                            <span>
                              {`${user.first_name + " " + user.last_name}`}
                              &nbsp; &#8226; &nbsp;{`${user.email}`}
                            </span>
                          ),
                          value: user.email,
                        };
                      })
                    : []
                }
                virtual={false}
                onPaste={(e) => {
                  const pastedText = e.clipboardData.getData("text");
                  const pastedUsers = pastedText
                    .split(",")
                    .map((email) => email.trim());

                  const uniqueEmails = new Set([
                    ...selectedMembers,
                    ...pastedUsers,
                  ]);

                  const uniqueEmailsArray = Array.from(uniqueEmails);

                  setSelectedMembers(uniqueEmailsArray);

                  form.setFieldsValue({
                    head_members: uniqueEmailsArray,
                  });

                  e.preventDefault();
                }}
              />
            </Form.Item>
            <div className="approver-list p-10">
              <Row gutter={[8, 16]} type="flex">
                <Col span={24}>
                  {selectedMembers &&
                    selectedMembers.map((user) => {
                      return (
                        <Tag
                          // contentEditable
                          closable
                          key={user}
                          className="user-approver-tag"
                          icon={<UserOutlined />}
                          onClose={() => handleTagClose(user, "head")}
                        >
                          {user}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>
            </div>
          </>
        )}
        {addMemberDialog.startsWith("lead") && (
          <>
            <Row align="middle" className="mb-10">
              <Button
                icon={<BackButton />}
                className="add-member-btn"
                onClick={() => {
                  const leadIndex = addMemberDialog.split("_")[1];
                  const newLeads = [...leads];
                  newLeads[leadIndex].lead_members = [...selectedMembers];
                  setLeads([...newLeads]);
                  setSelectedMembers([]);
                  setSearchQuery("");
                  setAddMemberDialog("main");
                  form.setFieldsValue({ lead_members: undefined });
                }}
              />
              <span style={{ marginLeft: 10 }}>Department Lead Members</span>
            </Row>
            <Form.Item name="lead_members" labelCol={{ span: 24 }}>
              <Select
                mode="tags"
                style={{
                  width: "100%",
                }}
                notFoundContent={
                  <p
                    style={{
                      color: "var(--color-solid-darkgrey)",
                      padding: 5,
                    }}
                  >
                    Type Username or Email-ID
                  </p>
                }
                placeholder="Search for Users"
                value={searchQuery}
                onChange={(value) => handleChange(value, "lead")}
                tokenSeparators={[","]}
                autoClearSearchValue
                optionLabelProp="value"
                maxTagCount={undefined} // Display all options
                onSearch={(value) => debouncedSearchUsers(value)} // Trigger debounced search
                options={
                  allUsers && allUsers.length !== 0
                    ? allUsers.map((user) => {
                        return {
                          label: (
                            <span>
                              {`${user.first_name + " " + user.last_name}`}
                              &nbsp; &#8226; &nbsp;{`${user.email}`}
                            </span>
                          ),
                          value: user.email,
                        };
                      })
                    : []
                }
                virtual={false}
                onPaste={(e) => {
                  const pastedText = e.clipboardData.getData("text");
                  const pastedUsers = pastedText
                    .split(",")
                    .map((email) => email.trim());

                  const uniqueEmails = new Set([
                    ...selectedMembers,
                    ...pastedUsers,
                  ]);

                  const uniqueEmailsArray = Array.from(uniqueEmails);

                  setSelectedMembers(uniqueEmailsArray);

                  form.setFieldsValue({
                    lead_members: uniqueEmailsArray,
                  });

                  e.preventDefault();
                }}
              />
            </Form.Item>
            <div className="approver-list p-10">
              <Row gutter={[8, 16]} type="flex">
                <Col span={24}>
                  {selectedMembers &&
                    selectedMembers.map((user) => {
                      return (
                        <Tag
                          // contentEditable
                          closable
                          key={user}
                          className="user-approver-tag"
                          icon={<UserOutlined />}
                          onClose={() => handleTagClose(user, "lead")}
                        >
                          {user}
                        </Tag>
                      );
                    })}
                </Col>
              </Row>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

export default CreateGroup;
