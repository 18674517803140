import { axiosInstance } from "../utils/common";

export const getContractTemplateApi = () => {
  return axiosInstance
    .get("v1/contractTemplates")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getContractTemplateByIdApi = (temId) => {
  return axiosInstance
    .get(`v1/contractTemplates/${temId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
