import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  List,
  Checkbox,
  Spin,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../../assets/style/setup.css";
import "../../assets/style/usermanagement.css";
import "../../assets/style/settings.css";
import {
  getSupplierForRole,
  getPrimaryContractForRole,
  getSecondaryContractForRole,
} from "../../api/rolesApi";
import { getNodeIcon } from "../../utils/common";

const { Search } = Input;

const RoleAccessController = ({
  mode,
  selectedSuppliers,
  setSelectedSuppliers,
  selectedPrimaryContracts,
  setSelectedPrimaryContracts,
  selectedSecondaryContracts,
  setSelectedSecondaryContracts,
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [hasMoreSuppliers, setHasMoreSuppliers] = useState(true);
  const [pageSuppliers, setPageSuppliers] = useState(1);
  const [searchTermSuppliers, setSearchTermSuppliers] = useState("");

  const [primaryContracts, setPrimaryContracts] = useState([]);
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [hasMorePrimary, setHasMorePrimary] = useState(true);
  const [pagePrimary, setPagePrimary] = useState(1);
  const [searchTermPrimary, setSearchTermPrimary] = useState("");

  const [secondaryContracts, setSecondaryContracts] = useState([]);

  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [hasMoreSecondary, setHasMoreSecondary] = useState(true);
  const [pageSecondary, setPageSecondary] = useState(1);
  const [searchTermSecondary, setSearchTermSecondary] = useState("");

  const fetchSuppliers = async (search = "") => {
    setLoadingSuppliers(true);
    try {
      const response = await getSupplierForRole(pageSuppliers, 20, search);
      setSuppliers((prev) => {
        const newSuppliers =
          pageSuppliers === 1
            ? response.suppliers
            : [...prev, ...response.suppliers];
        const suppliersWithAll =
          newSuppliers[0]?._id !== "All"
            ? [{ supplier_name: "All", _id: "All" }, ...newSuppliers]
            : newSuppliers;

        // If "All" is selected, include all new supplier IDs
        if (selectedSuppliers.includes("All")) {
          setSelectedSuppliers((prevSelected) => [
            ...new Set([
              ...prevSelected,
              ...suppliersWithAll.map((s) => s._id),
            ]),
          ]);
        }

        return suppliersWithAll;
      });
      setHasMoreSuppliers(response.hasMore);
    } catch (error) {
      message.error("Failed to fetch suppliers.");
    } finally {
      setLoadingSuppliers(false);
    }
  };

  const fetchPrimaryContracts = async (search = "") => {
    console.log("selectedSuppliers: ", selectedSuppliers);

    setLoadingPrimary(true);
    try {
      const response = await getPrimaryContractForRole(
        pagePrimary,
        20,
        search,
        selectedSuppliers
      );
      setPrimaryContracts((prev) => {
        const newPrimaryContracts =
          pagePrimary === 1
            ? response.contracts
            : [...prev, ...response.contracts];
        const primaryWithAll =
          newPrimaryContracts[0]?._id !== "All"
            ? [{ contract_name: "All", _id: "All" }, ...newPrimaryContracts]
            : newPrimaryContracts;

        // If "All" is selected, include all new primary contract IDs
        if (selectedPrimaryContracts.includes("All")) {
          setSelectedPrimaryContracts((prevSelected) => [
            ...new Set([
              ...prevSelected,
              ...primaryWithAll.map((contract) => contract._id),
            ]),
          ]);
        }

        return primaryWithAll;
      });
      setHasMorePrimary(response.hasMore);
    } catch (error) {
      message.error("Failed to fetch primary contracts.");
    } finally {
      setLoadingPrimary(false);
    }
  };

  const fetchSecondaryContracts = async (primaryIds, search = "") => {
    console.log("primaryIds: ", primaryIds);

    setLoadingSecondary(true);
    try {
      const response = await getSecondaryContractForRole(
        pageSecondary,
        20,
        search,
        primaryIds
      );
      setSecondaryContracts((prev) => {
        const newSecondaryContracts =
          pageSecondary === 1
            ? response.contracts
            : [...prev, ...response.contracts];
        const secondaryWithAll =
          newSecondaryContracts[0]?._id !== "All"
            ? [{ contract_name: "All", _id: "All" }, ...newSecondaryContracts]
            : newSecondaryContracts;

        // If "All" is selected, include all new secondary contract IDs
        if (selectedSecondaryContracts.includes("All")) {
          setSelectedSecondaryContracts((prevSelected) => [
            ...new Set([
              ...prevSelected,
              ...secondaryWithAll.map((contract) => contract._id),
            ]),
          ]);
        }

        return secondaryWithAll;
      });
      setHasMoreSecondary(response.hasMore);
    } catch (error) {
      message.error("Failed to fetch secondary contracts.");
    } finally {
      setLoadingSecondary(false);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [pageSuppliers]);

  useEffect(() => {
    if (selectedSuppliers.length > 0) {
      fetchPrimaryContracts();
    } else {
      setPrimaryContracts([]);
    }
  }, [selectedSuppliers, pagePrimary]);

  useEffect(() => {
    if (selectedPrimaryContracts.length > 0) {
      fetchSecondaryContracts(selectedPrimaryContracts);
    } else {
      setSecondaryContracts([]);
    }
  }, [selectedPrimaryContracts, pageSecondary]);

  const handleSearch = (value) => {
    setSearchTermSuppliers(value);
    setPageSuppliers(1);
    fetchSuppliers(value);
  };

  const handleSearchPrimary = (value) => {
    setSearchTermPrimary(value);
    setPagePrimary(1);
    fetchPrimaryContracts(value);
  };

  const handleSearchSecondary = (value) => {
    setSearchTermSecondary(value);
    setPageSecondary(1);
    fetchSecondaryContracts(selectedPrimaryContracts, value);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollHeight - scrollTop <= clientHeight &&
      hasMoreSuppliers &&
      !loadingSuppliers
    ) {
      setPageSuppliers((prev) => prev + 1);
    }
  };

  const handleScrollPrimary = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollHeight - scrollTop <= clientHeight &&
      hasMorePrimary &&
      !loadingPrimary
    ) {
      setPagePrimary((prev) => prev + 1);
    }
  };

  const handleScrollSecondary = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollHeight - scrollTop <= clientHeight &&
      hasMoreSecondary &&
      !loadingSecondary
    ) {
      setPageSecondary((prev) => prev + 1);
    }
  };

  const handleSupplierCheck = (supplierId, checked) => {
    setSelectedSuppliers((prev) => {
      // If "All" is selected, select all individual suppliers
      if (supplierId === "All") {
        return checked ? suppliers.map((supplier) => supplier._id) : [];
      }

      let updatedSelection = checked
        ? [...prev, supplierId]
        : prev.filter((id) => id !== supplierId);

      // If all suppliers are selected, add "All"
      if (updatedSelection.length === suppliers.length) {
        updatedSelection = ["All"];
      } else if (
        updatedSelection.length < suppliers.length &&
        prev.includes("All")
      ) {
        // If "All" is deselected, remove it
        updatedSelection = updatedSelection.filter((id) => id !== "All");
      }

      return updatedSelection;
    });
  };

  const handlePrimaryCheck = (primaryId, checked) => {
    setSelectedPrimaryContracts((prev) => {
      // If "All" is selected, select all individual primary contracts
      if (primaryId === "All") {
        return checked ? primaryContracts.map((contract) => contract._id) : [];
      }

      let updatedSelection = checked
        ? [...prev, primaryId]
        : prev.filter((id) => id !== primaryId);

      // If all primary contracts are selected, add "All"
      if (updatedSelection.length === primaryContracts.length) {
        updatedSelection = ["All"];
      } else if (
        updatedSelection.length < primaryContracts.length &&
        prev.includes("All")
      ) {
        // If "All" is deselected, remove it
        updatedSelection = updatedSelection.filter((id) => id !== "All");
      }

      return updatedSelection;
    });
  };

  const handleSecondaryCheck = (secondaryId, checked) => {
    setSelectedSecondaryContracts((prev) => {
      // If "All" is selected, select all individual secondary contracts
      if (secondaryId === "All") {
        return checked
          ? secondaryContracts.map((contract) => contract._id)
          : [];
      }

      let updatedSelection = checked
        ? [...prev, secondaryId]
        : prev.filter((id) => id !== secondaryId);

      // If all secondary contracts are selected, add "All"
      if (updatedSelection.length === secondaryContracts.length) {
        updatedSelection = ["All"];
      } else if (
        updatedSelection.length < secondaryContracts.length &&
        prev.includes("All")
      ) {
        // If "All" is deselected, remove it
        updatedSelection = updatedSelection.filter((id) => id !== "All");
      }

      return updatedSelection;
    });
  };

  useEffect(() => {
    console.log(
      selectedSuppliers,
      selectedPrimaryContracts,
      selectedSecondaryContracts
    );
  }, [selectedSuppliers, selectedPrimaryContracts, selectedSecondaryContracts]);

  return (
    <Row
      className="m-10"
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        padding: "0px 0px 10px 0px",
        height: "calc(100vh - 200px)",
      }}
    >
      <Col span={24}>
        <Row
          gutter={[12, 24]}
          justify="center"
          align="middle"
          className="mt-10"
        >
          {/* Suppliers Section */}
          <Col span={7}>
            <Search
              placeholder="Search Suppliers"
              onSearch={handleSearch}
              style={{ marginBottom: 16 }}
            />
            <div
              style={{
                height: 400,
                overflow: "auto",
                border: "1px solid #d9d9d9",
                borderRadius: 10,
              }}
              onScroll={handleScroll}
            >
              <List
                rootClassName="role-entity-list"
                dataSource={suppliers}
                renderItem={(item) => (
                  <List.Item>
                    <Checkbox
                      checked={selectedSuppliers.includes(item._id)}
                      onChange={(e) => {
                        if (!mode.view) {
                          handleSupplierCheck(item._id, e.target.checked);
                        }
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginTop: 3, marginLeft: 5 }}>
                          {getNodeIcon(
                            item._id === "All" ? "" : "Supplier",
                            selectedSuppliers.includes(item._id)
                              ? "active"
                              : "inactive"
                          )}
                        </span>
                        {item.supplier_name}
                      </span>
                    </Checkbox>
                  </List.Item>
                )}
              />
              {loadingSuppliers && (
                <div style={{ textAlign: "center", padding: 16 }}>
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "var(--color-solid-darkerblue)",
                    }}
                  />
                </div>
              )}
              {/* {!hasMoreSuppliers && suppliers.length > 0 && (
                <div style={{ textAlign: "center", padding: 16 }}>-</div>
              )} */}
            </div>
          </Col>

          {/* Primary Contracts Section */}
          <Col span={7}>
            <Search
              placeholder="Search Primary Contracts"
              onSearch={handleSearchPrimary}
              style={{ marginBottom: 16 }}
            />
            <div
              style={{
                height: 400,
                overflow: "auto",
                border: "1px solid #d9d9d9",
                borderRadius: 10,
              }}
              onScroll={handleScrollPrimary}
            >
              <List
                rootClassName="role-entity-list"
                dataSource={primaryContracts}
                renderItem={(item) => (
                  <List.Item>
                    <Checkbox
                      checked={selectedPrimaryContracts.includes(item._id)}
                      onChange={(e) => {
                        if (!mode.view) {
                          handlePrimaryCheck(item._id, e.target.checked);
                        }
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginTop: 3, marginLeft: 5 }}>
                          {getNodeIcon(
                            item.contract_type,
                            selectedPrimaryContracts.includes(item._id)
                              ? "active"
                              : "inactive"
                          )}
                        </span>
                        {item.contract_name}
                      </span>
                    </Checkbox>
                  </List.Item>
                )}
              />
              {loadingPrimary && (
                <div style={{ textAlign: "center", padding: 16 }}>
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "var(--color-solid-darkerblue)",
                    }}
                  />
                </div>
              )}
              {/* {!hasMorePrimary && primaryContracts.length > 0 && (
                <div style={{ textAlign: "center", padding: 16 }}>---</div>
              )} */}
            </div>
          </Col>

          {/* Secondary Contracts Section */}
          <Col span={7}>
            <Search
              placeholder="Search Secondary & Amendment Contracts"
              onSearch={handleSearchSecondary}
              style={{ marginBottom: 16 }}
            />
            <div
              style={{
                height: 400,
                overflow: "auto",
                border: "1px solid #d9d9d9",
                borderRadius: 10,
              }}
              onScroll={handleScrollSecondary}
            >
              <List
                rootClassName="role-entity-list"
                dataSource={secondaryContracts}
                renderItem={(item) => (
                  <List.Item>
                    <Checkbox
                      checked={selectedSecondaryContracts.includes(item._id)}
                      onChange={(e) => {
                        if (!mode.view) {
                          handleSecondaryCheck(item._id, e.target.checked);
                        }
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginTop: 3, marginLeft: 5 }}>
                          {getNodeIcon(
                            item.contract_type,
                            selectedSecondaryContracts.includes(item._id)
                              ? "active"
                              : "inactive"
                          )}
                        </span>
                        {item.contract_name}
                      </span>
                    </Checkbox>
                  </List.Item>
                )}
              />
              {loadingSecondary && (
                <div style={{ textAlign: "center", padding: 16 }}>
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "var(--color-solid-darkerblue)",
                    }}
                  />
                </div>
              )}
              {/* {!hasMoreSecondary && secondaryContracts.length > 0 && (
                <div style={{ textAlign: "center", padding: 16 }}>
                  No more secondary contracts
                </div>
              )} */}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RoleAccessController;
