import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Table,
  Modal,
  InputNumber,
  Checkbox,
  Space,
} from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { SaveButton, ClearAllButton, OkButton } from "../GlobalButton";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import { monthsShort } from "../../utils/data";
import dayjs from "dayjs";
import { isEmptyObject } from "../../utils/common";

const DaysModal = ({
  modalState,
  formData,
  yearsList,
  recordKey,
  commercialItemRates,
  commercialItemDates,
  onClose,
  onSaveDays,
}) => {
  const [daysForm] = Form.useForm();
  const popupModalInstance = PopupModal();
  const recordYearList = yearsList[recordKey];
  const [daysFormData, setDaysFormData] = useState(formData ? formData : {});
  const [chargingYear, setChargingYear] = useState(
    Object.keys(recordYearList || {})[0]
  );
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isClearButtonEnabled, setIsClearButtonEnabled] = useState(false);
  const [isRepeatChecked, setIsRepeatChecked] = useState(false);
  const dayInput = useRef();
  const chargingYearBackup = useRef({});

  const { startDate, endDate } = commercialItemDates;
  const { itemPrice, rateBasis, ratePeriod } = commercialItemRates;

  const [startDateYear, startDateMonth, startDateDay] = [
    startDate.year(),
    startDate.format("MMM").toLowerCase(),
    startDate.date(),
  ];
  const [endDateYear, endDateMonth, endDateDay] = [
    endDate.year(),
    endDate.format("MMM").toLowerCase(),
    endDate.date(),
  ];

  useEffect(() => {
    daysForm.resetFields();
    daysForm.setFieldsValue({
      ...(formData && formData[Object.keys(recordYearList || {})[0]]),
    });
  }, []);

  useEffect(() => {
    const enableClearAll = shouldEnableClearAllButton(chargingYear);
    setIsClearButtonEnabled(enableClearAll);
  }, [daysFormData]);

  const months = [
    { name: "January", key: "jan" },
    { name: "February", key: "feb" },
    { name: "March", key: "mar" },
    { name: "April", key: "apr" },
    { name: "May", key: "may" },
    { name: "June", key: "jun" },
    { name: "July", key: "jul" },
    { name: "August", key: "aug" },
    { name: "September", key: "sep" },
    { name: "October", key: "oct" },
    { name: "November", key: "nov" },
    { name: "December", key: "dec" },
  ];

  // Function to determine if the Clear All button should be enabled
  const shouldEnableClearAllButton = (selectedYear) => {
    const yearData = daysFormData?.[selectedYear] || {};

    return Object.keys(yearData).some(
      (monthKey) => yearData[monthKey] !== null
    );
  };

  // Function to get the maximum days in the month considering leap year
  const getMaxDaysInMonth = (year, monthKey) => {
    const monthIndex = monthsShort.indexOf(monthKey);

    // Create a dayjs object for the first day of the month in the given year
    return dayjs(new Date(year, monthIndex, 1)).daysInMonth();
  };

  const onChangeDays = (day, monthKey) => {
    // console.log("day: ", day, monthKey);
    // console.log(chargingYear, endDateYear, monthKey, endDateMonth);
    // console.log(chargingYear, startDateYear, monthKey, startDateMonth);

    // When user empties the Input field
    if (!day) {
      daysForm.setFieldValue(
        monthKey,
        modalState.parentType === "amendment" ? 0 : null
      );
      setDaysFormData((prevData) => ({
        ...prevData,
        [chargingYear]: {
          ...prevData[chargingYear],
          [monthKey]: modalState.parentType === "amendment" ? 0 : null,
        },
      }));
      return;
    }

    const year = Number(chargingYear);
    const maxDaysInMonth = getMaxDaysInMonth(year, monthKey);
    const isFirstMonth = year === startDateYear && monthKey === startDateMonth;
    const isLastMonth = year === endDateYear && monthKey === endDateMonth;

    let adjustedDay = rateBasis === "Days Based" ? Math.trunc(day) : day;

    const handleWarning = (modalMessage, allowedDays) => {
      adjustedDay = allowedDays;
      if (daysFormData[chargingYear] && daysFormData[chargingYear][monthKey]) {
        if (!isErrorModalOpen) {
          setIsErrorModalOpen(true);
          popupModalInstance
            .warningModal(ModalMessage[modalMessage])
            .then((userChoice) => {
              if (userChoice === "ok") {
                setIsErrorModalOpen(false);
              }
            });
        }
      }
    };

    const isDaysBased = rateBasis === "Days Based";
    const allowedDaysForFirstMonth = maxDaysInMonth - startDateDay + 1;
    const allowedDaysForLastMonth = endDateDay;
    const allowedDaysForStartEndMonth = endDateDay - startDateDay + 1;

    if (isDaysBased) {
      if (isFirstMonth && isLastMonth) {
        // Both start and end month logic
        if (day > allowedDaysForStartEndMonth) {
          handleWarning(
            "CC_Commercial_Start_Date",
            allowedDaysForStartEndMonth
          );
        }
      } else if (isFirstMonth) {
        // Start month logic
        if (day > allowedDaysForFirstMonth) {
          handleWarning("CC_Commercial_Start_Date", allowedDaysForFirstMonth);
        }
      } else if (isLastMonth) {
        // End month logic
        if (day > allowedDaysForLastMonth) {
          handleWarning("CC_Commercial_End_Date", allowedDaysForLastMonth);
        }
      }
    }

    if (rateBasis === "FTE Based" && day > 99) {
      handleWarning("CC_FTE_limit", 99);
    }

    daysForm.setFieldValue(monthKey, adjustedDay);
    setDaysFormData((prevData) => ({
      ...prevData,
      [chargingYear]: {
        ...prevData[chargingYear],
        [monthKey]: adjustedDay,
      },
    }));

    dayInput.current = adjustedDay;
  };

  const handleClearAll = () => {
    daysForm.resetFields();

    setDaysFormData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[chargingYear];
      return updatedData;
    });

    setIsClearButtonEnabled(false);
    setIsRepeatChecked(false);
  };

  const handleRepeat = () => {
    // if (Object.keys(chargingYearBackup.current).length !== 0) {
    //   setDaysFormData((prevData) => ({
    //     ...prevData,
    //     [chargingYear]: chargingYearBackup.current,
    //   }));

    //   daysForm.resetFields();
    //   daysForm.setFieldsValue({ ...chargingYearBackup.current });
    //   chargingYearBackup.current = {};
    //   setIsRepeatChecked(false);
    // } else {
    const existingData = daysFormData?.[chargingYear] || {};
    const monthsToFill = recordYearList[chargingYear].filter(
      (month) => !existingData[month]
    );

    if (dayInput.current) {
      const updatedData = monthsToFill.reduce((acc, month) => {
        acc[month] = dayInput.current;
        return acc;
      }, {});

      // chargingYearBackup.current = existingData;

      setDaysFormData((prevData) => ({
        ...prevData,
        [chargingYear]: {
          ...prevData[chargingYear],
          ...updatedData,
        },
      }));

      daysForm.setFieldsValue(updatedData);
      setIsRepeatChecked(true);
    }
    // }
  };

  const onDaysSubmit = () => {
    let costObj = {};

    //Calculates cost for the required months based on the commercial item's price
    for (const year in daysFormData) {
      if (!costObj[year]) {
        costObj[year] = {};
      }

      for (const month in daysFormData[year]) {
        const daysValue = daysFormData[year][month] || 0;
        const cost =
          ratePeriod !== "Yearly Rate"
            ? daysValue * itemPrice
            : Number.isInteger((daysValue / 12) * itemPrice)
            ? (daysValue / 12) * itemPrice
            : ((daysValue / 12) * itemPrice).toFixed(2);

        costObj[year][month] = Number(cost);
      }
    }

    onSaveDays(daysFormData, costObj);
    onClose();
  };

  // Check if every required month has a corresponding key in filledMonths and the value is not undefined or null
  const isDaysFormComplete = () => {
    return Object.keys(recordYearList).every((year) => {
      const requiredMonths = recordYearList[year] || [];
      const filledMonths = daysFormData[year] || {};

      return requiredMonths.every(
        (month) =>
          filledMonths.hasOwnProperty(month) && filledMonths[month] != null
      );
    });
  };

  // Check if both formData and daysFormData have the same structure and values
  const hasDaysFormDataChanged = () => {
    return Object.keys(formData).every((year) => {
      const formYearData = formData[year] || {};
      const daysYearData = daysFormData[year] || {};

      return Object.keys(formYearData).every(
        (month) => formYearData[month] === daysYearData[month]
      );
    });
  };

  const areAllMonthsFilled = () => {
    const currentYearData = daysFormData[chargingYear] || {};
    return (
      Object.keys(currentYearData).length ===
        recordYearList[chargingYear].length &&
      Object.values(currentYearData).every(
        (value) => value !== null && value !== undefined
      )
    );
  };

  const shouldDisableRepeatCheckBox = () => {
    return (
      areAllMonthsFilled() ||
      !shouldEnableClearAllButton(chargingYear) ||
      !dayInput.current
    );
  };

  return (
    <div>
      <Form
        form={daysForm}
        name="days_form"
        className="days-form"
        onFinish={onDaysSubmit}
      >
        <Row align="middle" type="flex" style={{ padding: "15px 20px 5px" }}>
          <Col span={24} className="vertical-align">
            <div
              className="div-head"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: 10 }}>Year</span>
              <Select
                id="year-select"
                onChange={(selectedYear) => {
                  setChargingYear(selectedYear);
                  daysForm.resetFields();
                  daysForm.setFieldsValue(
                    daysFormData &&
                      daysFormData[selectedYear] &&
                      daysFormData[selectedYear]
                  );

                  const enableClearAll =
                    shouldEnableClearAllButton(selectedYear);
                  setIsClearButtonEnabled(enableClearAll);
                  setIsRepeatChecked(false);
                  dayInput.current = null;
                  // chargingYearBackup.current = {};
                }}
                defaultValue={Object.keys(recordYearList || {})[0]}
                showSearch={true}
                placeholder="Year"
                options={
                  yearsList &&
                  recordYearList &&
                  recordYearList &&
                  Object.keys(recordYearList || {}).map((data) => ({
                    key: data,
                    label: data,
                    value: data,
                  }))
                }
              />
            </div>
          </Col>
        </Row>
        <hr className="mt-10" />
        <Table
          columns={[
            {
              title: "Month",
              dataIndex: "name",
              key: "name",
              width: "50%",
              render: (text, record, index) => (
                <span id={`month-${index}`}>{text}</span>
              ),
            },
            {
              title: "Days",
              dataIndex: "key",
              key: "key",
              width: "50%",
              render: (key, record, index) => {
                return (
                  <Form.Item name={key} noStyle>
                    <InputNumber
                      id={`days-${key}`}
                      readOnly={modalState === "view"}
                      disabled={
                        !(
                          recordYearList &&
                          recordYearList[chargingYear] &&
                          recordYearList[chargingYear].includes(key)
                        )
                      }
                      min={
                        modalState.parentType === "amendment"
                          ? -getMaxDaysInMonth(chargingYear, key)
                          : 0
                      }
                      type="number"
                      className="commercial-days-costs-input"
                      onChange={(value) => {
                        setIsRepeatChecked(false);
                        if (rateBasis === "Days Based") {
                          const maxDaysInMonth = getMaxDaysInMonth(
                            chargingYear,
                            key
                          );
                          if (value > maxDaysInMonth) {
                            if (!isErrorModalOpen) {
                              setIsErrorModalOpen(true);
                              popupModalInstance
                                .errorModal(
                                  ModalMessage.CC_Commercial_Max_Calender
                                )
                                .then(async (userChoice) => {
                                  if (userChoice === "ok") {
                                    daysForm.setFieldValue(key, maxDaysInMonth);
                                    setIsErrorModalOpen(false);
                                  }
                                });
                            }
                          } else {
                            onChangeDays(value, key);
                          }
                        } else {
                          onChangeDays(value, key);
                        }
                      }}
                      bordered={false}
                      placeholder={`${
                        recordYearList &&
                        recordYearList[chargingYear] &&
                        recordYearList[chargingYear].includes(key)
                          ? "Days"
                          : "Disabled"
                      } for ${months.find((month) => month.key === key).name}`}
                      controls={{
                        upIcon: <UpOutlined />,
                        downIcon: <DownOutlined />,
                      }}
                    />
                  </Form.Item>
                );
              },
            },
          ]}
          dataSource={months.map((month) => ({
            key: month.key,
            name: month.name,
          }))}
          pagination={false}
          rowKey="key"
          id="commercial-days-costs"
          className="data-table mt-10"
        />
        {modalState.mode === "edit" || modalState.mode === "create" ? (
          <Row
            align="middle"
            type="flex"
            justify="center"
            gutter={[8, 16]}
            className="mt-10"
          >
            <Col span={24} className="vertical-align">
              <Form.Item className="m-0">
                <Space>
                  <Checkbox
                    checked={isRepeatChecked}
                    onChange={handleRepeat}
                    disabled={shouldDisableRepeatCheckBox()}
                  />
                  <span style={{ color: "var(--color-solid-darkergrey)" }}>
                    Repeat for remaining months
                  </span>
                </Space>
              </Form.Item>
            </Col>
            <Col className="vertical-align" span={10}>
              <Form.Item>
                <ClearAllButton
                  type="primary"
                  disabled={!isClearButtonEnabled}
                  onClick={handleClearAll}
                />
              </Form.Item>
            </Col>
            <Col className="vertical-align" span={10}>
              <Form.Item>
                <SaveButton
                  type="primary"
                  htmlType="submit"
                  disabled={
                    Object.keys(formData).length === 0
                      ? !isDaysFormComplete()
                      : hasDaysFormDataChanged()
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row
            align="middle"
            type="flex"
            justify="center"
            gutter={[8, 16]}
            className="mt-15"
          >
            <Col span={10} className="mb-15">
              <OkButton onClick={onClose} />
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default DaysModal;
