import { useEffect, useState } from "react";
import mammoth from "mammoth";
import { message } from "antd";
import { downloadFileApi } from "../api/commonApi";

const CompareDocs = ({ oldVersion, newVersion, setText1, setText2 }) => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const fetchFileContent = async (fileNameOnS3) => {
    try {
      const response = await downloadFileApi({
        fileKey: fileNameOnS3,
        flag: "contract",
      });
      console.log("response: ", response);
      const file = new File([response], fileNameOnS3, {
        type: response.type,
      });
      return file;
    } catch (error) {
      console.error("Error fetching file content:", error);
      return null;
    }
  };

  const extractText = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  useEffect(() => {
    const initializeFiles = async () => {
      const file1Content =
        typeof oldVersion === "string"
          ? await fetchFileContent(oldVersion)
          : oldVersion;
      const file2Content =
        typeof newVersion === "string"
          ? await fetchFileContent(newVersion)
          : newVersion;

      console.log("fileContent1: ", file1Content);
      console.log("fileContent2: ", file2Content);

      setFile1(
        file1Content.originFileObj ? file1Content.originFileObj : file1Content
      );
      setFile2(
        file2Content.originFileObj ? file2Content.originFileObj : file2Content
      );
    };

    initializeFiles();
  }, [oldVersion, newVersion]);

  useEffect(() => {
    if (file1) handleFile1Upload(file1);
    if (file2) handleFile2Upload(file2);
  }, [file1, file2]);

  const handleFile1Upload = async (file) => {
    if (file) {
      try {
        const text = await extractText(file);
        setText1(text);
      } catch (error) {
        message.error(
          "Failed to extract text from the first document. Please try again."
        );
      }
    }
  };

  const handleFile2Upload = async (file) => {
    if (file) {
      try {
        const text = await extractText(file);
        setText2(text);
      } catch (error) {
        message.error(
          "Failed to extract text from the second document. Please try again."
        );
      }
    }
  };

  return null;
};

export default CompareDocs;
