import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Layout,
  Tabs,
  Input,
  Select,
  Popconfirm,
  Modal,
} from "antd"; //antd components
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/style/setup.css";
import "../../assets/style/usermanagement.css";
import "../../assets/style/settings.css";
import { createRoleAPi } from "../../api/rolesApi";
import { editRoleAPi } from "../../api/rolesApi";
import { suppliersTreeApi } from "../../api/suppliersApi";
import { createActivityLogApi } from "../../api/logApi";
import { getAllMsaApi } from "../../api/msaApi";
import TopHeader from "../../components/Header";
import { Checkbox } from "antd";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, userDataAtom } from "../../store/store";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  DeleteButton,
  AddButton,
} from "../GlobalButton";
import {
  getAllApprovedContractsWoMsa,
  getByMsa,
  getBySupplierWoMsa,
} from "../../api/contractsApi";
import CustomLoader from "../CustomLoader";
import HeaderCard from "../HeaderCard";
import RoleAccessController from "./RoleAccessController";

function CreateRoleAccess() {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom); //global state
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold the header local data
  let header = headerChanger.header;
  // State variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // Variable to use navigate function
  const navigate = useNavigate();
  // Variable to hold local form values
  let { state } = useLocation();
  // Assign the header values into state variable
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  const history = useNavigate();
  const [stateButton, setStateButton] = useState(1);
  const [form] = Form.useForm();
  const selectRef = useRef();
  const [addForm] = Form.useForm();
  const [allSupplierData, setAllSupplierData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [disableAddButton, setDisableAddButton] = useState(true);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [entityData, setEntityData] = useState({
    supplier: [],
    msa: [],
    contract: [],
  });
  // const [count, setCount] = useState(1);
  // let count = 1;
  const [addedData, setAddedData] = useState([]);
  const [selectedData, setSelectedData] = useState({
    supplier: [],
    msa: [],
    contract: [],
  });
  const [selectedAcc, setSelectedAcc] = useState({
    supplier: [],
    msa: [],
    contract: [],
  });
  const [dataAccessLoader, setDataAccessLoader] = useState(false);
  const [selectedMode, setSelectedMode] = useState({
    supplier: "",
    msa: "",
    contract: "",
  });

  // State variable to hold screen loading screen visibility status
  const [loader, setLoader] = useState(false);
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  // State variable to hold checked the screen access
  const [checked, setChecked] = useState({
    contracts: {
      contract_tree: {
        view: false,
      },
      view_contract: {
        view: false,
      },
      contract_pipeline: {
        view: false,
      },
      create_contract: {
        view: false,
        create: false,
      },
    },
    system_setup: {
      supplier_setup: {
        view: false,
        create: false,
      },
      legal_entity_setup: {
        view: false,
        create: false,
      },
      cost_center_setup: {
        view: false,
        create: false,
      },
      ratecard_setup: {
        view: false,
        create: false,
      },
      fx_setup: {
        view: false,
        create: false,
      },
      indexation: {
        view: false,
        create: false,
      },
      pricing_profile: {
        view: false,
        create: false,
      },
      invoicing_profile: {
        view: false,
        create: false,
      },
      workflow: {
        view: false,
        create: false,
      },
    },
    contract_authority: {
      clause_library: {
        view: false,
        create: false,
      },
      templates: {
        view: false,
        create: false,
      },
    },
    action_management: {
      root_action: {
        view: false,
        create: false,
      },
      action_instance: {
        view: false,
        create: false,
      },
    },
    reports: {
      build_report: {
        view: false,
        create: false,
      },
      scheduled_report: {
        view: false,
        create: false,
      },
    },
  });

  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedPrimaryContracts, setSelectedPrimaryContracts] = useState([]);
  const [selectedSecondaryContracts, setSelectedSecondaryContracts] = useState(
    []
  );

  /* Function to handle onChange event of check and uncheck the screen access
   * @Param type - a value to screen assess in main catogrey
   * @Param assess - a screen name which is going to be checked or unchecked
   * Param value - a boolean value to check or uncheck the screen access
   */
  const onCheckAccess = (menu, access, type, value) => {
    // console.log("menu", menu);
    // console.log("access", access);
    // console.log("type", type);
    // console.log("value", value);
    if (type === "create") {
      if (value && !checked[menu][access]["view"]) {
        setChecked((prevState) => ({
          ...prevState,
          [menu]: {
            ...prevState[menu],
            [access]: {
              view: true,
              create: true,
            },
          },
        }));
      } else {
        setChecked((prevState) => ({
          ...prevState,
          [menu]: {
            ...prevState[menu],
            [access]: {
              ...prevState[menu][access],
              [type]: value,
            },
          },
        }));
      }
    } else {
      setChecked((prevState) => ({
        ...prevState,
        [menu]: {
          ...prevState[menu],
          [access]: {
            ...prevState[menu][access],
            [type]: value,
          },
        },
      }));
    }
  };

  useEffect(() => {
    console.log("checked changed", checked);
  }, [checked]);

  const onChangeSupplier = (key, value) => {
    setDisableAddButton(false);
    console.log("supplier key", key);
    console.log("supplier value", value);
    form.setFieldsValue({ msa: null });
    setSelectedData({
      ...selectedData,
      supplier: key,
      msa: [],
    });
    // setSelectedAcc({
    //   ...selectedAcc,
    //   supplier: key,
    // });
    setSelectedMode({
      ...selectedMode,
      supplier: key.includes("All") ? "All" : "Not All",
    });
    setEntityData({
      ...entityData,
      supplier: key.includes("All")
        ? supplierData.map((val) => {
            return {
              id: val.id ? val.id : val._id,
              supplier_name: val.supplier_name,
            };
          })
        : value.map((val) => {
            return {
              id: val.key,
              supplier_name: val.label,
            };
          }),
    });
    if (key.length > 0) {
      if (key.includes("All")) {
        getAllMsaApi().then((res) => {
          let allMsaData =
            res.length > 0 ? [{ id: "All", contract_name: "All" }, ...res] : [];
          setMsaData(allMsaData);
          // setMsaData({
          //   ...msaData,
          //   [count]: allMsaData,
          // });
        });
      } else {
        getAllMsaApi().then((res) => {
          let filteredMsa = res.filter((data) =>
            key.includes(data.supplier_id)
          );
          filteredMsa =
            filteredMsa.length > 0
              ? [
                  {
                    id: "All",
                    contract_name: "All",
                  },
                  ...filteredMsa,
                ]
              : [];
          setMsaData(filteredMsa);
          // setMsaData({
          //   ...msaData,
          //   [count]: filteredMsa,
          // });
        });
      }
    } else {
      setMsaData([]);
      // setMsaData({
      //   ...msaData,
      //   [count]: [{ id: "All", contract_name: "All" }, ...key],
      // });
    }
  };

  const onChangeMsa = (key, value) => {
    console.log("msa key", key);
    console.log("msa value", value);
    form.setFieldsValue({ contract: null });
    setSelectedData({
      ...selectedData,
      msa: key,
      contract: [],
    });
    // setSelectedAcc({
    //   ...selectedAcc,
    //   msa: key,
    // });
    setSelectedMode({
      ...selectedMode,
      msa:
        selectedMode.supplier === "All" && key.includes("All")
          ? "All"
          : "Not All",
    });
    setEntityData({
      ...entityData,
      msa: key.includes("All")
        ? [
            { msa: "All", msa_name: "All" },
            ...msaData.map((val) => {
              return {
                msa: val.id ? val.id : val._id,
                supplier: val.supplier_id,
                msa_name: val.contract_name,
              };
            }),
          ]
        : value.map((val) => {
            return {
              msa: val.key,
              supplier: val.supplier,
              msa_name: val.label,
            };
          }),
    });
    if (key.length > 0) {
      if (key.includes("All")) {
        if (selectedData.supplier.includes("All")) {
          getAllApprovedContractsWoMsa().then((res) => {
            console.log("all contracts", res);
            setContractData(
              res.length > 0
                ? [{ id: "All", contract_name: "All" }, ...res]
                : []
            );
            // setContractData({
            //   ...contractData,
            //   [count]: [{ id: "All", contract_name: "All" }, ...res],
            // });
          });
        } else {
          getBySupplierWoMsa(selectedData.supplier.join(",")).then((res) => {
            console.log("Contract - get by supplier", res);
            setContractData(
              res.length > 0
                ? [{ id: "All", contract_name: "All" }, ...res]
                : []
            );
            // setContractData({
            //   ...contractData,
            //   [count]: [{ id: "All", contract_name: "All" }, ...res],
            // });
          });
        }
      } else {
        getByMsa(key.join(",")).then((res) => {
          console.log("Contract - get by msa", res);
          let child = [];
          res &&
            res.length > 0 &&
            res.map((par) => child.push(...par.children));
          setContractData(
            child.length > 0
              ? [{ id: "All", contract_name: "All" }, ...child]
              : []
          );
          // setContractData({
          //   ...contractData,
          //   [count]: [{ id: "All", contract_name: "All" }, ...child],
          // });
        });
      }
    } else {
      setContractData([]);
      // setContractData({
      //   ...contractData,
      //   [count]: [{ id: "All", contract_name: "All" }, ...key],
      // });
    }
  };

  const onChangeContract = (key, value) => {
    console.log("contract key", key);
    console.log("contract value", value);
    setSelectedData({
      ...selectedData,
      contract: key,
    });
    // setSelectedAcc({
    //   ...selectedAcc,
    //   contract: [...selectedAcc.contract, ...key],
    // });
    setSelectedMode({
      ...selectedMode,
      contract:
        selectedMode.supplier === "All" &&
        selectedMode.msa === "All" &&
        key.includes("All")
          ? "All"
          : "Not All",
    });
    setEntityData({
      ...entityData,
      contract: key.includes("All")
        ? [
            { contract: "All", contract_name: "All" },
            ...contractData.map((val) => {
              return {
                contract: val.id ? val.id : val._id,
                supplier: val.supplier_id,
                contract_name: val.contract_name,
              };
            }),
          ]
        : value.map((val) => {
            return {
              contract: val.key,
              supplier: val.supplier,
              contract_name: val.label,
            };
          }),
    });
  };

  const onCheckAll = (e) => {
    setChecked({
      contracts: {
        contract_tree: {
          view: e.target.checked,
        },
        view_contract: {
          view: e.target.checked,
        },
        contract_pipeline: {
          view: e.target.checked,
        },
        create_contract: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      system_setup: {
        supplier_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        legal_entity_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        cost_center_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        ratecard_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        fx_setup: {
          view: e.target.checked,
          create: e.target.checked,
        },
        indexation: {
          view: e.target.checked,
          create: e.target.checked,
        },
        pricing_profile: {
          view: e.target.checked,
          create: e.target.checked,
        },
        invoicing_profile: {
          view: e.target.checked,
          create: e.target.checked,
        },
        workflow: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      contract_authority: {
        clause_library: {
          view: e.target.checked,
          create: e.target.checked,
        },
        templates: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      action_management: {
        root_action: {
          view: e.target.checked,
          create: e.target.checked,
        },
        action_instance: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
      reports: {
        build_report: {
          view: e.target.checked,
          create: e.target.checked,
        },
        scheduled_report: {
          view: e.target.checked,
          create: e.target.checked,
        },
      },
    });
  };
  // Function to return the page access for Contrct and System setup
  const pageAccess = () => {
    return (
      <>
        {/* <div className="create-supplier-div"> */}

        {/* </div> */}
        <div className="create-supplier-div">
          <Row className="mt-10 mb-10">
            <Col span={24}>
              <Checkbox
                id="select-all-checkbox"
                // className="float-right"
                onChange={onCheckAll}
              >
                <b>Select All</b>
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="create-user-entity-div">
                <h4 className="access-title">Contracts</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Contract Tree</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   checked.contracts.contract_tree.create
                                  // }
                                  id="contract-tree-view-checkbox"
                                  checked={checked.contracts.contract_tree.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "contract_tree",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>View Contract</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="view-contract-checkbox"
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "view_contract",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Contract Pipeline</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="contract-pipeline-view-checkbox"
                                  checked={
                                    checked.contracts.contract_pipeline.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "contract_pipeline",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Create Contract</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="create-contract-view-checkbox"
                                  disabled={
                                    checked.contracts.create_contract.create
                                  }
                                  checked={
                                    checked.contracts.create_contract.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "create_contract",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="create-contract-create-checkbox"
                                  checked={
                                    checked.contracts.create_contract.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contracts",
                                      "create_contract",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">Contract Authority</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Clause Library</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="clause-library-view-checkbox"
                                  disabled={
                                    checked.contract_authority.clause_library
                                      .create
                                  }
                                  checked={
                                    checked.contract_authority.clause_library
                                      .view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "clause_library",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="clause-library-create-checkbox"
                                  checked={
                                    checked.contract_authority.clause_library
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "clause_library",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Templates</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="templates-view-checkbox"
                                  disabled={
                                    checked.contract_authority.templates.create
                                  }
                                  checked={
                                    checked.contract_authority.templates.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "templates",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="templates-create-checkbox"
                                  checked={
                                    checked.contract_authority.templates.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "contract_authority",
                                      "templates",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">Action Management</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Root Action</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="root-action-view-checkbox"
                                  disabled={
                                    checked.action_management.root_action.create
                                  }
                                  checked={
                                    checked.action_management.root_action.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "root_action",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="root-action-create-checkbox"
                                  checked={
                                    checked.action_management.root_action.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "root_action",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Action Instance</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="action-instance-view-checkbox"
                                  disabled={
                                    checked.action_management.action_instance
                                      .create
                                  }
                                  checked={
                                    checked.action_management.action_instance
                                      .view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "action_instance",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="action-instance-create-checkbox"
                                  checked={
                                    checked.action_management.action_instance
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "action_management",
                                      "action_instance",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">Reports</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Build Report</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="build-report-view-checkbox"
                                  disabled={checked.reports.build_report.create}
                                  checked={checked.reports.build_report.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "build_report",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="build-report-create-checkbox"
                                  checked={checked.reports.build_report.create}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "build_report",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Scheduled Report</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="scheduled-report-view-checkbox"
                                  disabled={
                                    checked.reports.scheduled_report.create
                                  }
                                  checked={
                                    checked.reports.scheduled_report.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "scheduled_report",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="scheduled-report-create-checkbox"
                                  checked={
                                    checked.reports.scheduled_report.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "reports",
                                      "scheduled_report",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="create-user-entity-div mt-30">
                <h4 className="access-title">System Setup</h4>
                <div className="access-content">
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[4, 16]}
                    className="m-0"
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess-div">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Supplier Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="supplier-setup-view-checkbox"
                                  disabled={
                                    checked.system_setup.supplier_setup.create
                                  }
                                  checked={
                                    checked.system_setup.supplier_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "supplier_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="supplier-setup-create-checkbox"
                                  checked={
                                    checked.system_setup.supplier_setup.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "supplier_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Legal Entity Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="legal-entity-setup-view-checkbox"
                                  disabled={
                                    checked.system_setup.legal_entity_setup
                                      .create
                                  }
                                  checked={
                                    checked.system_setup.legal_entity_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "legal_entity_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="legal-entity-setup-create-checkbox"
                                  checked={
                                    checked.system_setup.legal_entity_setup
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "legal_entity_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Cost Center Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="cost-center-setup-view-checkbox"
                                  disabled={
                                    checked.system_setup.cost_center_setup
                                      .create
                                  }
                                  checked={
                                    checked.system_setup.cost_center_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "cost_center_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="cost-center-setup-create-checkbox"
                                  checked={
                                    checked.system_setup.cost_center_setup
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "cost_center_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Rate Card Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="ratecard-setup-view-checkbox"
                                  disabled={
                                    checked.system_setup.ratecard_setup.create
                                  }
                                  checked={
                                    checked.system_setup.ratecard_setup.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "ratecard_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="ratecard-setup-create-checkbox"
                                  checked={
                                    checked.system_setup.ratecard_setup.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "ratecard_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Fx Setup</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="fx-setup-view-checkbox"
                                  disabled={
                                    checked.system_setup.fx_setup.create
                                  }
                                  checked={checked.system_setup.fx_setup.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "fx_setup",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="fx-setup-create-checkbox"
                                  checked={checked.system_setup.fx_setup.create}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "fx_setup",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Indexation</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="indexation-view-checkbox"
                                  disabled={
                                    checked.system_setup.indexation.create
                                  }
                                  checked={checked.system_setup.indexation.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "indexation",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="indexation-create-checkbox"
                                  checked={
                                    checked.system_setup.indexation.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "indexation",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Pricing Profile</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="pricing-profile-view-checkbox"
                                  disabled={
                                    checked.system_setup.pricing_profile.create
                                  }
                                  checked={
                                    checked.system_setup.pricing_profile.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "pricing_profile",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="pricing-profile-create-checkbox"
                                  checked={
                                    checked.system_setup.pricing_profile.create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "pricing_profile",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Invoicing Profile</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="invoicing-profile-view-checkbox"
                                  disabled={
                                    checked.system_setup.invoicing_profile
                                      .create
                                  }
                                  checked={
                                    checked.system_setup.invoicing_profile.view
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "invoicing_profile",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="invoicing-profile-create-checkbox"
                                  checked={
                                    checked.system_setup.invoicing_profile
                                      .create
                                  }
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "invoicing_profile",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="create-pageaccess">
                            <Row type="flex">
                              <Col span={16}>
                                <p>Workflow</p>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  id="workflow-view-checkbox"
                                  disabled={
                                    checked.system_setup.workflow.create
                                  }
                                  checked={checked.system_setup.workflow.view}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "workflow",
                                      "view",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  View
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <Checkbox
                                  // disabled={
                                  //   state &&
                                  //   state.data_access &&
                                  //   state.data_access === "All access"
                                  //     ? true
                                  //     : false
                                  // }
                                  id="workflow-create-checkbox"
                                  checked={checked.system_setup.workflow.create}
                                  onChange={(e) => {
                                    onCheckAccess(
                                      "system_setup",
                                      "workflow",
                                      "create",
                                      e.target.checked
                                    );
                                  }}
                                >
                                  Create
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  useEffect(() => {
    // suppliersTreeApi(userData[0].id).then((res) => {
    let supplierMaster = [].map((data) => {
      return data;
    });
    supplierMaster = [
      {
        _id: "All",
        supplier_name: "All",
      },
      ...supplierMaster,
    ];
    setSupplierData(supplierMaster);
    setAllSupplierData(supplierMaster);
    // });
  }, []);

  const handleDelete = (rowData) => {
    console.log("rowDataaa", rowData);
    try {
      setDataAccessLoader(true);
      let deletedSup = allSupplierData.filter(
        (data) => (data.id ? data.id : data._id) === rowData.supplier
      );
      setSupplierData([...supplierData, ...deletedSup]);

      // Get an array of msa, contract values from rowData.msa/contract
      const msaValues = rowData.msa.map((obj) => obj.msa);
      const contractValues = rowData.contract.map((obj) => obj.contract);

      // Filter arr to remove elements that are present in msaValues, contractValues
      const filteredMsa = selectedAcc.msa.filter(
        (item) => !msaValues.includes(item) && item !== "All"
      );
      const filteredContract = selectedAcc.contract.filter(
        (item) => !contractValues.includes(item) && item !== "All"
      );

      setSelectedAcc({
        ...selectedAcc,
        supplier: selectedAcc.supplier.filter(
          (data) => data !== rowData.supplier
        ),
        msa: filteredMsa,
        contract: filteredContract,
      });
      setAddedData(
        addedData.filter(
          (data) =>
            data.supplier !== rowData.supplier && data.supplier !== "All"
        )
      );
      setDataAccessLoader(false);
    } catch (error) {
      console.error("Error occurred:", error);
      setDataAccessLoader(false);
    }
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const handleAdd = async () => {
    console.log("entityData****", entityData);
    try {
      setDataAccessLoader(true);

      setSelectedAcc({
        ...selectedAcc,
        supplier: [...selectedAcc.supplier, ...selectedData.supplier],
        msa: [...selectedAcc.msa, ...selectedData.msa],
        contract: [...selectedAcc.contract, ...selectedData.contract],
      });

      let supIds = entityData.supplier.map((data) => data.id);
      setSupplierData(
        supplierData.filter(
          (sup) => !supIds.includes(sup.id ? sup.id : sup._id)
        )
      );

      // Map through selected suppliers to gather corresponding contract and msa values
      const output = [];
      await Promise.all(
        entityData.supplier.map(async (sup) => {
          let msaOption = [];
          let contractOption = [];

          const msaS =
            entityData.msa
              .filter((m) => m.supplier === sup.id)
              ?.map((m) => m.msa) || [];

          await getAllMsaApi().then((res) => {
            let filteredMsa = res.filter((data) => sup.id === data.supplier_id);
            msaOption =
              filteredMsa.length > 0
                ? [
                    {
                      id: "All",
                      contract_name: "All",
                    },
                    ...filteredMsa,
                  ]
                : [];
          });

          if (msaS.length > 0) {
            await getByMsa(msaS.join(",")).then((res) => {
              let child = [];
              res &&
                res.length > 0 &&
                res.map((par) => child.push(...par.children));
              contractOption =
                child.length > 0
                  ? [{ id: "All", contract_name: "All" }, ...child]
                  : [];
            });
          } else {
            contractOption = [];
          }

          const supplierContracts =
            entityData.contract
              ?.filter((c) => c.supplier === sup.id)
              ?.map((c) => c) || [];
          const supplierMsas =
            entityData.msa
              ?.filter((m) => m.supplier === sup.id)
              ?.map((m) => m) || [];

          output.push({
            supplier: sup.id,
            supplier_name: sup.supplier_name,
            msa: supplierMsas,
            contract: supplierContracts,
            msa_options: msaOption,
            contract_options: contractOption,
            contractMode: entityData.contract.some(
              (ele) => ele.contract === "All"
            )
              ? "All"
              : supplierContracts.map((data) => data.contract),
            msaMode: entityData.msa.some((ele) => ele.msa === "All")
              ? "All"
              : supplierMsas.map((data) => data.msa),
          });
        })
      );
      console.log("output :", output);
      setIsSubmitEnabled(true);

      setAddedData([...addedData, ...output]);
      setContractData([]);
      setMsaData([]);
      setSelectedData({
        supplier: [],
        msa: [],
        contract: [],
      });
      setEntityData({
        supplier: [],
        msa: [],
        contract: [],
      });
      addForm.resetFields(); // This will clear all fields in the form
      addForm.setFieldsValue({ contract: null });
      addForm.setFieldsValue({ msa: null });
      setDataAccessLoader(false);
    } catch (error) {
      console.error("Error occurred:", error);
      setDataAccessLoader(false);
    }
  };

  /* Function to handle the create user get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeAddedMsa = (key, value, supplier) => {
    console.log("msa key", key);
    console.log("msa value", value);

    setSelectedAcc({
      ...selectedAcc,
      msa: new Set([...selectedAcc.msa, ...key]),
    });

    const updatedMsa = addedData.map(async (obj) => {
      if (obj.supplier === supplier) {
        let contractOption = [];
        if (key.length > 0) {
          if (key.includes("All")) {
            const res = await getBySupplierWoMsa(supplier);
            console.log("Contract - get by supplier", res);
            contractOption =
              res.length > 0
                ? [{ id: "All", contract_name: "All" }, ...res]
                : [];
          } else {
            const res = await getByMsa(key.join(","));
            console.log("Contract - get by msa", res);
            let child = [];
            res &&
              res.length > 0 &&
              res.map((par) => child.push(...par.children));
            contractOption =
              child.length > 0
                ? [{ id: "All", contract_name: "All" }, ...child]
                : [];
          }
        } else {
          contractOption = [];
        }
        return {
          ...obj,
          msaMode: key.includes("All") ? "All" : key,
          msa: key.includes("All")
            ? [
                { msa: "All", msa_name: "All" },
                ...obj.msa_options.map((val) => {
                  return {
                    msa: val._id ? val._id : val.id,
                    supplier: val.supplier_id,
                    msa_name: val.contract_name,
                  };
                }),
              ]
            : value.map((val) => {
                return {
                  msa: val.key,
                  supplier: val.supplier,
                  msa_name: val.label,
                };
              }),
          contract_options: contractOption,
        };
      } else {
        return obj;
      }
    });

    // Wait for all promises to resolve before updating state
    Promise.all(updatedMsa).then((updatedData) => {
      setAddedData(updatedData);
    });
  };

  const onChangeAddedContract = (key, value, supplier) => {
    console.log("contract key", key);
    console.log("contract value", value);

    setSelectedAcc({
      ...selectedAcc,
      contract: new Set([...selectedAcc.contract, ...key]),
    });

    const updatedContract = addedData.map((obj) => {
      if (obj.supplier === supplier) {
        return {
          ...obj,
          contractMode: key.includes("All") ? "All" : key,
          contract: key.includes("All")
            ? [
                { contract: "All", contract_name: "All" },
                ...obj.contract_options.map((val) => {
                  return {
                    contract: val._id ? val._id : val.id,
                    supplier: val.supplier_id,
                    contract_name: val.contract_name,
                  };
                }),
              ]
            : value.map((val) => {
                return {
                  contract: val.key,
                  supplier: val.supplier,
                  contract_name: val.label,
                };
              }),
        };
      } else {
        return obj;
      }
    });
    setAddedData(updatedContract);
  };

  useEffect(() => {
    console.log("selected selectedAcc***", selectedAcc);
  }, [selectedAcc]);

  // Function to return the entity access for create, view and action
  const dataAccess = () => {
    return (
      // <div className="create-supplier-div">
      //   <Row>
      //     <Col span={24}>
      //       <div className="create-user-entity-div">
      //         <h4 className="access-title">Select Data</h4>
      //         {!dataAccessLoader ? (
      //           <div className="access-content">
      //             <Row
      //               align="middle"
      //               type="flex"
      //               gutter={[4, 16]}
      //               className="m-0 mt-30"
      //             >
      //               <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      //                 <Form Form={addForm}>
      //                   <div className="create-roles-div p-20">
      //                     <Col span={24} className="add-data-col">
      //                       <Row align="middle" type="flex" gutter={[24, 16]}>
      //                         <Col span={8}>
      //                           <Form.Item
      //                             name="supplier"
      //                             {...formItemLayout}
      //                             className="org-supplier-label"
      //                             label="SUPPLIER"
      //                             colon={false}
      //                           >
      //                             <Select
      //                               ref={selectRef}
      //                               value={selectedData.supplier}
      //                               mode="multiple"
      //                               showSearch={true}
      //                               maxTagCount="responsive"
      //                               placeholder="Select Supplier"
      //                               allowClear={true}
      //                               onChange={(key, value) =>
      //                                 onChangeSupplier(key, value)
      //                               }
      //                               options={
      //                                 supplierData &&
      //                                 supplierData.length > 0 &&
      //                                 supplierData.map((data) => ({
      //                                   key: data._id ? data._id : data.id,
      //                                   label: data.supplier_name,
      //                                   value: data._id ? data._id : data.id,
      //                                 }))
      //                               }
      //                             ></Select>
      //                           </Form.Item>
      //                         </Col>

      //                         <Col span={8}>
      //                           <Form.Item
      //                             {...formItemLayout}
      //                             className="org-supplier-label"
      //                             name="msa"
      //                             label="MSA"
      //                             colon={false}
      //                           >
      //                             <Select
      //                               ref={selectRef}
      //                               value={selectedData.msa}
      //                               mode="multiple"
      //                               allowClear
      //                               showSearch={true}
      //                               placeholder="Choose MSA"
      //                               onChange={(key, value) =>
      //                                 onChangeMsa(key, value)
      //                               }
      //                               maxTagCount="responsive"
      //                               options={
      //                                 msaData &&
      //                                 msaData.length > 0 &&
      //                                 msaData.map((data) => ({
      //                                   key: data._id ? data._id : data.id,
      //                                   label: data.contract_name,
      //                                   value: data._id ? data._id : data.id,
      //                                   supplier: data.supplier_id,
      //                                 }))
      //                               }
      //                             ></Select>
      //                           </Form.Item>
      //                         </Col>

      //                         <Col span={8}>
      //                           <Form.Item
      //                             {...formItemLayout}
      //                             className="org-supplier-label"
      //                             name="contract"
      //                             label="CONTRACT"
      //                             colon={false}
      //                           >
      //                             <Select
      //                               ref={selectRef}
      //                               value={selectedData.contract}
      //                               allowClear
      //                               mode="multiple"
      //                               showSearch={true}
      //                               placeholder="Choose Contract"
      //                               onChange={(key, value) =>
      //                                 onChangeContract(key, value)
      //                               }
      //                               maxTagCount="responsive"
      //                               options={
      //                                 contractData &&
      //                                 contractData.length > 0 &&
      //                                 contractData.map((data) => ({
      //                                   key: data._id ? data._id : data.id,
      //                                   label: data.contract_name,
      //                                   value: data._id ? data._id : data.id,
      //                                   supplier: data.supplier_id,
      //                                 }))
      //                               }
      //                             ></Select>
      //                           </Form.Item>
      //                         </Col>
      //                       </Row>
      //                     </Col>
      //                   </div>
      //                   <Row>
      //                     <Col
      //                       span={24}
      //                       align="center"
      //                       className="vertical-align"
      //                     >
      //                       <Col span={2}>
      //                         <AddButton
      //                           onClick={handleAdd}
      //                           disabled={disableAddButton}
      //                         />
      //                       </Col>
      //                     </Col>
      //                   </Row>

      //                   {addedData &&
      //                     addedData.length > 0 &&
      //                     addedData.map((added) => {
      //                       // console.log("addedd on return", added);
      //                       if (added.supplier !== "All") {
      //                         return (
      //                           <Row
      //                             align="middle"
      //                             type="flex"
      //                             gutter={[4, 16]}
      //                             className="m-0 mt-10"
      //                             key={added.supplier}
      //                           >
      //                             <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      //                               <div className="added-div">
      //                                 <Col span={24} className="added-data-col">
      //                                   <Row
      //                                     align="middle"
      //                                     type="flex"
      //                                     gutter={[24, 16]}
      //                                   >
      //                                     <Col span={7}>
      //                                       <h5>SUPPLIER</h5>
      //                                       <Input
      //                                         className="ellipsis-input mt-10"
      //                                         defaultValue={added.supplier_name}
      //                                         readOnly
      //                                       />
      //                                     </Col>

      //                                     <Col span={7}>
      //                                       <h5>MSA</h5>
      //                                       <Select
      //                                         className="added-select mt-10"
      //                                         defaultValue={added.msa.map(
      //                                           (val) => val.msa
      //                                         )}
      //                                         mode="multiple"
      //                                         allowClear
      //                                         showSearch={true}
      //                                         placeholder="Choose MSA"
      //                                         onChange={(key, value) =>
      //                                           onChangeAddedMsa(
      //                                             key,
      //                                             value,
      //                                             added.supplier
      //                                           )
      //                                         }
      //                                         maxTagCount="responsive"
      //                                         options={
      //                                           added.msa_options &&
      //                                           added.msa_options.length > 0 &&
      //                                           added.msa_options.map(
      //                                             (data) => ({
      //                                               key: data._id
      //                                                 ? data._id
      //                                                 : data.id,
      //                                               label: data.contract_name,
      //                                               value: data._id
      //                                                 ? data._id
      //                                                 : data.id,
      //                                               supplier: data.supplier_id,
      //                                             })
      //                                           )
      //                                         }
      //                                       ></Select>
      //                                     </Col>

      //                                     <Col span={7}>
      //                                       <h5>CONTRACT</h5>
      //                                       <Select
      //                                         className="added-select mt-10"
      //                                         defaultValue={added.contract.map(
      //                                           (val) => val.contract_name
      //                                         )}
      //                                         mode="multiple"
      //                                         allowClear
      //                                         showSearch={true}
      //                                         placeholder="Choose Contract"
      //                                         onChange={(key, value) =>
      //                                           onChangeAddedContract(
      //                                             key,
      //                                             value,
      //                                             added.supplier
      //                                           )
      //                                         }
      //                                         maxTagCount="responsive"
      //                                         options={
      //                                           added.contract_options &&
      //                                           added.contract_options.length >
      //                                             0 &&
      //                                           added.contract_options.map(
      //                                             (data) => ({
      //                                               key: data._id
      //                                                 ? data._id
      //                                                 : data.id,
      //                                               label: data.contract_name,
      //                                               value: data._id
      //                                                 ? data._id
      //                                                 : data.id,
      //                                               supplier: data.supplier_id,
      //                                             })
      //                                           )
      //                                         }
      //                                       ></Select>
      //                                     </Col>

      //                                     <Col span={3}>
      //                                       <Popconfirm
      //                                         title="Sure to delete?"
      //                                         onConfirm={() =>
      //                                           handleDelete(added)
      //                                         }
      //                                       >
      //                                         <a>
      //                                           <DeleteButton />
      //                                         </a>
      //                                       </Popconfirm>
      //                                     </Col>
      //                                   </Row>
      //                                 </Col>
      //                               </div>
      //                             </Col>
      //                           </Row>
      //                         );
      //                       }
      //                     })}
      //                 </Form>
      //               </Col>
      //             </Row>
      //           </div>
      //         ) : (
      //           <CustomLoader />
      //         )}
      //       </div>
      //     </Col>
      //   </Row>
      // </div>
      <div className="create-supplier-div">
        <RoleAccessController
          mode={{ view: false, edit: false, create: true }}
          selectedSuppliers={selectedSuppliers}
          setSelectedSuppliers={setSelectedSuppliers}
          selectedPrimaryContracts={selectedPrimaryContracts}
          setSelectedPrimaryContracts={setSelectedPrimaryContracts}
          selectedSecondaryContracts={selectedSecondaryContracts}
          setSelectedSecondaryContracts={setSelectedSecondaryContracts}
        />
      </div>
    );
  };

  // Variable to hold a two components of page access and entity access
  const items = [
    {
      key: "1",
      label: `Page Access`,
      children: pageAccess(),
    },
    {
      key: "2",
      label: `Data Access`,
      children: dataAccess(),
      disabled:
        Object.values(checked.contracts).some((val) => val) ||
        Object.values(checked.system_setup).some((val) => val)
          ? false
          : true,
    },
  ];

  function processFormData(data) {
    const { suppliers, primary, secondary } = data;

    // Function to process arrays with "All"
    const processArray = (array) => {
      if (array.includes("All")) {
        return ["All"]; // Retain only "All" if present
      }
      return array; // Otherwise, return the array as is
    };

    // Process each array
    const processedSuppliers = processArray(suppliers);
    const processedPrimary = processArray(primary);
    const processedSecondary = processArray(secondary);

    // Return the processed data
    return {
      ...data,
      suppliers: processedSuppliers,
      primary: processedPrimary,
      secondary: processedSecondary,
    };
  }

  // Function to handle the create Role form data stored
  const onFinish = async () => {
    console.log("addedData****", addedData);

    const entityAccessData = {
      suppliers: selectedSuppliers,
      primary: selectedPrimaryContracts,
      secondary: selectedSecondaryContracts,
    };

    const processedEntityAccessData = processFormData(entityAccessData);

    let data = {
      role_name: state && state.role_name,
      role_description: state && state.role_description,
      supplier: addedData.some((data) => data.supplier === "All")
        ? "All"
        : "Not All",
      msa:
        addedData.some((data) => data.supplier === "All") &&
        addedData.every((data) => data.msaMode === "All")
          ? "All"
          : "Not All",
      contract:
        addedData.some((data) => data.supplier === "All") &&
        addedData.every((data) => data.msaMode === "All") &&
        addedData.every((data) => data.contractMode === "All")
          ? "All"
          : "Not All",
      page_access: {
        contracts: checked.contracts,
        contract_authority: checked.contract_authority,
        action_management: checked.action_management,
        reports: checked.reports,
        system_setup: checked.system_setup,
      },
      entity_rights: addedData.map((item) => ({
        supplier: item.supplier,
        msa: item.msa.map((msaItem) => ({
          msa: msaItem.msa,
          supplier: msaItem.supplier,
        })),
        contract: item.contract.map((contractItem) => ({
          contract: contractItem.contract,
          supplier: contractItem.supplier,
        })),
        contractMode: item.contractMode,
        msaMode: item.msaMode,
      })),
      access_rights: processedEntityAccessData,
      status: stateButton === 1 ? "In Draft" : "Active",
      created_by: userData[0].id,
      updated_by: userData[0].id,
      created_on: new Date(),
      updated_on: new Date(),
    };

    console.log("create data", data);
    console.log(
      selectedSuppliers,
      selectedPrimaryContracts,
      selectedSecondaryContracts
    );
    if (stateButton === 1) {
      setSaveTableLoader(true);

      data["created_by"] = userData[0].id;

      await createRoleAPi(data)
        .then((res) => {
          console.log("ress", res);
          if (res.status === 400) {
            popupModalInstance.errorModal(ModalMessage.Role_Create_Error);
            setSaveTableLoader(false);
          } else if (res.status === 201) {
            let umLog = {
              ss_id: res.data.id ? res.data.id : res.data._id,
              ss_name: res.data.role_name,
              code: "roles",
              author: userData[0].id,
              action: "draft",
              notes: "Saved New Role",
              updated_on: new Date(),
            };

            createActivityLogApi(umLog).then(() => {});
            setSaveTableLoader(false);

            popupModalInstance.successModal(
              state.role_name,
              ModalMessage.Role_Save,
              ""
            );

            state.role_id = res.data.id || res.data._id;
          } else {
            setSaveTableLoader(false);
            Modal.error({
              title: "Error on create",
              className: "popup-model-error",
              centered: true,
              icon: false,
              content: (
                <>
                  <div style={{ fontWeight: 600 }} className="mb-10">
                    Role creation failed for the following reasons:
                  </div>
                  {alert("error-role")}
                </>
              ),
            });
          }
        })
        .catch((error) => {
          console.error("Error creating role:", error);
          setSaveTableLoader(false);
          popupModalInstance.errorModal(
            "An unexpected error occurred. Please try again."
          );
        });
    }

    // if (stateButton === 2) {
    //   setLoader(true);
    //   popupModalInstance
    //     .infoModal(ModalMessage.Role_Create)
    //     .then(async (userChoice) => {
    //       if (userChoice === "ok") {
    //         try {
    //           const res = await createRoleAPi(data);
    //           if (res.status === 400) {
    //             popupModalInstance.errorModal(ModalMessage.Role_Create_Error);
    //             setLoader(false);
    //           } else if (res.status === 201) {
    //             let umLog = {
    //               ss_id: res.data.id ? res.data.id : res.data._id,
    //               ss_name: res.data.role_name,
    //               code: "roles",
    //               author: userData[0].id,
    //               action: "create",
    //               notes: "Created New Role",
    //               updated_on: new Date(),
    //             };

    //             await createActivityLogApi(umLog);
    //             setLoader(false);
    //             navigate(`/user-management/?back=set-roles`);
    //           }
    //         } catch (error) {
    //           console.error("Error creating role:", error);
    //           setLoader(false);
    //           popupModalInstance.errorModal(
    //             "An unexpected error occurred. Please try again."
    //           );
    //         }
    //       } else if (userChoice === "cancel") {
    //         setLoader(false);
    //       }
    //     });
    // }
    if (stateButton === 2) {
      setLoader(true);

      popupModalInstance
        .infoModal(ModalMessage.Role_Create)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            const roleId = state.role_id;

            if (!roleId) {
              // If role_id doesn't exist, create the role first
              try {
                const res = await createRoleAPi(data);
                if (res.status === 400) {
                  popupModalInstance.errorModal(ModalMessage.Role_Create_Error);
                  setLoader(false);
                } else if (res.status === 201) {
                  // Once the role is created, set the role_id
                  state.role_id = res.data.id || res.data._id;

                  let umLog = {
                    ss_id: state.role_id,
                    ss_name: res.data.role_name,
                    code: "roles",
                    author: userData[0].id,
                    action: "create",
                    notes: "Created New Role",
                    updated_on: new Date(),
                  };

                  await createActivityLogApi(umLog);

                  submitRole();

                  setLoader(false);
                }
              } catch (error) {
                console.error("Error creating role:", error);
                setLoader(false);
                popupModalInstance.errorModal(
                  "An unexpected error occurred. Please try again."
                );
              }
            } else {
              submitRole();
            }
          }

          if (userChoice === "cancel") {
            setLoader(false);
          }
        });
    }

    const submitRole = async () => {
      const roleId = state.role_id;

      if (roleId) {
        try {
          const res = await editRoleAPi(roleId, data);

          let umLog = {
            ss_id: roleId,
            ss_name: data.role_name,
            code: "roles",
            author: userData[0].id,
            action: "submit",
            notes: "Submitted Role",
            updated_on: new Date(),
          };

          await createActivityLogApi(umLog);
          setLoader(false);

          navigate(`/user-management/?back=set-roles`);
        } catch (error) {
          console.error("Error submitting role:", error);
          setLoader(false);
          popupModalInstance.errorModal(
            "An unexpected error occurred while submitting. Please try again."
          );
        }
      } else {
        setLoader(false);
        popupModalInstance.errorModal("Role ID is missing or invalid.");
      }
    };
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout>
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      <>
        <div className="system-setup1">
          <Form
            Form={form}
            name="create-role-access"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
            initialValues={
              {
                // contracts: {
                //   ["contract_tree"]: true,
                //   ["contract_search"]: true,
                //   ["contract_pipeline"]: false,
                //   ["contract_creation"]: false,
                // },
                // ["create"]: true,
                // ["view"]: false,
                // ["action"]: false,
              }
            }
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={20}
                md={21}
                lg={21}
                xl={18}
              >
                <HeaderCard
                  mainMenu="User Management"
                  subMenu="Roles"
                  itemName={state.role_name}
                  // itemStatus={state.status}
                  goBackLink="/user-management/?back=set-roles"
                  // state={""}
                  // showDetails={"create"}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <CancelButton onClick={() => history(`/user-management`)} />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SaveButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => setStateButton(1)}
                  loading={saveTableLoader}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SubmitButton
                  type="primary"
                  htmlType="submit"
                  onClick={() => setStateButton(2)}
                  // disabled={!isSubmitEnabled}
                  disabled={
                    selectedSuppliers.length === 0 ||
                    selectedPrimaryContracts.length === 0 ||
                    selectedSecondaryContracts.length === 0
                  }
                  loading={loader}
                />
              </Col>
            </Row>
            <div className="scrolling-content">
              <Tabs items={items} className="m-15" />
            </div>
          </Form>
        </div>
      </>
    </Layout>
  );
}

export default CreateRoleAccess;
