import { axiosInstance } from "../utils/common";

export const getAllDataOnSearchApi = (page, limit, searchText, userId) => {
  return axiosInstance
    .get(
      `v1/globalSearch/all/data-on-search/${searchText}/${userId}?limit=${limit}&page=${page}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getMenuDataOnSearchApi = (
  page,
  limit,
  searchText,
  menuType,
  userId
) => {
  return axiosInstance
    .get(
      `v1/globalSearch/get-by-menu/data-on-search/${searchText}/${menuType}/${userId}?limit=${limit}&page=${page}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
