import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image, Menu, Input, Table, Pagination, Spin } from "antd"; //antd components
import { SearchOutlined } from "@ant-design/icons";
import "../assets/style/globalsearch.css";
import CustomLoader from "./CustomLoader";
import ContractSearchIcon from "../assets/images/icons/contracts_search_icon.svg";
import frameworkAgreement from "../assets/images/icons/framework_agreement.svg";
import supplement from "../assets/images/icons/supplement.svg";
import ccn from "../assets/images/icons/ccn.svg";
import sow from "../assets/images/icons/sow.svg";
import cr from "../assets/images/icons/cr.svg";
import others from "../assets/images/icons/others.svg";
import person_inactive from "../assets/images/icons/person_inactive.png";
import adminPanel_inactive from "../assets/images/icons/admin_panel_settings.png";
import priceChange_inactive from "../assets/images/icons/cost_center_inactive.png";
import rateCard_inactive from "../assets/images/icons/rate_card_inactive.png";
import pricingProfile_inactive from "../assets/images/icons/pricing_profile_inactive.png";
import invoicingProfile_inactive from "../assets/images/icons/invoicing_profile_inactive.png";
import fxSetup_inactive from "../assets/images/icons/fx_setup_inactive.png";
import workflow_inactive from "../assets/images/icons/workflow_inactive.png";
import buildReportInactive from "../assets/images/icons/build_report_inactive.svg";
import groups_inactive from "../assets/images/icons/groups.png";
import moment from "moment";
import {
  getAllDataOnSearchApi,
  getMenuDataOnSearchApi,
} from "../api/globalSearch";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  SupplierTreeDataAtom,
  userDataAtom,
} from "../store/store";
import ContractFile from "./Contract/ContractFile";
import { getSingleContractApi } from "../api/contractsApi";
import { clauseLibraryByIdApi } from "../api/contractAuthorityApi";
import { getContractTemplateByIdApi } from "../api/contractTemplateApi";
import { getSingleActionDataApi } from "../api/actionManagementApi";
import { getInstanceByIdApi } from "../api/actionInstanceApi";
import { getSingleSupplierDataApi } from "../api/suppliersApi";
import { getLegalEntityByIdApi } from "../api/legalEntityApi";
import { getSingleCostCenterDataApi } from "../api/costCenterApi";
import { getSingleRateCardDataApi } from "../api/ratecardApi";
import { getSingleFxTableDataApi } from "../api/fxTableApi";
import { getPricingProfileByIdApi } from "../api/pricingProfileApi";
import { getSingleInvoicingProfileDataApi } from "../api/invoicingProfileApi";
import { getSingleWorkflowsApi } from "../api/workflowApi";
import { getReportByIdData } from "../api/reportApi";
import { getSingleRolesApi } from "../api/rolesApi";
import { getUserByIdApi } from "../api/usersApi";
import { getSingleGroupApi } from "../api/groupsApi";
import { navigateBasedOnContractStatus } from "../utils/common";
import { ContractTabContext } from "./Contract/ContractTabContext";

const GlobalSearch = ({ searchQuery, setSearchQuery }) => {
  const { handleAddTab } = useContext(ContractTabContext);

  const navigate = useNavigate();
  const [searchMenuType, setSearchMenuType] = useState("all");
  const [searchText, setSearchText] = useState(searchQuery);
  const [searchTabledataSource, setSearchTabledataSource] = useState([]);
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom); //global state
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // redirect loader
  const [redirectLoader, setRedirectLoader] = useState(false);
  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [selectedContract, setSelectedContract] = useState();
  // Variable to hold supplier tree data
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  // State variable to hold screen size
  const [screenSize, setScreenSize] = useState("min");
  const [responseData, setResponseData] = useState([]); //tree data

  useEffect(() => {
    // set supplier tree data from global state
    if (supplierTreeData.length) {
      setResponseData([...supplierTreeData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierTreeData]);

  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  const getImageSource = (item) => {
    if (item.type === "Contract") {
      switch (item.contract_type) {
        case "MSA":
          return frameworkAgreement;
        case "Supplement":
        case "Sub-Supplement":
          return supplement;
        case "CCN":
          return ccn;
        case "SOW":
          return sow;
        case "CR":
          return cr;
        default:
          return others;
      }
    }

    switch (item.type) {
      case "Clause Library":
      case "Contract Template":
      case "Root Action":
      case "Action Instance":
        return ContractSearchIcon;
      case "Supplier":
        return person_inactive;
      case "Legal Entity":
        return adminPanel_inactive;
      case "Cost Center":
        return priceChange_inactive;
      case "Ratecard":
        return rateCard_inactive;
      case "Fx Table":
        return fxSetup_inactive;
      case "Pricing Profile":
        return pricingProfile_inactive;
      case "Invoicing Profile":
        return invoicingProfile_inactive;
      case "Workflow":
        return workflow_inactive;
      case "Report":
        return buildReportInactive;
      case "UserRoles":
        return person_inactive;
      case "User":
        return adminPanel_inactive;
      default:
        return groups_inactive;
    }
  };

  const getDisplayName = (item) => {
    switch (item.type) {
      case "Contract":
        return item.contract_name;
      case "Clause Library":
        return item.header_name;
      case "Contract Template":
        return item.contract_template_name;
      case "Root Action":
        return item.action_name;
      case "Action Instance":
        return item.action_instance_name;
      case "Supplier":
        return item.supplier_name;
      case "Legal Entity":
        return item.le_name;
      case "Cost Center":
        return item.cc_name;
      case "Ratecard":
        return item.ratetable_name;
      case "Fx Table":
        return item.fx_table_name;
      case "Pricing Profile":
        return item.price_profile_name;
      case "Invoicing Profile":
        return item.invoice_profile_name;
      case "Workflow":
        return item.workflow_name;
      case "Report":
        return item.report_name;
      case "UserRoles":
        return item.role_name;
      case "User":
        return item.first_name + " " + item.last_name;
      default:
        return item.group_name;
    }
  };

  const getPathOfItem = (item) => {
    const pathMap = {
      Contract:
        item.contract_status === "Contract-in-Execution"
          ? "Judo:/Contracts/Contract Tree"
          : "Judo:/Contracts/Contract Pipeline",
      "Clause Library": "Judo:/Contract Authority/Clause Library",
      "Contract Template": "Judo:/Contract Authority/Contract Template",
      "Root Action": "Judo:/Action Management/Root Action",
      "Action Instance": "Judo:/Action Management/Action Instance",
      Supplier: "Judo:/System Setup/Organization Setup/Supplier Setup",
      "Legal Entity":
        "Judo:/System Setup/Organization Setup/Legal Entity Setup",
      "Cost Center": "Judo:/System Setup/Organization Setup/Cost Center Setup",
      Ratecard: "Judo:/System Setup/Contract Setup/Rate Card",
      "Fx Table": "Judo:/System Setup/Contract Setup/Fx Setup",
      "Pricing Profile": "Judo:/System Setup/Contract Setup/Pricing Profile",
      "Invoicing Profile":
        "Judo:/System Setup/Contract Setup/Invoicing Profile",
      Workflow: "Judo:/System Setup/Contract Setup/Workflow",
      Report: "Judo:/Report",
      UserRoles: "Judo:/User Management/Roles",
      User: "Judo:/User Management/User",
      default: "Judo:/User Management/Groups",
    };

    return pathMap[item.type] || pathMap.default;
  };

  // secondary function for set path of selected node
  const getPathOfAction = (model, id) => {
    let path,
      item = {
        id: model._id,
        name: model.contract_name ? model.contract_name : model.supplier_name,
        type: model.contract_type ? model.contract_type : "Supplier",
      };

    if (!model || typeof model !== "object") return;

    if (model._id === id) return [item];

    (model.children || []).some((child) => (path = getPathOfAction(child, id)));
    return path && [item, ...path];
  };

  const onClickItem = (item) => {
    setRedirectLoader(true);
    if (item.type === "Contract") {
      navigateBasedOnContractStatus(
        item,
        navigate,
        setHeaderChanger,
        handleAddTab
      );
      setRedirectLoader(false);
    } else if (item.type === "Clause Library") {
      clauseLibraryByIdApi(item._id).then((res) => {
        setHeaderChanger({
          header: "contractAuthority",
          headerItem: "1",
          headerItemName: "contractAuthority",
          stateSetter: res.docs[0],
        });
        navigate(`/contract_authority/view/clause-library`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Contract Template") {
      getContractTemplateByIdApi(item._id).then((res) => {
        setHeaderChanger({
          header: "contractAuthority",
          headerItem: "2",
          headerItemName: "contractAuthority",
          stateSetter: res.docs[0],
        });
        navigate(`/contract_authority/view/template`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Root Action") {
      getSingleActionDataApi(item._id).then((data) => {
        // getTopPath(res.docs[0].contract_id);
        let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
        responseData.some((res) => {
          const pathVal = getPath(res, data[0].contract_id);
          if (pathVal) {
            finalPathOnModify = [...finalPathOnModify, ...pathVal];
          }
        });

        setHeaderChanger({
          header: "actionManagement",
          headerItem: "1",
          headerItemName: "actionManagement",
          stateSetter: { actionData: data[0], finalPath: finalPathOnModify },
        });
        navigate(`/action-management/view-action`, {
          state: { actionData: data[0], finalPath: finalPathOnModify },
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Action Instance") {
      getInstanceByIdApi(item._id).then((res) => {
        setHeaderChanger({
          header: "actionManagement",
          headerItem: "2",
          headerItemName: "actionManagement",
          stateSetter: res[0],
        });
        navigate(`/action-management/view-action-instance`, {
          state: res[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Supplier") {
      getSingleSupplierDataApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "1",
          headerItemName: "systemSetup",
          stateSetter: res,
        });
        navigate(`/organization-setup/view/supplier-setup`, {
          state: res,
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Legal Entity") {
      getLegalEntityByIdApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "1",
          headerItemName: "systemSetup",
          stateSetter: res.docs[0],
        });
        navigate(`/organization-setup/view/legal-entity`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Cost Center") {
      getSingleCostCenterDataApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "1",
          headerItemName: "systemSetup",
          stateSetter: res,
        });
        navigate(`/organization-setup/view/cost-center-setup`, {
          state: res,
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Ratecard") {
      getSingleRateCardDataApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "2",
          headerItemName: "systemSetup",
          stateSetter: res,
        });
        navigate(`/contract-setup/view/rate-card`, {
          state: res,
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Fx Table") {
      getSingleFxTableDataApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "2",
          headerItemName: "systemSetup",
          stateSetter: res,
        });
        navigate(`/contract-setup/view/fx-setup`, {
          state: res,
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Pricing Profile") {
      getPricingProfileByIdApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "2",
          headerItemName: "systemSetup",
          stateSetter: res.docs[0],
        });
        navigate(`/contract-setup/view/pricing-profile`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Invoicing Profile") {
      getSingleInvoicingProfileDataApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "2",
          headerItemName: "systemSetup",
          stateSetter: res,
        });
        navigate(`/contract-setup/view/invoicing-profile`, {
          state: res,
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Workflow") {
      getSingleWorkflowsApi(item._id).then((res) => {
        setHeaderChanger({
          header: "systemSetup",
          headerItem: "2",
          headerItemName: "systemSetup",
          stateSetter: res,
        });
        navigate(`/contract-setup/view/workflow`, {
          state: res,
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Report") {
      getReportByIdData(item._id).then((res) => {
        navigate(`/reports/build-report/view/report`, { state: res.docs[0] });
        setRedirectLoader(false);
      });
    } else if (item.type === "UserRoles") {
      getSingleRolesApi(item._id).then((res) => {
        setHeaderChanger({
          header: "userManagement",
          headerItem: "1",
          headerItemName: "userManagement",
          stateSetter: res.docs[0],
        });
        navigate(`/user-management/roles/view`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "User") {
      getUserByIdApi(item._id).then((res) => {
        console.log("user res", res);
        setHeaderChanger({
          header: "userManagement",
          headerItem: "2",
          headerItemName: "userManagement",
          stateSetter: res.docs[0],
        });
        navigate(`/user-management/user/view`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else if (item.type === "Group") {
      getSingleGroupApi(item._id).then((res) => {
        console.log("group res", res);
        setHeaderChanger({
          header: "userManagement",
          headerItem: "3",
          headerItemName: "userManagement",
          stateSetter: res.docs[0],
        });
        navigate(`/user-management/groups/view`, {
          state: res.docs[0],
        });
        setRedirectLoader(false);
      });
    } else {
      setRedirectLoader(false);
    }
  };

  const listSearchData = (page, limit, searchText) => {
    setLoader(true);
    getAllDataOnSearchApi(page, limit, searchText, userData[0].id).then(
      (res) => {
        setLoader(false);
        console.log("All Data On Search :", res);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.total,
        });
        let searchedData =
          res &&
          res.results &&
          res.results.length > 0 &&
          res.results.map((item, index) => {
            let tableRow = {
              // key: `${searchText}_${page}_${index}`,
              item: (
                <div>
                  <Image
                    src={getImageSource(item)}
                    preview={false}
                    width={item.type === "Contract" ? 20 : 25}
                    height={item.type === "Contract" ? 25 : 25}
                  />
                  <span
                    onClick={() => onClickItem(item)}
                    style={{
                      marginLeft: 10,
                      color: "var(--color-solid-darkblue)",
                      fontWeight: "100",
                      cursor: "pointer",
                    }}
                  >
                    {getDisplayName(item)}
                  </span>
                </div>
              ),
              type: item.type,
              path: getPathOfItem(item),
              details: moment(item.updatedAt).format("DD/MM/YYYY, HH:mm"),
            };
            return tableRow;
          });
        console.log("searchedData", searchedData);
        setSearchTabledataSource(searchedData);
      }
    );
  };

  const listSearchByMenu = (page, limit, searchText, searchMenuType) => {
    setLoader(true);
    getMenuDataOnSearchApi(
      page,
      limit,
      searchText,
      searchMenuType,
      userData[0].id
    ).then((res) => {
      setLoader(false);
      console.log("Menu Data On Search :", res);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.total,
      });
      let searchedData =
        res &&
        res.results &&
        res.results.map((item) => {
          let tableRow = {
            // key: `${key}_${item.item_name}`,
            item: (
              <div>
                <Image
                  src={getImageSource(item)}
                  preview={false}
                  width={item.type === "Contract" ? 20 : 25}
                  height={item.type === "Contract" ? 25 : 25}
                />
                <span
                  onClick={() => onClickItem(item)}
                  style={{
                    marginLeft: 10,
                    color: "var(--color-solid-darkblue)",
                    fontWeight: "100",
                    cursor: "pointer",
                  }}
                >
                  {getDisplayName(item)}
                </span>
              </div>
            ),
            type: item.type,
            path: getPathOfItem(item),
            details: moment(item.updatedAt).format("DD/MM/YYYY, HH:mm"),
          };
          return tableRow;
        });
      console.log("searchedData", searchedData);
      setSearchTabledataSource(searchedData);
    });
  };

  useEffect(() => {
    listSearchByMenu(1, 20, searchText, searchMenuType);
  }, [searchMenuType]);

  useEffect(() => {
    listSearchData(1, 20, searchText);
  }, [searchText]);

  const onPageChange = (page, limit) => {
    if (searchMenuType === "all") {
      listSearchData(page, limit, searchText);
    } else {
      listSearchByMenu(page, limit, searchText, searchMenuType);
    }
  };

  const searchColumns = [
    {
      title: "Item",
      dataIndex: "item",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Path",
      dataIndex: "path",
      align: "left",
      ellipsis: true,
    },
    {
      title: "Details",
      dataIndex: "details",
      align: "left",
      ellipsis: true,
    },
  ];

  const onMenuChange = (e) => {
    setSearchMenuType(e.key);
    if (e.key === "all") {
      listSearchData(1, 20, searchText);
    } else {
      listSearchByMenu(1, 20, searchText, e.key);
    }
  };

  const items = [
    {
      label: "All",
      key: "all",
      show: true,
    },
    {
      label: "Contract",
      key: "contract",
      show:
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.contracts.view_contract.view,
    },
    {
      label: "Contract Authority",
      key: "contract_authority",
      show:
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.contract_authority.clause_library.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.contract_authority.templates.view),
    },
    {
      label: "Action Management",
      key: "action_management",
      show:
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.action_management.root_action.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.action_management.action_instance.view),
    },
    {
      label: "System Setup",
      key: "system_setup",
      show:
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.supplier_setup.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.legal_entity_setup.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.cost_center_setup.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.ratecard_setup.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.fx_setup.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.pricing_profile.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.invoicing_profile.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.workflow.view),
    },
    {
      label: "Reports",
      key: "reports",
      show:
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.reports.build_report.view) ||
        (roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.reports.scheduled_report.view),
    },
    {
      label: "User Management",
      key: "user_management",
      show: roleUser && roleUser[0] && roleUser[0].role_name === "Admin",
    },
  ].filter((item) => item.show);

  return (
    <>
      {!selectedContract && (
        <div className="global-search-layout">
          <div className="global-search p-15">
            <Row>
              <Col span={24} align="center">
                <Col span={12}>
                  <Input
                    defaultValue={searchQuery}
                    placeholder="Search"
                    className="top-search-box mb-15"
                    // value={searchQuery}
                    onPressEnter={(event) => setSearchText(event.target.value)}
                    allowClear={true}
                    prefix={
                      <SearchOutlined
                        style={{
                          color: "#0177fb",
                          width: 20,
                          height: 30,
                        }}
                      />
                    }
                  />
                </Col>
              </Col>
            </Row>
            <Menu
              className="search-type-menu"
              onClick={onMenuChange}
              selectedKeys={[searchMenuType]}
              mode="horizontal"
              items={items}
            />
            {!redirectLoader ? (
              <>
                <Table
                  bordered
                  showHeader={false}
                  dataSource={searchTabledataSource}
                  columns={searchColumns}
                  size="large "
                  scroll={{ x: "calc(200px + 50%)", y: "calc(100vh - 260px)" }}
                  pagination={false}
                  id="fx-setup-table"
                  className="system-setup-table mt-20"
                  style={{ width: "100%" }}
                  loading={loader}
                />
                <div className="float-right mb-15 mt-15">
                  <Pagination
                    // simple
                    // pageSizeOptions={[5, 10, 20, 50, 100]}
                    defaultPageSize={paginateData.limit}
                    responsive
                    current={paginateData.pageNo}
                    total={paginateData.total}
                    showSizeChanger
                    onShowSizeChange={onPageChange}
                    onChange={onPageChange}
                  />
                </div>
              </>
            ) : (
              <CustomLoader />
            )}
          </div>
        </div>
      )}
      {/* {selectedContract && (
        <div className="approval-link-page">
          <ContractFile
            selected={selectedContract}
            screenSize={screenSize}
            pipelinePath={"approval"}
          />
        </div>
      )} */}
    </>
  );
};

export default GlobalSearch;
