import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/style/createcontract.css";
import "../../assets/style/oldcreatecontract.css";
import {
  Layout,
  Row,
  Col,
  Card,
  Input,
  Tree,
  Divider,
  Modal,
  Empty,
  Form,
  Space,
  Image,
  Radio,
  Select,
  Button,
  Upload,
  message,
} from "antd";
import {
  SyncOutlined,
  DownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TopHeader from "../Header";
import confirmation from "../../assets/images/icons/confirmation.svg";
import { useRecoilState } from "recoil";
import {
  HeaderChangerAtom,
  RoleAccessAtom,
  userDataAtom,
  isLiteAtom,
} from "../../store/store";
import { ContractTabContext } from "../Contract/ContractTabContext";
import {
  getContractTreeApi,
  searchContractTree,
  contractExtractionApi,
  getContractParentApi,
} from "../../api/contractsApi";
import { getContractTemplateApi } from "../../api/contractTemplateApi";
import { getAllWorkflowsApi } from "../../api/workflowApi";
import { getPricingProfileList } from "../../api/pricingProfileApi";
import {
  getNodeIcon,
  contractTypeOptions,
  contractTypes,
  contractConfig,
  determineContractClass,
} from "../../utils/common";
import Breadcrumbs from "../Contract/Breadcrumbs";
import {
  CancelButton,
  NextButton,
  UploadButton,
  CreateButtonShort,
} from "../GlobalButton";
import dayjs from "dayjs";

const { Search } = Input;

const NewCreateContract = () => {
  const navigate = useNavigate();
  const { defaultBreadcrumbs } = useContext(ContractTabContext);
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  const roleUser = useRecoilState(RoleAccessAtom);
  const userData = useRecoilState(userDataAtom);

  const [selectedType, setSelectedType] = useState(
    roleUser &&
      roleUser[0] &&
      roleUser[0].page_access.contracts.create_contract.create
      ? sessionStorage.getItem("cardItem")
        ? sessionStorage.getItem("cardItem")
        : "MSA"
      : "MSA"
  );
  const [supplierTree, setSupplierTree] = useState(null);
  const [expandLoader, setExpandLoader] = useState(false);
  const [nodeMap, setNodeMap] = useState();
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const paginateTreeData = useRef({ pageNo: 1, limit: 20 });
  const [tabBreadcrumbs, setTabBreadcrumbs] = useState(defaultBreadcrumbs);

  const [treePathRepresent, setTreePathRepresent] = useState([]);
  const [scrollLoad, setScrollLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const selectedTypeRef = useRef(selectedType);
  const scrollContainerRef = useRef(null);
  const initialFetchCompleted = useRef(false);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [parentData, setParentData] = useState();
  const [processorName, setProcessorName] = useState([
    {
      id: "projects/enmatter/locations/us/processors/a9a438f0ccae0e73",
      label: "Contract-Extraction",
    },
  ]);
  const [contractFile, setContractFile] = useState(null);
  const [extractionLoader, setExtractionLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [contractAuthoring, setContractAuthoring] = useState(1);
  const [contractTemplate, setContractTemplate] = useState([]);
  const [workflowList, setWorkflowList] = useState([]);
  const [pricingProfile, setPricingProfile] = useState([]);
  const [cePricingProfile, setCePricingProfile] = useState();
  const [basicdata, setBasicData] = useState();
  const [searchInactive, setSearchInactive] = useState(true);
  const [supplierTreeBackup, setSupplierTreeBackup] = useState([]);
  const [expandedKeysBackup, setExpandedKeysBackup] = useState([]);
  const [lite] = useRecoilState(isLiteAtom);

  useEffect(() => {
    if (!initialFetchCompleted.current) {
      fetchAndSetSupplierTree(false);
      initialFetchCompleted.current = true;
    }

    getContractTemplateApi().then((res) => {
      setContractTemplate(res.docs);
    });

    getAllWorkflowsApi().then((data) => {
      setWorkflowList(data);
    });
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      const container = e.target;
      const isBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight;
      if (isBottom && !loadingMore && scrollLoad && searchInactive) {
        fetchAndSetSupplierTree(true);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadingMore, searchInactive]);

  useEffect(() => {
    selectedTypeRef.current = selectedType;
    const updatedTree = updateTreeWithDisabledField(supplierTree, selectedType);
    setSupplierTree(updatedTree);
    setTreePathRepresent([]);
    setSelectedKeys([]);
    setTabBreadcrumbs([...defaultBreadcrumbs]);

    sessionStorage.setItem("cardItem", selectedType);
  }, [selectedType]);

  const closeModal = () => {
    setContractModal(false);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      handleProcessDocument(contractFile);
    }, 0);
  };

  const getParentContractData = async () => {
    setCreateBtnLoader(true);

    const childContractClass = determineContractClass(
      selectedType,
      tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs.type,
      contractConfig
    );

    if (tabBreadcrumbs.length >= 2 && childContractClass !== "primary") {
      await getContractParentApi(
        tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs.contract_id,
        childContractClass
      ).then((parentRes) => {
        console.log("parentRes: ", parentRes);
        setParentData(parentRes);
      });
    }

    setCreateBtnLoader(false);
  };

  const handleFileChange = (info) => {
    const uploadFile = info.file.originFileObj;
    if (info.file.status === "done" || info.file.status === "uploading") {
      if (uploadFile.type !== "application/pdf") {
        if (!hasError) {
          message.error("Upload only PDF files");
          setHasError(true);
        }
        return;
      }
      setHasError(false);
      console.log("info", info);
      console.log("info-file", info.file);
      console.log("info-fileList", info.fileList);
      setContractFile(uploadFile);
    } else if (info.file.status === "error") {
      message.error({
        content: `${info.file.name} file upload failed.`,
        top: 150,
      });
    }
  };

  const handleProcessDocument = async (file) => {
    if (!file) {
      message.error("Please select a file first!");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64Content = reader.result.split(",")[1];

      try {
        setExtractionLoader(true);
        let data = {
          processorName: processorName,
          documentContent: base64Content,
        };

        const res = await contractExtractionApi(data);
        console.log("ce-res", res);
        if (res) {
          setExtractionLoader(false);
          console.log("resres", res);
          // Process response to handle arrays
          const processedRes = {};
          for (const [key, value] of Object.entries(res)) {
            if (Array.isArray(value)) {
              processedRes[key] = value.join(", ");
            } else {
              processedRes[key] = value;
            }
          }
          console.log("Processed Response Data:", processedRes);
          console.log("commercialdata", res.commercialProperties);
          // message.success("Document processed successfully!");
          let key = 0;

          const prepareCommercialData = (commericalExtractionData) => {
            const getMonthsInRange = (startDate, endDate) => {
              const months = [];
              let currentDate = dayjs(startDate);

              while (
                currentDate.isBefore(endDate) ||
                currentDate.isSame(endDate, "month")
              ) {
                months.push({
                  year: currentDate.year(),
                  month: currentDate.format("MMM").toLowerCase(),
                });
                currentDate = currentDate.add(1, "month");
              }

              return months;
            };

            // Helper function to convert string to case-sensitive format (capitalize first letter)
            const toCaseSensitive = (text) => {
              return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            };

            // Function to transform the data
            const transformData = (data) => {
              const result = [];

              data.forEach((itemArray) => {
                let days = 0;
                let cost = 0;
                let startDate, endDate;
                let quantity = 1;

                // Process each entry and map type to appropriate keys
                const baseObject = {};

                itemArray.forEach(({ type, mentionText }) => {
                  const caseSensitiveType = toCaseSensitive(type); // Convert type to case-sensitive
                  switch (caseSensitiveType) {
                    case "Days":
                      days = parseInt(mentionText);
                      break;
                    case "End_date":
                      endDate = dayjs(mentionText, "DD/MM/YYYY"); // Store as dayjs object
                      baseObject["End Date"] = mentionText; // Keep as dayjs
                      break;
                    case "Start_date":
                      startDate = dayjs(mentionText, "DD/MM/YYYY"); // Store as dayjs object
                      baseObject["Start Date"] = mentionText; // Keep as dayjs
                      break;
                    case "Total_cost":
                      cost = parseInt(mentionText);
                      break;
                    case "Grade":
                      baseObject["Grade"] = mentionText;
                      break;
                    case "Quantity":
                      quantity = parseInt(mentionText);
                      break;
                    case "Rate_basis":
                      baseObject["Rate Basis"] = mentionText;
                      break;
                    case "Rate_period":
                      baseObject["Rate Period"] = mentionText;
                      break;
                    case "Location":
                      baseObject["Location"] = mentionText;
                      break;
                    case "Role":
                      baseObject["Role"] = mentionText;
                      break;
                    case "Price":
                      baseObject["Price"] = mentionText;
                      break;
                    case "Currency":
                      baseObject["Currency"] = mentionText;
                      break;
                    default:
                      break;
                  }
                });

                // Calculate the distribution of days and costs
                const monthsInRange = getMonthsInRange(startDate, endDate);
                const daysPerMonth = Math.floor(days / monthsInRange.length);
                const remainingDays = days % monthsInRange.length;
                const costPerMonth = Math.floor(cost / monthsInRange.length);
                const remainingCost = cost % monthsInRange.length;

                const daysObj = {};
                const costObj = {};

                // Distribute days and costs across months
                monthsInRange.forEach((month, index) => {
                  const { year, month: monthKey } = month;
                  if (!daysObj[year]) daysObj[year] = {};
                  if (!costObj[year]) costObj[year] = {};

                  daysObj[year][monthKey] =
                    daysPerMonth + (index < remainingDays ? 1 : 0);
                  costObj[year][monthKey] =
                    costPerMonth + (index < remainingCost ? 1 : 0);
                });

                baseObject["Days"] = daysObj;
                baseObject["Cost"] = costObj;

                // Duplicate the object based on the quantity
                for (let i = 0; i < quantity; i++) {
                  result.push({ ...baseObject, key: key });
                  key++;
                }
              });

              return result;
            };

            const transformedData = transformData(commericalExtractionData);
            console.log("transformedData1", transformedData);
            return transformedData;
          };

          let commercialTestData = prepareCommercialData(
            res.commercialProperties
          );

          console.log("commercialTestData: ", commercialTestData);

          console.log("pricingProfile****************: ", pricingProfile);
          const keys =
            cePricingProfile && cePricingProfile.ratetable_data.rt_items_desc;
          let ratecardData =
            cePricingProfile &&
            cePricingProfile.ratetable_data.rt_items_detail.map((detail) => {
              let dataObj = {};
              for (let i = 0; i < keys.length; i++) {
                dataObj[keys[i]] = detail[i];
              }
              return dataObj;
            });
          console.log("ratecardDataaa", ratecardData);

          let filteredRC = ratecardData.map(
            ({ ["Item Id"]: _, ...rest }) => rest
          );
          console.log("filteredRC: ", filteredRC);

          // function to compare all the keys present in rateCardObj and ignore extra keys in commercialObj
          const compareObjects = (commercialObj, rateCardObj) => {
            // get the keys from rateCardObj
            const keysToCompare = Object.keys(rateCardObj);

            // check if all the keys in rateCardObj match in commercialObj
            return keysToCompare.every(
              (key) => commercialObj[key] === rateCardObj[key]
            );
          };

          // check if every object in commercialData has a matching object in rateCardData
          const isAllMatch = commercialTestData.every((commercialObj) =>
            filteredRC.some((rateCardObj) =>
              compareObjects(commercialObj, rateCardObj)
            )
          );
          console.log(isAllMatch);
          if (isAllMatch) {
            const newContractBreadcrumbs = [
              ...tabBreadcrumbs,
              {
                title: `New ${
                  selectedType === "MSA" ? "Framework Agreement" : selectedType
                }`,
                apiArgs: {
                  supplied_id: undefined,
                  contract_id: undefined,
                  type: `${selectedType}`,
                },
              },
            ];

            message.success("Document processed successfully!");
            console.log("---setTimeout---");
            setHeaderChanger({
              header: "contract",
              headerItem: "3",
              headerItemName: headerChanger.headerItemName,
              stateSetter: {
                ...basicdata,
                contractExtractData: processedRes.extractedData,
                commercialExtractionData: commercialTestData,
                mode: { view: false, edit: false, create: true },
                breadcrumbs: newContractBreadcrumbs,
                location: "createContractForm",
              },
            });
            navigate(`/contract/create/form`, {
              state: {
                data: basicdata,
                contractExtractData: processedRes.extractedData,
                commercialExtractionData: commercialTestData,
                mode: { view: false, edit: false, create: true },
                breadcrumbs: newContractBreadcrumbs,
                location: "createContractForm",
              },
            });
          } else {
            message.error(
              "Extracted Commercial Data doesn't match with RateCard"
            );
          }
        } else {
          message.error("Try again later");
        }
      } catch (error) {
        setExtractionLoader(false);
        console.error("Error processing document:", error);
        message.error("Error processing document...Try again later");
      }
    };
  };

  const onContractAuthoringChange = (e) => {
    console.log("radio checked", e.target.value);
    setContractAuthoring(e.target.value);
    if (e.target.value === 3) {
      const supplierId =
        tabBreadcrumbs.length >= 2
          ? tabBreadcrumbs[1].apiArgs.supplier_id
          : null;

      if (supplierId) {
        getPricingProfileList(supplierId).then((res) => {
          console.log("pricingRes: ", res);
          setPricingProfile(res);
        });
      }
    }
  };

  const onChangeTemplate = (value) => {
    // console.log("template", value);
  };

  const onChangePricingProfile = (key, value) => {
    if (value) {
      console.log(key, value);
      let selectedPP = pricingProfile.filter((pp) => pp._id === value.key);
      setCePricingProfile(selectedPP[0]);
    }
  };

  const handleProcessorChange = (selectedId) => {
    console.log("selectedIddd", selectedId);
    const selectedProcessor = processorName.find(
      (proc) => proc.id === selectedId
    );
    if (selectedProcessor) {
      setProcessorName([selectedProcessor]);
    }
  };

  const fetchAndSetSupplierTree = async (loadMore = false) => {
    if (!loading && !loadingMore) {
      loadMore ? setLoadingMore(true) : setLoading(true);
      try {
        const contractTreeRes = await getContractTreeApi(
          userData[0].id,
          paginateTreeData.current.pageNo,
          paginateTreeData.current.limit,
          undefined,
          undefined,
          "Supplier"
        );

        if (contractTreeRes.docs && contractTreeRes.docs.length > 0) {
          // Append the new nodes to the existing tree (or use only new data if it's the first fetch)
          const combinedTreeData = loadMore
            ? [...(supplierTree || []), ...contractTreeRes.docs]
            : contractTreeRes.docs;

          // Apply the disabled field evaluation to the entire combined tree
          const updatedTree = updateTreeWithDisabledField(
            combinedTreeData,
            selectedTypeRef.current
          );

          setSupplierTree(updatedTree);
          buildNodeMap(contractTreeRes.docs);

          if (loadMore) {
            paginateTreeData.current.pageNo += 1;
          }
        } else {
          if (!loadMore) {
            setSupplierTree([]);
          }
          setScrollLoad(false);
          console.log("No more results to load");
        }
      } catch (error) {
        console.error("There was an issue fetching supplier tree: ", error);
      } finally {
        loadMore ? setLoadingMore(false) : setLoading(false);
      }
    }
  };

  const updateTreeWithDisabledField = (tree, selectedType) => {
    if (tree) {
      const updateNode = (node) => {
        const allowedTypes = contractTypeOptions[node.type] || [];
        const isDisabled = !allowedTypes.includes(selectedType);

        const updatedNode = {
          ...node,
          disabled: isDisabled,
        };

        if (
          updatedNode.children &&
          updatedNode.children.length > 0 &&
          updatedNode.children[0] !== true
        ) {
          updatedNode.children = updatedNode.children.map(updateNode);
        }

        return updatedNode;
      };

      return tree.map(updateNode);
    } else {
      return null;
    }
  };

  const buildNodeMap = (tree) => {
    const generatedNodeMap = new Map();

    const traverseTree = (nodes) => {
      for (const node of nodes) {
        generatedNodeMap.set(node._id, node);
        if (
          node.children &&
          node.children.length &&
          typeof node.children !== "boolean"
        ) {
          traverseTree(node.children);
        }
      }
    };

    traverseTree(tree);
    setNodeMap((prevNodeMap) => {
      const updatedNodeMap = new Map(prevNodeMap);
      generatedNodeMap.forEach((value, key) => {
        updatedNodeMap.set(key, value);
      });
      return updatedNodeMap;
    });
    return generatedNodeMap;
  };

  const addChildrenToTree = (tree, contractId, newChildren, depth) => {
    // Create a map of all nodes in the tree for quick access
    const generatedNodeMap = buildNodeMap(tree);

    // Check if the node exists and update its children
    const nodeToUpdate = generatedNodeMap.get(contractId);
    if (nodeToUpdate) {
      const updatedChildren = newChildren.map((child) => {
        const allowedTypes = contractTypeOptions[child.type] || [];
        const isDisabled = !allowedTypes.includes(selectedType);

        return {
          ...child,
          depth, // Set the depth based on the parent's depth
          disabled: isDisabled,
        };
      });

      nodeToUpdate.children = updatedChildren;
    }

    return [...tree];
  };

  const findParentNode = (childNodeId, nodeMap) => {
    for (let node of nodeMap.values()) {
      if (node.children && Array.isArray(node.children)) {
        const hasChild = node.children.some(
          (child) => child._id === childNodeId
        );
        if (hasChild) {
          return node;
        }
      }
    }
    return null;
  };

  const onTreeSelect = (_, info) => {
    console.log("selected", info);
    setSelectedKeys([info.node._id]);

    // Check if the selected node's apiArgs is the same as the last breadcrumb's apiArgs
    // If so, there is no need to update tabBreadcrumbs
    if (
      JSON.stringify({
        supplier_id: info.node.supplier_name ? info.node._id : undefined,
        contract_id: info.node._id,
        type: info.node.type,
      }) !== JSON.stringify(tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs)
    ) {
      let newBreadcrumbs = [];
      newBreadcrumbs.push(defaultBreadcrumbs[0]);
      // Use nodeMap to build the path from the selected node up to the root
      let currentNode = nodeMap.get(info.node._id);
      const breadcrumbTrail = [];
      let newTreeData = null;
      let lastInsertedNode = null;

      while (currentNode) {
        const breadcrumb = {
          title: currentNode.title,
          apiArgs: {
            supplier_id: currentNode.supplier_name
              ? currentNode._id
              : undefined,
            contract_id: currentNode._id,
            type: currentNode.type,
          },
        };
        breadcrumbTrail.unshift(breadcrumb);

        // Build tree node based on current node
        const treeNode = {
          key: currentNode._id,
          title: currentNode.title,
          type: currentNode.type,
          children: lastInsertedNode ? [lastInsertedNode] : [],
        };

        // Update lastInsertedNode to the current node for the next iteration
        lastInsertedNode = treeNode;

        // Search for the parent node in the nodeMap
        const parentNode = findParentNode(currentNode._id, nodeMap);

        if (!parentNode) {
          newTreeData = treeNode;
          break;
        }
        currentNode = parentNode;
      }

      const insertNewNodeAtDeepest = (node) => {
        if (!node.children || node.children.length === 0) {
          node.children = [
            {
              key: `new-contract-${selectedType}`,
              title: `New ${
                selectedType === "MSA" ? "Framework Agreement" : selectedType
              }`,
              type: selectedType,
              children: [],
            },
          ];
        } else {
          insertNewNodeAtDeepest(node.children[node.children.length - 1]);
        }
      };

      if (newTreeData) {
        insertNewNodeAtDeepest(newTreeData);
      }

      newBreadcrumbs.push(...breadcrumbTrail);

      console.log("newBreadcrumbs: ", newBreadcrumbs);

      setTabBreadcrumbs(newBreadcrumbs);
      setTreePathRepresent([newTreeData]);
    }
  };

  const onTreeExpand = async (_, info) => {
    console.log("expanded", info);

    if (info.expanded) {
      // Check if the current node has children loaded
      if (
        info.node.children &&
        Array.isArray(info.node.children) &&
        info.node.children.length > 0 &&
        typeof info.node.children[0] !== "boolean"
      ) {
        // If children are already loaded, no need to make an API call
        console.log("Children already loaded, skipping API call");
        setExpandedKeys((prev) => [...prev, info.node.key]);
        return;
      }

      setExpandLoader(true);
      setSelectedNode(info.node._id);

      // Make an API call only if children are not loaded or if children are true
      const newTree = await getContractTreeApi(
        userData[0].id,
        undefined,
        undefined,
        info.node.supplier_name ? info.node._id : undefined,
        info.node._id,
        info.node.type
      ).catch((error) =>
        console.error("There was an error fetching tree children: ", error)
      );

      setSupplierTree((prevTree) => {
        const parentDepth = info.node.depth || 0;
        const updatedTree = addChildrenToTree(
          prevTree,
          info.node._id,
          newTree[0].children,
          parentDepth + 1
        );
        return updatedTree;
      });

      buildNodeMap(newTree[0].children);

      setExpandedKeys((prev) => [...prev, info.node.key]);
      setExpandLoader(false);
      setSelectedNode(null);
    } else {
      // If the node is collapsing, just remove it from the expandedKeys
      setExpandedKeys((prev) => prev.filter((key) => key !== info.node.key));
    }
  };

  const onTreeSearch = (query) => {
    setSearchInactive(false);
    if (supplierTreeBackup.length === 0 && expandedKeysBackup.length === 0) {
      setSupplierTreeBackup(supplierTree);
      setExpandedKeysBackup(expandedKeys);
    }
    const trimmedQuery = query.trim();
    if (trimmedQuery.length !== 0) {
      setSupplierTree(null);
      searchContractTree(trimmedQuery).then((res) => {
        buildNodeMap(res.searchResults);
        const updatedTree = updateTreeWithDisabledField(
          res.searchResults,
          selectedType
        );
        setSupplierTree(updatedTree);
        setExpandedKeys(res.expandedKeys);
      });
    }
  };

  const resetSearch = () => {
    setSearchInactive(true);
    const updatedTree = updateTreeWithDisabledField(
      supplierTreeBackup,
      selectedType
    );
    setSupplierTree(updatedTree);
    setExpandedKeys(expandedKeysBackup);
    setSupplierTreeBackup([]);
    setExpandedKeysBackup([]);
  };

  const treeTitleRender = (node) => {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 5px",
          color: node.disabled
            ? "var(--color-solid-darkgrey)"
            : "var(--color-solid-darkergrey)",
        }}
      >
        {getNodeIcon(node.type, node.disabled ? "inactive" : "active")}
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 13,
            fontWeight: 600,
            width: "100%",
          }}
        >
          {node.title}
        </span>
      </span>
    );
  };

  const treePathRepresentTitleRender = (node) => {
    const isRootNode = node.type === "Supplier";

    return (
      <div
        className={`tree-node-container ${isRootNode ? "root-node" : ""}`}
        style={{
          paddingLeft: isRootNode ? 0 : 15, // Indent child nodes
        }}
      >
        {!isRootNode && (
          <div className="tree-node-connector">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 0px",
                color: node.disabled
                  ? "var(--color-solid-darkgrey)"
                  : node.key.startsWith(`new-contract-${selectedType}`)
                  ? "var(--color-solid-darkerblue)"
                  : "var(--color-solid-darkergrey)",
              }}
            >
              {getNodeIcon(node.type, node.disabled ? "inactive" : "active")}
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: 13,
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                <span
                  style={{
                    borderRadius: 20,
                    padding: "3px 8px",
                    marginRight: 8,
                    background: "var(--color-solid-darkblue)",
                    color: "white",
                    fontSize: 10,
                  }}
                >
                  {node.type === "MSA" ? "Framework Agreement" : node.type}
                </span>
                {node.title}
              </span>
            </span>
          </div>
        )}
        {isRootNode && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px 0px",
              color: node.disabled
                ? "var(--color-solid-darkgrey)"
                : "var(--color-solid-darkergrey)",
            }}
          >
            {getNodeIcon(node.type, node.disabled ? "inactive" : "active")}
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 13,
                fontWeight: 600,
                width: "100%",
              }}
            >
              <span
                style={{
                  borderRadius: 20,
                  padding: "3px 8px",
                  marginRight: 8,
                  background: "var(--color-solid-darkblue)",
                  color: "white",
                  fontSize: 10,
                }}
              >
                {node.type === "MSA" ? "Framework Agreement" : node.type}
              </span>
              {node.title}
            </span>
          </span>
        )}
      </div>
    );
  };

  const switcherIcon = (node) => {
    // Return loading icon if this node is currently loading
    if (selectedNode === node._id && expandLoader) {
      return (
        <SyncOutlined
          spin
          style={{ color: "var(--color-solid-darkerblue)", fontSize: 12 }}
        />
      );
    }
    return <DownOutlined />;
  };

  const getAllKeys = (nodes) => {
    let keys = [];
    nodes.forEach((node) => {
      keys.push(node.key);
      if (node.children) {
        keys = keys.concat(getAllKeys(node.children));
      }
    });
    return keys;
  };

  const allExpandedKeys = useMemo(() => {
    return getAllKeys(treePathRepresent);
  }, [treePathRepresent]);

  const onFinish = (value) => {
    console.log("values: ", value);

    let data;
    let template =
      contractTemplate &&
      contractTemplate.length !== 0 &&
      contractTemplate.filter((data) => data._id === value.contract_template);

    let commercialOption =
      value.commercial_optionality === 1
        ? "table"
        : value.commercial_optionality === 2
        ? "value"
        : value.commercial_optionality === 3
        ? "none"
        : "";

    if (selectedType !== "MSA") {
      data = {
        title: value.title,
        supplier_id: tabBreadcrumbs[1].apiArgs.supplier_id
          ? tabBreadcrumbs[1].apiArgs.supplier_id
          : undefined,
        selectedType: value.contractType,
        selectedFramework: selectedType,
        contract_template: template[0],
        workflow: workflowList.find((wf) => wf._id === value.workflow),
        contract_class: determineContractClass(
          selectedType,
          tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs.type,
          contractConfig
        ),
        parentData,
        commercial_options: commercialOption,
        // pricing_profile: pricingProfile ? pricingProfile : "",
      };
    } else {
      data = {
        title: value.title,
        supplier_id: tabBreadcrumbs[1].apiArgs.supplier_id
          ? tabBreadcrumbs[1].apiArgs.supplier_id
          : undefined,
        selectedType: value.contractType,
        selectedFramework: selectedType,
        contract_template: template[0],
        workflow: workflowList.find((wf) => wf._id === value.workflow),
        pricing_profile: cePricingProfile ? cePricingProfile : "",
        contract_class: determineContractClass(
          selectedType,
          tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs.type,
          contractConfig
        ),
        commercial_options: commercialOption,
      };
    }
    console.log("Values**", value);
    setBasicData(data);

    const newContractBreadcrumbs = [
      ...tabBreadcrumbs,
      {
        title: `New ${
          selectedType === "MSA" ? "Framework Agreement" : selectedType
        }`,
        apiArgs: {
          supplied_id: undefined,
          contract_id: undefined,
          type: `${selectedType}`,
        },
      },
    ];

    if (contractAuthoring === 1) {
      setContractModal(false);
      setHeaderChanger({
        header: "contract",
        headerItem: "3",
        headerItemName: headerChanger.headerItemName,
        stateSetter: data,
      });
      navigate(`/contract/create/onlineauthoring`, { state: data });
    } else if (contractAuthoring === 3) {
      setContractModal(true);
    } else {
      setContractModal(false);
      setHeaderChanger({
        header: "contract",
        headerItem: "3",
        headerItemName: headerChanger.headerItemName,
        stateSetter: {
          data,
          mode: { view: false, edit: false, create: true },
          breadcrumbs: newContractBreadcrumbs,
          location: "createContractForm",
        },
      });
      navigate(`/contract/create/form`, {
        state: {
          data,
          mode: { view: false, edit: false, create: true },
          breadcrumbs: newContractBreadcrumbs,
          location: "createContractForm",
        },
      });
      setParentData();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    document.title = `Create Contract | Judo`;
  }, []);

  return (
    <Layout id="create-contract-layout">
      <TopHeader
        headerChange={header}
        menuKey={"5"}
        logo={true}
        feedRow={false}
        searchRow={false}
      />
      <Row gutter={[25, 12]} align="middle">
        {contractTypes.map((contractType) => {
          return (
            <Col span={4}>
              <Card
                id="create-contract-card"
                rootClassName={
                  selectedType === contractType.type
                    ? "create-contract-title-card selected"
                    : "create-contract-title-card"
                }
                bordered={false}
                onClick={() => setSelectedType(contractType.type)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: 90,
                  }}
                >
                  <img
                    className="create-contract-card-icon"
                    src={
                      selectedType === contractType.type
                        ? contractType.icon.active
                        : contractType.icon.inactive
                    }
                    style={{ width: 35, height: 35 }}
                  />
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginTop: 10,
                    }}
                  >
                    {contractType.type === "MSA"
                      ? "Framework Agreement"
                      : contractType.type}
                  </span>
                </div>
              </Card>
            </Col>
          );
        })}
        <Col span={24}>
          <Card id="create-contract-breadcrumb-card" bordered={false}>
            <Row align="middle" style={{ marginTop: 1 }}>
              <Col span={4}>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: "var(--color-solid-darkergrey)",
                  }}
                >
                  {`Create New ${
                    selectedType === "MSA"
                      ? "Framework Agreement"
                      : selectedType
                  }`}
                </span>
              </Col>
              <Col>
                <Divider
                  rootClassName="create-contract-divider"
                  type="vertical"
                />
              </Col>
              <Col span={19}>
                <Breadcrumbs
                  tabBreadcrumbs={tabBreadcrumbs}
                  setTabBreadcrumbs={setTabBreadcrumbs}
                  location={"createContract"}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={14}>
              <Card className="create-contract-tree-card" bordered={false}>
                <Search
                  allowClear
                  placeholder="Search for Suppliers and Contracts"
                  onChange={(event) => {
                    if (event.target.value.length === 0 && !searchInactive) {
                      resetSearch();
                    }
                  }}
                  onSearch={(query) => onTreeSearch(query)}
                  onClear={() => {
                    if (!searchInactive) resetSearch();
                  }}
                />
                <div
                  ref={scrollContainerRef}
                  className="scroll-container mt-10"
                >
                  {!supplierTree ? (
                    <div className="loading-container">
                      <LoadingOutlined className="loading-icon" />
                    </div>
                  ) : (
                    <Tree
                      showLine
                      blockNode
                      selectedKeys={selectedKeys}
                      expandedKeys={expandedKeys}
                      className="create-contract-tree"
                      rootClassName="create-contract-tree-wrapper"
                      treeData={supplierTree}
                      onSelect={onTreeSelect}
                      onExpand={onTreeExpand}
                      switcherIcon={switcherIcon}
                      titleRender={treeTitleRender}
                    />
                  )}
                  {supplierTree && loadingMore && (
                    <div className="loading-more">
                      <LoadingOutlined className="loading-more-icon" />
                      Loading suppliers
                    </div>
                  )}
                </div>
              </Card>
            </Col>

            <Col span={10}>
              <Card className="create-contract-tree-info-card" bordered={false}>
                {treePathRepresent.length !== 0 ? (
                  <div className="contract-path-container">
                    <div className="contract-path-header">
                      <span
                        className="contract-path-title"
                        style={{ color: "var(--color-solid-darkerblue)" }}
                      >
                        New Contract Path
                      </span>
                    </div>
                    <div className="tree-represent-container">
                      <Tree
                        expandedKeys={allExpandedKeys}
                        selectable={false}
                        showIcon={false}
                        blockNode
                        className="create-contract-tree-represent"
                        rootClassName="create-contract-tree-represent-wrapper"
                        treeData={treePathRepresent}
                        switcherIcon={false}
                        titleRender={treePathRepresentTitleRender}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="contract-path-container">
                    <div className="contract-path-header">
                      <span
                        className="contract-path-title"
                        style={{ color: "var(--color-solid-darkergrey)" }}
                      >
                        New Contract Path
                      </span>
                    </div>
                    <div className="empty-container">
                      <span className="empty-text">
                        <Empty
                          className="create-contract-tree-represent-empty"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <span>
                              {`Select a ${
                                selectedType === "MSA"
                                  ? `supplier`
                                  : `parent contract`
                              } to establish a new `}
                              <span className="selected-type">
                                {selectedType === "MSA"
                                  ? "Framework Agreement"
                                  : selectedType}
                              </span>
                              {` under it`}
                            </span>
                          }
                        />
                      </span>
                    </div>
                  </div>
                )}
                <Row
                  gutter={[12, 12]}
                  align="middle"
                  justify="center"
                  className="mt-10"
                >
                  <Col span={6}>
                    <CancelButton
                      disabled={treePathRepresent.length === 0}
                      onClick={() => {
                        setTreePathRepresent([]);
                        setSelectedKeys([]);
                        setTabBreadcrumbs([...defaultBreadcrumbs]);
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <NextButton
                      loading={createBtnLoader}
                      disabled={treePathRepresent.length === 0}
                      onClick={async () => {
                        await getParentContractData();
                        setOpenCreateModal(true);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {openCreateModal && !createBtnLoader && (
        <>
          <Modal
            centered
            open={openCreateModal}
            onOk={() => setOpenCreateModal(false)}
            onCancel={() => {
              setOpenCreateModal(false);
              setParentData();
            }}
            width={580}
            footer={false}
          >
            <Space align="">
              <Image src={confirmation} preview={false} />
              <div>
                <h3>Confirmation</h3>
                <span style={{ fontSize: "12px" }}>
                  For creating the{" "}
                  {selectedType === "MSA"
                    ? "Framework Agreement"
                    : selectedType}
                </span>
              </div>
            </Space>
            <Form
              className="mt-10 confirmation-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                ["supplier"]:
                  tabBreadcrumbs && tabBreadcrumbs.length >= 1
                    ? tabBreadcrumbs[1] && tabBreadcrumbs[1].title
                    : "",
                ["contractType"]:
                  selectedType === "MSA" ? "Framework Agreement" : selectedType,
                ["workflow"]: parentData && parentData.parent_workflow_id,
              }}
            >
              {/* Contract Authoring */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="contract_authoring"
                    label="Contract Authoring"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select Contract Authoring!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onContractAuthoringChange}
                      value={contractAuthoring}
                      style={{ width: "100%" }}
                    >
                     <Row justify="center">
                        {!lite && (
                          <Col span={8}>
                            <Radio value={1} style={{ whiteSpace: "nowrap" }}>
                              Online Authoring
                            </Radio>
                          </Col>
                        )}
                        <Col span={lite ? 24 : 8}>
                          <Radio value={2} style={{ whiteSpace: "nowrap" }}>
                            Offline Authoring
                          </Radio>
                        </Col>
                        {!lite && (
                          <Col span={8}>
                            <Radio value={3}>Smart Authoring</Radio>
                          </Col>
                        )}
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {/* Contract Commercial Optionality */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="commercial_optionality"
                    label="Contract Commercials"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please select contract commercial optionality!",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }}>
                      <Row justify="center">
                        <Col span={8}>
                          <Radio value={1} style={{ whiteSpace: "nowrap" }}>
                            Commercial Line-Items
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value={2} style={{ whiteSpace: "nowrap" }}>
                            Commercial Value
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value={3}>No Commercials</Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {/* Supplier &&  Contract Type */}
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <Form.Item
                    name="supplier"
                    label="Supplier"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled className="confirmation-input" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contractType"
                    label="Contract Type"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled className="confirmation-input" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Title */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="title"
                    label="Title"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Title!",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input placeholder="Enter your title" className="" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Contract Template */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="contract_template"
                    label="Contract Template"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select template!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={(value) => onChangeTemplate(value)}
                      showSearch={true}
                      className=""
                      placeholder="Select Contract Template"
                      options={
                        contractTemplate &&
                        contractTemplate
                          .filter(
                            (data) =>
                              data.supplier_id ===
                              (tabBreadcrumbs.length >= 2
                                ? tabBreadcrumbs[1].apiArgs.supplier_id
                                : tabBreadcrumbs[tabBreadcrumbs.length - 1]
                                    .apiArgs.contract_id)
                          )
                          .map((data) => ({
                            key: data._id,
                            label: data.contract_template_name,
                            value: data._id,
                          }))
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* Smart Authoring */}
              {contractAuthoring && contractAuthoring === 3 && (
                <>
                  {/* Pricing Profile */}
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="pricing_profile"
                        label="Pricing Profile"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Please select pricing profile!",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          onChange={(key, value) =>
                            onChangePricingProfile(key, value)
                          }
                          showSearch
                          className=""
                          placeholder="Select Pricing Profile"
                          options={
                            pricingProfile &&
                            pricingProfile
                              // .filter(
                              //   (data) =>
                              //     data.supplier_id ===
                              //     (tabBreadcrumbs.length >= 2
                              //       ? tabBreadcrumbs[1].apiArgs.supplier_id
                              //       : tabBreadcrumbs[tabBreadcrumbs.length - 1]
                              //           .apiArgs.contract_id)
                              // )
                              .map((data) => ({
                                key: data._id,
                                label: data.price_profile_name,
                                value: data._id,
                              }))
                          }
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Processor */}
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="processor"
                        label="Processor"
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Please select Processor!",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          onChange={(value) => handleProcessorChange(value)}
                          showSearch={true}
                          className=""
                          placeholder="Select Processor"
                          options={
                            processorName &&
                            processorName.map((data) => ({
                              key: data.id,
                              label: data.label,
                              value: data.id,
                            }))
                          }
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {/* Workflow */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    name="workflow"
                    label="Workflow"
                    rules={[
                      {
                        required: true,
                        message: "Please select workflow!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select workflow"
                      className=""
                      allowClear={true}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          >
                            or
                          </Divider>
                          <Button
                            style={{
                              width: "100%",
                            }}
                            className="mb-5 create-btn"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              navigate(`/system-setup`);
                              localStorage.setItem("wf-create", true);
                              setHeaderChanger({
                                header: "systemSetup",
                                headerItem: "2",
                                headerItemName: "systemSetup",
                              });
                              sessionStorage.setItem("conTabKey", 5);
                            }}
                          >
                            Create New Workflow
                          </Button>
                        </>
                      )}
                      options={
                        workflowList &&
                        workflowList.map((workflow) => ({
                          key: workflow._id,
                          label: workflow.workflow_name,
                          value: workflow._id,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" align="middle">
                <Col
                  span={24}
                  align="middle"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Col span={7} style={{ marginRight: 5 }}>
                    <CancelButton
                      onClick={() => {
                        setOpenCreateModal(false);
                        setParentData();
                      }}
                    />
                  </Col>
                  <Col span={7} style={{ marginLeft: 5 }}>
                    <CreateButtonShort htmlType="submit" />
                    <Modal
                      open={contractModal}
                      className="maximize-batch-content"
                      onCancel={closeModal}
                      footer={null}
                      centered
                      closeIcon={null}
                      width="90%"
                    >
                      {/* <div className="system-setup1"> */}
                      <Row>
                        <Col span={24} align="center">
                          <div>
                            <h3>Enter Contract Details</h3>
                            <hr className="mt-10 view-supplier-hr" />
                          </div>
                        </Col>
                      </Row>
                      <div className="contract-div">
                        <Row
                          align="middle"
                          gutter={[40, 16]}
                          justify="center"
                          className="mt-25"
                        >
                          <Col
                            span={16}
                            align="right"
                            className="vertical-align"
                          >
                            <div className="choose-box-div">
                              <Form.Item>
                                <Row
                                  type="flex"
                                  gutter={[64, 56]}
                                  align={"middle"}
                                >
                                  <Col
                                    span={24}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <span className="upload-note">
                                      Click below to autofill Contract details
                                      through Uploading
                                    </span>
                                  </Col>
                                  <Col
                                    span={24}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <Col span={16} style={{ marginRight: 5 }}>
                                      <div className="main-test">
                                        <Upload
                                          // {...props}
                                          accept=".pdf"
                                          showUploadList
                                          listType="picture"
                                          maxCount={1}
                                          // beforeUpload={() => false}
                                          onChange={handleFileChange}
                                          customRequest={dummyRequest}
                                          className="contract_authority-upload-wrapper"
                                        >
                                          <Col
                                            span={24}
                                            align="middle"
                                            style={{
                                              display: "flex",
                                              justifyContent: "end",
                                              alignItems: "end",
                                            }}
                                          >
                                            <Col
                                              span={24}
                                              style={{ marginRight: 5 }}
                                            >
                                              <UploadButton
                                                className="template-upload"
                                                type="primary"
                                                // onClick={() =>
                                                //   setOpenCreateModal(true)
                                                // }
                                              />
                                            </Col>
                                          </Col>
                                        </Upload>
                                        {extractionLoader && (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              margin: 20,
                                            }}
                                          >
                                            <LoadingOutlined
                                              style={{
                                                fontSize: 20,
                                                color:
                                                  "var(--color-solid-darkerblue)",
                                              }}
                                              spin
                                            />
                                            <span
                                              style={{
                                                marginLeft: 10,
                                                color:
                                                  "var(--color-solid-darkerblue)",
                                              }}
                                            >
                                              Extracting Contract Details&nbsp;
                                              <span className="ellipsis"></span>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* </div> */}
                    </Modal>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      )}
    </Layout>
  );
};

export default NewCreateContract;
