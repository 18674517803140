import React, { useState, useEffect } from "react";
import TopHeader from "../../Header";
import { HeaderChangerAtom, RoleAccessAtom } from "../../../store/store";
import {
  Layout,
  Row,
  Col,
  Card,
  Image,
  Form,
  Input,
  Button,
  Tabs,
  Divider,
  Checkbox,
} from "antd"; //antd components
import {
  EyeOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import { useRecoilState } from "recoil";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import workspace_premium from "../../../assets/images/icons/workspace_premium.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { OkButton } from "../../../components/GlobalButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../assets/style/clauselibrary.css";
import NotAuthorized from "../../NotAuthorized";
import DataTable from "../../Tables/DataTable";

function ViewClauseLibrary() {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  const [confirmationModel, setConfirmationModel] = useState(false);
  const { state } = useLocation();
  console.log("Stateforview", state);
  if (state === null || state === undefined) {
    console.log("Header state: ", headerChanger.stateSetter);
    state = headerChanger.stateSetter;
  }
  let header = headerChanger.header;
  const menuKey = "5";
  const navigate = useNavigate();
  let disableVal = "disable-btn";
  const [disable, setDisable] = useState(disableVal);
  const [tableLoader, setTableLoader] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const col = [
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <EyeOutlined
              title="View"
              style={{
                fontSize: "20px",
                color: "var(--color-solid-darkgrey)",
              }}
              className="cursor-pointer"
              onClick={() => handleViewBatch(record)}
            />
          </div>
        </span>
      ),
    },
  ];

  // Modify dataSource to contain multiple batch details
  const dataSource =
    state &&
    state.batch_details.map((batch, index) => ({
      key: index.toString(), // or use unique key if available
      batch_name: batch.batch_name,
    }));

  const handleViewBatch = (batchData) => {
    console.log("View batch data:", batchData);
    console.log("State ********", state);
    let batch_detail =
      state &&
      state.batch_details.filter(
        (batch, index) => index === Number(batchData.key)
      );
    setInitialFormValues(batch_detail[0]);
    setConfirmationModel(true);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  // Function to handle the return of the data from the API
  const ClauseLibraryDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="org-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="header_name"
                    label="HEADER NAME"
                    colon={false}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="header_description"
                    label="HEADER DESCRIPTION"
                    colon={false}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <div className="create-entity-div">
                <Row
                  align="middle"
                  type="flex"
                  style={{ padding: "10px 30px 0px" }}
                >
                  <Col span={18} className="vertical-align">
                    <h4 className="entity-text">Content Batches </h4>
                  </Col>
                </Row>
                <hr className="mt-10 view-supplier-hr" />
                <Row>
                  <DataTable
                    rowClassName={() => "contract-tree-table-row"}
                    type="clause-library"
                    dataTableData={dataSource}
                    colData={col}
                    showSorterTooltip={false}
                  />
                </Row>
                <Row
                  align="middle"
                  type="flex"
                  style={{ padding: "10px 0px 30px 15px" }}
                  gutter={[8, 16]}
                ></Row>
              </div>
            </div>
            {confirmationModel && (
              <Modal
                centered
                open={confirmationModel}
                onOk={() => setConfirmationModel(false)}
                onCancel={() => setConfirmationModel(false)}
                width={540}
                footer={false}
              >
                <div>
                  <Form name="batch-form" initialValues={initialFormValues}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={workspace_premium}
                        style={{ width: 25, height: 25 }}
                      />
                      <p
                        style={{
                          paddingTop: 1,
                          paddingLeft: 6,
                          fontWeight: 600,
                        }}
                      >
                        View Batch
                      </p>
                    </div>
                    {/* Header Name
                      <Form.Item
                        name="header_name"
                        label="Header Name"
                        labelCol={{ span: 24 }}
                      >
                        <Input placeholder="Axy Company" />
                      </Form.Item> */}

                    {/* Batch Name */}
                    <Form.Item
                      name="batch_name"
                      label="Batch Name"
                      labelCol={{ span: 24 }}
                      style={{ marginTop: "10px" }}
                    >
                      <Input disabled placeholder="Batch Name" />
                    </Form.Item>

                    <Form.Item
                      name="batch_desc"
                      label="Batch Description"
                      labelCol={{ span: 24 }}
                    >
                      <Input disabled placeholder="Batch Description" />
                    </Form.Item>

                    <Form.Item name="batch_content" labelCol={{ span: 24 }}>
                      <Row
                        justify="space-between"
                        align="middle"
                        className="mb-10"
                      >
                        <Col span={12}>
                          <span>Batch Content</span>
                        </Col>
                        <Col span={12} align="right">
                          <Button
                            type="primary"
                            icon={<FullscreenOutlined />}
                            onClick={openModal}
                          />
                        </Col>
                      </Row>
                      <ReactQuill
                        theme="snow"
                        className="view-quill"
                        value={
                          (state && state.batch_details[0]?.batch_content) || {
                            ops: [],
                          }
                        }
                        modules={{ toolbar: false }}
                        readOnly
                      />
                    </Form.Item>
                    <Modal
                      className="maximize-batch-content"
                      title="Batch Content"
                      open={modalVisible}
                      onCancel={closeModal}
                      footer={null}
                      width="90%"
                      styles={{
                        body: {
                          height: "80vh",
                          overflow: "auto",
                        },
                      }}
                      closeIcon={
                        <FullscreenExitOutlined className="exit-fullscreen-icon" />
                      }
                      centered
                    >
                      <ReactQuill
                        id="max-quill-view"
                        theme="snow"
                        value={
                          (state && state.batch_details[0]?.batch_content) || {
                            ops: [],
                          }
                        }
                        modules={{ toolbar: false }}
                        readOnly
                        style={{ height: "100%" }}
                        placeholder="Batch Content..."
                      />
                    </Modal>
                    {/* <Checkbox disabled>
                          Make as default Batch
                        </Checkbox> */}
                    <Row gutter={[16, 8]} className="mt-15">
                      <Col span={16} offset={7}>
                        <Row type="flex" align={"middle"}>
                          <Col span={11} offset={1}>
                            <OkButton
                              htmlType="submit"
                              onClick={() => setConfirmationModel(false)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Modal>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state} />;
  };

  // Variable to hold the component returned by the function.
  const items = [
    {
      key: "1",
      label: `Clause Library Details`,
      children: ClauseLibraryDetails(),
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "2",
      label: `Entity Log`,
      children: EntityLog(),
    },
  ];

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="view-clause-library"
            initialValues={{
              ["header_name"]: state && state.header_name,
              ["header_description"]: state && state.header_desc,
              batch_name: state && state.batch_details[0]?.batch_name,
              batch_description: state && state.batch_details[0]?.batch_desc,
              batch_content: state && state.batch_details[0]?.batch_content,
              // ["le_country"]: state&&state.le_country,
              // ["le_address"]: state&&state.le_address,
            }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={16}
                md={18}
                lg={18}
                xl={24}
              >
                <Card className="setup-header-card">
                  <Row type="flex" gutter={[8, 16]}>
                    <Col
                      className="vertical-align"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={2}
                    >
                      <Row type="flex" gutter={[8, 16]}>
                        <Col span={12}>
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`/contract_authority/clause-library`)
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <Image
                            src={arrow_right_disable}
                            style={{ width: 20 }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="vertical-align"
                      xs={17}
                      sm={17}
                      md={19}
                      lg={19}
                      xl={20}
                    >
                      <span>Contract Authority</span> &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/contract_authority/clause-library`)
                        }
                      >
                        Clause Library
                      </span>
                      &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span>
                        {state && state.header_name
                          ? state && state.header_name
                          : "View Clause Header"}
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs items={items} />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/contract_authority/clause-library`} />
      )}
    </Layout>
  );
}

export default ViewClauseLibrary;
