import React, { useEffect, useState } from "react";
import TopHeader from "../Header";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Layout, Table, Empty } from "antd"; //antd components
import { LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import "../../assets/style/setup.css";
import "../../assets/style/reports.css";
import AggregateValue from "../Contract/AggregateValue";
import moment from "moment";
import { reportArrays } from "../../utils/data";
import { HeaderChangerAtom, RoleAccessAtom } from "../../store/store";
import { suppliersOnlyApi } from "../../api/suppliersApi";
import {
  contractFilterApi,
  getAggregateValueApi,
} from "../../api/contractsApi";
import { legalEntitysOnlyApi } from "../../api/legalEntityApi";
import { costCenterOnlyApi } from "../../api/costCenterApi";
import { getRatecardReportApi } from "../../api/ratecardApi";
import { getFxTableReportApi } from "../../api/fxTableApi";
import { getPricingProfileReportApi } from "../../api/pricingProfileApi";
import { getAllInvoicingProfileReportApi } from "../../api/invoicingProfileApi";
import { getSingleWorkflowsReportApi } from "../../api/workflowApi";
import NotAuthorized from "../NotAuthorized";
import ReportLoader from "../ReportLoader";
import HeaderCard from "../HeaderCard";
import DataTable from "../Tables/DataTable";

const ViewBuildReport = () => {
  const [headerChanger] = useRecoilState(HeaderChangerAtom); // Holds the top header global atom
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  let header = headerChanger.header; // Holds the top header state extracted from the header atom

  const navigate = useNavigate(); // Stores navigate function to navigate to other pages
  let { state } = useLocation(); // Stores details of selected Report from Build/Schedule Report Table

  const [reportDataSource, setReportDataSource] = useState(["Empty"]); // Stores API response of the selected report & used when downloading report
  const [rateCardSetup, setRateCardSetup] = useState([]); // Stores names of all Rate Card columns
  const [allRateCardColumns, setAllRateCardColumns] = useState([]); //Stores description of all RateCard Columns
  /* Stores raw Contract Commercial data from API which is sent to AggregateValue component, to display the Contract Aggregate Table.
   * Object parameters:
   * mode: Set to "view" so that the Aggregate table is in view only mode.
   * report: Set to boolean true when Contract Commercial report is generated.
   * contracts: Key added to commercialData after getting the raw Contract commercial data from API. */
  const [commercialData, setCommercialData] = useState({
    mode: "view",
    report: false,
  });
  const [fxTableType] = useState(state && state.fx_table_type); // Stores FX Table type (Contractual/SpotRates)
  const [selectedColLoc] = useState(
    state && state.column_desc.length !== 0
      ? state && state.column_desc
      : state && state.type_of_report === "fx_setup"
      ? reportArrays[state && state.type_of_report][fxTableType]
      : state && state.type_of_report === "ratecard_setup"
      ? rateCardSetup
      : reportArrays[state && state.type_of_report]
  ); // Stores the names of report columns that needs to be displayed
  console.log("State: ", state);
  /* Stores the processed Contract Commercial Aggregate data.
   * setAggregateData function is passed to AggregateValue component as a custom prop,
     so that aggregate data can be passed down to this component to view the Aggregate table or download the excel. */
  const [aggregateData, setAggregateData] = useState({}); // Warning : Even though aggregateData appears to be unused, removing it will not render the Commercial Aggregate Table
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  useEffect(() => {
    getReportDetails(state && state);
    getReportColumns(state && state.type_of_report);
  }, []);

  // Contract reports column descriptions
  const allContractColumns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      align: "left",
      show: selectedColLoc.includes("Supplier") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Type",
      dataIndex: "contract_type",
      align: "left",
      show: selectedColLoc.includes("Contract Type") ? true : false,
      ellipsis: true,
    },
    {
      title: "Parent Contract",
      dataIndex: "parent_contract",
      align: "left",
      show: selectedColLoc.includes("Parent Contract") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Name",
      dataIndex: "contract_name",
      align: "left",
      show: selectedColLoc.includes("Contract Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      show: selectedColLoc.includes("Start Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      show: selectedColLoc.includes("End Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "ACV (EUR)",
      dataIndex: "acv_(eur)",
      show: selectedColLoc.includes("ACV (EUR)") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "TCV (EUR)",
      dataIndex: "tcv_(eur)",
      show: selectedColLoc.includes("TCV (EUR)") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Initiator",
      dataIndex: "initiator",
      show: selectedColLoc.includes("Initiator") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Approval Levels",
      dataIndex: "approval_levels",
      show: selectedColLoc.includes("Approval Levels") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Current Approval Level",
      dataIndex: "current_approval_level",
      show: selectedColLoc.includes("Current Approval Level") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Current Approver",
      dataIndex: "current_approver",
      show: selectedColLoc.includes("Current Approver") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Submitted Date",
      dataIndex: "submitted_date",
      show: selectedColLoc.includes("Submitted Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Ageing",
      dataIndex: "ageing",
      show: selectedColLoc.includes("Ageing") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Approved Date",
      dataIndex: "approved_date",
      show: selectedColLoc.includes("Approved Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Rejection Level",
      dataIndex: "rejection_level",
      show: selectedColLoc.includes("Rejection Level") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Rejected Date",
      dataIndex: "rejected_date",
      show: selectedColLoc.includes("Rejected Date") ? true : false,
      align: "left",
      ellipsis: true,
    },
    {
      title: "Reason For Rejection",
      dataIndex: "reason_for_rejection",
      show: selectedColLoc.includes("Reason For Rejection") ? true : false,
      align: "left",
      ellipsis: true,
    },
  ];

  // Supplier report column descriptions
  const allSupplierColumns = [
    {
      title: "Supplier ID",
      dataIndex: "supplier_id",
      align: "left",
      show: selectedColLoc.includes("Supplier ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      align: "left",
      show: selectedColLoc.includes("Supplier Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Name Short",
      dataIndex: "supplier_name_short",
      align: "left",
      show: selectedColLoc.includes("Supplier Name Short") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Status",
      dataIndex: "supplier_status",
      align: "left",
      show: selectedColLoc.includes("Supplier Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Address",
      dataIndex: "supplier_address",
      align: "left",
      show: selectedColLoc.includes("Supplier Address") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Country",
      dataIndex: "supplier_country",
      align: "left",
      show: selectedColLoc.includes("Supplier Country") ? true : false,
      ellipsis: true,
    },
  ];

  // Legal entity report column descriptions
  const allLegalColumns = [
    {
      title: "Legal Entity ID",
      dataIndex: "legal_entity_id",
      align: "left",
      show: selectedColLoc.includes("Legal Entity ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Name",
      dataIndex: "legal_entity_name",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Status",
      dataIndex: "legal_entity_status",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Address",
      dataIndex: "legal_entity_address",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Address") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Country",
      dataIndex: "legal_entity_country",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Country") ? true : false,
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      align: "left",
      show: selectedColLoc.includes("Created On") ? true : false,
      ellipsis: true,
    },
    {
      title: "Last Updated On",
      dataIndex: "last_updated_on",
      align: "left",
      show: selectedColLoc.includes("Last Updated On") ? true : false,
      ellipsis: true,
    },
  ];

  // Cost center report column descriptions
  const allCostColumns = [
    {
      title: "Cost Center ID",
      dataIndex: "cost_center_id",
      align: "left",
      show: selectedColLoc.includes("Cost Center ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center Name",
      dataIndex: "cost_center_name",
      align: "left",
      show: selectedColLoc.includes("Cost Center Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center Number",
      dataIndex: "cost_center_number",
      align: "left",
      show: selectedColLoc.includes("Cost Center Number") ? true : false,
      ellipsis: true,
    },
    {
      title: "Cost Center Status",
      dataIndex: "cost_center_status",
      align: "left",
      show: selectedColLoc.includes("Cost Center Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity ID",
      dataIndex: "legal_entity_id",
      align: "left",
      show: selectedColLoc.includes("Legal Entity ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Legal Entity Name",
      dataIndex: "legal_entity_name",
      align: "left",
      show: selectedColLoc.includes("Legal Entity Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      align: "left",
      show: selectedColLoc.includes("Created On") ? true : false,
      ellipsis: true,
    },
    {
      title: "Last Updated On",
      dataIndex: "last_updated_on",
      align: "left",
      show: selectedColLoc.includes("Last Updated On") ? true : false,
      ellipsis: true,
    },
  ];
  // Ratecard column descriptions are defined inside getAllRateCardData() because it is dynamic

  // Contractual FX report column descriptions
  const allFxContractualColumns = [
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
      align: "left",
      show: selectedColLoc.includes("Billing Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "Year",
      dataIndex: "year",
      align: "left",
      show: selectedColLoc.includes("Year") ? true : false,
      ellipsis: true,
    },
  ];

  // Spot rates FX report column descriptions
  const allFxSpotColumns = [
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
      align: "left",
      show: selectedColLoc.includes("Billing Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "Jan",
      dataIndex: "jan",
      align: "left",
      show: selectedColLoc.includes("Jan") ? true : false,
      ellipsis: true,
    },
    {
      title: "Feb",
      dataIndex: "feb",
      align: "left",
      show: selectedColLoc.includes("Feb") ? true : false,
      ellipsis: true,
    },
    {
      title: "Mar",
      dataIndex: "mar",
      align: "left",
      show: selectedColLoc.includes("Mar") ? true : false,
      ellipsis: true,
    },
    {
      title: "Apr",
      dataIndex: "apr",
      align: "left",
      show: selectedColLoc.includes("Apr") ? true : false,
      ellipsis: true,
    },
    {
      title: "May",
      dataIndex: "may",
      align: "left",
      show: selectedColLoc.includes("May") ? true : false,
      ellipsis: true,
    },
    {
      title: "Jun",
      dataIndex: "jun",
      align: "left",
      show: selectedColLoc.includes("Jun") ? true : false,
      ellipsis: true,
    },
    {
      title: "Jul",
      dataIndex: "jul",
      align: "left",
      show: selectedColLoc.includes("Jul") ? true : false,
      ellipsis: true,
    },
    {
      title: "Aug",
      dataIndex: "aug",
      align: "left",
      show: selectedColLoc.includes("Aug") ? true : false,
      ellipsis: true,
    },
    {
      title: "Sep",
      dataIndex: "sep",
      align: "left",
      show: selectedColLoc.includes("Sep") ? true : false,
      ellipsis: true,
    },
    {
      title: "Oct",
      dataIndex: "oct",
      align: "left",
      show: selectedColLoc.includes("Oct") ? true : false,
      ellipsis: true,
    },
    {
      title: "Nov",
      dataIndex: "nov",
      align: "left",
      show: selectedColLoc.includes("Nov") ? true : false,
      ellipsis: true,
    },
    {
      title: "Dec",
      dataIndex: "dec",
      align: "left",
      show: selectedColLoc.includes("Dec") ? true : false,
      ellipsis: true,
    },
  ];

  // Pricing profile report column descriptions
  const allPricingColumns = [
    {
      title: "Pricing Profile ID",
      dataIndex: "pricing_profile_id",
      align: "left",
      show: selectedColLoc.includes("Pricing Profile ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Pricing Profile Name",
      dataIndex: "pricing_profile_name",
      align: "left",
      show: selectedColLoc.includes("Pricing Profile Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Pricing Profile Status",
      dataIndex: "pricing_profile_status",
      align: "left",
      show: selectedColLoc.includes("Pricing Profile Status") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier ID",
      dataIndex: "supplier_id",
      align: "left",
      show: selectedColLoc.includes("Supplier ID") ? true : false,
      ellipsis: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      align: "left",
      show: selectedColLoc.includes("Supplier Name") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Pricing",
      dataIndex: "contract_pricing",
      align: "left",
      show: selectedColLoc.includes("Contract Pricing") ? true : false,
      ellipsis: true,
    },
    {
      title: "Contract Currency",
      dataIndex: "contract_currency",
      align: "left",
      show: selectedColLoc.includes("Contract Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "Delivery Currency",
      dataIndex: "delivery_currency",
      align: "left",
      show: selectedColLoc.includes("Delivery Currency") ? true : false,
      ellipsis: true,
    },
    {
      title: "FX Table",
      dataIndex: "fx_table",
      align: "left",
      show: selectedColLoc.includes("FX Table") ? true : false,
      ellipsis: true,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      align: "left",
      show: selectedColLoc.includes("Created On") ? true : false,
      ellipsis: true,
    },
    {
      title: "Last Updated On",
      dataIndex: "last_updated_on",
      align: "left",
      show: selectedColLoc.includes("Last Updated On") ? true : false,
      ellipsis: true,
    },
  ];

  // Invoicing profile report column descriptions
  const allInvoicingColumns = [
    {
      title: "From Entity",
      dataIndex: "from_entity",
      align: "left",
      show: selectedColLoc.includes("From Entity") ? true : false,
      ellipsis: true,
    },
    {
      title: "To Entity",
      dataIndex: "to_entity",
      align: "left",
      show: selectedColLoc.includes("To Entity") ? true : false,
      ellipsis: true,
    },
    {
      title: "Allocation Percentage",
      dataIndex: "allocation_percentage",
      align: "left",
      show: selectedColLoc.includes("Allocation Percentage") ? true : false,
      ellipsis: true,
    },
  ];

  // Workflow report column descriptions
  const allWorkflowColumns = [
    {
      title: "Step",
      dataIndex: "step",
      align: "left",
      show: selectedColLoc.includes("Step") ? true : false,
      ellipsis: true,
    },
    {
      title: "Step Fulfillment",
      dataIndex: "step_fulfillment",
      align: "left",
      show: selectedColLoc.includes("Step Fulfillment") ? true : false,
      ellipsis: true,
    },
    {
      title: "From",
      dataIndex: "from",
      align: "left",
      show: selectedColLoc.includes("From") ? true : false,
      ellipsis: true,
    },
    {
      title: "To",
      dataIndex: "to",
      align: "left",
      show: selectedColLoc.includes("To") ? true : false,
      ellipsis: true,
    },
    {
      title: "Approver",
      dataIndex: "approver",
      align: "left",
      show: selectedColLoc.includes("Approver") ? true : false,
      ellipsis: true,
    },
    {
      title: "Approval Type",
      dataIndex: "approval_type",
      align: "left",
      show: selectedColLoc.includes("Approval Type") ? true : false,
      ellipsis: true,
    },
    {
      title: "Email ID",
      dataIndex: "email_id",
      align: "left",
      show: selectedColLoc.includes("Email ID") ? true : false,
      ellipsis: true,
    },
  ];

  // Variable which stores the column description of the selected type of report & passed to AntD Table
  let columnToShow =
    state && state.report_category === "setup_data"
      ? state && state.setup_report === "supplier_setup"
        ? allSupplierColumns.filter((col) => col.show !== false)
        : state && state.setup_report === "legal_setup"
        ? allLegalColumns.filter((col) => col.show !== false)
        : state && state.setup_report === "cost_setup"
        ? allCostColumns.filter((col) => col.show !== false)
        : state && state.setup_report === "ratecard_setup"
        ? allRateCardColumns.filter((col) => col.show !== false)
        : state && state.setup_report === "fx_setup"
        ? fxTableType === "contractual"
          ? allFxContractualColumns.filter((col) => col.show !== false)
          : fxTableType === "spot"
          ? allFxSpotColumns.filter((col) => col.show !== false)
          : ""
        : state && state.setup_report === "pricing_setup"
        ? allPricingColumns.filter((col) => col.show !== false)
        : state && state.setup_report === "invoicing_setup"
        ? allInvoicingColumns.filter((col) => col.show !== false)
        : state && state.setup_report === "workflow_setup"
        ? allWorkflowColumns.filter((col) => col.show !== false)
        : ""
      : (state && state.report_category === "contract_data") ||
        (state && state.report_category === "commercial_data")
      ? allContractColumns.filter((col) => col.show !== false)
      : "";

  // Function which handles fetching & processing of raw API data into report data
  const getReportDetails = (data) => {
    console.log("State: ", data);
    const getAllContractData = (resData) => {
      console.log("resData  ***", resData);
      const reportApiData =
        resData &&
        resData.map((i, index) => {
          let tableRow = {
            key: i._id,
            supplier: i.supplier_name,
            contract_type: i.contract_type,
            parent_contract: i.parent_contract,
            contract_name: i.contract_name,
            // contract_description: "",
            start_date: i.start_date
              ? moment(i.start_date).format("DD MMMM YYYY")
              : "",
            end_date: i.end_date
              ? moment(i.end_date).format("DD MMMM YYYY")
              : "",
            "acv_(eur)": i.contract_value_acv,
            "tcv_(eur)": i.contract_value_tcv,
            initiator: i.initiator,
            approval_levels: i.approval_levels,
            current_approval_level: i.current_approval_level,
            current_approver: i.current_approver,
            submitted_date: i.submitted_date
              ? moment(i.submitted_date).format("DD MMMM YYYY")
              : "",
            ageing: i.ageing,
            approved_date: i.updatedAt
              ? moment(i.updatedAt).format("DD MMMM YYYY")
              : "",
            rejection_level: i.rejection_level,
            rejected_date: i.rejected_date,
            reason_for_rejection: i.reason_for_rejection,
          };
          return tableRow;
        });
      console.log("Contract Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllSupplierData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          supplier_id: i.id,
          supplier_name: i.supplier_name,
          supplier_name_short: i.supplier_name_short,
          supplier_status: i.supplier_status,
          supplier_entity:
            i.supplier_entities &&
            i.supplier_entities.length !== 0 &&
            i.supplier_entities
              .map((entity) => entity.supplier_entity)
              .join(" | "),
          supplier_address:
            i.supplier_entities &&
            i.supplier_entities.length !== 0 &&
            i.supplier_entities
              .map((entity) => entity.supplier_address)
              .join(" | "),
          supplier_country:
            i.supplier_entities &&
            i.supplier_entities.length !== 0 &&
            i.supplier_entities
              .map((entity) => entity.supplier_country)
              .join(" | "),
        };
        return tableRow;
      });
      console.log("Supplier Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllLegalData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          legal_entity_id: i._id,
          legal_entity_name: i.le_name,
          legal_entity_status: i.le_status,
          legal_entity_address: i.le_address,
          legal_entity_country: i.le_country,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Legal Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllCostData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          cost_center_id: i._id,
          cost_center_name: i.cc_name,
          cost_center_number: i.cc_nbr,
          cost_center_status: i.cc_status,
          legal_entity_id: i.le_id,
          legal_entity_name: i.le_name,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Cost Center Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllRateCardData = (resData) => {
      const rateColName = resData[0].rt_items_desc;
      setRateCardSetup(rateColName);
      console.log("RateColName: ", rateColName);
      const rateData = resData[0].rt_items_detail;
      const allRateColumns = rateColName.map((element) => {
        return {
          title: element,
          dataIndex: element.toLowerCase().replace(/\s+/g, "_"),
          align: "left",
          show: selectedColLoc.includes(element) ? true : false,
          // show: true,
        };
      });
      console.log("RateColumns: ", allRateColumns);
      setAllRateCardColumns(allRateColumns);
      const reportApiData = rateData.map((row) => {
        const rowData = {};
        rateColName.forEach((colHeader, index) => {
          const formattedKey = colHeader.toLowerCase().replace(/\s+/g, "_"); // Convert to lowercase and replace spaces with underscores
          rowData[formattedKey] = row[index];
        });
        return rowData;
      });
      console.log("Rate Card Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllFxData = (resData, fx_type) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow =
          fx_type === "contractual"
            ? {
                billing_currency: i.billing_currency,
                year: i.year,
              }
            : fx_type === "spot"
            ? {
                billing_currency: i.billing_currency,
                jan: i.Jan,
                feb: i.Feb,
                mar: i.Mar,
                apr: i.Apr,
                may: i.May,
                jun: i.Jun,
                jul: i.Jul,
                aug: i.Aug,
                sep: i.Sep,
                oct: i.Oct,
                nov: i.Nov,
                dec: i.Dec,
              }
            : "";
        return tableRow;
      });
      console.log("FX Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllPricingData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let contract_cc = i.contract_cc.join(", ");
        let delivery_cc = i.delivery_cc.join(", ");
        let fx_table = i.fx_table.join(", ");
        let contract_pricing = i.contract_pricing
          .map((ele) => {
            return ele === 1
              ? "Fixed"
              : ele === 2
              ? "Variable"
              : ele === 3
              ? "Agreed"
              : ele === 4
              ? "All"
              : "";
          })
          .join(", ");
        let tableRow = {
          pricing_profile_id: i._id,
          pricing_profile_name: i.price_profile_name,
          pricing_profile_status: i.status,
          supplier_id: i.supplier_id,
          supplier_name: i.supplier_name,
          contract_pricing: contract_pricing,
          contract_currency: contract_cc,
          delivery_currency: delivery_cc,
          fx_table: fx_table,
          created_on: i.created_on
            ? moment(i.created_on).format("DD MMMM YYYY")
            : i.createdAt
            ? moment(i.createdAt).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updated_on
            ? moment(i.updated_on).format("DD MMMM YYYY")
            : i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Pricing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllInvoicingData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          from_entity: i.from_entity,
          to_entity: i.to_entity,
          allocation_percentage: i.allocation_p,
        };
        return tableRow;
      });
      console.log("Invoicing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllWorkflowData = (resData) => {
      const levelData = resData[0].workflow_metadata;
      const reportApiData = [];
      Object.keys(levelData).forEach((level, levelIndex) => {
        levelData[level].forEach((approverData) => {
          let fromNode = approverData.from.join(", ");
          let toNode = approverData.to.join(", ");
          const rowData = {
            step: levelIndex + 1,
            step_fulfillment: approverData.stepFulfillment,
            from: fromNode,
            to: toNode,
            approver: approverData.approver,
            approval_type: approverData.approvalType,
            email_id: approverData.emailId,
          };
          reportApiData.push(rowData);
        });
      });
      console.log("Workflow Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    if (data.report_category === "contract_data") {
      contractFilterApi(
        data.type_of_report,
        data.supplier_id,
        data.contract_id !== undefined ? data.contract_id : "null",
        data.contract_start_date !== undefined
          ? data.contract_start_date
          : "null",
        data.contract_end_date !== undefined ? data.contract_end_date : "null",
        data.contract_status
      ).then((res) => {
        console.log("contractFilterApi response", res);
        if (Object.keys(res).length !== 0) {
          getAllContractData(res);
        } else {
          setReportDataSource([]);
        }
      });
    } else if (data.report_category === "commercial_data") {
      if (data.type_of_report === "commercial") {
        getAggregateValueApi(data.contract_id, data.contract_type).then(
          (res) => {
            console.log("commercialFilterApi response", res);
            if (res) {
              setCommercialData({
                ...commercialData,
                report: true,
                contracts: res.docs && res.docs[0],
              });
            } else {
              setCommercialData({ ...commercialData, report: true });
            }
          }
        );
      }
    } else if (data.report_category === "setup_data") {
      if (data.setup_report === "supplier_setup") {
        suppliersOnlyApi().then((res) => {
          console.log("supplierFilterApi response", res);
          getAllSupplierData(res);
        });
      } else if (data.setup_report === "legal_setup") {
        legalEntitysOnlyApi().then((res) => {
          console.log("legalFilterApi response", res);
          getAllLegalData(res);
        });
      } else if (data.setup_report === "cost_setup") {
        costCenterOnlyApi().then((res) => {
          console.log("costFilterApi response", res);
          getAllCostData(res);
        });
      } else if (data.setup_report === "ratecard_setup") {
        console.log("rateCardId", data.ratecard_id);
        getRatecardReportApi(data.ratecard_id).then((res) => {
          console.log("rateCardFilterApi response", res);
          getAllRateCardData(res);
        });
      } else if (data.setup_report === "fx_setup") {
        console.log(
          "fxTableId, fxTableType",
          data.fx_table_id,
          data.fx_table_type
        );
        getFxTableReportApi(data.fx_table_id, data.fx_table_type).then(
          (res) => {
            console.log("fxFilterApi response", res);
            getAllFxData(res, fxTableType);
          }
        );
      } else if (data.setup_report === "pricing_setup") {
        getPricingProfileReportApi(data.pricing_profile_id).then((res) => {
          console.log("pricingFilterApi response", res);
          getAllPricingData(res);
        });
      } else if (data.setup_report === "invoicing_setup") {
        getAllInvoicingProfileReportApi(data.invoicing_profile_id).then(
          (res) => {
            console.log("invoicingFilterApi response", res);
            getAllInvoicingData(res);
          }
        );
      } else if (data.setup_report === "workflow_setup") {
        getSingleWorkflowsReportApi(data.workflow_id).then((res) => {
          console.log("workflowFilterApi response", res);
          getAllWorkflowData(res);
        });
      }
    }
  };

  /* Function to handle getting the names of report columns according to type of report
   * @Param reportType - Holds the selected type of report name
   */
  const getReportColumns = (reportType) => {
    console.log("State: ", state);
    console.log("ReportType: ", reportType);
    let activeColumns = [];
    let setupDataColumns = [
      "supplier_setup",
      "legal_setup",
      "cost_setup",
      "ratecard_setup",
      "fx_setup",
      "pricing_setup",
      "invoicing_setup",
      "workflow_setup",
    ];
    let repCol =
      state && state.setup_report === "setup_data"
        ? reportArrays[state && state.setup_report]
        : (state && state.report_category === "contract_data") ||
          (state && state.report_category === "commercial_data")
        ? reportArrays[state && state.type_of_report]
        : [];
    //filtering columns based on selected report
    if (setupDataColumns.includes(reportType)) {
      repCol.map((column) => {
        activeColumns.push(column);
      });
    } else {
      allContractColumns.map((column) => {
        if (repCol.includes(column.title)) {
          activeColumns.push(column.title);
        }
      });
    }
  };

  /* Function which calculates individual column widths based on the length of the column title
   * @Param columnToShow - Holds the names of all report columns
   */
  const calcScrollWidth = (columnToShow) => {
    let totalWidth = 0;
    columnToShow &&
      columnToShow.map((column) => {
        totalWidth = totalWidth + column.title.length * 20;
      });
    return totalWidth;
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={24}
            >
              <HeaderCard
                mainMenu="Reports"
                subMenu="View Reports"
                itemName={state.report_name}
                // itemStatus={state.status}
                goBackLink="/reports"
                // state={""}
                // showDetails={"create"}
              />
            </Col>
          </Row>
          <div className="price-profile-content m-10">
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
              <Col span={24}>
                <div className="create-supplier-div">
                  <div className="m-20">
                    <Row type="flex">
                      <Col span={24}>
                        {state &&
                        state.type_of_report === "commercial" &&
                        commercialData &&
                        commercialData.report &&
                        Object.hasOwn(commercialData, "contracts") &&
                        reportDataSource[0] === "Empty" ? (
                          <AggregateValue
                            contractData={commercialData}
                            sendAggregate={setAggregateData}
                          />
                        ) : state &&
                          state.type_of_report === "commercial" &&
                          commercialData.report === false ? (
                          <ReportLoader />
                        ) : (
                          state &&
                          state.type_of_report === "commercial" &&
                          commercialData &&
                          commercialData.report &&
                          !Object.hasOwn(commercialData, "contracts") && (
                            <>
                              <Col span={24} align="center">
                                <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  description={"No Commercial Report was found"}
                                />
                              </Col>
                            </>
                          )
                        )}
                        {state &&
                        state.type_of_report !== "commercial" &&
                        reportDataSource.length !== 0 &&
                        reportDataSource[0] !== "Empty" ? (
                          <>
                            <DataTable
                              type="report"
                              dataTableData={reportDataSource}
                              colData={columnToShow}
                            />
                          </>
                        ) : state &&
                          state.type_of_report !== "commercial" &&
                          reportDataSource[0] === "Empty" ? (
                          <ReportLoader />
                        ) : (
                          state &&
                          state.type_of_report !== "commercial" &&
                          reportDataSource.length === 0 && (
                            <>
                              <Col span={24} align="center">
                                <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  description={"No Report was found"}
                                />
                              </Col>
                            </>
                          )
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <NotAuthorized redirect={`/reports`} />
      )}
    </Layout>
  );
};

export default ViewBuildReport;
